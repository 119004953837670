import React, { useContext, useEffect, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import {
  CommissionTemplateType,
  Mutation,
  MutationCreateCommissionRangeArgs,
  MutationDeleteCommissionTemplateArgs,
  MutationUpdateCommissionTemplateArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { CommissionRangeEmpty } from "../../entity/empties";
import { useMutation } from "@apollo/client";
import {
  CREATE_COMMISSION_RANGE_MUTATION,
  DELETE_COMMISSION_TEMPLATE_MUTATION,
  UPDATE_COMMISSION_TEMPLATE_MUTATION,
} from "../../apollo/mutations/commissions";
import { handleError } from "../../entity/ErrorHandler";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { Button, Table } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { WithStyles } from "@material-ui/core/styles";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import TextField from "@material-ui/core/TextField";
import { dialogConfirm } from "../../utils/dialogs";
import CommissionRangeRow from "./CommissionRangeRow";

interface Props extends WithStyles<typeof styles> {
  commissionTemplate: CommissionTemplateType;
}

const getDefaultCommissionTemplate = (
  commissionTemplate: CommissionTemplateType
) => ({
  ...commissionTemplate,
  baseSalary: formatNumber(commissionTemplate.baseSalary, 2),
});

function CommissionTemplate({ classes, commissionTemplate }: Props) {
  const { t } = useTranslation();

  const [commissionTemplateEdited, setCommissionTemplateEdited] = useState(
    getDefaultCommissionTemplate(commissionTemplate)
  );

  useEffect(() => {
    setCommissionTemplateEdited(
      getDefaultCommissionTemplate(commissionTemplate)
    );
  }, [commissionTemplate]);

  const [commissionRangeNew, setCommissionRangeNew] =
    useState(CommissionRangeEmpty);

  const [showCommissionRangeNew, setShowCommissionRangeNew] = useState(false);

  const [updateCommissionTemplate, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCommissionTemplateArgs
  >(UPDATE_COMMISSION_TEMPLATE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [createCommissionRange, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCommissionRangeArgs
  >(CREATE_COMMISSION_RANGE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const [deleteCommissionTemplate, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCommissionTemplateArgs
  >(DELETE_COMMISSION_TEMPLATE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("commissionTemplates"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionCommissionRangeAdd = checkPermission(myPermissions, [
    "commissions.add_commissionrange",
  ]);
  const hasPermissionCommissionTemplateChange = checkPermission(myPermissions, [
    "commissions.change_commissiontemplate",
  ]);
  const hasPermissionCommissionTemplateDelete = checkPermission(myPermissions, [
    "commissions.delete_commissiontemplate",
  ]);

  const onClickAddCommissionRange = () => {
    createCommissionRange({
      variables: {
        commissionTemplateId: commissionTemplateEdited.id,
        daysMax: commissionRangeNew.daysMax,
      },
    });
    setShowCommissionRangeNew(false);
    setCommissionRangeNew(CommissionRangeEmpty);
  };

  const onClickShowCommissionRangeNew = () => {
    setShowCommissionRangeNew(true);
  };
  const onClickHideCommissionRangeNew = () => {
    setShowCommissionRangeNew(false);
  };

  const handleUpdate = (
    values: Partial<MutationUpdateCommissionTemplateArgs> = {}
  ) => {
    const base: MutationUpdateCommissionTemplateArgs = {
      commissionTemplateId: commissionTemplateEdited.id,
      name: commissionTemplateEdited.name,
      baseSalary: parseNumber(commissionTemplateEdited.baseSalary),
    };

    updateCommissionTemplate({
      variables: { ...base, ...values },
    });
  };

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCommissionTemplate({
        variables: {
          commissionTemplateId: commissionTemplateEdited.id,
        },
      });
    });
  };

  return (
    <Table borderless className="w-auto mb-5">
      <thead>
        <tr className={loadingUpdate ? "loading" : ""}>
          <td colSpan={2}>
            <FormControl fullWidth>
              <TextField
                label={t("name")}
                onChange={(event) => {
                  setCommissionTemplateEdited({
                    ...commissionTemplateEdited,
                    name: event.target.value,
                  });
                }}
                onBlur={() => handleUpdate()}
                value={commissionTemplateEdited.name}
                disabled={!hasPermissionCommissionTemplateChange}
                required={true}
              />
            </FormControl>
          </td>
          <td>
            {hasPermissionCommissionTemplateDelete && (
              <ButtonLoad
                loading={loadingDelete}
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={() => handleClickDelete()}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonLoad>
            )}
          </td>
        </tr>
        <tr className={loadingUpdate ? "loading" : ""}>
          <td colSpan={2}>
            <FormControl fullWidth>
              <TextFieldFocus
                label={t("base_salary")}
                onChange={(event) => {
                  setCommissionTemplateEdited({
                    ...commissionTemplateEdited,
                    baseSalary: event.target.value,
                  });
                }}
                onBlur={() => {
                  setCommissionTemplateEdited({
                    ...commissionTemplateEdited,
                    baseSalary: formatNumber(
                      commissionTemplateEdited.baseSalary,
                      2
                    ),
                  });
                  handleUpdate({
                    baseSalary: parseNumber(
                      commissionTemplateEdited.baseSalary
                    ),
                  });
                }}
                value={commissionTemplateEdited.baseSalary}
                disabled={!hasPermissionCommissionTemplateChange}
                required={true}
                inputProps={{ maxLength: 9 }}
              />
            </FormControl>
          </td>
          <td></td>
        </tr>
        <tr>
          <th colSpan={3}></th>
        </tr>
        <tr>
          <th>{t("commission_range_min")}</th>
          <th>{t("commission_range_max")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {commissionTemplateEdited.commissionrangeSet.map(
          (commissionRange, index) => {
            const commissionRangePrev =
              index > 0
                ? commissionTemplateEdited.commissionrangeSet[index - 1]
                : null;

            return (
              <CommissionRangeRow
                key={commissionRange.id}
                commissionRange={commissionRange}
                commissionRangePrev={commissionRangePrev}
                isLast={
                  commissionTemplateEdited.commissionrangeSet.length - 1 ===
                  index
                }
              />
            );
          }
        )}
      </tbody>
      <tfoot>
        <tr>
          <td>
            {hasPermissionCommissionRangeAdd && !showCommissionRangeNew && (
              <Button
                onClick={onClickShowCommissionRangeNew}
                className="btnRoundSmall"
              >
                <AddIcon />
              </Button>
            )}
          </td>
          <td>
            {showCommissionRangeNew && (
              <FormControl>
                <TextFieldFocus
                  value={commissionRangeNew.daysMax}
                  onChange={(event) => {
                    setCommissionRangeNew({
                      ...commissionRangeNew,
                      daysMax: parseNumber(event.target.value),
                    });
                  }}
                  inputProps={{ maxLength: 11 }}
                />
              </FormControl>
            )}
          </td>
          <td>
            {hasPermissionCommissionRangeAdd && showCommissionRangeNew && (
              <ButtonLoad
                loading={loadingCreate}
                onClick={onClickAddCommissionRange}
                variant="primary"
                size="sm"
                className={classes.btn}
              >
                <FontAwesomeIcon icon={faSave} />
              </ButtonLoad>
            )}
          </td>
          <td>
            {hasPermissionCommissionRangeAdd && showCommissionRangeNew && (
              <Button
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickHideCommissionRangeNew}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
          </td>
        </tr>
      </tfoot>
    </Table>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(CommissionTemplate);
