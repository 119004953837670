import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { addStringToArr, removeStringFromArr } from "../../utils/collections";
import { ReportPriceType } from "../../entity/types";
import ReportDayRowContent from "./ReportDayRowContent";
import { formatNumber } from "../../utils/formatting";
import { format } from "date-fns";

interface Props extends WithStyles<typeof styles> {
  date: string;
  reportPrices: ReportPriceType[];
}

function ReportDayContent({ classes, date, reportPrices }: Props) {
  const { t } = useTranslation();
  const [rowsOpen, setRowsOpen] = useState<string[]>([]);

  const getEurosForDate = () => {
    let totalEuros = 0;
    reportPrices.forEach((data) => {
      if (data.useDayPrice) {
        totalEuros += parseFloat(data.dayPrice);
      } else {
        const dtArr = date.split("-");
        const dtStr = dtArr[0] + "-" + dtArr[1];
        data.reportpricemonthSet.forEach((reportPriceMonth) => {
          if (reportPriceMonth.month === dtStr) {
            totalEuros += parseFloat(reportPriceMonth.pricePerDay);
          }
        });
      }
    });
    return t("currency", { amount: formatNumber(totalEuros, 2) });
  };

  return (
    <Accordion
      key={date}
      onChange={(event, expanded) => {
        if (expanded) {
          setRowsOpen(addStringToArr(rowsOpen, date));
        } else {
          setRowsOpen(removeStringFromArr(rowsOpen, date));
        }
      }}
    >
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          <span className="me-4">
            {format(new Date(date), t("format_date"))}
          </span>
          <span>{getEurosForDate()}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="ps-0 ps-sm-5">
        {rowsOpen.includes(date) && (
          <ReportDayRowContent date={date} reportPrices={reportPrices} />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    avatarUpper: { backgroundColor: theme.palette.primary.main },
    heading: {
      flex: 1,
      fontSize: "1.0rem",
      paddingTop: "0.4rem",
    },
    headerEfficiency: {
      paddingTop: "1rem",
      width: "calc(33% - 20px)",
    },
  });

export default withStyles(styles)(ReportDayContent);
