import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import {
  CatalogCategoryUpperType,
  CatalogRowType,
  Mutation,
  MutationUpdateCatalogRowOrderArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { ID_EMPTY } from "../../utils/constants";
import { updateCacheCatalogEdit } from "../../utils/cache";
import { UPDATE_CATALOG_ROW_ORDER_MUTATION } from "../../apollo/mutations/catalogs";
import { getCatalogExtraSortStyles } from "../../utils/catalog_extra/catalog_extra";
import { renderSelectGroupCategoryUpperCategory } from "../../utils/catalogs/select_group";
import { IdToIndexType } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  catalogCategoryIdSelected: string;
  setCatalogCategoryIdSelected: React.Dispatch<React.SetStateAction<string>>;
}

function CatalogSortRows({
  classes,
  catalogCategoriesUpper,
  catalogCategoryIdSelected,
  setCatalogCategoryIdSelected,
}: Props) {
  const { t } = useTranslation();

  const [updateCatalogRowOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateCatalogRowOrderArgs
  >(UPDATE_CATALOG_ROW_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  let catalogRows: CatalogRowType[] = [];
  catalogCategoriesUpper.forEach((catalogCategoryUpper) => {
    catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) => {
      if (catalogCategory.id === catalogCategoryIdSelected) {
        catalogRows = catalogCategory.catalogrowSet;
      }
    });
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let idToIndex: IdToIndexType[] = [];
    arrayMove(catalogRows, oldIndex, newIndex).map(
      (catalogRow: CatalogRowType, index) => {
        idToIndex.push({
          id: catalogRow.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogRowOrder({
      variables: { idOrderInput: idToIndex },
    });
  };

  const DragHandleRow = SortableHandle(() => (
    <span className={classes.elSort}>
      <FontAwesomeIcon icon={faArrowsV} size="lg" />
    </span>
  ));

  const RowContainer = SortableElement(({ catalogRow }: any) => (
    <div key={catalogRow.id} className="mb-3">
      <DragHandleRow />
      {catalogRow.name}
    </div>
  ));

  const CategoriesContainer = SortableContainer(({ items }: any) => {
    return (
      <div className={loading ? "loading" : ""}>
        {items.map((catalogRow: CatalogRowType, index: number) => (
          <RowContainer
            key={catalogRow.id}
            index={index}
            catalogRow={catalogRow}
          />
        ))}
      </div>
    );
  });

  return (
    <div>
      <h5 className="mb-4">{t("sort_catalog_rows")}</h5>
      <FormControl className="mb-4" fullWidth>
        <InputLabel id="lblCatalogRowCategoryFilter">
          {t("catalog_row")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogRowCategoryFilter"
          value={catalogCategoryIdSelected}
          onChange={(event) => {
            setCatalogCategoryIdSelected(String(event.target.value));
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogCategoriesUpper.map((catalogCategoryUpper) =>
            renderSelectGroupCategoryUpperCategory(catalogCategoryUpper)
          )}
        </Select>
      </FormControl>
      <CategoriesContainer
        items={catalogRows}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    elSort: getCatalogExtraSortStyles(theme),
  });

export default withStyles(styles)(CatalogSortRows);
