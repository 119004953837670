import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InvoiceDeliveryMethods,
  InvoicesInvoiceRowRowTypeChoices,
  InvoiceSendMethods,
  InvoiceType,
  Mutation,
  MutationDoInvoiceApproveArgs,
  MutationDoInvoiceSendArgs,
  MutationSendInvoiceArgs,
  MutationSendInvoicePaymentArgs,
  MutationUpdateInvoiceArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { Table } from "react-bootstrap";
import { formatNumber } from "../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltRight,
  faFileInvoice,
} from "@fortawesome/pro-light-svg-icons";
import format from "date-fns/format";
import { useMutation } from "@apollo/client";
import {
  DO_INVOICE_APPROVE_MUTATION,
  DO_INVOICE_SEND_MUTATION,
  SEND_INVOICE_MUTATION,
  SEND_INVOICE_PAYMENT_MUTATION,
  UPDATE_INVOICE_MUTATION,
} from "../../apollo/mutations/invoices";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryKey } from "../../utils/cache";
import { ROOT_QUERY } from "../../utils/constants";
import { newDate } from "../../utils/dates";
import ManagementInvoiceRow from "./ManagementInvoiceRow";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { getVatFactor } from "../../utils/calc";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  invoice: InvoiceType;
  readonly: boolean;
  callbackAfterSend?: (invoiceId: string) => void;
}

function ManagementInvoice({
  classes,
  invoice,
  readonly,
  callbackAfterSend,
}: Props) {
  const { t } = useTranslation();

  const [invoiceEdited, setInvoiceEdited] = useState(invoice);
  const [invoiceSendMethod, setInvoiceSendMethod] = useState(
    InvoiceSendMethods.Normal
  );
  const [invoiceDeliveryMethod, setInvoiceDeliveryMethod] = useState(
    InvoiceDeliveryMethods.Einvoice
  );
  const [emailInvoicing, setEmailInvoicing] = useState(
    invoiceEdited.reference.customer.invoicingEmail
  );

  const [sendInvoice, { loading: loadingSendInvoice }] = useMutation<
    Mutation,
    MutationSendInvoiceArgs
  >(SEND_INVOICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      if (callbackAfterSend && result.sendInvoice?.invoice) {
        callbackAfterSend(result.sendInvoice.invoice.id);
      }
    },
    update: (cache, invoiceRow) => {
      if (invoiceRow.data?.sendInvoice?.invoice?.invoicerowSet) {
        invoiceRow.data.sendInvoice.invoice.invoicerowSet.forEach((ir) => {
          cache.evict({
            id: "InvoiceRowType:" + ir.id,
          });
        });
      }

      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("invoicesSent"),
      });
    },
  });

  const [updateInvoice, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateInvoiceArgs
  >(UPDATE_INVOICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [sendInvoicePayment, { loading: loadingSendPayment }] = useMutation<
    Mutation,
    MutationSendInvoicePaymentArgs
  >(SEND_INVOICE_PAYMENT_MUTATION, {
    onError: (error) => {
      handleError(t("error_sending_invoice_payment", { error: error.message }));
    },
  });

  const [doInvoiceApprove, { loading: loadingDoApprove }] = useMutation<
    Mutation,
    MutationDoInvoiceApproveArgs
  >(DO_INVOICE_APPROVE_MUTATION, {
    onError: (error) => {
      handleError(t("error_sending_invoice_approve", { error: error.message }));
    },
  });

  const [doInvoiceSend, { loading: loadingDoSend }] = useMutation<
    Mutation,
    MutationDoInvoiceSendArgs
  >(DO_INVOICE_SEND_MUTATION, {
    onError: (error) => {
      handleError(t("error_sending_invoice_send", { error: error.message }));
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditInvoice =
    checkPermission(myPermissions, ["invoices.change_invoice"]) ||
    (invoice.isAdvance &&
      checkPermission(myPermissions, [
        "invoices.allow_advance_invoicing_without_perms",
      ]));

  const onClickInvoiceSend = () => {
    const shouldApprove = [
      InvoiceSendMethods.Advance,
      InvoiceSendMethods.Card,
    ].includes(invoiceSendMethod);
    const shouldAddPayment = invoiceSendMethod === InvoiceSendMethods.Card;

    sendInvoice({
      variables: {
        invoiceId: invoiceEdited.id,
        invoiceSendMethod: invoiceSendMethod,
        invoiceDeliveryMethod: invoiceDeliveryMethod,
        emailInvoicing: emailInvoicing,
      },
    }).then((result) => {
      if (!result.errors && shouldApprove) {
        doInvoiceApprove({
          variables: {
            invoiceId: invoiceEdited.id,
          },
        }).then((result) => {
          if (!result.errors) {
            doInvoiceSend({
              variables: {
                invoiceId: invoiceEdited.id,
              },
            }).then((result) => {
              if (!result.errors && shouldAddPayment) {
                sendInvoicePayment({
                  variables: {
                    invoiceId: invoiceEdited.id,
                  },
                });
              }
            });
          }
        });
      }
    });
  };

  let total = 0;
  let totalWithVat = 0;
  let vatsUsed: string[] = [];
  invoiceEdited.invoicerowSet.forEach((invoiceRow) => {
    if (
      invoiceSendMethod === InvoiceSendMethods.Card &&
      invoiceRow.rowType === InvoicesInvoiceRowRowTypeChoices.Surcharge
    ) {
      return;
    }

    const vatFactor = getVatFactor(invoiceRow.vat);

    const amount = Number(invoiceRow.price);
    total += amount;
    totalWithVat += amount * vatFactor;

    const vat = formatNumber(invoiceRow.vat);
    if (!vatsUsed.includes(vat)) {
      vatsUsed.push(vat);
    }
  });

  const loading =
    loadingSendInvoice ||
    loadingSendPayment ||
    loadingDoApprove ||
    loadingDoSend ||
    loadingUpdate;

  const handleUpdateInvoice = (values: Partial<MutationUpdateInvoiceArgs>) => {
    const base: MutationUpdateInvoiceArgs = {
      invoiceId: invoiceEdited.id,
      information: invoiceEdited.information,
    };

    updateInvoice({
      variables: { ...base, ...values },
    });
  };

  return (
    <Paper variant="outlined" className={classes.conInvoice}>
      <div className={classes.header}>
        <div className={`${classes.conIcon} d-inline-block`}>
          <FontAwesomeIcon className={classes.icon} icon={faFileInvoice} />
        </div>
        <div className="d-inline-block">
          {invoiceEdited.reference.customer.name}
          <br />
          {invoiceEdited.reference.name}
        </div>
      </div>
      <div>
        <FormControl fullWidth>
          <TextField
            multiline
            label={t("information_invoice")}
            value={invoiceEdited.information}
            minRows={2}
            inputProps={{ maxLength: 500 }}
            disabled={!hasPermissionEditInvoice}
            onChange={(event) => {
              setInvoiceEdited({
                ...invoiceEdited,
                information: event.currentTarget.value,
              });
            }}
            onBlur={() => {
              handleUpdateInvoice({
                information: invoiceEdited.information,
              });
            }}
          />
        </FormControl>
      </div>
      <Table className="tblInvoice" borderless responsive={true}>
        <thead>
          <tr>
            <th className={classes.cellFirst}>{t("product_number")}</th>
            <th className={classes.cellSecond + (readonly ? " text-end" : "")}>
              {t("quantity")}
            </th>
            <th className={classes.cellThird + (readonly ? " text-end" : "")}>
              {t("unit_price")}
            </th>
            <th className={classes.cellFourth}>{t("price")}</th>
            <th className={classes.cellFifth}>{t("title")}</th>
            <th className={classes.cellSixth}>{t("description")}</th>
            <th className={classes.cellSeventh}>{t("dimensions")}</th>
          </tr>
        </thead>
        <tbody>
          {invoiceEdited.invoicerowSet.map((invoiceRow) => (
            <ManagementInvoiceRow
              key={invoiceRow.id}
              invoiceRow={invoiceRow}
              invoiceSendMethod={invoiceSendMethod}
              readonly={readonly}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th className={classes.cellFirst}></th>
            <th className={classes.cellSecond}></th>
            <th colSpan={2} className={`${classes.cellTotalSum} text-end`}>
              <span title={t("total_vat0")}>
                {t("currency", { amount: formatNumber(total, 2) })}
              </span>
              <br />
              <span
                className="text-muted"
                title={t("total_vat1", {
                  vat: vatsUsed.join("/"),
                })}
              >
                {t("currency", {
                  amount: formatNumber(totalWithVat, 2),
                })}
              </span>
            </th>
            <th colSpan={3} className="text-end">
              {invoiceEdited.sentAt && (
                <span className="text-muted" title={t("sent_at")}>
                  {format(newDate(invoiceEdited.sentAt), t("format_datetime"))}
                </span>
              )}
              {hasPermissionEditInvoice && !invoiceEdited.sentAt && (
                <div>
                  <FormControl
                    className={`me-3 ${classes.ddlInvoiceSendMethod}`}
                  >
                    <InputLabel id={`lblInvoiceSend_${invoiceEdited.id}`}>
                      {t("send_invoice")}
                    </InputLabel>
                    <Select
                      autoWidth
                      labelId={`lblInvoiceSend_${invoiceEdited.id}`}
                      value={invoiceSendMethod}
                      onChange={(event) => {
                        setInvoiceSendMethod(
                          event.target.value as InvoiceSendMethods
                        );
                      }}
                    >
                      <MenuItem value={InvoiceSendMethods.Normal}>
                        {t("send_invoice_normal")}
                      </MenuItem>
                      <MenuItem value={InvoiceSendMethods.Advance}>
                        {t("send_invoice_email")}
                      </MenuItem>
                      <MenuItem value={InvoiceSendMethods.Card}>
                        {t("send_invoice_card_payment")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <ButtonLoad
                    loading={loading}
                    className="mt-2"
                    onClick={() => onClickInvoiceSend()}
                    title={t("send_invoice")}
                  >
                    <FontAwesomeIcon icon={faArrowAltRight} />
                  </ButtonLoad>
                  {invoiceSendMethod === InvoiceSendMethods.Normal && (
                    <div className="w-100 mt-2">
                      <RadioGroup
                        className="float-end"
                        row
                        onChange={(event, value) => {
                          setInvoiceDeliveryMethod(
                            value as InvoiceDeliveryMethods
                          );
                        }}
                      >
                        <FormControlLabel
                          value={InvoiceDeliveryMethods.Einvoice}
                          control={<Radio color="primary" />}
                          label={t("invoicing_e")}
                          checked={
                            invoiceDeliveryMethod ===
                            InvoiceDeliveryMethods.Einvoice
                          }
                        />
                        <FormControlLabel
                          value={InvoiceDeliveryMethods.Post}
                          control={<Radio color="primary" />}
                          label={t("invoicing_post")}
                          checked={
                            invoiceDeliveryMethod ===
                            InvoiceDeliveryMethods.Post
                          }
                        />
                        <FormControlLabel
                          value={InvoiceDeliveryMethods.Email}
                          control={<Radio color="primary" />}
                          label={t("invoicing_by_email")}
                          checked={
                            invoiceDeliveryMethod ===
                            InvoiceDeliveryMethods.Email
                          }
                        />
                      </RadioGroup>
                      {invoiceDeliveryMethod ===
                        InvoiceDeliveryMethods.Einvoice && (
                        <div className="w-100 float-end text-muted mt-2">
                          {invoiceEdited.reference.customer.invoicingEAddress
                            ? invoiceEdited.reference.customer.invoicingEAddress
                            : t("invoicing_e_address_missing")}
                        </div>
                      )}
                      {invoiceDeliveryMethod ===
                        InvoiceDeliveryMethods.Post && (
                        <span className="w-100 float-end text-muted mt-2">
                          {invoiceEdited.reference.customer.invoicingAddress
                            ? invoiceEdited.reference.customer.invoicingAddress
                            : t("invoicing_address_missing")}
                        </span>
                      )}
                      {invoiceDeliveryMethod ===
                        InvoiceDeliveryMethods.Email && (
                        <span className="w-100 float-end text-muted mt-2">
                          {invoiceEdited.reference.customer.invoicingEmail
                            ? invoiceEdited.reference.customer.invoicingEmail
                            : t("invoicing_email_missing")}
                        </span>
                      )}
                    </div>
                  )}
                  {[
                    InvoiceSendMethods.Advance,
                    InvoiceSendMethods.Card,
                  ].includes(invoiceSendMethod) && (
                    <div className="mt-2">
                      <FormControl className={classes.txtEmail}>
                        <TextField
                          type="email"
                          label={t("email")}
                          onChange={(event) => {
                            setEmailInvoicing(event.target.value);
                          }}
                          value={emailInvoicing}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>
              )}
            </th>
          </tr>
        </tfoot>
      </Table>
    </Paper>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    conInvoice: {
      marginBottom: spacing(5),
      padding: spacing(2),
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
      backgroundColor: palette.grey["50"],
    },
    conIcon: {
      width: "75px",
    },
    icon: {
      fontSize: "2.5rem",
      color: palette.primary.main,
    },
    header: {
      fontWeight: "bold",
      fontSize: "1rem",
      padding: "0.75rem",
    },
    cellFirst: {
      width: "8%",
    },
    cellSecond: {
      width: "6%",
    },
    cellThird: {
      width: "6%",
    },
    cellFourth: {
      width: "6%",
      textAlign: "right",
    },
    cellFifth: {
      width: "30%",
    },
    cellSixth: {
      width: "30%",
    },
    cellSeventh: {
      width: "14%",
    },
    cellTotalSum: {
      fontSize: "1.4rem",
    },
    ddlInvoiceSendMethod: {
      minWidth: "10rem",
    },
    txtEmail: {
      minWidth: "14rem",
    },
    totalVat0: {
      marginTop: "-1rem",
    },
    totalVat1: {
      marginTop: "1rem",
    },
  });

export default withStyles(styles)(ManagementInvoice);
