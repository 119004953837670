import { ReservationType, TransportType } from "../../entity/types";
import { getMachineName } from "../machines/machine";
import { TFunction } from "react-i18next";
import format from "date-fns/format";
import { newDate } from "../dates";
import {
  ReservationFromReservationsQueueReturnType,
  ReservationFromReservationsWorkQueuesType,
} from "../../apollo/queries/reservations";

type ReservationForCanBeDeletedType = Pick<
  ReservationType,
  "giveCheckedAt" | "giveAt" | "returnAt" | "returnCheckedAt"
>;

export function reservationCanBeDeleted(
  reservation: ReservationForCanBeDeletedType
): boolean {
  return (
    !reservation.giveCheckedAt &&
    !reservation.giveAt &&
    !reservation.returnAt &&
    !reservation.returnCheckedAt
  );
}

export function orderCanBeDeleted(
  reservations: ReservationForCanBeDeletedType[]
): boolean {
  let canBeDeleted = true;
  reservations.forEach((reservation) => {
    if (!reservationCanBeDeleted(reservation)) {
      canBeDeleted = false;
    }
  });
  return canBeDeleted;
}

export function getReservationProductName(
  t: TFunction<"translation">,
  reservation: ReservationFromReservationsQueueReturnType,
  showMachineIdentifier = false,
  showReturnedAmount = false
) {
  let id = "";
  let name = "";
  if (reservation.catalogExtraRowRental) {
    id = reservation.catalogExtraRowRental.identifier;
    name = reservation.catalogExtraRowRental.name;
  } else if (reservation.machine) {
    id = reservation.machine.identifier;
    name = getMachineName(reservation.machine);
    if (reservation.machine.bulkProduct) {
      name +=
        " " +
        t("x_pcs", {
          pcs: showReturnedAmount
            ? reservation.bulkAmountReturned
            : reservation.bulkAmount,
        });
    }
  }
  return (showMachineIdentifier && id !== "" ? id + " " : "") + name;
}

export function checkIfReservationHandled(
  transport: TransportType,
  reservation: Pick<ReservationType, "drivecontentSet">
) {
  return reservation.drivecontentSet.some(
    (driveContent) => driveContent.transport.id === transport.id
  );
}

export function hasReservationCatalogExtra(
  reservation: ReservationFromReservationsWorkQueuesType
) {
  return Boolean(reservation.catalogExtraRowRental);
}

export const getReturnCheckedAtColor = (
  reservation: Pick<ReservationType, "returnCheckedAt" | "returnCheckedPreAt">
) => {
  if (reservation.returnCheckedAt) {
    return "primary";
  } else if (reservation.returnCheckedPreAt) {
    return "warning";
  }
  return "danger";
};

export const getReservationDuplicateConfirmMessage = (
  t: TFunction<"translation">,
  reservations: Pick<ReservationType, "id" | "dateRented" | "dateReturned">[],
  message: string
): string => {
  const textReservationList = reservations
    .map(
      (reservationFound) =>
        t("reservation_id", { id: reservationFound.id }) +
        " " +
        t("date_range", {
          start: format(newDate(reservationFound.dateRented), t("format_date")),
          end: format(newDate(reservationFound.dateReturned), t("format_date")),
        })
    )
    .join("\n");

  return t("confirm_reservation_duplicates", {
    reservations: textReservationList,
    message: message,
  });
};
