import { CatalogEmpty } from "../../entity/empties";
import { CatalogType, SettingsType } from "../../entity/types";
import { parseNumber } from "../formatting";
import React from "react";
import { findFromSetById } from "../collections";
import { ID_EMPTY } from "../constants";

export function getSelectedCatalog(
  settings: SettingsType,
  catalogs: CatalogType[],
  catalogId: string,
  setCatalogId: React.Dispatch<React.SetStateAction<string>>
): CatalogType {
  let catalog = CatalogEmpty;

  if (catalogId !== ID_EMPTY) {
    catalogs.forEach(function (catalogLooped: CatalogType) {
      if (parseNumber(catalogId) === parseNumber(catalogLooped.id)) {
        catalog = catalogLooped;
      }
    });
  } else {
    let catalogIdDefault = getCatalogIdActive(settings);
    if (catalogId !== catalogIdDefault) setCatalogId(catalogIdDefault);
    catalog = findFromSetById(catalogIdDefault, catalogs, CatalogEmpty);
  }
  return catalog;
}

export const getCatalogIdActive = (settings: SettingsType) => {
  return settings.catalogActive ? settings.catalogActive.id : ID_EMPTY;
};

export const setCatalogIdSelected = (catalogId: string) => {
  localStorage.setItem("catalogIdSelected", catalogId);
};

export const getCatalogIdSelected = (settings: SettingsType) => {
  const catalogIdStorage =
    localStorage.getItem("catalogIdSelected") === null ||
    localStorage.getItem("catalogIdSelected") === ""
      ? ID_EMPTY
      : String(localStorage.getItem("catalogIdSelected"));

  if (catalogIdStorage !== ID_EMPTY) {
    return catalogIdStorage;
  }
  return getCatalogIdActive(settings);
};
