import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  MachinesMachineFinancingInterestTypeChoices,
  MachinesMachineFinancingInterestVariableTypeChoices,
  MachinesMachineFinancingLoanTypeChoices,
  MachinesMachineFinancingTypeChoices,
  MachineType,
  Mutation,
  MutationUpdateMachineArgs,
  MutationUploadFileMachineFinancingArgs,
} from "../../../entity/types";
import { useMutation } from "@apollo/client";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { TabPanel } from "@material-ui/lab";
import { handleError } from "../../../entity/ErrorHandler";
import {
  MutationResultUploadFileMachineFinancing,
  UPDATE_MACHINE_MUTATION,
  UPLOAD_FILE_MACHINE_FINANCING_MUTATION,
} from "../../../apollo/mutations/machines";
import ButtonLoad from "../../Shared/ButtonLoad";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import TextFieldFocus from "../../Shared/TextFieldFocus";
import { ProductCardTabs } from "./DialogMachineProductCard";

const getMachineDefaults = (machine: MachineType): MachineType => ({
  ...machine,
  financingInterestRate: formatNumber(machine.financingInterestRate, 2),
  financingInterestMargin: formatNumber(machine.financingInterestMargin, 2),
});

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCardFinancing({ classes, machine }: Props) {
  const { t } = useTranslation();

  const [machineEdited, setMachineEdited] = useState<MachineType>(
    getMachineDefaults(machine)
  );

  useEffect(() => {
    setMachineEdited(getMachineDefaults(machine));
  }, [machine]);

  const [updateMachine, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineArgs
  >(UPDATE_MACHINE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [uploadFileFinancing, { loading: loadingUpload }] = useMutation<
    MutationResultUploadFileMachineFinancing,
    MutationUploadFileMachineFinancingArgs
  >(UPLOAD_FILE_MACHINE_FINANCING_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);

  const hasPermissionViewMachineFinancingInfo = checkPermission(myPermissions, [
    "machines.show_machine_financing_info",
  ]);
  const hasPermissionEditMachines = checkPermission(myPermissions, [
    "machines.change_machine",
  ]);

  if (!hasPermissionViewMachineFinancingInfo) {
    return null;
  }

  const onChangeFileFinancing = ({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && validity.valid) {
      let file = files[0];
      uploadFileFinancing({
        variables: {
          machineId: machineEdited.id,
          fileFinancing: file,
        },
      });
    }
  };

  const onDeleteFileFinancing = () => {
    uploadFileFinancing({
      variables: {
        machineId: machineEdited.id,
        fileFinancing: null,
      },
    });
  };

  return (
    <TabPanel
      value={ProductCardTabs.FinancingInfo}
      className={loadingUpload || loadingUpdate ? "loading" : ""}
    >
      <FormControl className={classes.txt}>
        <InputLabel id="lblFinancingType">{t("financing_type")}</InputLabel>
        <Select
          fullWidth
          labelId="lblFinancingType"
          value={machineEdited.financingType}
          onChange={(event) => {
            const financingType = event.target
              .value as MachinesMachineFinancingTypeChoices;
            setMachineEdited({
              ...machineEdited,
              financingType: financingType,
            });
            updateMachine({
              variables: {
                machineId: machineEdited.id,
                financingType: financingType,
              },
            });
          }}
        >
          {Object.keys(MachinesMachineFinancingTypeChoices).map((key) => {
            return (
              <MenuItem
                key={key}
                value={
                  MachinesMachineFinancingTypeChoices[
                    key as keyof typeof MachinesMachineFinancingTypeChoices
                  ]
                }
              >
                {t("financing_type_" + key)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {machineEdited.financingType ===
        MachinesMachineFinancingTypeChoices.Loan && (
        <div>
          <FormControl className={classes.txt}>
            <InputLabel id="lblFinancingLoanType">
              {t("financing_loan_type")}
            </InputLabel>
            <Select
              fullWidth
              labelId="lblFinancingLoanType"
              value={machineEdited.financingLoanType}
              onChange={(event) => {
                const financingLoanType = event.target
                  .value as MachinesMachineFinancingLoanTypeChoices;
                setMachineEdited({
                  ...machineEdited,
                  financingLoanType: financingLoanType,
                });
                updateMachine({
                  variables: {
                    machineId: machineEdited.id,
                    financingLoanType: financingLoanType,
                  },
                });
              }}
            >
              {Object.keys(MachinesMachineFinancingLoanTypeChoices).map(
                (key) => {
                  return (
                    <MenuItem
                      key={key}
                      value={
                        MachinesMachineFinancingLoanTypeChoices[
                          key as keyof typeof MachinesMachineFinancingLoanTypeChoices
                        ]
                      }
                    >
                      {t("financing_loan_type_" + key)}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          </FormControl>
          {[
            MachinesMachineFinancingLoanTypeChoices.Leasing,
            MachinesMachineFinancingLoanTypeChoices.Installment,
          ].includes(machineEdited.financingLoanType) && (
            <div>
              <TextField
                fullWidth
                className={classes.txt}
                label={t("financing_company")}
                value={machineEdited.financingCompany}
                disabled={!hasPermissionEditMachines}
                onChange={(event) => {
                  setMachineEdited({
                    ...machineEdited,
                    financingCompany: event.target.value,
                  });
                }}
                onBlur={() => {
                  updateMachine({
                    variables: {
                      machineId: machineEdited.id,
                      financingCompany: machineEdited.financingCompany,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                className={classes.txt}
                label={t("financing_agreement_number")}
                value={machineEdited.financingAgreementNumber}
                disabled={!hasPermissionEditMachines}
                onChange={(event) => {
                  setMachineEdited({
                    ...machineEdited,
                    financingAgreementNumber: event.target.value,
                  });
                }}
                onBlur={() => {
                  updateMachine({
                    variables: {
                      machineId: machineEdited.id,
                      financingAgreementNumber:
                        machineEdited.financingAgreementNumber,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                className={classes.txt}
                type="date"
                label={t("financing_date_start")}
                value={machineEdited.financingDateStart}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  setMachineEdited({
                    ...machineEdited,
                    financingDateStart:
                      event.target.value === "" ? null : event.target.value,
                  });
                }}
                onBlur={() => {
                  updateMachine({
                    variables: {
                      machineId: machineEdited.id,
                      financingDateStart: machineEdited.financingDateStart,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                className={classes.txt}
                type="date"
                label={t("financing_date_end")}
                value={machineEdited.financingDateEnd}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  setMachineEdited({
                    ...machineEdited,
                    financingDateEnd:
                      event.target.value === "" ? null : event.target.value,
                  });
                }}
                onBlur={() => {
                  updateMachine({
                    variables: {
                      machineId: machineEdited.id,
                      financingDateEnd: machineEdited.financingDateEnd,
                    },
                  });
                }}
              />
              {machineEdited.financingLoanType ===
                MachinesMachineFinancingLoanTypeChoices.Installment && (
                <div>
                  <FormControl className={classes.txt}>
                    <InputLabel id="lblFinancingInterestType">
                      {t("financing_interest_type")}
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="lblFinancingInterestType"
                      value={machineEdited.financingInterestType}
                      onChange={(event) => {
                        const financingInterestType = event.target
                          .value as MachinesMachineFinancingInterestTypeChoices;
                        setMachineEdited({
                          ...machineEdited,
                          financingInterestType: financingInterestType,
                        });
                        updateMachine({
                          variables: {
                            machineId: machineEdited.id,
                            financingInterestType: financingInterestType,
                          },
                        });
                      }}
                    >
                      {Object.keys(
                        MachinesMachineFinancingInterestTypeChoices
                      ).map((key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={
                              MachinesMachineFinancingInterestTypeChoices[
                                key as keyof typeof MachinesMachineFinancingInterestTypeChoices
                              ]
                            }
                          >
                            {t("financing_interest_type_" + key)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {machineEdited.financingInterestType ===
                    MachinesMachineFinancingInterestTypeChoices.Variable && (
                    <div>
                      <FormControl className={classes.txt}>
                        <InputLabel id="lblFinancingInterestVariableType">
                          {t("financing_interest_variable_type")}
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="lblFinancingInterestVariableType"
                          value={machineEdited.financingInterestVariableType}
                          onChange={(event) => {
                            const financingInterestVariableType = event.target
                              .value as MachinesMachineFinancingInterestVariableTypeChoices;
                            setMachineEdited({
                              ...machineEdited,
                              financingInterestVariableType:
                                financingInterestVariableType,
                            });
                            updateMachine({
                              variables: {
                                machineId: machineEdited.id,
                                financingInterestVariableType:
                                  financingInterestVariableType,
                              },
                            });
                          }}
                        >
                          {Object.keys(
                            MachinesMachineFinancingInterestVariableTypeChoices
                          ).map((key) => {
                            return (
                              <MenuItem
                                key={key}
                                value={
                                  MachinesMachineFinancingInterestVariableTypeChoices[
                                    key as keyof typeof MachinesMachineFinancingInterestVariableTypeChoices
                                  ]
                                }
                              >
                                {t("financing_interest_variable_type_" + key)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  )}
                  <TextFieldFocus
                    fullWidth
                    className={classes.txt}
                    label={t("financing_interest_rate")}
                    value={machineEdited.financingInterestRate}
                    disabled={!hasPermissionEditMachines}
                    onChange={(event) => {
                      setMachineEdited({
                        ...machineEdited,
                        financingInterestRate: event.target.value,
                      });
                    }}
                    onBlur={() => {
                      const financingPriceNew =
                        machineEdited.financingInterestRate === ""
                          ? null
                          : parseNumber(machineEdited.financingInterestRate);
                      setMachineEdited({
                        ...machineEdited,
                        financingInterestRate:
                          financingPriceNew === null
                            ? financingPriceNew
                            : formatNumber(financingPriceNew, 2),
                      });
                      updateMachine({
                        variables: {
                          machineId: machineEdited.id,
                          financingInterestRate: financingPriceNew,
                        },
                      });
                    }}
                  />
                  <TextFieldFocus
                    fullWidth
                    className={classes.txt}
                    label={t("financing_interest_margin")}
                    value={machineEdited.financingInterestMargin}
                    disabled={!hasPermissionEditMachines}
                    onChange={(event) => {
                      setMachineEdited({
                        ...machineEdited,
                        financingInterestMargin: event.target.value,
                      });
                    }}
                    onBlur={() => {
                      const financingPriceNew =
                        machineEdited.financingInterestMargin === ""
                          ? null
                          : parseNumber(machineEdited.financingInterestMargin);
                      setMachineEdited({
                        ...machineEdited,
                        financingInterestMargin:
                          financingPriceNew === null
                            ? financingPriceNew
                            : formatNumber(financingPriceNew, 2),
                      });
                      updateMachine({
                        variables: {
                          machineId: machineEdited.id,
                          financingInterestMargin: financingPriceNew,
                        },
                      });
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <TextField
        fullWidth
        multiline
        minRows={5}
        className={classes.txt}
        label={t("financing_information")}
        value={machineEdited.financingInformation}
        disabled={!hasPermissionEditMachines}
        onChange={(event) => {
          setMachineEdited({
            ...machineEdited,
            financingInformation: event.target.value,
          });
        }}
        onBlur={() => {
          updateMachine({
            variables: {
              machineId: machineEdited.id,
              financingInformation: machineEdited.financingInformation,
            },
          });
        }}
      />
      {machineEdited.fileFinancing && (
        <div className="mb-3">
          <a
            href={machineEdited.fileFinancing}
            target="_blank"
            rel="noreferrer"
          >
            {t("machine_file_financing")}
          </a>
        </div>
      )}
      <input
        id="fileMachineFinancing"
        type="file"
        onChange={onChangeFileFinancing}
        disabled={!hasPermissionEditMachines}
      />
      {machineEdited.fileFinancing && (
        <ButtonLoad
          className="ms-3"
          variant="light"
          loading={false}
          onClick={onDeleteFileFinancing}
          disabled={!hasPermissionEditMachines}
        >
          {t("delete")}
        </ButtonLoad>
      )}
    </TabPanel>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    txt: {
      display: "block",
      width: "25rem",
      maxWidth: "100%",
    },
  });

export default withStyles(styles)(TabMachineCardFinancing);
