import React, { useContext } from "react";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  InspectionsInspectionReturnQuestionAnswerTypeChoices,
  InspectionReturnQuestionType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnQuestion: InspectionReturnQuestionType;
  setInspectionReturnQuestion: React.Dispatch<
    React.SetStateAction<InspectionReturnQuestionType>
  >;
}

function DialogContentInspectionReturnQuestion({
  classes,
  inspectionReturnQuestion,
  setInspectionReturnQuestion,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "inspections.change_inspectionreturnquestion",
  ]);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "inspections.add_inspectionreturnquestion",
  ]);
  const disabled = inspectionReturnQuestion.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("question")}
          onChange={(event) => {
            setInspectionReturnQuestion({
              ...inspectionReturnQuestion,
              question: event.target.value,
            });
          }}
          value={inspectionReturnQuestion.question}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblInspectionReturnQuestionAnswerType">
          {t("answer_type")}
        </InputLabel>
        <Select
          labelId="lblInspectionReturnQuestionAnswerType"
          value={inspectionReturnQuestion.answerType}
          disabled={disabled}
          onChange={(event) => {
            setInspectionReturnQuestion({
              ...inspectionReturnQuestion,
              answerType: event.target
                .value as InspectionsInspectionReturnQuestionAnswerTypeChoices,
            });
          }}
        >
          <MenuItem
            value={InspectionsInspectionReturnQuestionAnswerTypeChoices.Text}
          >
            {t(
              "answer_type_" +
                InspectionsInspectionReturnQuestionAnswerTypeChoices.Text
            )}
          </MenuItem>
          <MenuItem
            value={InspectionsInspectionReturnQuestionAnswerTypeChoices.Options}
          >
            {t(
              "answer_type_" +
                InspectionsInspectionReturnQuestionAnswerTypeChoices.Options
            )}
          </MenuItem>
          <MenuItem
            value={InspectionsInspectionReturnQuestionAnswerTypeChoices.Check}
          >
            {t(
              "answer_type_" +
                InspectionsInspectionReturnQuestionAnswerTypeChoices.Check
            )}
          </MenuItem>
        </Select>
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentInspectionReturnQuestion);
