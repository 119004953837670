import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  GET_PRODUCT_CODES_QUERY,
  QueryResultProductCodes,
} from "../../apollo/queries/product_codes";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Table } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/pro-light-svg-icons";
import ProductCodeRow from "./ProductCodeRow";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  CostcenterEmpty,
  CosttypeEmpty,
  ProductCodeEmpty,
} from "../../entity/empties";
import { Mutation, MutationCreateProductCodeArgs } from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_PRODUCT_CODE_MUTATION } from "../../apollo/mutations/product_codes";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ButtonLoad from "../Shared/ButtonLoad";
import HeaderPage from "../Shared/HeaderPage";
import {
  GET_COSTCENTERS_QUERY,
  QueryResultCostcenters,
} from "../../apollo/queries/costcenters";
import {
  GET_COSTTYPES_QUERY,
  QueryResultCosttypes,
} from "../../apollo/queries/costtypes";

interface Props extends WithStyles<typeof styles> {}

const ProductCodeList = ({ classes }: Props) => {
  const { t } = useTranslation();

  const [productCodeNew, setProductCodeNew] = useState({
    ...ProductCodeEmpty,
    code: ProductCodeEmpty.code,
    description: ProductCodeEmpty.description,
  });

  const [showProductCodeNew, setShowProductCodeNew] = useState(false);

  const { loading, error, data } = useQuery<QueryResultProductCodes>(
    GET_PRODUCT_CODES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("productCodes"),
    }
  );

  const {
    loading: loadingCostcenters,
    error: errorCostcenters,
    data: dataCostcenters,
  } = useQuery<QueryResultCostcenters>(GET_COSTCENTERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costcenters"),
  });

  const {
    loading: loadingCosttypes,
    error: errorCosttypes,
    data: dataCosttypes,
  } = useQuery<QueryResultCosttypes>(GET_COSTTYPES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costtypes"),
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionProductRowAdd = checkPermission(myPermissions, [
    "product_codes.add_productcode",
  ]);

  const [createProductCode, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateProductCodeArgs
  >(CREATE_PRODUCT_CODE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("productCodes"),
      });
    },
  });

  if (loading || loadingCostcenters || loadingCosttypes)
    return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (errorCostcenters) return <Error error={errorCostcenters} />;
  if (errorCosttypes) return <Error error={errorCosttypes} />;
  if (!data || !dataCostcenters || !dataCosttypes) {
    return <Error error={t("error_query_failed")} />;
  }

  const onClickShowProductCodeNew = () => {
    setShowProductCodeNew(true);
  };
  const onClickHideProductCodeNew = () => {
    setShowProductCodeNew(false);
  };
  const onClickAddProductCode = () => {
    createProductCode({
      variables: {
        code: productCodeNew.code,
        costcenterId: productCodeNew.costcenter
          ? productCodeNew.costcenter.id
          : ID_EMPTY,
        costtypeId: productCodeNew.costtype
          ? productCodeNew.costtype.id
          : ID_EMPTY,
        description: productCodeNew.description,
      },
    });
    setShowProductCodeNew(false);
    setProductCodeNew(ProductCodeEmpty);
  };

  return (
    <>
      <HeaderPage title={t("product_numbers")} />
      <div className="containerInner">
        <Table responsive borderless className="w-auto mb-5">
          <thead>
            <tr>
              <th>{t("product_number")}</th>
              <th>{t("product_number_description")}</th>
              <th>{t("costcenter")}</th>
              <th>{t("costtype")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.productCodes?.map((productCode, index) => (
              <ProductCodeRow
                key={productCode.id}
                productCode={productCode}
                costcenters={
                  dataCostcenters?.costcenters
                    ? dataCostcenters.costcenters
                    : []
                }
                costtypes={
                  dataCosttypes?.costtypes ? dataCosttypes.costtypes : []
                }
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                {hasPermissionProductRowAdd && !showProductCodeNew && (
                  <Button
                    onClick={onClickShowProductCodeNew}
                    className="btnRoundSmall"
                  >
                    <AddIcon />
                  </Button>
                )}
              </td>
            </tr>
            <tr>
              <td>
                {showProductCodeNew && (
                  <FormControl fullWidth>
                    <TextField
                      value={productCodeNew.code}
                      onChange={(event) => {
                        setProductCodeNew({
                          ...productCodeNew,
                          code: event.target.value,
                        });
                      }}
                      inputProps={{ maxLength: 32 }}
                      onBlur={() => {
                        setProductCodeNew({
                          ...productCodeNew,
                          code: productCodeNew.code,
                        });
                      }}
                    />
                  </FormControl>
                )}
              </td>
              <td>
                {showProductCodeNew && (
                  <FormControl fullWidth>
                    <TextField
                      value={productCodeNew.description}
                      onChange={(event) => {
                        setProductCodeNew({
                          ...productCodeNew,
                          description: event.target.value,
                        });
                      }}
                      inputProps={{ maxLength: 200 }}
                      onBlur={() => {
                        setProductCodeNew({
                          ...productCodeNew,
                          description: productCodeNew.description,
                        });
                      }}
                    />
                  </FormControl>
                )}
              </td>
              <td>
                {showProductCodeNew && (
                  <FormControl className="mb-4" fullWidth>
                    <InputLabel id="lblProductCodeNewCostcenter">
                      {t("costcenter")}
                    </InputLabel>
                    <Select
                      autoWidth
                      labelId="lblProductCodeNewCostcenter"
                      value={
                        productCodeNew.costcenter
                          ? productCodeNew.costcenter.id
                          : ID_EMPTY
                      }
                      onChange={(event) => {
                        const costcenterNew = productCodeNew.costcenter
                          ? productCodeNew.costcenter
                          : CostcenterEmpty;
                        setProductCodeNew({
                          ...productCodeNew,
                          costcenter: {
                            ...costcenterNew,
                            id: String(event.target.value),
                          },
                        });
                      }}
                    >
                      <MenuItem value={ID_EMPTY}>
                        {t("costcenter_from_location")}
                      </MenuItem>
                      {dataCostcenters.costcenters?.map((costcenter) => (
                        <MenuItem key={costcenter.id} value={costcenter.id}>
                          {costcenter.number} - {costcenter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </td>
              <td>
                {showProductCodeNew && (
                  <FormControl className="mb-4" fullWidth>
                    <InputLabel id="lblProductCodeNewCosttype">
                      {t("costtype")}
                    </InputLabel>
                    <Select
                      autoWidth
                      labelId="lblProductCodeNewCosttype"
                      value={
                        productCodeNew.costtype
                          ? productCodeNew.costtype.id
                          : ID_EMPTY
                      }
                      onChange={(event) => {
                        const costtypeNew = productCodeNew.costtype
                          ? productCodeNew.costtype
                          : CosttypeEmpty;
                        setProductCodeNew({
                          ...productCodeNew,
                          costtype: {
                            ...costtypeNew,
                            id: String(event.target.value),
                          },
                        });
                      }}
                    >
                      <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
                      {dataCosttypes.costtypes?.map((costtype) => (
                        <MenuItem key={costtype.id} value={costtype.id}>
                          {costtype.number} - {costtype.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </td>
              <td>
                {hasPermissionProductRowAdd && showProductCodeNew && (
                  <ButtonLoad
                    loading={loadingCreate}
                    onClick={onClickAddProductCode}
                    variant="primary"
                    size="sm"
                    className={classes.btn}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </ButtonLoad>
                )}
              </td>
              <td>
                {hasPermissionProductRowAdd && showProductCodeNew && (
                  <Button
                    variant="light"
                    size="sm"
                    className={classes.btn}
                    onClick={onClickHideProductCodeNew}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                )}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
  });

export default withStyles(styles)(ProductCodeList);
