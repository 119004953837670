import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, TextField, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  Mutation,
  MutationCreateMachineBreakdownCommentArgs,
  MutationUpdateMachineBreakdownArgs,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCommentAlt,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import format from "date-fns/format";
import { GET_MACHINE_BREAKDOWNS_UNFIXED_QUERY } from "../../apollo/queries/machines";
import {
  CREATE_MACHINE_BREAKDOWN_COMMENT_MUTATION,
  UPDATE_MACHINE_BREAKDOWN_MUTATION,
} from "../../apollo/mutations/machines";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { dateDiffInDays, newDate } from "../../utils/dates";
import { getMachineName } from "../../utils/machines/machine";
import { dialogConfirm } from "../../utils/dialogs";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  machineBreakdown: MachineBreakdownType;
  reservationNext: ReservationType;
}

function WorkQueueCardBreakdown({
  classes,
  machineBreakdown,
  reservationNext,
}: Props) {
  const { t } = useTranslation();

  const [showComments, setShowComments] = useState(false);
  const [comment, setComment] = useState("");

  const [updateMachineBreakdown, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineBreakdownArgs
  >(UPDATE_MACHINE_BREAKDOWN_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_BREAKDOWNS_UNFIXED_QUERY }],
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machineBreakdownsUnfixed"),
      });
    },
  });

  const [createMachineBreakdownComment, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateMachineBreakdownCommentArgs>(
      CREATE_MACHINE_BREAKDOWN_COMMENT_MUTATION,
      {
        refetchQueries: [{ query: GET_MACHINE_BREAKDOWNS_UNFIXED_QUERY }],
        onCompleted: () => {
          setComment("");
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionShowComment = checkPermission(myPermissions, [
    "machines.view_machinebreakdowncomment",
  ]);
  const hasPermissionAddComment = checkPermission(myPermissions, [
    "machines.add_machinebreakdowncomment",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "machines.change_machinebreakdown",
  ]);

  const onClickFixed = () => {
    dialogConfirm(t, t("confirm_maintenance_completed"), () => {
      updateMachineBreakdown({
        variables: {
          machineBreakdownId: machineBreakdown.id,
          stillRentable: machineBreakdown.stillRentable,
          title: machineBreakdown.title,
          information: machineBreakdown.information,
          fixByStart: machineBreakdown.fixByStart,
          fixByEnd: machineBreakdown.fixByEnd,
          billing: machineBreakdown.billing,
          amountBilledSingle: machineBreakdown.amountBilledSingle,
          fixedAt: machineBreakdown.fixedAt ? null : new Date(),
        },
      });
    });
  };

  const sendNewComment = () => {
    createMachineBreakdownComment({
      variables: {
        machineBreakdownId: machineBreakdown.id,
        comment: comment,
      },
    });
  };

  let daysTillReservation = 0;
  let dateRentedNext = new Date();
  let classNameReservationNext = "";
  if (reservationNext.id !== ID_EMPTY) {
    dateRentedNext = newDate(reservationNext.dateRented);
    daysTillReservation = dateDiffInDays(new Date(), dateRentedNext);

    if (daysTillReservation >= 14) {
      classNameReservationNext = "text-success";
    } else if (daysTillReservation >= 7) {
      classNameReservationNext = "text-warning";
    } else {
      classNameReservationNext = "text-danger";
    }
  }

  return (
    <div className="cardWorkQueue">
      <div className="cardWorkQueueActions">
        <div className="cardWorkQueueActionCol left">
          <ButtonLoad
            loading={loadingUpdate}
            variant={
              machineBreakdown.fixedAt
                ? "primary"
                : machineBreakdown.stillRentable
                ? "warning"
                : "danger"
            }
            title={t("fixed")}
            onClick={onClickFixed}
            disabled={!hasPermissionEdit}
          >
            <FontAwesomeIcon icon={faWrench} />
          </ButtonLoad>
        </div>
        <div className="cardWorkQueueActionCol right">
          {machineBreakdown.fixByStart === machineBreakdown.fixByEnd
            ? format(newDate(machineBreakdown.fixByStart), t("format_date"))
            : t("date_range", {
                start: format(
                  newDate(machineBreakdown.fixByStart),
                  t("format_date")
                ),
                end: format(
                  newDate(machineBreakdown.fixByEnd),
                  t("format_date")
                ),
              })}
          {reservationNext.id !== ID_EMPTY && (
            <div className={classNameReservationNext}>
              {t("next_reservation_at", {
                date: format(dateRentedNext, t("format_date")),
              })}
            </div>
          )}
        </div>
      </div>
      {machineBreakdown.machine && (
        <span className="text-muted">
          {machineBreakdown.machine.identifier}
        </span>
      )}
      {machineBreakdown.machine && (
        <b className="ms-2">{getMachineName(machineBreakdown.machine)}</b>
      )}
      {machineBreakdown.catalogExtraRowRental && (
        <b>{machineBreakdown.catalogExtraRowRental.name}</b>
      )}
      <span className="ms-2">{machineBreakdown.title}</span>
      <div className="text-muted mt-1">{machineBreakdown.information}</div>
      {hasPermissionShowComment &&
        (hasPermissionAddComment ||
          machineBreakdown.machinebreakdowncommentSet.length > 0) && (
          <div className="cardWorkQueueBottom">
            <Button
              variant={
                machineBreakdown.machinebreakdowncommentSet.length > 0
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => setShowComments(!showComments)}
            >
              {machineBreakdown.machinebreakdowncommentSet.length}
              <FontAwesomeIcon className="ms-2" icon={faCommentAlt} />
              <FontAwesomeIcon
                className="ms-2"
                icon={showComments ? faChevronUp : faChevronDown}
              />
            </Button>
            {showComments && (
              <div className="cardWorkQueueComments">
                {machineBreakdown.machinebreakdowncommentSet.map(
                  (machineBreakdownComment) => (
                    <div
                      key={machineBreakdownComment.id}
                      className="cardWorkQueueComment"
                    >
                      <div className="cardWorkQueueCommentUser">
                        {machineBreakdownComment.createdBy && (
                          <span className="user me-2">
                            {machineBreakdownComment.createdBy.lastName +
                              " " +
                              machineBreakdownComment.createdBy.firstName}
                          </span>
                        )}
                        <span className="text-muted">
                          {format(
                            newDate(machineBreakdownComment.createdAt),
                            t("format_datetime")
                          )}
                        </span>
                      </div>
                      {machineBreakdownComment.comment}
                    </div>
                  )
                )}
                {hasPermissionAddComment && (
                  <div className="cardWorkQueueComment new">
                    <TextField
                      label={t("new_reservation_comment")}
                      fullWidth
                      value={comment}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <ButtonLoad
                            loading={loadingCreate}
                            variant="light"
                            onClick={sendNewComment}
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </ButtonLoad>
                        ),
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(event) =>
                        setComment(event.currentTarget.value)
                      }
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          sendNewComment();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueCardBreakdown);
