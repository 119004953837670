import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { Autocomplete } from "@material-ui/lab";
import {
  QueryReservationsSearchArgs,
  ReservationType,
} from "../../entity/types";
import { useLazyQuery } from "@apollo/client";

import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { handleError } from "../../entity/ErrorHandler";
import {
  QueryResultReservationsSearch,
  RESERVATIONS_SEARCH_QUERY,
} from "../../apollo/queries/reservations";
import { getReservationProductName } from "../../utils/reservations/reservation";
import { ReservationEmpty } from "../../entity/empties";
import ClearIcon from "@material-ui/icons/Clear";
import DialogOrderId from "../Customer/DialogOrderId";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  setShowTitle: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchOrder = ({ classes, setShowTitle }: Props) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [openDialogOrder, setOpenDialogOrder] = useState(false);
  const [reservationSelected, setReservationSelected] =
    useState(ReservationEmpty);

  const [
    searchReservations,
    { data: dataReservations, loading: loadingReservations },
  ] = useLazyQuery<QueryResultReservationsSearch, QueryReservationsSearchArgs>(
    RESERVATIONS_SEARCH_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("reservationsSearch"),
      variables: {
        search: search,
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  useEffect(() => {
    if (!openDialogOrder && reservationSelected.id !== ID_EMPTY) {
      setReservationSelected(ReservationEmpty);
    }
    // eslint-disable-next-line
  }, [openDialogOrder]);

  const updateTransportAddresses = (searchNew: string) => {
    setSearch(searchNew);
    searchReservations({
      variables: {
        search: searchNew,
      },
    });
  };

  const reservations: ReservationType[] = dataReservations?.reservationsSearch
    ? dataReservations.reservationsSearch
    : [];

  return (
    <div className={classes.container}>
      <FormControl
        className={`me-2 ${classes.autocomplete} ${
          showSearch ? classes.autocompleteVisible : classes.autocompleteHidden
        }`}
      >
        <Autocomplete
          fullWidth={true}
          options={reservations}
          getOptionLabel={(reservationLooped: ReservationType) =>
            getReservationProductName(t, reservationLooped, true) +
            (reservationLooped.order.customer &&
            reservationLooped.order.customer.id !== ID_EMPTY
              ? " " + reservationLooped.order.customer.name
              : "") +
            (reservationLooped.id === search
              ? " " + t("reservation_id", { id: reservationLooped.id })
              : "") +
            (reservationLooped.order.id === search
              ? " " + t("order_id", { id: reservationLooped.order.id })
              : "")
          }
          /* Disable filtering in frontend */
          filterOptions={(options) => options}
          renderInput={(params) => (
            <TextField
              {...params}
              className="m-0"
              label={t("reservation_search")}
              variant="standard"
            />
          )}
          value={null}
          getOptionSelected={(a, b) => {
            if (!a || !b) {
              return false;
            }
            return a.id === b.id;
          }}
          onKeyUp={(event) => {
            const target = event.target as HTMLInputElement;
            updateTransportAddresses(target.value);
          }}
          onBlur={() => {
            updateTransportAddresses("");
          }}
          onChange={(event, optionSelected) => {
            if (optionSelected) {
              setReservationSelected(optionSelected as ReservationType);
              setOpenDialogOrder(true);
            } else {
              setReservationSelected(ReservationEmpty);
              setOpenDialogOrder(false);
            }
          }}
        />
      </FormControl>
      <Button
        onClick={() => {
          setShowSearch(!showSearch);
          setShowTitle(showSearch);
        }}
        className={`btnRoundSmall ${loadingReservations ? "loading" : ""}`}
      >
        {showSearch ? <ClearIcon /> : <FontAwesomeIcon icon={faSearch} />}
      </Button>
      {openDialogOrder && (
        <DialogOrderId
          orderId={reservationSelected.order.id}
          reservationIdSelected={reservationSelected.id}
          open={openDialogOrder}
          setOpen={setOpenDialogOrder}
        />
      )}
    </div>
  );
};

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    container: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    autocomplete: {
      transition: "width 1s",
      overflow: "hidden",
    },
    autocompleteVisible: {
      width: "25rem",
      [breakpoints.down("sm")]: {
        width: "17rem",
      },
    },
    autocompleteHidden: {
      width: "0",
    },
  });

export default withStyles(styles)(SearchOrder);
