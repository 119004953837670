import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { CustomerIndustryType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  customerIndustry: CustomerIndustryType;
  setCustomerIndustry: React.Dispatch<
    React.SetStateAction<CustomerIndustryType>
  >;
}

function DialogContentCustomerIndustry({
  classes,
  customerIndustry,
  setCustomerIndustry,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "customers.add_customerindustry",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customerindustry",
  ]);
  const disabled =
    customerIndustry.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("title")}
          onChange={(event) => {
            setCustomerIndustry({
              ...customerIndustry,
              title: event.target.value,
            });
          }}
          value={customerIndustry.title}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCustomerIndustry);
