import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_INSPECTION_RETURN_CATEGORY_MUTATION } from "../../apollo/mutations/inspections";
import {
  InspectionReturnCategoryType,
  Mutation,
  MutationCreateInspectionReturnCategoryArgs,
} from "../../entity/types";
import { InspectionReturnCategoryEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogContentInspectionReturnCategory from "./DialogContentInspectionReturnCategory";
import { WithStyles } from "@material-ui/core/styles";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {}

function CreateInspectionReturnCategory({ classes }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [inspectionReturnCategory, setInspectionReturnCategory] =
    useState<InspectionReturnCategoryType>(InspectionReturnCategoryEmpty);

  const [createInspectionReturnCategory, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateInspectionReturnCategoryArgs>(
      CREATE_INSPECTION_RETURN_CATEGORY_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
        onCompleted: (result) => {
          setInspectionReturnCategory(InspectionReturnCategoryEmpty);
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "inspections.add_inspectionreturncategory",
  ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createInspectionReturnCategory({
      variables: {
        name: inspectionReturnCategory.name,
      },
    });
    setOpen(false);
  };

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_inspection_category")}</DialogTitle>
          <DialogContentInspectionReturnCategory
            inspectionReturnCategory={inspectionReturnCategory}
            setInspectionReturnCategory={setInspectionReturnCategory}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <ButtonLoad
              loading={loadingCreate}
              disabled={!inspectionReturnCategory.name.trim()}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </ButtonLoad>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateInspectionReturnCategory);
