import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CustomerType, ReservationType } from "../../entity/types";
import ChartCustomerReservations from "./ChartCustomerReservations";
import MaterialTableReservations from "../Reservation/MaterialTableReservations";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
}

const CustomerShowReservations = ({ classes, customer }: Props) => {
  const reservations: ReservationType[] = [];
  customer.orderSet.forEach((order) => {
    order.reservationSet.forEach((reservation) => {
      reservations.push({ ...reservation, order: order });
    });
  });

  return (
    <div>
      <ChartCustomerReservations customer={customer} />
      <MaterialTableReservations reservations={reservations} />
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerShowReservations);
