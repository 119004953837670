import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, createStyles, Tab, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import HeaderPage from "../components/Shared/HeaderPage";
import DimensionsCostcenters from "../components/Dimensions/DimensionsCostcenters";
import { PermissionsContext } from "../Root";
import { checkPermission } from "../utils/permissions";
import DimensionsCosttypes from "../components/Dimensions/DimensionsCosttypes";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import DimensionsSet from "../components/Dimensions/DimensionsSet";

interface Props extends WithStyles<typeof styles> {}

function SettingsDimensions({ classes }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState("0");

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionCostcenterView = checkPermission(myPermissions, [
    "costcenters.view_costcenter",
  ]);
  const hasPermissionCosttypeView = checkPermission(myPermissions, [
    "costtypes.view_costtype",
  ]);

  return (
    <Container className="containerMain">
      <HeaderPage title={t("dimensions")} />
      <div className="containerInner">
        <TabContext value={tabSelected}>
          <Box>
            <TabList onChange={(event, newTab) => setTabSelected(newTab)}>
              <Tab label={t("tab_dimensions_set")} value="0" />
              <Tab label={t("tab_dimensions_create")} value="1" />
            </TabList>
          </Box>
          <TabPanel className={classes.panel} value="0">
            <DimensionsSet />
          </TabPanel>
          <TabPanel className={classes.panel} value="1">
            <Row>
              {hasPermissionCostcenterView && (
                <Col>
                  <DimensionsCostcenters />
                </Col>
              )}
              {hasPermissionCosttypeView && (
                <Col>
                  <DimensionsCosttypes />
                </Col>
              )}
            </Row>
          </TabPanel>
        </TabContext>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    panel: {
      marginTop: "2rem",
    },
  });

export default withStyles(styles)(SettingsDimensions);
