import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION } from "../../apollo/mutations/users";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentUser from "./DialogContentUser";
import {
  GroupType,
  LocationType,
  Mutation,
  MutationCreateUserArgs,
  UserType,
} from "../../entity/types";
import { UserEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { hasUserRequired } from "../../utils/users/user";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  groups: GroupType[];
  locations: LocationType[];
}

function CreateUser({ classes, groups, locations }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<UserType>(UserEmpty);

  const [createUser, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateUserArgs
  >(CREATE_USER_MUTATION, {
    onCompleted: (result) => {
      setUser(UserEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("users"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, ["auth.add_user"]);

  if (!hasPermissionAdd) {
    return <></>;
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createUser({
      variables: {
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        email: user.email,
        password: user.password,
        phone: user.UserInfo?.phone,
        address: user.UserInfo?.address,
        postcode: user.UserInfo?.postcode,
        district: user.UserInfo?.district,
        language: user.UserInfo?.language.toLowerCase(),
        groupId: user.groups[0] ? user.groups[0].id : ID_EMPTY,
        locationId: user.UserInfo?.location
          ? user.UserInfo?.location.id
          : undefined,
      },
    });
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form
          className={classes.form}
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle>{t("new_user")}</DialogTitle>
          <DialogContentUser
            user={user}
            setUser={setUser}
            groups={groups}
            locations={locations}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <ButtonLoad
              loading={loadingCreate}
              disabled={!hasUserRequired(user)}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </ButtonLoad>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
    textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });

export default withStyles(styles)(CreateUser);
