import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { QueryReferencesByIdsArgs } from "../../entity/types";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { parseNumber } from "../../utils/formatting";
import ManagementInvoicesOpenReference from "../Management/ManagementInvoicesOpenReference";
import { InvoiceableRowsByReferenceType } from "./InvoicesCreateListReferences";
import { findFromSetById } from "../../utils/collections";
import { ReferenceEmpty } from "../../entity/empties";
import {
  GET_REFERENCES_BY_IDS_QUERY,
  QueryResultReferencesByIds,
} from "../../apollo/queries/references";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { SettingsContext } from "../../Root";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

interface Props extends WithStyles<typeof styles> {
  invoiceableRowsByReference: InvoiceableRowsByReferenceType;
  hideSurchargeInvoices: boolean;
  isAdvance?: Boolean;
  dateTo: Date;
  fetchAllRowsForReference?: (referenceId: string) => void;
  className?: string;
  referenceIdsAllFetched?: string[];
}

function InvoiceableRows({
  classes,
  invoiceableRowsByReference,
  hideSurchargeInvoices,
  isAdvance,
  dateTo,
  fetchAllRowsForReference,
  className,
  referenceIdsAllFetched,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const referenceIds: string[] = Object.keys(invoiceableRowsByReference);

  const {
    loading: loadingReferences,
    error: errorReferences,
    data: dataReferences,
  } = useQuery<QueryResultReferencesByIds, QueryReferencesByIdsArgs>(
    GET_REFERENCES_BY_IDS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("referencesByIds"),
      skip: referenceIds.length === 0,
      variables: { referenceIds: referenceIds },
    }
  );

  if (loadingReferences) return <LoadingSimple />;
  if (errorReferences) return <Error error={errorReferences} />;

  return (
    <div>
      {Object.entries(invoiceableRowsByReference).map(
        ([referenceId, invoiceableRows]) => {
          if (hideSurchargeInvoices) {
            const total = invoiceableRows.reduce(
              (sum, row) => sum + parseNumber(row.price),
              0
            );
            if (total < settings.billingSurchargeLimit) return null;
          }

          const reference = findFromSetById(
            referenceId,
            dataReferences?.referencesByIds
              ? dataReferences.referencesByIds
              : [],
            ReferenceEmpty
          );

          return (
            <div className={className} key={referenceId}>
              {fetchAllRowsForReference &&
                !referenceIdsAllFetched?.includes(referenceId) && (
                  <Button
                    className="mb-2"
                    variant="light"
                    onClick={() => fetchAllRowsForReference(referenceId)}
                  >
                    {t("fetch_all_rows_for_reference")}
                  </Button>
                )}
              <ManagementInvoicesOpenReference
                reference={reference}
                invoiceableRows={invoiceableRows}
                isAdvance={Boolean(isAdvance)}
                dateTo={dateTo}
              />
            </div>
          );
        }
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoiceableRows);
