import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import DialogOrderId from "../Customer/DialogOrderId";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  orderId: string;
  reservationId?: string;
  buttonText?: string;
  className?: string;
}

function ButtonOrderDialog({
  classes,
  orderId,
  reservationId,
  buttonText,
  className,
}: Props) {
  const [openDialog, setOpenDialog] = useState(false);

  const onClick = () => {
    setOpenDialog(true);
  };

  const hasReservationId = reservationId && reservationId !== ID_EMPTY;
  const buttonTextDefault = "#" + (hasReservationId ? reservationId : orderId);
  const text = buttonText ? buttonText : buttonTextDefault;

  return (
    <>
      <Button className={className} variant="light" size="sm" onClick={onClick}>
        {text}
      </Button>
      {openDialog && (
        <DialogOrderId
          orderId={orderId}
          open={openDialog}
          setOpen={setOpenDialog}
          reservationIdSelected={hasReservationId ? reservationId : ID_EMPTY}
        />
      )}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonOrderDialog);
