import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { ID_EMPTY } from "../../utils/constants";
import { useQuery } from "@apollo/client";
import {
  GET_USERS_SIMPLE_QUERY,
  QueryResultUsersSimple,
} from "../../apollo/queries/users";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import {
  GET_CUSTOMER_AREAS_QUERY,
  GET_CUSTOMER_INDUSTRIES_QUERY,
  GET_CUSTOMER_RANKS_QUERY,
  QueryResultCustomerAreas,
  QueryResultCustomerIndustries,
  QueryResultCustomerRanks,
} from "../../apollo/queries/customers";
import { getValuesUsingAllOption } from "../../utils/collections";
import {
  CustomerListFilterDefaults,
  CustomerListFiltersType,
} from "../../utils/customers/customer";

interface Props extends WithStyles<typeof styles> {
  filters: CustomerListFiltersType;
  setFilters: React.Dispatch<React.SetStateAction<CustomerListFiltersType>>;
}

const CustomerListFilters = ({ classes, filters, setFilters }: Props) => {
  const { t } = useTranslation();

  const [filtersEdited, setFiltersEdited] = useState(filters);

  const USER_OWNER_ID_EMPTY = "-1";

  useEffect(() => {
    setFiltersEdited(filters);
  }, [filters]);

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery<QueryResultUsersSimple>(GET_USERS_SIMPLE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const {
    loading: loadingIndustries,
    error: errorIndustries,
    data: dataIndustries,
  } = useQuery<QueryResultCustomerIndustries>(GET_CUSTOMER_INDUSTRIES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customerIndustries"),
  });

  const {
    loading: loadingAreas,
    error: errorAreas,
    data: dataAreas,
  } = useQuery<QueryResultCustomerAreas>(GET_CUSTOMER_AREAS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customerAreas"),
  });

  const {
    loading: loadingRanks,
    error: errorRanks,
    data: dataRanks,
  } = useQuery<QueryResultCustomerRanks>(GET_CUSTOMER_RANKS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customerRanks"),
  });

  if (loadingUsers || loadingIndustries || loadingAreas || loadingRanks) {
    return <LoadingSimple />;
  }
  if (errorUsers) return <Error error={errorUsers} />;
  if (errorIndustries) return <Error error={errorIndustries} />;
  if (errorAreas) return <Error error={errorAreas} />;
  if (errorRanks) return <Error error={errorRanks} />;
  if (!dataUsers || !dataIndustries || !dataAreas || !dataRanks) {
    return <Error error={t("error_query_failed")} />;
  }

  const onClickReset = () => {
    setFilters(CustomerListFilterDefaults);
  };

  return (
    <Row>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblCustomerOwner">{t("customer_owner")}</InputLabel>
          <Select
            autoWidth
            labelId="lblCustomerOwner"
            value={filtersEdited.userIdsSelected}
            multiple
            onChange={(event) => {
              const valNew = getValuesUsingAllOption(
                event.target.value as string[],
                filtersEdited.userIdsSelected
              );
              setFiltersEdited({ ...filtersEdited, userIdsSelected: valNew });
            }}
            onClose={() =>
              setFilters({
                ...filtersEdited,
                userIdsSelected: filtersEdited.userIdsSelected,
              })
            }
          >
            <MenuItem value={ID_EMPTY}>{t("all")}</MenuItem>
            <MenuItem value={USER_OWNER_ID_EMPTY}>
              {t("no_customer_owner")}
            </MenuItem>
            {dataUsers.users?.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.lastName + " " + user.firstName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblCustomerPrivateOrCompany">
            {t("customer_type")}
          </InputLabel>
          <Select
            autoWidth
            labelId="lblCustomerPrivateOrCompany"
            value={filtersEdited.isBusiness}
            multiple
            onChange={(event) => {
              setFiltersEdited({
                ...filtersEdited,
                isBusiness: event.target.value as string[],
              });
            }}
            onClose={() =>
              setFilters({
                ...filtersEdited,
                isBusiness: filtersEdited.isBusiness,
              })
            }
          >
            <MenuItem value="1">{t("company")}</MenuItem>
            <MenuItem value="0">{t("private")}</MenuItem>
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblIndustries">{t("customer_industry")}</InputLabel>
          <Select
            autoWidth
            labelId="lblIndustries"
            value={filtersEdited.customerIndustryIds}
            multiple
            onChange={(event) => {
              const valNew = getValuesUsingAllOption(
                event.target.value as string[],
                filtersEdited.customerIndustryIds
              );
              setFiltersEdited({
                ...filtersEdited,
                customerIndustryIds: valNew,
              });
            }}
            onClose={() =>
              setFilters({
                ...filtersEdited,
                customerIndustryIds: filtersEdited.customerIndustryIds,
              })
            }
          >
            <MenuItem value={ID_EMPTY}>{t("all")}</MenuItem>
            {dataIndustries.customerIndustries.map((customerIndustry) => (
              <MenuItem key={customerIndustry.id} value={customerIndustry.id}>
                {customerIndustry.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblCustomerArea">{t("customer_area")}</InputLabel>
          <Select
            autoWidth
            labelId="lblCustomerArea"
            value={filtersEdited.customerAreaIds}
            multiple
            onChange={(event) => {
              const valNew = getValuesUsingAllOption(
                event.target.value as string[],
                filtersEdited.customerAreaIds
              );
              setFiltersEdited({ ...filtersEdited, customerAreaIds: valNew });
            }}
            onClose={() =>
              setFilters({
                ...filtersEdited,
                customerAreaIds: filtersEdited.customerAreaIds,
              })
            }
          >
            <MenuItem value={ID_EMPTY}>{t("all")}</MenuItem>
            {dataAreas.customerAreas.map((customerArea) => (
              <MenuItem key={customerArea.id} value={customerArea.id}>
                {customerArea.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        <FormControl fullWidth>
          <InputLabel id="lblCustomerRank">{t("customer_rank")}</InputLabel>
          <Select
            autoWidth
            labelId="lblCustomerRank"
            value={filtersEdited.customerRankIds}
            multiple
            onChange={(event) => {
              const valNew = getValuesUsingAllOption(
                event.target.value as string[],
                filtersEdited.customerRankIds
              );
              setFiltersEdited({ ...filtersEdited, customerRankIds: valNew });
            }}
            onClose={() =>
              setFilters({
                ...filtersEdited,
                customerRankIds: filtersEdited.customerRankIds,
              })
            }
          >
            <MenuItem value={ID_EMPTY}>{t("all")}</MenuItem>
            {dataRanks.customerRanks.map((customerRank) => (
              <MenuItem key={customerRank.id} value={customerRank.id}>
                {customerRank.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Col>
      <Col className="mb-3" sm={6} md={4} lg={4} xl={3}>
        {filters.canQuery && (
          <Button className="mt-2" onClick={onClickReset}>
            {t("reset_search")}
          </Button>
        )}
        {!filters.canQuery && (
          <Button
            className="mt-2"
            onClick={() =>
              setFilters({ ...filters, canQuery: !filters.canQuery })
            }
          >
            {t("search")}
          </Button>
        )}
      </Col>
    </Row>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerListFilters);
