import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import {
  CustomerType,
  DiscountListType,
  EventOfferCatalogType,
  EventOfferDiscountListType,
  EventType,
  OrderType,
  ReferenceType,
} from "../../entity/types";
import MaterialTable, { Column } from "material-table";
import {
  getDefaultSortEvents,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  setDefaultSortEvents,
  SortingDirectionType,
  SortingType,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import { useHistory } from "react-router-dom";
import { getUrlOffer } from "../../utils/urls";
import { ID_EMPTY } from "../../utils/constants";
import { isArray } from "@apollo/client/cache/inmemory/helpers";
import ButtonOrderDialog from "../Shared/ButtonOrderDialog";

export const defaultSortField = "date";
export const defaultSortDirection = "desc";

enum EventObjectTypes {
  Event,
  DiscountList,
  EventOfferCatalog,
  EventOfferDiscountList,
  Reference,
  Order,
}

type EventListEventType = {
  object:
    | EventType
    | DiscountListType
    | EventOfferCatalogType
    | EventOfferDiscountListType
    | ReferenceType
    | OrderType;
  objectType: EventObjectTypes;
  date: string;
  createdBy: string;
  typeName: string;
  information: string;
  orderId: string;
  createdAt: string;
};

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  events: EventType[];
  eventsOfferCatalog: EventOfferCatalogType[];
  eventsOfferDiscountList: EventOfferDiscountListType[];
  discountLists: DiscountListType[];
  references: ReferenceType[];
  orders: OrderType[];
}

const CustomerShowEventsTable = ({
  classes,
  customer,
  events,
  eventsOfferCatalog,
  eventsOfferDiscountList,
  discountLists,
  references,
  orders,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [objectTypesSelected, setObjectTypesSelected] = useState<
    EventObjectTypes[]
  >([EventObjectTypes.Event, EventObjectTypes.Order]);
  const [sorting, setSorting] = useState<SortingType>(getDefaultSortEvents());

  const data: EventListEventType[] = [];

  events.forEach((event) => {
    data.push({
      object: event,
      objectType: EventObjectTypes.Event,
      date: event.date,
      createdBy: event.createdBy
        ? event.createdBy.lastName + " " + event.createdBy.firstName
        : "",
      typeName: t("event_type_" + event.eventType),
      information: event.information,
      orderId: ID_EMPTY,
      createdAt: event.createdAt,
    });
  });

  eventsOfferCatalog.forEach((eventOfferCatalog) => {
    data.push({
      object: eventOfferCatalog,
      objectType: EventObjectTypes.EventOfferCatalog,
      date: eventOfferCatalog.createdAt,
      createdBy: eventOfferCatalog.createdBy
        ? eventOfferCatalog.createdBy.lastName +
          " " +
          eventOfferCatalog.createdBy.firstName
        : "",
      typeName: t("offer"),
      information: eventOfferCatalog.catalog.name,
      orderId: ID_EMPTY,
      createdAt: eventOfferCatalog.createdAt,
    });
  });

  eventsOfferDiscountList.forEach((eventOfferDiscountList) => {
    data.push({
      object: eventOfferDiscountList,
      objectType: EventObjectTypes.EventOfferDiscountList,
      date: eventOfferDiscountList.createdAt,
      createdBy: eventOfferDiscountList.createdBy
        ? eventOfferDiscountList.createdBy.lastName +
          " " +
          eventOfferDiscountList.createdBy.firstName
        : "",
      typeName: t("offer"),
      information: eventOfferDiscountList.discountList.name,
      orderId: ID_EMPTY,
      createdAt: eventOfferDiscountList.createdAt,
    });
  });

  discountLists.forEach((discountList) => {
    data.push({
      object: discountList,
      objectType: EventObjectTypes.DiscountList,
      date: discountList.createdAt,
      createdBy: discountList.createdBy
        ? discountList.createdBy.lastName +
          " " +
          discountList.createdBy.firstName
        : "",
      typeName: t("offer"),
      information: discountList.name,
      orderId: ID_EMPTY,
      createdAt: discountList.createdAt,
    });
  });

  references.forEach((reference) => {
    data.push({
      object: reference,
      objectType: EventObjectTypes.Reference,
      date: reference.createdAt,
      createdBy: "",
      typeName: t("reference"),
      information: reference.name,
      orderId: ID_EMPTY,
      createdAt: reference.createdAt,
    });
  });

  orders.forEach((order) => {
    data.push({
      object: order,
      objectType: EventObjectTypes.Order,
      date: order.createdAt,
      createdBy: order.createdBy
        ? order.createdBy.lastName + " " + order.createdBy.firstName
        : "",
      typeName: t("order"),
      information: order.information,
      orderId: order.id,
      createdAt: order.createdAt,
    });
  });

  const getUrlOfferFromObject = (rowData: EventListEventType) => {
    let url = "";

    if (rowData.object.__typename === "DiscountListType") {
      let discountList: DiscountListType = rowData.object;
      url = getUrlOffer(customer.id, discountList.id, ID_EMPTY);
    } else if (rowData.object.__typename === "EventOfferCatalogType") {
      let eventOfferCatalog: EventOfferCatalogType = rowData.object;
      url = getUrlOffer(customer.id, ID_EMPTY, eventOfferCatalog.catalog.id);
    } else if (rowData.object.__typename === "EventOfferDiscountListType") {
      let eventOfferDiscountList: EventOfferDiscountListType = rowData.object;
      url = getUrlOffer(
        customer.id,
        eventOfferDiscountList.discountList.id,
        ID_EMPTY
      );
    }

    return url;
  };

  const columns: Column<EventListEventType>[] = [
    {
      title: t("date"),
      field: "date",
      type: "date",
      defaultSort:
        sorting.field === "date" ? sorting.orderDirection : undefined,
    },
    {
      title: t("type"),
      field: "typeName",
      defaultSort:
        sorting.field === "typeName" ? sorting.orderDirection : undefined,
    },
    {
      title: t("user"),
      field: "createdBy",
      defaultSort:
        sorting.field === "createdBy" ? sorting.orderDirection : undefined,
    },
    {
      title: t("information"),
      field: "information",
      render: (rowData) => {
        if (rowData.orderId !== ID_EMPTY) {
          return (
            <>
              <ButtonOrderDialog className="me-1" orderId={rowData.orderId} />
              {rowData.information}
            </>
          );
        } else {
          return rowData.information;
        }
      },
      defaultSort:
        sorting.field === "information" ? sorting.orderDirection : undefined,
    },
    {
      title: t("created_at"),
      field: "createdAt",
      type: "datetime",
      defaultSort:
        sorting.field === "createdAt" ? sorting.orderDirection : undefined,
    },
  ];

  const handleOrderChange = (
    orderBy: number,
    orderDirection: "asc" | "desc" | ""
  ) => {
    const field = columns[orderBy]
      ? String(columns[orderBy].field)
      : defaultSortField;
    const orderDirectionNew: SortingDirectionType =
      orderDirection === "" ? defaultSortDirection : orderDirection;

    setSorting({
      field: field,
      orderDirection: orderDirectionNew,
    });

    setDefaultSortEvents(field, orderDirectionNew);
  };

  return (
    <>
      <FormControl className={classes.filter} fullWidth>
        <InputLabel id="lblEventsListObjectTypes">
          {t("event_object_types")}
        </InputLabel>
        <Select
          labelId="lblEventsListObjectTypes"
          value={objectTypesSelected}
          multiple
          onChange={(event) => {
            setObjectTypesSelected(event.target.value as EventObjectTypes[]);
          }}
        >
          {Object.keys(EventObjectTypes)
            .filter((key: any) => isNaN(key))
            .map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={EventObjectTypes[key as keyof typeof EventObjectTypes]}
                >
                  {t("event_object_type_" + key)}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <MaterialTable<EventListEventType>
        style={getTableStyle()}
        title={""}
        icons={tableIcons}
        localization={GetTableLocalization(t)}
        columns={columns}
        data={data.filter((obj) =>
          objectTypesSelected.includes(obj.objectType)
        )}
        actions={[
          (rowData) => ({
            icon: () => <FontAwesomeIcon icon={faFile} />,
            tooltip: t("offer"),
            onClick: (event, rowData) => {
              if (!isArray(rowData)) {
                history.push(getUrlOfferFromObject(rowData));
              }
            },
            hidden: ![
              EventObjectTypes.DiscountList,
              EventObjectTypes.EventOfferCatalog,
              EventObjectTypes.EventOfferDiscountList,
            ].includes(rowData.objectType),
          }),
        ]}
        options={getTableOptions()}
        onOrderChange={handleOrderChange}
      />
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    filter: {
      maxWidth: "40rem",
    },
  });

export default withStyles(styles)(CustomerShowEventsTable);
