export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Decimal: any;
  GenericScalar: any;
  Time: any;
  Upload: any;
};

export type AddInspectionGiveTagToMachine = {
  __typename?: 'AddInspectionGiveTagToMachine';
  machineId?: Maybe<Scalars['ID']>;
};

export type AddInspectionGiveTagToQuestion = {
  __typename?: 'AddInspectionGiveTagToQuestion';
  inspectionGiveTagId?: Maybe<Scalars['ID']>;
};

export type AddInspectionReturnTagToMachine = {
  __typename?: 'AddInspectionReturnTagToMachine';
  machineId?: Maybe<Scalars['ID']>;
};

export type AddInspectionReturnTagToQuestion = {
  __typename?: 'AddInspectionReturnTagToQuestion';
  inspectionReturnTagId?: Maybe<Scalars['ID']>;
};

export type AddMachineInfoTagToMachine = {
  __typename?: 'AddMachineInfoTagToMachine';
  machineId?: Maybe<Scalars['ID']>;
};

export type AddMachineInfoTagToQuestion = {
  __typename?: 'AddMachineInfoTagToQuestion';
  machineInfoTagId?: Maybe<Scalars['ID']>;
};

export type BulkAmountDate = {
  __typename?: 'BulkAmountDate';
  amountFree?: Maybe<Scalars['Int']>;
  amountReserved?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
  machineId?: Maybe<Scalars['ID']>;
};

export type CatalogCategoryType = {
  __typename?: 'CatalogCategoryType';
  catalogCategoriesSourced: Array<CatalogCategoryType>;
  catalogCategorySource?: Maybe<CatalogCategoryType>;
  catalogCategoryUpper: CatalogCategoryUpperType;
  catalogrowSet: Array<CatalogRowType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  image: Scalars['String'];
  information: Scalars['String'];
  name: Scalars['String'];
  orderCategory: Scalars['Int'];
};

export type CatalogCategoryUpperType = {
  __typename?: 'CatalogCategoryUpperType';
  catalog: CatalogType;
  catalogCategoriesUpperSourced: Array<CatalogCategoryUpperType>;
  catalogCategoryUpperSource?: Maybe<CatalogCategoryUpperType>;
  catalogcategorySet: Array<CatalogCategoryType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  orderCategoryUpper: Scalars['Int'];
};

export type CatalogExtraCategoryRentalType = {
  __typename?: 'CatalogExtraCategoryRentalType';
  catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType;
  catalogextrarowrentalSet: Array<CatalogExtraRowRentalType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type CatalogExtraCategoryUnitPriceType = {
  __typename?: 'CatalogExtraCategoryUnitPriceType';
  catalogextrarowunitpriceSet: Array<CatalogExtraRowUnitPriceType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  printSeparately: Scalars['Boolean'];
};

export type CatalogExtraCategoryUpperRentalType = {
  __typename?: 'CatalogExtraCategoryUpperRentalType';
  catalogextracategoryrentalSet: Array<CatalogExtraCategoryRentalType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type CatalogExtraRowInvoiceStatusField = {
  __typename?: 'CatalogExtraRowInvoiceStatusField';
  addedToInvoiceAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  invoiceSentAt?: Maybe<Scalars['DateTime']>;
};

export type CatalogExtraRowInvoiceType = {
  __typename?: 'CatalogExtraRowInvoiceType';
  answer: Scalars['String'];
  catalogExtraRowUnitPrice?: Maybe<CatalogExtraRowUnitPriceType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invoiceRow?: Maybe<InvoiceRowType>;
  productCode?: Maybe<ProductCodeType>;
  quantity: Scalars['Decimal'];
  reservation: ReservationType;
  titleOverride: Scalars['String'];
  unitPriceOverride: Scalars['Decimal'];
};

export type CatalogExtraRowRentalType = {
  __typename?: 'CatalogExtraRowRentalType';
  billingDaysWeekCompany: Scalars['Int'];
  billingDaysWeekPrivate: Scalars['Int'];
  catalogExtraCategoryRental: CatalogExtraCategoryRentalType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  information: Scalars['String'];
  location: LocationType;
  machineSet: Array<MachineType>;
  machinebreakdownSet: Array<MachineBreakdownType>;
  maintenanceSet: Array<MaintenanceType>;
  maintenancesetting?: Maybe<MaintenanceSettingType>;
  name: Scalars['String'];
  order: Scalars['Int'];
  priceDayCompany: Scalars['Decimal'];
  priceDayPrivate: Scalars['Decimal'];
  priceMonthCompany: Scalars['Decimal'];
  priceMonthPrivate: Scalars['Decimal'];
  productNumber: Scalars['String'];
  reservationSet: Array<ReservationType>;
  showPrint: Scalars['Boolean'];
};

export type CatalogExtraRowUnitPriceType = {
  __typename?: 'CatalogExtraRowUnitPriceType';
  allowDiscount: Scalars['Boolean'];
  catalogExtraCategoryUnitPrice: CatalogExtraCategoryUnitPriceType;
  catalogextrarowinvoiceSet: Array<CatalogExtraRowInvoiceType>;
  costcenter?: Maybe<CostcenterType>;
  costtype?: Maybe<CosttypeType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineSet: Array<MachineType>;
  minCharge: Scalars['Int'];
  name: Scalars['String'];
  productCode: ProductCodeType;
  requireAnswer: Scalars['Boolean'];
  showPrint: Scalars['Boolean'];
  unit: Scalars['String'];
  unitPrice: Scalars['Decimal'];
};

export type CatalogRowType = {
  __typename?: 'CatalogRowType';
  billingDaysWeekCompany: Scalars['Int'];
  billingDaysWeekPrivate: Scalars['Int'];
  catalogCategory: CatalogCategoryType;
  catalogRowSource?: Maybe<CatalogRowType>;
  catalogRowsSourced: Array<CatalogRowType>;
  createdAt: Scalars['DateTime'];
  discountrowSet: Array<DiscountRowType>;
  extraHoursCompany: Scalars['Decimal'];
  extraHoursPrivate: Scalars['Decimal'];
  hourLimitDayCompany: Scalars['Int'];
  hourLimitDayPrivate: Scalars['Int'];
  hourLimitMonthCompany: Scalars['Int'];
  hourLimitMonthPrivate: Scalars['Int'];
  id: Scalars['ID'];
  image: Scalars['String'];
  information: Scalars['String'];
  machineSet: Array<MachineType>;
  name: Scalars['String'];
  orderRow: Scalars['Int'];
  priceDayCompany: Scalars['Decimal'];
  priceDayPrivate: Scalars['Decimal'];
  priceMonthCompany: Scalars['Decimal'];
  priceMonthPrivate: Scalars['Decimal'];
  productNumberDay: Scalars['String'];
  productNumberMonth: Scalars['String'];
  reservationSet: Array<ReservationType>;
};

export type CatalogTransferMethodType = {
  __typename?: 'CatalogTransferMethodType';
  catalogtransferpriceSet: Array<CatalogTransferPriceType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  transportSet: Array<TransportType>;
  transportinvoicingSet: Array<TransportInvoicingType>;
};

export type CatalogTransferPriceType = {
  __typename?: 'CatalogTransferPriceType';
  catalogTransferMethod: CatalogTransferMethodType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  location: LocationType;
  priceBasic: Scalars['Decimal'];
  priceExtra: Scalars['Decimal'];
  priceStop: Scalars['Decimal'];
  productNumberBasic: Scalars['String'];
  productNumberExtra: Scalars['String'];
  productNumberStop: Scalars['String'];
};

export type CatalogType = {
  __typename?: 'CatalogType';
  catalogSource?: Maybe<CatalogType>;
  catalogcategoryupperSet: Array<CatalogCategoryUpperType>;
  catalogsSourced: Array<CatalogType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  creationCompletedAt?: Maybe<Scalars['DateTime']>;
  customerSet: Array<CustomerType>;
  discountlistSet: Array<DiscountListType>;
  eventoffercatalogSet: Array<EventOfferCatalogType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  setActiveAt?: Maybe<Scalars['DateTime']>;
};

export type CloneDiscountList = {
  __typename?: 'CloneDiscountList';
  discountList?: Maybe<DiscountListType>;
};

export type CloneDiscountListFromCatalog = {
  __typename?: 'CloneDiscountListFromCatalog';
  discountList?: Maybe<DiscountListType>;
};

export enum CommissionCaseType {
  CaseOrderer = 'CASE_ORDERER',
  CaseOwner = 'CASE_OWNER'
}

export type CommissionLevelType = {
  __typename?: 'CommissionLevelType';
  commissionRange: CommissionRangeType;
  commissionTemplate: CommissionTemplateType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maxCommission: Scalars['Decimal'];
  maxDiscount: Scalars['Decimal'];
  minCommission: Scalars['Decimal'];
};

export type CommissionRangeType = {
  __typename?: 'CommissionRangeType';
  commissionTemplate: CommissionTemplateType;
  commissionlevelSet: Array<CommissionLevelType>;
  createdAt: Scalars['DateTime'];
  daysMax: Scalars['Int'];
  id: Scalars['ID'];
};

export type CommissionReportRowType = {
  __typename?: 'CommissionReportRowType';
  case: CommissionsCommissionReportRowCaseChoices;
  commission: Scalars['Decimal'];
  commissionPercent: Scalars['Decimal'];
  commissionReport: CommissionReportType;
  createdAt: Scalars['DateTime'];
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  discountPercent?: Maybe<Scalars['Decimal']>;
  hasCommission?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  reservation: ReservationType;
  revenue: Scalars['Decimal'];
  source: CommissionsCommissionReportRowSourceChoices;
};

export type CommissionReportType = {
  __typename?: 'CommissionReportType';
  commissionreportrowSet: Array<CommissionReportRowType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  month: Scalars['String'];
  user: UserType;
};

export type CommissionRowRentThroughType = {
  __typename?: 'CommissionRowRentThroughType';
  case?: Maybe<CommissionCaseType>;
  commission?: Maybe<Scalars['Decimal']>;
  commissionPercent?: Maybe<Scalars['Decimal']>;
  customerId?: Maybe<Scalars['ID']>;
  customerName?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  hasCommission?: Maybe<Scalars['Boolean']>;
  machineName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  reservationId?: Maybe<Scalars['ID']>;
  revenue?: Maybe<Scalars['Decimal']>;
  substituteMachine?: Maybe<Scalars['Boolean']>;
  userSellerName?: Maybe<Scalars['String']>;
};

export type CommissionRowType = {
  __typename?: 'CommissionRowType';
  case?: Maybe<CommissionCaseType>;
  commission?: Maybe<Scalars['Decimal']>;
  commissionPercent?: Maybe<Scalars['Decimal']>;
  customerId?: Maybe<Scalars['ID']>;
  customerName?: Maybe<Scalars['String']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  discountPercent?: Maybe<Scalars['Decimal']>;
  machineName?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  reservationId?: Maybe<Scalars['ID']>;
  revenue?: Maybe<Scalars['Decimal']>;
  userSellerName?: Maybe<Scalars['String']>;
};

export type CommissionTemplateType = {
  __typename?: 'CommissionTemplateType';
  baseSalary: Scalars['Decimal'];
  commissionlevelSet: Array<CommissionLevelType>;
  commissionrangeSet: Array<CommissionRangeType>;
  commissionusersettingSet: Array<CommissionUserSettingType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CommissionType = {
  __typename?: 'CommissionType';
  case: CommissionsCommissionCaseChoices;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  order: OrderType;
  userSeller: UserType;
};

export type CommissionUserSettingType = {
  __typename?: 'CommissionUserSettingType';
  commissionTemplate?: Maybe<CommissionTemplateType>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  showCommissions: Scalars['Boolean'];
  user: UserType;
};

/** An enumeration. */
export enum CommissionsCommissionCaseChoices {
  /** Orderer */
  Orderer = 'ORDERER',
  /** Owner */
  Owner = 'OWNER'
}

/** An enumeration. */
export enum CommissionsCommissionReportRowCaseChoices {
  /** Orderer */
  Orderer = 'ORDERER',
  /** Owner */
  Owner = 'OWNER'
}

/** An enumeration. */
export enum CommissionsCommissionReportRowSourceChoices {
  /** Correction */
  Correction = 'CORRECTION',
  /** Normal */
  Normal = 'NORMAL',
  /** Rent through */
  Renthrough = 'RENTHROUGH'
}

export type ConnectIdsType = {
  catalogExtraRowIds: Array<InputMaybe<Scalars['ID']>>;
  machineId: Scalars['ID'];
};

export type ConnectMachineCatalogExtraRowRental = {
  __typename?: 'ConnectMachineCatalogExtraRowRental';
  machines?: Maybe<Array<Maybe<MachineType>>>;
};

export type ConnectMachineCatalogExtraRowUnitPrice = {
  __typename?: 'ConnectMachineCatalogExtraRowUnitPrice';
  machines?: Maybe<Array<Maybe<MachineType>>>;
};

export type CopyCatalogExtraRowRentalMachines = {
  __typename?: 'CopyCatalogExtraRowRentalMachines';
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
};

export type CopyCatalogExtraRowUnitPriceMachines = {
  __typename?: 'CopyCatalogExtraRowUnitPriceMachines';
  catalogExtraRowUnitPrice?: Maybe<CatalogExtraRowUnitPriceType>;
};

export type CopyInspectionGiveTags = {
  __typename?: 'CopyInspectionGiveTags';
  countAnswersAdded?: Maybe<Scalars['Int']>;
};

export type CopyInspectionReturnTags = {
  __typename?: 'CopyInspectionReturnTags';
  countAnswersAdded?: Maybe<Scalars['Int']>;
};

export type CopyMachineCatalogExtraRowRental = {
  __typename?: 'CopyMachineCatalogExtraRowRental';
  machine?: Maybe<MachineType>;
};

export type CopyMachineCatalogExtraRowUnitPrice = {
  __typename?: 'CopyMachineCatalogExtraRowUnitPrice';
  machine?: Maybe<MachineType>;
};

export type CopyMachineInfoTags = {
  __typename?: 'CopyMachineInfoTags';
  countAnswersAdded?: Maybe<Scalars['Int']>;
};

export type CostcenterType = {
  __typename?: 'CostcenterType';
  catalogextrarowunitpriceSet: Array<CatalogExtraRowUnitPriceType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  invoicerowSet: Array<InvoiceRowType>;
  name: Scalars['String'];
  number: Scalars['String'];
  productcodeSet: Array<ProductCodeType>;
};

export type CosttypeForRentThroughType = {
  __typename?: 'CosttypeForRentThroughType';
  costtype: CosttypeType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  location: LocationType;
};

export type CosttypeType = {
  __typename?: 'CosttypeType';
  catalogextrarowunitpriceSet: Array<CatalogExtraRowUnitPriceType>;
  costtypeforrentthroughSet: Array<CosttypeForRentThroughType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  invoicerowSet: Array<InvoiceRowType>;
  name: Scalars['String'];
  number: Scalars['String'];
  productcodeSet: Array<ProductCodeType>;
};

export type CreateCatalog = {
  __typename?: 'CreateCatalog';
  catalog?: Maybe<CatalogType>;
};

export type CreateCatalogCategory = {
  __typename?: 'CreateCatalogCategory';
  catalogCategory?: Maybe<CatalogCategoryType>;
};

export type CreateCatalogCategoryUpper = {
  __typename?: 'CreateCatalogCategoryUpper';
  catalogCategoryUpper?: Maybe<CatalogCategoryUpperType>;
};

export type CreateCatalogExtraCategoryRental = {
  __typename?: 'CreateCatalogExtraCategoryRental';
  catalogExtraCategoryRental?: Maybe<CatalogExtraCategoryRentalType>;
};

export type CreateCatalogExtraCategoryUnitPrice = {
  __typename?: 'CreateCatalogExtraCategoryUnitPrice';
  catalogExtraCategoryUnitPrice?: Maybe<CatalogExtraCategoryUnitPriceType>;
};

export type CreateCatalogExtraCategoryUpperRental = {
  __typename?: 'CreateCatalogExtraCategoryUpperRental';
  catalogExtraCategoryUpperRental?: Maybe<CatalogExtraCategoryUpperRentalType>;
};

export type CreateCatalogExtraRowInvoice = {
  __typename?: 'CreateCatalogExtraRowInvoice';
  catalogExtraRowInvoice?: Maybe<CatalogExtraRowInvoiceType>;
};

export type CreateCatalogExtraRowRental = {
  __typename?: 'CreateCatalogExtraRowRental';
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
};

export type CreateCatalogExtraRowUnitPrice = {
  __typename?: 'CreateCatalogExtraRowUnitPrice';
  catalogExtraRowUnitPrice?: Maybe<CatalogExtraRowUnitPriceType>;
};

export type CreateCatalogRow = {
  __typename?: 'CreateCatalogRow';
  catalogRow?: Maybe<CatalogRowType>;
};

export type CreateCatalogTransferMethod = {
  __typename?: 'CreateCatalogTransferMethod';
  catalogTransferMethod?: Maybe<CatalogTransferMethodType>;
};

export type CreateCatalogTransferPrice = {
  __typename?: 'CreateCatalogTransferPrice';
  catalogTransferPrice?: Maybe<CatalogTransferPriceType>;
};

export type CreateCommission = {
  __typename?: 'CreateCommission';
  commission?: Maybe<CommissionType>;
};

export type CreateCommissionLevel = {
  __typename?: 'CreateCommissionLevel';
  commissionLevel?: Maybe<CommissionLevelType>;
};

export type CreateCommissionRange = {
  __typename?: 'CreateCommissionRange';
  commissionRange?: Maybe<CommissionRangeType>;
};

export type CreateCommissionReports = {
  __typename?: 'CreateCommissionReports';
  commissionReports?: Maybe<Array<Maybe<CommissionReportType>>>;
};

export type CreateCommissionTemplate = {
  __typename?: 'CreateCommissionTemplate';
  commissionTemplate?: Maybe<CommissionTemplateType>;
};

export type CreateCostcenter = {
  __typename?: 'CreateCostcenter';
  costcenter?: Maybe<CostcenterType>;
};

export type CreateCosttype = {
  __typename?: 'CreateCosttype';
  costtype?: Maybe<CosttypeType>;
};

export type CreateCustomer = {
  __typename?: 'CreateCustomer';
  customer?: Maybe<CustomerType>;
};

export type CreateCustomerArea = {
  __typename?: 'CreateCustomerArea';
  customerArea?: Maybe<CustomerAreaType>;
};

export type CreateCustomerIndustry = {
  __typename?: 'CreateCustomerIndustry';
  customerIndustry?: Maybe<CustomerIndustryType>;
};

export type CreateCustomerRank = {
  __typename?: 'CreateCustomerRank';
  customerRank?: Maybe<CustomerRankType>;
};

export type CreateDiscountList = {
  __typename?: 'CreateDiscountList';
  discountList?: Maybe<DiscountListType>;
};

export type CreateDiscountRow = {
  __typename?: 'CreateDiscountRow';
  discountRow?: Maybe<DiscountRowType>;
};

export type CreateDrive = {
  __typename?: 'CreateDrive';
  drive?: Maybe<DriveType>;
};

export type CreateEvent = {
  __typename?: 'CreateEvent';
  event?: Maybe<EventType>;
};

export type CreateGroup = {
  __typename?: 'CreateGroup';
  group?: Maybe<GroupType>;
};

export type CreateHoliday = {
  __typename?: 'CreateHoliday';
  holiday?: Maybe<HolidayType>;
};

export type CreateInspectionGiveAnswer = {
  __typename?: 'CreateInspectionGiveAnswer';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateInspectionGiveCategory = {
  __typename?: 'CreateInspectionGiveCategory';
  inspectionGiveCategory?: Maybe<InspectionGiveCategoryType>;
};

export type CreateInspectionGiveQuestion = {
  __typename?: 'CreateInspectionGiveQuestion';
  inspectionGiveQuestion?: Maybe<InspectionGiveQuestionType>;
};

export type CreateInspectionGiveQuestionOption = {
  __typename?: 'CreateInspectionGiveQuestionOption';
  inspectionGiveQuestionOption?: Maybe<InspectionGiveQuestionOptionType>;
};

export type CreateInspectionGiveTag = {
  __typename?: 'CreateInspectionGiveTag';
  inspectionGiveTag?: Maybe<InspectionGiveTagType>;
};

export type CreateInspectionGiveToMachine = {
  __typename?: 'CreateInspectionGiveToMachine';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateInspectionReturnAnswer = {
  __typename?: 'CreateInspectionReturnAnswer';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateInspectionReturnCategory = {
  __typename?: 'CreateInspectionReturnCategory';
  inspectionReturnCategory?: Maybe<InspectionReturnCategoryType>;
};

export type CreateInspectionReturnQuestion = {
  __typename?: 'CreateInspectionReturnQuestion';
  inspectionReturnQuestion?: Maybe<InspectionReturnQuestionType>;
};

export type CreateInspectionReturnQuestionOption = {
  __typename?: 'CreateInspectionReturnQuestionOption';
  inspectionReturnQuestionOption?: Maybe<InspectionReturnQuestionOptionType>;
};

export type CreateInspectionReturnTag = {
  __typename?: 'CreateInspectionReturnTag';
  inspectionReturnTag?: Maybe<InspectionReturnTagType>;
};

export type CreateInspectionReturnToMachine = {
  __typename?: 'CreateInspectionReturnToMachine';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateInvoice = {
  __typename?: 'CreateInvoice';
  invoice?: Maybe<InvoiceType>;
};

export type CreateLocation = {
  __typename?: 'CreateLocation';
  location?: Maybe<LocationType>;
};

export type CreateLog = {
  __typename?: 'CreateLog';
  log?: Maybe<LogType>;
};

export type CreateMachine = {
  __typename?: 'CreateMachine';
  machine?: Maybe<MachineType>;
};

export type CreateMachineBreakdown = {
  __typename?: 'CreateMachineBreakdown';
  machineBreakdown?: Maybe<MachineBreakdownType>;
};

export type CreateMachineBreakdownComment = {
  __typename?: 'CreateMachineBreakdownComment';
  machineBreakdownComment?: Maybe<MachineBreakdownCommentType>;
};

export type CreateMachineBulkBreakdown = {
  __typename?: 'CreateMachineBulkBreakdown';
  machineBulkBreakdown?: Maybe<MachineBulkBreakdownType>;
};

export type CreateMachineInfoAnswer = {
  __typename?: 'CreateMachineInfoAnswer';
  success?: Maybe<Scalars['Boolean']>;
};

export type CreateMachineInfoCategory = {
  __typename?: 'CreateMachineInfoCategory';
  machineInfoCategory?: Maybe<MachineInfoCategoryType>;
};

export type CreateMachineInfoQuestion = {
  __typename?: 'CreateMachineInfoQuestion';
  machineInfoQuestion?: Maybe<MachineInfoQuestionType>;
};

export type CreateMachineInfoQuestionOption = {
  __typename?: 'CreateMachineInfoQuestionOption';
  machineInfoQuestionOption?: Maybe<MachineInfoQuestionOptionType>;
};

export type CreateMachineInfoTag = {
  __typename?: 'CreateMachineInfoTag';
  machineInfoTag?: Maybe<MachineInfoTagType>;
};

export type CreateMachineMake = {
  __typename?: 'CreateMachineMake';
  machineMake?: Maybe<MachineMakeType>;
};

export type CreateMachineModel = {
  __typename?: 'CreateMachineModel';
  machineModel?: Maybe<MachineModelType>;
};

export type CreateMaintenance = {
  __typename?: 'CreateMaintenance';
  maintenance?: Maybe<MaintenanceType>;
};

export type CreateMaintenanceComment = {
  __typename?: 'CreateMaintenanceComment';
  maintenanceComment?: Maybe<MaintenanceCommentType>;
};

export type CreateMaintenanceInterval = {
  __typename?: 'CreateMaintenanceInterval';
  maintenanceInterval?: Maybe<MaintenanceIntervalType>;
};

export type CreateOperationHour = {
  __typename?: 'CreateOperationHour';
  operationHour?: Maybe<OperationHourType>;
};

export type CreateOrder = {
  __typename?: 'CreateOrder';
  order?: Maybe<OrderType>;
};

export type CreateProductCode = {
  __typename?: 'CreateProductCode';
  productCode?: Maybe<ProductCodeType>;
};

export type CreateQuickTransport = {
  __typename?: 'CreateQuickTransport';
  order?: Maybe<OrderType>;
};

export type CreateReference = {
  __typename?: 'CreateReference';
  reference?: Maybe<ReferenceType>;
};

export type CreateReservation = {
  __typename?: 'CreateReservation';
  reservation?: Maybe<ReservationType>;
};

export type CreateReservationComment = {
  __typename?: 'CreateReservationComment';
  reservationComment?: Maybe<ReservationCommentType>;
};

export type CreateTransport = {
  __typename?: 'CreateTransport';
  transport?: Maybe<TransportType>;
};

export type CreateTransportInvoicing = {
  __typename?: 'CreateTransportInvoicing';
  transportInvoicing?: Maybe<TransportInvoicingType>;
};

export type CreateTransportInvoicingSummary = {
  __typename?: 'CreateTransportInvoicingSummary';
  transportInvoicingSummary?: Maybe<TransportInvoicingSummaryType>;
};

export type CreateUser = {
  __typename?: 'CreateUser';
  user?: Maybe<UserType>;
};

export type CustomerAreaType = {
  __typename?: 'CustomerAreaType';
  createdAt: Scalars['DateTime'];
  customerSet: Array<CustomerType>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type CustomerIndustryType = {
  __typename?: 'CustomerIndustryType';
  createdAt: Scalars['DateTime'];
  customerSet: Array<CustomerType>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type CustomerPriceCatalogRowType = {
  __typename?: 'CustomerPriceCatalogRowType';
  discountPercent: Scalars['Decimal'];
  priceDay: Scalars['Decimal'];
  priceMonth: Scalars['Decimal'];
};

export type CustomerRankType = {
  __typename?: 'CustomerRankType';
  createdAt: Scalars['DateTime'];
  customerSet: Array<CustomerType>;
  id: Scalars['ID'];
  order: Scalars['Int'];
  title: Scalars['String'];
};

export type CustomerType = {
  __typename?: 'CustomerType';
  blockedAt?: Maybe<Scalars['DateTime']>;
  blockedReason: Scalars['String'];
  businessId: Scalars['String'];
  catalogSelected?: Maybe<CatalogType>;
  contactNext?: Maybe<Scalars['Date']>;
  contactPersonFirstname: Scalars['String'];
  contactPersonLastname: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  creditInformationCheckedAt?: Maybe<Scalars['Date']>;
  creditInformationOk: Scalars['Boolean'];
  creditRiskClass: CustomersCustomerCreditRiskClassChoices;
  customerAreas: Array<CustomerAreaType>;
  customerIndustries: Array<CustomerIndustryType>;
  customerNumber: Scalars['String'];
  customerParent?: Maybe<CustomerType>;
  customerRank?: Maybe<CustomerRankType>;
  customersChildren: Array<CustomerType>;
  customersDiscountLists: Array<DiscountListType>;
  discountGlobalCatalogExtra: Scalars['Decimal'];
  discountGlobalCatalogRow: Scalars['Decimal'];
  discountListSelected?: Maybe<DiscountListType>;
  email: Scalars['String'];
  eventSet: Array<EventType>;
  eventoffercatalogSet: Array<EventOfferCatalogType>;
  eventofferdiscountlistSet: Array<EventOfferDiscountListType>;
  handledInvoicingAt?: Maybe<Scalars['Date']>;
  hasInsurance: Scalars['Boolean'];
  id: Scalars['ID'];
  invoicingAddress: Scalars['String'];
  invoicingDistrict: Scalars['String'];
  invoicingEAddress: Scalars['String'];
  invoicingEOperator: Scalars['String'];
  invoicingEmail: Scalars['String'];
  invoicingPostcode: Scalars['String'];
  isBusiness: Scalars['Boolean'];
  name: Scalars['String'];
  noBillingSurchargeAt?: Maybe<Scalars['DateTime']>;
  orderSet: Array<OrderType>;
  paymentTerm?: Maybe<Scalars['Int']>;
  phone: Scalars['String'];
  preInvoicingAt?: Maybe<Scalars['DateTime']>;
  preInvoicingReason: Scalars['String'];
  referenceSet: Array<ReferenceType>;
  useParentInvoicing: Scalars['Boolean'];
  useParentPricing: Scalars['Boolean'];
  userOwner?: Maybe<UserType>;
  visitAddress: Scalars['String'];
  visitDistrict: Scalars['String'];
  visitPostcode: Scalars['String'];
};

/** An enumeration. */
export enum CustomersCustomerCreditRiskClassChoices {
  /** Class 1 */
  Class_1 = 'CLASS_1',
  /** Class 2 */
  Class_2 = 'CLASS_2',
  /** Class 3 */
  Class_3 = 'CLASS_3',
  /** Class 4 */
  Class_4 = 'CLASS_4',
  /** Class 5 */
  Class_5 = 'CLASS_5',
  /** Not selected */
  ClassNone = 'CLASS_NONE'
}

export type DeleteCatalog = {
  __typename?: 'DeleteCatalog';
  catalogId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogCategory = {
  __typename?: 'DeleteCatalogCategory';
  catalogCategoryId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogCategoryUpper = {
  __typename?: 'DeleteCatalogCategoryUpper';
  catalogCategoryUpperId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogExtraCategoryRental = {
  __typename?: 'DeleteCatalogExtraCategoryRental';
  catalogExtraCategoryRentalId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogExtraCategoryUnitPrice = {
  __typename?: 'DeleteCatalogExtraCategoryUnitPrice';
  catalogExtraCategoryUnitPriceId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogExtraCategoryUpperRental = {
  __typename?: 'DeleteCatalogExtraCategoryUpperRental';
  catalogExtraCategoryUpperRentalId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogExtraRowInvoice = {
  __typename?: 'DeleteCatalogExtraRowInvoice';
  catalogExtraRowInvoiceId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogExtraRowRental = {
  __typename?: 'DeleteCatalogExtraRowRental';
  catalogExtraRowRentalId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogExtraRowUnitPrice = {
  __typename?: 'DeleteCatalogExtraRowUnitPrice';
  catalogExtraRowUnitPriceId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogRow = {
  __typename?: 'DeleteCatalogRow';
  catalogRowId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogTransferMethod = {
  __typename?: 'DeleteCatalogTransferMethod';
  catalogTransferMethodId?: Maybe<Scalars['ID']>;
};

export type DeleteCatalogTransferPrice = {
  __typename?: 'DeleteCatalogTransferPrice';
  catalogTransferPriceId?: Maybe<Scalars['ID']>;
};

export type DeleteCommission = {
  __typename?: 'DeleteCommission';
  commissionId?: Maybe<Scalars['ID']>;
};

export type DeleteCommissionLevel = {
  __typename?: 'DeleteCommissionLevel';
  commissionLevelId?: Maybe<Scalars['ID']>;
};

export type DeleteCommissionRange = {
  __typename?: 'DeleteCommissionRange';
  commissionRangeId?: Maybe<Scalars['ID']>;
};

export type DeleteCommissionTemplate = {
  __typename?: 'DeleteCommissionTemplate';
  commissionTemplateId?: Maybe<Scalars['ID']>;
};

export type DeleteCommissionUserSetting = {
  __typename?: 'DeleteCommissionUserSetting';
  commissionUserSettingId?: Maybe<Scalars['ID']>;
};

export type DeleteCostcenter = {
  __typename?: 'DeleteCostcenter';
  costcenterId?: Maybe<Scalars['ID']>;
};

export type DeleteCosttype = {
  __typename?: 'DeleteCosttype';
  costtypeId?: Maybe<Scalars['ID']>;
};

export type DeleteCustomer = {
  __typename?: 'DeleteCustomer';
  customerId?: Maybe<Scalars['ID']>;
};

export type DeleteCustomerArea = {
  __typename?: 'DeleteCustomerArea';
  id?: Maybe<Scalars['ID']>;
};

export type DeleteCustomerIndustry = {
  __typename?: 'DeleteCustomerIndustry';
  id?: Maybe<Scalars['ID']>;
};

export type DeleteCustomerRank = {
  __typename?: 'DeleteCustomerRank';
  id?: Maybe<Scalars['ID']>;
};

export type DeleteDiscountList = {
  __typename?: 'DeleteDiscountList';
  discountListId?: Maybe<Scalars['ID']>;
};

export type DeleteDrive = {
  __typename?: 'DeleteDrive';
  driveId?: Maybe<Scalars['ID']>;
};

export type DeleteDriveContent = {
  __typename?: 'DeleteDriveContent';
  driveContentId?: Maybe<Scalars['ID']>;
};

export type DeleteEvent = {
  __typename?: 'DeleteEvent';
  eventId?: Maybe<Scalars['ID']>;
};

export type DeleteGroup = {
  __typename?: 'DeleteGroup';
  groupId?: Maybe<Scalars['ID']>;
};

export type DeleteHoliday = {
  __typename?: 'DeleteHoliday';
  holidayId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionGiveAnswer = {
  __typename?: 'DeleteInspectionGiveAnswer';
  inspectionGiveAnswerId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionGiveCategory = {
  __typename?: 'DeleteInspectionGiveCategory';
  inspectionGiveCategoryId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionGiveQuestion = {
  __typename?: 'DeleteInspectionGiveQuestion';
  inspectionGiveQuestionId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionGiveQuestionOption = {
  __typename?: 'DeleteInspectionGiveQuestionOption';
  inspectionGiveQuestionOptionId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionGiveTag = {
  __typename?: 'DeleteInspectionGiveTag';
  inspectionGiveTagId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionGiveToMachine = {
  __typename?: 'DeleteInspectionGiveToMachine';
  inspectionGiveToMachineId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionReturnAnswer = {
  __typename?: 'DeleteInspectionReturnAnswer';
  inspectionReturnAnswerId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionReturnCategory = {
  __typename?: 'DeleteInspectionReturnCategory';
  inspectionReturnCategoryId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionReturnQuestion = {
  __typename?: 'DeleteInspectionReturnQuestion';
  inspectionReturnQuestionId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionReturnQuestionOption = {
  __typename?: 'DeleteInspectionReturnQuestionOption';
  inspectionReturnQuestionOptionId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionReturnTag = {
  __typename?: 'DeleteInspectionReturnTag';
  inspectionReturnTagId?: Maybe<Scalars['ID']>;
};

export type DeleteInspectionReturnToMachine = {
  __typename?: 'DeleteInspectionReturnToMachine';
  inspectionReturnToMachineId?: Maybe<Scalars['ID']>;
};

export type DeleteInvoice = {
  __typename?: 'DeleteInvoice';
  invoiceId?: Maybe<Scalars['ID']>;
};

export type DeleteLocation = {
  __typename?: 'DeleteLocation';
  locationId?: Maybe<Scalars['ID']>;
};

export type DeleteMachine = {
  __typename?: 'DeleteMachine';
  machineId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineBreakdown = {
  __typename?: 'DeleteMachineBreakdown';
  machineBreakdownId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineBulkBreakdown = {
  __typename?: 'DeleteMachineBulkBreakdown';
  machineBulkBreakdownId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineInfoAnswer = {
  __typename?: 'DeleteMachineInfoAnswer';
  machineInfoAnswerId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineInfoCategory = {
  __typename?: 'DeleteMachineInfoCategory';
  machineInfoCategoryId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineInfoQuestion = {
  __typename?: 'DeleteMachineInfoQuestion';
  machineInfoQuestionId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineInfoQuestionOption = {
  __typename?: 'DeleteMachineInfoQuestionOption';
  machineInfoQuestionOptionId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineInfoTag = {
  __typename?: 'DeleteMachineInfoTag';
  machineInfoTagId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineMake = {
  __typename?: 'DeleteMachineMake';
  machineMakeId?: Maybe<Scalars['ID']>;
};

export type DeleteMachineModel = {
  __typename?: 'DeleteMachineModel';
  machineModelId?: Maybe<Scalars['ID']>;
};

export type DeleteMaintenance = {
  __typename?: 'DeleteMaintenance';
  maintenanceId?: Maybe<Scalars['ID']>;
};

export type DeleteMaintenanceComment = {
  __typename?: 'DeleteMaintenanceComment';
  maintenanceCommentId?: Maybe<Scalars['ID']>;
};

export type DeleteMaintenanceImage = {
  __typename?: 'DeleteMaintenanceImage';
  maintenanceImageId?: Maybe<Scalars['ID']>;
};

export type DeleteMaintenanceInterval = {
  __typename?: 'DeleteMaintenanceInterval';
  maintenanceIntervalId?: Maybe<Scalars['ID']>;
};

export type DeleteOperationHour = {
  __typename?: 'DeleteOperationHour';
  operationHourId?: Maybe<Scalars['ID']>;
};

export type DeleteOrder = {
  __typename?: 'DeleteOrder';
  orderId?: Maybe<Scalars['ID']>;
};

export type DeleteProductCode = {
  __typename?: 'DeleteProductCode';
  productCodeId?: Maybe<Scalars['ID']>;
};

export type DeleteReference = {
  __typename?: 'DeleteReference';
  referenceId?: Maybe<Scalars['ID']>;
};

export type DeleteReservation = {
  __typename?: 'DeleteReservation';
  reservationId?: Maybe<Scalars['ID']>;
};

export type DeleteReservationImage = {
  __typename?: 'DeleteReservationImage';
  reservationImageId?: Maybe<Scalars['ID']>;
};

export type DeleteReservationImageBasic = {
  __typename?: 'DeleteReservationImageBasic';
  reservationImageBasicId?: Maybe<Scalars['ID']>;
};

export type DeleteTransport = {
  __typename?: 'DeleteTransport';
  transportId?: Maybe<Scalars['ID']>;
};

export type DeleteTransportInvoicing = {
  __typename?: 'DeleteTransportInvoicing';
  transportInvoicingId?: Maybe<Scalars['ID']>;
};

export type DeleteTransportInvoicingSummary = {
  __typename?: 'DeleteTransportInvoicingSummary';
  transportInvoicingSummaryId?: Maybe<Scalars['ID']>;
};

export type DeleteUser = {
  __typename?: 'DeleteUser';
  userId?: Maybe<Scalars['ID']>;
};

export type DiscountListType = {
  __typename?: 'DiscountListType';
  catalog: CatalogType;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  customer?: Maybe<CustomerType>;
  customersSelected: Array<CustomerType>;
  discountListSource?: Maybe<DiscountListType>;
  discountListsSourced: Array<DiscountListType>;
  discountrowSet: Array<DiscountRowType>;
  discountrowSetForCatalogRow?: Maybe<Array<DiscountRowType>>;
  eventofferdiscountlistSet: Array<EventOfferDiscountListType>;
  id: Scalars['ID'];
  isBusiness: Scalars['Boolean'];
  isOffer: Scalars['Boolean'];
  locked?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
};


export type DiscountListTypeDiscountrowSetForCatalogRowArgs = {
  catalogRowId: Scalars['ID'];
};

export type DiscountRowType = {
  __typename?: 'DiscountRowType';
  catalogRow: CatalogRowType;
  createdAt: Scalars['DateTime'];
  discountList: DiscountListType;
  id: Scalars['ID'];
  percent?: Maybe<Scalars['Decimal']>;
  showPrint: Scalars['Boolean'];
};

export type DoInvoiceApprove = {
  __typename?: 'DoInvoiceApprove';
  invoice?: Maybe<InvoiceType>;
};

export type DoInvoiceSend = {
  __typename?: 'DoInvoiceSend';
  invoice?: Maybe<InvoiceType>;
};

export type DriveContentType = {
  __typename?: 'DriveContentType';
  createdAt: Scalars['DateTime'];
  drive: DriveType;
  id: Scalars['ID'];
  reservation: ReservationType;
  transport: TransportType;
};

export type DriveType = {
  __typename?: 'DriveType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  drivecontentSet: Array<DriveContentType>;
  id: Scalars['ID'];
};

export type EditableDatesType = {
  __typename?: 'EditableDatesType';
  dateEnd?: Maybe<Scalars['Date']>;
  dateStart?: Maybe<Scalars['Date']>;
};

export type EstimatedHoursType = {
  __typename?: 'EstimatedHoursType';
  hours?: Maybe<Scalars['Int']>;
};

export type EventOfferCatalogType = {
  __typename?: 'EventOfferCatalogType';
  catalog: CatalogType;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  customer: CustomerType;
  id: Scalars['ID'];
};

export type EventOfferDiscountListType = {
  __typename?: 'EventOfferDiscountListType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  customer: CustomerType;
  discountList: DiscountListType;
  id: Scalars['ID'];
};

export type EventType = {
  __typename?: 'EventType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  customer: CustomerType;
  date: Scalars['Date'];
  eventType: EventsEventEventTypeChoices;
  id: Scalars['ID'];
  information: Scalars['String'];
};

/** An enumeration. */
export enum EventsEventEventTypeChoices {
  /** Call */
  Call = 'CALL',
  /** Visit */
  Visit = 'VISIT'
}

export type GetCommissionReportPdf = {
  __typename?: 'GetCommissionReportPdf';
  url?: Maybe<Scalars['String']>;
};

export type GetConfirmationPdf = {
  __typename?: 'GetConfirmationPdf';
  url?: Maybe<Scalars['String']>;
};

export type GetContractPdf = {
  __typename?: 'GetContractPdf';
  url?: Maybe<Scalars['String']>;
};

export type GetDrivePdf = {
  __typename?: 'GetDrivePdf';
  url?: Maybe<Scalars['String']>;
};

export type GetOfferPdf = {
  __typename?: 'GetOfferPdf';
  url?: Maybe<Scalars['String']>;
};

export type GetOrderOfferPdf = {
  __typename?: 'GetOrderOfferPdf';
  url?: Maybe<Scalars['String']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<PermissionType>;
  /** Käyttäjäryhmät joihin tämä käyttäjä kuuluu. Käyttäjä saa käyttöoikeudet kaikista käyttäjäryhmistä, joihin hän kuuluu. */
  userSet: Array<UserType>;
};

export type HolidayType = {
  __typename?: 'HolidayType';
  createdAt: Scalars['DateTime'];
  date: Scalars['Date'];
  holidayType: HolidaysHolidayHolidayTypeChoices;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** An enumeration. */
export enum HolidaysHolidayHolidayTypeChoices {
  /** Company */
  Company = 'COMPANY',
  /** Private */
  Private = 'PRIVATE'
}

export type IdOrderInputType = {
  id?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type IdPercentPrintInputType = {
  id: Scalars['ID'];
  percent?: InputMaybe<Scalars['String']>;
  showPrint?: InputMaybe<Scalars['Boolean']>;
};

export type IdPriceType = {
  __typename?: 'IdPriceType';
  invoicePeriodEnd?: Maybe<Scalars['Date']>;
  invoicePeriodStart?: Maybe<Scalars['Date']>;
  isDayPrice?: Maybe<Scalars['Boolean']>;
  reservationId?: Maybe<Scalars['ID']>;
  total?: Maybe<Scalars['Decimal']>;
  totalReservation?: Maybe<Scalars['Decimal']>;
  totalReservationWithoutDiscount?: Maybe<Scalars['Decimal']>;
  totalUninvoiced?: Maybe<Scalars['Decimal']>;
};

export type InspectionGiveAnswerType = {
  __typename?: 'InspectionGiveAnswerType';
  answer: Scalars['String'];
  checked?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionGiveQuestionOption?: Maybe<InspectionGiveQuestionOptionType>;
  inspectionGiveToMachine: InspectionGiveToMachineType;
  reservation: ReservationType;
};

export type InspectionGiveCategoryType = {
  __typename?: 'InspectionGiveCategoryType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectiongivequestionSet: Array<InspectionGiveQuestionType>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type InspectionGiveQuestionOptionType = {
  __typename?: 'InspectionGiveQuestionOptionType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionGiveQuestion: InspectionGiveQuestionType;
  inspectiongiveanswerSet: Array<InspectionGiveAnswerType>;
  title: Scalars['String'];
};

export type InspectionGiveQuestionType = {
  __typename?: 'InspectionGiveQuestionType';
  answerType: InspectionsInspectionGiveQuestionAnswerTypeChoices;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionGiveCategory: InspectionGiveCategoryType;
  inspectiongivequestionoptionSet: Array<InspectionGiveQuestionOptionType>;
  inspectiongivetagSet: Array<InspectionGiveTagType>;
  inspectiongivetomachineSet: Array<InspectionGiveToMachineType>;
  order: Scalars['Int'];
  question: Scalars['String'];
};

export type InspectionGiveTagType = {
  __typename?: 'InspectionGiveTagType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionGiveQuestions: Array<InspectionGiveQuestionType>;
  name: Scalars['String'];
};

export type InspectionGiveToMachineType = {
  __typename?: 'InspectionGiveToMachineType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionGiveQuestion: InspectionGiveQuestionType;
  inspectiongiveanswerSet: Array<InspectionGiveAnswerType>;
  machine: MachineType;
};

export type InspectionReturnAnswerType = {
  __typename?: 'InspectionReturnAnswerType';
  answer: Scalars['String'];
  checked?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionReturnQuestionOption?: Maybe<InspectionReturnQuestionOptionType>;
  inspectionReturnToMachine: InspectionReturnToMachineType;
  reservation: ReservationType;
};

export type InspectionReturnCategoryType = {
  __typename?: 'InspectionReturnCategoryType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionreturnquestionSet: Array<InspectionReturnQuestionType>;
  name: Scalars['String'];
  order: Scalars['Int'];
};

export type InspectionReturnQuestionOptionType = {
  __typename?: 'InspectionReturnQuestionOptionType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionReturnQuestion: InspectionReturnQuestionType;
  inspectionreturnanswerSet: Array<InspectionReturnAnswerType>;
  title: Scalars['String'];
};

export type InspectionReturnQuestionType = {
  __typename?: 'InspectionReturnQuestionType';
  answerType: InspectionsInspectionReturnQuestionAnswerTypeChoices;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionReturnCategory: InspectionReturnCategoryType;
  inspectionreturnquestionoptionSet: Array<InspectionReturnQuestionOptionType>;
  inspectionreturntagSet: Array<InspectionReturnTagType>;
  inspectionreturntomachineSet: Array<InspectionReturnToMachineType>;
  order: Scalars['Int'];
  question: Scalars['String'];
};

export type InspectionReturnTagType = {
  __typename?: 'InspectionReturnTagType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionReturnQuestions: Array<InspectionReturnQuestionType>;
  name: Scalars['String'];
};

export type InspectionReturnToMachineType = {
  __typename?: 'InspectionReturnToMachineType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  inspectionReturnQuestion: InspectionReturnQuestionType;
  inspectionreturnanswerSet: Array<InspectionReturnAnswerType>;
  machine: MachineType;
};

/** An enumeration. */
export enum InspectionsInspectionGiveQuestionAnswerTypeChoices {
  /** Check */
  Check = 'CHECK',
  /** Options */
  Options = 'OPTIONS',
  /** Text */
  Text = 'TEXT'
}

/** An enumeration. */
export enum InspectionsInspectionReturnQuestionAnswerTypeChoices {
  /** Check */
  Check = 'CHECK',
  /** Options */
  Options = 'OPTIONS',
  /** Text */
  Text = 'TEXT'
}

export enum InvoiceDeliveryMethods {
  Einvoice = 'EINVOICE',
  Email = 'EMAIL',
  Post = 'POST'
}

export type InvoiceRowType = {
  __typename?: 'InvoiceRowType';
  catalogextrarowinvoiceSet: Array<CatalogExtraRowInvoiceType>;
  costcenter?: Maybe<CostcenterType>;
  costtype?: Maybe<CosttypeType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  invoice: InvoiceType;
  invoiceRowCreditedBy?: Maybe<InvoiceRowType>;
  invoiceRowsCredited: Array<InvoiceRowType>;
  machinebreakdownSet: Array<MachineBreakdownType>;
  operationhourSet: Array<OperationHourType>;
  order: OrderType;
  price: Scalars['Decimal'];
  priceType: InvoicesInvoiceRowPriceTypeChoices;
  productNumber: Scalars['String'];
  quantity: Scalars['Decimal'];
  reservation?: Maybe<ReservationType>;
  rowType: InvoicesInvoiceRowRowTypeChoices;
  title: Scalars['String'];
  transportinvoicingSet: Array<TransportInvoicingType>;
  transportinvoicingsummarySet: Array<TransportInvoicingSummaryType>;
  unitPrice: Scalars['Decimal'];
  vat: Scalars['Decimal'];
};

export enum InvoiceSendMethods {
  Advance = 'ADVANCE',
  Card = 'CARD',
  Normal = 'NORMAL'
}

export type InvoiceType = {
  __typename?: 'InvoiceType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  information: Scalars['String'];
  invoicerowSet: Array<InvoiceRowType>;
  isAdvance: Scalars['Boolean'];
  reference: ReferenceType;
  remoteId: Scalars['Int'];
  remoteInvoiceNo: Scalars['String'];
  sentAt?: Maybe<Scalars['DateTime']>;
  status: InvoicesInvoiceStatusChoices;
};

export enum InvoiceableRowSources {
  CatalogExtraRowInvoice = 'CATALOG_EXTRA_ROW_INVOICE',
  Insurance = 'INSURANCE',
  MachineBreakdown = 'MACHINE_BREAKDOWN',
  OperationHour = 'OPERATION_HOUR',
  Reservation = 'RESERVATION',
  TransportInvoicing = 'TRANSPORT_INVOICING',
  TransportInvoicingSummary = 'TRANSPORT_INVOICING_SUMMARY'
}

export type InvoiceableRowType = {
  __typename?: 'InvoiceableRowType';
  catalogExtraRowInvoices: Array<Maybe<CatalogExtraRowInvoiceType>>;
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
  description: Scalars['String'];
  identifier: Scalars['String'];
  machineBreakdowns: Array<Maybe<MachineBreakdownType>>;
  operationHours: Array<Maybe<OperationHourType>>;
  orderId: Scalars['ID'];
  price: Scalars['Decimal'];
  priceType: Scalars['String'];
  productNumber: Scalars['String'];
  quantity: Scalars['Decimal'];
  referenceId: Scalars['ID'];
  reservationId: Scalars['ID'];
  rowSource: InvoiceableRowSources;
  rowType: Scalars['String'];
  subrowReservationId: Scalars['ID'];
  title: Scalars['String'];
  transportInvoicingSummaries: Array<Maybe<TransportInvoicingSummaryType>>;
  transportInvoicings: Array<Maybe<TransportInvoicingType>>;
  unitPrice: Scalars['Decimal'];
  vat: Scalars['Decimal'];
};

/** An enumeration. */
export enum InvoicesInvoiceRowPriceTypeChoices {
  /** Day price */
  Day = 'DAY',
  /** Month price */
  Month = 'MONTH',
  /** Single price */
  Single = 'SINGLE'
}

/** An enumeration. */
export enum InvoicesInvoiceRowRowTypeChoices {
  /** Breakdown credit row */
  Credibreak = 'CREDIBREAK',
  /** Credit row */
  Credit = 'CREDIT',
  /** Insurance row */
  Insurance = 'INSURANCE',
  /** Normal row */
  Normal = 'NORMAL',
  /** Surcharge row */
  Surcharge = 'SURCHARGE'
}

/** An enumeration. */
export enum InvoicesInvoiceStatusChoices {
  /** Open */
  Open = 'OPEN',
  /** Sent */
  Sent = 'SENT'
}

export type InvoicingPriceReservationType = {
  __typename?: 'InvoicingPriceReservationType';
  days?: Maybe<Scalars['Int']>;
  isDayPrice?: Maybe<Scalars['Boolean']>;
  months?: Maybe<Scalars['Decimal']>;
  total?: Maybe<Scalars['Decimal']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  catalogextrarowrentalSet: Array<CatalogExtraRowRentalType>;
  catalogtransferpriceSet: Array<CatalogTransferPriceType>;
  costtypeforrentthroughSet: Array<CosttypeForRentThroughType>;
  createdAt: Scalars['DateTime'];
  dimIdentifier: Scalars['String'];
  id: Scalars['ID'];
  machineSet: Array<MachineType>;
  machinebulkamountSet: Array<MachineBulkAmountType>;
  name: Scalars['String'];
  orderSet: Array<OrderType>;
  transferDistanceIncluded: Scalars['Int'];
  transferDistanceLimit: Scalars['Int'];
  userinfoSet: Array<UserInfoType>;
};

export type LockDiscountList = {
  __typename?: 'LockDiscountList';
  discountList?: Maybe<DiscountListType>;
};

export type LogType = {
  __typename?: 'LogType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  data: Scalars['String'];
  foreignKey: Scalars['Int'];
  id: Scalars['ID'];
  location: Scalars['String'];
  method: Scalars['String'];
  typeKey: Scalars['String'];
};

export type MachineBreakdownCommentType = {
  __typename?: 'MachineBreakdownCommentType';
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  machineBreakdown: MachineBreakdownType;
};

export type MachineBreakdownType = {
  __typename?: 'MachineBreakdownType';
  amountBilledSingle: Scalars['Decimal'];
  billing: MachinesMachineBreakdownBillingChoices;
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  fixByEnd: Scalars['Date'];
  fixByStart: Scalars['Date'];
  fixedAt?: Maybe<Scalars['DateTime']>;
  fixedBy?: Maybe<UserType>;
  id: Scalars['ID'];
  information: Scalars['String'];
  invoiceRow?: Maybe<InvoiceRowType>;
  machine?: Maybe<MachineType>;
  machinebreakdowncommentSet: Array<MachineBreakdownCommentType>;
  stillRentable: Scalars['Boolean'];
  title: Scalars['String'];
};

export type MachineBulkAmountType = {
  __typename?: 'MachineBulkAmountType';
  bulkAmount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  location: LocationType;
  machine: MachineType;
  machinebulkbreakdownSet: Array<MachineBulkBreakdownType>;
};

export type MachineBulkBreakdownType = {
  __typename?: 'MachineBulkBreakdownType';
  amountBroken: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  fixable: Scalars['Boolean'];
  fixedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  machine: MachineType;
  machineBulkAmount: MachineBulkAmountType;
  reservation: ReservationType;
};

export type MachineInfoAnswerType = {
  __typename?: 'MachineInfoAnswerType';
  answer: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machine: MachineType;
  machineInfoQuestion: MachineInfoQuestionType;
  machineInfoQuestionOption?: Maybe<MachineInfoQuestionOptionType>;
};

export type MachineInfoCategoryType = {
  __typename?: 'MachineInfoCategoryType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineinfoquestionSet: Array<MachineInfoQuestionType>;
  name: Scalars['String'];
};

export type MachineInfoQuestionOptionType = {
  __typename?: 'MachineInfoQuestionOptionType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineInfoQuestion: MachineInfoQuestionType;
  machineinfoanswerSet: Array<MachineInfoAnswerType>;
  title: Scalars['String'];
};

export type MachineInfoQuestionType = {
  __typename?: 'MachineInfoQuestionType';
  answerType: MachinesMachineInfoQuestionAnswerTypeChoices;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineInfoCategory: MachineInfoCategoryType;
  machineinfoanswerSet: Array<MachineInfoAnswerType>;
  machineinfoquestionoptionSet: Array<MachineInfoQuestionOptionType>;
  machineinfotagSet: Array<MachineInfoTagType>;
  question: Scalars['String'];
  showDrive: Scalars['Boolean'];
  showProductCard: Scalars['Boolean'];
};

export type MachineInfoTagType = {
  __typename?: 'MachineInfoTagType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineInfoQuestions: Array<MachineInfoQuestionType>;
  name: Scalars['String'];
};

export type MachineMakeType = {
  __typename?: 'MachineMakeType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machinemodelSet: Array<MachineModelType>;
  title: Scalars['String'];
};

export type MachineModelType = {
  __typename?: 'MachineModelType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  machineMake: MachineMakeType;
  machineSet: Array<MachineType>;
  title: Scalars['String'];
};

export type MachineType = {
  __typename?: 'MachineType';
  acquisitionDate: Scalars['Date'];
  bulkProduct: Scalars['Boolean'];
  catalogExtraRowsRental: Array<CatalogExtraRowRentalType>;
  catalogExtraRowsUnitPrice: Array<CatalogExtraRowUnitPriceType>;
  catalogRows: Array<CatalogRowType>;
  createdAt: Scalars['DateTime'];
  dateRemoved?: Maybe<Scalars['Date']>;
  fileCheckList: Scalars['String'];
  fileFinancing: Scalars['String'];
  filePurchase: Scalars['String'];
  fileTypePlate: Scalars['String'];
  financingAgreementNumber: Scalars['String'];
  financingCompany: Scalars['String'];
  financingDateEnd?: Maybe<Scalars['Date']>;
  financingDateStart?: Maybe<Scalars['Date']>;
  financingInformation: Scalars['String'];
  financingInterestMargin?: Maybe<Scalars['Decimal']>;
  financingInterestRate?: Maybe<Scalars['Decimal']>;
  financingInterestType: MachinesMachineFinancingInterestTypeChoices;
  financingInterestVariableType: MachinesMachineFinancingInterestVariableTypeChoices;
  financingLoanType: MachinesMachineFinancingLoanTypeChoices;
  financingType: MachinesMachineFinancingTypeChoices;
  hourTracking: Scalars['Boolean'];
  id: Scalars['ID'];
  identifier: Scalars['String'];
  information: Scalars['String'];
  inspectiongivetomachineSet: Array<InspectionGiveToMachineType>;
  inspectionreturntomachineSet: Array<InspectionReturnToMachineType>;
  location?: Maybe<LocationType>;
  machineModel: MachineModelType;
  machinebreakdownSet: Array<MachineBreakdownType>;
  machinebulkamountSet: Array<MachineBulkAmountType>;
  machinebulkbreakdownSet: Array<MachineBulkBreakdownType>;
  machineinfoanswerSet: Array<MachineInfoAnswerType>;
  maintenanceSet: Array<MaintenanceType>;
  maintenancesetting?: Maybe<MaintenanceSettingType>;
  operationhourSet: Array<OperationHourType>;
  purchaseInformation: Scalars['String'];
  purchasePrice?: Maybe<Scalars['Decimal']>;
  purchaseSupplier: Scalars['String'];
  rentThrough: Scalars['Boolean'];
  rentThroughCommissionCase: MachinesMachineRentThroughCommissionCaseChoices;
  rentThroughCompany: Scalars['String'];
  rentThroughInformation: Scalars['String'];
  reservationSet: Array<ReservationType>;
  serial: Scalars['String'];
  yearModel: Scalars['Int'];
};

/** An enumeration. */
export enum MachinesMachineBreakdownBillingChoices {
  /** Not billed */
  No = 'NO',
  /** Machine not in use */
  NotInUse = 'NOT_IN_USE',
  /** Single compensation, not billed */
  NoSingle = 'NO_SINGLE',
  /** Billed normally */
  Yes = 'YES',
  /** Single compensation, billed normally */
  YesSingle = 'YES_SINGLE'
}

/** An enumeration. */
export enum MachinesMachineFinancingInterestTypeChoices {
  /** Fixed */
  Fixed = 'FIXED',
  /** Not selected */
  NotSelected = 'NOT_SELECTED',
  /** Variable */
  Variable = 'VARIABLE'
}

/** An enumeration. */
export enum MachinesMachineFinancingInterestVariableTypeChoices {
  /** Euribor 3 months */
  Euribor3 = 'EURIBOR3',
  /** Euribor 6 months */
  Euribor6 = 'EURIBOR6',
  /** Euribor 12 months */
  Euribor12 = 'EURIBOR12',
  /** Not selected */
  NotSelected = 'NOT_SELECTED'
}

/** An enumeration. */
export enum MachinesMachineFinancingLoanTypeChoices {
  /** Installment */
  Installment = 'INSTALLMENT',
  /** Leasing */
  Leasing = 'LEASING',
  /** Not selected */
  NotSelected = 'NOT_SELECTED',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum MachinesMachineFinancingTypeChoices {
  /** Cash */
  Cash = 'CASH',
  /** Loan */
  Loan = 'LOAN',
  /** Not selected */
  NotSelected = 'NOT_SELECTED'
}

/** An enumeration. */
export enum MachinesMachineInfoQuestionAnswerTypeChoices {
  /** Options */
  Options = 'OPTIONS',
  /** Text */
  Text = 'TEXT'
}

/** An enumeration. */
export enum MachinesMachineRentThroughCommissionCaseChoices {
  /** No */
  No = 'NO',
  /** Substitute */
  Substitute = 'SUBSTITUTE',
  /** Yes */
  Yes = 'YES'
}

export type MaintenanceAlbumType = {
  __typename?: 'MaintenanceAlbumType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  maintenance?: Maybe<MaintenanceType>;
  maintenanceimageSet: Array<MaintenanceImageType>;
};

export type MaintenanceCommentType = {
  __typename?: 'MaintenanceCommentType';
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  maintenance?: Maybe<MaintenanceType>;
};

export type MaintenanceImageType = {
  __typename?: 'MaintenanceImageType';
  album: MaintenanceAlbumType;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  eurosOwnCosts: Scalars['Decimal'];
  eurosWarranty: Scalars['Decimal'];
  id: Scalars['ID'];
  image: Scalars['String'];
  imageName: Scalars['String'];
  imageType: MaintenanceMaintenanceImageImageTypeChoices;
  information: Scalars['String'];
};

export type MaintenanceIntervalType = {
  __typename?: 'MaintenanceIntervalType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  days: Scalars['Int'];
  description: Scalars['String'];
  hours: Scalars['Int'];
  id: Scalars['ID'];
  intervalType: MaintenanceMaintenanceIntervalIntervalTypeChoices;
  maintenanceSet: Array<MaintenanceType>;
  maintenanceSetting: MaintenanceSettingType;
};

/** An enumeration. */
export enum MaintenanceMaintenanceImageImageTypeChoices {
  /** Invoice */
  Invoice = 'INVOICE',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum MaintenanceMaintenanceIntervalIntervalTypeChoices {
  /** Last */
  Last = 'LAST',
  /** Normal */
  Normal = 'NORMAL'
}

/** An enumeration. */
export enum MaintenanceMaintenanceMaintenanceTypeChoices {
  /** Scheduled */
  Scheduled = 'SCHEDULED',
  /** Upcoming */
  Upcoming = 'UPCOMING'
}

/** An enumeration. */
export enum MaintenanceMaintenanceSettingScheduleTypeChoices {
  /** Days */
  Days = 'DAYS',
  /** Hours */
  Hours = 'HOURS',
  /** Hours or days */
  HoursDays = 'HOURS_DAYS',
  /** None */
  None = 'NONE'
}

/** An enumeration. */
export enum MaintenanceMaintenanceUpcomingFetchFetchTypeChoices {
  /** Auto */
  Auto = 'AUTO',
  /** Force */
  Force = 'FORCE'
}

export type MaintenanceSettingType = {
  __typename?: 'MaintenanceSettingType';
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  estimatedHoursPerDay: Scalars['Decimal'];
  id: Scalars['ID'];
  machine?: Maybe<MachineType>;
  maintenanceintervalSet: Array<MaintenanceIntervalType>;
  scheduleType: MaintenanceMaintenanceSettingScheduleTypeChoices;
  startDate: Scalars['Date'];
};

export type MaintenanceType = {
  __typename?: 'MaintenanceType';
  album: MaintenanceAlbumType;
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  createdManually: Scalars['Boolean'];
  estimatedDate: Scalars['Date'];
  id: Scalars['ID'];
  machine?: Maybe<MachineType>;
  maintenanceInterval: MaintenanceIntervalType;
  maintenanceType: MaintenanceMaintenanceMaintenanceTypeChoices;
  maintenancecommentSet: Array<MaintenanceCommentType>;
  operationhour?: Maybe<OperationHourType>;
  overdueHours?: Maybe<Scalars['Int']>;
  serviceContractor: Scalars['String'];
  serviceEndBy?: Maybe<Scalars['Date']>;
  serviceStartBy?: Maybe<Scalars['Date']>;
  serviceTechnician?: Maybe<UserType>;
  servicedAt?: Maybe<Scalars['DateTime']>;
  verifiedAt?: Maybe<Scalars['DateTime']>;
  verifiedBy?: Maybe<UserType>;
};

export type MaintenanceUpcomingFetchType = {
  __typename?: 'MaintenanceUpcomingFetchType';
  fetchTime: Scalars['DateTime'];
  fetchType: MaintenanceMaintenanceUpcomingFetchFetchTypeChoices;
  id: Scalars['ID'];
};

export type MoveCatalogExtraRowRentalToLocation = {
  __typename?: 'MoveCatalogExtraRowRentalToLocation';
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
};

export type MoveMachineToLocation = {
  __typename?: 'MoveMachineToLocation';
  machine?: Maybe<MachineType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addInspectionGiveTagToMachine?: Maybe<AddInspectionGiveTagToMachine>;
  addInspectionGiveTagToQuestion?: Maybe<AddInspectionGiveTagToQuestion>;
  addInspectionReturnTagToMachine?: Maybe<AddInspectionReturnTagToMachine>;
  addInspectionReturnTagToQuestion?: Maybe<AddInspectionReturnTagToQuestion>;
  addMachineInfoTagToMachine?: Maybe<AddMachineInfoTagToMachine>;
  addMachineInfoTagToQuestion?: Maybe<AddMachineInfoTagToQuestion>;
  cloneDiscountList?: Maybe<CloneDiscountList>;
  cloneDiscountListFromCatalog?: Maybe<CloneDiscountListFromCatalog>;
  connectMachineCatalogExtraRowRental?: Maybe<ConnectMachineCatalogExtraRowRental>;
  connectMachineCatalogExtraRowUnitPrice?: Maybe<ConnectMachineCatalogExtraRowUnitPrice>;
  copyCatalogExtraRowRentalMachines?: Maybe<CopyCatalogExtraRowRentalMachines>;
  copyCatalogExtraRowUnitPriceMachines?: Maybe<CopyCatalogExtraRowUnitPriceMachines>;
  copyInspectionGiveTags?: Maybe<CopyInspectionGiveTags>;
  copyInspectionReturnTags?: Maybe<CopyInspectionReturnTags>;
  copyMachineCatalogExtraRowRental?: Maybe<CopyMachineCatalogExtraRowRental>;
  copyMachineCatalogExtraRowUnitPrice?: Maybe<CopyMachineCatalogExtraRowUnitPrice>;
  copyMachineInfoTags?: Maybe<CopyMachineInfoTags>;
  createCatalog?: Maybe<CreateCatalog>;
  createCatalogCategory?: Maybe<CreateCatalogCategory>;
  createCatalogCategoryUpper?: Maybe<CreateCatalogCategoryUpper>;
  createCatalogExtraCategoryRental?: Maybe<CreateCatalogExtraCategoryRental>;
  createCatalogExtraCategoryUnitPrice?: Maybe<CreateCatalogExtraCategoryUnitPrice>;
  createCatalogExtraCategoryUpperRental?: Maybe<CreateCatalogExtraCategoryUpperRental>;
  createCatalogExtraRowInvoice?: Maybe<CreateCatalogExtraRowInvoice>;
  createCatalogExtraRowRental?: Maybe<CreateCatalogExtraRowRental>;
  createCatalogExtraRowUnitPrice?: Maybe<CreateCatalogExtraRowUnitPrice>;
  createCatalogRow?: Maybe<CreateCatalogRow>;
  createCatalogTransferMethod?: Maybe<CreateCatalogTransferMethod>;
  createCatalogTransferPrice?: Maybe<CreateCatalogTransferPrice>;
  createCommission?: Maybe<CreateCommission>;
  createCommissionLevel?: Maybe<CreateCommissionLevel>;
  createCommissionRange?: Maybe<CreateCommissionRange>;
  createCommissionReports?: Maybe<CreateCommissionReports>;
  createCommissionTemplate?: Maybe<CreateCommissionTemplate>;
  createCostcenter?: Maybe<CreateCostcenter>;
  createCosttype?: Maybe<CreateCosttype>;
  createCustomer?: Maybe<CreateCustomer>;
  createCustomerArea?: Maybe<CreateCustomerArea>;
  createCustomerIndustry?: Maybe<CreateCustomerIndustry>;
  createCustomerRank?: Maybe<CreateCustomerRank>;
  createDiscountList?: Maybe<CreateDiscountList>;
  createDiscountRow?: Maybe<CreateDiscountRow>;
  createDrive?: Maybe<CreateDrive>;
  createEvent?: Maybe<CreateEvent>;
  createGroup?: Maybe<CreateGroup>;
  createHoliday?: Maybe<CreateHoliday>;
  createInspectionGiveAnswer?: Maybe<CreateInspectionGiveAnswer>;
  createInspectionGiveCategory?: Maybe<CreateInspectionGiveCategory>;
  createInspectionGiveQuestion?: Maybe<CreateInspectionGiveQuestion>;
  createInspectionGiveQuestionOption?: Maybe<CreateInspectionGiveQuestionOption>;
  createInspectionGiveTag?: Maybe<CreateInspectionGiveTag>;
  createInspectionGiveToMachine?: Maybe<CreateInspectionGiveToMachine>;
  createInspectionReturnAnswer?: Maybe<CreateInspectionReturnAnswer>;
  createInspectionReturnCategory?: Maybe<CreateInspectionReturnCategory>;
  createInspectionReturnQuestion?: Maybe<CreateInspectionReturnQuestion>;
  createInspectionReturnQuestionOption?: Maybe<CreateInspectionReturnQuestionOption>;
  createInspectionReturnTag?: Maybe<CreateInspectionReturnTag>;
  createInspectionReturnToMachine?: Maybe<CreateInspectionReturnToMachine>;
  createInvoice?: Maybe<CreateInvoice>;
  createLocation?: Maybe<CreateLocation>;
  createLog?: Maybe<CreateLog>;
  createMachine?: Maybe<CreateMachine>;
  createMachineBreakdown?: Maybe<CreateMachineBreakdown>;
  createMachineBreakdownComment?: Maybe<CreateMachineBreakdownComment>;
  createMachineBulkBreakdown?: Maybe<CreateMachineBulkBreakdown>;
  createMachineInfoAnswer?: Maybe<CreateMachineInfoAnswer>;
  createMachineInfoCategory?: Maybe<CreateMachineInfoCategory>;
  createMachineInfoQuestion?: Maybe<CreateMachineInfoQuestion>;
  createMachineInfoQuestionOption?: Maybe<CreateMachineInfoQuestionOption>;
  createMachineInfoTag?: Maybe<CreateMachineInfoTag>;
  createMachineMake?: Maybe<CreateMachineMake>;
  createMachineModel?: Maybe<CreateMachineModel>;
  createMaintenance?: Maybe<CreateMaintenance>;
  createMaintenanceComment?: Maybe<CreateMaintenanceComment>;
  createMaintenanceInterval?: Maybe<CreateMaintenanceInterval>;
  createOperationHour?: Maybe<CreateOperationHour>;
  createOrder?: Maybe<CreateOrder>;
  createProductCode?: Maybe<CreateProductCode>;
  createQuickTransport?: Maybe<CreateQuickTransport>;
  createReference?: Maybe<CreateReference>;
  createReservation?: Maybe<CreateReservation>;
  createReservationComment?: Maybe<CreateReservationComment>;
  createTransport?: Maybe<CreateTransport>;
  createTransportInvoicing?: Maybe<CreateTransportInvoicing>;
  createTransportInvoicingSummary?: Maybe<CreateTransportInvoicingSummary>;
  createUser?: Maybe<CreateUser>;
  deleteCatalog?: Maybe<DeleteCatalog>;
  deleteCatalogCategory?: Maybe<DeleteCatalogCategory>;
  deleteCatalogCategoryUpper?: Maybe<DeleteCatalogCategoryUpper>;
  deleteCatalogExtraCategoryRental?: Maybe<DeleteCatalogExtraCategoryRental>;
  deleteCatalogExtraCategoryUnitPrice?: Maybe<DeleteCatalogExtraCategoryUnitPrice>;
  deleteCatalogExtraCategoryUpperRental?: Maybe<DeleteCatalogExtraCategoryUpperRental>;
  deleteCatalogExtraRowInvoice?: Maybe<DeleteCatalogExtraRowInvoice>;
  deleteCatalogExtraRowRental?: Maybe<DeleteCatalogExtraRowRental>;
  deleteCatalogExtraRowUnitPrice?: Maybe<DeleteCatalogExtraRowUnitPrice>;
  deleteCatalogRow?: Maybe<DeleteCatalogRow>;
  deleteCatalogTransferMethod?: Maybe<DeleteCatalogTransferMethod>;
  deleteCatalogTransferPrice?: Maybe<DeleteCatalogTransferPrice>;
  deleteCommission?: Maybe<DeleteCommission>;
  deleteCommissionLevel?: Maybe<DeleteCommissionLevel>;
  deleteCommissionRange?: Maybe<DeleteCommissionRange>;
  deleteCommissionTemplate?: Maybe<DeleteCommissionTemplate>;
  deleteCommissionUserSetting?: Maybe<DeleteCommissionUserSetting>;
  deleteCostcenter?: Maybe<DeleteCostcenter>;
  deleteCosttype?: Maybe<DeleteCosttype>;
  deleteCustomer?: Maybe<DeleteCustomer>;
  deleteCustomerArea?: Maybe<DeleteCustomerArea>;
  deleteCustomerIndustry?: Maybe<DeleteCustomerIndustry>;
  deleteCustomerRank?: Maybe<DeleteCustomerRank>;
  deleteDiscountList?: Maybe<DeleteDiscountList>;
  deleteDrive?: Maybe<DeleteDrive>;
  deleteDriveContent?: Maybe<DeleteDriveContent>;
  deleteEvent?: Maybe<DeleteEvent>;
  deleteGroup?: Maybe<DeleteGroup>;
  deleteHoliday?: Maybe<DeleteHoliday>;
  deleteInspectionGiveAnswer?: Maybe<DeleteInspectionGiveAnswer>;
  deleteInspectionGiveCategory?: Maybe<DeleteInspectionGiveCategory>;
  deleteInspectionGiveQuestion?: Maybe<DeleteInspectionGiveQuestion>;
  deleteInspectionGiveQuestionOption?: Maybe<DeleteInspectionGiveQuestionOption>;
  deleteInspectionGiveTag?: Maybe<DeleteInspectionGiveTag>;
  deleteInspectionGiveToMachine?: Maybe<DeleteInspectionGiveToMachine>;
  deleteInspectionReturnAnswer?: Maybe<DeleteInspectionReturnAnswer>;
  deleteInspectionReturnCategory?: Maybe<DeleteInspectionReturnCategory>;
  deleteInspectionReturnQuestion?: Maybe<DeleteInspectionReturnQuestion>;
  deleteInspectionReturnQuestionOption?: Maybe<DeleteInspectionReturnQuestionOption>;
  deleteInspectionReturnTag?: Maybe<DeleteInspectionReturnTag>;
  deleteInspectionReturnToMachine?: Maybe<DeleteInspectionReturnToMachine>;
  deleteInvoice?: Maybe<DeleteInvoice>;
  deleteLocation?: Maybe<DeleteLocation>;
  deleteMachine?: Maybe<DeleteMachine>;
  deleteMachineBreakdown?: Maybe<DeleteMachineBreakdown>;
  deleteMachineBulkBreakdown?: Maybe<DeleteMachineBulkBreakdown>;
  deleteMachineInfoAnswer?: Maybe<DeleteMachineInfoAnswer>;
  deleteMachineInfoCategory?: Maybe<DeleteMachineInfoCategory>;
  deleteMachineInfoQuestion?: Maybe<DeleteMachineInfoQuestion>;
  deleteMachineInfoQuestionOption?: Maybe<DeleteMachineInfoQuestionOption>;
  deleteMachineInfoTag?: Maybe<DeleteMachineInfoTag>;
  deleteMachineMake?: Maybe<DeleteMachineMake>;
  deleteMachineModel?: Maybe<DeleteMachineModel>;
  deleteMaintenance?: Maybe<DeleteMaintenance>;
  deleteMaintenanceComment?: Maybe<DeleteMaintenanceComment>;
  deleteMaintenanceImage?: Maybe<DeleteMaintenanceImage>;
  deleteMaintenanceInterval?: Maybe<DeleteMaintenanceInterval>;
  deleteOperationHour?: Maybe<DeleteOperationHour>;
  deleteOrder?: Maybe<DeleteOrder>;
  deleteProductCode?: Maybe<DeleteProductCode>;
  deleteReference?: Maybe<DeleteReference>;
  deleteReservation?: Maybe<DeleteReservation>;
  deleteReservationImage?: Maybe<DeleteReservationImage>;
  deleteReservationImageBasic?: Maybe<DeleteReservationImageBasic>;
  deleteTransport?: Maybe<DeleteTransport>;
  deleteTransportInvoicing?: Maybe<DeleteTransportInvoicing>;
  deleteTransportInvoicingSummary?: Maybe<DeleteTransportInvoicingSummary>;
  deleteUser?: Maybe<DeleteUser>;
  doInvoiceApprove?: Maybe<DoInvoiceApprove>;
  doInvoiceSend?: Maybe<DoInvoiceSend>;
  getCommissionReportPdf?: Maybe<GetCommissionReportPdf>;
  getConfirmationPdf?: Maybe<GetConfirmationPdf>;
  getContractPdf?: Maybe<GetContractPdf>;
  getDrivePdf?: Maybe<GetDrivePdf>;
  getOfferPdf?: Maybe<GetOfferPdf>;
  getOrderOfferPdf?: Maybe<GetOrderOfferPdf>;
  lockDiscountList?: Maybe<LockDiscountList>;
  moveCatalogExtraRowRentalToLocation?: Maybe<MoveCatalogExtraRowRentalToLocation>;
  moveMachineToLocation?: Maybe<MoveMachineToLocation>;
  permissionForGroupAdd?: Maybe<PermissionForGroupAdd>;
  permissionForGroupDelete?: Maybe<PermissionForGroupDelete>;
  refreshToken?: Maybe<Refresh>;
  removeInspectionGiveTagFromQuestion?: Maybe<RemoveInspectionGiveTagFromQuestion>;
  removeInspectionReturnTagFromQuestion?: Maybe<RemoveInspectionReturnTagFromQuestion>;
  removeMachineInfoTagFromQuestion?: Maybe<RemoveMachineInfoTagFromQuestion>;
  returnPartialReservation?: Maybe<ReturnPartialReservation>;
  saveMachineBulkAmount?: Maybe<SaveMachineBulkAmount>;
  selectCustomerCatalog?: Maybe<SelectCustomerCatalog>;
  selectCustomerCatalogActive?: Maybe<SelectCustomerCatalogActive>;
  selectCustomerDiscountList?: Maybe<SelectCustomerDiscountList>;
  selectLinkedCustomerPricing?: Maybe<SelectLinkedCustomerPricing>;
  sendInvoice?: Maybe<SendInvoice>;
  sendInvoicePayment?: Maybe<SendInvoicePayment>;
  splitOrder?: Maybe<SplitOrder>;
  splitReservation?: Maybe<SplitReservation>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  updateCatalog?: Maybe<UpdateCatalog>;
  updateCatalogCategory?: Maybe<UpdateCatalogCategory>;
  updateCatalogCategoryOrder?: Maybe<UpdateCatalogCategoryOrder>;
  updateCatalogCategoryUpper?: Maybe<UpdateCatalogCategoryUpper>;
  updateCatalogCategoryUpperOrder?: Maybe<UpdateCatalogCategoryUpperOrder>;
  updateCatalogExtraCategoryRental?: Maybe<UpdateCatalogExtraCategoryRental>;
  updateCatalogExtraCategoryRentalOrder?: Maybe<UpdateCatalogExtraCategoryRentalOrder>;
  updateCatalogExtraCategoryUnitPrice?: Maybe<UpdateCatalogExtraCategoryUnitPrice>;
  updateCatalogExtraCategoryUpperRental?: Maybe<UpdateCatalogExtraCategoryUpperRental>;
  updateCatalogExtraCategoryUpperRentalOrder?: Maybe<UpdateCatalogExtraCategoryUpperRentalOrder>;
  updateCatalogExtraRowInvoice?: Maybe<UpdateCatalogExtraRowInvoice>;
  updateCatalogExtraRowRental?: Maybe<UpdateCatalogExtraRowRental>;
  updateCatalogExtraRowRentalOrder?: Maybe<UpdateCatalogExtraRowRentalOrder>;
  updateCatalogExtraRowUnitPrice?: Maybe<UpdateCatalogExtraRowUnitPrice>;
  updateCatalogRow?: Maybe<UpdateCatalogRow>;
  updateCatalogRowLocked?: Maybe<UpdateCatalogRowLocked>;
  updateCatalogRowOrder?: Maybe<UpdateCatalogRowOrder>;
  updateCatalogRowSource?: Maybe<UpdateCatalogRowSource>;
  updateCatalogTransferMethod?: Maybe<UpdateCatalogTransferMethod>;
  updateCatalogTransferPrice?: Maybe<UpdateCatalogTransferPrice>;
  updateCommission?: Maybe<UpdateCommission>;
  updateCommissionLevel?: Maybe<UpdateCommissionLevel>;
  updateCommissionRange?: Maybe<UpdateCommissionRange>;
  updateCommissionTemplate?: Maybe<UpdateCommissionTemplate>;
  updateCommissionUserSetting?: Maybe<UpdateCommissionUserSetting>;
  updateCostcenter?: Maybe<UpdateCostcenter>;
  updateCosttype?: Maybe<UpdateCosttype>;
  updateCosttypeForRentThrough?: Maybe<UpdateCosttypeForRentThrough>;
  updateCustomer?: Maybe<UpdateCustomer>;
  updateCustomerArea?: Maybe<UpdateCustomerArea>;
  updateCustomerIndustry?: Maybe<UpdateCustomerIndustry>;
  updateCustomerParent?: Maybe<UpdateCustomerParent>;
  updateCustomerRank?: Maybe<UpdateCustomerRank>;
  updateDiscountList?: Maybe<UpdateDiscountList>;
  updateDiscountRow?: Maybe<UpdateDiscountRow>;
  updateDiscountRowPercentsPrints?: Maybe<UpdateDiscountRowPercentsPrints>;
  updateDrive?: Maybe<UpdateDrive>;
  updateEvent?: Maybe<UpdateEvent>;
  updateGroup?: Maybe<UpdateGroup>;
  updateHoliday?: Maybe<UpdateHoliday>;
  updateInspectionGiveAnswer?: Maybe<UpdateInspectionGiveAnswer>;
  updateInspectionGiveCategory?: Maybe<UpdateInspectionGiveCategory>;
  updateInspectionGiveCategoryOrder?: Maybe<UpdateInspectionGiveCategoryOrder>;
  updateInspectionGiveQuestion?: Maybe<UpdateInspectionGiveQuestion>;
  updateInspectionGiveQuestionOption?: Maybe<UpdateInspectionGiveQuestionOption>;
  updateInspectionGiveQuestionOrder?: Maybe<UpdateInspectionGiveQuestionOrder>;
  updateInspectionGiveTag?: Maybe<UpdateInspectionGiveTag>;
  updateInspectionReturnAnswer?: Maybe<UpdateInspectionReturnAnswer>;
  updateInspectionReturnCategory?: Maybe<UpdateInspectionReturnCategory>;
  updateInspectionReturnCategoryOrder?: Maybe<UpdateInspectionReturnCategoryOrder>;
  updateInspectionReturnQuestion?: Maybe<UpdateInspectionReturnQuestion>;
  updateInspectionReturnQuestionOption?: Maybe<UpdateInspectionReturnQuestionOption>;
  updateInspectionReturnQuestionOrder?: Maybe<UpdateInspectionReturnQuestionOrder>;
  updateInspectionReturnTag?: Maybe<UpdateInspectionReturnTag>;
  updateInvoice?: Maybe<UpdateInvoice>;
  updateInvoiceRow?: Maybe<UpdateInvoiceRow>;
  updateLocation?: Maybe<UpdateLocation>;
  updateLocationTransfer?: Maybe<UpdateLocationTransfer>;
  updateMachine?: Maybe<UpdateMachine>;
  updateMachineBreakdown?: Maybe<UpdateMachineBreakdown>;
  updateMachineBulkBreakdown?: Maybe<UpdateMachineBulkBreakdown>;
  updateMachineInfoAnswer?: Maybe<UpdateMachineInfoAnswer>;
  updateMachineInfoCategory?: Maybe<UpdateMachineInfoCategory>;
  updateMachineInfoQuestion?: Maybe<UpdateMachineInfoQuestion>;
  updateMachineInfoQuestionOption?: Maybe<UpdateMachineInfoQuestionOption>;
  updateMachineInfoTag?: Maybe<UpdateMachineInfoTag>;
  updateMachineMake?: Maybe<UpdateMachineMake>;
  updateMachineModel?: Maybe<UpdateMachineModel>;
  updateMaintenance?: Maybe<UpdateMaintenance>;
  updateMaintenanceImage?: Maybe<UpdateMaintenanceImage>;
  updateMaintenanceInterval?: Maybe<UpdateMaintenanceInterval>;
  updateMaintenanceSettings?: Maybe<UpdateMaintenanceSettings>;
  updateOperationHour?: Maybe<UpdateOperationHour>;
  updateOrder?: Maybe<UpdateOrder>;
  updateOrderStatus?: Maybe<UpdateOrderStatus>;
  updateProductCode?: Maybe<UpdateProductCode>;
  updateReference?: Maybe<UpdateReference>;
  updateReservation?: Maybe<UpdateReservation>;
  updateReservationImageBasic?: Maybe<UpdateReservationImageBasic>;
  updateReservationStamps?: Maybe<UpdateReservationStamps>;
  updateReservationsReturnAt?: Maybe<UpdateReservationsReturnAt>;
  updateSettings?: Maybe<UpdateSettings>;
  updateTransport?: Maybe<UpdateTransport>;
  updateTransportInvoicing?: Maybe<UpdateTransportInvoicing>;
  updateTransportInvoicingSummary?: Maybe<UpdateTransportInvoicingSummary>;
  updateUser?: Maybe<UpdateUser>;
  uploadCatalogCategory?: Maybe<UploadCatalogCategory>;
  uploadCatalogRow?: Maybe<UploadCatalogRow>;
  uploadFileCatalogCoverBack?: Maybe<UploadFileCatalogCoverBack>;
  uploadFileCatalogCoverFront?: Maybe<UploadFileCatalogCoverFront>;
  uploadFileCatalogCustomerCoverBack?: Maybe<UploadFileCatalogCustomerCoverBack>;
  uploadFileCatalogCustomerCoverFront?: Maybe<UploadFileCatalogCustomerCoverFront>;
  uploadFileConfirmationCoverBack?: Maybe<UploadFileConfirmationCoverBack>;
  uploadFileConfirmationCoverFront?: Maybe<UploadFileConfirmationCoverFront>;
  uploadFileMachineCheckList?: Maybe<UploadFileMachineCheckList>;
  uploadFileMachineFinancing?: Maybe<UploadFileMachineFinancing>;
  uploadFileMachinePurchase?: Maybe<UploadFileMachinePurchase>;
  uploadFileMachineTypePlate?: Maybe<UploadFileMachineTypePlate>;
  uploadMaintenanceImage?: Maybe<UploadMaintenanceImage>;
  uploadReservationImageBasic?: Maybe<UploadReservationImageBasic>;
  uploadReservationImages?: Maybe<UploadReservationImages>;
  verifyToken?: Maybe<Verify>;
};


export type MutationAddInspectionGiveTagToMachineArgs = {
  inspectionGiveTagIds: Array<InputMaybe<Scalars['ID']>>;
  machineId: Scalars['ID'];
};


export type MutationAddInspectionGiveTagToQuestionArgs = {
  inspectionGiveQuestionId: Scalars['ID'];
  inspectionGiveTagId: Scalars['ID'];
};


export type MutationAddInspectionReturnTagToMachineArgs = {
  inspectionReturnTagIds: Array<InputMaybe<Scalars['ID']>>;
  machineId: Scalars['ID'];
};


export type MutationAddInspectionReturnTagToQuestionArgs = {
  inspectionReturnQuestionId: Scalars['ID'];
  inspectionReturnTagId: Scalars['ID'];
};


export type MutationAddMachineInfoTagToMachineArgs = {
  machineId: Scalars['ID'];
  machineInfoTagIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationAddMachineInfoTagToQuestionArgs = {
  machineInfoQuestionId: Scalars['ID'];
  machineInfoTagId: Scalars['ID'];
};


export type MutationCloneDiscountListArgs = {
  customerId: Scalars['ID'];
  discountListId: Scalars['ID'];
};


export type MutationCloneDiscountListFromCatalogArgs = {
  catalogId: Scalars['ID'];
  customerId: Scalars['ID'];
};


export type MutationConnectMachineCatalogExtraRowRentalArgs = {
  data?: InputMaybe<Array<InputMaybe<ConnectIdsType>>>;
};


export type MutationConnectMachineCatalogExtraRowUnitPriceArgs = {
  data?: InputMaybe<Array<InputMaybe<ConnectIdsType>>>;
};


export type MutationCopyCatalogExtraRowRentalMachinesArgs = {
  catalogExtraRowRentalIdCopyFrom: Scalars['ID'];
  catalogExtraRowRentalIdCopyTo: Scalars['ID'];
};


export type MutationCopyCatalogExtraRowUnitPriceMachinesArgs = {
  catalogExtraRowUnitPriceIdCopyFrom: Scalars['ID'];
  catalogExtraRowUnitPriceIdCopyTo: Scalars['ID'];
};


export type MutationCopyInspectionGiveTagsArgs = {
  machineIdFrom: Scalars['ID'];
  machineIdTo: Scalars['ID'];
};


export type MutationCopyInspectionReturnTagsArgs = {
  machineIdFrom: Scalars['ID'];
  machineIdTo: Scalars['ID'];
};


export type MutationCopyMachineCatalogExtraRowRentalArgs = {
  machineIdCopyFrom: Scalars['ID'];
  machineIdCopyTo: Scalars['ID'];
};


export type MutationCopyMachineCatalogExtraRowUnitPriceArgs = {
  machineIdCopyFrom: Scalars['ID'];
  machineIdCopyTo: Scalars['ID'];
};


export type MutationCopyMachineInfoTagsArgs = {
  machineIdFrom: Scalars['ID'];
  machineIdTo: Scalars['ID'];
};


export type MutationCreateCatalogArgs = {
  catalogIdBase?: InputMaybe<Scalars['ID']>;
  forceSynch?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationCreateCatalogCategoryArgs = {
  catalogCategoryUpperId?: InputMaybe<Scalars['ID']>;
  information?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateCatalogCategoryUpperArgs = {
  catalogId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};


export type MutationCreateCatalogExtraCategoryRentalArgs = {
  catalogExtraCategoryUpperRentalId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateCatalogExtraCategoryUnitPriceArgs = {
  name: Scalars['String'];
  printSeparately: Scalars['Boolean'];
};


export type MutationCreateCatalogExtraCategoryUpperRentalArgs = {
  name: Scalars['String'];
};


export type MutationCreateCatalogExtraRowInvoiceArgs = {
  answer: Scalars['String'];
  catalogExtraRowUnitPriceId?: InputMaybe<Scalars['ID']>;
  invoiceRowId?: InputMaybe<Scalars['ID']>;
  productCodeId: Scalars['ID'];
  quantity: Scalars['Decimal'];
  reservationId: Scalars['ID'];
  titleOverride: Scalars['String'];
  unitPriceOverride: Scalars['Decimal'];
};


export type MutationCreateCatalogExtraRowRentalArgs = {
  billingDaysWeekCompany: Scalars['Int'];
  billingDaysWeekPrivate: Scalars['Int'];
  catalogExtraCategoryRentalId: Scalars['ID'];
  identifier: Scalars['String'];
  information: Scalars['String'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
  priceDayCompany: Scalars['Decimal'];
  priceDayPrivate: Scalars['Decimal'];
  priceMonthCompany: Scalars['Decimal'];
  priceMonthPrivate: Scalars['Decimal'];
  productNumber: Scalars['String'];
  showPrint: Scalars['Boolean'];
};


export type MutationCreateCatalogExtraRowUnitPriceArgs = {
  allowDiscount: Scalars['Boolean'];
  catalogExtraCategoryUnitPriceId: Scalars['ID'];
  costcenterId: Scalars['ID'];
  costtypeId: Scalars['ID'];
  minCharge: Scalars['Int'];
  name: Scalars['String'];
  productCodeId: Scalars['ID'];
  requireAnswer: Scalars['Boolean'];
  showPrint: Scalars['Boolean'];
  unit: Scalars['String'];
  unitPrice: Scalars['Decimal'];
};


export type MutationCreateCatalogRowArgs = {
  billingDaysWeekCompany: Scalars['Int'];
  billingDaysWeekPrivate: Scalars['Int'];
  catalogCategoryId: Scalars['ID'];
  extraHoursCompany: Scalars['Decimal'];
  extraHoursPrivate: Scalars['Decimal'];
  hourLimitDayCompany: Scalars['Int'];
  hourLimitDayPrivate: Scalars['Int'];
  hourLimitMonthCompany: Scalars['Int'];
  hourLimitMonthPrivate: Scalars['Int'];
  information?: InputMaybe<Scalars['String']>;
  machineIds: Array<InputMaybe<Scalars['ID']>>;
  name: Scalars['String'];
  priceDayCompany: Scalars['Decimal'];
  priceDayPrivate: Scalars['Decimal'];
  priceMonthCompany: Scalars['Decimal'];
  priceMonthPrivate: Scalars['Decimal'];
  productNumberDay?: InputMaybe<Scalars['String']>;
  productNumberMonth?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCatalogTransferMethodArgs = {
  name: Scalars['String'];
};


export type MutationCreateCatalogTransferPriceArgs = {
  catalogTransferMethodId: Scalars['ID'];
  locationId: Scalars['ID'];
  priceBasic: Scalars['Decimal'];
  priceExtra: Scalars['Decimal'];
  priceStop: Scalars['Decimal'];
  productNumberBasic: Scalars['String'];
  productNumberExtra: Scalars['String'];
  productNumberStop: Scalars['String'];
};


export type MutationCreateCommissionArgs = {
  case: Scalars['String'];
  orderId: Scalars['ID'];
  userSellerId: Scalars['ID'];
};


export type MutationCreateCommissionLevelArgs = {
  commissionRangeId: Scalars['ID'];
  commissionTemplateId: Scalars['ID'];
  maxCommission: Scalars['Decimal'];
  maxDiscount: Scalars['Decimal'];
  minCommission: Scalars['Decimal'];
};


export type MutationCreateCommissionRangeArgs = {
  commissionTemplateId: Scalars['ID'];
  daysMax: Scalars['Int'];
};


export type MutationCreateCommissionReportsArgs = {
  month: Scalars['Int'];
  userIds: Array<InputMaybe<Scalars['ID']>>;
  year: Scalars['Int'];
};


export type MutationCreateCommissionTemplateArgs = {
  baseSalary: Scalars['Decimal'];
  name: Scalars['String'];
};


export type MutationCreateCostcenterArgs = {
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCosttypeArgs = {
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCustomerArgs = {
  blockedAt?: InputMaybe<Scalars['DateTime']>;
  blockedReason: Scalars['String'];
  businessId: Scalars['String'];
  contactNext?: InputMaybe<Scalars['Date']>;
  contactPersonFirstname: Scalars['String'];
  contactPersonLastname: Scalars['String'];
  creditInformationCheckedAt?: InputMaybe<Scalars['Date']>;
  creditInformationOk: Scalars['Boolean'];
  creditRiskClass: Scalars['String'];
  customerAreaIds: Array<InputMaybe<Scalars['ID']>>;
  customerIdParent?: InputMaybe<Scalars['ID']>;
  customerIndustryIds: Array<InputMaybe<Scalars['ID']>>;
  customerRankId: Scalars['ID'];
  email: Scalars['String'];
  hasInsurance: Scalars['Boolean'];
  invoicingAddress: Scalars['String'];
  invoicingDistrict: Scalars['String'];
  invoicingEAddress: Scalars['String'];
  invoicingEOperator: Scalars['String'];
  invoicingEmail: Scalars['String'];
  invoicingPostcode: Scalars['String'];
  isBusiness: Scalars['Boolean'];
  name: Scalars['String'];
  noBillingSurchargeAt?: InputMaybe<Scalars['DateTime']>;
  paymentTerm?: InputMaybe<Scalars['Int']>;
  phone: Scalars['String'];
  preInvoicingAt?: InputMaybe<Scalars['DateTime']>;
  preInvoicingReason: Scalars['String'];
  useParentInvoicing: Scalars['Boolean'];
  useParentPricing: Scalars['Boolean'];
  userOwnerId: Scalars['ID'];
  visitAddress: Scalars['String'];
  visitDistrict: Scalars['String'];
  visitPostcode: Scalars['String'];
};


export type MutationCreateCustomerAreaArgs = {
  title: Scalars['String'];
};


export type MutationCreateCustomerIndustryArgs = {
  title: Scalars['String'];
};


export type MutationCreateCustomerRankArgs = {
  order: Scalars['Int'];
  title: Scalars['String'];
};


export type MutationCreateDiscountListArgs = {
  catalogId: Scalars['ID'];
  isBusiness: Scalars['Boolean'];
  name: Scalars['String'];
};


export type MutationCreateDiscountRowArgs = {
  catalogRowId: Scalars['ID'];
  discountListId: Scalars['ID'];
  percent?: InputMaybe<Scalars['Decimal']>;
  showPrint: Scalars['Boolean'];
};


export type MutationCreateDriveArgs = {
  transportReservation: Array<InputMaybe<TransportReservationType>>;
};


export type MutationCreateEventArgs = {
  customerId: Scalars['ID'];
  date: Scalars['Date'];
  eventType: Scalars['String'];
  information: Scalars['String'];
};


export type MutationCreateGroupArgs = {
  name: Scalars['String'];
};


export type MutationCreateHolidayArgs = {
  date: Scalars['Date'];
  holidayType: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateInspectionGiveAnswerArgs = {
  answer?: InputMaybe<Scalars['String']>;
  checked?: InputMaybe<Scalars['Boolean']>;
  inspectionGiveQuestionOptionId?: InputMaybe<Scalars['ID']>;
  inspectionGiveToMachineIds: Array<InputMaybe<Scalars['ID']>>;
  reservationId: Scalars['ID'];
};


export type MutationCreateInspectionGiveCategoryArgs = {
  name: Scalars['String'];
};


export type MutationCreateInspectionGiveQuestionArgs = {
  answerType: Scalars['String'];
  inspectionGiveCategoryId: Scalars['ID'];
  question: Scalars['String'];
};


export type MutationCreateInspectionGiveQuestionOptionArgs = {
  inspectionGiveQuestionId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateInspectionGiveTagArgs = {
  inspectionGiveQuestionId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateInspectionGiveToMachineArgs = {
  inspectionGiveQuestionIds: Array<InputMaybe<Scalars['ID']>>;
  machineId: Scalars['ID'];
};


export type MutationCreateInspectionReturnAnswerArgs = {
  answer?: InputMaybe<Scalars['String']>;
  checked?: InputMaybe<Scalars['Boolean']>;
  inspectionReturnQuestionOptionId?: InputMaybe<Scalars['ID']>;
  inspectionReturnToMachineIds: Array<InputMaybe<Scalars['ID']>>;
  reservationId: Scalars['ID'];
};


export type MutationCreateInspectionReturnCategoryArgs = {
  name: Scalars['String'];
};


export type MutationCreateInspectionReturnQuestionArgs = {
  answerType: Scalars['String'];
  inspectionReturnCategoryId: Scalars['ID'];
  question: Scalars['String'];
};


export type MutationCreateInspectionReturnQuestionOptionArgs = {
  inspectionReturnQuestionId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateInspectionReturnTagArgs = {
  inspectionReturnQuestionId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateInspectionReturnToMachineArgs = {
  inspectionReturnQuestionIds: Array<InputMaybe<Scalars['ID']>>;
  machineId: Scalars['ID'];
};


export type MutationCreateInvoiceArgs = {
  catalogExtraRowInvoiceIds: Array<InputMaybe<Scalars['ID']>>;
  dateTo?: InputMaybe<Scalars['Date']>;
  isAdvance: Scalars['Boolean'];
  operationHourIds: Array<InputMaybe<Scalars['ID']>>;
  referenceId: Scalars['ID'];
  reservationIds: Array<InputMaybe<Scalars['ID']>>;
  transportInvoicingIds: Array<InputMaybe<Scalars['ID']>>;
  transportInvoicingSummaryIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationCreateLocationArgs = {
  dimIdentifier: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateLogArgs = {
  date?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateMachineArgs = {
  acquisitionDate: Scalars['Date'];
  bulkProduct: Scalars['Boolean'];
  catalogRowIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateRemoved?: InputMaybe<Scalars['Date']>;
  hourTracking: Scalars['Boolean'];
  identifier: Scalars['String'];
  information: Scalars['String'];
  locationId: Scalars['ID'];
  machineModelId: Scalars['ID'];
  rentThrough: Scalars['Boolean'];
  rentThroughCommissionCase: Scalars['String'];
  rentThroughCompany: Scalars['String'];
  rentThroughInformation: Scalars['String'];
  serial: Scalars['String'];
  yearModel: Scalars['Int'];
};


export type MutationCreateMachineBreakdownArgs = {
  amountBilledSingle: Scalars['Decimal'];
  billing: Scalars['String'];
  catalogExtraRowRentalId?: InputMaybe<Scalars['ID']>;
  fixByEnd: Scalars['Date'];
  fixByStart: Scalars['Date'];
  information: Scalars['String'];
  machineId?: InputMaybe<Scalars['ID']>;
  stillRentable: Scalars['Boolean'];
  title: Scalars['String'];
};


export type MutationCreateMachineBreakdownCommentArgs = {
  comment: Scalars['String'];
  machineBreakdownId: Scalars['ID'];
};


export type MutationCreateMachineBulkBreakdownArgs = {
  amountBroken: Scalars['Int'];
  fixable: Scalars['Boolean'];
  fixedAt?: InputMaybe<Scalars['DateTime']>;
  machineId: Scalars['ID'];
  reservationId: Scalars['ID'];
};


export type MutationCreateMachineInfoAnswerArgs = {
  answer?: InputMaybe<Scalars['String']>;
  machineId: Scalars['ID'];
  machineInfoQuestionIds: Array<InputMaybe<Scalars['ID']>>;
  machineInfoQuestionOptionId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateMachineInfoCategoryArgs = {
  name: Scalars['String'];
};


export type MutationCreateMachineInfoQuestionArgs = {
  answerType: Scalars['String'];
  machineInfoCategoryId: Scalars['ID'];
  question: Scalars['String'];
  showDrive: Scalars['Boolean'];
  showProductCard: Scalars['Boolean'];
};


export type MutationCreateMachineInfoQuestionOptionArgs = {
  machineInfoQuestionId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateMachineInfoTagArgs = {
  machineInfoQuestionId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateMachineMakeArgs = {
  title: Scalars['String'];
};


export type MutationCreateMachineModelArgs = {
  machineMakeId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationCreateMaintenanceArgs = {
  catalogExtraRowRentalId?: InputMaybe<Scalars['ID']>;
  createdManually?: InputMaybe<Scalars['Boolean']>;
  estimatedDate: Scalars['Date'];
  machineId?: InputMaybe<Scalars['ID']>;
  maintenanceIntervalId: Scalars['ID'];
  maintenanceType?: InputMaybe<Scalars['String']>;
  serviceEndBy?: InputMaybe<Scalars['Date']>;
  serviceStartBy?: InputMaybe<Scalars['Date']>;
  serviceTechnicianId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateMaintenanceCommentArgs = {
  comment: Scalars['String'];
  maintenanceId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateMaintenanceIntervalArgs = {
  days: Scalars['Int'];
  description: Scalars['String'];
  hours: Scalars['Int'];
  maintenanceSettingId: Scalars['ID'];
};


export type MutationCreateOperationHourArgs = {
  dateChecked: Scalars['Date'];
  hours: Scalars['Int'];
  machineId: Scalars['ID'];
  maintenanceId: Scalars['ID'];
  reservationId: Scalars['ID'];
  source: Scalars['String'];
};


export type MutationCreateOrderArgs = {
  advanceValidUntil?: InputMaybe<Scalars['Date']>;
  confirmationType: Scalars['String'];
  customerId: Scalars['ID'];
  hasInsurance: Scalars['Boolean'];
  information: Scalars['String'];
  informationInvoice: Scalars['String'];
  isBusinessIfNoCustomer: Scalars['Boolean'];
  locationId: Scalars['ID'];
  referenceId: Scalars['ID'];
  status: Scalars['String'];
  updatePricesInCatalogSwitch: Scalars['Boolean'];
};


export type MutationCreateProductCodeArgs = {
  code: Scalars['String'];
  costcenterId?: InputMaybe<Scalars['ID']>;
  costtypeId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
};


export type MutationCreateQuickTransportArgs = {
  address?: InputMaybe<Scalars['String']>;
  catalogTransferMethodId: Scalars['ID'];
  district?: InputMaybe<Scalars['String']>;
  km: Scalars['Int'];
  method: Scalars['String'];
  orderId: Scalars['ID'];
  postcode?: InputMaybe<Scalars['String']>;
};


export type MutationCreateReferenceArgs = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateReservationArgs = {
  bulkAmount: Scalars['Int'];
  catalogExtraRowRentalId?: InputMaybe<Scalars['ID']>;
  catalogRowId?: InputMaybe<Scalars['ID']>;
  dateBillingMin?: InputMaybe<Scalars['Date']>;
  dateRented: Scalars['Date'];
  dateReturned: Scalars['Date'];
  deliveryMethod: Scalars['String'];
  information: Scalars['String'];
  machineId?: InputMaybe<Scalars['ID']>;
  orderId: Scalars['ID'];
  timeRented?: InputMaybe<Scalars['Time']>;
  timeReturned?: InputMaybe<Scalars['Time']>;
};


export type MutationCreateReservationCommentArgs = {
  comment: Scalars['String'];
  reservationId: Scalars['ID'];
};


export type MutationCreateTransportArgs = {
  address: Scalars['String'];
  catalogTransferMethodId: Scalars['ID'];
  dateDeadline?: InputMaybe<Scalars['Date']>;
  dateEndUsage?: InputMaybe<Scalars['Date']>;
  district: Scalars['String'];
  information: Scalars['String'];
  method: Scalars['String'];
  orderId: Scalars['ID'];
  postcode: Scalars['String'];
  reservationIds: Array<InputMaybe<Scalars['ID']>>;
  timeDeadline?: InputMaybe<Scalars['Time']>;
  timeEndUsage?: InputMaybe<Scalars['Time']>;
};


export type MutationCreateTransportInvoicingArgs = {
  catalogTransferMethodId: Scalars['ID'];
  description: Scalars['String'];
  discountPercent: Scalars['Decimal'];
  method: Scalars['String'];
  orderId: Scalars['ID'];
  quantity: Scalars['Decimal'];
  referenceId: Scalars['ID'];
  rowType: Scalars['String'];
  title: Scalars['String'];
  unitPrice: Scalars['Decimal'];
};


export type MutationCreateTransportInvoicingSummaryArgs = {
  orderId: Scalars['ID'];
  percent: Scalars['Decimal'];
  price: Scalars['Decimal'];
  referenceId: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  address?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  groupId: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


export type MutationDeleteCatalogArgs = {
  catalogId: Scalars['ID'];
};


export type MutationDeleteCatalogCategoryArgs = {
  catalogCategoryId: Scalars['ID'];
};


export type MutationDeleteCatalogCategoryUpperArgs = {
  catalogCategoryUpperId: Scalars['ID'];
};


export type MutationDeleteCatalogExtraCategoryRentalArgs = {
  catalogExtraCategoryRentalId: Scalars['ID'];
};


export type MutationDeleteCatalogExtraCategoryUnitPriceArgs = {
  catalogExtraCategoryUnitPriceId: Scalars['ID'];
};


export type MutationDeleteCatalogExtraCategoryUpperRentalArgs = {
  catalogExtraCategoryUpperRentalId: Scalars['ID'];
};


export type MutationDeleteCatalogExtraRowInvoiceArgs = {
  catalogExtraRowInvoiceId: Scalars['ID'];
};


export type MutationDeleteCatalogExtraRowRentalArgs = {
  catalogExtraRowRentalId: Scalars['ID'];
};


export type MutationDeleteCatalogExtraRowUnitPriceArgs = {
  catalogExtraRowUnitPriceId: Scalars['ID'];
};


export type MutationDeleteCatalogRowArgs = {
  catalogRowId: Scalars['ID'];
};


export type MutationDeleteCatalogTransferMethodArgs = {
  catalogTransferMethodId: Scalars['ID'];
};


export type MutationDeleteCatalogTransferPriceArgs = {
  catalogTransferPriceId: Scalars['ID'];
};


export type MutationDeleteCommissionArgs = {
  commissionId: Scalars['ID'];
};


export type MutationDeleteCommissionLevelArgs = {
  commissionLevelId: Scalars['ID'];
};


export type MutationDeleteCommissionRangeArgs = {
  commissionRangeId: Scalars['ID'];
};


export type MutationDeleteCommissionTemplateArgs = {
  commissionTemplateId: Scalars['ID'];
};


export type MutationDeleteCommissionUserSettingArgs = {
  commissionUserSettingId: Scalars['ID'];
};


export type MutationDeleteCostcenterArgs = {
  costcenterId: Scalars['ID'];
};


export type MutationDeleteCosttypeArgs = {
  costtypeId: Scalars['ID'];
};


export type MutationDeleteCustomerArgs = {
  customerId: Scalars['ID'];
};


export type MutationDeleteCustomerAreaArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerIndustryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerRankArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDiscountListArgs = {
  discountListId: Scalars['ID'];
};


export type MutationDeleteDriveArgs = {
  driveId: Scalars['ID'];
};


export type MutationDeleteDriveContentArgs = {
  driveContentId: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationDeleteHolidayArgs = {
  holidayId: Scalars['ID'];
};


export type MutationDeleteInspectionGiveAnswerArgs = {
  inspectionGiveAnswerId: Scalars['ID'];
};


export type MutationDeleteInspectionGiveCategoryArgs = {
  inspectionGiveCategoryId: Scalars['ID'];
};


export type MutationDeleteInspectionGiveQuestionArgs = {
  inspectionGiveQuestionId: Scalars['ID'];
};


export type MutationDeleteInspectionGiveQuestionOptionArgs = {
  inspectionGiveQuestionOptionId: Scalars['ID'];
};


export type MutationDeleteInspectionGiveTagArgs = {
  inspectionGiveTagId: Scalars['ID'];
};


export type MutationDeleteInspectionGiveToMachineArgs = {
  inspectionGiveToMachineId: Scalars['ID'];
};


export type MutationDeleteInspectionReturnAnswerArgs = {
  inspectionReturnAnswerId: Scalars['ID'];
};


export type MutationDeleteInspectionReturnCategoryArgs = {
  inspectionReturnCategoryId: Scalars['ID'];
};


export type MutationDeleteInspectionReturnQuestionArgs = {
  inspectionReturnQuestionId: Scalars['ID'];
};


export type MutationDeleteInspectionReturnQuestionOptionArgs = {
  inspectionReturnQuestionOptionId: Scalars['ID'];
};


export type MutationDeleteInspectionReturnTagArgs = {
  inspectionReturnTagId: Scalars['ID'];
};


export type MutationDeleteInspectionReturnToMachineArgs = {
  inspectionReturnToMachineId: Scalars['ID'];
};


export type MutationDeleteInvoiceArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  locationId: Scalars['ID'];
};


export type MutationDeleteMachineArgs = {
  machineId: Scalars['ID'];
};


export type MutationDeleteMachineBreakdownArgs = {
  machineBreakdownId: Scalars['ID'];
};


export type MutationDeleteMachineBulkBreakdownArgs = {
  machineBulkBreakdownId: Scalars['ID'];
};


export type MutationDeleteMachineInfoAnswerArgs = {
  machineInfoAnswerId: Scalars['ID'];
};


export type MutationDeleteMachineInfoCategoryArgs = {
  machineInfoCategoryId: Scalars['ID'];
};


export type MutationDeleteMachineInfoQuestionArgs = {
  machineInfoQuestionId: Scalars['ID'];
};


export type MutationDeleteMachineInfoQuestionOptionArgs = {
  machineInfoQuestionOptionId: Scalars['ID'];
};


export type MutationDeleteMachineInfoTagArgs = {
  machineInfoTagId: Scalars['ID'];
};


export type MutationDeleteMachineMakeArgs = {
  machineMakeId: Scalars['ID'];
};


export type MutationDeleteMachineModelArgs = {
  machineModelId: Scalars['ID'];
};


export type MutationDeleteMaintenanceArgs = {
  maintenanceId: Scalars['ID'];
};


export type MutationDeleteMaintenanceCommentArgs = {
  maintenanceCommentId: Scalars['ID'];
};


export type MutationDeleteMaintenanceImageArgs = {
  maintenanceImageId: Scalars['ID'];
};


export type MutationDeleteMaintenanceIntervalArgs = {
  maintenanceIntervalId: Scalars['ID'];
};


export type MutationDeleteOperationHourArgs = {
  operationHourId: Scalars['ID'];
};


export type MutationDeleteOrderArgs = {
  orderId: Scalars['ID'];
};


export type MutationDeleteProductCodeArgs = {
  productCodeId: Scalars['ID'];
};


export type MutationDeleteReferenceArgs = {
  referenceId: Scalars['ID'];
};


export type MutationDeleteReservationArgs = {
  reservationId: Scalars['ID'];
};


export type MutationDeleteReservationImageArgs = {
  reservationImageId: Scalars['ID'];
};


export type MutationDeleteReservationImageBasicArgs = {
  reservationImageBasicId: Scalars['ID'];
};


export type MutationDeleteTransportArgs = {
  transportId: Scalars['ID'];
};


export type MutationDeleteTransportInvoicingArgs = {
  transportInvoicingId: Scalars['ID'];
};


export type MutationDeleteTransportInvoicingSummaryArgs = {
  transportInvoicingSummaryId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDoInvoiceApproveArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationDoInvoiceSendArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationGetCommissionReportPdfArgs = {
  month: Scalars['Int'];
  userIds: Array<InputMaybe<Scalars['ID']>>;
  year: Scalars['Int'];
};


export type MutationGetConfirmationPdfArgs = {
  orderId: Scalars['ID'];
};


export type MutationGetContractPdfArgs = {
  orderId: Scalars['ID'];
};


export type MutationGetDrivePdfArgs = {
  driveId: Scalars['ID'];
};


export type MutationGetOfferPdfArgs = {
  catalogId?: InputMaybe<Scalars['ID']>;
  catalogRowIdsPrinted?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customerId?: InputMaybe<Scalars['ID']>;
  discountListId?: InputMaybe<Scalars['ID']>;
};


export type MutationGetOrderOfferPdfArgs = {
  offerText: Scalars['String'];
  orderId: Scalars['ID'];
};


export type MutationLockDiscountListArgs = {
  discountListId: Scalars['ID'];
};


export type MutationMoveCatalogExtraRowRentalToLocationArgs = {
  catalogExtraRowRentalId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type MutationMoveMachineToLocationArgs = {
  locationId: Scalars['ID'];
  machineId: Scalars['ID'];
};


export type MutationPermissionForGroupAddArgs = {
  groupId: Scalars['ID'];
  permissionId: Scalars['ID'];
};


export type MutationPermissionForGroupDeleteArgs = {
  groupId: Scalars['ID'];
  permissionId: Scalars['ID'];
};


export type MutationRefreshTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationRemoveInspectionGiveTagFromQuestionArgs = {
  inspectionGiveQuestionId: Scalars['ID'];
  inspectionGiveTagId: Scalars['ID'];
};


export type MutationRemoveInspectionReturnTagFromQuestionArgs = {
  inspectionReturnQuestionId: Scalars['ID'];
  inspectionReturnTagId: Scalars['ID'];
};


export type MutationRemoveMachineInfoTagFromQuestionArgs = {
  machineInfoQuestionId: Scalars['ID'];
  machineInfoTagId: Scalars['ID'];
};


export type MutationReturnPartialReservationArgs = {
  bulkAmountReturned: Scalars['Int'];
  catalogExtraRowRentalId?: InputMaybe<Scalars['ID']>;
  dateEnd: Scalars['Date'];
  machineId?: InputMaybe<Scalars['ID']>;
  reservationId: Scalars['ID'];
};


export type MutationSaveMachineBulkAmountArgs = {
  bulkAmount: Scalars['Int'];
  locationId: Scalars['ID'];
  machineId: Scalars['ID'];
};


export type MutationSelectCustomerCatalogArgs = {
  catalogId: Scalars['ID'];
  customerId: Scalars['ID'];
};


export type MutationSelectCustomerCatalogActiveArgs = {
  customerId: Scalars['ID'];
};


export type MutationSelectCustomerDiscountListArgs = {
  customerId: Scalars['ID'];
  discountListId: Scalars['ID'];
};


export type MutationSelectLinkedCustomerPricingArgs = {
  customerId: Scalars['ID'];
  useParentPricing: Scalars['Boolean'];
};


export type MutationSendInvoiceArgs = {
  emailInvoicing?: InputMaybe<Scalars['String']>;
  invoiceDeliveryMethod: InvoiceDeliveryMethods;
  invoiceId: Scalars['ID'];
  invoiceSendMethod: InvoiceSendMethods;
};


export type MutationSendInvoicePaymentArgs = {
  invoiceId: Scalars['ID'];
};


export type MutationSplitOrderArgs = {
  dateEnd: Scalars['Date'];
  orderId: Scalars['ID'];
  reservationIds: Array<InputMaybe<Scalars['ID']>>;
};


export type MutationSplitReservationArgs = {
  bulkAmountOld?: InputMaybe<Scalars['Int']>;
  catalogExtraRowRentalId?: InputMaybe<Scalars['ID']>;
  catalogRowId?: InputMaybe<Scalars['ID']>;
  dateEnd: Scalars['Date'];
  machineId?: InputMaybe<Scalars['ID']>;
  reservationId: Scalars['ID'];
};


export type MutationTokenAuthArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationUpdateCatalogArgs = {
  catalogId: Scalars['ID'];
  name: Scalars['String'];
  setActiveAt?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateCatalogCategoryArgs = {
  catalogCategoryId: Scalars['ID'];
  catalogCategoryUpperId?: InputMaybe<Scalars['ID']>;
  information?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUpdateCatalogCategoryOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateCatalogCategoryUpperArgs = {
  catalogCategoryUpperId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateCatalogCategoryUpperOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateCatalogExtraCategoryRentalArgs = {
  catalogExtraCategoryRentalId: Scalars['ID'];
  catalogExtraCategoryUpperRentalId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateCatalogExtraCategoryRentalOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateCatalogExtraCategoryUnitPriceArgs = {
  catalogExtraCategoryUnitPriceId: Scalars['ID'];
  name: Scalars['String'];
  printSeparately: Scalars['Boolean'];
};


export type MutationUpdateCatalogExtraCategoryUpperRentalArgs = {
  catalogExtraCategoryUpperRentalId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateCatalogExtraCategoryUpperRentalOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateCatalogExtraRowInvoiceArgs = {
  answer: Scalars['String'];
  catalogExtraRowInvoiceId: Scalars['ID'];
  catalogExtraRowUnitPriceId?: InputMaybe<Scalars['ID']>;
  invoiceRowId?: InputMaybe<Scalars['ID']>;
  productCodeId: Scalars['ID'];
  quantity: Scalars['Decimal'];
  reservationId: Scalars['ID'];
  titleOverride: Scalars['String'];
  unitPriceOverride: Scalars['Decimal'];
};


export type MutationUpdateCatalogExtraRowRentalArgs = {
  billingDaysWeekCompany: Scalars['Int'];
  billingDaysWeekPrivate: Scalars['Int'];
  catalogExtraCategoryRentalId: Scalars['ID'];
  catalogExtraRowRentalId: Scalars['ID'];
  identifier: Scalars['String'];
  information: Scalars['String'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
  priceDayCompany: Scalars['Decimal'];
  priceDayPrivate: Scalars['Decimal'];
  priceMonthCompany: Scalars['Decimal'];
  priceMonthPrivate: Scalars['Decimal'];
  productNumber: Scalars['String'];
  showPrint: Scalars['Boolean'];
};


export type MutationUpdateCatalogExtraRowRentalOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateCatalogExtraRowUnitPriceArgs = {
  allowDiscount?: InputMaybe<Scalars['Boolean']>;
  catalogExtraCategoryUnitPriceId?: InputMaybe<Scalars['ID']>;
  catalogExtraRowUnitPriceId: Scalars['ID'];
  costcenterId?: InputMaybe<Scalars['ID']>;
  costtypeId?: InputMaybe<Scalars['ID']>;
  minCharge?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  productCodeId?: InputMaybe<Scalars['ID']>;
  requireAnswer?: InputMaybe<Scalars['Boolean']>;
  showPrint?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['Decimal']>;
};


export type MutationUpdateCatalogRowArgs = {
  billingDaysWeekCompany?: InputMaybe<Scalars['Int']>;
  billingDaysWeekPrivate?: InputMaybe<Scalars['Int']>;
  catalogCategoryId?: InputMaybe<Scalars['ID']>;
  catalogRowId: Scalars['ID'];
  extraHoursCompany?: InputMaybe<Scalars['Decimal']>;
  extraHoursPrivate?: InputMaybe<Scalars['Decimal']>;
  hourLimitDayCompany?: InputMaybe<Scalars['Int']>;
  hourLimitDayPrivate?: InputMaybe<Scalars['Int']>;
  hourLimitMonthCompany?: InputMaybe<Scalars['Int']>;
  hourLimitMonthPrivate?: InputMaybe<Scalars['Int']>;
  information?: InputMaybe<Scalars['String']>;
  machineIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  priceDayCompany?: InputMaybe<Scalars['Decimal']>;
  priceDayPrivate?: InputMaybe<Scalars['Decimal']>;
  priceMonthCompany?: InputMaybe<Scalars['Decimal']>;
  priceMonthPrivate?: InputMaybe<Scalars['Decimal']>;
  productNumberDay?: InputMaybe<Scalars['String']>;
  productNumberMonth?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCatalogRowLockedArgs = {
  catalogRowId: Scalars['ID'];
  information?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productNumberDay?: InputMaybe<Scalars['String']>;
  productNumberMonth?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCatalogRowOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateCatalogRowSourceArgs = {
  catalogRowId: Scalars['ID'];
  catalogRowIdSource?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateCatalogTransferMethodArgs = {
  catalogTransferMethodId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateCatalogTransferPriceArgs = {
  catalogTransferMethodId: Scalars['ID'];
  catalogTransferPriceId: Scalars['ID'];
  locationId: Scalars['ID'];
  priceBasic: Scalars['Decimal'];
  priceExtra: Scalars['Decimal'];
  priceStop: Scalars['Decimal'];
  productNumberBasic: Scalars['String'];
  productNumberExtra: Scalars['String'];
  productNumberStop: Scalars['String'];
};


export type MutationUpdateCommissionArgs = {
  commissionId: Scalars['ID'];
  orderId: Scalars['ID'];
  userSellerId: Scalars['ID'];
};


export type MutationUpdateCommissionLevelArgs = {
  commissionLevelId: Scalars['ID'];
  maxCommission: Scalars['Decimal'];
  maxDiscount: Scalars['Decimal'];
  minCommission: Scalars['Decimal'];
};


export type MutationUpdateCommissionRangeArgs = {
  commissionRangeId: Scalars['ID'];
  daysMax: Scalars['Int'];
};


export type MutationUpdateCommissionTemplateArgs = {
  baseSalary: Scalars['Decimal'];
  commissionTemplateId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateCommissionUserSettingArgs = {
  commissionTemplateId: Scalars['ID'];
  commissionUserSettingId: Scalars['ID'];
  showCommissions?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateCostcenterArgs = {
  costcenterId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCosttypeArgs = {
  costtypeId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCosttypeForRentThroughArgs = {
  costtypeId?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
};


export type MutationUpdateCustomerArgs = {
  blockedAt?: InputMaybe<Scalars['DateTime']>;
  blockedReason?: InputMaybe<Scalars['String']>;
  businessId?: InputMaybe<Scalars['String']>;
  contactNext?: InputMaybe<Scalars['Date']>;
  contactPersonFirstname?: InputMaybe<Scalars['String']>;
  contactPersonLastname?: InputMaybe<Scalars['String']>;
  creditInformationCheckedAt?: InputMaybe<Scalars['Date']>;
  creditInformationOk?: InputMaybe<Scalars['Boolean']>;
  creditRiskClass?: InputMaybe<Scalars['String']>;
  customerAreaIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customerId: Scalars['ID'];
  customerIdParent?: InputMaybe<Scalars['ID']>;
  customerIndustryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customerRankId?: InputMaybe<Scalars['ID']>;
  discountGlobalCatalogExtra?: InputMaybe<Scalars['Decimal']>;
  discountGlobalCatalogRow?: InputMaybe<Scalars['Decimal']>;
  email?: InputMaybe<Scalars['String']>;
  handledInvoicingAt?: InputMaybe<Scalars['Date']>;
  hasInsurance?: InputMaybe<Scalars['Boolean']>;
  invoicingAddress?: InputMaybe<Scalars['String']>;
  invoicingDistrict?: InputMaybe<Scalars['String']>;
  invoicingEAddress?: InputMaybe<Scalars['String']>;
  invoicingEOperator?: InputMaybe<Scalars['String']>;
  invoicingEmail?: InputMaybe<Scalars['String']>;
  invoicingPostcode?: InputMaybe<Scalars['String']>;
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  noBillingSurchargeAt?: InputMaybe<Scalars['DateTime']>;
  paymentTerm?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  preInvoicingAt?: InputMaybe<Scalars['DateTime']>;
  preInvoicingReason?: InputMaybe<Scalars['String']>;
  useParentInvoicing?: InputMaybe<Scalars['Boolean']>;
  useParentPricing?: InputMaybe<Scalars['Boolean']>;
  userOwnerId?: InputMaybe<Scalars['ID']>;
  visitAddress?: InputMaybe<Scalars['String']>;
  visitDistrict?: InputMaybe<Scalars['String']>;
  visitPostcode?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerAreaArgs = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerIndustryArgs = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomerParentArgs = {
  customerId: Scalars['ID'];
  customerIdParent: Scalars['ID'];
};


export type MutationUpdateCustomerRankArgs = {
  id: Scalars['ID'];
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDiscountListArgs = {
  catalogId: Scalars['ID'];
  discountListId: Scalars['ID'];
  isBusiness?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateDiscountRowArgs = {
  catalogRowId: Scalars['ID'];
  discountListId: Scalars['ID'];
  discountRowId: Scalars['ID'];
  percent?: InputMaybe<Scalars['Decimal']>;
  showPrint: Scalars['Boolean'];
};


export type MutationUpdateDiscountRowPercentsPrintsArgs = {
  idPercentPrintInput?: InputMaybe<Array<InputMaybe<IdPercentPrintInputType>>>;
};


export type MutationUpdateDriveArgs = {
  driveId: Scalars['ID'];
};


export type MutationUpdateEventArgs = {
  customerId: Scalars['ID'];
  date: Scalars['Date'];
  eventId: Scalars['ID'];
  eventType: Scalars['String'];
  information: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  groupId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateHolidayArgs = {
  date: Scalars['Date'];
  holidayId: Scalars['ID'];
  holidayType: Scalars['String'];
  name: Scalars['String'];
};


export type MutationUpdateInspectionGiveAnswerArgs = {
  answer: Scalars['String'];
  checked?: InputMaybe<Scalars['Boolean']>;
  inspectionGiveAnswerId: Scalars['ID'];
  inspectionGiveQuestionOptionId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateInspectionGiveCategoryArgs = {
  inspectionGiveCategoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateInspectionGiveCategoryOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateInspectionGiveQuestionArgs = {
  answerType: Scalars['String'];
  inspectionGiveCategoryId: Scalars['ID'];
  inspectionGiveQuestionId: Scalars['ID'];
  question: Scalars['String'];
};


export type MutationUpdateInspectionGiveQuestionOptionArgs = {
  inspectionGiveQuestionOptionId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateInspectionGiveQuestionOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateInspectionGiveTagArgs = {
  inspectionGiveTagId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateInspectionReturnAnswerArgs = {
  answer: Scalars['String'];
  checked?: InputMaybe<Scalars['Boolean']>;
  inspectionReturnAnswerId: Scalars['ID'];
  inspectionReturnQuestionOptionId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateInspectionReturnCategoryArgs = {
  inspectionReturnCategoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateInspectionReturnCategoryOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateInspectionReturnQuestionArgs = {
  answerType: Scalars['String'];
  inspectionReturnCategoryId: Scalars['ID'];
  inspectionReturnQuestionId: Scalars['ID'];
  question: Scalars['String'];
};


export type MutationUpdateInspectionReturnQuestionOptionArgs = {
  inspectionReturnQuestionOptionId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateInspectionReturnQuestionOrderArgs = {
  idOrderInput?: InputMaybe<Array<InputMaybe<IdOrderInputType>>>;
};


export type MutationUpdateInspectionReturnTagArgs = {
  inspectionReturnTagId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateInvoiceArgs = {
  information: Scalars['String'];
  invoiceId: Scalars['ID'];
};


export type MutationUpdateInvoiceRowArgs = {
  description: Scalars['String'];
  invoiceRowId: Scalars['ID'];
  productNumber: Scalars['String'];
  quantity: Scalars['Decimal'];
  title: Scalars['String'];
  unitPrice: Scalars['Decimal'];
};


export type MutationUpdateLocationArgs = {
  dimIdentifier: Scalars['String'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateLocationTransferArgs = {
  locationId: Scalars['ID'];
  transferDistanceIncluded: Scalars['Int'];
  transferDistanceLimit: Scalars['Int'];
};


export type MutationUpdateMachineArgs = {
  acquisitionDate?: InputMaybe<Scalars['Date']>;
  bulkProduct?: InputMaybe<Scalars['Boolean']>;
  catalogRowIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  dateRemoved?: InputMaybe<Scalars['Date']>;
  financingAgreementNumber?: InputMaybe<Scalars['String']>;
  financingCompany?: InputMaybe<Scalars['String']>;
  financingDateEnd?: InputMaybe<Scalars['Date']>;
  financingDateStart?: InputMaybe<Scalars['Date']>;
  financingInformation?: InputMaybe<Scalars['String']>;
  financingInterestMargin?: InputMaybe<Scalars['Decimal']>;
  financingInterestRate?: InputMaybe<Scalars['Decimal']>;
  financingInterestType?: InputMaybe<Scalars['String']>;
  financingInterestVariableType?: InputMaybe<Scalars['String']>;
  financingLoanType?: InputMaybe<Scalars['String']>;
  financingType?: InputMaybe<Scalars['String']>;
  hourTracking?: InputMaybe<Scalars['Boolean']>;
  identifier?: InputMaybe<Scalars['String']>;
  information?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  machineId: Scalars['ID'];
  machineModelId?: InputMaybe<Scalars['ID']>;
  purchaseInformation?: InputMaybe<Scalars['String']>;
  purchasePrice?: InputMaybe<Scalars['Decimal']>;
  purchaseSupplier?: InputMaybe<Scalars['String']>;
  rentThrough?: InputMaybe<Scalars['Boolean']>;
  rentThroughCommissionCase?: InputMaybe<Scalars['String']>;
  rentThroughCompany?: InputMaybe<Scalars['String']>;
  rentThroughInformation?: InputMaybe<Scalars['String']>;
  serial?: InputMaybe<Scalars['String']>;
  yearModel?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateMachineBreakdownArgs = {
  amountBilledSingle: Scalars['Decimal'];
  billing: Scalars['String'];
  fixByEnd: Scalars['Date'];
  fixByStart: Scalars['Date'];
  fixedAt?: InputMaybe<Scalars['DateTime']>;
  information: Scalars['String'];
  machineBreakdownId: Scalars['ID'];
  stillRentable: Scalars['Boolean'];
  title: Scalars['String'];
};


export type MutationUpdateMachineBulkBreakdownArgs = {
  fixedAt?: InputMaybe<Scalars['DateTime']>;
  machineBulkBreakdownId: Scalars['ID'];
};


export type MutationUpdateMachineInfoAnswerArgs = {
  answer: Scalars['String'];
  machineId: Scalars['ID'];
  machineInfoAnswerId: Scalars['ID'];
  machineInfoQuestionId: Scalars['ID'];
  machineInfoQuestionOptionId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateMachineInfoCategoryArgs = {
  machineInfoCategoryId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateMachineInfoQuestionArgs = {
  answerType: Scalars['String'];
  machineInfoCategoryId: Scalars['ID'];
  machineInfoQuestionId: Scalars['ID'];
  question: Scalars['String'];
  showDrive: Scalars['Boolean'];
  showProductCard: Scalars['Boolean'];
};


export type MutationUpdateMachineInfoQuestionOptionArgs = {
  machineInfoQuestionOptionId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateMachineInfoTagArgs = {
  machineInfoTagId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationUpdateMachineMakeArgs = {
  machineMakeId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateMachineModelArgs = {
  machineModelId: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateMaintenanceArgs = {
  estimatedDate?: InputMaybe<Scalars['Date']>;
  maintenanceId: Scalars['ID'];
  serviceContractor?: InputMaybe<Scalars['String']>;
  serviceEndBy?: InputMaybe<Scalars['Date']>;
  serviceStartBy?: InputMaybe<Scalars['Date']>;
  serviceTechnicianId?: InputMaybe<Scalars['ID']>;
  servicedAt?: InputMaybe<Scalars['DateTime']>;
  verifiedAt?: InputMaybe<Scalars['DateTime']>;
};


export type MutationUpdateMaintenanceImageArgs = {
  eurosOwnCosts: Scalars['Decimal'];
  eurosWarranty: Scalars['Decimal'];
  imageType: Scalars['String'];
  information: Scalars['String'];
  maintenanceImageId: Scalars['ID'];
};


export type MutationUpdateMaintenanceIntervalArgs = {
  days: Scalars['Int'];
  description: Scalars['String'];
  hours: Scalars['Int'];
  maintenanceIntervalId: Scalars['ID'];
};


export type MutationUpdateMaintenanceSettingsArgs = {
  estimatedHoursPerDay: Scalars['Decimal'];
  maintenanceSettingId: Scalars['ID'];
  scheduleType: Scalars['String'];
  startDate: Scalars['Date'];
};


export type MutationUpdateOperationHourArgs = {
  dateChecked: Scalars['Date'];
  hours: Scalars['Int'];
  operationHourId: Scalars['ID'];
};


export type MutationUpdateOrderArgs = {
  confirmationType: Scalars['String'];
  customerId: Scalars['ID'];
  hasInsurance: Scalars['Boolean'];
  information: Scalars['String'];
  informationInvoice: Scalars['String'];
  locationId: Scalars['ID'];
  orderId: Scalars['ID'];
  referenceId: Scalars['ID'];
  updatePricesInCatalogSwitch: Scalars['Boolean'];
};


export type MutationUpdateOrderStatusArgs = {
  advanceValidUntil?: InputMaybe<Scalars['Date']>;
  orderId: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationUpdateProductCodeArgs = {
  code?: InputMaybe<Scalars['String']>;
  costcenterId?: InputMaybe<Scalars['ID']>;
  costtypeId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  productCodeId: Scalars['ID'];
};


export type MutationUpdateReferenceArgs = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
  referenceId: Scalars['ID'];
};


export type MutationUpdateReservationArgs = {
  billingDaysWeekCompany?: InputMaybe<Scalars['Int']>;
  billingDaysWeekPrivate?: InputMaybe<Scalars['Int']>;
  bulkAmount?: InputMaybe<Scalars['Int']>;
  catalogRowId?: InputMaybe<Scalars['ID']>;
  dateBillingMin?: InputMaybe<Scalars['Date']>;
  dateRented?: InputMaybe<Scalars['Date']>;
  dateReturned?: InputMaybe<Scalars['Date']>;
  deliveryMethod?: InputMaybe<Scalars['String']>;
  discountPercentDay?: InputMaybe<Scalars['Decimal']>;
  discountPercentMonth?: InputMaybe<Scalars['Decimal']>;
  estimatedMachineHoursPerDay?: InputMaybe<Scalars['Decimal']>;
  hourLimitDayCompany?: InputMaybe<Scalars['Int']>;
  hourLimitDayPrivate?: InputMaybe<Scalars['Int']>;
  hourLimitMonthCompany?: InputMaybe<Scalars['Int']>;
  hourLimitMonthPrivate?: InputMaybe<Scalars['Int']>;
  information?: InputMaybe<Scalars['String']>;
  machineId?: InputMaybe<Scalars['ID']>;
  orderId?: InputMaybe<Scalars['ID']>;
  reservationId: Scalars['ID'];
  timeRented?: InputMaybe<Scalars['Time']>;
  timeReturned?: InputMaybe<Scalars['Time']>;
};


export type MutationUpdateReservationImageBasicArgs = {
  information: Scalars['String'];
  reservationImageBasicId: Scalars['ID'];
  shootingDate: Scalars['Date'];
};


export type MutationUpdateReservationStampsArgs = {
  giveAt?: InputMaybe<Scalars['DateTime']>;
  giveCheckedAt?: InputMaybe<Scalars['DateTime']>;
  giveImagesAt?: InputMaybe<Scalars['DateTime']>;
  giveInstructedAt?: InputMaybe<Scalars['DateTime']>;
  reservationId: Scalars['ID'];
  returnAt?: InputMaybe<Scalars['DateTime']>;
  returnCheckedAt?: InputMaybe<Scalars['DateTime']>;
  returnCheckedCatalogAt?: InputMaybe<Scalars['DateTime']>;
  returnCheckedPreAt?: InputMaybe<Scalars['DateTime']>;
  returnImagesAt?: InputMaybe<Scalars['DateTime']>;
  returnStoredAt?: InputMaybe<Scalars['DateTime']>;
  userGiveId?: InputMaybe<Scalars['ID']>;
  userReturnId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateReservationsReturnAtArgs = {
  reservationIds: Array<InputMaybe<Scalars['ID']>>;
  returnAt: Scalars['DateTime'];
};


export type MutationUpdateSettingsArgs = {
  billingSurchargeAmount?: InputMaybe<Scalars['Decimal']>;
  billingSurchargeLimit?: InputMaybe<Scalars['Decimal']>;
  commissionOrderer?: InputMaybe<Scalars['Decimal']>;
  commissionOwner?: InputMaybe<Scalars['Decimal']>;
  commissionRentThrough?: InputMaybe<Scalars['Decimal']>;
  costcenterBillingSurchargeId?: InputMaybe<Scalars['ID']>;
  costcenterBreakdownSingleId?: InputMaybe<Scalars['ID']>;
  costcenterCatalogExtraRentalId?: InputMaybe<Scalars['ID']>;
  costcenterCatalogId?: InputMaybe<Scalars['ID']>;
  costcenterCatalogTransferId?: InputMaybe<Scalars['ID']>;
  costcenterInsuranceId?: InputMaybe<Scalars['ID']>;
  costcenterMachineRentThroughId?: InputMaybe<Scalars['ID']>;
  costcenterOpHoursId?: InputMaybe<Scalars['ID']>;
  costcenterOpeningFeeId?: InputMaybe<Scalars['ID']>;
  costtypeBillingSurchargeId?: InputMaybe<Scalars['ID']>;
  costtypeBreakdownSingleId?: InputMaybe<Scalars['ID']>;
  costtypeCatalogExtraRentalId?: InputMaybe<Scalars['ID']>;
  costtypeCatalogId?: InputMaybe<Scalars['ID']>;
  costtypeCatalogTransferId?: InputMaybe<Scalars['ID']>;
  costtypeInsuranceId?: InputMaybe<Scalars['ID']>;
  costtypeOpHoursId?: InputMaybe<Scalars['ID']>;
  costtypeOpeningFeeId?: InputMaybe<Scalars['ID']>;
  helpTextImagesGive?: InputMaybe<Scalars['String']>;
  helpTextImagesReturn?: InputMaybe<Scalars['String']>;
  insurancePercent?: InputMaybe<Scalars['Decimal']>;
  openingFeeAmount?: InputMaybe<Scalars['Decimal']>;
  openingFeeLimit?: InputMaybe<Scalars['Decimal']>;
  productNumberBillingSurcharge?: InputMaybe<Scalars['String']>;
  productNumberInsurance?: InputMaybe<Scalars['String']>;
  productNumberOpHours?: InputMaybe<Scalars['String']>;
  productNumberOpeningFee?: InputMaybe<Scalars['String']>;
  productNumberSingle?: InputMaybe<Scalars['String']>;
  textConfirmation1?: InputMaybe<Scalars['String']>;
  textConfirmation2?: InputMaybe<Scalars['String']>;
  textContract1?: InputMaybe<Scalars['String']>;
  textContract2?: InputMaybe<Scalars['String']>;
  textOffer0?: InputMaybe<Scalars['String']>;
  textOffer1?: InputMaybe<Scalars['String']>;
  textOffer2?: InputMaybe<Scalars['String']>;
  vat?: InputMaybe<Scalars['Decimal']>;
};


export type MutationUpdateTransportArgs = {
  address: Scalars['String'];
  catalogTransferMethodId: Scalars['ID'];
  dateDeadline?: InputMaybe<Scalars['Date']>;
  dateEndUsage?: InputMaybe<Scalars['Date']>;
  district: Scalars['String'];
  information: Scalars['String'];
  method: Scalars['String'];
  orderId: Scalars['ID'];
  postcode: Scalars['String'];
  reservationIds: Array<InputMaybe<Scalars['ID']>>;
  timeDeadline?: InputMaybe<Scalars['Time']>;
  timeEndUsage?: InputMaybe<Scalars['Time']>;
  transportId: Scalars['ID'];
};


export type MutationUpdateTransportInvoicingArgs = {
  catalogTransferMethodId: Scalars['ID'];
  description: Scalars['String'];
  discountPercent: Scalars['Decimal'];
  method: Scalars['String'];
  orderId: Scalars['ID'];
  quantity: Scalars['Decimal'];
  referenceId: Scalars['ID'];
  rowType: Scalars['String'];
  title: Scalars['String'];
  transportInvoicingId: Scalars['ID'];
  unitPrice: Scalars['Decimal'];
};


export type MutationUpdateTransportInvoicingSummaryArgs = {
  orderId: Scalars['ID'];
  percent: Scalars['Decimal'];
  price: Scalars['Decimal'];
  referenceId: Scalars['ID'];
  transportInvoicingSummaryId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  address?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  groupId: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  locationId?: InputMaybe<Scalars['ID']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  username: Scalars['String'];
};


export type MutationUploadCatalogCategoryArgs = {
  catalogCategoryId: Scalars['ID'];
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadCatalogRowArgs = {
  catalogRowId: Scalars['ID'];
  file?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileCatalogCoverBackArgs = {
  fileCatalogCoverBack?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileCatalogCoverFrontArgs = {
  fileCatalogCoverFront?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileCatalogCustomerCoverBackArgs = {
  fileCatalogCustomerCoverBack?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileCatalogCustomerCoverFrontArgs = {
  fileCatalogCustomerCoverFront?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileConfirmationCoverBackArgs = {
  fileConfirmationCoverBack?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileConfirmationCoverFrontArgs = {
  fileConfirmationCoverFront?: InputMaybe<Scalars['Upload']>;
};


export type MutationUploadFileMachineCheckListArgs = {
  fileCheckList?: InputMaybe<Scalars['Upload']>;
  machineId: Scalars['ID'];
};


export type MutationUploadFileMachineFinancingArgs = {
  fileFinancing?: InputMaybe<Scalars['Upload']>;
  machineId: Scalars['ID'];
};


export type MutationUploadFileMachinePurchaseArgs = {
  filePurchase?: InputMaybe<Scalars['Upload']>;
  machineId: Scalars['ID'];
};


export type MutationUploadFileMachineTypePlateArgs = {
  fileTypePlate?: InputMaybe<Scalars['Upload']>;
  machineId: Scalars['ID'];
};


export type MutationUploadMaintenanceImageArgs = {
  file: Scalars['Upload'];
  maintenanceAlbumId: Scalars['ID'];
};


export type MutationUploadReservationImageBasicArgs = {
  file: Scalars['Upload'];
  reservationId: Scalars['ID'];
};


export type MutationUploadReservationImagesArgs = {
  files: Array<InputMaybe<Scalars['Upload']>>;
  reservationAlbumId: Scalars['ID'];
};


export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};

export type NextMaintenanceType = {
  __typename?: 'NextMaintenanceType';
  days?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  intervalType?: Maybe<Scalars['String']>;
  nextMaintenanceDate?: Maybe<Scalars['Date']>;
  nextMaintenanceInterval?: Maybe<MaintenanceIntervalType>;
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  user?: Maybe<UserType>;
};

export type OperationHourType = {
  __typename?: 'OperationHourType';
  createdAt: Scalars['DateTime'];
  dateChecked: Scalars['Date'];
  hours: Scalars['Int'];
  hoursExtra: Scalars['Int'];
  id: Scalars['ID'];
  invoiceRow?: Maybe<InvoiceRowType>;
  invoiceable: Scalars['Boolean'];
  isBase: Scalars['Boolean'];
  machine: MachineType;
  maintenance?: Maybe<MaintenanceType>;
  reservation?: Maybe<ReservationType>;
  source: OperationHoursOperationHourSourceChoices;
};

/** An enumeration. */
export enum OperationHoursOperationHourSourceChoices {
  /** Give check */
  GiveCheck = 'GIVE_CHECK',
  /** Maintenance */
  Maintenance = 'MAINTENANCE',
  /** Return check */
  ReturnCheck = 'RETURN_CHECK',
  /** None */
  SourceNone = 'SOURCE_NONE'
}

export type OrderPriceType = {
  __typename?: 'OrderPriceType';
  amount: Scalars['Decimal'];
  title: Scalars['String'];
};

export type OrderType = {
  __typename?: 'OrderType';
  advanceValidUntil?: Maybe<Scalars['Date']>;
  commissionSet: Array<CommissionType>;
  confirmationType: OrdersOrderConfirmationTypeChoices;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  customer?: Maybe<CustomerType>;
  hasInsurance: Scalars['Boolean'];
  id: Scalars['ID'];
  information: Scalars['String'];
  informationInvoice: Scalars['String'];
  invoicerowSet: Array<InvoiceRowType>;
  isBusinessIfNoCustomer: Scalars['Boolean'];
  location: LocationType;
  orderSource?: Maybe<OrderType>;
  ordersSourced: Array<OrderType>;
  reference?: Maybe<ReferenceType>;
  reportpriceSet: Array<ReportPriceType>;
  reservationSet: Array<ReservationType>;
  status: OrdersOrderStatusChoices;
  transportSet: Array<TransportType>;
  transportinvoicingSet: Array<TransportInvoicingType>;
  transportinvoicingsummarySet: Array<TransportInvoicingSummaryType>;
  updatePricesInCatalogSwitch: Scalars['Boolean'];
};

/** An enumeration. */
export enum OrdersOrderConfirmationTypeChoices {
  /** Email */
  Email = 'EMAIL',
  /** Print */
  Print = 'PRINT'
}

/** An enumeration. */
export enum OrdersOrderStatusChoices {
  /** Advance */
  Advance = 'ADVANCE',
  /** Offer */
  Offer = 'OFFER',
  /** Reserved */
  Reserved = 'RESERVED'
}

export type PermissionForGroupAdd = {
  __typename?: 'PermissionForGroupAdd';
  group?: Maybe<GroupType>;
  permission?: Maybe<PermissionType>;
};

export type PermissionForGroupDelete = {
  __typename?: 'PermissionForGroupDelete';
  group?: Maybe<GroupType>;
  permission?: Maybe<PermissionType>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  codename: Scalars['String'];
  groupSet: Array<GroupType>;
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Tämän käyttäjän spesifit oikeudet. */
  userSet: Array<UserType>;
};

export type ProductCodeType = {
  __typename?: 'ProductCodeType';
  catalogextrarowinvoiceSet: Array<CatalogExtraRowInvoiceType>;
  catalogextrarowunitpriceSet: Array<CatalogExtraRowUnitPriceType>;
  code: Scalars['String'];
  costcenter?: Maybe<CostcenterType>;
  costtype?: Maybe<CosttypeType>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  bulkAmountsByDate: Array<BulkAmountDate>;
  catalog: CatalogType;
  catalogCategoriesForUpper: Array<CatalogCategoryType>;
  catalogCategoriesUpper: Array<CatalogCategoryUpperType>;
  catalogExtraCategoriesUnitPrice: Array<CatalogExtraCategoryUnitPriceType>;
  catalogExtraCategoriesUpperRental: Array<CatalogExtraCategoryUpperRentalType>;
  catalogExtraRowsInvoiceReservation: Array<CatalogExtraRowInvoiceType>;
  catalogExtraRowsInvoiceStatus: Array<CatalogExtraRowInvoiceStatusField>;
  catalogExtraRowsRental: Array<CatalogExtraRowRentalType>;
  catalogExtraRowsRentalByLocationMachines: Array<CatalogExtraRowRentalType>;
  catalogExtraRowsRentalByLocations: Array<CatalogExtraRowRentalType>;
  catalogExtraRowsUnitPrice: Array<CatalogExtraRowUnitPriceType>;
  catalogRow: CatalogRowType;
  catalogTransferMethods: Array<CatalogTransferMethodType>;
  catalogs: Array<CatalogType>;
  commissionReportForMonth: Array<CommissionReportType>;
  commissionRowsForUser: Array<CommissionRowType>;
  commissionRowsRentThroughForUser: Array<CommissionRowRentThroughType>;
  commissionTemplates: Array<CommissionTemplateType>;
  commissionUserSetting: CommissionUserSettingType;
  commissionUserSettings: Array<CommissionUserSettingType>;
  commissionsForOrder: Array<CommissionType>;
  costcenters: Array<CostcenterType>;
  costtypes: Array<CosttypeType>;
  costtypesForRentThrough: Array<CosttypeForRentThroughType>;
  customer: CustomerType;
  customerAreas: Array<CustomerAreaType>;
  customerIndustries: Array<CustomerIndustryType>;
  customerPriceForCatalogRow: CustomerPriceCatalogRowType;
  customerRanks: Array<CustomerRankType>;
  customers: Array<CustomerType>;
  customersOpenReservations: Array<CustomerType>;
  customersPrimary: Array<CustomerType>;
  discountList: DiscountListType;
  discountLists: Array<DiscountListType>;
  discountListsForCatalogRow: Array<DiscountListType>;
  discountRows: Array<DiscountRowType>;
  drives: Array<DriveType>;
  drivesForWorkQueues: Array<DriveType>;
  errorsSettingCatalogActive: Array<Scalars['String']>;
  estimatedHoursForMachine: EstimatedHoursType;
  events: Array<EventType>;
  eventsOfferCatalog: Array<EventOfferCatalogType>;
  eventsOfferDiscountList: Array<EventOfferDiscountListType>;
  groups: Array<GroupType>;
  holidays: Array<HolidayType>;
  inspectionGiveAnswersForReservation: Array<InspectionGiveAnswerType>;
  inspectionGiveCategories: Array<InspectionGiveCategoryType>;
  inspectionGiveTags: Array<InspectionGiveTagType>;
  inspectionReturnAnswersForReservation: Array<InspectionReturnAnswerType>;
  inspectionReturnCategories: Array<InspectionReturnCategoryType>;
  inspectionReturnTags: Array<InspectionReturnTagType>;
  invoiceableRows: Array<InvoiceableRowType>;
  invoicesOpen: Array<InvoiceType>;
  invoicesSent: Array<InvoiceType>;
  invoicingPriceForReservation: InvoicingPriceReservationType;
  locations: Array<LocationType>;
  logs: Array<LogType>;
  machine: MachineType;
  machineBreakdowns: Array<MachineBreakdownType>;
  machineBreakdownsForMachine: Array<MachineBreakdownType>;
  machineBreakdownsUnfixed: Array<MachineBreakdownType>;
  machineBulkAmountsForMachine: Array<MachineBulkAmountType>;
  machineBulkBreakdownsForMachine: Array<MachineBulkBreakdownType>;
  machineBulkBreakdownsUnfixed: Array<MachineBulkBreakdownType>;
  machineInfoCategories: Array<MachineInfoCategoryType>;
  machineInfoQuestionsForDrives: Array<MachineInfoQuestionType>;
  machineInfoTags: Array<MachineInfoTagType>;
  machineMakes: Array<MachineMakeType>;
  machines: Array<MachineType>;
  machinesForCatalog: Array<MachineType>;
  machinesForCatalogRow: Array<MachineType>;
  machinesForCatalogRowAvailable: Array<MachineType>;
  machinesForCatalogRows: Array<MachineType>;
  machinesForLocation: Array<MachineType>;
  machinesForLocationsWithMaintenanceSettings: Array<MachineType>;
  machinesSearchAvailable: Array<MachineType>;
  machinesWithIds: Array<MachineType>;
  machinesWithoutCatalogRow: Array<MachineType>;
  maintenanceHistoryForCatalogExtraRowRental: Array<MaintenanceType>;
  maintenanceHistoryForMachine: Array<MaintenanceType>;
  maintenanceImages: Array<MaintenanceImageType>;
  maintenanceNextForCatalogExtraRowRental?: Maybe<NextMaintenanceType>;
  maintenanceNextForMachine?: Maybe<NextMaintenanceType>;
  maintenanceSettingsForExtraRowRental: MaintenanceSettingType;
  maintenanceSettingsForMachine: MaintenanceSettingType;
  maintenanceUpcomingFetch?: Maybe<MaintenanceUpcomingFetchType>;
  maintenances: Array<MaintenanceType>;
  maintenancesCalendar: Array<MaintenanceType>;
  maintenancesUpcoming: Array<MaintenanceType>;
  me: UserType;
  myPermissions: Array<Scalars['String']>;
  operationHoursForMachine: Array<OperationHourType>;
  order: OrderType;
  orderCanChangeCustomer: Scalars['Boolean'];
  orderTotal: Array<OrderPriceType>;
  orders: Array<OrderType>;
  permissions: Array<PermissionType>;
  productCode: ProductCodeType;
  productCodes: Array<ProductCodeType>;
  references: Array<ReferenceType>;
  referencesByIds: Array<ReferenceType>;
  reportPrices: Array<ReportPriceType>;
  reservation: ReservationType;
  reservationComments: Array<ReservationCommentType>;
  reservationEditableDates: EditableDatesType;
  reservationImageBasics: Array<ReservationImageBasicType>;
  reservationImageGives?: Maybe<Array<ReservationImageType>>;
  reservationImageReturns?: Maybe<Array<ReservationImageType>>;
  reservationPrice: Array<IdPriceType>;
  reservations: Array<ReservationType>;
  reservationsCalendar: Array<ReservationType>;
  reservationsCurrentByMachine: Array<ReservationType>;
  reservationsForMachine: Array<ReservationType>;
  reservationsForOrder: Array<ReservationType>;
  reservationsOverlappingWithOrder: Array<ReservationType>;
  reservationsRentedOut: Array<ReservationType>;
  reservationsSearch: Array<ReservationType>;
  reservationsUpcomingByMachine: Array<ReservationType>;
  reservationsWorkQueues: Array<ReservationType>;
  reservationsWorkQueuesReturn: Array<ReservationType>;
  settings: SettingsType;
  transportInvoicingSummariesByOrder: Array<TransportInvoicingSummaryType>;
  transportInvoicingsByOrder: Array<TransportInvoicingType>;
  transportsByOrder: Array<TransportType>;
  transportsSearch: Array<TransportType>;
  transportsUnhandled: Array<TransportType>;
  user: UserType;
  users: Array<UserType>;
};


export type QueryBulkAmountsByDateArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  locationId: Scalars['ID'];
};


export type QueryCatalogArgs = {
  catalogId: Scalars['ID'];
};


export type QueryCatalogCategoriesForUpperArgs = {
  catalogCategoryUpperId: Scalars['ID'];
};


export type QueryCatalogCategoriesUpperArgs = {
  catalogCategoryUpperId?: InputMaybe<Scalars['ID']>;
  catalogId: Scalars['ID'];
};


export type QueryCatalogExtraCategoriesUpperRentalArgs = {
  catalogExtraCategoryRentalIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryCatalogExtraRowsInvoiceReservationArgs = {
  reservationId?: InputMaybe<Scalars['ID']>;
};


export type QueryCatalogExtraRowsInvoiceStatusArgs = {
  catalogExtraRowInvoiceIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryCatalogExtraRowsRentalByLocationMachinesArgs = {
  locationId?: InputMaybe<Scalars['ID']>;
  machineIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryCatalogExtraRowsRentalByLocationsArgs = {
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryCatalogExtraRowsUnitPriceArgs = {
  machineId?: InputMaybe<Scalars['ID']>;
};


export type QueryCatalogRowArgs = {
  catalogRowId?: InputMaybe<Scalars['ID']>;
};


export type QueryCatalogsArgs = {
  catalogIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryCommissionReportForMonthArgs = {
  month: Scalars['Int'];
  userIds: Array<InputMaybe<Scalars['ID']>>;
  year: Scalars['Int'];
};


export type QueryCommissionRowsForUserArgs = {
  monthFrom: Scalars['Int'];
  monthTo: Scalars['Int'];
  userId: Scalars['ID'];
  yearFrom: Scalars['Int'];
  yearTo: Scalars['Int'];
};


export type QueryCommissionRowsRentThroughForUserArgs = {
  monthFrom: Scalars['Int'];
  monthTo: Scalars['Int'];
  userId: Scalars['ID'];
  yearFrom: Scalars['Int'];
  yearTo: Scalars['Int'];
};


export type QueryCommissionUserSettingArgs = {
  userId: Scalars['ID'];
};


export type QueryCommissionsForOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomerPriceForCatalogRowArgs = {
  catalogRowId: Scalars['ID'];
  customerId?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomersArgs = {
  customerAreaIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customerIndustryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customerRankIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isBusiness?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
  userOwnerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryCustomersOpenReservationsArgs = {
  dateHandled?: InputMaybe<Scalars['Date']>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  onlyEndedReservations: Scalars['Boolean'];
  onlyPreinvoicingCustomers: Scalars['Boolean'];
};


export type QueryCustomersPrimaryArgs = {
  search?: InputMaybe<Scalars['String']>;
};


export type QueryDiscountListArgs = {
  discountListId: Scalars['ID'];
};


export type QueryDiscountListsArgs = {
  catalogId: Scalars['ID'];
};


export type QueryDiscountListsForCatalogRowArgs = {
  catalogRowId: Scalars['ID'];
};


export type QueryDiscountRowsArgs = {
  discountListId: Scalars['ID'];
};


export type QueryDrivesArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  locationIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryDrivesForWorkQueuesArgs = {
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryErrorsSettingCatalogActiveArgs = {
  catalogId: Scalars['ID'];
};


export type QueryEstimatedHoursForMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryEventsArgs = {
  customerId: Scalars['ID'];
};


export type QueryEventsOfferCatalogArgs = {
  customerId: Scalars['ID'];
};


export type QueryEventsOfferDiscountListArgs = {
  customerId: Scalars['ID'];
};


export type QueryInspectionGiveAnswersForReservationArgs = {
  reservationId: Scalars['ID'];
};


export type QueryInspectionReturnAnswersForReservationArgs = {
  reservationId: Scalars['ID'];
};


export type QueryInvoiceableRowsArgs = {
  customerId?: InputMaybe<Scalars['ID']>;
  dateTo: Scalars['Date'];
  isAdvance?: InputMaybe<Scalars['Boolean']>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  onlyEndedReservations?: InputMaybe<Scalars['Boolean']>;
  onlyPreinvoicingCustomers?: InputMaybe<Scalars['Boolean']>;
  orderId?: InputMaybe<Scalars['ID']>;
  referenceId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicesOpenArgs = {
  dateTo?: InputMaybe<Scalars['Date']>;
  isAdvance?: InputMaybe<Scalars['Boolean']>;
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicesSentArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  orderId?: InputMaybe<Scalars['ID']>;
};


export type QueryInvoicingPriceForReservationArgs = {
  date: Scalars['Date'];
  reservationId: Scalars['ID'];
};


export type QueryLogsArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
};


export type QueryMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryMachineBreakdownsArgs = {
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
};


export type QueryMachineBreakdownsForMachineArgs = {
  machineId?: InputMaybe<Scalars['ID']>;
};


export type QueryMachineBreakdownsUnfixedArgs = {
  categoryIds: Array<InputMaybe<Scalars['ID']>>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  userIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryMachineBulkAmountsForMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryMachineBulkBreakdownsForMachineArgs = {
  machineId: Scalars['ID'];
  reservationId?: InputMaybe<Scalars['ID']>;
};


export type QueryMachineBulkBreakdownsUnfixedArgs = {
  categoryIds: Array<InputMaybe<Scalars['ID']>>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  userIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryMachineInfoQuestionsForDrivesArgs = {
  machineIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryMachinesForCatalogArgs = {
  catalogId: Scalars['ID'];
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
};


export type QueryMachinesForCatalogRowArgs = {
  catalogRowId: Scalars['ID'];
};


export type QueryMachinesForCatalogRowAvailableArgs = {
  catalogRowId: Scalars['ID'];
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
};


export type QueryMachinesForCatalogRowsArgs = {
  catalogRowIds: Array<InputMaybe<Scalars['ID']>>;
  locationId?: InputMaybe<Scalars['ID']>;
};


export type QueryMachinesForLocationArgs = {
  catalogRowIds: Array<InputMaybe<Scalars['ID']>>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  rentThroughs: Array<InputMaybe<Scalars['Int']>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryMachinesForLocationsWithMaintenanceSettingsArgs = {
  locationIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryMachinesSearchAvailableArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  search: Scalars['String'];
};


export type QueryMachinesWithIdsArgs = {
  machineIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryMachinesWithoutCatalogRowArgs = {
  catalogId: Scalars['ID'];
};


export type QueryMaintenanceHistoryForCatalogExtraRowRentalArgs = {
  catalogExtraRowRentalId: Scalars['ID'];
};


export type QueryMaintenanceHistoryForMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryMaintenanceImagesArgs = {
  maintenanceAlbumId: Scalars['ID'];
};


export type QueryMaintenanceNextForCatalogExtraRowRentalArgs = {
  catalogExtraRowRentalId: Scalars['ID'];
};


export type QueryMaintenanceNextForMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryMaintenanceSettingsForExtraRowRentalArgs = {
  catalogExtraRowRentalId: Scalars['ID'];
};


export type QueryMaintenanceSettingsForMachineArgs = {
  machineId: Scalars['ID'];
};


export type QueryMaintenancesArgs = {
  categoryIds: Array<InputMaybe<Scalars['ID']>>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  userIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryMaintenancesCalendarArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  locationId: Scalars['ID'];
};


export type QueryMaintenancesUpcomingArgs = {
  categoryIds: Array<InputMaybe<Scalars['ID']>>;
  create?: InputMaybe<Scalars['Boolean']>;
  locationIds: Array<InputMaybe<Scalars['ID']>>;
  userIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryOperationHoursForMachineArgs = {
  machineId: Scalars['ID'];
  reservationId: Scalars['ID'];
};


export type QueryOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderCanChangeCustomerArgs = {
  orderId: Scalars['ID'];
};


export type QueryOrderTotalArgs = {
  orderId: Scalars['ID'];
};


export type QueryProductCodeArgs = {
  id: Scalars['ID'];
};


export type QueryReferencesArgs = {
  customerId: Scalars['ID'];
};


export type QueryReferencesByIdsArgs = {
  referenceIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryReportPricesArgs = {
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
};


export type QueryReservationArgs = {
  id: Scalars['ID'];
};


export type QueryReservationCommentsArgs = {
  reservationId: Scalars['ID'];
};


export type QueryReservationEditableDatesArgs = {
  reservationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryReservationImageBasicsArgs = {
  reservationId: Scalars['ID'];
};


export type QueryReservationImageGivesArgs = {
  reservationId: Scalars['ID'];
};


export type QueryReservationImageReturnsArgs = {
  reservationId: Scalars['ID'];
};


export type QueryReservationPriceArgs = {
  dateTo?: InputMaybe<Scalars['Date']>;
  reservationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};


export type QueryReservationsArgs = {
  catalogExtraRowRentalId?: InputMaybe<Scalars['ID']>;
  catalogRowId?: InputMaybe<Scalars['ID']>;
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  includeNotReturned: Scalars['Boolean'];
  machineId?: InputMaybe<Scalars['ID']>;
};


export type QueryReservationsCalendarArgs = {
  catalogCategoryId?: InputMaybe<Scalars['ID']>;
  catalogCategoryUpperId?: InputMaybe<Scalars['ID']>;
  catalogRowId?: InputMaybe<Scalars['ID']>;
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
  locationId: Scalars['ID'];
};


export type QueryReservationsForMachineArgs = {
  machineId?: InputMaybe<Scalars['ID']>;
};


export type QueryReservationsForOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryReservationsOverlappingWithOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryReservationsRentedOutArgs = {
  locationIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryReservationsSearchArgs = {
  search: Scalars['String'];
};


export type QueryReservationsWorkQueuesArgs = {
  locationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryTransportInvoicingSummariesByOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryTransportInvoicingsByOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryTransportsByOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryTransportsSearchArgs = {
  customerId: Scalars['ID'];
  searchTerms?: InputMaybe<Scalars['String']>;
};


export type QueryTransportsUnhandledArgs = {
  locationIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type ReferenceType = {
  __typename?: 'ReferenceType';
  createdAt: Scalars['DateTime'];
  customer: CustomerType;
  id: Scalars['ID'];
  invoiceSet: Array<InvoiceType>;
  name: Scalars['String'];
  orderSet: Array<OrderType>;
  transportinvoicingSet: Array<TransportInvoicingType>;
  transportinvoicingsummarySet: Array<TransportInvoicingSummaryType>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RemoveInspectionGiveTagFromQuestion = {
  __typename?: 'RemoveInspectionGiveTagFromQuestion';
  inspectionGiveTagId?: Maybe<Scalars['ID']>;
};

export type RemoveInspectionReturnTagFromQuestion = {
  __typename?: 'RemoveInspectionReturnTagFromQuestion';
  inspectionReturnTagId?: Maybe<Scalars['ID']>;
};

export type RemoveMachineInfoTagFromQuestion = {
  __typename?: 'RemoveMachineInfoTagFromQuestion';
  machineInfoTagId?: Maybe<Scalars['ID']>;
};

export type ReportPriceDaySkippedType = {
  __typename?: 'ReportPriceDaySkippedType';
  createdAt: Scalars['DateTime'];
  dateSkipped: Scalars['Date'];
  id: Scalars['ID'];
  reportPrice: ReportPriceType;
};

export type ReportPriceMonthType = {
  __typename?: 'ReportPriceMonthType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  month: Scalars['String'];
  pricePerDay: Scalars['Decimal'];
  reportPrice: ReportPriceType;
};

export type ReportPriceType = {
  __typename?: 'ReportPriceType';
  createdAt: Scalars['DateTime'];
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
  dayPrice: Scalars['Decimal'];
  id: Scalars['ID'];
  order: OrderType;
  reportpricedayskippedSet: Array<ReportPriceDaySkippedType>;
  reportpricemonthSet: Array<ReportPriceMonthType>;
  reservation: ReservationType;
  title: Scalars['String'];
  useDayPrice: Scalars['Boolean'];
};

export type ReservationAlbumType = {
  __typename?: 'ReservationAlbumType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  reservationGive?: Maybe<ReservationType>;
  reservationReturn?: Maybe<ReservationType>;
  reservationimageSet: Array<ReservationImageType>;
};

export type ReservationCommentType = {
  __typename?: 'ReservationCommentType';
  comment: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  reservation: ReservationType;
};

export type ReservationImageBasicType = {
  __typename?: 'ReservationImageBasicType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  image: Scalars['String'];
  information: Scalars['String'];
  reservation: ReservationType;
  shootingDate: Scalars['Date'];
};

export type ReservationImageType = {
  __typename?: 'ReservationImageType';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  id: Scalars['ID'];
  image: Scalars['String'];
  reservationAlbum: ReservationAlbumType;
};

export type ReservationType = {
  __typename?: 'ReservationType';
  albumGive: ReservationAlbumType;
  albumReturn: ReservationAlbumType;
  billingDaysWeekCompany?: Maybe<Scalars['Int']>;
  billingDaysWeekPrivate?: Maybe<Scalars['Int']>;
  bulkAmount: Scalars['Int'];
  bulkAmountReturned: Scalars['Int'];
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
  catalogRow?: Maybe<CatalogRowType>;
  catalogextrarowinvoiceSet: Array<CatalogExtraRowInvoiceType>;
  commissionreportrowSet: Array<CommissionReportRowType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  dateBillingMin?: Maybe<Scalars['Date']>;
  dateRented?: Maybe<Scalars['Date']>;
  dateReturned?: Maybe<Scalars['Date']>;
  deliveryMethod: ReservationsReservationDeliveryMethodChoices;
  discountPercentDay: Scalars['Decimal'];
  discountPercentMonth: Scalars['Decimal'];
  drivecontentSet: Array<DriveContentType>;
  estimatedMachineHoursPerDay: Scalars['Decimal'];
  giveAt?: Maybe<Scalars['DateTime']>;
  giveCheckedAt?: Maybe<Scalars['DateTime']>;
  giveImagesAt?: Maybe<Scalars['DateTime']>;
  giveInstructedAt?: Maybe<Scalars['DateTime']>;
  hourLimitDayCompany?: Maybe<Scalars['Int']>;
  hourLimitDayPrivate?: Maybe<Scalars['Int']>;
  hourLimitMonthCompany?: Maybe<Scalars['Int']>;
  hourLimitMonthPrivate?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  information: Scalars['String'];
  inspectiongiveanswerSet: Array<InspectionGiveAnswerType>;
  inspectionreturnanswerSet: Array<InspectionReturnAnswerType>;
  invoicerowSet: Array<InvoiceRowType>;
  machine?: Maybe<MachineType>;
  machinebulkbreakdownSet: Array<MachineBulkBreakdownType>;
  operationhourSet: Array<OperationHourType>;
  order: OrderType;
  reportpriceSet: Array<ReportPriceType>;
  reservationSource?: Maybe<ReservationType>;
  reservationcommentSet: Array<ReservationCommentType>;
  reservationimagebasicSet: Array<ReservationImageBasicType>;
  reservationsSourced: Array<ReservationType>;
  returnAt?: Maybe<Scalars['DateTime']>;
  returnCheckedAt?: Maybe<Scalars['DateTime']>;
  returnCheckedCatalogAt?: Maybe<Scalars['DateTime']>;
  returnCheckedPreAt?: Maybe<Scalars['DateTime']>;
  returnImagesAt?: Maybe<Scalars['DateTime']>;
  returnStoredAt?: Maybe<Scalars['DateTime']>;
  status: ReservationsReservationStatusChoices;
  timeRented?: Maybe<Scalars['Time']>;
  timeReturned?: Maybe<Scalars['Time']>;
  transportSet: Array<TransportType>;
  userGive?: Maybe<UserType>;
  userReturn?: Maybe<UserType>;
};

/** An enumeration. */
export enum ReservationsReservationDeliveryMethodChoices {
  /** Customer */
  Customer = 'CUSTOMER',
  /** Transport */
  Transport = 'TRANSPORT'
}

/** An enumeration. */
export enum ReservationsReservationStatusChoices {
  /** Invoiced */
  Invoiced = 'INVOICED',
  /** Open */
  Open = 'OPEN'
}

export type ReturnPartialReservation = {
  __typename?: 'ReturnPartialReservation';
  reservation?: Maybe<ReservationType>;
};

export type SaveMachineBulkAmount = {
  __typename?: 'SaveMachineBulkAmount';
  machineBulkAmount?: Maybe<MachineBulkAmountType>;
};

export type SelectCustomerCatalog = {
  __typename?: 'SelectCustomerCatalog';
  customer?: Maybe<CustomerType>;
};

export type SelectCustomerCatalogActive = {
  __typename?: 'SelectCustomerCatalogActive';
  customer?: Maybe<CustomerType>;
};

export type SelectCustomerDiscountList = {
  __typename?: 'SelectCustomerDiscountList';
  customer?: Maybe<CustomerType>;
};

export type SelectLinkedCustomerPricing = {
  __typename?: 'SelectLinkedCustomerPricing';
  customer?: Maybe<CustomerType>;
};

export type SendInvoice = {
  __typename?: 'SendInvoice';
  invoice?: Maybe<InvoiceType>;
};

export type SendInvoicePayment = {
  __typename?: 'SendInvoicePayment';
  invoice?: Maybe<InvoiceType>;
};

export type SettingsType = {
  __typename?: 'SettingsType';
  billingSurchargeAmount: Scalars['Decimal'];
  billingSurchargeLimit: Scalars['Decimal'];
  catalogActive?: Maybe<CatalogType>;
  commissionOrderer: Scalars['Decimal'];
  commissionOwner: Scalars['Decimal'];
  commissionRentThrough: Scalars['Decimal'];
  costcenterBillingSurcharge?: Maybe<CostcenterType>;
  costcenterBreakdownSingle?: Maybe<CostcenterType>;
  costcenterCatalog?: Maybe<CostcenterType>;
  costcenterCatalogExtraRental?: Maybe<CostcenterType>;
  costcenterCatalogTransfer?: Maybe<CostcenterType>;
  costcenterInsurance?: Maybe<CostcenterType>;
  costcenterMachineRentThrough?: Maybe<CostcenterType>;
  costcenterOpHours?: Maybe<CostcenterType>;
  costcenterOpeningFee?: Maybe<CostcenterType>;
  costtypeBillingSurcharge?: Maybe<CosttypeType>;
  costtypeBreakdownSingle?: Maybe<CosttypeType>;
  costtypeCatalog?: Maybe<CosttypeType>;
  costtypeCatalogExtraRental?: Maybe<CosttypeType>;
  costtypeCatalogTransfer?: Maybe<CosttypeType>;
  costtypeInsurance?: Maybe<CosttypeType>;
  costtypeOpHours?: Maybe<CosttypeType>;
  costtypeOpeningFee?: Maybe<CosttypeType>;
  fileCatalogCoverBack: Scalars['String'];
  fileCatalogCoverFront: Scalars['String'];
  fileCatalogCustomerCoverBack: Scalars['String'];
  fileCatalogCustomerCoverFront: Scalars['String'];
  fileConfirmationCoverBack: Scalars['String'];
  fileConfirmationCoverFront: Scalars['String'];
  helpTextImagesGive: Scalars['String'];
  helpTextImagesReturn: Scalars['String'];
  id: Scalars['ID'];
  insurancePercent: Scalars['Decimal'];
  openingFeeAmount: Scalars['Decimal'];
  openingFeeLimit: Scalars['Decimal'];
  productNumberBillingSurcharge: Scalars['String'];
  productNumberInsurance: Scalars['String'];
  productNumberOpHours: Scalars['String'];
  productNumberOpeningFee: Scalars['String'];
  productNumberSingle: Scalars['String'];
  textConfirmation1: Scalars['String'];
  textConfirmation2: Scalars['String'];
  textContract1: Scalars['String'];
  textContract2: Scalars['String'];
  textOffer0: Scalars['String'];
  textOffer1: Scalars['String'];
  textOffer2: Scalars['String'];
  vat: Scalars['Decimal'];
  vatFactor: Scalars['Decimal'];
};

export type SplitOrder = {
  __typename?: 'SplitOrder';
  order?: Maybe<OrderType>;
};

export type SplitReservation = {
  __typename?: 'SplitReservation';
  reservation?: Maybe<ReservationType>;
};

export type TransportInvoicingSummaryType = {
  __typename?: 'TransportInvoicingSummaryType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  invoiceRow?: Maybe<InvoiceRowType>;
  order: OrderType;
  percent: Scalars['Decimal'];
  price: Scalars['Decimal'];
  reference?: Maybe<ReferenceType>;
};

export type TransportInvoicingType = {
  __typename?: 'TransportInvoicingType';
  catalogTransferMethod?: Maybe<CatalogTransferMethodType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  description: Scalars['String'];
  discountPercent: Scalars['Decimal'];
  id: Scalars['ID'];
  invoiceRow?: Maybe<InvoiceRowType>;
  method: TransportsTransportInvoicingMethodChoices;
  order: OrderType;
  price: Scalars['Decimal'];
  quantity: Scalars['Decimal'];
  reference?: Maybe<ReferenceType>;
  rowType: TransportsTransportInvoicingRowTypeChoices;
  title: Scalars['String'];
  unitPrice: Scalars['Decimal'];
};

export type TransportReservationType = {
  reservationId?: InputMaybe<Scalars['ID']>;
  transportId?: InputMaybe<Scalars['ID']>;
};

export type TransportType = {
  __typename?: 'TransportType';
  address: Scalars['String'];
  catalogTransferMethod?: Maybe<CatalogTransferMethodType>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  dateDeadline?: Maybe<Scalars['Date']>;
  dateEndUsage?: Maybe<Scalars['Date']>;
  district: Scalars['String'];
  drivecontentSet: Array<DriveContentType>;
  handledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  information: Scalars['String'];
  method: TransportsTransportMethodChoices;
  order: OrderType;
  postcode: Scalars['String'];
  reservations: Array<ReservationType>;
  timeDeadline?: Maybe<Scalars['Time']>;
  timeEndUsage?: Maybe<Scalars['Time']>;
};

/** An enumeration. */
export enum TransportsTransportInvoicingMethodChoices {
  /** Retrieve */
  Retrieve = 'RETRIEVE',
  /** Send */
  Send = 'SEND'
}

/** An enumeration. */
export enum TransportsTransportInvoicingRowTypeChoices {
  /** Basic */
  Basic = 'BASIC',
  /** Distance */
  Distance = 'DISTANCE',
  /** Stop */
  Stop = 'STOP'
}

/** An enumeration. */
export enum TransportsTransportMethodChoices {
  /** Retrieve */
  Retrieve = 'RETRIEVE',
  /** Send */
  Send = 'SEND'
}

export type UpdateCatalog = {
  __typename?: 'UpdateCatalog';
  catalog?: Maybe<CatalogType>;
};

export type UpdateCatalogCategory = {
  __typename?: 'UpdateCatalogCategory';
  catalogCategory?: Maybe<CatalogCategoryType>;
};

export type UpdateCatalogCategoryOrder = {
  __typename?: 'UpdateCatalogCategoryOrder';
  catalogCategories?: Maybe<Array<CatalogCategoryType>>;
};

export type UpdateCatalogCategoryUpper = {
  __typename?: 'UpdateCatalogCategoryUpper';
  catalogCategoryUpper?: Maybe<CatalogCategoryUpperType>;
};

export type UpdateCatalogCategoryUpperOrder = {
  __typename?: 'UpdateCatalogCategoryUpperOrder';
  catalogCategoriesUpper?: Maybe<Array<CatalogCategoryUpperType>>;
};

export type UpdateCatalogExtraCategoryRental = {
  __typename?: 'UpdateCatalogExtraCategoryRental';
  catalogExtraCategoryRental?: Maybe<CatalogExtraCategoryRentalType>;
};

export type UpdateCatalogExtraCategoryRentalOrder = {
  __typename?: 'UpdateCatalogExtraCategoryRentalOrder';
  catalogExtraCategoriesRental?: Maybe<Array<Maybe<CatalogExtraCategoryRentalType>>>;
};

export type UpdateCatalogExtraCategoryUnitPrice = {
  __typename?: 'UpdateCatalogExtraCategoryUnitPrice';
  catalogExtraCategoryUnitPrice?: Maybe<CatalogExtraCategoryUnitPriceType>;
};

export type UpdateCatalogExtraCategoryUpperRental = {
  __typename?: 'UpdateCatalogExtraCategoryUpperRental';
  catalogExtraCategoryUpperRental?: Maybe<CatalogExtraCategoryUpperRentalType>;
};

export type UpdateCatalogExtraCategoryUpperRentalOrder = {
  __typename?: 'UpdateCatalogExtraCategoryUpperRentalOrder';
  catalogExtraCategoriesUpperRental?: Maybe<Array<Maybe<CatalogExtraCategoryUpperRentalType>>>;
};

export type UpdateCatalogExtraRowInvoice = {
  __typename?: 'UpdateCatalogExtraRowInvoice';
  catalogExtraRowInvoice?: Maybe<CatalogExtraRowInvoiceType>;
};

export type UpdateCatalogExtraRowRental = {
  __typename?: 'UpdateCatalogExtraRowRental';
  catalogExtraRowRental?: Maybe<CatalogExtraRowRentalType>;
};

export type UpdateCatalogExtraRowRentalOrder = {
  __typename?: 'UpdateCatalogExtraRowRentalOrder';
  catalogExtraRowsRental?: Maybe<Array<Maybe<CatalogExtraRowRentalType>>>;
};

export type UpdateCatalogExtraRowUnitPrice = {
  __typename?: 'UpdateCatalogExtraRowUnitPrice';
  catalogExtraRowUnitPrice?: Maybe<CatalogExtraRowUnitPriceType>;
};

export type UpdateCatalogRow = {
  __typename?: 'UpdateCatalogRow';
  catalogRow?: Maybe<CatalogRowType>;
};

export type UpdateCatalogRowLocked = {
  __typename?: 'UpdateCatalogRowLocked';
  catalogRow?: Maybe<CatalogRowType>;
};

export type UpdateCatalogRowOrder = {
  __typename?: 'UpdateCatalogRowOrder';
  catalogRows?: Maybe<Array<CatalogRowType>>;
};

export type UpdateCatalogRowSource = {
  __typename?: 'UpdateCatalogRowSource';
  catalogRow?: Maybe<CatalogRowType>;
};

export type UpdateCatalogTransferMethod = {
  __typename?: 'UpdateCatalogTransferMethod';
  catalogTransferMethod?: Maybe<CatalogTransferMethodType>;
};

export type UpdateCatalogTransferPrice = {
  __typename?: 'UpdateCatalogTransferPrice';
  catalogTransferPrice?: Maybe<CatalogTransferPriceType>;
};

export type UpdateCommission = {
  __typename?: 'UpdateCommission';
  commission?: Maybe<CommissionType>;
};

export type UpdateCommissionLevel = {
  __typename?: 'UpdateCommissionLevel';
  commissionLevel?: Maybe<CommissionLevelType>;
};

export type UpdateCommissionRange = {
  __typename?: 'UpdateCommissionRange';
  commissionRange?: Maybe<CommissionRangeType>;
};

export type UpdateCommissionTemplate = {
  __typename?: 'UpdateCommissionTemplate';
  commissionTemplate?: Maybe<CommissionTemplateType>;
};

export type UpdateCommissionUserSetting = {
  __typename?: 'UpdateCommissionUserSetting';
  commissionUserSetting?: Maybe<CommissionUserSettingType>;
};

export type UpdateCostcenter = {
  __typename?: 'UpdateCostcenter';
  costcenter?: Maybe<CostcenterType>;
};

export type UpdateCosttype = {
  __typename?: 'UpdateCosttype';
  costtype?: Maybe<CosttypeType>;
};

export type UpdateCosttypeForRentThrough = {
  __typename?: 'UpdateCosttypeForRentThrough';
  costtypeForRentThrough?: Maybe<CosttypeForRentThroughType>;
};

export type UpdateCustomer = {
  __typename?: 'UpdateCustomer';
  customer?: Maybe<CustomerType>;
};

export type UpdateCustomerArea = {
  __typename?: 'UpdateCustomerArea';
  customerArea?: Maybe<CustomerAreaType>;
};

export type UpdateCustomerIndustry = {
  __typename?: 'UpdateCustomerIndustry';
  customerIndustry?: Maybe<CustomerIndustryType>;
};

export type UpdateCustomerParent = {
  __typename?: 'UpdateCustomerParent';
  customer?: Maybe<CustomerType>;
};

export type UpdateCustomerRank = {
  __typename?: 'UpdateCustomerRank';
  customerRank?: Maybe<CustomerRankType>;
};

export type UpdateDiscountList = {
  __typename?: 'UpdateDiscountList';
  discountList?: Maybe<DiscountListType>;
};

export type UpdateDiscountRow = {
  __typename?: 'UpdateDiscountRow';
  discountRow?: Maybe<DiscountRowType>;
};

export type UpdateDiscountRowPercentsPrints = {
  __typename?: 'UpdateDiscountRowPercentsPrints';
  success?: Maybe<Scalars['Boolean']>;
};

export type UpdateDrive = {
  __typename?: 'UpdateDrive';
  drive?: Maybe<DriveType>;
};

export type UpdateEvent = {
  __typename?: 'UpdateEvent';
  event?: Maybe<EventType>;
};

export type UpdateGroup = {
  __typename?: 'UpdateGroup';
  group?: Maybe<GroupType>;
};

export type UpdateHoliday = {
  __typename?: 'UpdateHoliday';
  holiday?: Maybe<HolidayType>;
};

export type UpdateInspectionGiveAnswer = {
  __typename?: 'UpdateInspectionGiveAnswer';
  inspectionGiveAnswer?: Maybe<InspectionGiveAnswerType>;
};

export type UpdateInspectionGiveCategory = {
  __typename?: 'UpdateInspectionGiveCategory';
  inspectionGiveCategory?: Maybe<InspectionGiveCategoryType>;
};

export type UpdateInspectionGiveCategoryOrder = {
  __typename?: 'UpdateInspectionGiveCategoryOrder';
  inspectionGiveCategories?: Maybe<Array<Maybe<InspectionGiveCategoryType>>>;
};

export type UpdateInspectionGiveQuestion = {
  __typename?: 'UpdateInspectionGiveQuestion';
  inspectionGiveQuestion?: Maybe<InspectionGiveQuestionType>;
};

export type UpdateInspectionGiveQuestionOption = {
  __typename?: 'UpdateInspectionGiveQuestionOption';
  inspectionGiveQuestionOption?: Maybe<InspectionGiveQuestionOptionType>;
};

export type UpdateInspectionGiveQuestionOrder = {
  __typename?: 'UpdateInspectionGiveQuestionOrder';
  inspectionGiveCategories?: Maybe<Array<Maybe<InspectionGiveQuestionType>>>;
};

export type UpdateInspectionGiveTag = {
  __typename?: 'UpdateInspectionGiveTag';
  inspectionGiveTag?: Maybe<InspectionGiveTagType>;
};

export type UpdateInspectionReturnAnswer = {
  __typename?: 'UpdateInspectionReturnAnswer';
  inspectionReturnAnswer?: Maybe<InspectionReturnAnswerType>;
};

export type UpdateInspectionReturnCategory = {
  __typename?: 'UpdateInspectionReturnCategory';
  inspectionReturnCategory?: Maybe<InspectionReturnCategoryType>;
};

export type UpdateInspectionReturnCategoryOrder = {
  __typename?: 'UpdateInspectionReturnCategoryOrder';
  inspectionReturnCategories?: Maybe<Array<Maybe<InspectionReturnCategoryType>>>;
};

export type UpdateInspectionReturnQuestion = {
  __typename?: 'UpdateInspectionReturnQuestion';
  inspectionReturnQuestion?: Maybe<InspectionReturnQuestionType>;
};

export type UpdateInspectionReturnQuestionOption = {
  __typename?: 'UpdateInspectionReturnQuestionOption';
  inspectionReturnQuestionOption?: Maybe<InspectionReturnQuestionOptionType>;
};

export type UpdateInspectionReturnQuestionOrder = {
  __typename?: 'UpdateInspectionReturnQuestionOrder';
  inspectionReturnCategories?: Maybe<Array<Maybe<InspectionReturnQuestionType>>>;
};

export type UpdateInspectionReturnTag = {
  __typename?: 'UpdateInspectionReturnTag';
  inspectionReturnTag?: Maybe<InspectionReturnTagType>;
};

export type UpdateInvoice = {
  __typename?: 'UpdateInvoice';
  invoice?: Maybe<InvoiceType>;
};

export type UpdateInvoiceRow = {
  __typename?: 'UpdateInvoiceRow';
  invoiceRow?: Maybe<InvoiceRowType>;
};

export type UpdateLocation = {
  __typename?: 'UpdateLocation';
  location?: Maybe<LocationType>;
};

export type UpdateLocationTransfer = {
  __typename?: 'UpdateLocationTransfer';
  location?: Maybe<LocationType>;
};

export type UpdateMachine = {
  __typename?: 'UpdateMachine';
  machine?: Maybe<MachineType>;
};

export type UpdateMachineBreakdown = {
  __typename?: 'UpdateMachineBreakdown';
  machineBreakdown?: Maybe<MachineBreakdownType>;
};

export type UpdateMachineBulkBreakdown = {
  __typename?: 'UpdateMachineBulkBreakdown';
  machineBulkBreakdown?: Maybe<MachineBulkBreakdownType>;
};

export type UpdateMachineInfoAnswer = {
  __typename?: 'UpdateMachineInfoAnswer';
  machineInfoAnswer?: Maybe<MachineInfoAnswerType>;
};

export type UpdateMachineInfoCategory = {
  __typename?: 'UpdateMachineInfoCategory';
  machineInfoCategory?: Maybe<MachineInfoCategoryType>;
};

export type UpdateMachineInfoQuestion = {
  __typename?: 'UpdateMachineInfoQuestion';
  machineInfoQuestion?: Maybe<MachineInfoQuestionType>;
};

export type UpdateMachineInfoQuestionOption = {
  __typename?: 'UpdateMachineInfoQuestionOption';
  machineInfoQuestionOption?: Maybe<MachineInfoQuestionOptionType>;
};

export type UpdateMachineInfoTag = {
  __typename?: 'UpdateMachineInfoTag';
  machineInfoTag?: Maybe<MachineInfoTagType>;
};

export type UpdateMachineMake = {
  __typename?: 'UpdateMachineMake';
  machineMake?: Maybe<MachineMakeType>;
};

export type UpdateMachineModel = {
  __typename?: 'UpdateMachineModel';
  machineModel?: Maybe<MachineModelType>;
};

export type UpdateMaintenance = {
  __typename?: 'UpdateMaintenance';
  maintenance?: Maybe<MaintenanceType>;
};

export type UpdateMaintenanceImage = {
  __typename?: 'UpdateMaintenanceImage';
  maintenanceImage?: Maybe<MaintenanceImageType>;
};

export type UpdateMaintenanceInterval = {
  __typename?: 'UpdateMaintenanceInterval';
  maintenanceInterval?: Maybe<MaintenanceIntervalType>;
};

export type UpdateMaintenanceSettings = {
  __typename?: 'UpdateMaintenanceSettings';
  maintenanceSettings?: Maybe<MaintenanceSettingType>;
};

export type UpdateOperationHour = {
  __typename?: 'UpdateOperationHour';
  operationHour?: Maybe<OperationHourType>;
};

export type UpdateOrder = {
  __typename?: 'UpdateOrder';
  order?: Maybe<OrderType>;
};

export type UpdateOrderStatus = {
  __typename?: 'UpdateOrderStatus';
  order?: Maybe<OrderType>;
};

export type UpdateProductCode = {
  __typename?: 'UpdateProductCode';
  productCode?: Maybe<ProductCodeType>;
};

export type UpdateReference = {
  __typename?: 'UpdateReference';
  reference?: Maybe<ReferenceType>;
};

export type UpdateReservation = {
  __typename?: 'UpdateReservation';
  reservation?: Maybe<ReservationType>;
};

export type UpdateReservationImageBasic = {
  __typename?: 'UpdateReservationImageBasic';
  reservationImageBasic?: Maybe<ReservationImageBasicType>;
};

export type UpdateReservationStamps = {
  __typename?: 'UpdateReservationStamps';
  reservation?: Maybe<ReservationType>;
};

export type UpdateReservationsReturnAt = {
  __typename?: 'UpdateReservationsReturnAt';
  reservations?: Maybe<Array<Maybe<ReservationType>>>;
};

export type UpdateSettings = {
  __typename?: 'UpdateSettings';
  settings?: Maybe<SettingsType>;
};

export type UpdateTransport = {
  __typename?: 'UpdateTransport';
  transport?: Maybe<TransportType>;
};

export type UpdateTransportInvoicing = {
  __typename?: 'UpdateTransportInvoicing';
  transportInvoicing?: Maybe<TransportInvoicingType>;
};

export type UpdateTransportInvoicingSummary = {
  __typename?: 'UpdateTransportInvoicingSummary';
  transportInvoicingSummary?: Maybe<TransportInvoicingSummaryType>;
};

export type UpdateUser = {
  __typename?: 'UpdateUser';
  user?: Maybe<UserType>;
};

export type UploadCatalogCategory = {
  __typename?: 'UploadCatalogCategory';
  success?: Maybe<Scalars['Boolean']>;
};

export type UploadCatalogRow = {
  __typename?: 'UploadCatalogRow';
  success?: Maybe<Scalars['Boolean']>;
};

export type UploadFileCatalogCoverBack = {
  __typename?: 'UploadFileCatalogCoverBack';
  settings?: Maybe<SettingsType>;
};

export type UploadFileCatalogCoverFront = {
  __typename?: 'UploadFileCatalogCoverFront';
  settings?: Maybe<SettingsType>;
};

export type UploadFileCatalogCustomerCoverBack = {
  __typename?: 'UploadFileCatalogCustomerCoverBack';
  settings?: Maybe<SettingsType>;
};

export type UploadFileCatalogCustomerCoverFront = {
  __typename?: 'UploadFileCatalogCustomerCoverFront';
  settings?: Maybe<SettingsType>;
};

export type UploadFileConfirmationCoverBack = {
  __typename?: 'UploadFileConfirmationCoverBack';
  settings?: Maybe<SettingsType>;
};

export type UploadFileConfirmationCoverFront = {
  __typename?: 'UploadFileConfirmationCoverFront';
  settings?: Maybe<SettingsType>;
};

export type UploadFileMachineCheckList = {
  __typename?: 'UploadFileMachineCheckList';
  machine?: Maybe<MachineType>;
};

export type UploadFileMachineFinancing = {
  __typename?: 'UploadFileMachineFinancing';
  machine?: Maybe<MachineType>;
};

export type UploadFileMachinePurchase = {
  __typename?: 'UploadFileMachinePurchase';
  machine?: Maybe<MachineType>;
};

export type UploadFileMachineTypePlate = {
  __typename?: 'UploadFileMachineTypePlate';
  machine?: Maybe<MachineType>;
};

export type UploadMaintenanceImage = {
  __typename?: 'UploadMaintenanceImage';
  maintenanceImage?: Maybe<MaintenanceImageType>;
};

export type UploadReservationImageBasic = {
  __typename?: 'UploadReservationImageBasic';
  reservationImageBasic?: Maybe<ReservationImageBasicType>;
};

export type UploadReservationImages = {
  __typename?: 'UploadReservationImages';
  reservationImages?: Maybe<Array<Maybe<ReservationImageType>>>;
};

export type UserInfoType = {
  __typename?: 'UserInfoType';
  address: Scalars['String'];
  district: Scalars['String'];
  id: Scalars['ID'];
  language: UserInfosUserInfoLanguageChoices;
  location?: Maybe<LocationType>;
  phone: Scalars['String'];
  postcode: Scalars['String'];
  user: UserType;
};

/** An enumeration. */
export enum UserInfosUserInfoLanguageChoices {
  /** Englanti */
  En = 'EN',
  /** Suomi */
  Fi = 'FI'
}

export type UserType = {
  __typename?: 'UserType';
  UserInfo?: Maybe<UserInfoType>;
  catalogSet: Array<CatalogType>;
  commissionReportsCreated: Array<CommissionReportType>;
  commissionSet: Array<CommissionType>;
  commissionreportSet: Array<CommissionReportType>;
  commissionusersetting?: Maybe<CommissionUserSettingType>;
  costcenterSet: Array<CostcenterType>;
  costtypeSet: Array<CosttypeType>;
  customersCreated: Array<CustomerType>;
  customersOwned: Array<CustomerType>;
  dateJoined: Scalars['DateTime'];
  discountlistSet: Array<DiscountListType>;
  driveSet: Array<DriveType>;
  email: Scalars['String'];
  eventSet: Array<EventType>;
  eventoffercatalogSet: Array<EventOfferCatalogType>;
  eventofferdiscountlistSet: Array<EventOfferDiscountListType>;
  firstName: Scalars['String'];
  /** Käyttäjäryhmät joihin tämä käyttäjä kuuluu. Käyttäjä saa käyttöoikeudet kaikista käyttäjäryhmistä, joihin hän kuuluu. */
  groups: Array<GroupType>;
  id: Scalars['ID'];
  invoiceSet: Array<InvoiceType>;
  invoicerowSet: Array<InvoiceRowType>;
  /** Määrää, voiko käyttäjä kirjautua sisään. Tällä voi estää käyttäjätilin käytön poistamatta sitä. */
  isActive: Scalars['Boolean'];
  /** Määrittää, pääseekö käyttäjä tähän sivuston ylläpito-osioon. */
  isStaff: Scalars['Boolean'];
  /** Antaa käyttäjälle kaikki oikeudet ilman, että niitä täytyy erikseen luetella. */
  isSuperuser: Scalars['Boolean'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  logSet: Array<LogType>;
  machineBreakdownsFixed: Array<MachineBreakdownType>;
  machinebreakdownSet: Array<MachineBreakdownType>;
  machinebreakdowncommentSet: Array<MachineBreakdownCommentType>;
  maintenanceSet: Array<MaintenanceType>;
  maintenancecommentSet: Array<MaintenanceCommentType>;
  maintenanceimageSet: Array<MaintenanceImageType>;
  maintenanceintervalSet: Array<MaintenanceIntervalType>;
  maintenancesettingSet: Array<MaintenanceSettingType>;
  orderSet: Array<OrderType>;
  password: Scalars['String'];
  reservationcommentSet: Array<ReservationCommentType>;
  reservationimageSet: Array<ReservationImageType>;
  reservationimagebasicSet: Array<ReservationImageBasicType>;
  reservationsCreated: Array<ReservationType>;
  reservationsGiven: Array<ReservationType>;
  reservationsReturned: Array<ReservationType>;
  serviceTechnician: Array<MaintenanceType>;
  transportSet: Array<TransportType>;
  transportinvoicingSet: Array<TransportInvoicingType>;
  /** Tämän käyttäjän spesifit oikeudet. */
  userPermissions: Array<PermissionType>;
  /** Vaaditaan. Enintään 150 merkkiä. Vain kirjaimet, numerot ja @/./+/-/_ ovat sallittuja. */
  username: Scalars['String'];
  verifiedBy: Array<MaintenanceType>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};
