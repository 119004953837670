import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { CatalogType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import CatalogSortCategoriesUpper from "./CatalogSortCategoriesUpper";
import CatalogSortCategories from "./CatalogSortCategories";
import { ID_EMPTY } from "../../utils/constants";
import CatalogSortRows from "./CatalogSortRows";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function CatalogSort({ classes, catalog }: Props) {
  const [catalogCategoryUpperIdSelected, setCatalogCategoryUpperIdSelected] =
    useState(ID_EMPTY);
  const [catalogCategoryIdSelected, setCatalogCategoryIdSelected] =
    useState(ID_EMPTY);

  return (
    <Row>
      <Col className={classes.col} sm={12} md={4}>
        <CatalogSortCategoriesUpper
          catalogCategoriesUpper={catalog.catalogcategoryupperSet}
        />
      </Col>
      <Col className={classes.col} sm={12} md={4}>
        <CatalogSortCategories
          catalogCategoriesUpper={catalog.catalogcategoryupperSet}
          catalogCategoryUpperIdSelected={catalogCategoryUpperIdSelected}
          setCatalogCategoryUpperIdSelected={setCatalogCategoryUpperIdSelected}
        />
      </Col>
      <Col className={classes.col} sm={12} md={4}>
        <CatalogSortRows
          catalogCategoriesUpper={catalog.catalogcategoryupperSet}
          catalogCategoryIdSelected={catalogCategoryIdSelected}
          setCatalogCategoryIdSelected={setCatalogCategoryIdSelected}
        />
      </Col>
    </Row>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    col: {
      marginBottom: spacing(3),
      userSelect: "none",
    },
  });

export default withStyles(styles)(CatalogSort);
