import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  GET_COSTTYPES_QUERY,
  QueryResultCosttypes,
} from "../../apollo/queries/costtypes";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { CosttypeEmpty } from "../../entity/empties";
import { CosttypeType } from "../../entity/types";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "react-bootstrap";
import DialogCosttype from "../Costtype/DialogCosttype";

interface Props extends WithStyles<typeof styles> {}

function DimensionsCosttypes({ classes }: Props) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const [costtypeSelected, setCosttypeSelected] = useState(CosttypeEmpty);

  const { loading, error, data } = useQuery<QueryResultCosttypes>(
    GET_COSTTYPES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("costtypes"),
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "costtypes.change_costtype",
  ]);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "costtypes.add_costtype",
  ]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const costtypes: CosttypeType[] = data.costtypes
    ? data.costtypes.map((o) => ({
        ...o,
      }))
    : [];

  const getTableActions = () => {
    if (!hasPermissionEdit) {
      return [];
    }

    return [
      {
        icon: () => <FontAwesomeIcon icon={faEllipsisV} />,
        tooltip: t("edit"),
        onClick: (event: any, rowData: CosttypeType | CosttypeType[]) => {
          const dataSingle: CosttypeType = Array.isArray(rowData)
            ? rowData[0]
            : rowData;
          setCosttypeSelected(dataSingle);
          setOpenDialog(true);
        },
      },
    ];
  };

  const onClickAdd = () => {
    setCosttypeSelected(CosttypeEmpty);
    setOpenDialog(!openDialog);
  };

  return (
    <div>
      <h4>{t("costtypes")}</h4>
      <MaterialTable<CosttypeType>
        style={getTableStyle()}
        title={""}
        icons={tableIcons}
        localization={GetTableLocalization(t)}
        columns={[
          { title: t("identifier"), field: "number" },
          { title: t("name"), field: "name" },
        ]}
        data={costtypes}
        options={getTableOptions()}
        actions={getTableActions()}
      />
      {openDialog && (
        <DialogCosttype
          costtype={costtypeSelected}
          setCosttype={setCosttypeSelected}
          open={openDialog}
          setOpen={setOpenDialog}
        />
      )}
      {hasPermissionAdd && (
        <Button
          onClick={onClickAdd}
          className="btnRound float-end mt-1"
          variant="primary"
          size="lg"
        >
          {openDialog ? <ClearIcon /> : <AddIcon />}
        </Button>
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DimensionsCosttypes);
