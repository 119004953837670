import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import CustomerList from "../components/Customer/CustomerList";
import CreateCustomer from "../components/Customer/CreateCustomer";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { createStyles, Theme } from "@material-ui/core";
import {
  GET_CUSTOMERS_FOR_LIST_QUERY,
  QueryResultCustomersForList,
} from "../apollo/queries/customers";
import { useQuery } from "@apollo/client";
import { Container } from "react-bootstrap";
import { QueryCustomersArgs } from "../entity/types";
import { useTranslation } from "react-i18next";
import { CONTAINER_MAIN_WIDTH_XL, ID_EMPTY } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import CustomerListFilters from "../components/Customer/CustomerListFilters";
import HeaderPage from "../components/Shared/HeaderPage";
import {
  CustomerListFiltersType,
  getCustomerListFilterDefaults,
  setCustomerListFilterDefaults,
} from "../utils/customers/customer";

interface Props extends WithStyles<typeof styles> {}

function Customers({ classes }: Props) {
  const { t } = useTranslation();

  const defaults = getCustomerListFilterDefaults();
  const [filters, setFilters] = useState<CustomerListFiltersType>(defaults);

  useEffect(() => {
    setCustomerListFilterDefaults(filters);
  }, [filters]);

  const { loading, error, data } = useQuery<
    QueryResultCustomersForList,
    QueryCustomersArgs
  >(GET_CUSTOMERS_FOR_LIST_QUERY, {
    variables: {
      userOwnerIds: filters.userIdsSelected.filter((id) => id !== ID_EMPTY),
      isBusiness: filters.isBusiness,
      customerIndustryIds: filters.customerIndustryIds.filter(
        (i) => i !== ID_EMPTY
      ),
      customerAreaIds: filters.customerAreaIds.filter((id) => id !== ID_EMPTY),
      customerRankIds: filters.customerRankIds.filter((id) => id !== ID_EMPTY),
    },
    skip: !filters.canQuery,
    fetchPolicy: getQueryFetchPolicy("customers"),
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("customers")} />
      <div className="containerInner">
        <CreateCustomer btnClass="addButton" showEventOption={true} />
        <CustomerListFilters filters={filters} setFilters={setFilters} />
        {filters.canQuery && (
          <CustomerList customers={data?.customers ? data.customers : []} />
        )}
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
  });

export default withStyles(styles)(Customers);
