import * as Types from '../../entity/types';

import { gql } from '@apollo/client';
import { ReservationFieldsForTableFragmentDoc } from './reservations.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomerFieldsFragment = (
  { __typename?: 'CustomerType' }
  & Pick<Types.CustomerType, 'id' | 'customerNumber' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'visitAddress' | 'visitPostcode' | 'visitDistrict' | 'contactNext' | 'isBusiness' | 'creditInformationOk' | 'creditInformationCheckedAt' | 'creditRiskClass'>
  & { customerIndustries: Array<(
    { __typename?: 'CustomerIndustryType' }
    & Pick<Types.CustomerIndustryType, 'id' | 'title'>
  )> }
);

export type CustomerFullFieldsFragment = (
  { __typename?: 'CustomerType' }
  & Pick<Types.CustomerType, 'id' | 'customerNumber' | 'businessId' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'email' | 'phone' | 'visitAddress' | 'visitPostcode' | 'visitDistrict' | 'invoicingAddress' | 'invoicingDistrict' | 'invoicingEmail' | 'invoicingPostcode' | 'invoicingEAddress' | 'invoicingEOperator' | 'contactNext' | 'isBusiness' | 'creditInformationOk' | 'creditInformationCheckedAt' | 'creditRiskClass' | 'blockedAt' | 'blockedReason' | 'preInvoicingAt' | 'preInvoicingReason' | 'hasInsurance' | 'useParentInvoicing' | 'useParentPricing' | 'handledInvoicingAt' | 'discountGlobalCatalogRow' | 'discountGlobalCatalogExtra' | 'paymentTerm' | 'noBillingSurchargeAt' | 'createdAt'>
  & { customerIndustries: Array<(
    { __typename?: 'CustomerIndustryType' }
    & Pick<Types.CustomerIndustryType, 'id' | 'title'>
  )>, customerRank?: Types.Maybe<(
    { __typename?: 'CustomerRankType' }
    & Pick<Types.CustomerRankType, 'id'>
  )>, customerAreas: Array<(
    { __typename?: 'CustomerAreaType' }
    & Pick<Types.CustomerAreaType, 'id'>
  )>, userOwner?: Types.Maybe<(
    { __typename?: 'UserType' }
    & Pick<Types.UserType, 'id'>
  )>, customerParent?: Types.Maybe<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id'>
  )>, discountListSelected?: Types.Maybe<(
    { __typename?: 'DiscountListType' }
    & Pick<Types.DiscountListType, 'id'>
  )>, catalogSelected?: Types.Maybe<(
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id'>
  )>, createdBy?: Types.Maybe<(
    { __typename?: 'UserType' }
    & Pick<Types.UserType, 'id'>
  )>, customersDiscountLists: Array<(
    { __typename?: 'DiscountListType' }
    & Pick<Types.DiscountListType, 'id'>
  )>, customersChildren: Array<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id'>
  )>, referenceSet: Array<(
    { __typename?: 'ReferenceType' }
    & Pick<Types.ReferenceType, 'id'>
  )>, orderSet: Array<(
    { __typename?: 'OrderType' }
    & Pick<Types.OrderType, 'id'>
  )> }
);

export type CustomerCalendarOrderFieldsFragment = (
  { __typename?: 'CustomerType' }
  & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'businessId' | 'email' | 'isBusiness' | 'blockedAt' | 'creditInformationOk' | 'preInvoicingAt'>
  & { discountListSelected?: Types.Maybe<(
    { __typename?: 'DiscountListType' }
    & Pick<Types.DiscountListType, 'id' | 'name'>
  )>, catalogSelected?: Types.Maybe<(
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name'>
  )>, customerParent?: Types.Maybe<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'blockedAt' | 'creditInformationOk' | 'preInvoicingAt'>
  )> }
);

export type GetCustomersForListQueryVariables = Types.Exact<{
  userOwnerIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  isBusiness?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']>> | Types.InputMaybe<Types.Scalars['String']>>;
  customerIndustryIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  customerAreaIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  customerRankIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type GetCustomersForListQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'customerNumber' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'visitAddress' | 'visitPostcode' | 'visitDistrict' | 'contactNext' | 'creditInformationOk' | 'creditInformationCheckedAt' | 'creditRiskClass'>
    & { customerAreas: Array<(
      { __typename?: 'CustomerAreaType' }
      & Pick<Types.CustomerAreaType, 'id' | 'title'>
    )>, customerRank?: Types.Maybe<(
      { __typename?: 'CustomerRankType' }
      & Pick<Types.CustomerRankType, 'id' | 'title' | 'order'>
    )>, userOwner?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, customerParent?: Types.Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'creditInformationOk' | 'creditInformationCheckedAt' | 'creditRiskClass'>
    )>, customersChildren: Array<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id'>
    )> }
  )> }
);

export type GetCustomerQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'businessId' | 'email' | 'phone' | 'invoicingAddress' | 'invoicingDistrict' | 'invoicingEmail' | 'invoicingPostcode' | 'invoicingEAddress' | 'invoicingEOperator' | 'blockedAt' | 'blockedReason' | 'preInvoicingAt' | 'preInvoicingReason' | 'hasInsurance' | 'useParentInvoicing' | 'useParentPricing' | 'discountGlobalCatalogRow' | 'discountGlobalCatalogExtra' | 'paymentTerm' | 'noBillingSurchargeAt' | 'createdAt' | 'id' | 'customerNumber' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'visitAddress' | 'visitPostcode' | 'visitDistrict' | 'contactNext' | 'isBusiness' | 'creditInformationOk' | 'creditInformationCheckedAt' | 'creditRiskClass'>
    & { userOwner?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, customerAreas: Array<(
      { __typename?: 'CustomerAreaType' }
      & Pick<Types.CustomerAreaType, 'id'>
    )>, customerRank?: Types.Maybe<(
      { __typename?: 'CustomerRankType' }
      & Pick<Types.CustomerRankType, 'id'>
    )>, discountListSelected?: Types.Maybe<(
      { __typename?: 'DiscountListType' }
      & Pick<Types.DiscountListType, 'id' | 'name' | 'locked' | 'isOffer' | 'isBusiness'>
      & { discountrowSet: Array<(
        { __typename?: 'DiscountRowType' }
        & Pick<Types.DiscountRowType, 'id' | 'percent' | 'showPrint'>
        & { catalogRow: (
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id'>
          & { catalogCategory: (
            { __typename?: 'CatalogCategoryType' }
            & Pick<Types.CatalogCategoryType, 'id'>
            & { catalogCategoryUpper: (
              { __typename?: 'CatalogCategoryUpperType' }
              & Pick<Types.CatalogCategoryUpperType, 'id'>
              & { catalog: (
                { __typename?: 'CatalogType' }
                & Pick<Types.CatalogType, 'id'>
              ) }
            ) }
          ) }
        ) }
      )>, catalog: (
        { __typename?: 'CatalogType' }
        & Pick<Types.CatalogType, 'id'>
        & { catalogcategoryupperSet: Array<(
          { __typename?: 'CatalogCategoryUpperType' }
          & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
          & { catalogcategorySet: Array<(
            { __typename?: 'CatalogCategoryType' }
            & Pick<Types.CatalogCategoryType, 'id' | 'name'>
            & { catalogrowSet: Array<(
              { __typename?: 'CatalogRowType' }
              & Pick<Types.CatalogRowType, 'id' | 'name' | 'priceDayCompany' | 'priceMonthCompany' | 'priceDayPrivate' | 'priceMonthPrivate'>
            )> }
          )> }
        )> }
      ) }
    )>, catalogSelected?: Types.Maybe<(
      { __typename?: 'CatalogType' }
      & Pick<Types.CatalogType, 'id' | 'name'>
    )>, customerParent?: Types.Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname' | 'discountGlobalCatalogRow' | 'contactNext' | 'useParentInvoicing'>
      & { userOwner?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'id' | 'lastName' | 'firstName'>
      )>, customersChildren: Array<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname' | 'contactNext'>
        & { userOwner?: Types.Maybe<(
          { __typename?: 'UserType' }
          & Pick<Types.UserType, 'id' | 'lastName' | 'firstName'>
        )> }
      )>, catalogSelected?: Types.Maybe<(
        { __typename?: 'CatalogType' }
        & Pick<Types.CatalogType, 'id'>
      )>, discountListSelected?: Types.Maybe<(
        { __typename?: 'DiscountListType' }
        & Pick<Types.DiscountListType, 'id'>
      )> }
    )>, customersChildren: Array<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname' | 'contactNext'>
      & { userOwner?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'id' | 'lastName' | 'firstName'>
      )> }
    )>, orderSet: Array<(
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id' | 'status' | 'createdAt'>
      & { reference?: Types.Maybe<(
        { __typename?: 'ReferenceType' }
        & Pick<Types.ReferenceType, 'id' | 'name'>
      )>, reservationSet: Array<(
        { __typename?: 'ReservationType' }
        & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned' | 'giveAt' | 'returnAt' | 'createdAt'>
        & { machine?: Types.Maybe<(
          { __typename?: 'MachineType' }
          & Pick<Types.MachineType, 'id' | 'information' | 'identifier'>
          & { machineModel: (
            { __typename?: 'MachineModelType' }
            & Pick<Types.MachineModelType, 'id' | 'title'>
            & { machineMake: (
              { __typename?: 'MachineMakeType' }
              & Pick<Types.MachineMakeType, 'id' | 'title'>
            ) }
          ) }
        )>, catalogRow?: Types.Maybe<(
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id' | 'name'>
        )>, catalogExtraRowRental?: Types.Maybe<(
          { __typename?: 'CatalogExtraRowRentalType' }
          & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
          & { catalogExtraCategoryRental: (
            { __typename?: 'CatalogExtraCategoryRentalType' }
            & Pick<Types.CatalogExtraCategoryRentalType, 'id' | 'name'>
          ) }
        )> }
      )> }
    )>, createdBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'username'>
    )>, customerIndustries: Array<(
      { __typename?: 'CustomerIndustryType' }
      & Pick<Types.CustomerIndustryType, 'id' | 'title'>
    )> }
  ) }
);

export type GetCustomerForCalendarQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetCustomerForCalendarQuery = (
  { __typename?: 'Query' }
  & { customer: (
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'customerNumber' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'visitAddress' | 'visitPostcode' | 'visitDistrict' | 'contactNext' | 'isBusiness' | 'creditInformationOk' | 'creditInformationCheckedAt' | 'creditRiskClass' | 'businessId' | 'email' | 'phone' | 'invoicingAddress' | 'invoicingDistrict' | 'invoicingEmail' | 'invoicingPostcode' | 'invoicingEAddress' | 'invoicingEOperator' | 'blockedAt' | 'blockedReason' | 'preInvoicingAt' | 'preInvoicingReason' | 'hasInsurance' | 'useParentInvoicing' | 'useParentPricing' | 'discountGlobalCatalogRow' | 'discountGlobalCatalogExtra' | 'paymentTerm' | 'noBillingSurchargeAt' | 'createdAt'>
    & { customerIndustries: Array<(
      { __typename?: 'CustomerIndustryType' }
      & Pick<Types.CustomerIndustryType, 'id' | 'title'>
    )>, userOwner?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, customerAreas: Array<(
      { __typename?: 'CustomerAreaType' }
      & Pick<Types.CustomerAreaType, 'id'>
    )>, customerRank?: Types.Maybe<(
      { __typename?: 'CustomerRankType' }
      & Pick<Types.CustomerRankType, 'id'>
    )>, discountListSelected?: Types.Maybe<(
      { __typename?: 'DiscountListType' }
      & Pick<Types.DiscountListType, 'id' | 'name' | 'locked' | 'isOffer' | 'isBusiness'>
      & { discountrowSet: Array<(
        { __typename?: 'DiscountRowType' }
        & Pick<Types.DiscountRowType, 'id' | 'percent' | 'showPrint'>
        & { catalogRow: (
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id'>
          & { catalogCategory: (
            { __typename?: 'CatalogCategoryType' }
            & Pick<Types.CatalogCategoryType, 'id'>
            & { catalogCategoryUpper: (
              { __typename?: 'CatalogCategoryUpperType' }
              & Pick<Types.CatalogCategoryUpperType, 'id'>
              & { catalog: (
                { __typename?: 'CatalogType' }
                & Pick<Types.CatalogType, 'id'>
              ) }
            ) }
          ) }
        ) }
      )>, catalog: (
        { __typename?: 'CatalogType' }
        & Pick<Types.CatalogType, 'id'>
        & { catalogcategoryupperSet: Array<(
          { __typename?: 'CatalogCategoryUpperType' }
          & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
          & { catalogcategorySet: Array<(
            { __typename?: 'CatalogCategoryType' }
            & Pick<Types.CatalogCategoryType, 'id' | 'name'>
            & { catalogrowSet: Array<(
              { __typename?: 'CatalogRowType' }
              & Pick<Types.CatalogRowType, 'id' | 'name' | 'priceDayCompany' | 'priceMonthCompany' | 'priceDayPrivate' | 'priceMonthPrivate'>
            )> }
          )> }
        )> }
      ) }
    )>, catalogSelected?: Types.Maybe<(
      { __typename?: 'CatalogType' }
      & Pick<Types.CatalogType, 'id' | 'name'>
    )>, customerParent?: Types.Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname' | 'discountGlobalCatalogRow' | 'contactNext' | 'useParentInvoicing'>
      & { userOwner?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'id' | 'lastName' | 'firstName'>
      )>, customersChildren: Array<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname' | 'contactNext'>
        & { userOwner?: Types.Maybe<(
          { __typename?: 'UserType' }
          & Pick<Types.UserType, 'id' | 'lastName' | 'firstName'>
        )> }
      )>, catalogSelected?: Types.Maybe<(
        { __typename?: 'CatalogType' }
        & Pick<Types.CatalogType, 'id'>
      )>, discountListSelected?: Types.Maybe<(
        { __typename?: 'DiscountListType' }
        & Pick<Types.DiscountListType, 'id'>
      )> }
    )>, customersChildren: Array<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname' | 'contactNext'>
      & { userOwner?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'id' | 'lastName' | 'firstName'>
      )> }
    )>, orderSet: Array<(
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id' | 'status' | 'createdAt'>
      & { reference?: Types.Maybe<(
        { __typename?: 'ReferenceType' }
        & Pick<Types.ReferenceType, 'id' | 'name'>
      )>, reservationSet: Array<(
        { __typename?: 'ReservationType' }
        & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned' | 'giveAt' | 'returnAt' | 'createdAt'>
        & { machine?: Types.Maybe<(
          { __typename?: 'MachineType' }
          & Pick<Types.MachineType, 'id' | 'information' | 'identifier'>
          & { machineModel: (
            { __typename?: 'MachineModelType' }
            & Pick<Types.MachineModelType, 'id' | 'title'>
            & { machineMake: (
              { __typename?: 'MachineMakeType' }
              & Pick<Types.MachineMakeType, 'id' | 'title'>
            ) }
          ) }
        )>, catalogRow?: Types.Maybe<(
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id' | 'name'>
        )>, catalogExtraRowRental?: Types.Maybe<(
          { __typename?: 'CatalogExtraRowRentalType' }
          & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
          & { catalogExtraCategoryRental: (
            { __typename?: 'CatalogExtraCategoryRentalType' }
            & Pick<Types.CatalogExtraCategoryRentalType, 'id' | 'name'>
          ) }
        )> }
      )> }
    )>, createdBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'username'>
    )> }
  ) }
);

export type GetCustomerPriceForCatalogRowQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  catalogRowId: Types.Scalars['ID'];
}>;


export type GetCustomerPriceForCatalogRowQuery = (
  { __typename?: 'Query' }
  & { customerPriceForCatalogRow: (
    { __typename?: 'CustomerPriceCatalogRowType' }
    & Pick<Types.CustomerPriceCatalogRowType, 'priceDay' | 'priceMonth' | 'discountPercent'>
  ) }
);

export type GetCustomersPrimaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomersPrimaryQuery = (
  { __typename?: 'Query' }
  & { customersPrimary: Array<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'businessId' | 'name'>
  )> }
);

export type GetCustomersCalendarQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomersCalendarQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'blockedAt' | 'creditInformationOk' | 'preInvoicingAt'>
    & { customerParent?: Types.Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'blockedAt' | 'creditInformationOk' | 'preInvoicingAt'>
    )> }
  )> }
);

export type GetCustomersOpenReservationsQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  onlyEndedReservations: Types.Scalars['Boolean'];
  onlyPreinvoicingCustomers: Types.Scalars['Boolean'];
  dateHandled?: Types.InputMaybe<Types.Scalars['Date']>;
}>;


export type GetCustomersOpenReservationsQuery = (
  { __typename?: 'Query' }
  & { customersOpenReservations: Array<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname' | 'handledInvoicingAt'>
  )> }
);

export type GetCustomerRanksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerRanksQuery = (
  { __typename?: 'Query' }
  & { customerRanks: Array<(
    { __typename?: 'CustomerRankType' }
    & Pick<Types.CustomerRankType, 'id' | 'title' | 'order'>
  )> }
);

export type GetCustomerAreasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerAreasQuery = (
  { __typename?: 'Query' }
  & { customerAreas: Array<(
    { __typename?: 'CustomerAreaType' }
    & Pick<Types.CustomerAreaType, 'id' | 'title'>
  )> }
);

export type GetCustomersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { customers: Array<(
    { __typename?: 'CustomerType' }
    & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonFirstname' | 'contactPersonLastname'>
  )> }
);

export type GetCustomerIndustriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCustomerIndustriesQuery = (
  { __typename?: 'Query' }
  & { customerIndustries: Array<(
    { __typename?: 'CustomerIndustryType' }
    & Pick<Types.CustomerIndustryType, 'id' | 'title'>
  )> }
);

export const CustomerFieldsFragmentDoc = gql`
    fragment CustomerFields on CustomerType {
  id
  customerNumber
  name
  contactPersonFirstname
  contactPersonLastname
  visitAddress
  visitPostcode
  visitDistrict
  customerIndustries {
    id
    title
  }
  contactNext
  isBusiness
  creditInformationOk
  creditInformationCheckedAt
  creditRiskClass
}
    `;
export const CustomerFullFieldsFragmentDoc = gql`
    fragment CustomerFullFields on CustomerType {
  id
  customerNumber
  businessId
  name
  contactPersonFirstname
  contactPersonLastname
  email
  phone
  visitAddress
  visitPostcode
  visitDistrict
  invoicingAddress
  invoicingDistrict
  invoicingEmail
  invoicingPostcode
  invoicingEAddress
  invoicingEOperator
  customerIndustries {
    id
    title
  }
  customerRank {
    id
  }
  customerAreas {
    id
  }
  contactNext
  isBusiness
  creditInformationOk
  creditInformationCheckedAt
  creditRiskClass
  blockedAt
  blockedReason
  preInvoicingAt
  preInvoicingReason
  hasInsurance
  useParentInvoicing
  useParentPricing
  handledInvoicingAt
  discountGlobalCatalogRow
  discountGlobalCatalogExtra
  paymentTerm
  noBillingSurchargeAt
  userOwner {
    id
  }
  customerParent {
    id
  }
  discountListSelected {
    id
  }
  catalogSelected {
    id
  }
  createdBy {
    id
  }
  customersDiscountLists {
    id
  }
  customersChildren {
    id
  }
  referenceSet {
    id
  }
  orderSet {
    id
  }
  createdAt
}
    `;
export const CustomerCalendarOrderFieldsFragmentDoc = gql`
    fragment CustomerCalendarOrderFields on CustomerType {
  id
  name
  contactPersonFirstname
  contactPersonLastname
  businessId
  email
  isBusiness
  blockedAt
  creditInformationOk
  preInvoicingAt
  discountListSelected {
    id
    name
  }
  catalogSelected {
    id
    name
  }
  customerParent {
    id
    blockedAt
    creditInformationOk
    preInvoicingAt
  }
}
    `;
export const GetCustomersForListDocument = gql`
    query getCustomersForList($userOwnerIds: [ID], $isBusiness: [String], $customerIndustryIds: [ID], $customerAreaIds: [ID], $customerRankIds: [ID]) {
  customers(
    userOwnerIds: $userOwnerIds
    isBusiness: $isBusiness
    customerIndustryIds: $customerIndustryIds
    customerAreaIds: $customerAreaIds
    customerRankIds: $customerRankIds
  ) {
    id
    customerNumber
    name
    contactPersonFirstname
    contactPersonLastname
    visitAddress
    visitPostcode
    visitDistrict
    contactNext
    creditInformationOk
    creditInformationCheckedAt
    creditRiskClass
    customerAreas {
      id
      title
    }
    customerRank {
      id
      title
      order
    }
    userOwner {
      id
      firstName
      lastName
    }
    customerParent {
      id
      creditInformationOk
      creditInformationCheckedAt
      creditRiskClass
    }
    customersChildren {
      id
    }
  }
}
    `;

/**
 * __useGetCustomersForListQuery__
 *
 * To run a query within a React component, call `useGetCustomersForListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersForListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersForListQuery({
 *   variables: {
 *      userOwnerIds: // value for 'userOwnerIds'
 *      isBusiness: // value for 'isBusiness'
 *      customerIndustryIds: // value for 'customerIndustryIds'
 *      customerAreaIds: // value for 'customerAreaIds'
 *      customerRankIds: // value for 'customerRankIds'
 *   },
 * });
 */
export function useGetCustomersForListQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersForListQuery, GetCustomersForListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersForListQuery, GetCustomersForListQueryVariables>(GetCustomersForListDocument, options);
      }
export function useGetCustomersForListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersForListQuery, GetCustomersForListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersForListQuery, GetCustomersForListQueryVariables>(GetCustomersForListDocument, options);
        }
export type GetCustomersForListQueryHookResult = ReturnType<typeof useGetCustomersForListQuery>;
export type GetCustomersForListLazyQueryHookResult = ReturnType<typeof useGetCustomersForListLazyQuery>;
export type GetCustomersForListQueryResult = Apollo.QueryResult<GetCustomersForListQuery, GetCustomersForListQueryVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($id: ID!) {
  customer(id: $id) {
    ...CustomerFields
    businessId
    email
    phone
    invoicingAddress
    invoicingDistrict
    invoicingEmail
    invoicingPostcode
    invoicingEAddress
    invoicingEOperator
    blockedAt
    blockedReason
    preInvoicingAt
    preInvoicingReason
    hasInsurance
    useParentInvoicing
    useParentPricing
    discountGlobalCatalogRow
    discountGlobalCatalogExtra
    paymentTerm
    noBillingSurchargeAt
    userOwner {
      id
      firstName
      lastName
    }
    customerAreas {
      id
    }
    customerRank {
      id
    }
    discountListSelected {
      id
      name
      locked
      isOffer
      isBusiness
      discountrowSet {
        id
        percent
        showPrint
        catalogRow {
          id
          catalogCategory {
            id
            catalogCategoryUpper {
              id
              catalog {
                id
              }
            }
          }
        }
      }
      catalog {
        id
        catalogcategoryupperSet {
          id
          name
          catalogcategorySet {
            id
            name
            catalogrowSet {
              id
              name
              priceDayCompany
              priceMonthCompany
              priceDayPrivate
              priceMonthPrivate
            }
          }
        }
      }
    }
    catalogSelected {
      id
      name
    }
    customerParent {
      id
      name
      contactPersonLastname
      contactPersonFirstname
      discountGlobalCatalogRow
      userOwner {
        id
        lastName
        firstName
      }
      contactNext
      customersChildren {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
      }
      useParentInvoicing
      catalogSelected {
        id
      }
      discountListSelected {
        id
      }
    }
    customersChildren {
      id
      name
      contactPersonLastname
      contactPersonFirstname
      userOwner {
        id
        lastName
        firstName
      }
      contactNext
    }
    orderSet {
      id
      reference {
        id
        name
      }
      status
      reservationSet {
        ...ReservationFieldsForTable
      }
      createdAt
    }
    createdAt
    createdBy {
      id
      username
    }
  }
}
    ${CustomerFieldsFragmentDoc}
${ReservationFieldsForTableFragmentDoc}`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
      }
export function useGetCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerForCalendarDocument = gql`
    query getCustomerForCalendar($id: ID!) {
  customer(id: $id) {
    id
    customerNumber
    name
    contactPersonFirstname
    contactPersonLastname
    visitAddress
    visitPostcode
    visitDistrict
    customerIndustries {
      id
      title
    }
    contactNext
    isBusiness
    creditInformationOk
    creditInformationCheckedAt
    creditRiskClass
    businessId
    email
    phone
    invoicingAddress
    invoicingDistrict
    invoicingEmail
    invoicingPostcode
    invoicingEAddress
    invoicingEOperator
    blockedAt
    blockedReason
    preInvoicingAt
    preInvoicingReason
    hasInsurance
    useParentInvoicing
    useParentPricing
    discountGlobalCatalogRow
    discountGlobalCatalogExtra
    paymentTerm
    noBillingSurchargeAt
    userOwner {
      id
      firstName
      lastName
    }
    customerAreas {
      id
    }
    customerRank {
      id
    }
    discountListSelected {
      id
      name
      locked
      isOffer
      isBusiness
      discountrowSet {
        id
        percent
        showPrint
        catalogRow {
          id
          catalogCategory {
            id
            catalogCategoryUpper {
              id
              catalog {
                id
              }
            }
          }
        }
      }
      catalog {
        id
        catalogcategoryupperSet {
          id
          name
          catalogcategorySet {
            id
            name
            catalogrowSet {
              id
              name
              priceDayCompany
              priceMonthCompany
              priceDayPrivate
              priceMonthPrivate
            }
          }
        }
      }
    }
    catalogSelected {
      id
      name
    }
    customerParent {
      id
      name
      contactPersonLastname
      contactPersonFirstname
      discountGlobalCatalogRow
      userOwner {
        id
        lastName
        firstName
      }
      contactNext
      customersChildren {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
      }
      useParentInvoicing
      catalogSelected {
        id
      }
      discountListSelected {
        id
      }
    }
    customersChildren {
      id
      name
      contactPersonLastname
      contactPersonFirstname
      userOwner {
        id
        lastName
        firstName
      }
      contactNext
    }
    orderSet {
      id
      reference {
        id
        name
      }
      status
      reservationSet {
        ...ReservationFieldsForTable
      }
      createdAt
    }
    createdAt
    createdBy {
      id
      username
    }
  }
}
    ${ReservationFieldsForTableFragmentDoc}`;

/**
 * __useGetCustomerForCalendarQuery__
 *
 * To run a query within a React component, call `useGetCustomerForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerForCalendarQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerForCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerForCalendarQuery, GetCustomerForCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerForCalendarQuery, GetCustomerForCalendarQueryVariables>(GetCustomerForCalendarDocument, options);
      }
export function useGetCustomerForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerForCalendarQuery, GetCustomerForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerForCalendarQuery, GetCustomerForCalendarQueryVariables>(GetCustomerForCalendarDocument, options);
        }
export type GetCustomerForCalendarQueryHookResult = ReturnType<typeof useGetCustomerForCalendarQuery>;
export type GetCustomerForCalendarLazyQueryHookResult = ReturnType<typeof useGetCustomerForCalendarLazyQuery>;
export type GetCustomerForCalendarQueryResult = Apollo.QueryResult<GetCustomerForCalendarQuery, GetCustomerForCalendarQueryVariables>;
export const GetCustomerPriceForCatalogRowDocument = gql`
    query getCustomerPriceForCatalogRow($customerId: ID!, $catalogRowId: ID!) {
  customerPriceForCatalogRow(customerId: $customerId, catalogRowId: $catalogRowId) {
    priceDay
    priceMonth
    discountPercent
  }
}
    `;

/**
 * __useGetCustomerPriceForCatalogRowQuery__
 *
 * To run a query within a React component, call `useGetCustomerPriceForCatalogRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerPriceForCatalogRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerPriceForCatalogRowQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      catalogRowId: // value for 'catalogRowId'
 *   },
 * });
 */
export function useGetCustomerPriceForCatalogRowQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerPriceForCatalogRowQuery, GetCustomerPriceForCatalogRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerPriceForCatalogRowQuery, GetCustomerPriceForCatalogRowQueryVariables>(GetCustomerPriceForCatalogRowDocument, options);
      }
export function useGetCustomerPriceForCatalogRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerPriceForCatalogRowQuery, GetCustomerPriceForCatalogRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerPriceForCatalogRowQuery, GetCustomerPriceForCatalogRowQueryVariables>(GetCustomerPriceForCatalogRowDocument, options);
        }
export type GetCustomerPriceForCatalogRowQueryHookResult = ReturnType<typeof useGetCustomerPriceForCatalogRowQuery>;
export type GetCustomerPriceForCatalogRowLazyQueryHookResult = ReturnType<typeof useGetCustomerPriceForCatalogRowLazyQuery>;
export type GetCustomerPriceForCatalogRowQueryResult = Apollo.QueryResult<GetCustomerPriceForCatalogRowQuery, GetCustomerPriceForCatalogRowQueryVariables>;
export const GetCustomersPrimaryDocument = gql`
    query getCustomersPrimary {
  customersPrimary {
    id
    businessId
    name
  }
}
    `;

/**
 * __useGetCustomersPrimaryQuery__
 *
 * To run a query within a React component, call `useGetCustomersPrimaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersPrimaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersPrimaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersPrimaryQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersPrimaryQuery, GetCustomersPrimaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersPrimaryQuery, GetCustomersPrimaryQueryVariables>(GetCustomersPrimaryDocument, options);
      }
export function useGetCustomersPrimaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersPrimaryQuery, GetCustomersPrimaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersPrimaryQuery, GetCustomersPrimaryQueryVariables>(GetCustomersPrimaryDocument, options);
        }
export type GetCustomersPrimaryQueryHookResult = ReturnType<typeof useGetCustomersPrimaryQuery>;
export type GetCustomersPrimaryLazyQueryHookResult = ReturnType<typeof useGetCustomersPrimaryLazyQuery>;
export type GetCustomersPrimaryQueryResult = Apollo.QueryResult<GetCustomersPrimaryQuery, GetCustomersPrimaryQueryVariables>;
export const GetCustomersCalendarDocument = gql`
    query getCustomersCalendar {
  customers {
    id
    name
    contactPersonFirstname
    contactPersonLastname
    blockedAt
    creditInformationOk
    preInvoicingAt
    customerParent {
      id
      blockedAt
      creditInformationOk
      preInvoicingAt
    }
  }
}
    `;

/**
 * __useGetCustomersCalendarQuery__
 *
 * To run a query within a React component, call `useGetCustomersCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersCalendarQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersCalendarQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersCalendarQuery, GetCustomersCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersCalendarQuery, GetCustomersCalendarQueryVariables>(GetCustomersCalendarDocument, options);
      }
export function useGetCustomersCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersCalendarQuery, GetCustomersCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersCalendarQuery, GetCustomersCalendarQueryVariables>(GetCustomersCalendarDocument, options);
        }
export type GetCustomersCalendarQueryHookResult = ReturnType<typeof useGetCustomersCalendarQuery>;
export type GetCustomersCalendarLazyQueryHookResult = ReturnType<typeof useGetCustomersCalendarLazyQuery>;
export type GetCustomersCalendarQueryResult = Apollo.QueryResult<GetCustomersCalendarQuery, GetCustomersCalendarQueryVariables>;
export const GetCustomersOpenReservationsDocument = gql`
    query getCustomersOpenReservations($locationIds: [ID]!, $onlyEndedReservations: Boolean!, $onlyPreinvoicingCustomers: Boolean!, $dateHandled: Date) {
  customersOpenReservations(
    locationIds: $locationIds
    onlyEndedReservations: $onlyEndedReservations
    onlyPreinvoicingCustomers: $onlyPreinvoicingCustomers
    dateHandled: $dateHandled
  ) {
    id
    name
    contactPersonFirstname
    contactPersonLastname
    handledInvoicingAt
  }
}
    `;

/**
 * __useGetCustomersOpenReservationsQuery__
 *
 * To run a query within a React component, call `useGetCustomersOpenReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersOpenReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersOpenReservationsQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      onlyEndedReservations: // value for 'onlyEndedReservations'
 *      onlyPreinvoicingCustomers: // value for 'onlyPreinvoicingCustomers'
 *      dateHandled: // value for 'dateHandled'
 *   },
 * });
 */
export function useGetCustomersOpenReservationsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomersOpenReservationsQuery, GetCustomersOpenReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersOpenReservationsQuery, GetCustomersOpenReservationsQueryVariables>(GetCustomersOpenReservationsDocument, options);
      }
export function useGetCustomersOpenReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersOpenReservationsQuery, GetCustomersOpenReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersOpenReservationsQuery, GetCustomersOpenReservationsQueryVariables>(GetCustomersOpenReservationsDocument, options);
        }
export type GetCustomersOpenReservationsQueryHookResult = ReturnType<typeof useGetCustomersOpenReservationsQuery>;
export type GetCustomersOpenReservationsLazyQueryHookResult = ReturnType<typeof useGetCustomersOpenReservationsLazyQuery>;
export type GetCustomersOpenReservationsQueryResult = Apollo.QueryResult<GetCustomersOpenReservationsQuery, GetCustomersOpenReservationsQueryVariables>;
export const GetCustomerRanksDocument = gql`
    query getCustomerRanks {
  customerRanks {
    id
    title
    order
  }
}
    `;

/**
 * __useGetCustomerRanksQuery__
 *
 * To run a query within a React component, call `useGetCustomerRanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerRanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerRanksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerRanksQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerRanksQuery, GetCustomerRanksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerRanksQuery, GetCustomerRanksQueryVariables>(GetCustomerRanksDocument, options);
      }
export function useGetCustomerRanksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerRanksQuery, GetCustomerRanksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerRanksQuery, GetCustomerRanksQueryVariables>(GetCustomerRanksDocument, options);
        }
export type GetCustomerRanksQueryHookResult = ReturnType<typeof useGetCustomerRanksQuery>;
export type GetCustomerRanksLazyQueryHookResult = ReturnType<typeof useGetCustomerRanksLazyQuery>;
export type GetCustomerRanksQueryResult = Apollo.QueryResult<GetCustomerRanksQuery, GetCustomerRanksQueryVariables>;
export const GetCustomerAreasDocument = gql`
    query getCustomerAreas {
  customerAreas {
    id
    title
  }
}
    `;

/**
 * __useGetCustomerAreasQuery__
 *
 * To run a query within a React component, call `useGetCustomerAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerAreasQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerAreasQuery, GetCustomerAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerAreasQuery, GetCustomerAreasQueryVariables>(GetCustomerAreasDocument, options);
      }
export function useGetCustomerAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerAreasQuery, GetCustomerAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerAreasQuery, GetCustomerAreasQueryVariables>(GetCustomerAreasDocument, options);
        }
export type GetCustomerAreasQueryHookResult = ReturnType<typeof useGetCustomerAreasQuery>;
export type GetCustomerAreasLazyQueryHookResult = ReturnType<typeof useGetCustomerAreasLazyQuery>;
export type GetCustomerAreasQueryResult = Apollo.QueryResult<GetCustomerAreasQuery, GetCustomerAreasQueryVariables>;
export const GetCustomersDocument = gql`
    query getCustomers {
  customers {
    id
    name
    contactPersonFirstname
    contactPersonLastname
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetCustomerIndustriesDocument = gql`
    query getCustomerIndustries {
  customerIndustries {
    id
    title
  }
}
    `;

/**
 * __useGetCustomerIndustriesQuery__
 *
 * To run a query within a React component, call `useGetCustomerIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomerIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerIndustriesQuery, GetCustomerIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerIndustriesQuery, GetCustomerIndustriesQueryVariables>(GetCustomerIndustriesDocument, options);
      }
export function useGetCustomerIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerIndustriesQuery, GetCustomerIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerIndustriesQuery, GetCustomerIndustriesQueryVariables>(GetCustomerIndustriesDocument, options);
        }
export type GetCustomerIndustriesQueryHookResult = ReturnType<typeof useGetCustomerIndustriesQuery>;
export type GetCustomerIndustriesLazyQueryHookResult = ReturnType<typeof useGetCustomerIndustriesLazyQuery>;
export type GetCustomerIndustriesQueryResult = Apollo.QueryResult<GetCustomerIndustriesQuery, GetCustomerIndustriesQueryVariables>;