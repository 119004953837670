import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUpdateReservationStampsArgs,
  ReservationsReservationStatusChoices,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarehouseAlt } from "@fortawesome/pro-light-svg-icons";
import ButtonLoad from "../Shared/ButtonLoad";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_STAMPS_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservationStampsMutation } from "../../utils/cache";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  disabled: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReservationButtonGiveChecked({
  classes,
  reservation,
  disabled,
  setOpen,
}: Props) {
  const { t } = useTranslation();

  const [updateReservationStamps, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationStampsArgs
  >(UPDATE_RESERVATION_STAMPS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOpen(false);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);

  const onClickGiveCheckedAt = () => {
    updateReservationStamps({
      variables: {
        reservationId: reservation.id,
        giveCheckedAt: reservation.giveCheckedAt ? null : new Date(),
      },
    });
  };

  const isDone = Boolean(reservation.giveCheckedAt);

  return (
    <>
      <ButtonLoad
        loading={loadingUpdate}
        key="giveCheckedAt"
        variant={isDone ? "primary" : "danger"}
        onClick={onClickGiveCheckedAt}
        disabled={
          disabled ||
          !hasPermissionEditReservation ||
          (isDone &&
            reservation.status ===
              ReservationsReservationStatusChoices.Invoiced) ||
          reservation.giveImagesAt
        }
      >
        <FontAwesomeIcon icon={faWarehouseAlt} />
        <span className="ms-2">{t("toggle_give_checked_at")}</span>
      </ButtonLoad>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationButtonGiveChecked);
