import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import { Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
  Mutation,
  MutationCreateOperationHourArgs,
  MutationDeleteOperationHourArgs,
  OperationHoursOperationHourSourceChoices,
  QueryInvoicingPriceForReservationArgs,
  QueryOperationHoursForMachineArgs,
} from "../../entity/types";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  GET_OPERATION_HOURS_FOR_MACHINE_QUERY,
  QueryResultOperationHoursForMachine,
} from "../../apollo/queries/operation_hours";
import { OperationHourEmpty } from "../../entity/empties";
import { format } from "date-fns";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../../utils/constants";
import {
  CREATE_OPERATION_HOUR_MUTATION,
  DELETE_OPERATION_HOUR_MUTATION,
} from "../../apollo/mutations/operation_hours";
import { handleError } from "../../entity/ErrorHandler";
import { formatNumber } from "../../utils/formatting";
import { faSave, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dialogConfirm } from "../../utils/dialogs";
import {
  GET_INVOICING_PRICE_FOR_RESERVATION_QUERY,
  MachineFromReservationsWorkQueuesType,
  QueryResultInvoicingPriceForReservation,
  ReservationFromReservationsWorkQueuesType,
} from "../../apollo/queries/reservations";
import { newDate } from "../../utils/dates";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  machine: MachineFromReservationsWorkQueuesType;
  source?: OperationHoursOperationHourSourceChoices;
}

function DialogContentOperationHours({
  classes,
  reservation,
  machine,
  source = OperationHoursOperationHourSourceChoices.SourceNone,
}: Props) {
  const { t } = useTranslation();

  const dateCheckedDefault = format(new Date(), DATE_FORMAT_ISO);
  const [operationHourNew, setOperationHourNew] = useState({
    ...OperationHourEmpty,
    dateChecked: dateCheckedDefault,
  });

  const {
    loading: loadingHours,
    error: errorHours,
    data: dataHours,
  } = useQuery<
    QueryResultOperationHoursForMachine,
    QueryOperationHoursForMachineArgs
  >(GET_OPERATION_HOURS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("operationHoursForMachine"),
    variables: {
      machineId: machine ? machine.id : ID_EMPTY,
      reservationId: reservation.id,
    },
  });

  const {
    loading: loadingPrice,
    error: errorPrice,
    data: dataPrice,
  } = useQuery<
    QueryResultInvoicingPriceForReservation,
    QueryInvoicingPriceForReservationArgs
  >(GET_INVOICING_PRICE_FOR_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("invoicingPriceForReservation"),
    variables: {
      reservationId: reservation.id,
      date: reservation.dateBillingMin
        ? reservation.dateBillingMin
        : reservation.dateReturned,
    },
  });

  const [createOperationHour, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateOperationHourArgs
  >(CREATE_OPERATION_HOUR_MUTATION, {
    refetchQueries: [
      {
        query: GET_OPERATION_HOURS_FOR_MACHINE_QUERY,
        variables: {
          machineId: machine ? machine.id : ID_EMPTY,
          reservationId: reservation.id,
          source: source,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteOperationHour, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteOperationHourArgs
  >(DELETE_OPERATION_HOUR_MUTATION, {
    refetchQueries: [
      {
        query: GET_OPERATION_HOURS_FOR_MACHINE_QUERY,
        variables: {
          machineId: machine ? machine.id : ID_EMPTY,
          reservationId: reservation.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const hoursMin =
    dataHours?.operationHoursForMachine && dataHours.operationHoursForMachine[0]
      ? dataHours.operationHoursForMachine[0].hours
      : 0;
  const checkHourMin = () => {
    if (operationHourNew.hours < hoursMin) {
      setOperationHourNew({
        ...operationHourNew,
        hours: hoursMin,
      });
    }
  };

  useEffect(() => {
    checkHourMin();
    // eslint-disable-next-line
  }, [hoursMin]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "operation_hours.add_operationhour",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "operation_hours.delete_operationhour",
  ]);

  if (errorHours) return <Error error={errorHours} />;
  if (errorPrice) return <Error error={errorPrice} />;
  if ((!loadingHours && !dataHours) || (!loadingPrice && !dataPrice)) {
    return <Error error={t("error_query_failed")} />;
  }

  if (reservation.catalogExtraRowRental) {
    return <Error error={t("no_operation_hours_for_catalog_extra_row")} />;
  }

  const hasHours =
    dataHours &&
    dataHours.operationHoursForMachine &&
    dataHours.operationHoursForMachine.length > 0;

  const canAddHours =
    !hasHours ||
    !dataPrice?.invoicingPriceForReservation?.isDayPrice ||
    reservation.dateReturned <= format(new Date(), DATE_FORMAT_ISO);

  const onClickAdd = () => {
    createOperationHour({
      variables: {
        reservationId: reservation.id,
        machineId: machine ? machine.id : ID_EMPTY,
        maintenanceId: ID_EMPTY,
        dateChecked: operationHourNew.dateChecked,
        hours: operationHourNew.hours,
        source: source,
      },
    }).then(() => {
      setOperationHourNew({
        ...operationHourNew,
        dateChecked: dateCheckedDefault,
        hours: hoursMin,
      });
    });
  };

  const onClickDelete = (operationHourId: string) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteOperationHour({
        variables: {
          operationHourId: operationHourId,
        },
      });
    });
  };

  return (
    <DialogContent
      className={
        loadingHours || loadingPrice || loadingCreate || loadingDelete
          ? "loading"
          : ""
      }
    >
      {hasPermissionAdd && !canAddHours && (
        <p className="text-muted">{t("cannot_add_operation_hours")}</p>
      )}
      {hasPermissionAdd && canAddHours && (
        <div className="mb-4">
          <h5 className="mb-3">{t("add_operation_hour")}</h5>
          <div className="d-flex">
            <FormControl className="me-3" fullWidth>
              <TextField
                type="date"
                label={t("date")}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  setOperationHourNew({
                    ...operationHourNew,
                    dateChecked: event.target.value,
                  });
                }}
                value={operationHourNew.dateChecked}
              />
            </FormControl>
            <FormControl className="me-3" fullWidth>
              <TextFieldFocus
                type="number"
                label={t("operation_hours")}
                inputProps={{ min: hoursMin }}
                onChange={(event) => {
                  setOperationHourNew({
                    ...operationHourNew,
                    hours: Number(event.target.value),
                  });
                }}
                onBlur={() => {
                  checkHourMin();
                }}
                value={operationHourNew.hours}
              />
            </FormControl>
            <Button variant="primary" onClick={onClickAdd}>
              <FontAwesomeIcon icon={faSave} />
            </Button>
          </div>
        </div>
      )}
      <h5>{t("operation_hour_history")}</h5>
      {!hasHours && (
        <p className="text-muted">{t("no_operation_hours_found")}</p>
      )}
      {hasHours && (
        <Table
          className={`${classes.tblOperationHours} w-auto`}
          borderless
          size="sm"
        >
          <tbody>
            {dataHours.operationHoursForMachine?.map((operationHour) => {
              let statusText = "";
              let className = "";
              if (operationHour.isBase) {
                statusText = t("operation_hours_base");
              } else if (operationHour.hoursExtra === 0) {
                statusText = t("operation_hours_no_extra");
              } else if (!operationHour.invoiceable) {
                statusText = t("operation_hours_not_invoiceable");
                className = classes.notInvoiceable;
              } else if (operationHour.invoiceRow) {
                statusText = t("invoiced");
              } else {
                statusText = t("not_invoiced");
              }

              return (
                <tr className={className} key={operationHour.id}>
                  <td className={classes.cell}>
                    {format(
                      newDate(operationHour.dateChecked),
                      t("format_date")
                    )}
                  </td>
                  <td className={classes.cell}>
                    {t("x_hours", {
                      hours: formatNumber(operationHour.hours),
                    })}
                  </td>
                  <td className={classes.cell}>
                    {t("x_hours_extra", {
                      hours: formatNumber(operationHour.hoursExtra),
                    })}
                  </td>
                  <td className={classes.cell}>{statusText}</td>
                  <td>
                    {hasPermissionDelete && !operationHour.invoiceRow && (
                      <Button
                        size="sm"
                        variant="light"
                        onClick={() => {
                          onClickDelete(operationHour.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </DialogContent>
  );
}

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    tblOperationHours: {
      [breakpoints.down("sm")]: {
        width: "100% !important",

        "& tbody tr": {
          display: "inline-block",
          borderBottom: "1px solid #EEEEEE",

          "& td": {
            display: "inline-block",
          },
        },
      },
    },
    notInvoiceable: {
      textDecoration: "line-through !important",
    },
    cell: {
      paddingRight: "2rem !important",
    },
  });

export default withStyles(styles)(DialogContentOperationHours);
