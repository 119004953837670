import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultUsers {
  users: Query["users"];
}
export const GET_USERS_QUERY = gql`
  query getUsersQuery {
    users {
      id
      firstName
      lastName
      email
      username
      groups {
        id
        name
      }
      UserInfo {
        phone
        address
        postcode
        district
        language
        location {
          id
        }
      }
    }
  }
`;

export interface QueryResultUsersSimple {
  users: Query["users"];
}
export const GET_USERS_SIMPLE_QUERY = gql`
  query getUsersSimpleQuery {
    users {
      id
      firstName
      lastName
    }
  }
`;
