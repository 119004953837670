import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionsInspectionGiveQuestionAnswerTypeChoices,
  InspectionGiveQuestionType,
  Mutation,
  MutationCreateInspectionGiveQuestionOptionArgs,
} from "../../entity/types";
import InspectionGiveQuestionOption from "./InspectionGiveQuestionOption";
import { Button } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_INSPECTION_GIVE_QUESTION_OPTION_MUTATION } from "../../apollo/mutations/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { GET_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WithStyles } from "@material-ui/core/styles";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveQuestion: InspectionGiveQuestionType;
}

function InspectionGiveQuestionOptions({
  classes,
  inspectionGiveQuestion,
}: Props) {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);

  const [createInspectionGiveQuestionOption, { loading: loadingCreateOption }] =
    useMutation<Mutation, MutationCreateInspectionGiveQuestionOptionArgs>(
      CREATE_INSPECTION_GIVE_QUESTION_OPTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  if (
    inspectionGiveQuestion.answerType !==
    InspectionsInspectionGiveQuestionAnswerTypeChoices.Options
  ) {
    return <></>;
  }

  const onClickOptionAdd = () => {
    createInspectionGiveQuestionOption({
      variables: {
        inspectionGiveQuestionId: inspectionGiveQuestion.id,
        title: "",
      },
    });
  };

  return (
    <>
      <tr>
        <td colSpan={5}>
          <Button
            variant="light"
            className="ms-3"
            size="sm"
            onClick={() => setShowOptions(!showOptions)}
          >
            <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} />
          </Button>
          <span className="text-muted ms-3">
            {t("options")}{" "}
            <span className="ms-1">
              ({inspectionGiveQuestion.inspectiongivequestionoptionSet.length})
            </span>
          </span>
        </td>
      </tr>
      {inspectionGiveQuestion.inspectiongivequestionoptionSet.map(
        (inspectionGiveQuestionOption) => (
          <InspectionGiveQuestionOption
            className={showOptions ? "" : "d-none"}
            key={inspectionGiveQuestionOption.id}
            inspectionGiveQuestionOption={inspectionGiveQuestionOption}
          />
        )
      )}
      <tr className={showOptions ? "" : "d-none"}>
        <td colSpan={5}>
          <ButtonLoad
            loading={loadingCreateOption}
            onClick={onClickOptionAdd}
            className="btnRoundSmall ms-5"
            variant="primary"
          >
            <AddIcon />
          </ButtonLoad>
        </td>
      </tr>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionGiveQuestionOptions);
