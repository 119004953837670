import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogExtraCategoryUpperRentalType,
  Mutation,
  MutationUpdateCatalogExtraCategoryUpperRentalOrderArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_ORDER_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { getCatalogExtraSortStyles } from "../../utils/catalog_extra/catalog_extra";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { IdToIndexType } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
}

function CatalogExtraRentalSortCategoriesUpper({
  classes,
  catalogExtraCategoriesUpperRental,
}: Props) {
  const { t } = useTranslation();

  const [updateCatalogExtraCategoryUpperRentalOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraCategoryUpperRentalOrderArgs
  >(UPDATE_CATALOG_EXTRA_CATEGORY_UPPER_RENTAL_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
      });
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let idToIndex: IdToIndexType[] = [];
    arrayMove(catalogExtraCategoriesUpperRental, oldIndex, newIndex).map(
      (
        catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType,
        index
      ) => {
        idToIndex.push({
          id: catalogExtraCategoryUpperRental.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogExtraCategoryUpperRentalOrder({
      variables: { idOrderInput: idToIndex },
    });
  };

  const DragHandleCategoryUpper = SortableHandle(() => (
    <span className={classes.elSort}>
      <FontAwesomeIcon icon={faArrowsV} size="lg" />
    </span>
  ));

  const CategoryUpperContainer = SortableElement(
    ({ catalogExtraCategoryUpperRental }: any) => (
      <div key={catalogExtraCategoryUpperRental.id} className="mb-3">
        <DragHandleCategoryUpper />
        {catalogExtraCategoryUpperRental.name}
      </div>
    )
  );

  const CategoriesUpperContainer = SortableContainer(({ items }: any) => {
    return (
      <div className={loading ? "loading" : ""}>
        {items.map(
          (
            catalogExtraCategoryUpperRental: CatalogExtraCategoryUpperRentalType,
            index: number
          ) => (
            <CategoryUpperContainer
              key={catalogExtraCategoryUpperRental.id}
              index={index}
              catalogExtraCategoryUpperRental={catalogExtraCategoryUpperRental}
            />
          )
        )}
      </div>
    );
  });

  return (
    <div>
      <h5 className="mb-4">{t("sort_catalog_extra_categories_upper")}</h5>
      <CategoriesUpperContainer
        items={catalogExtraCategoriesUpperRental}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    elSort: getCatalogExtraSortStyles(theme),
  });

export default withStyles(styles)(CatalogExtraRentalSortCategoriesUpper);
