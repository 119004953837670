import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineInfoCategoryType,
  Mutation,
  MutationCreateMachineInfoCategoryArgs,
  MutationUpdateMachineInfoCategoryArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import { MachineInfoCategoryEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentMachineInfoCategory from "./DialogContentMachineInfoCategory";
import {
  CREATE_MACHINE_INFO_CATEGORY_MUTATION,
  UPDATE_MACHINE_INFO_CATEGORY_MUTATION,
} from "../../apollo/mutations/machines";
import { GET_MACHINE_INFO_CATEGORIES_QUERY } from "../../apollo/queries/machines";
import { ID_EMPTY } from "../../utils/constants";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machineInfoCategory: MachineInfoCategoryType;
}

function DialogMachineInfoCategory({
  classes,
  open,
  setOpen,
  machineInfoCategory,
}: Props) {
  const { t } = useTranslation();
  const [machineInfoCategoryEdited, setMachineInfoCategoryEdited] =
    useState<MachineInfoCategoryType>(machineInfoCategory);

  const [createMachineInfoCategory, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateMachineInfoCategoryArgs
  >(CREATE_MACHINE_INFO_CATEGORY_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onCompleted: (result) => {
      setMachineInfoCategoryEdited(MachineInfoCategoryEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateMachineInfoCategory, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineInfoCategoryArgs
  >(UPDATE_MACHINE_INFO_CATEGORY_MUTATION, {
    refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (machineInfoCategoryEdited.id !== ID_EMPTY) {
      updateMachineInfoCategory({
        variables: {
          machineInfoCategoryId: machineInfoCategoryEdited.id,
          name: machineInfoCategoryEdited.name,
        },
      });
    } else {
      createMachineInfoCategory({
        variables: {
          name: machineInfoCategoryEdited.name,
        },
      });
    }

    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form className={classes.form} onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>
          {machineInfoCategoryEdited.id !== ID_EMPTY
            ? t("update_machine_info_category")
            : t("new_machine_info_category")}
        </DialogTitle>
        <DialogContentMachineInfoCategory
          machineInfoCategory={machineInfoCategoryEdited}
          setMachineInfoCategory={setMachineInfoCategoryEdited}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingCreate || loadingUpdate}
            disabled={!machineInfoCategoryEdited.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogMachineInfoCategory);
