import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { Table } from "react-bootstrap";
import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import {
  PROFILE_PERMISSION_ADD_MUTATION,
  PROFILE_PERMISSION_DELETE_MUTATION,
} from "../../apollo/mutations/profiles";
import { handleError } from "../../entity/ErrorHandler";
import { GET_PROFILES_QUERY } from "../../apollo/queries/profiles";
import { checkPermission } from "../../utils/permissions";
import { PermissionsContext } from "../../Root";
import { GroupType, PermissionType } from "../../entity/types";
import ProfileMenu from "./ProfileMenu";

interface Props extends WithStyles<typeof styles> {
  groups: GroupType[];
  permissions: PermissionType[];
}

function ProfileList({ classes, groups, permissions }: Props) {
  const { t } = useTranslation();

  type LoadingType = {
    group_id: string;
    permission_id: string;
  };

  const [loading, setLoading] = useState<LoadingType[]>([]);

  const permissionsIgnored = [
    "delete_logentry",
    "view_logentry",
    "change_logentry",
    "add_logentry",
    "delete_log",
    "change_log",
    "add_log",
    "add_contenttype",
    "change_contenttype",
    "delete_contenttype",
    "view_contenttype",
    "add_session",
    "change_session",
    "delete_session",
    "view_session",
    "add_permission",
    "delete_permission",
    "add_userinfo",
    "change_userinfo",
    "delete_userinfo",
    "view_userinfo",
    "add_settings",
    "delete_settings",
    "add_eventoffercatalog",
    "add_eventofferdiscountlist",
    "change_eventoffercatalog",
    "change_eventofferdiscountlist",
    "delete_eventoffercatalog",
    "delete_eventofferdiscountlist",
    "view_eventoffercatalog",
    "view_eventofferdiscountlist",
    "add_invoicelog",
    "change_invoicelog",
    "delete_invoicelog",
    "view_invoicelog",
    "add_maintenanceupcomingfetch",
    "change_maintenanceupcomingfetch",
    "delete_maintenanceupcomingfetch",
    "add_reportprice",
    "add_reportpricedayskipped",
    "add_reportpricemonth",
    "change_reportprice",
    "change_reportpricedayskipped",
    "change_reportpricemonth",
    "delete_reportprice",
    "delete_reportpricedayskipped",
    "delete_reportpricemonth",
  ];

  type PermissionTranslatedType = PermissionType & {
    nameTranslated: string;
  };

  let permissionTranslated: PermissionTranslatedType[] = [];
  permissions.forEach(function (permission) {
    if (permissionsIgnored.indexOf(permission.codename) < 0) {
      permissionTranslated.push({
        ...permission,
        nameTranslated: t("perm." + permission.codename),
      });
    }
  });

  permissionTranslated.sort(function (a, b) {
    let nameA = a.nameTranslated.toLowerCase(),
      nameB = b.nameTranslated.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  let groups_permissions: { [key: string]: string[] } = {};
  groups.forEach(function (group: GroupType) {
    groups_permissions[group.id] = [];
    group.permissions.forEach(function (permission) {
      groups_permissions[group.id].push(permission.id);
    });
  });

  const [deleteProfilePermission] = useMutation(
    PROFILE_PERMISSION_DELETE_MUTATION,
    {
      refetchQueries: [{ query: GET_PROFILES_QUERY }],
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const [addProfilePermission] = useMutation(PROFILE_PERMISSION_ADD_MUTATION, {
    refetchQueries: [{ query: GET_PROFILES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const loadingFindIndex = (group_id: string, permission_id: string) => {
    let index = -1;
    let i = 0;
    loading.forEach((obj) => {
      if (obj.group_id === group_id && obj.permission_id === permission_id) {
        index = i;
      }
      i++;
    });
    return index;
  };

  const handlePermissionClick = (
    event: React.MouseEvent<HTMLTableDataCellElement>,
    group_id: string,
    permission_id: string,
    wasChecked: boolean
  ) => {
    loading.push({
      group_id: group_id,
      permission_id: permission_id,
    });
    setLoading(loading);

    if (wasChecked) {
      deleteProfilePermission({
        variables: { groupId: group_id, permissionId: permission_id },
      }).then(() => {
        let index = loadingFindIndex(group_id, permission_id);
        loading.splice(index, 1);
      });
    } else {
      addProfilePermission({
        variables: { groupId: group_id, permissionId: permission_id },
      }).then(() => {
        let index = loadingFindIndex(group_id, permission_id);
        loading.splice(index, 1);
      });
    }
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "auth.change_permission",
  ]);

  return (
    <Table className={classes.table} hover>
      <thead>
        <tr>
          <th></th>
          {groups.map((group: GroupType) => (
            <th key={group.id} className={classes.th}>
              <ProfileMenu group={group} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {permissionTranslated.map((permission) => (
          <tr key={permission.id}>
            <td>{permission.nameTranslated}</td>
            {groups.map((group: GroupType) => {
              const isSelected =
                groups_permissions[group.id].indexOf(permission.id) >= 0;
              const isLoading = loadingFindIndex(group.id, permission.id) >= 0;

              if (hasPermissionEdit) {
                return (
                  <td
                    className={`${classes.tdPermission} ${
                      classes.tdPermissionEdit
                    } ${isLoading ? "loading" : ""}`}
                    key={group.id}
                    title={group.name}
                    onClick={(event) =>
                      handlePermissionClick(
                        event,
                        group.id,
                        permission.id,
                        isSelected
                      )
                    }
                  >
                    {isSelected && !isLoading && (
                      <FontAwesomeIcon size="lg" icon={faCheck} />
                    )}
                  </td>
                );
              } else {
                return (
                  <td className={classes.tdPermission} key={group.id}>
                    {isSelected && <FontAwesomeIcon size="lg" icon={faCheck} />}
                  </td>
                );
              }
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    table: {
      margin: "auto",
      "& thead th": {
        border: "none",
      },
      "& td, th": {
        borderLeft: "none",
        borderRight: "none",
      },
      "& td:hover": {
        backgroundColor: "#D8F6D5",
      },
    },
    th: {
      textAlign: "center",
    },
    tdPermission: {
      textAlign: "center",
      color: theme.palette.grey["600"],
    },
    tdPermissionEdit: {
      cursor: "pointer",
      "&:hover": {
        color: "#000000",
      },
    },
  });

export default withStyles(styles)(ProfileList);
