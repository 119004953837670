import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogCatalogCategoryUpper from "./DialogCatalogCategoryUpper";
import { CatalogType } from "../../entity/types";
import DialogCatalogCategory from "./DialogCatalogCategory";
import DialogCatalogRow from "./DialogCatalogRow";
import {
  CatalogCategoryEmpty,
  CatalogCategoryUpperEmpty,
  CatalogRowEmpty,
} from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function CreateCatalogCategoriesMenu({ classes, catalog }: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialogCatalogCategoryUpper, setOpenDialogCatalogCategoryUpper] =
    useState(false);
  const [openDialogCatalogCategory, setOpenDialogCatalogCategory] =
    useState(false);
  const [openDialogCatalogRow, setOpenDialogCatalogRow] = useState(false);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddCatalogCategoryUpper = checkPermission(myPermissions, [
    "catalogs.add_catalogcategoryupper",
  ]);
  const hasPermissionAddCatalogCategory = checkPermission(myPermissions, [
    "catalogs.add_catalogcategory",
  ]);
  const hasPermissionAddCatalogRow = checkPermission(myPermissions, [
    "catalogs.add_catalogrow",
  ]);

  if (
    !hasPermissionAddCatalogCategoryUpper &&
    !hasPermissionAddCatalogCategory &&
    !hasPermissionAddCatalogRow
  ) {
    return <></>;
  }

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        className="addButton"
        variant="primary"
        size="lg"
        aria-controls="menuCatalog"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {Boolean(anchorEl) ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Menu
        id="menuCatalog"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {hasPermissionAddCatalogCategoryUpper && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogCategoryUpper(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_category_upper")}
          </MenuItem>
        )}
        {hasPermissionAddCatalogCategory && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogCategory(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_category")}
          </MenuItem>
        )}
        {hasPermissionAddCatalogRow && (
          <MenuItem
            onClick={() => {
              setOpenDialogCatalogRow(true);
              handleCloseMenu();
            }}
          >
            {t("new_catalog_row")}
          </MenuItem>
        )}
      </Menu>
      <DialogCatalogCategoryUpper
        open={openDialogCatalogCategoryUpper}
        setOpen={setOpenDialogCatalogCategoryUpper}
        catalog={catalog}
        catalogCategoryUpperOrig={CatalogCategoryUpperEmpty}
      />
      <DialogCatalogCategory
        open={openDialogCatalogCategory}
        setOpen={setOpenDialogCatalogCategory}
        catalogCategoryOrig={CatalogCategoryEmpty}
        catalogCategoriesUpper={catalog.catalogcategoryupperSet}
      />
      <DialogCatalogRow
        open={openDialogCatalogRow}
        setOpen={setOpenDialogCatalogRow}
        catalogCategoriesUpper={catalog.catalogcategoryupperSet}
        catalogRowOrig={CatalogRowEmpty}
        catalog={catalog}
      />
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCatalogCategoriesMenu);
