import React, { forwardRef } from "react";

import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  Search,
} from "@material-ui/icons";
import { TFunction } from "react-i18next";
import { Options } from "material-table";
import {
  defaultSortDirection as defaultSortDirectionCustomer,
  defaultSortField as defaultSortFieldCustomer,
} from "../components/Customer/CustomerList";
import {
  defaultSortDirection as defaultSortDirectionMachine,
  defaultSortField as defaultSortFieldMachine,
} from "../components/Machine/MaterialTableMachine";
import {
  defaultSortDirection as defaultSortDirectionReservation,
  defaultSortField as defaultSortFieldReservation,
} from "../components/Reservation/MaterialTableReservations";
import {
  defaultSortDirection as defaultSortDirectionEvent,
  defaultSortField as defaultSortFieldEvent,
} from "../components/Customer/CustomerShowEventsTable";

export const tableIcons: any = {
  Add: forwardRef((props: any, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props: any, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: any, ref) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props: any, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props: any, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: any, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props: any, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props: any, ref) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props: any, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props: any, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props: any, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props: any, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props: any, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: any, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props: any, ref) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props: any, ref) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

export const GetTableLocalization = (t: TFunction<"translation">) => ({
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} / {count}",
    labelRowsSelect: t("x_rows"),
    labelRowsPerPage: t("rows_per_page"),
    firstAriaLabel: t("first_page"),
    firstTooltip: t("first_page"),
    previousAriaLabel: t("prev_page"),
    previousTooltip: t("prev_page"),
    nextAriaLabel: t("next_page"),
    nextTooltip: t("next_page"),
    lastAriaLabel: t("last_page"),
    lastTooltip: t("last_page"),
  },
  toolbar: {
    searchPlaceholder: t("search"),
    searchTooltip: t("search"),
    nRowsSelected: t("x_rows_selected"),
  },
  body: {
    emptyDataSourceMessage: t("no_records"),
  },
});

export const getTableStyle = () => {
  return {
    boxShadow: "none",
    padding: "0.25vw 0.8vw",
  };
};

export const getTableOptions = (
  optionsOverrides: Partial<Options<any>> = {}
): Partial<Options<any>> => {
  let optionsDefault: Partial<Options<any>> = {
    headerStyle: { fontWeight: "bold", fontSize: "1rem" },
    actionsColumnIndex: -1,
    paging: false,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50],
    rowStyle: { fontSize: "1rem" },
    tableLayout: "auto",
  };

  return { ...optionsDefault, ...optionsOverrides };
};

export type SortingDirectionType = "asc" | "desc" | undefined;

export type SortingType = {
  field: string;
  orderDirection: SortingDirectionType;
};

const getDefaultSort = (
  defaultSortField: string,
  defaultSortDirection: SortingDirectionType,
  storageKeyField: string,
  storageKeyDirection: string
): SortingType => {
  const fieldStored = localStorage.getItem(storageKeyField);
  const field = fieldStored === null ? defaultSortField : fieldStored;

  const directionStored = localStorage.getItem(storageKeyDirection);
  const direction =
    directionStored === null
      ? defaultSortDirection
      : ((directionStored === ""
          ? undefined
          : directionStored) as SortingDirectionType);

  return {
    field: field,
    orderDirection: direction,
  };
};

export const getDefaultSortCustomers = (): SortingType =>
  getDefaultSort(
    defaultSortFieldCustomer,
    defaultSortDirectionCustomer,
    "DefaultSortCustomerField",
    "DefaultSortCustomerDirection"
  );

export const getDefaultSortMachines = (): SortingType =>
  getDefaultSort(
    defaultSortFieldMachine,
    defaultSortDirectionMachine,
    "DefaultSortMachineField",
    "DefaultSortMachineDirection"
  );

export const getDefaultSortReservations = (): SortingType =>
  getDefaultSort(
    defaultSortFieldReservation,
    defaultSortDirectionReservation,
    "DefaultSortReservationField",
    "DefaultSortReservationDirection"
  );

export const getDefaultSortEvents = (): SortingType =>
  getDefaultSort(
    defaultSortFieldEvent,
    defaultSortDirectionEvent,
    "DefaultSortEventField",
    "DefaultSortEventDirection"
  );

export const setDefaultSortCustomers = (
  field: string,
  orderDirection: SortingDirectionType
) => {
  localStorage.setItem("DefaultSortCustomerField", field);
  localStorage.setItem(
    "DefaultSortCustomerDirection",
    orderDirection === undefined ? "" : orderDirection
  );
};

export const setDefaultSortMachines = (
  field: string,
  orderDirection: SortingDirectionType
) => {
  localStorage.setItem("DefaultSortMachineField", field);
  localStorage.setItem(
    "DefaultSortMachineDirection",
    orderDirection === undefined ? "" : orderDirection
  );
};

export const setDefaultSortReservations = (
  field: string,
  orderDirection: SortingDirectionType
) => {
  localStorage.setItem("DefaultSortReservationField", field);
  localStorage.setItem(
    "DefaultSortReservationDirection",
    orderDirection === undefined ? "" : orderDirection
  );
};

export const setDefaultSortEvents = (
  field: string,
  orderDirection: SortingDirectionType
) => {
  localStorage.setItem("DefaultSortEventField", field);
  localStorage.setItem(
    "DefaultSortEventDirection",
    orderDirection === undefined ? "" : orderDirection
  );
};
