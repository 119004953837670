import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { LocationType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  location: LocationType;
  setLocation: React.Dispatch<React.SetStateAction<LocationType>>;
}

function DialogContentLocation({ classes, location, setLocation }: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "locations.add_location",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "locations.change_location",
  ]);
  const disabled =
    location.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setLocation({
              ...location,
              name: event.target.value,
            });
          }}
          value={location.name}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("dim_identifier")}
          onChange={(event) => {
            setLocation({
              ...location,
              dimIdentifier: event.target.value,
            });
          }}
          value={location.dimIdentifier}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentLocation);
