import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineType,
  QueryMachineBreakdownsForMachineArgs,
  QueryMaintenanceHistoryForMachineArgs,
} from "../../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_MACHINE_BREAKDOWNS_FOR_MACHINE_QUERY,
  GET_MAINTENANCE_HISTORY_FOR_MACHINE,
  QueryResultMachineBreakdownsForMachine,
  QueryResultMaintenanceHistoryForMachine,
} from "../../../apollo/queries/machines";
import { NextMaintenanceTypeEmpty } from "../../../entity/empties";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import { TabPanel } from "@material-ui/lab";
import MaintenanceHistoryMachine from "../../Maintenance/MaintenanceHistoryMachine";
import LoadingSimple from "../../Shared/LoadingSimple";
import Error from "../../Shared/Error";
import { ProductCardTabs } from "./DialogMachineProductCard";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCardMaintenance({ classes, machine }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingBreakdowns,
    error: errorBreakdowns,
    data: dataBreakdowns,
  } = useQuery<
    QueryResultMachineBreakdownsForMachine,
    QueryMachineBreakdownsForMachineArgs
  >(GET_MACHINE_MACHINE_BREAKDOWNS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machineBreakdownsForMachine"),
    variables: {
      machineId: machine.id,
    },
  });

  const {
    loading: loadingMaintenance,
    error: errorMaintenance,
    data: dataMaintenance,
  } = useQuery<
    QueryResultMaintenanceHistoryForMachine,
    QueryMaintenanceHistoryForMachineArgs
  >(GET_MAINTENANCE_HISTORY_FOR_MACHINE, {
    fetchPolicy: getQueryFetchPolicy("machine"),
    variables: {
      machineId: machine.id,
    },
  });

  const myPermissions = useContext(PermissionsContext);

  const hasPermissionViewServiceMaintenance = checkPermission(myPermissions, [
    "maintenance.view_maintenance",
  ]);

  if (loadingBreakdowns || loadingMaintenance) return <LoadingSimple />;
  if (errorBreakdowns) <Error error={errorBreakdowns} />;
  if (errorMaintenance) <Error error={errorMaintenance} />;
  if (!dataBreakdowns || !dataMaintenance) {
    return <Error error={t("error_query_failed")} />;
  }

  if (!hasPermissionViewServiceMaintenance) {
    return null;
  }

  return (
    <TabPanel value={ProductCardTabs.Maintenance}>
      {(dataMaintenance.maintenanceHistoryForMachine ||
        dataBreakdowns.machineBreakdownsForMachine) && (
        <>
          {(dataMaintenance.maintenanceHistoryForMachine ||
            dataBreakdowns.machineBreakdownsForMachine) && (
            <MaintenanceHistoryMachine
              machine={machine}
              maintenances={
                dataMaintenance.maintenanceHistoryForMachine
                  ? dataMaintenance.maintenanceHistoryForMachine
                  : []
              }
              nextMaintenance={
                dataMaintenance.maintenanceNextForMachine
                  ? dataMaintenance.maintenanceNextForMachine
                  : NextMaintenanceTypeEmpty
              }
              breakdowns={dataBreakdowns.machineBreakdownsForMachine}
            />
          )}
        </>
      )}
    </TabPanel>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TabMachineCardMaintenance);
