import { gql } from "@apollo/client";

export const CREATE_USER_MUTATION = gql`
  mutation createUser(
    $firstName: String!
    $lastName: String!
    $username: String!
    $email: String!
    $password: String!
    $phone: String!
    $address: String!
    $postcode: String!
    $district: String!
    $language: String!
    $groupId: ID!
    $locationId: ID
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      username: $username
      email: $email
      password: $password
      phone: $phone
      address: $address
      postcode: $postcode
      district: $district
      language: $language
      groupId: $groupId
      locationId: $locationId
    ) {
      user {
        id
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $userId: ID!
    $firstName: String!
    $lastName: String!
    $username: String!
    $email: String!
    $password: String!
    $phone: String!
    $address: String!
    $postcode: String!
    $district: String!
    $language: String!
    $groupId: ID!
    $locationId: ID
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      username: $username
      email: $email
      password: $password
      phone: $phone
      address: $address
      postcode: $postcode
      district: $district
      language: $language
      groupId: $groupId
      locationId: $locationId
    ) {
      user {
        id
        firstName
        lastName
        username
        email
        password
        groups {
          id
        }
        UserInfo {
          phone
          address
          postcode
          district
          language
          location {
            id
          }
        }
      }
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      userId
    }
  }
`;
