import * as Types from '../../entity/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReservationFieldsFullFragment = (
  { __typename?: 'ReservationType' }
  & Pick<Types.ReservationType, 'id' | 'dateRented' | 'timeRented' | 'dateReturned' | 'timeReturned' | 'dateBillingMin' | 'information' | 'deliveryMethod' | 'status' | 'bulkAmount' | 'bulkAmountReturned' | 'discountPercentDay' | 'discountPercentMonth' | 'estimatedMachineHoursPerDay' | 'giveCheckedAt' | 'giveImagesAt' | 'giveInstructedAt' | 'giveAt' | 'returnAt' | 'returnImagesAt' | 'returnCheckedCatalogAt' | 'returnCheckedPreAt' | 'returnCheckedAt' | 'returnStoredAt' | 'billingDaysWeekCompany' | 'billingDaysWeekPrivate' | 'hourLimitDayCompany' | 'hourLimitDayPrivate' | 'hourLimitMonthCompany' | 'hourLimitMonthPrivate' | 'createdAt'>
  & { order: (
    { __typename?: 'OrderType' }
    & Pick<Types.OrderType, 'id'>
  ), machine?: Types.Maybe<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id'>
  )>, catalogRow?: Types.Maybe<(
    { __typename?: 'CatalogRowType' }
    & Pick<Types.CatalogRowType, 'id'>
  )>, catalogExtraRowRental?: Types.Maybe<(
    { __typename?: 'CatalogExtraRowRentalType' }
    & Pick<Types.CatalogExtraRowRentalType, 'id'>
  )>, reservationSource?: Types.Maybe<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id'>
  )>, albumGive: (
    { __typename?: 'ReservationAlbumType' }
    & Pick<Types.ReservationAlbumType, 'id'>
  ), albumReturn: (
    { __typename?: 'ReservationAlbumType' }
    & Pick<Types.ReservationAlbumType, 'id'>
  ), userGive?: Types.Maybe<(
    { __typename?: 'UserType' }
    & Pick<Types.UserType, 'id'>
  )>, userReturn?: Types.Maybe<(
    { __typename?: 'UserType' }
    & Pick<Types.UserType, 'id'>
  )>, reservationcommentSet: Array<(
    { __typename?: 'ReservationCommentType' }
    & Pick<Types.ReservationCommentType, 'id'>
  )>, invoicerowSet: Array<(
    { __typename?: 'InvoiceRowType' }
    & Pick<Types.InvoiceRowType, 'id'>
  )>, catalogextrarowinvoiceSet: Array<(
    { __typename?: 'CatalogExtraRowInvoiceType' }
    & Pick<Types.CatalogExtraRowInvoiceType, 'id'>
  )>, reservationsSourced: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id'>
  )>, operationhourSet: Array<(
    { __typename?: 'OperationHourType' }
    & Pick<Types.OperationHourType, 'id'>
  )>, transportSet: Array<(
    { __typename?: 'TransportType' }
    & Pick<Types.TransportType, 'id'>
  )>, drivecontentSet: Array<(
    { __typename?: 'DriveContentType' }
    & Pick<Types.DriveContentType, 'id'>
  )>, machinebulkbreakdownSet: Array<(
    { __typename?: 'MachineBulkBreakdownType' }
    & Pick<Types.MachineBulkBreakdownType, 'id'>
  )>, inspectionreturnanswerSet: Array<(
    { __typename?: 'InspectionReturnAnswerType' }
    & Pick<Types.InspectionReturnAnswerType, 'id'>
  )>, inspectiongiveanswerSet: Array<(
    { __typename?: 'InspectionGiveAnswerType' }
    & Pick<Types.InspectionGiveAnswerType, 'id'>
  )>, reservationimagebasicSet: Array<(
    { __typename?: 'ReservationImageBasicType' }
    & Pick<Types.ReservationImageBasicType, 'id'>
  )>, commissionreportrowSet: Array<(
    { __typename?: 'CommissionReportRowType' }
    & Pick<Types.CommissionReportRowType, 'id'>
  )>, createdBy?: Types.Maybe<(
    { __typename?: 'UserType' }
    & Pick<Types.UserType, 'id'>
  )> }
);

export type ReservationFieldsFragment = (
  { __typename?: 'ReservationType' }
  & Pick<Types.ReservationType, 'id' | 'status' | 'dateRented' | 'timeRented' | 'dateReturned' | 'timeReturned' | 'dateBillingMin' | 'bulkAmount' | 'bulkAmountReturned' | 'discountPercentDay' | 'discountPercentMonth' | 'estimatedMachineHoursPerDay'>
);

export type ReservationFieldsForTableFragment = (
  { __typename?: 'ReservationType' }
  & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned' | 'giveAt' | 'returnAt' | 'createdAt'>
  & { machine?: Types.Maybe<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'information' | 'identifier'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ) }
  )>, catalogRow?: Types.Maybe<(
    { __typename?: 'CatalogRowType' }
    & Pick<Types.CatalogRowType, 'id' | 'name'>
  )>, catalogExtraRowRental?: Types.Maybe<(
    { __typename?: 'CatalogExtraRowRentalType' }
    & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
    & { catalogExtraCategoryRental: (
      { __typename?: 'CatalogExtraCategoryRentalType' }
      & Pick<Types.CatalogExtraCategoryRentalType, 'id' | 'name'>
    ) }
  )> }
);

export type GetReservationForAddingImagesQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetReservationForAddingImagesQuery = (
  { __typename?: 'Query' }
  & { reservation: (
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'giveCheckedAt' | 'giveImagesAt' | 'giveInstructedAt' | 'giveAt' | 'returnAt' | 'returnImagesAt' | 'returnCheckedCatalogAt' | 'returnCheckedPreAt' | 'returnCheckedAt' | 'returnStoredAt' | 'status'>
    & { userGive?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id'>
    )>, userReturn?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id'>
    )>, albumGive: (
      { __typename?: 'ReservationAlbumType' }
      & Pick<Types.ReservationAlbumType, 'id'>
    ), albumReturn: (
      { __typename?: 'ReservationAlbumType' }
      & Pick<Types.ReservationAlbumType, 'id'>
    ) }
  ) }
);

export type GetReservationsMachinesQueryVariables = Types.Exact<{
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
  includeNotReturned: Types.Scalars['Boolean'];
}>;


export type GetReservationsMachinesQuery = (
  { __typename?: 'Query' }
  & { reservations: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id'>
    )> }
  )> }
);

export type GetReservationsQueryVariables = Types.Exact<{
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
  includeNotReturned: Types.Scalars['Boolean'];
  catalogRowId?: Types.InputMaybe<Types.Scalars['ID']>;
  catalogExtraRowRentalId?: Types.InputMaybe<Types.Scalars['ID']>;
  machineId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetReservationsQuery = (
  { __typename?: 'Query' }
  & { reservations: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned'>
  )> }
);

export type GetReservationsForMachineQueryQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type GetReservationsForMachineQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsForMachine: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned' | 'giveAt' | 'returnAt'>
    & { order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
      & { customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GetReservationsForOrderQueryQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID'];
}>;


export type GetReservationsForOrderQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsForOrder: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'bulkAmount' | 'bulkAmountReturned' | 'giveAt' | 'returnAt'>
    & { catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id'>
      ) }
    )>, machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'bulkProduct' | 'identifier'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ) }
    )>, order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
    ) }
  )> }
);

export type GetReservationsWorkQueuesQueryQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  userId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetReservationsWorkQueuesQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsWorkQueues: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'status' | 'dateRented' | 'timeRented' | 'dateReturned' | 'timeReturned' | 'dateBillingMin' | 'bulkAmount' | 'bulkAmountReturned' | 'information' | 'giveCheckedAt' | 'giveImagesAt' | 'giveInstructedAt' | 'giveAt' | 'returnAt' | 'returnImagesAt' | 'returnCheckedCatalogAt' | 'returnCheckedPreAt' | 'returnCheckedAt' | 'returnStoredAt'>
    & { userGive?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, userReturn?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id'>
      ) }
    )>, reservationcommentSet: Array<(
      { __typename?: 'ReservationCommentType' }
      & Pick<Types.ReservationCommentType, 'id'>
    )>, machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'hourTracking' | 'bulkProduct' | 'identifier'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ), location?: Types.Maybe<(
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id' | 'name'>
      )> }
    )>, order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
      & { customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name'>
      )>, location: (
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id' | 'name'>
      ) }
    ) }
  )> }
);

export type GetReservationCommentsQueryVariables = Types.Exact<{
  reservationId: Types.Scalars['ID'];
}>;


export type GetReservationCommentsQuery = (
  { __typename?: 'Query' }
  & { reservationComments: Array<(
    { __typename?: 'ReservationCommentType' }
    & Pick<Types.ReservationCommentType, 'id' | 'comment' | 'createdAt'>
    & { createdBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type GetReservationsRentedOutQueryQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetReservationsRentedOutQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsRentedOut: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned' | 'giveAt' | 'returnAt' | 'createdAt'>
    & { order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id' | 'status' | 'createdAt'>
      & { reference?: Types.Maybe<(
        { __typename?: 'ReferenceType' }
        & Pick<Types.ReferenceType, 'id' | 'name'>
      )> }
    ), machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'information' | 'identifier'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ) }
    )>, catalogRow?: Types.Maybe<(
      { __typename?: 'CatalogRowType' }
      & Pick<Types.CatalogRowType, 'id' | 'name'>
    )>, catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
      & { catalogExtraCategoryRental: (
        { __typename?: 'CatalogExtraCategoryRentalType' }
        & Pick<Types.CatalogExtraCategoryRentalType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type GetReservationsWorkQueuesReturnQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetReservationsWorkQueuesReturnQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsWorkQueuesReturn: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'bulkAmount' | 'bulkAmountReturned' | 'returnAt'>
    & { catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id'>
      ) }
    )>, machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'identifier' | 'bulkProduct'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ), location?: Types.Maybe<(
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id' | 'name'>
      )> }
    )>, order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
      & { customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type GetReservationPriceQueryQueryVariables = Types.Exact<{
  reservationIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
  dateTo?: Types.InputMaybe<Types.Scalars['Date']>;
}>;


export type GetReservationPriceQueryQuery = (
  { __typename?: 'Query' }
  & { reservationPrice: Array<(
    { __typename?: 'IdPriceType' }
    & Pick<Types.IdPriceType, 'reservationId' | 'total' | 'totalUninvoiced' | 'totalReservation' | 'totalReservationWithoutDiscount' | 'invoicePeriodStart' | 'invoicePeriodEnd' | 'isDayPrice'>
  )> }
);

export type GetReservationEditableDatesQueryQueryVariables = Types.Exact<{
  reservationIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type GetReservationEditableDatesQueryQuery = (
  { __typename?: 'Query' }
  & { reservationEditableDates: (
    { __typename?: 'EditableDatesType' }
    & Pick<Types.EditableDatesType, 'dateStart' | 'dateEnd'>
  ) }
);

export type GetInvoicingPriceForReservationQueryVariables = Types.Exact<{
  reservationId: Types.Scalars['ID'];
  date: Types.Scalars['Date'];
}>;


export type GetInvoicingPriceForReservationQuery = (
  { __typename?: 'Query' }
  & { invoicingPriceForReservation: (
    { __typename?: 'InvoicingPriceReservationType' }
    & Pick<Types.InvoicingPriceReservationType, 'total' | 'isDayPrice' | 'days' | 'months'>
  ) }
);

export type GetReservationImageGivesQueryVariables = Types.Exact<{
  reservationId: Types.Scalars['ID'];
}>;


export type GetReservationImageGivesQuery = (
  { __typename?: 'Query' }
  & { reservationImageGives?: Types.Maybe<Array<(
    { __typename?: 'ReservationImageType' }
    & Pick<Types.ReservationImageType, 'id' | 'image' | 'createdAt'>
  )>> }
);

export type GetReservationImageReturnsQueryVariables = Types.Exact<{
  reservationId: Types.Scalars['ID'];
}>;


export type GetReservationImageReturnsQuery = (
  { __typename?: 'Query' }
  & { reservationImageReturns?: Types.Maybe<Array<(
    { __typename?: 'ReservationImageType' }
    & Pick<Types.ReservationImageType, 'id' | 'image' | 'createdAt'>
  )>> }
);

export type GetReservationImageBasicsQueryVariables = Types.Exact<{
  reservationId: Types.Scalars['ID'];
}>;


export type GetReservationImageBasicsQuery = (
  { __typename?: 'Query' }
  & { reservationImageBasics: Array<(
    { __typename?: 'ReservationImageBasicType' }
    & Pick<Types.ReservationImageBasicType, 'id' | 'image' | 'information' | 'shootingDate' | 'createdAt'>
  )> }
);

export type ReservationsSearchQueryQueryVariables = Types.Exact<{
  search: Types.Scalars['String'];
}>;


export type ReservationsSearchQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsSearch: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'bulkAmount' | 'bulkAmountReturned'>
    & { order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
      & { customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name'>
      )> }
    ), catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
    )>, machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'identifier' | 'bulkProduct'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ) }
    )> }
  )> }
);

export type GetReportPriceQueryQueryVariables = Types.Exact<{
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
}>;


export type GetReportPriceQueryQuery = (
  { __typename?: 'Query' }
  & { reportPrices: Array<(
    { __typename?: 'ReportPriceType' }
    & Pick<Types.ReportPriceType, 'dateStart' | 'dateEnd' | 'title' | 'useDayPrice' | 'dayPrice'>
    & { order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
    ), reservation: (
      { __typename?: 'ReservationType' }
      & Pick<Types.ReservationType, 'id'>
    ), reportpricemonthSet: Array<(
      { __typename?: 'ReportPriceMonthType' }
      & Pick<Types.ReportPriceMonthType, 'id' | 'month' | 'pricePerDay'>
    )>, reportpricedayskippedSet: Array<(
      { __typename?: 'ReportPriceDaySkippedType' }
      & Pick<Types.ReportPriceDaySkippedType, 'id' | 'dateSkipped'>
    )> }
  )> }
);

export type GetReservationsOverlappingWithOrderQueryQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID'];
}>;


export type GetReservationsOverlappingWithOrderQueryQuery = (
  { __typename?: 'Query' }
  & { reservationsOverlappingWithOrder: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'status' | 'dateRented' | 'timeRented' | 'dateReturned' | 'timeReturned' | 'dateBillingMin' | 'bulkAmount' | 'bulkAmountReturned' | 'discountPercentDay' | 'discountPercentMonth' | 'estimatedMachineHoursPerDay'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'bulkProduct'>
    )> }
  )> }
);

export const ReservationFieldsFullFragmentDoc = gql`
    fragment ReservationFieldsFull on ReservationType {
  id
  order {
    id
  }
  machine {
    id
  }
  catalogRow {
    id
  }
  catalogExtraRowRental {
    id
  }
  reservationSource {
    id
  }
  albumGive {
    id
  }
  albumReturn {
    id
  }
  userGive {
    id
  }
  userReturn {
    id
  }
  dateRented
  timeRented
  dateReturned
  timeReturned
  dateBillingMin
  information
  deliveryMethod
  status
  bulkAmount
  bulkAmountReturned
  discountPercentDay
  discountPercentMonth
  estimatedMachineHoursPerDay
  giveCheckedAt
  giveImagesAt
  giveInstructedAt
  giveAt
  returnAt
  returnImagesAt
  returnCheckedCatalogAt
  returnCheckedPreAt
  returnCheckedAt
  returnStoredAt
  billingDaysWeekCompany
  billingDaysWeekPrivate
  hourLimitDayCompany
  hourLimitDayPrivate
  hourLimitMonthCompany
  hourLimitMonthPrivate
  reservationcommentSet {
    id
  }
  invoicerowSet {
    id
  }
  catalogextrarowinvoiceSet {
    id
  }
  reservationsSourced {
    id
  }
  operationhourSet {
    id
  }
  transportSet {
    id
  }
  drivecontentSet {
    id
  }
  machinebulkbreakdownSet {
    id
  }
  inspectionreturnanswerSet {
    id
  }
  inspectiongiveanswerSet {
    id
  }
  reservationimagebasicSet {
    id
  }
  commissionreportrowSet {
    id
  }
  createdAt
  createdBy {
    id
  }
}
    `;
export const ReservationFieldsFragmentDoc = gql`
    fragment ReservationFields on ReservationType {
  id
  status
  dateRented
  timeRented
  dateReturned
  timeReturned
  dateBillingMin
  bulkAmount
  bulkAmountReturned
  discountPercentDay
  discountPercentMonth
  estimatedMachineHoursPerDay
}
    `;
export const ReservationFieldsForTableFragmentDoc = gql`
    fragment ReservationFieldsForTable on ReservationType {
  id
  machine {
    id
    information
    identifier
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
  }
  catalogRow {
    id
    name
  }
  catalogExtraRowRental {
    id
    identifier
    name
    catalogExtraCategoryRental {
      id
      name
    }
  }
  dateRented
  dateReturned
  giveAt
  returnAt
  createdAt
}
    `;
export const GetReservationForAddingImagesDocument = gql`
    query getReservationForAddingImages($id: ID!) {
  reservation(id: $id) {
    id
    giveCheckedAt
    giveImagesAt
    giveInstructedAt
    giveAt
    returnAt
    returnImagesAt
    returnCheckedCatalogAt
    returnCheckedPreAt
    returnCheckedAt
    returnStoredAt
    status
    userGive {
      id
    }
    userReturn {
      id
    }
    albumGive {
      id
    }
    albumReturn {
      id
    }
  }
}
    `;

/**
 * __useGetReservationForAddingImagesQuery__
 *
 * To run a query within a React component, call `useGetReservationForAddingImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationForAddingImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationForAddingImagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReservationForAddingImagesQuery(baseOptions: Apollo.QueryHookOptions<GetReservationForAddingImagesQuery, GetReservationForAddingImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationForAddingImagesQuery, GetReservationForAddingImagesQueryVariables>(GetReservationForAddingImagesDocument, options);
      }
export function useGetReservationForAddingImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationForAddingImagesQuery, GetReservationForAddingImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationForAddingImagesQuery, GetReservationForAddingImagesQueryVariables>(GetReservationForAddingImagesDocument, options);
        }
export type GetReservationForAddingImagesQueryHookResult = ReturnType<typeof useGetReservationForAddingImagesQuery>;
export type GetReservationForAddingImagesLazyQueryHookResult = ReturnType<typeof useGetReservationForAddingImagesLazyQuery>;
export type GetReservationForAddingImagesQueryResult = Apollo.QueryResult<GetReservationForAddingImagesQuery, GetReservationForAddingImagesQueryVariables>;
export const GetReservationsMachinesDocument = gql`
    query getReservationsMachines($dateFrom: Date!, $dateTo: Date!, $includeNotReturned: Boolean!) {
  reservations(
    dateFrom: $dateFrom
    dateTo: $dateTo
    includeNotReturned: $includeNotReturned
  ) {
    id
    machine {
      id
    }
  }
}
    `;

/**
 * __useGetReservationsMachinesQuery__
 *
 * To run a query within a React component, call `useGetReservationsMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsMachinesQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      includeNotReturned: // value for 'includeNotReturned'
 *   },
 * });
 */
export function useGetReservationsMachinesQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsMachinesQuery, GetReservationsMachinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsMachinesQuery, GetReservationsMachinesQueryVariables>(GetReservationsMachinesDocument, options);
      }
export function useGetReservationsMachinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsMachinesQuery, GetReservationsMachinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsMachinesQuery, GetReservationsMachinesQueryVariables>(GetReservationsMachinesDocument, options);
        }
export type GetReservationsMachinesQueryHookResult = ReturnType<typeof useGetReservationsMachinesQuery>;
export type GetReservationsMachinesLazyQueryHookResult = ReturnType<typeof useGetReservationsMachinesLazyQuery>;
export type GetReservationsMachinesQueryResult = Apollo.QueryResult<GetReservationsMachinesQuery, GetReservationsMachinesQueryVariables>;
export const GetReservationsDocument = gql`
    query getReservations($dateFrom: Date!, $dateTo: Date!, $includeNotReturned: Boolean!, $catalogRowId: ID, $catalogExtraRowRentalId: ID, $machineId: ID) {
  reservations(
    dateFrom: $dateFrom
    dateTo: $dateTo
    includeNotReturned: $includeNotReturned
    catalogRowId: $catalogRowId
    catalogExtraRowRentalId: $catalogExtraRowRentalId
    machineId: $machineId
  ) {
    id
    dateRented
    dateReturned
  }
}
    `;

/**
 * __useGetReservationsQuery__
 *
 * To run a query within a React component, call `useGetReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      includeNotReturned: // value for 'includeNotReturned'
 *      catalogRowId: // value for 'catalogRowId'
 *      catalogExtraRowRentalId: // value for 'catalogExtraRowRentalId'
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetReservationsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsQuery, GetReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsQuery, GetReservationsQueryVariables>(GetReservationsDocument, options);
      }
export function useGetReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsQuery, GetReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsQuery, GetReservationsQueryVariables>(GetReservationsDocument, options);
        }
export type GetReservationsQueryHookResult = ReturnType<typeof useGetReservationsQuery>;
export type GetReservationsLazyQueryHookResult = ReturnType<typeof useGetReservationsLazyQuery>;
export type GetReservationsQueryResult = Apollo.QueryResult<GetReservationsQuery, GetReservationsQueryVariables>;
export const GetReservationsForMachineQueryDocument = gql`
    query getReservationsForMachineQuery($machineId: ID!) {
  reservationsForMachine(machineId: $machineId) {
    id
    dateRented
    dateReturned
    giveAt
    order {
      id
      customer {
        id
        name
      }
    }
    returnAt
  }
}
    `;

/**
 * __useGetReservationsForMachineQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationsForMachineQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsForMachineQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsForMachineQueryQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetReservationsForMachineQueryQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsForMachineQueryQuery, GetReservationsForMachineQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsForMachineQueryQuery, GetReservationsForMachineQueryQueryVariables>(GetReservationsForMachineQueryDocument, options);
      }
export function useGetReservationsForMachineQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsForMachineQueryQuery, GetReservationsForMachineQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsForMachineQueryQuery, GetReservationsForMachineQueryQueryVariables>(GetReservationsForMachineQueryDocument, options);
        }
export type GetReservationsForMachineQueryQueryHookResult = ReturnType<typeof useGetReservationsForMachineQueryQuery>;
export type GetReservationsForMachineQueryLazyQueryHookResult = ReturnType<typeof useGetReservationsForMachineQueryLazyQuery>;
export type GetReservationsForMachineQueryQueryResult = Apollo.QueryResult<GetReservationsForMachineQueryQuery, GetReservationsForMachineQueryQueryVariables>;
export const GetReservationsForOrderQueryDocument = gql`
    query getReservationsForOrderQuery($orderId: ID!) {
  reservationsForOrder(orderId: $orderId) {
    id
    bulkAmount
    bulkAmountReturned
    giveAt
    returnAt
    catalogExtraRowRental {
      id
      identifier
      name
      location {
        id
      }
    }
    machine {
      id
      bulkProduct
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
      identifier
    }
    order {
      id
    }
  }
}
    `;

/**
 * __useGetReservationsForOrderQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationsForOrderQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsForOrderQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsForOrderQueryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetReservationsForOrderQueryQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsForOrderQueryQuery, GetReservationsForOrderQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsForOrderQueryQuery, GetReservationsForOrderQueryQueryVariables>(GetReservationsForOrderQueryDocument, options);
      }
export function useGetReservationsForOrderQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsForOrderQueryQuery, GetReservationsForOrderQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsForOrderQueryQuery, GetReservationsForOrderQueryQueryVariables>(GetReservationsForOrderQueryDocument, options);
        }
export type GetReservationsForOrderQueryQueryHookResult = ReturnType<typeof useGetReservationsForOrderQueryQuery>;
export type GetReservationsForOrderQueryLazyQueryHookResult = ReturnType<typeof useGetReservationsForOrderQueryLazyQuery>;
export type GetReservationsForOrderQueryQueryResult = Apollo.QueryResult<GetReservationsForOrderQueryQuery, GetReservationsForOrderQueryQueryVariables>;
export const GetReservationsWorkQueuesQueryDocument = gql`
    query getReservationsWorkQueuesQuery($locationIds: [ID]!, $userId: ID) {
  reservationsWorkQueues(locationIds: $locationIds, userId: $userId) {
    id
    status
    dateRented
    timeRented
    dateReturned
    timeReturned
    dateBillingMin
    bulkAmount
    bulkAmountReturned
    information
    userGive {
      id
      firstName
      lastName
    }
    userReturn {
      id
      firstName
      lastName
    }
    giveCheckedAt
    giveImagesAt
    giveInstructedAt
    giveAt
    returnAt
    returnImagesAt
    returnCheckedCatalogAt
    returnCheckedPreAt
    returnCheckedAt
    returnStoredAt
    catalogExtraRowRental {
      id
      identifier
      name
      location {
        id
      }
    }
    reservationcommentSet {
      id
    }
    machine {
      id
      hourTracking
      bulkProduct
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
      identifier
      location {
        id
        name
      }
    }
    order {
      id
      customer {
        id
        name
      }
      location {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetReservationsWorkQueuesQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationsWorkQueuesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsWorkQueuesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsWorkQueuesQueryQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetReservationsWorkQueuesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsWorkQueuesQueryQuery, GetReservationsWorkQueuesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsWorkQueuesQueryQuery, GetReservationsWorkQueuesQueryQueryVariables>(GetReservationsWorkQueuesQueryDocument, options);
      }
export function useGetReservationsWorkQueuesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsWorkQueuesQueryQuery, GetReservationsWorkQueuesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsWorkQueuesQueryQuery, GetReservationsWorkQueuesQueryQueryVariables>(GetReservationsWorkQueuesQueryDocument, options);
        }
export type GetReservationsWorkQueuesQueryQueryHookResult = ReturnType<typeof useGetReservationsWorkQueuesQueryQuery>;
export type GetReservationsWorkQueuesQueryLazyQueryHookResult = ReturnType<typeof useGetReservationsWorkQueuesQueryLazyQuery>;
export type GetReservationsWorkQueuesQueryQueryResult = Apollo.QueryResult<GetReservationsWorkQueuesQueryQuery, GetReservationsWorkQueuesQueryQueryVariables>;
export const GetReservationCommentsDocument = gql`
    query getReservationComments($reservationId: ID!) {
  reservationComments(reservationId: $reservationId) {
    id
    comment
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetReservationCommentsQuery__
 *
 * To run a query within a React component, call `useGetReservationCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationCommentsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationCommentsQuery, GetReservationCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationCommentsQuery, GetReservationCommentsQueryVariables>(GetReservationCommentsDocument, options);
      }
export function useGetReservationCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationCommentsQuery, GetReservationCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationCommentsQuery, GetReservationCommentsQueryVariables>(GetReservationCommentsDocument, options);
        }
export type GetReservationCommentsQueryHookResult = ReturnType<typeof useGetReservationCommentsQuery>;
export type GetReservationCommentsLazyQueryHookResult = ReturnType<typeof useGetReservationCommentsLazyQuery>;
export type GetReservationCommentsQueryResult = Apollo.QueryResult<GetReservationCommentsQuery, GetReservationCommentsQueryVariables>;
export const GetReservationsRentedOutQueryDocument = gql`
    query getReservationsRentedOutQuery($locationIds: [ID]!) {
  reservationsRentedOut(locationIds: $locationIds) {
    ...ReservationFieldsForTable
    order {
      id
      reference {
        id
        name
      }
      status
      createdAt
    }
  }
}
    ${ReservationFieldsForTableFragmentDoc}`;

/**
 * __useGetReservationsRentedOutQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationsRentedOutQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsRentedOutQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsRentedOutQueryQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useGetReservationsRentedOutQueryQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsRentedOutQueryQuery, GetReservationsRentedOutQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsRentedOutQueryQuery, GetReservationsRentedOutQueryQueryVariables>(GetReservationsRentedOutQueryDocument, options);
      }
export function useGetReservationsRentedOutQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsRentedOutQueryQuery, GetReservationsRentedOutQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsRentedOutQueryQuery, GetReservationsRentedOutQueryQueryVariables>(GetReservationsRentedOutQueryDocument, options);
        }
export type GetReservationsRentedOutQueryQueryHookResult = ReturnType<typeof useGetReservationsRentedOutQueryQuery>;
export type GetReservationsRentedOutQueryLazyQueryHookResult = ReturnType<typeof useGetReservationsRentedOutQueryLazyQuery>;
export type GetReservationsRentedOutQueryQueryResult = Apollo.QueryResult<GetReservationsRentedOutQueryQuery, GetReservationsRentedOutQueryQueryVariables>;
export const GetReservationsWorkQueuesReturnQueryDocument = gql`
    query getReservationsWorkQueuesReturnQuery {
  reservationsWorkQueuesReturn {
    id
    bulkAmount
    bulkAmountReturned
    returnAt
    catalogExtraRowRental {
      id
      identifier
      name
      location {
        id
      }
    }
    machine {
      id
      identifier
      bulkProduct
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
      location {
        id
        name
      }
    }
    order {
      id
      customer {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetReservationsWorkQueuesReturnQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationsWorkQueuesReturnQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsWorkQueuesReturnQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsWorkQueuesReturnQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReservationsWorkQueuesReturnQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetReservationsWorkQueuesReturnQueryQuery, GetReservationsWorkQueuesReturnQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsWorkQueuesReturnQueryQuery, GetReservationsWorkQueuesReturnQueryQueryVariables>(GetReservationsWorkQueuesReturnQueryDocument, options);
      }
export function useGetReservationsWorkQueuesReturnQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsWorkQueuesReturnQueryQuery, GetReservationsWorkQueuesReturnQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsWorkQueuesReturnQueryQuery, GetReservationsWorkQueuesReturnQueryQueryVariables>(GetReservationsWorkQueuesReturnQueryDocument, options);
        }
export type GetReservationsWorkQueuesReturnQueryQueryHookResult = ReturnType<typeof useGetReservationsWorkQueuesReturnQueryQuery>;
export type GetReservationsWorkQueuesReturnQueryLazyQueryHookResult = ReturnType<typeof useGetReservationsWorkQueuesReturnQueryLazyQuery>;
export type GetReservationsWorkQueuesReturnQueryQueryResult = Apollo.QueryResult<GetReservationsWorkQueuesReturnQueryQuery, GetReservationsWorkQueuesReturnQueryQueryVariables>;
export const GetReservationPriceQueryDocument = gql`
    query getReservationPriceQuery($reservationIds: [ID], $dateTo: Date) {
  reservationPrice(reservationIds: $reservationIds, dateTo: $dateTo) {
    reservationId
    total
    totalUninvoiced
    totalReservation
    totalReservationWithoutDiscount
    invoicePeriodStart
    invoicePeriodEnd
    total
    isDayPrice
  }
}
    `;

/**
 * __useGetReservationPriceQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationPriceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationPriceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationPriceQueryQuery({
 *   variables: {
 *      reservationIds: // value for 'reservationIds'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useGetReservationPriceQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetReservationPriceQueryQuery, GetReservationPriceQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationPriceQueryQuery, GetReservationPriceQueryQueryVariables>(GetReservationPriceQueryDocument, options);
      }
export function useGetReservationPriceQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationPriceQueryQuery, GetReservationPriceQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationPriceQueryQuery, GetReservationPriceQueryQueryVariables>(GetReservationPriceQueryDocument, options);
        }
export type GetReservationPriceQueryQueryHookResult = ReturnType<typeof useGetReservationPriceQueryQuery>;
export type GetReservationPriceQueryLazyQueryHookResult = ReturnType<typeof useGetReservationPriceQueryLazyQuery>;
export type GetReservationPriceQueryQueryResult = Apollo.QueryResult<GetReservationPriceQueryQuery, GetReservationPriceQueryQueryVariables>;
export const GetReservationEditableDatesQueryDocument = gql`
    query getReservationEditableDatesQuery($reservationIds: [ID]) {
  reservationEditableDates(reservationIds: $reservationIds) {
    dateStart
    dateEnd
  }
}
    `;

/**
 * __useGetReservationEditableDatesQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationEditableDatesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationEditableDatesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationEditableDatesQueryQuery({
 *   variables: {
 *      reservationIds: // value for 'reservationIds'
 *   },
 * });
 */
export function useGetReservationEditableDatesQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetReservationEditableDatesQueryQuery, GetReservationEditableDatesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationEditableDatesQueryQuery, GetReservationEditableDatesQueryQueryVariables>(GetReservationEditableDatesQueryDocument, options);
      }
export function useGetReservationEditableDatesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationEditableDatesQueryQuery, GetReservationEditableDatesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationEditableDatesQueryQuery, GetReservationEditableDatesQueryQueryVariables>(GetReservationEditableDatesQueryDocument, options);
        }
export type GetReservationEditableDatesQueryQueryHookResult = ReturnType<typeof useGetReservationEditableDatesQueryQuery>;
export type GetReservationEditableDatesQueryLazyQueryHookResult = ReturnType<typeof useGetReservationEditableDatesQueryLazyQuery>;
export type GetReservationEditableDatesQueryQueryResult = Apollo.QueryResult<GetReservationEditableDatesQueryQuery, GetReservationEditableDatesQueryQueryVariables>;
export const GetInvoicingPriceForReservationDocument = gql`
    query getInvoicingPriceForReservation($reservationId: ID!, $date: Date!) {
  invoicingPriceForReservation(reservationId: $reservationId, date: $date) {
    total
    isDayPrice
    days
    months
  }
}
    `;

/**
 * __useGetInvoicingPriceForReservationQuery__
 *
 * To run a query within a React component, call `useGetInvoicingPriceForReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicingPriceForReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicingPriceForReservationQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetInvoicingPriceForReservationQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicingPriceForReservationQuery, GetInvoicingPriceForReservationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicingPriceForReservationQuery, GetInvoicingPriceForReservationQueryVariables>(GetInvoicingPriceForReservationDocument, options);
      }
export function useGetInvoicingPriceForReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicingPriceForReservationQuery, GetInvoicingPriceForReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicingPriceForReservationQuery, GetInvoicingPriceForReservationQueryVariables>(GetInvoicingPriceForReservationDocument, options);
        }
export type GetInvoicingPriceForReservationQueryHookResult = ReturnType<typeof useGetInvoicingPriceForReservationQuery>;
export type GetInvoicingPriceForReservationLazyQueryHookResult = ReturnType<typeof useGetInvoicingPriceForReservationLazyQuery>;
export type GetInvoicingPriceForReservationQueryResult = Apollo.QueryResult<GetInvoicingPriceForReservationQuery, GetInvoicingPriceForReservationQueryVariables>;
export const GetReservationImageGivesDocument = gql`
    query getReservationImageGives($reservationId: ID!) {
  reservationImageGives(reservationId: $reservationId) {
    id
    image
    createdAt
  }
}
    `;

/**
 * __useGetReservationImageGivesQuery__
 *
 * To run a query within a React component, call `useGetReservationImageGivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationImageGivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationImageGivesQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationImageGivesQuery(baseOptions: Apollo.QueryHookOptions<GetReservationImageGivesQuery, GetReservationImageGivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationImageGivesQuery, GetReservationImageGivesQueryVariables>(GetReservationImageGivesDocument, options);
      }
export function useGetReservationImageGivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationImageGivesQuery, GetReservationImageGivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationImageGivesQuery, GetReservationImageGivesQueryVariables>(GetReservationImageGivesDocument, options);
        }
export type GetReservationImageGivesQueryHookResult = ReturnType<typeof useGetReservationImageGivesQuery>;
export type GetReservationImageGivesLazyQueryHookResult = ReturnType<typeof useGetReservationImageGivesLazyQuery>;
export type GetReservationImageGivesQueryResult = Apollo.QueryResult<GetReservationImageGivesQuery, GetReservationImageGivesQueryVariables>;
export const GetReservationImageReturnsDocument = gql`
    query getReservationImageReturns($reservationId: ID!) {
  reservationImageReturns(reservationId: $reservationId) {
    id
    image
    createdAt
  }
}
    `;

/**
 * __useGetReservationImageReturnsQuery__
 *
 * To run a query within a React component, call `useGetReservationImageReturnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationImageReturnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationImageReturnsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationImageReturnsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationImageReturnsQuery, GetReservationImageReturnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationImageReturnsQuery, GetReservationImageReturnsQueryVariables>(GetReservationImageReturnsDocument, options);
      }
export function useGetReservationImageReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationImageReturnsQuery, GetReservationImageReturnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationImageReturnsQuery, GetReservationImageReturnsQueryVariables>(GetReservationImageReturnsDocument, options);
        }
export type GetReservationImageReturnsQueryHookResult = ReturnType<typeof useGetReservationImageReturnsQuery>;
export type GetReservationImageReturnsLazyQueryHookResult = ReturnType<typeof useGetReservationImageReturnsLazyQuery>;
export type GetReservationImageReturnsQueryResult = Apollo.QueryResult<GetReservationImageReturnsQuery, GetReservationImageReturnsQueryVariables>;
export const GetReservationImageBasicsDocument = gql`
    query getReservationImageBasics($reservationId: ID!) {
  reservationImageBasics(reservationId: $reservationId) {
    id
    image
    information
    shootingDate
    createdAt
  }
}
    `;

/**
 * __useGetReservationImageBasicsQuery__
 *
 * To run a query within a React component, call `useGetReservationImageBasicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationImageBasicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationImageBasicsQuery({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetReservationImageBasicsQuery(baseOptions: Apollo.QueryHookOptions<GetReservationImageBasicsQuery, GetReservationImageBasicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationImageBasicsQuery, GetReservationImageBasicsQueryVariables>(GetReservationImageBasicsDocument, options);
      }
export function useGetReservationImageBasicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationImageBasicsQuery, GetReservationImageBasicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationImageBasicsQuery, GetReservationImageBasicsQueryVariables>(GetReservationImageBasicsDocument, options);
        }
export type GetReservationImageBasicsQueryHookResult = ReturnType<typeof useGetReservationImageBasicsQuery>;
export type GetReservationImageBasicsLazyQueryHookResult = ReturnType<typeof useGetReservationImageBasicsLazyQuery>;
export type GetReservationImageBasicsQueryResult = Apollo.QueryResult<GetReservationImageBasicsQuery, GetReservationImageBasicsQueryVariables>;
export const ReservationsSearchQueryDocument = gql`
    query ReservationsSearchQuery($search: String!) {
  reservationsSearch(search: $search) {
    id
    bulkAmount
    bulkAmountReturned
    order {
      id
      customer {
        id
        name
      }
    }
    catalogExtraRowRental {
      id
      identifier
      name
    }
    machine {
      id
      identifier
      bulkProduct
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useReservationsSearchQueryQuery__
 *
 * To run a query within a React component, call `useReservationsSearchQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsSearchQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsSearchQueryQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReservationsSearchQueryQuery(baseOptions: Apollo.QueryHookOptions<ReservationsSearchQueryQuery, ReservationsSearchQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsSearchQueryQuery, ReservationsSearchQueryQueryVariables>(ReservationsSearchQueryDocument, options);
      }
export function useReservationsSearchQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsSearchQueryQuery, ReservationsSearchQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsSearchQueryQuery, ReservationsSearchQueryQueryVariables>(ReservationsSearchQueryDocument, options);
        }
export type ReservationsSearchQueryQueryHookResult = ReturnType<typeof useReservationsSearchQueryQuery>;
export type ReservationsSearchQueryLazyQueryHookResult = ReturnType<typeof useReservationsSearchQueryLazyQuery>;
export type ReservationsSearchQueryQueryResult = Apollo.QueryResult<ReservationsSearchQueryQuery, ReservationsSearchQueryQueryVariables>;
export const GetReportPriceQueryDocument = gql`
    query getReportPriceQuery($dateFrom: Date!, $dateTo: Date!) {
  reportPrices(dateFrom: $dateFrom, dateTo: $dateTo) {
    order {
      id
    }
    reservation {
      id
    }
    dateStart
    dateEnd
    title
    useDayPrice
    dayPrice
    reportpricemonthSet {
      id
      month
      pricePerDay
    }
    reportpricedayskippedSet {
      id
      dateSkipped
    }
  }
}
    `;

/**
 * __useGetReportPriceQueryQuery__
 *
 * To run a query within a React component, call `useGetReportPriceQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportPriceQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportPriceQueryQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useGetReportPriceQueryQuery(baseOptions: Apollo.QueryHookOptions<GetReportPriceQueryQuery, GetReportPriceQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportPriceQueryQuery, GetReportPriceQueryQueryVariables>(GetReportPriceQueryDocument, options);
      }
export function useGetReportPriceQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportPriceQueryQuery, GetReportPriceQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportPriceQueryQuery, GetReportPriceQueryQueryVariables>(GetReportPriceQueryDocument, options);
        }
export type GetReportPriceQueryQueryHookResult = ReturnType<typeof useGetReportPriceQueryQuery>;
export type GetReportPriceQueryLazyQueryHookResult = ReturnType<typeof useGetReportPriceQueryLazyQuery>;
export type GetReportPriceQueryQueryResult = Apollo.QueryResult<GetReportPriceQueryQuery, GetReportPriceQueryQueryVariables>;
export const GetReservationsOverlappingWithOrderQueryDocument = gql`
    query getReservationsOverlappingWithOrderQuery($orderId: ID!) {
  reservationsOverlappingWithOrder(orderId: $orderId) {
    ...ReservationFields
    machine {
      id
      bulkProduct
    }
  }
}
    ${ReservationFieldsFragmentDoc}`;

/**
 * __useGetReservationsOverlappingWithOrderQueryQuery__
 *
 * To run a query within a React component, call `useGetReservationsOverlappingWithOrderQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationsOverlappingWithOrderQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationsOverlappingWithOrderQueryQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetReservationsOverlappingWithOrderQueryQuery(baseOptions: Apollo.QueryHookOptions<GetReservationsOverlappingWithOrderQueryQuery, GetReservationsOverlappingWithOrderQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationsOverlappingWithOrderQueryQuery, GetReservationsOverlappingWithOrderQueryQueryVariables>(GetReservationsOverlappingWithOrderQueryDocument, options);
      }
export function useGetReservationsOverlappingWithOrderQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationsOverlappingWithOrderQueryQuery, GetReservationsOverlappingWithOrderQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationsOverlappingWithOrderQueryQuery, GetReservationsOverlappingWithOrderQueryQueryVariables>(GetReservationsOverlappingWithOrderQueryDocument, options);
        }
export type GetReservationsOverlappingWithOrderQueryQueryHookResult = ReturnType<typeof useGetReservationsOverlappingWithOrderQueryQuery>;
export type GetReservationsOverlappingWithOrderQueryLazyQueryHookResult = ReturnType<typeof useGetReservationsOverlappingWithOrderQueryLazyQuery>;
export type GetReservationsOverlappingWithOrderQueryQueryResult = Apollo.QueryResult<GetReservationsOverlappingWithOrderQueryQuery, GetReservationsOverlappingWithOrderQueryQueryVariables>;