import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { InputLabelProps, TextFieldProps } from "@material-ui/core";
import TextFieldFocus from "./TextFieldFocus";
import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";

interface Props extends WithStyles<typeof styles> {}

const TextFieldYear = ({ classes, ...other }: Props & TextFieldProps) => {
  const onChange = other.onChange;
  const InputLabelPropsVal: InputLabelProps = {
    ...other.InputLabelProps,
    shrink: true,
  };

  const inputPropsDefault: StandardInputProps["inputProps"] = {
    min: 2020,
    max: 2200,
  };
  const inputProps: StandardInputProps["inputProps"] = {
    ...inputPropsDefault,
    ...other.inputProps,
  };

  delete other.onChange;
  delete other.type;
  delete other.InputLabelProps;
  delete other.inputProps;

  return (
    <TextFieldFocus
      {...other}
      type="number"
      InputLabelProps={InputLabelPropsVal}
      inputProps={inputProps}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }

        // Focus not set in some browsers when pressing up-down buttons in numeric input. Needed for blur!
        event.target.focus();
      }}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TextFieldYear);
