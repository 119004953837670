import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InspectionsInspectionReturnQuestionAnswerTypeChoices,
  InspectionReturnQuestionType,
  InspectionReturnTagType,
  Mutation,
  MutationDeleteInspectionReturnQuestionArgs,
  MutationUpdateInspectionReturnQuestionArgs,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  DELETE_INSPECTION_RETURN_QUESTION_MUTATION,
  UPDATE_INSPECTION_RETURN_QUESTION_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import InspectionReturnQuestionTags from "./InspectionReturnQuestionTags";
import { dialogConfirm } from "../../utils/dialogs";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnQuestion: InspectionReturnQuestionType;
  inspectionReturnTags: InspectionReturnTagType[];
}

function InspectionReturnQuestion({
  classes,
  inspectionReturnQuestion,
  inspectionReturnTags,
}: Props) {
  const { t } = useTranslation();

  const [inspectionReturnQuestionEdited, setInspectionReturnQuestionEdited] =
    useState(inspectionReturnQuestion);

  const [updateInspectionReturnQuestion, { loading: loadingUpdate }] =
    useMutation<Mutation, MutationUpdateInspectionReturnQuestionArgs>(
      UPDATE_INSPECTION_RETURN_QUESTION_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [deleteInspectionReturnQuestion, { loading: loadingDelete }] =
    useMutation<Mutation, MutationDeleteInspectionReturnQuestionArgs>(
      DELETE_INSPECTION_RETURN_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditQuestion = checkPermission(myPermissions, [
    "inspections.change_inspectionreturnquestion",
  ]);
  const hasPermissionDeleteQuestion = checkPermission(myPermissions, [
    "inspections.delete_inspectionreturnquestion",
  ]);

  const handleDeleteInspectionReturnQuestion = (
    inspectionReturnQuestionId: string
  ) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionReturnQuestion({
        variables: { inspectionReturnQuestionId: inspectionReturnQuestionId },
      });
    });
  };

  const handleUpdateInspectionReturnQuestion = (
    values: Partial<MutationUpdateInspectionReturnQuestionArgs> = {}
  ) => {
    let base: MutationUpdateInspectionReturnQuestionArgs = {
      inspectionReturnQuestionId: inspectionReturnQuestionEdited.id,
      inspectionReturnCategoryId:
        inspectionReturnQuestionEdited.inspectionReturnCategory.id,
      question: inspectionReturnQuestionEdited.question,
      answerType: inspectionReturnQuestionEdited.answerType,
    };

    updateInspectionReturnQuestion({
      variables: { ...base, ...values },
    });
  };

  return (
    <tr
      key={inspectionReturnQuestion.id}
      className={`${classes.rowQuestion} ${loadingUpdate ? "loading" : ""}`}
    >
      <td className={classes.cellQuestion}>
        <FormControl fullWidth>
          <TextField
            onChange={(event) => {
              setInspectionReturnQuestionEdited({
                ...inspectionReturnQuestionEdited,
                question: event.target.value,
              });
            }}
            value={inspectionReturnQuestionEdited.question}
            disabled={!hasPermissionEditQuestion}
            inputProps={{ maxLength: 100 }}
            onBlur={() => handleUpdateInspectionReturnQuestion()}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <Select
            value={inspectionReturnQuestionEdited.answerType}
            disabled={!hasPermissionEditQuestion}
            onChange={(event) => {
              const answerType = event.target
                .value as InspectionsInspectionReturnQuestionAnswerTypeChoices;

              setInspectionReturnQuestionEdited({
                ...inspectionReturnQuestionEdited,
                answerType: answerType,
              });

              handleUpdateInspectionReturnQuestion({
                answerType: answerType,
              });
            }}
          >
            <MenuItem
              value={InspectionsInspectionReturnQuestionAnswerTypeChoices.Text}
            >
              {t(
                "answer_type_" +
                  InspectionsInspectionReturnQuestionAnswerTypeChoices.Text
              )}
            </MenuItem>
            <MenuItem
              value={
                InspectionsInspectionReturnQuestionAnswerTypeChoices.Options
              }
            >
              {t(
                "answer_type_" +
                  InspectionsInspectionReturnQuestionAnswerTypeChoices.Options
              )}
            </MenuItem>
            <MenuItem
              value={InspectionsInspectionReturnQuestionAnswerTypeChoices.Check}
            >
              {t(
                "answer_type_" +
                  InspectionsInspectionReturnQuestionAnswerTypeChoices.Check
              )}
            </MenuItem>
          </Select>
        </FormControl>
      </td>
      <InspectionReturnQuestionTags
        inspectionReturnQuestion={inspectionReturnQuestion}
        inspectionReturnTags={inspectionReturnTags}
      />
      <td>
        {hasPermissionDeleteQuestion && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            onClick={() =>
              handleDeleteInspectionReturnQuestion(inspectionReturnQuestion.id)
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    rowQuestion: {
      "& $cellQuestion": {
        paddingLeft: spacing(4),
      },
    },
    cellQuestion: {
      // Need to have cellQuestion here even if empty
      // so that "& $cellQuestion" works
      minWidth: "15rem",
    },
  });

export default withStyles(styles)(InspectionReturnQuestion);
