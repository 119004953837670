import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useLazyQuery, useQuery } from "@apollo/client";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import {
  GET_MACHINE_BREAKDOWNS_UNFIXED_QUERY,
  QueryResultMachinesBreakdownsUnfixed,
} from "../apollo/queries/machines";
import {
  GET_MAINTENANCE_QUEUES_QUERY,
  QueryResultMaintenanceQueues,
} from "../apollo/queries/maintenances";
import WorkQueuesMaintenanceBreakdowns from "../components/WorkQueues/WorkQueuesMaintenanceBreakdowns";
import WorkQueuesUpcomingMaintenances from "../components/WorkQueues/WorkQueuesMaintenancesUpcoming";
import WorkQueuesMaintenances from "../components/WorkQueues/WorkQueuesMaintenances";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import LocationFilter from "../components/Shared/LocationFilter";
import HeaderPage from "../components/Shared/HeaderPage";
import CatalogCategoryFilter from "../components/Shared/CatalogCategoryFilter";
import UserFilter from "../components/Shared/UserFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-light-svg-icons";
import ButtonLoad from "../components/Shared/ButtonLoad";
import { newDate } from "../utils/dates";
import format from "date-fns/format";

interface Props extends WithStyles<typeof styles> {}

function WorkQueuesMaintenance({ classes }: Props) {
  const { t } = useTranslation();

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([]);
  const [productGroupIdsSelected, setProductGroupIdsSelected] = useState<
    string[]
  >([]);
  const [userIdsSelected, setUserIdsSelected] = useState<string[]>([]);

  const { loading, error, data } =
    useQuery<QueryResultMachinesBreakdownsUnfixed>(
      GET_MACHINE_BREAKDOWNS_UNFIXED_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("machineBreakdownsUnfixed"),
        variables: {
          locationIds: locationIdsSelected,
          categoryIds: productGroupIdsSelected,
          userIds: userIdsSelected,
        },
      }
    );

  const [
    getMaintenanceQueues,
    {
      loading: loadingMaintenanceQueues,
      error: errorMaintenanceQueues,
      data: dataMaintenanceQueues,
    },
  ] = useLazyQuery<QueryResultMaintenanceQueues>(GET_MAINTENANCE_QUEUES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("maintenances"),
    variables: {
      locationIds: locationIdsSelected,
      categoryIds: productGroupIdsSelected,
      userIds: userIdsSelected,
      create: false,
    },
  });

  useEffect(() => {
    getMaintenanceQueues({
      variables: {
        locationIds: locationIdsSelected,
        categoryIds: productGroupIdsSelected,
        userIds: userIdsSelected,
        create: false,
      },
    });
    // eslint-disable-next-line
  }, [locationIdsSelected]);

  if (loading || loadingMaintenanceQueues) return <Loading />;
  if (error) return <Error error={error} />;
  if (errorMaintenanceQueues) return <Error error={errorMaintenanceQueues} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const xs = 12;
  const sm = 12;
  const md = 12;
  const lg = 4;

  return (
    <Container className="containerMain">
      <HeaderPage title={t("work_queues_maintenance")} />
      <div className="containerInner">
        <Row className="mb-3">
          <Col xs={xs} sm={sm} md={6} lg={4} xl={3}>
            <LocationFilter
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
            />
          </Col>
          <Col xs={xs} sm={sm} md={6} lg={4} xl={3}>
            <CatalogCategoryFilter
              catalogCategoryIdsSelected={productGroupIdsSelected}
              setCatalogCategoryIdsSelected={setProductGroupIdsSelected}
            />
          </Col>
          <Col xs={xs} sm={sm} md={6} lg={4} xl={3}>
            <UserFilter
              userIdsSelected={userIdsSelected}
              setUserIdsSelected={setUserIdsSelected}
            />
          </Col>
          <Col>
            <div className="float-end">
              <small className="m-2">
                {t("updated_at", {
                  date: dataMaintenanceQueues?.maintenanceUpcomingFetch
                    ? format(
                        newDate(
                          dataMaintenanceQueues.maintenanceUpcomingFetch
                            .fetchTime
                        ),
                        t("format_datetime")
                      )
                    : "",
                })}
              </small>
              <ButtonLoad
                loading={loadingMaintenanceQueues}
                variant="light"
                size="lg"
                onClick={() => {
                  getMaintenanceQueues({
                    variables: {
                      locationIds: locationIdsSelected,
                      categoryIds: productGroupIdsSelected,
                      userIds: userIdsSelected,
                      create: true,
                    },
                  });
                }}
                className="btnRoundSmall pull-right"
              >
                {<FontAwesomeIcon icon={faRedo} />}
              </ButtonLoad>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={xs} sm={sm} md={md} lg={lg}>
            <WorkQueuesMaintenanceBreakdowns
              machineBreakdowns={
                data.machineBreakdownsUnfixed
                  ? data.machineBreakdownsUnfixed
                  : []
              }
              machineBulkBreakdowns={
                data.machineBulkBreakdownsUnfixed
                  ? data.machineBulkBreakdownsUnfixed
                  : []
              }
              reservationsUpcoming={
                data.reservationsUpcomingByMachine
                  ? data.reservationsUpcomingByMachine
                  : []
              }
            />
          </Col>
          <Col>
            <WorkQueuesUpcomingMaintenances
              maintenancesUpcoming={
                dataMaintenanceQueues?.maintenancesUpcoming
                  ? dataMaintenanceQueues.maintenancesUpcoming
                  : []
              }
              reservationsUpcoming={
                data.reservationsUpcomingByMachine
                  ? data.reservationsUpcomingByMachine
                  : []
              }
              reservationsCurrent={
                data.reservationsCurrentByMachine
                  ? data.reservationsCurrentByMachine
                  : []
              }
              machineBreakdowns={
                data.machineBreakdownsUnfixed
                  ? data.machineBreakdownsUnfixed
                  : []
              }
              locations={locationIdsSelected}
            />
          </Col>
          <Col>
            <WorkQueuesMaintenances
              maintenances={
                dataMaintenanceQueues?.maintenances
                  ? dataMaintenanceQueues.maintenances
                  : []
              }
              reservationsUpcoming={
                data.reservationsUpcomingByMachine
                  ? data.reservationsUpcomingByMachine
                  : []
              }
              reservationsCurrent={
                data.reservationsCurrentByMachine
                  ? data.reservationsCurrentByMachine
                  : []
              }
              machineBreakdowns={
                data.machineBreakdownsUnfixed
                  ? data.machineBreakdownsUnfixed
                  : []
              }
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueuesMaintenance);
