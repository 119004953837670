import { ID_EMPTY } from "./constants";

export function getUrlOfferCatalog(
  customer_id: string = ":customer_id(\\d+)",
  catalog_id: string = ":catalog_id(\\d+)"
): string {
  return `/customers/${customer_id}/events/offer_catalog/${catalog_id}/`;
}

export function getUrlOfferDiscountList(
  customer_id: string = ":customer_id(\\d+)",
  discount_list_id: string = ":discount_list_id(\\d+)"
): string {
  return `/customers/${customer_id}/events/offer/${discount_list_id}/`;
}

export function getUrlOffer(
  customer_id: string,
  discount_list_id: string,
  catalog_id: string
): string {
  let url = getUrlOfferCatalog(customer_id, catalog_id);
  if (discount_list_id !== ID_EMPTY) {
    url = getUrlOfferDiscountList(customer_id, discount_list_id);
  }
  return url;
}

export function getUrlPrefixBackend(): string {
  return process.env.REACT_APP_URL_BACKEND === undefined
    ? ""
    : process.env.REACT_APP_URL_BACKEND;
}

export function getUrlCalendar(
  catalog_id: string = ":catalog_id(\\d+)",
  catalog_category_upper_id: string = ":catalog_category_upper_id(\\d+)",
  catalog_category_id: string = ":catalog_category_id(\\d+)",
  catalog_row_id: string = ":catalog_row_id(\\d+)"
): string {
  return `/calendar/${catalog_id}/${catalog_category_upper_id}/${catalog_category_id}/${catalog_row_id}`;
}

export function getUrlProfile(profile_id: string = ":id(\\d+)"): string {
  return `/profile/${profile_id}`;
}

export function getUrlCustomerTab(
  customer_id = ":customer_id(\\d+)",
  tab: ":tab" | "basic" | "events" | "reservations" | "offer" = ":tab"
): string {
  return `/customers/${customer_id}/${tab}`;
}

export function getUrlSettingsCatalogEdit(
  catalog_id = ":catalog_id(\\d+)"
): string {
  return `/settings/catalog/edit/${catalog_id}`;
}

export function getUrlSettingsDiscount(
  discount_list_id = ":discount_list_id(\\d+)"
): string {
  return `/settings/discounts/${discount_list_id}`;
}

export const URL_ROOT = "/";
export const URL_CALENDAR = "/calendar";
export const URL_CUSTOMERS = "/customers";
export const URL_SETTINGS_PROFILES = "/settings/profiles";
export const URL_SETTINGS_USERS = "/settings/users";
export const URL_SETTINGS_LOGS = "/settings/logs";
export const URL_SETTINGS_HOLIDAYS = "/settings/holidays";
export const URL_SETTINGS_LOCATIONS = "/settings/locations";
export const URL_SETTINGS_CATALOG_NEW = "/settings/catalog/new";
export const URL_SETTINGS_CATALOG = "/settings/catalog";
export const URL_SETTINGS_CATALOG_EXTRA_RENTAL =
  "/settings/catalog_extra_rental";
export const URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE =
  "/settings/catalog_extra_unit_price";
export const URL_SETTINGS_CATALOG_TRANSFER = "/settings/catalog_transfer";
export const URL_SETTINGS_DISCOUNTS = "/settings/discounts";
export const URL_SETTINGS_MACHINES = "/settings/machines";
export const URL_SETTINGS_MACHINE_INFORMATION = "/settings/machine_information";
export const URL_SETTINGS_COMMISSIONS = "/settings/commissions";
export const URL_SETTINGS_INSPECTION_RETURN = "/settings/inspection/return";
export const URL_SETTINGS_INSPECTION_GIVE = "/settings/inspection/give";
export const URL_SETTINGS_DIMENSIONS = "/settings/dimensions";
export const URL_SETTINGS_CUSTOMER_AREAS = "/settings/customer_areas";
export const URL_SETTINGS_CUSTOMER_RANKS = "/settings/customer_ranks";
export const URL_SETTINGS_CUSTOMER_INDUSTRIES = "/settings/customer_industries";
export const URL_SETTINGS_OTHER = "/settings/other";
export const URL_WORK_QUEUES_DRIVING = "/work_queues/driving/:tab";
export const URL_WORK_QUEUES_DRIVING_CREATE = "/work_queues/driving/create";
export const URL_WORK_QUEUES_DRIVING_LIST = "/work_queues/driving/list";
export const URL_WORK_QUEUES_RENTING = "/work_queues/renting";
export const URL_WORK_QUEUES_MAINTENANCE = "/work_queues/maintenance";
export const URL_WORK_QUEUES_ON_RENT = "/work_queues/on_rent";
export const URL_INVOICES_CREATE = "/management/invoices_create";
export const URL_INVOICES_OPEN = "/management/invoices_open";
export const URL_INVOICES_SENT = "/management/invoices_sent";
export const URL_REPORTS_COMMISSIONS = "/management/reports_commissions";
export const URL_REPORTS_DAY = "/management/reports_day";
export const URL_404 = "/404";
