import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { OrderType, ReservationType } from "../../entity/types";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogReservationSplitMachine from "./DialogReservationSplitMachine";
import DialogReservationSplitExtraRow from "./DialogReservationSplitExtraRow";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  reservation: ReservationType;
  setReservation: React.Dispatch<React.SetStateAction<ReservationType>>;
}

function ButtonReservationSplit({
  classes,
  order,
  reservation,
  setReservation,
}: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddReservation = checkPermission(myPermissions, [
    "reservations.add_reservation",
  ]);
  const hasPermissionChangeReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);

  const disabled =
    !hasPermissionAddReservation || !hasPermissionChangeReservation;

  return (
    <>
      <Button
        variant="light"
        onClick={() => {
          setOpen(!open);
        }}
        disabled={disabled}
      >
        {t("reservation_split")}
      </Button>
      {reservation.catalogExtraRowRental && open && (
        <DialogReservationSplitExtraRow
          open={open}
          setOpen={setOpen}
          order={order}
          reservation={reservation}
        />
      )}
      {!reservation.catalogExtraRowRental && open && (
        <DialogReservationSplitMachine
          open={open}
          setOpen={setOpen}
          reservation={reservation}
          setReservation={setReservation}
        />
      )}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonReservationSplit);
