import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import {
  CatalogType,
  MachineType,
  Mutation,
  MutationSaveMachineBulkAmountArgs,
  MutationUpdateMachineArgs,
} from "../../entity/types";
import MaterialTableMachine from "./MaterialTableMachine";
import MachineListMenu from "./MachineListMenu";
import { useMutation } from "@apollo/client";
import {
  SAVE_MACHINE_BULK_AMOUNT_MUTATION,
  UPDATE_MACHINE_MUTATION,
} from "../../apollo/mutations/machines";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentMachine from "./DialogContentMachine";
import DialogMachineCatalogExtraRowRental from "./DialogMachineCatalogExtraRowRental";
import DialogMachineCatalogExtraRowUnitPrice from "./DialogMachineCatalogExtraRowUnitPrice";
import DialogMachineInfo from "./MachineInfo/DialogMachineInfo";
import { MachineEmpty } from "../../entity/empties";
import DialogInspectionGive from "../InspectionGive/DialogInspectionGive";
import DialogInspectionReturn from "../InspectionReturn/DialogInspectionReturn";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import MaintenanceSettingsMachine from "../Maintenance/MaintenanceSettingsMachine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/pro-light-svg-icons";
import DialogMachineProductCard from "./MachineInfo/DialogMachineProductCard";

interface Props extends WithStyles<typeof styles> {
  machines: MachineType[];
  catalog: CatalogType;
}

const MachineList = ({ classes, machines, catalog }: Props) => {
  const { t } = useTranslation();

  const [machineSelected, setMachineSelected] =
    useState<MachineType>(MachineEmpty);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDialogConnectRowsRental, setOpenDialogConnectRowsRental] =
    useState(false);
  const [openDialogConnectRowsUnitPrice, setOpenDialogConnectRowsUnitPrice] =
    useState(false);
  const [openDialogMachineInfo, setOpenDialogMachineInfo] = useState(false);
  const [openDialogInspectionReturn, setOpenDialogInspectionReturn] =
    useState(false);
  const [openDialogInspectionGive, setOpenDialogInspectionGive] =
    useState(false);
  const [openDialogMaintenanceSettings, setOpenDialogMaintenanceSettings] =
    useState(false);
  const [openProductCard, setOpenProductCard] = useState(false);

  const [updateMachine, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineArgs
  >(UPDATE_MACHINE_MUTATION, {
    onCompleted: (result) => {
      setOpenEdit(false);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machinesForCatalogRows"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machinesForLocation"),
      });
    },
  });

  const [saveMachineBulkAmount, { loading: loadingSaveBulkAmount }] =
    useMutation<Mutation, MutationSaveMachineBulkAmountArgs>(
      SAVE_MACHINE_BULK_AMOUNT_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const handleSubmitEdit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let catalogRowIds: string[] = [];
    machineSelected.catalogRows.forEach(function (catalogRow) {
      catalogRowIds.push(catalogRow.id);
    });

    updateMachine({
      variables: {
        machineId: machineSelected.id,
        machineModelId: machineSelected.machineModel.id,
        information: machineSelected.information,
        identifier: machineSelected.identifier,
        serial: machineSelected.serial,
        acquisitionDate: machineSelected.acquisitionDate,
        dateRemoved: machineSelected.dateRemoved,
        yearModel: machineSelected.yearModel,
        hourTracking: machineSelected.hourTracking,
        rentThrough: machineSelected.rentThrough,
        rentThroughCompany: machineSelected.rentThroughCompany,
        rentThroughInformation: machineSelected.rentThroughInformation,
        rentThroughCommissionCase: machineSelected.rentThroughCommissionCase,
        bulkProduct: machineSelected.bulkProduct,
        catalogRowIds: catalogRowIds,
        locationId: machineSelected.location
          ? machineSelected.location.id
          : ID_EMPTY,
      },
    });

    machineSelected.machinebulkamountSet.forEach((machineBulkAmount) => {
      saveMachineBulkAmount({
        variables: {
          machineId: machineSelected.id,
          locationId: machineBulkAmount.location.id,
          bulkAmount: machineBulkAmount.bulkAmount,
        },
      });
    });
  };

  return (
    <div>
      <MaterialTableMachine
        machines={machines}
        catalogIdSelected={catalog.id}
        showExtendedList={true}
        actions={[
          {
            icon: "menu",
            onClick: () => {},
          },
        ]}
        components={{
          Action: (props) => (
            <>
              <Button
                className="me-1"
                variant="light"
                title={t("open_product_card")}
                onClick={() => {
                  setMachineSelected(props.data);
                  setOpenProductCard(true);
                }}
              >
                <FontAwesomeIcon icon={faClipboardList} />
              </Button>
              <MachineListMenu
                machine={props.data}
                setOpenEdit={setOpenEdit}
                setOpenDialogConnectRowsRental={setOpenDialogConnectRowsRental}
                setOpenDialogConnectRowsUnitPrice={
                  setOpenDialogConnectRowsUnitPrice
                }
                setOpenDialogMachineInfo={setOpenDialogMachineInfo}
                setOpenDialogInspectionReturn={setOpenDialogInspectionReturn}
                setOpenDialogInspectionGive={setOpenDialogInspectionGive}
                setOpenDialogMaintenanceSettings={
                  setOpenDialogMaintenanceSettings
                }
                setMachineSelected={setMachineSelected}
              />
            </>
          ),
        }}
      />
      {openEdit && (
        <Dialog open={openEdit}>
          <form
            className={loadingUpdate || loadingSaveBulkAmount ? "loading" : ""}
            onSubmit={(event) => handleSubmitEdit(event)}
          >
            <DialogTitle>{t("edit_machine")}</DialogTitle>
            <DialogContentMachine
              machine={machineSelected}
              setMachine={setMachineSelected}
              catalog={catalog}
            />
            <DialogActions>
              <Button onClick={() => setOpenEdit(false)} variant="secondary">
                {t("cancel")}
              </Button>
              <Button type="submit" variant="primary">
                {t("save")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      {openDialogConnectRowsRental && (
        <DialogMachineCatalogExtraRowRental
          open={openDialogConnectRowsRental}
          setOpen={setOpenDialogConnectRowsRental}
          machine={machineSelected}
        />
      )}
      {openDialogConnectRowsUnitPrice && (
        <DialogMachineCatalogExtraRowUnitPrice
          open={openDialogConnectRowsUnitPrice}
          setOpen={setOpenDialogConnectRowsUnitPrice}
          machine={machineSelected}
        />
      )}
      {openDialogMachineInfo && (
        <DialogMachineInfo
          open={openDialogMachineInfo}
          setOpen={setOpenDialogMachineInfo}
          machineId={machineSelected.id}
        />
      )}
      {openDialogInspectionGive && (
        <DialogInspectionGive
          open={openDialogInspectionGive}
          setOpen={setOpenDialogInspectionGive}
          machine={machineSelected}
        />
      )}
      {openDialogInspectionReturn && (
        <DialogInspectionReturn
          open={openDialogInspectionReturn}
          setOpen={setOpenDialogInspectionReturn}
          machine={machineSelected}
        />
      )}
      {openDialogMaintenanceSettings && (
        <MaintenanceSettingsMachine
          open={openDialogMaintenanceSettings}
          setOpen={setOpenDialogMaintenanceSettings}
          machine={machineSelected}
        />
      )}
      {openProductCard && (
        <DialogMachineProductCard
          open={openProductCard}
          setOpen={setOpenProductCard}
          machineId={machineSelected.id}
        />
      )}
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineList);
