import { gql } from "@apollo/client";
import { FRAG_CUSTOMER_CALENDAR_ORDER } from "./customers";
import { FRAG_RESERVATION_SHARED } from "./reservations";
import { Query } from "../../entity/types";

export interface QueryResultOrder {
  order: Query["order"];
  orderCanChangeCustomer: Query["orderCanChangeCustomer"];
}
export const GET_ORDER_QUERY = gql`
  ${FRAG_CUSTOMER_CALENDAR_ORDER}
  ${FRAG_RESERVATION_SHARED}
  query getOrderQuery($orderId: ID!) {
    order(orderId: $orderId) {
      id
      status
      advanceValidUntil
      information
      informationInvoice
      confirmationType
      isBusinessIfNoCustomer
      hasInsurance
      updatePricesInCatalogSwitch
      customer {
        ...CustomerCalendarOrderFields
      }
      reference {
        id
        name
      }
      location {
        id
        name
      }
      reservationSet {
        ...ReservationFields
        deliveryMethod
        information
        userGive {
          id
        }
        userReturn {
          id
        }
        giveCheckedAt
        giveAt
        returnAt
        returnCheckedCatalogAt
        returnCheckedPreAt
        returnCheckedAt
        billingDaysWeekCompany
        billingDaysWeekPrivate
        hourLimitDayCompany
        hourLimitDayPrivate
        hourLimitMonthCompany
        hourLimitMonthPrivate
        machine {
          id
          identifier
          serial
          yearModel
          bulkProduct
          machineModel {
            id
            title
            machineMake {
              id
              title
            }
          }
          location {
            id
            name
          }
          maintenancesetting {
            id
            scheduleType
            estimatedHoursPerDay
          }
        }
        catalogExtraRowRental {
          id
          identifier
          name
          information
          priceDayCompany
          priceDayPrivate
          priceMonthCompany
          priceMonthPrivate
          billingDaysWeekCompany
          billingDaysWeekPrivate
          location {
            id
          }
        }
        catalogRow {
          id
          image
          information
          billingDaysWeekCompany
          billingDaysWeekPrivate
          hourLimitDayCompany
          hourLimitDayPrivate
          hourLimitMonthCompany
          hourLimitMonthPrivate
          catalogCategory {
            id
            catalogCategoryUpper {
              id
              catalog {
                id
              }
            }
          }
        }
      }
      transportSet {
        id
      }
      createdAt
    }
    orderCanChangeCustomer(orderId: $orderId)
  }
`;

export interface QueryResultOrderTotal {
  orderTotal: Query["orderTotal"];
}
export const GET_ORDER_TOTAL_QUERY = gql`
  query getOrderTotalQuery($orderId: ID!) {
    orderTotal(orderId: $orderId) {
      title
      amount
    }
  }
`;
