import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  EventsEventEventTypeChoices,
  EventType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import { Autocomplete } from "@material-ui/lab";
import { useQuery } from "@apollo/client";
import {
  GET_CUSTOMERS_QUERY,
  QueryResultCustomers,
} from "../../apollo/queries/customers";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import { CustomerEmpty } from "../../entity/empties";
import { getContactNextDefault } from "../../utils/customers/customer";

interface Props extends WithStyles<typeof styles> {
  event: EventType;
  setEvent: React.Dispatch<React.SetStateAction<EventType>>;
}

function DialogContentEvent({ classes, event, setEvent }: Props) {
  const { t } = useTranslation();
  const myPermissions = useContext(PermissionsContext);

  const showCustomerList = event.id === ID_EMPTY;

  const { loading, error, data } = useQuery<QueryResultCustomers>(
    GET_CUSTOMERS_QUERY,
    {
      skip: !showCustomerList,
      fetchPolicy: getQueryFetchPolicy("customers"),
    }
  );

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;

  const hasPermissionAdd = checkPermission(myPermissions, ["events.add_event"]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "events.change_event",
  ]);
  const disabled =
    event.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  return (
    <DialogContent>
      {showCustomerList && (
        <Autocomplete<CustomerType>
          options={[CustomerEmpty, ...(data?.customers ? data.customers : [])]}
          getOptionLabel={(customer) =>
            customer.id === ID_EMPTY
              ? t("not_selected")
              : (customer.name || t("customer_name_empty")) +
                (customer.contactPersonLastname ||
                customer.contactPersonFirstname
                  ? " - " +
                    customer.contactPersonLastname +
                    " " +
                    customer.contactPersonFirstname
                  : "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("customer")}
              variant="outlined"
              required
            />
          )}
          value={event.customer}
          disabled={disabled}
          getOptionSelected={(a, b) => {
            return a.id === b.id;
          }}
          onChange={(e, customerSelected) => {
            const customerNew: CustomerType = customerSelected
              ? customerSelected
              : CustomerEmpty;
            setEvent({
              ...event,
              customer: {
                ...customerNew,
                contactNext: getContactNextDefault(),
              },
            });
          }}
        />
      )}
      <FormControl fullWidth>
        <InputLabel id="lblEventType">{t("type")}</InputLabel>
        <Select
          autoWidth
          labelId="lblEventType"
          value={event.eventType}
          disabled={disabled}
          onChange={(e) => {
            setEvent({
              ...event,
              eventType: e.target.value as EventsEventEventTypeChoices,
            });
          }}
        >
          <MenuItem value={EventsEventEventTypeChoices.Call}>
            {t("event_type_" + EventsEventEventTypeChoices.Call)}
          </MenuItem>
          <MenuItem value={EventsEventEventTypeChoices.Visit}>
            {t("event_type_" + EventsEventEventTypeChoices.Visit)}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("date")}
          type="date"
          value={event.date}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEvent({
              ...event,
              date: e.target.value,
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("information")}
          onChange={(e) => {
            setEvent({
              ...event,
              information: e.target.value,
            });
          }}
          value={event.information}
          disabled={disabled}
          multiline
        />
      </FormControl>
      <FormControl className="mt-3" fullWidth>
        <TextField
          type="date"
          label={t("contact_next")}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            setEvent({
              ...event,
              customer: { ...event.customer, contactNext: e.target.value },
            });
          }}
          value={event.customer.contactNext ? event.customer.contactNext : ""}
          disabled={disabled}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentEvent);
