import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, TextField, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationCreateReservationCommentArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { newDate } from "../../utils/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { CREATE_RESERVATION_COMMENT_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import format from "date-fns/format";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { updateCacheReservationStampsMutation } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";
import { useGetReservationCommentsQuery } from "../../apollo/queries/reservations.generated";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";

interface Props extends WithStyles<typeof styles> {
  reservationId: string;
}

function WorkQueueComments({ classes, reservationId }: Props) {
  const { t } = useTranslation();

  const [comment, setComment] = useState("");

  const { loading, error, data } = useGetReservationCommentsQuery({
    variables: {
      reservationId: reservationId,
    },
  });

  const [createReservationComment, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateReservationCommentArgs
  >(CREATE_RESERVATION_COMMENT_MUTATION, {
    onCompleted: () => {
      setComment("");
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddComment = checkPermission(myPermissions, [
    "reservations.add_reservationcomment",
  ]);

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const sendNewComment = () => {
    createReservationComment({
      variables: {
        reservationId: reservationId,
        comment: comment,
      },
    });
  };

  return (
    <div className="cardWorkQueueComments">
      {data.reservationComments.map((reservationComment) => (
        <div key={reservationComment.id + "_"} className="cardWorkQueueComment">
          <div className="cardWorkQueueCommentUser">
            {reservationComment.createdBy && (
              <span className="user me-2">
                {reservationComment.createdBy.lastName +
                  " " +
                  reservationComment.createdBy.firstName}
              </span>
            )}
            <span className="text-muted">
              {format(
                newDate(reservationComment.createdAt),
                t("format_datetime")
              )}
            </span>
          </div>
          {reservationComment.comment}
        </div>
      ))}
      {hasPermissionAddComment && (
        <div className="cardWorkQueueComment new">
          <TextField
            label={t("new_reservation_comment")}
            fullWidth
            value={comment}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <ButtonLoad
                  loading={loadingCreate}
                  variant="light"
                  onClick={sendNewComment}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </ButtonLoad>
              ),
            }}
            inputProps={{
              maxLength: 200,
            }}
            onChange={(event) => setComment(event.currentTarget.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                sendNewComment();
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueComments);
