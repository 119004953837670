import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Box, createStyles, Tab, Tabs, Theme } from "@material-ui/core";
import { CatalogCategoryUpperType, CatalogType } from "../../../entity/types";
import { Alert, Table } from "react-bootstrap";
import CatalogCategoryUpper from "./CatalogCategoryUpper";
import { isLocked } from "../../../utils/catalogs/locked";
import CatalogSort from "../CatalogSort";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function Catalog({ classes, catalog }: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState(0);

  const renderSwitch = () => {
    switch (tabSelected) {
      case 0:
        return (
          <div>
            <Table className="tblCatalog" borderless>
              <thead>
                <tr className="text-muted">
                  <th>
                    {catalog.catalogSource && (
                      <small>
                        {t("catalog_source_is", {
                          name: catalog.catalogSource.name,
                        })}
                      </small>
                    )}
                  </th>
                  <th>{t("company")}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>{t("private")}</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  {!isLocked(catalog) && <th></th>}
                  <th></th>
                </tr>
              </thead>
            </Table>
            <div>
              {catalog.catalogcategoryupperSet.map(
                (catalogCategoryUpper: CatalogCategoryUpperType) => (
                  <CatalogCategoryUpper
                    key={catalogCategoryUpper.id}
                    catalogCategoriesUpper={catalog.catalogcategoryupperSet}
                    catalogCategoryUpper={catalogCategoryUpper}
                    catalog={catalog}
                  />
                )
              )}
            </div>
          </div>
        );
      case 1:
        return <CatalogSort catalog={catalog} />;
      default:
        return <Alert variant="danger">{t("error_page_not_found")}</Alert>;
    }
  };

  const a11yProps = (index: number) => ({
    id: `tab-catalog-${index}`,
    "aria-controls": `tab-catalog-${index}`,
  });

  const handleChange = (event: React.ChangeEvent<{}>, newTab: number) => {
    setTabSelected(newTab);
  };

  return (
    <div>
      <Box className="mb-4">
        <Tabs
          value={tabSelected}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={t("catalog_rows")} {...a11yProps(0)} />
          <Tab label={t("sort_catalog")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      {renderSwitch()}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Catalog);
