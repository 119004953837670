import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { QueryMachineArgs } from "../../../entity/types";
import DialogTitleWithClose from "../../Shared/DialogTitleWithClose";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_QUERY,
  QueryResultMachine,
} from "../../../apollo/queries/machines";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import Error from "../../Shared/Error";
import LoadingDialog from "../../Shared/LoadingDialog";
import DialogContentMachineInfo from "./DialogContentMachineInfo";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machineId: string;
}

function DialogMachineInfo({ classes, open, setOpen, machineId }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultMachine,
    QueryMachineArgs
  >(GET_MACHINE_QUERY, {
    variables: {
      machineId: machineId,
    },
    fetchPolicy: getQueryFetchPolicy("machine"),
  });

  if (loading) return <LoadingDialog />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const machine = data.machine;

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose id="dialogTitleMachineInfo" onClose={onClickClose}>
        {t("machine_information")}
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentMachineInfo machine={machine} editable={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogMachineInfo);
