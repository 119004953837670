import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineType,
  QueryMachineBreakdownsForMachineArgs,
  ReservationType,
} from "../../../entity/types";
import { useQuery } from "@apollo/client";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import { TabPanel } from "@material-ui/lab";
import LoadingSimple from "../../Shared/LoadingSimple";
import Error from "../../Shared/Error";
import {
  GET_RESERVATIONS_FOR_MACHINE_QUERY,
  QueryResultReservationsForMachine,
} from "../../../apollo/queries/reservations";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../../utils/TableProps";
import ButtonOrderReservation from "../../Shared/ButtonOrderReservation";
import { ID_EMPTY } from "../../../utils/constants";
import DialogOrderId from "../../Customer/DialogOrderId";
import { ProductCardTabs } from "./DialogMachineProductCard";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCardReservations({ classes, machine }: Props) {
  const { t } = useTranslation();

  const [openDialogOrder, setOpenDialogOrder] = useState(false);
  const [reservationIdSelected, setReservationIdSelected] = useState(ID_EMPTY);
  const [orderIdSelected, setOrderIdSelected] = useState(ID_EMPTY);

  const { loading, error, data } = useQuery<
    QueryResultReservationsForMachine,
    QueryMachineBreakdownsForMachineArgs
  >(GET_RESERVATIONS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationsForMachine"),
    variables: {
      machineId: machine.id,
    },
  });

  const myPermissions = useContext(PermissionsContext);

  const hasPermissionViewReservations = checkPermission(myPermissions, [
    "reservations.view_reservation",
  ]);

  if (loading) return <LoadingSimple />;
  if (error) <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  if (!hasPermissionViewReservations) {
    return null;
  }

  return (
    <TabPanel value={ProductCardTabs.Reservations}>
      <MaterialTable<ReservationType>
        style={getTableStyle()}
        title={""}
        icons={tableIcons}
        localization={GetTableLocalization(t)}
        columns={[
          {
            title: t("reservation_id_header"),
            field: "id",
            render: (reservation) => (
              <ButtonOrderReservation
                reservationId={reservation.id}
                callbackOnClick={() => {
                  setReservationIdSelected(reservation.id);
                  setOrderIdSelected(reservation.order.id);
                  setOpenDialogOrder(true);
                }}
              />
            ),
          },
          { title: t("customer"), field: "order.customer.name" },
          { title: t("date_rented"), field: "dateRented", type: "date" },
          { title: t("date_returned"), field: "dateReturned", type: "date" },
          { title: t("return_at"), field: "returnAt", type: "datetime" },
        ]}
        data={data.reservationsForMachine.map((o) => ({ ...o }))}
        options={getTableOptions()}
      />
      {openDialogOrder && (
        <DialogOrderId
          open={openDialogOrder}
          setOpen={setOpenDialogOrder}
          orderId={orderIdSelected}
          reservationIdSelected={reservationIdSelected}
        />
      )}
    </TabPanel>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TabMachineCardReservations);
