import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import {
  URL_SETTINGS_CATALOG,
  URL_SETTINGS_CATALOG_EXTRA_RENTAL,
  URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE,
  URL_SETTINGS_CATALOG_TRANSFER,
  URL_SETTINGS_COMMISSIONS,
  URL_SETTINGS_DISCOUNTS,
  URL_SETTINGS_HOLIDAYS,
  URL_SETTINGS_INSPECTION_RETURN,
  URL_SETTINGS_INSPECTION_GIVE,
  URL_SETTINGS_LOCATIONS,
  URL_SETTINGS_LOGS,
  URL_SETTINGS_MACHINE_INFORMATION,
  URL_SETTINGS_MACHINES,
  URL_SETTINGS_OTHER,
  URL_SETTINGS_PROFILES,
  URL_SETTINGS_USERS,
  URL_SETTINGS_DIMENSIONS,
  URL_SETTINGS_CUSTOMER_AREAS,
  URL_SETTINGS_CUSTOMER_RANKS,
  URL_SETTINGS_CUSTOMER_INDUSTRIES,
} from "../../utils/urls";
import { AuthType } from "../../Root";

type Props = {
  classes: ClassNameMap;
  Auth: AuthType;
};

const AppButtonSettings = ({ classes, Auth }: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LinkSettingsProfiles = Auth.Profiles(() => {
    return (
      <LinkContainer to={URL_SETTINGS_PROFILES}>
        <MenuItem onClick={handleClose}>{t("profiles")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsUsers = Auth.Users(() => {
    return (
      <LinkContainer to={URL_SETTINGS_USERS}>
        <MenuItem onClick={handleClose}>{t("users")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCommissions = Auth.SettingsCommissions(() => {
    return (
      <LinkContainer to={URL_SETTINGS_COMMISSIONS}>
        <MenuItem onClick={handleClose}>{t("commissions")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsLogs = Auth.Logs(() => {
    return (
      <LinkContainer to={URL_SETTINGS_LOGS}>
        <MenuItem onClick={handleClose}>{t("logs")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsHolidays = Auth.Holidays(() => {
    return (
      <LinkContainer to={URL_SETTINGS_HOLIDAYS}>
        <MenuItem onClick={handleClose}>{t("holidays")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsLocations = Auth.Locations(() => {
    return (
      <LinkContainer to={URL_SETTINGS_LOCATIONS}>
        <MenuItem onClick={handleClose}>{t("locations")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalog = Auth.Catalogs(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG}>
        <MenuItem onClick={handleClose}>{t("catalog")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogExtraRental = Auth.CatalogsExtraRental(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG_EXTRA_RENTAL}>
        <MenuItem onClick={handleClose}>{t("catalog_extra_rental")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogExtraUnitPrice = Auth.CatalogsExtraUnitPrice(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE}>
        <MenuItem onClick={handleClose}>
          {t("catalog_extra_unit_price")}
        </MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCatalogTransfer = Auth.CatalogsTransfer(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CATALOG_TRANSFER}>
        <MenuItem onClick={handleClose}>{t("catalog_transfer")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsMachines = Auth.Machines(() => {
    return (
      <LinkContainer to={URL_SETTINGS_MACHINES}>
        <MenuItem onClick={handleClose}>{t("machines")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsMachineInformation = Auth.MachineInformation(() => {
    return (
      <LinkContainer to={URL_SETTINGS_MACHINE_INFORMATION}>
        <MenuItem onClick={handleClose}>{t("machine_information")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsInspectionReturn = Auth.InspectionReturn(() => {
    return (
      <LinkContainer to={URL_SETTINGS_INSPECTION_RETURN}>
        <MenuItem onClick={handleClose}>{t("inspection_return")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsInspectionGive = Auth.InspectionGive(() => {
    return (
      <LinkContainer to={URL_SETTINGS_INSPECTION_GIVE}>
        <MenuItem onClick={handleClose}>{t("inspection_give")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsDimensions = Auth.Dimensions(() => {
    return (
      <LinkContainer to={URL_SETTINGS_DIMENSIONS}>
        <MenuItem onClick={handleClose}>{t("dimensions")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCustomerAreas = Auth.SettingsCustomerAreas(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CUSTOMER_AREAS}>
        <MenuItem onClick={handleClose}>{t("customer_areas")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCustomerRanks = Auth.SettingsCustomerRanks(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CUSTOMER_RANKS}>
        <MenuItem onClick={handleClose}>{t("customer_ranks")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsCustomerIndustries = Auth.SettingsCustomerIndustries(() => {
    return (
      <LinkContainer to={URL_SETTINGS_CUSTOMER_INDUSTRIES}>
        <MenuItem onClick={handleClose}>{t("customer_industries")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsSettingsOther = Auth.SettingsOther(() => {
    return (
      <LinkContainer to={URL_SETTINGS_OTHER}>
        <MenuItem onClick={handleClose}>{t("settings_other")}</MenuItem>
      </LinkContainer>
    );
  });
  const LinkSettingsDiscounts = Auth.Discounts(() => {
    return (
      <LinkContainer to={URL_SETTINGS_DISCOUNTS}>
        <MenuItem onClick={handleClose}>{t("discounts")}</MenuItem>
      </LinkContainer>
    );
  });

  return (
    <div>
      <Button
        className={classes.btn}
        color="primary"
        aria-controls="menuSettings"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div className={classes.faIconCon}>
          <FontAwesomeIcon size="lg" icon={faCog} />
        </div>
        <div>{t("settings")}</div>
      </Button>
      <Menu
        id="menuSettings"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <LinkSettingsProfiles />
        <LinkSettingsCommissions />
        <LinkSettingsUsers />
        <LinkSettingsLogs />
        <LinkSettingsHolidays />
        <LinkSettingsLocations />
        <LinkSettingsCatalog />
        <LinkSettingsCatalogExtraRental />
        <LinkSettingsCatalogExtraUnitPrice />
        <LinkSettingsCatalogTransfer />
        <LinkSettingsDiscounts />
        <LinkSettingsMachines />
        <LinkSettingsMachineInformation />
        <LinkSettingsInspectionReturn />
        <LinkSettingsInspectionGive />
        <LinkSettingsDimensions />
        <LinkSettingsCustomerAreas />
        <LinkSettingsCustomerRanks />
        <LinkSettingsCustomerIndustries />
        <LinkSettingsSettingsOther />
      </Menu>
    </div>
  );
};

export default AppButtonSettings;
