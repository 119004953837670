import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { CustomerType, OrderType } from "../../entity/types";
import { CustomerEmpty } from "../../entity/empties";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogCustomer from "./DialogCustomer";
import DialogCustomerSelectType from "./DialogCustomerSelectType";
import { ID_EMPTY } from "../../utils/constants";
import { useLazyQuery } from "@apollo/client";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_CUSTOMER_QUERY,
  QueryResultCustomer,
} from "../../apollo/queries/customers";
import DialogCustomerSub from "./DialogCustomerSub";
import { handleError } from "../../entity/ErrorHandler";
import { getCustomerEmptyWithOwner } from "../../utils/customers/customer";
import { useTranslation } from "react-i18next";
import DialogCustomerEvent from "./DialogCustomerEvent";

interface Props extends WithStyles<typeof styles> {
  btnClass: string;
  order?: OrderType;
  callbackCreateCustomer?: Function;
  showEventOption: boolean;
}

function CreateCustomer({
  classes,
  btnClass,
  order,
  callbackCreateCustomer,
  showEventOption,
}: Props) {
  const { t } = useTranslation();
  const user = useContext(UserContext);

  const [openCreate, setOpenCreate] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [openDialogEvent, setOpenDialogEvent] = useState(false);
  const [customer, setCustomer] = useState<CustomerType>(
    getCustomerEmptyWithOwner(user)
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddCustomer = checkPermission(myPermissions, [
    "customers.add_customer",
  ]);
  const hasPermissionAddEvent = checkPermission(myPermissions, [
    "events.add_event",
  ]);

  const [getParent, { data: searchParent }] = useLazyQuery<QueryResultCustomer>(
    GET_CUSTOMER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customer"),
      variables: {
        id: ID_EMPTY,
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  if (!hasPermissionAddCustomer) {
    return <></>;
  }

  const handleSetParent = (parentId: string) => {
    getParent({
      variables: {
        id: parentId,
      },
    });
  };

  const handleNext = () => {
    setOpenSelect(false);
    setOpenCreate(true);
  };

  const isChild = () => {
    if (searchParent?.customer) {
      if (searchParent.customer.id !== ID_EMPTY) {
        return true;
      }
    }
    return false;
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        onClick={(event) => {
          if (showEventOption) {
            setAnchorEl(event.currentTarget);
          } else {
            setOpenSelect(true);
          }
        }}
        className={btnClass}
        variant="primary"
        size="lg"
      >
        {openSelect || openCreate ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Menu
        id="menuCustomer"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {hasPermissionAddCustomer && (
          <MenuItem
            onClick={() => {
              setOpenSelect(true);
              handleCloseMenu();
            }}
          >
            {t("new_customer")}
          </MenuItem>
        )}
        {hasPermissionAddEvent && (
          <MenuItem
            onClick={() => {
              setOpenDialogEvent(true);
              handleCloseMenu();
            }}
          >
            {t("new_event")}
          </MenuItem>
        )}
      </Menu>
      {openSelect && (
        <DialogCustomerSelectType
          open={openSelect}
          handleNext={handleNext}
          setOpenSelect={setOpenSelect}
          setParent={handleSetParent}
        />
      )}
      {openCreate && !isChild() && (
        <DialogCustomer
          customer={customer}
          setCustomer={setCustomer}
          open={openCreate}
          setOpen={setOpenCreate}
          order={order}
          callbackCreateCustomer={callbackCreateCustomer}
        />
      )}
      {openCreate && isChild() && (
        <DialogCustomerSub
          open={openCreate}
          setOpen={setOpenCreate}
          customerParent={
            searchParent?.customer ? searchParent.customer : CustomerEmpty
          }
          callbackCreateCustomer={callbackCreateCustomer}
        />
      )}
      {openDialogEvent && (
        <DialogCustomerEvent
          open={openDialogEvent}
          setOpen={setOpenDialogEvent}
          customer={CustomerEmpty}
        />
      )}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateCustomer);
