import { gql } from "@apollo/client";
import { Query } from "../../entity/types";
import {
  GetReservationForAddingImagesQuery,
  GetReservationsForOrderQueryQuery,
  GetReservationsMachinesQuery,
  GetReservationsQuery,
  GetReservationsWorkQueuesQueryQuery,
  GetReservationsWorkQueuesReturnQueryQuery,
} from "./reservations.generated";

export const FRAG_RESERVATION_FULL = gql`
  fragment ReservationFieldsFull on ReservationType {
    id
    order {
      id
    }
    machine {
      id
    }
    catalogRow {
      id
    }
    catalogExtraRowRental {
      id
    }
    reservationSource {
      id
    }
    albumGive {
      id
    }
    albumReturn {
      id
    }
    userGive {
      id
    }
    userReturn {
      id
    }
    dateRented
    timeRented
    dateReturned
    timeReturned
    dateBillingMin
    information
    deliveryMethod
    status
    bulkAmount
    bulkAmountReturned
    discountPercentDay
    discountPercentMonth
    estimatedMachineHoursPerDay
    giveCheckedAt
    giveImagesAt
    giveInstructedAt
    giveAt
    returnAt
    returnImagesAt
    returnCheckedCatalogAt
    returnCheckedPreAt
    returnCheckedAt
    returnStoredAt
    billingDaysWeekCompany
    billingDaysWeekPrivate
    hourLimitDayCompany
    hourLimitDayPrivate
    hourLimitMonthCompany
    hourLimitMonthPrivate
    reservationcommentSet {
      id
    }
    invoicerowSet {
      id
    }
    catalogextrarowinvoiceSet {
      id
    }
    reservationsSourced {
      id
    }
    operationhourSet {
      id
    }
    transportSet {
      id
    }
    drivecontentSet {
      id
    }
    machinebulkbreakdownSet {
      id
    }
    inspectionreturnanswerSet {
      id
    }
    inspectiongiveanswerSet {
      id
    }
    reservationimagebasicSet {
      id
    }
    commissionreportrowSet {
      id
    }
    createdAt
    createdBy {
      id
    }
  }
`;

export const FRAG_RESERVATION_SHARED = gql`
  fragment ReservationFields on ReservationType {
    id
    status
    dateRented
    timeRented
    dateReturned
    timeReturned
    dateBillingMin
    bulkAmount
    bulkAmountReturned
    discountPercentDay
    discountPercentMonth
    estimatedMachineHoursPerDay
  }
`;

export const FRAG_RESERVATION_FIELDS_FOR_TABLE = gql`
  fragment ReservationFieldsForTable on ReservationType {
    id
    machine {
      id
      information
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
    catalogRow {
      id
      name
    }
    catalogExtraRowRental {
      id
      identifier
      name
      catalogExtraCategoryRental {
        id
        name
      }
    }
    dateRented
    dateReturned
    giveAt
    returnAt
    createdAt
  }
`;

export type ReservationForAddingImagesType =
  GetReservationForAddingImagesQuery["reservation"];
export const GET_RESERVATION_FOR_ADDING_IMAGES = gql`
  query getReservationForAddingImages($id: ID!) {
    reservation(id: $id) {
      id
      giveCheckedAt
      giveImagesAt
      giveInstructedAt
      giveAt
      returnAt
      returnImagesAt
      returnCheckedCatalogAt
      returnCheckedPreAt
      returnCheckedAt
      returnStoredAt
      status
      userGive {
        id
      }
      userReturn {
        id
      }
      albumGive {
        id
      }
      albumReturn {
        id
      }
    }
  }
`;

export type ReservationFromReservationsMachinesType =
  GetReservationsMachinesQuery["reservations"][0];
export const GET_RESERVATIONS_MACHINES = gql`
  query getReservationsMachines(
    $dateFrom: Date!
    $dateTo: Date!
    $includeNotReturned: Boolean!
  ) {
    reservations(
      dateFrom: $dateFrom
      dateTo: $dateTo
      includeNotReturned: $includeNotReturned
    ) {
      id
      machine {
        id
      }
    }
  }
`;

export type ReservationFromReservationsType =
  GetReservationsQuery["reservations"][0];
export const GET_RESERVATIONS = gql`
  query getReservations(
    $dateFrom: Date!
    $dateTo: Date!
    $includeNotReturned: Boolean!
    $catalogRowId: ID
    $catalogExtraRowRentalId: ID
    $machineId: ID
  ) {
    reservations(
      dateFrom: $dateFrom
      dateTo: $dateTo
      includeNotReturned: $includeNotReturned
      catalogRowId: $catalogRowId
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      machineId: $machineId
    ) {
      id
      dateRented
      dateReturned
    }
  }
`;

export interface QueryResultReservationsForMachine {
  reservationsForMachine: Query["reservationsForMachine"];
}
export const GET_RESERVATIONS_FOR_MACHINE_QUERY = gql`
  query getReservationsForMachineQuery($machineId: ID!) {
    reservationsForMachine(machineId: $machineId) {
      id
      dateRented
      dateReturned
      giveAt
      order {
        id
        customer {
          id
          name
        }
      }
      returnAt
    }
  }
`;

export type ReservationFromForOrderType =
  GetReservationsForOrderQueryQuery["reservationsForOrder"][0];
export const GET_RESERVATIONS_FOR_ORDER_QUERY = gql`
  query getReservationsForOrderQuery($orderId: ID!) {
    reservationsForOrder(orderId: $orderId) {
      id
      bulkAmount
      bulkAmountReturned
      giveAt
      returnAt
      catalogExtraRowRental {
        id
        identifier
        name
        location {
          id
        }
      }
      machine {
        id
        bulkProduct
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
        identifier
      }
      order {
        id
      }
    }
  }
`;

export type ReservationFromReservationsWorkQueuesType =
  GetReservationsWorkQueuesQueryQuery["reservationsWorkQueues"][0];
export type OrderFromReservationsWorkQueuesType =
  ReservationFromReservationsWorkQueuesType["order"];
export type MachineFromReservationsWorkQueuesType =
  ReservationFromReservationsWorkQueuesType["machine"];
export const GET_RESERVATIONS_WORK_QUEUES_QUERY = gql`
  query getReservationsWorkQueuesQuery($locationIds: [ID]!, $userId: ID) {
    reservationsWorkQueues(locationIds: $locationIds, userId: $userId) {
      id
      status
      dateRented
      timeRented
      dateReturned
      timeReturned
      dateBillingMin
      bulkAmount
      bulkAmountReturned
      information
      userGive {
        id
        firstName
        lastName
      }
      userReturn {
        id
        firstName
        lastName
      }
      giveCheckedAt
      giveImagesAt
      giveInstructedAt
      giveAt
      returnAt
      returnImagesAt
      returnCheckedCatalogAt
      returnCheckedPreAt
      returnCheckedAt
      returnStoredAt
      catalogExtraRowRental {
        id
        identifier
        name
        location {
          id
        }
      }
      reservationcommentSet {
        id
      }
      machine {
        id
        hourTracking
        bulkProduct
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
        identifier
        location {
          id
          name
        }
      }
      order {
        id
        customer {
          id
          name
        }
        location {
          id
          name
        }
      }
    }
  }
`;

export const GET_RESERVATION_COMMENTS = gql`
  query getReservationComments($reservationId: ID!) {
    reservationComments(reservationId: $reservationId) {
      id
      comment
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
`;

export interface QueryResultReservationsRentedOut {
  reservationsRentedOut: Query["reservationsRentedOut"];
}
export const GET_RESERVATIONS_RENTED_OUT_QUERY = gql`
  ${FRAG_RESERVATION_FIELDS_FOR_TABLE}
  query getReservationsRentedOutQuery($locationIds: [ID]!) {
    reservationsRentedOut(locationIds: $locationIds) {
      ...ReservationFieldsForTable
      order {
        id
        reference {
          id
          name
        }
        status
        createdAt
      }
    }
  }
`;

export type ReservationFromReservationsQueueReturnType =
  GetReservationsWorkQueuesReturnQueryQuery["reservationsWorkQueuesReturn"][0];
// This query has been checked for minimal required fields 2023-05-05
export const GET_RESERVATIONS_WORK_QUEUES_RETURN_QUERY = gql`
  query getReservationsWorkQueuesReturnQuery {
    reservationsWorkQueuesReturn {
      id
      bulkAmount
      bulkAmountReturned
      returnAt
      catalogExtraRowRental {
        id
        identifier
        name
        location {
          id
        }
      }
      machine {
        id
        identifier
        bulkProduct
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
        location {
          id
          name
        }
      }
      order {
        id
        customer {
          id
          name
        }
      }
    }
  }
`;

export interface QueryResultReservationPrice {
  reservationPrice: Query["reservationPrice"];
}
export const GET_RESERVATION_PRICE_QUERY = gql`
  query getReservationPriceQuery($reservationIds: [ID], $dateTo: Date) {
    reservationPrice(reservationIds: $reservationIds, dateTo: $dateTo) {
      reservationId
      total
      totalUninvoiced
      totalReservation
      totalReservationWithoutDiscount
      invoicePeriodStart
      invoicePeriodEnd
      total
      isDayPrice
    }
  }
`;

export interface QueryResultReservationEditableDates {
  reservationEditableDates: Query["reservationEditableDates"];
}
export const GET_RESERVATION_EDITABLE_DATES_QUERY = gql`
  query getReservationEditableDatesQuery($reservationIds: [ID]) {
    reservationEditableDates(reservationIds: $reservationIds) {
      dateStart
      dateEnd
    }
  }
`;

export interface QueryResultInvoicingPriceForReservation {
  invoicingPriceForReservation: Query["invoicingPriceForReservation"];
}
export const GET_INVOICING_PRICE_FOR_RESERVATION_QUERY = gql`
  query getInvoicingPriceForReservation($reservationId: ID!, $date: Date!) {
    invoicingPriceForReservation(reservationId: $reservationId, date: $date) {
      total
      isDayPrice
      days
      months
    }
  }
`;

export interface QueryResultReservationImageGives {
  reservationImageGives: Query["reservationImageGives"];
}
export const GET_RESERVATION_IMAGE_GIVES_QUERY = gql`
  query getReservationImageGives($reservationId: ID!) {
    reservationImageGives(reservationId: $reservationId) {
      id
      image
      createdAt
    }
  }
`;

export interface QueryResultReservationImageReturns {
  reservationImageReturns: Query["reservationImageReturns"];
}
export const GET_RESERVATION_IMAGE_RETURNS_QUERY = gql`
  query getReservationImageReturns($reservationId: ID!) {
    reservationImageReturns(reservationId: $reservationId) {
      id
      image
      createdAt
    }
  }
`;

export interface QueryResultReservationImageBasics {
  reservationImageBasics: Query["reservationImageBasics"];
}
export const GET_RESERVATION_IMAGE_BASICS_QUERY = gql`
  query getReservationImageBasics($reservationId: ID!) {
    reservationImageBasics(reservationId: $reservationId) {
      id
      image
      information
      shootingDate
      createdAt
    }
  }
`;

export interface QueryResultReservationsSearch {
  reservationsSearch: Query["reservationsSearch"];
}
export const RESERVATIONS_SEARCH_QUERY = gql`
  query ReservationsSearchQuery($search: String!) {
    reservationsSearch(search: $search) {
      id
      bulkAmount
      bulkAmountReturned
      order {
        id
        customer {
          id
          name
        }
      }
      catalogExtraRowRental {
        id
        identifier
        name
      }
      machine {
        id
        identifier
        bulkProduct
        machineModel {
          id
          title
          machineMake {
            id
            title
          }
        }
      }
    }
  }
`;
export interface QueryResultReportPrices {
  reportPrices: Query["reportPrices"];
}
export const GET_REPORT_PRICES_QUERY = gql`
  query getReportPriceQuery($dateFrom: Date!, $dateTo: Date!) {
    reportPrices(dateFrom: $dateFrom, dateTo: $dateTo) {
      order {
        id
      }
      reservation {
        id
      }
      dateStart
      dateEnd
      title
      useDayPrice
      dayPrice
      reportpricemonthSet {
        id
        month
        pricePerDay
      }
      reportpricedayskippedSet {
        id
        dateSkipped
      }
    }
  }
`;

export interface QueryResultReservationsOverlappingWithOrder {
  reservationsOverlappingWithOrder: Query["reservationsOverlappingWithOrder"];
}
export const GET_RESERVATIONS_OVERLAPPING_WITH_ORDER_QUERY = gql`
  ${FRAG_RESERVATION_SHARED}
  query getReservationsOverlappingWithOrderQuery($orderId: ID!) {
    reservationsOverlappingWithOrder(orderId: $orderId) {
      ...ReservationFields
      machine {
        id
        bulkProduct
      }
    }
  }
`;
