import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  Mutation,
  MutationUpdateCustomerParentArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CUSTOMERS_PRIMARY_QUERY,
  QueryResultCustomersPrimary,
} from "../../apollo/queries/customers";
import Error from "../Shared/Error";
import { ID_EMPTY } from "../../utils/constants";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { CustomerEmpty } from "../../entity/empties";
import { Button } from "react-bootstrap";
import { UPDATE_CUSTOMER_PARENT_MUTATION } from "../../apollo/mutations/customers";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { Autocomplete } from "@material-ui/lab";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogCustomerParent({ classes, customer, open, setOpen }: Props) {
  const { t } = useTranslation();

  const [customerEdited, setCustomerEdited] = useState(customer);

  const { loading, error, data } = useQuery<QueryResultCustomersPrimary>(
    GET_CUSTOMERS_PRIMARY_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customersPrimary"),
    }
  );

  const [updateCustomerParent, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCustomerParentArgs
  >(UPDATE_CUSTOMER_PARENT_MUTATION, {
    refetchQueries: [{ query: GET_CUSTOMERS_PRIMARY_QUERY }],
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOpen(false);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customer",
  ]);

  if (!loading && error) return <Error error={error} />;
  if (!loading && !data) return <Error error={t("error_query_failed")} />;

  const getLabel = (option: CustomerType) => {
    return option.businessId + " " + option.name;
  };

  const onclickSave = () => {
    updateCustomerParent({
      variables: {
        customerId: customerEdited.id,
        customerIdParent: customerEdited.customerParent
          ? customerEdited.customerParent.id
          : ID_EMPTY,
      },
    });
  };

  const customersParent: CustomerType[] = (
    data && data.customersPrimary ? data.customersPrimary : []
  ).filter((customerLooped) => customerLooped.id !== customer.id);

  return (
    <Dialog open={open}>
      <DialogTitle>{t("customer_parent")}</DialogTitle>
      <DialogContent className={loading ? "loading" : ""}>
        <p className="text-muted">
          {customerEdited.businessId} {customerEdited.name}
        </p>
        <h6 id="lblCustomerParent">{t("customer_family_head")}</h6>
        <FormControl fullWidth>
          <Autocomplete
            className="mt-2 mb-2 flex-grow-1"
            options={customersParent}
            disabled={!hasPermissionEdit}
            filterOptions={(options, search) => {
              const keyWords = search.inputValue.split(" ");
              return options.filter((option) => {
                return keyWords.every((item) => {
                  return getLabel(option)
                    .toLowerCase()
                    .includes(item.toLowerCase());
                });
              });
            }}
            getOptionLabel={(option: CustomerType) => getLabel(option)}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
            value={
              customerEdited.customerParent
                ? customerEdited.customerParent
                : null
            }
            getOptionSelected={(a, b) => {
              return a.id === b.id;
            }}
            onChange={(event, customerSelected) => {
              const customerParent = customerSelected
                ? customerSelected
                : CustomerEmpty;

              setCustomerEdited({
                ...customerEdited,
                customerParent: {
                  ...customerParent,
                  id: String(customerParent.id),
                },
              });
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <ButtonLoad
          loading={loadingUpdate}
          onClick={onclickSave}
          variant="primary"
        >
          {t("save")}
        </ButtonLoad>
        <Button onClick={() => setOpen(false)} variant="secondary">
          {t("cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCustomerParent);
