import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionGiveCategoryType,
  InspectionsInspectionGiveQuestionAnswerTypeChoices,
  InspectionGiveQuestionType,
  InspectionGiveTagType,
} from "../../entity/types";
import CreateInspectionGiveQuestion from "./CreateInspectionGiveQuestion";
import InspectionGiveQuestion from "./InspectionGiveQuestion";
import InspectionGiveQuestionOptions from "./InspectionGiveQuestionOptions";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveCategory: InspectionGiveCategoryType;
  inspectionGiveQuestions: InspectionGiveQuestionType[];
  inspectionGiveTags: InspectionGiveTagType[];
}

function InspectionGiveCategoryQuestions({
  classes,
  inspectionGiveCategory,
  inspectionGiveQuestions,
  inspectionGiveTags,
}: Props) {
  return (
    <>
      <tbody>
        {inspectionGiveQuestions.map((inspectionGiveQuestion) => (
          <React.Fragment key={inspectionGiveQuestion.id}>
            <InspectionGiveQuestion
              inspectionGiveTags={inspectionGiveTags}
              inspectionGiveQuestion={inspectionGiveQuestion}
            />
            {inspectionGiveQuestion.answerType ===
              InspectionsInspectionGiveQuestionAnswerTypeChoices.Options && (
              <InspectionGiveQuestionOptions
                inspectionGiveQuestion={inspectionGiveQuestion}
              />
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <CreateInspectionGiveQuestion
              inspectionGiveCategory={inspectionGiveCategory}
            />
          </td>
        </tr>
      </tfoot>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionGiveCategoryQuestions);
