import {
  TransportsTransportInvoicingMethodChoices,
  TransportsTransportInvoicingRowTypeChoices,
  TransportInvoicingType,
} from "../../entity/types";
import { getPercentFactorDiscount } from "../calc";
import { TFunction } from "react-i18next";

export const getTransportInvoicingPrice = (
  transportInvoicing: TransportInvoicingType
) =>
  transportInvoicing.price *
  getPercentFactorDiscount(transportInvoicing.discountPercent);

export const getDescriptionTextTransport = (
  t: TFunction<"translation">,
  method: TransportsTransportInvoicingMethodChoices,
  description: string
) => t("transport_row_type_" + method) + ", " + description;

export const getTitleTextTransport = (
  t: TFunction<"translation">,
  rowType: TransportsTransportInvoicingRowTypeChoices,
  locationName: string
) => t("transport_row_type_" + rowType) + ", " + locationName;
