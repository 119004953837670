import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  InspectionGiveCategoryType,
  Mutation,
  MutationUpdateInspectionGiveCategoryOrderArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { getCatalogExtraSortStyles } from "../../utils/catalog_extra/catalog_extra";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { UPDATE_INSPECTION_GIVE_CATEGORY_ORDER_MUTATION } from "../../apollo/mutations/inspections";
import { IdToIndexType } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveCategories: InspectionGiveCategoryType[];
}

function InspectionGiveSortCategory({
  classes,
  inspectionGiveCategories,
}: Props) {
  const { t } = useTranslation();

  const [updateInspectionGiveCategoryOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateInspectionGiveCategoryOrderArgs
  >(UPDATE_INSPECTION_GIVE_CATEGORY_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("inspectionGiveCategories"),
      });
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let idToIndex: IdToIndexType[] = [];
    arrayMove(inspectionGiveCategories, oldIndex, newIndex).map(
      (inspectionGiveCategory: InspectionGiveCategoryType, index) => {
        idToIndex.push({
          id: inspectionGiveCategory.id,
          order: index,
        });
        return null;
      }
    );
    updateInspectionGiveCategoryOrder({
      variables: { idOrderInput: idToIndex },
    });
  };

  const DragHandleCategoryUpper = SortableHandle(() => (
    <span className={classes.elSort}>
      <FontAwesomeIcon icon={faArrowsV} size="lg" />
    </span>
  ));

  const CategoryUpperContainer = SortableElement(
    ({ inspectionGiveCategory }: any) => (
      <div key={inspectionGiveCategory.id} className="mb-3">
        <DragHandleCategoryUpper />
        {inspectionGiveCategory.name}
      </div>
    )
  );

  const CategoriesUpperContainer = SortableContainer(({ items }: any) => {
    return (
      <div className={loading ? "loading" : ""}>
        {items.map(
          (
            inspectionGiveCategory: InspectionGiveCategoryType,
            index: number
          ) => (
            <CategoryUpperContainer
              key={inspectionGiveCategory.id}
              index={index}
              inspectionGiveCategory={inspectionGiveCategory}
            />
          )
        )}
      </div>
    );
  });

  return (
    <div>
      <h5 className="mb-4">{t("sort_catalog_extra_categories_upper")}</h5>
      <CategoriesUpperContainer
        items={inspectionGiveCategories}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    elSort: getCatalogExtraSortStyles(theme),
  });

export default withStyles(styles)(InspectionGiveSortCategory);
