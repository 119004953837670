import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import {
  GET_CATALOG_WITH_CATS_QUERY,
  QueryResultCatalogWithCats,
} from "../apollo/queries/catalogs";
import { getCatalogIdActive } from "../utils/catalogs/selected_catalog";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CalendarSelectRow from "../components/Calendar/CalendarSelectRow";
import { QueryCatalogArgs } from "../entity/types";
import { CatalogEmpty } from "../entity/empties";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";
import { SettingsContext } from "../Root";

interface Props extends WithStyles<typeof styles> {}

function Calendar({ classes }: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const catalogId = getCatalogIdActive(settings);

  const { loading, error, data } = useQuery<
    QueryResultCatalogWithCats,
    QueryCatalogArgs
  >(GET_CATALOG_WITH_CATS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalog"),
    variables: {
      catalogId: catalogId,
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container className="containerMain">
      <HeaderPage title={t("calendar")} />
      <div className={classes.containerInner}>
        <CalendarSelectRow
          catalog={data.catalog ? data.catalog : CatalogEmpty}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    containerInner: {
      paddingTop: "2vh",
    },
  });

export default withStyles(styles)(Calendar);
