import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export const CREATE_CATALOG_MUTATION = gql`
  mutation createCatalog($name: String!, $catalogIdBase: ID) {
    createCatalog(name: $name, catalogIdBase: $catalogIdBase) {
      catalog {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_MUTATION = gql`
  mutation updateCatalog(
    $catalogId: ID!
    $name: String!
    $setActiveAt: DateTime
  ) {
    updateCatalog(
      catalogId: $catalogId
      name: $name
      setActiveAt: $setActiveAt
    ) {
      catalog {
        id
        name
        setActiveAt
      }
    }
  }
`;

export const DELETE_CATALOG_MUTATION = gql`
  mutation deleteCatalog($catalogId: ID!) {
    deleteCatalog(catalogId: $catalogId) {
      catalogId
    }
  }
`;

export const CREATE_CATALOG_CATEGORY_UPPER_MUTATION = gql`
  mutation createCatalogCategoryUpper($name: String!, $catalogId: ID!) {
    createCatalogCategoryUpper(name: $name, catalogId: $catalogId) {
      catalogCategoryUpper {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_CATEGORY_UPPER_MUTATION = gql`
  mutation updateCatalogCategoryUpper(
    $catalogCategoryUpperId: ID!
    $name: String!
  ) {
    updateCatalogCategoryUpper(
      catalogCategoryUpperId: $catalogCategoryUpperId
      name: $name
    ) {
      catalogCategoryUpper {
        id
        name
      }
    }
  }
`;

export const CREATE_CATALOG_CATEGORY_MUTATION = gql`
  mutation createCatalogCategory(
    $name: String!
    $information: String
    $catalogCategoryUpperId: ID!
  ) {
    createCatalogCategory(
      name: $name
      information: $information
      catalogCategoryUpperId: $catalogCategoryUpperId
    ) {
      catalogCategory {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_CATEGORY_MUTATION = gql`
  mutation updateCatalogCategory(
    $catalogCategoryId: ID!
    $name: String!
    $information: String
    $catalogCategoryUpperId: ID!
  ) {
    updateCatalogCategory(
      catalogCategoryId: $catalogCategoryId
      name: $name
      information: $information
      catalogCategoryUpperId: $catalogCategoryUpperId
    ) {
      catalogCategory {
        id
        name
        information
        catalogCategoryUpper {
          id
        }
      }
    }
  }
`;

export const CREATE_CATALOG_ROW_MUTATION = gql`
  mutation createCatalogRow(
    $name: String!
    $catalogCategoryId: ID!
    $information: String
    $billingDaysWeekCompany: Int!
    $priceDayCompany: Decimal!
    $priceMonthCompany: Decimal!
    $billingDaysWeekPrivate: Int!
    $priceDayPrivate: Decimal!
    $priceMonthPrivate: Decimal!
    $hourLimitDayCompany: Int!
    $hourLimitDayPrivate: Int!
    $hourLimitMonthCompany: Int!
    $hourLimitMonthPrivate: Int!
    $extraHoursCompany: Decimal!
    $extraHoursPrivate: Decimal!
    $productNumberDay: String
    $productNumberMonth: String
    $machineIds: [ID]!
  ) {
    createCatalogRow(
      name: $name
      catalogCategoryId: $catalogCategoryId
      information: $information
      billingDaysWeekCompany: $billingDaysWeekCompany
      priceDayCompany: $priceDayCompany
      priceMonthCompany: $priceMonthCompany
      billingDaysWeekPrivate: $billingDaysWeekPrivate
      priceDayPrivate: $priceDayPrivate
      priceMonthPrivate: $priceMonthPrivate
      hourLimitDayCompany: $hourLimitDayCompany
      hourLimitDayPrivate: $hourLimitDayPrivate
      hourLimitMonthCompany: $hourLimitMonthCompany
      hourLimitMonthPrivate: $hourLimitMonthPrivate
      extraHoursCompany: $extraHoursCompany
      extraHoursPrivate: $extraHoursPrivate
      productNumberDay: $productNumberDay
      productNumberMonth: $productNumberMonth
      machineIds: $machineIds
    ) {
      catalogRow {
        id
      }
    }
  }
`;

export const UPDATE_CATALOG_ROW_MUTATION = gql`
  mutation updateCatalogRow(
    $catalogRowId: ID!
    $name: String
    $catalogCategoryId: ID
    $information: String
    $billingDaysWeekCompany: Int
    $priceDayCompany: Decimal
    $priceMonthCompany: Decimal
    $billingDaysWeekPrivate: Int
    $priceDayPrivate: Decimal
    $priceMonthPrivate: Decimal
    $hourLimitDayCompany: Int
    $hourLimitDayPrivate: Int
    $hourLimitMonthCompany: Int
    $hourLimitMonthPrivate: Int
    $extraHoursCompany: Decimal
    $extraHoursPrivate: Decimal
    $productNumberDay: String
    $productNumberMonth: String
    $machineIds: [ID]
  ) {
    updateCatalogRow(
      catalogRowId: $catalogRowId
      name: $name
      catalogCategoryId: $catalogCategoryId
      information: $information
      billingDaysWeekCompany: $billingDaysWeekCompany
      priceDayCompany: $priceDayCompany
      priceMonthCompany: $priceMonthCompany
      billingDaysWeekPrivate: $billingDaysWeekPrivate
      priceDayPrivate: $priceDayPrivate
      priceMonthPrivate: $priceMonthPrivate
      hourLimitDayCompany: $hourLimitDayCompany
      hourLimitDayPrivate: $hourLimitDayPrivate
      hourLimitMonthCompany: $hourLimitMonthCompany
      hourLimitMonthPrivate: $hourLimitMonthPrivate
      extraHoursCompany: $extraHoursCompany
      extraHoursPrivate: $extraHoursPrivate
      productNumberDay: $productNumberDay
      productNumberMonth: $productNumberMonth
      machineIds: $machineIds
    ) {
      catalogRow {
        id
        name
        catalogCategory {
          id
        }
        information
        billingDaysWeekCompany
        priceDayCompany
        priceMonthCompany
        billingDaysWeekPrivate
        priceDayPrivate
        priceMonthPrivate
        hourLimitDayCompany
        hourLimitDayPrivate
        hourLimitMonthCompany
        hourLimitMonthPrivate
        extraHoursCompany
        extraHoursPrivate
        productNumberDay
        productNumberMonth
        machineSet {
          id
        }
      }
    }
  }
`;

export const UPDATE_CATALOG_ROW_SOURCE_MUTATION = gql`
  mutation updateCatalogRowSource($catalogRowId: ID!, $catalogRowIdSource: ID) {
    updateCatalogRowSource(
      catalogRowId: $catalogRowId
      catalogRowIdSource: $catalogRowIdSource
    ) {
      catalogRow {
        id
        catalogRowSource {
          id
        }
      }
    }
  }
`;

export const UPDATE_CATALOG_ROW_LOCKED_MUTATION = gql`
  mutation updateCatalogRowLocked(
    $catalogRowId: ID!
    $name: String!
    $information: String
    $productNumberDay: String
    $productNumberMonth: String
  ) {
    updateCatalogRowLocked(
      catalogRowId: $catalogRowId
      name: $name
      information: $information
      productNumberDay: $productNumberDay
      productNumberMonth: $productNumberMonth
    ) {
      catalogRow {
        id
        name
        information
        productNumberDay
        productNumberMonth
      }
    }
  }
`;

export const DELETE_CATALOG_ROW_MUTATION = gql`
  mutation deleteCatalogRow($catalogRowId: ID!) {
    deleteCatalogRow(catalogRowId: $catalogRowId) {
      catalogRowId
    }
  }
`;

export const DELETE_CATALOG_CATEGORY_MUTATION = gql`
  mutation deleteCatalogCategory($catalogCategoryId: ID!) {
    deleteCatalogCategory(catalogCategoryId: $catalogCategoryId) {
      catalogCategoryId
    }
  }
`;

export const DELETE_CATALOG_CATEGORY_UPPER_MUTATION = gql`
  mutation deleteCatalogCategoryUpper($catalogCategoryUpperId: ID!) {
    deleteCatalogCategoryUpper(
      catalogCategoryUpperId: $catalogCategoryUpperId
    ) {
      catalogCategoryUpperId
    }
  }
`;

export const UPLOAD_CATALOG_CATEGORY_MUTATION = gql`
  mutation uploadCatalogCategory($catalogCategoryId: ID!, $file: Upload) {
    uploadCatalogCategory(catalogCategoryId: $catalogCategoryId, file: $file) {
      success
    }
  }
`;

export const UPLOAD_CATALOG_ROW_MUTATION = gql`
  mutation uploadCatalogRow($catalogRowId: ID!, $file: Upload) {
    uploadCatalogRow(catalogRowId: $catalogRowId, file: $file) {
      success
    }
  }
`;

export const UPDATE_CATALOG_CATEGORY_UPPER_ORDER_MUTATION = gql`
  mutation updateCatalogCategoryUpperOrder($idOrderInput: [IdOrderInputType]) {
    updateCatalogCategoryUpperOrder(idOrderInput: $idOrderInput) {
      catalogCategoriesUpper {
        id
        orderCategoryUpper
      }
    }
  }
`;

export const UPDATE_CATALOG_CATEGORY_ORDER_MUTATION = gql`
  mutation updateCatalogCategoryOrder($idOrderInput: [IdOrderInputType]) {
    updateCatalogCategoryOrder(idOrderInput: $idOrderInput) {
      catalogCategories {
        id
        orderCategory
      }
    }
  }
`;

export const UPDATE_CATALOG_ROW_ORDER_MUTATION = gql`
  mutation updateCatalogRowOrder($idOrderInput: [IdOrderInputType]) {
    updateCatalogRowOrder(idOrderInput: $idOrderInput) {
      catalogRows {
        id
        orderRow
      }
    }
  }
`;

export interface MutationResultOfferPdf {
  getOfferPdf: Mutation["getOfferPdf"];
}
export const GET_OFFER_PDF_MUTATION = gql`
  mutation getOfferPdf(
    $catalogId: ID
    $customerId: ID
    $discountListId: ID
    $catalogRowIdsPrinted: [ID]
  ) {
    getOfferPdf(
      catalogId: $catalogId
      customerId: $customerId
      discountListId: $discountListId
      catalogRowIdsPrinted: $catalogRowIdsPrinted
    ) {
      url
    }
  }
`;
