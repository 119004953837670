import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  createStyles,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import {
  InspectionGiveCategoryType,
  InspectionGiveTagType,
  MachineType,
  Mutation,
  MutationAddInspectionGiveTagToMachineArgs,
  MutationCopyInspectionGiveTagsArgs,
  MutationCreateInspectionGiveToMachineArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { findFromSetById } from "../../utils/collections";
import {
  InspectionGiveQuestionEmpty,
  InspectionGiveTagEmpty,
} from "../../entity/empties";
import {
  ADD_INSPECTION_GIVE_TAG_TO_MACHINE_MUTATION,
  COPY_INSPECTION_GIVE_TAGS_MUTATION,
  CREATE_INSPECTION_GIVE_TO_MACHINE_MUTATION,
} from "../../apollo/mutations/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { ID_EMPTY } from "../../utils/constants";
import { getMachineName } from "../../utils/machines/machine";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  machines: MachineType[];
  inspectionGiveTags: InspectionGiveTagType[];
  inspectionGiveCategories: InspectionGiveCategoryType[];
}

function DialogContentInspectionGiveActions({
  classes,
  machine,
  machines,
  inspectionGiveTags,
  inspectionGiveCategories,
}: Props) {
  const { t } = useTranslation();

  const [inspectionGiveTagIdsToAdd, setInspectionGiveTagIdsToAdd] = useState<
    string[]
  >([]);
  const [inspectionGiveQuestionIdsToAdd, setInspectionGiveQuestionIdsToAdd] =
    useState<string[]>([]);

  const [addInspectionGiveTagToMachine, resultAdd] = useMutation<
    Mutation,
    MutationAddInspectionGiveTagToMachineArgs
  >(ADD_INSPECTION_GIVE_TAG_TO_MACHINE_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onCompleted: () => {
      setInspectionGiveTagIdsToAdd([]);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [createInspectionGiveToMachine, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateInspectionGiveToMachineArgs>(
      CREATE_INSPECTION_GIVE_TO_MACHINE_MUTATION,
      {
        refetchQueries: [
          {
            query: GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY,
            variables: {
              machineId: machine.id,
            },
          },
        ],
        onCompleted: () => {
          setInspectionGiveQuestionIdsToAdd([]);
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [copyInspectionGiveTags, resultCopy] = useMutation<
    Mutation,
    MutationCopyInspectionGiveTagsArgs
  >(COPY_INSPECTION_GIVE_TAGS_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const handleAddTagsToMachine = () => {
    if (inspectionGiveTagIdsToAdd.length > 0) {
      addInspectionGiveTagToMachine({
        variables: {
          machineId: machine.id,
          inspectionGiveTagIds: inspectionGiveTagIdsToAdd,
        },
      });
    }
  };

  const handleAddQuestionToMachine = () => {
    if (inspectionGiveQuestionIdsToAdd.length > 0) {
      createInspectionGiveToMachine({
        variables: {
          machineId: machine.id,
          inspectionGiveQuestionIds: inspectionGiveQuestionIdsToAdd,
        },
      });
    }
  };

  const onChangeCopyInspectionGive = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    copyInspectionGiveTags({
      variables: {
        machineIdFrom: String(event.target.value),
        machineIdTo: machine.id,
      },
    });
  };

  const renderSelectGroup = (
    inspectionGiveCategories: InspectionGiveCategoryType[]
  ) => {
    return inspectionGiveCategories.map((inspectionGiveCategory) => {
      const itemsInner = inspectionGiveCategory.inspectiongivequestionSet.map(
        (inspectionGiveQuestion) => {
          return (
            <MenuItem
              key={inspectionGiveQuestion.id}
              value={inspectionGiveQuestion.id}
            >
              <Checkbox
                checked={
                  inspectionGiveQuestionIdsToAdd.indexOf(
                    inspectionGiveQuestion.id
                  ) > -1
                }
              />
              {inspectionGiveQuestion.question}
            </MenuItem>
          );
        }
      );

      return [
        <ListSubheader>{inspectionGiveCategory.name}</ListSubheader>,
        itemsInner,
      ];
    });
  };

  return (
    <Paper
      className={`${classes.paper} ${
        resultAdd.loading || resultCopy.loading ? "loading" : ""
      } mb-5`}
    >
      <FormControl fullWidth>
        <InputLabel id="lblDialogInspectionGiveTags">
          {t("inspections_from_tag")}
        </InputLabel>
        <Select
          value={inspectionGiveTagIdsToAdd}
          labelId="lblDialogInspectionGiveTags"
          multiple={true}
          renderValue={(value) => {
            let names: string[] = [];
            (value as string[]).forEach((id) => {
              const inspectionGiveTag = findFromSetById(
                id,
                inspectionGiveTags,
                InspectionGiveTagEmpty
              );
              if (inspectionGiveTag.id !== ID_EMPTY) {
                names.push(inspectionGiveTag.name);
              }
            });
            return names.join(", ");
          }}
          onChange={(event) => {
            setInspectionGiveTagIdsToAdd(event.target.value as string[]);
          }}
          onClose={() => {
            handleAddTagsToMachine();
          }}
        >
          {inspectionGiveTags.map((inspectionGiveTag) => (
            <MenuItem key={inspectionGiveTag.id} value={inspectionGiveTag.id}>
              <Checkbox
                checked={
                  inspectionGiveTagIdsToAdd.indexOf(inspectionGiveTag.id) > -1
                }
              />
              {inspectionGiveTag.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth className={loadingCreate ? "loading" : ""}>
        <InputLabel id="lblDialogInspectionGiveAdd">
          {t("add_inspection_questions")}
        </InputLabel>
        <Select
          labelId="lblDialogInspectionGiveAdd"
          value={inspectionGiveQuestionIdsToAdd}
          multiple={true}
          renderValue={(value) => {
            let names: string[] = [];
            (value as string[]).forEach((id) => {
              let inspectionGiveQuestion = InspectionGiveQuestionEmpty;
              inspectionGiveCategories.forEach((inspectionGiveCategory) => {
                const inspectionGiveQuestionFound = findFromSetById(
                  id,
                  inspectionGiveCategory.inspectiongivequestionSet,
                  InspectionGiveTagEmpty
                );
                if (inspectionGiveQuestionFound.id !== ID_EMPTY) {
                  inspectionGiveQuestion = inspectionGiveQuestionFound;
                  return;
                }
              });
              if (inspectionGiveQuestion.id !== ID_EMPTY) {
                names.push(inspectionGiveQuestion.question);
              }
            });
            return names.join(", ");
          }}
          onChange={(event) => {
            setInspectionGiveQuestionIdsToAdd(event.target.value as string[]);
          }}
          onClose={() => {
            handleAddQuestionToMachine();
          }}
        >
          {renderSelectGroup(inspectionGiveCategories)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblDialogInspectionGiveCopy">
          {t("add_inspection_copy")}
        </InputLabel>
        <Select
          value=""
          labelId="lblDialogInspectionGiveCopy"
          onChange={onChangeCopyInspectionGive}
        >
          {machines.map((machineLooped) => {
            if (machineLooped.id === machine.id) {
              return undefined;
            }
            return (
              <MenuItem key={machineLooped.id} value={machineLooped.id}>
                {getMachineName(machineLooped, true)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Paper>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogContentInspectionGiveActions);
