import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineType,
  QueryMachineBulkBreakdownsForMachineArgs,
} from "../../entity/types";
import { Button, Table } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_BULK_BREAKDOWNS_FOR_MACHINE_QUERY,
  QueryResultMachineBulkBreakdownsForMachine,
} from "../../apollo/queries/machines";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import MachineBulkBreakdownRow from "./MachineBulkBreakdownRow";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { MachineBulkBreakdownEmpty } from "../../entity/empties";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  machine: Pick<MachineType, "id">;
  reservation: ReservationFromReservationsWorkQueuesType;
}

function MachineBulkBreakdowns({ classes, machine, reservation }: Props) {
  const { t } = useTranslation();

  const [showInsertRow, setShowInsertRow] = useState(false);

  const {
    loading: loadingBreakdowns,
    error: errorBreakdowns,
    data: dataBreakdowns,
  } = useQuery<
    QueryResultMachineBulkBreakdownsForMachine,
    QueryMachineBulkBreakdownsForMachineArgs
  >(GET_MACHINE_BULK_BREAKDOWNS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machineBulkBreakdownsForMachine"),
    variables: { machineId: machine.id, reservationId: reservation.id },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "machines.add_machinebulkbreakdown",
  ]);

  if (loadingBreakdowns) return <LoadingSimple />;
  if (errorBreakdowns) return <Error error={errorBreakdowns} />;
  if (!dataBreakdowns) return <Error error={t("error_query_failed")} />;

  const onClickAdd = () => {
    setShowInsertRow(!showInsertRow);
  };

  return (
    <div className={`mt-4 mb-3`}>
      {((dataBreakdowns.machineBulkBreakdownsForMachine &&
        dataBreakdowns.machineBulkBreakdownsForMachine.length > 0) ||
        showInsertRow) && (
        <Table borderless>
          <thead>
            <tr>
              <th>{t("amount_broken")}</th>
              <th>{t("fixable")}</th>
              <th>{t("fixed_at")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataBreakdowns.machineBulkBreakdownsForMachine?.map(
              (machineBulkBreakdown) => (
                <MachineBulkBreakdownRow
                  key={machineBulkBreakdown.id}
                  machine={machine}
                  reservation={reservation}
                  machineBulkBreakdown={machineBulkBreakdown}
                  setShowInsertRow={setShowInsertRow}
                />
              )
            )}
          </tbody>
          {showInsertRow && (
            <tfoot>
              <MachineBulkBreakdownRow
                machine={machine}
                reservation={reservation}
                machineBulkBreakdown={MachineBulkBreakdownEmpty}
                setShowInsertRow={setShowInsertRow}
              />
            </tfoot>
          )}
        </Table>
      )}
      {!showInsertRow && hasPermissionAdd && (
        <div className="buttons">
          <Button
            className="btnRoundSmall"
            variant="primary"
            onClick={onClickAdd}
          >
            {<AddIcon />}
          </Button>
        </div>
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineBulkBreakdowns);
