import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { CustomerRankType } from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import { parseNumber } from "../../utils/formatting";

interface Props extends WithStyles<typeof styles> {
  customerRank: CustomerRankType;
  setCustomerRank: React.Dispatch<React.SetStateAction<CustomerRankType>>;
}

function DialogContentCustomerRank({
  classes,
  customerRank,
  setCustomerRank,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "customers.add_customerrank",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customerrank",
  ]);
  const disabled =
    customerRank.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <TextField
          label={t("sort_order")}
          type="number"
          value={customerRank.order}
          disabled={disabled}
          onChange={(event) => {
            setCustomerRank({
              ...customerRank,
              order: parseNumber(event.target.value),
            });
          }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("title")}
          onChange={(event) => {
            setCustomerRank({
              ...customerRank,
              title: event.target.value,
            });
          }}
          value={customerRank.title}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCustomerRank);
