import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_CUSTOMER_QUERY,
  QueryResultCustomer,
} from "../apollo/queries/customers";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { Alert, Container } from "react-bootstrap";
import CustomerShowBasic from "../components/Customer/CustomerShowBasic";
import { useTranslation } from "react-i18next";
import NavigationCustomer from "../components/Customer/NavigationCustomer";
import CustomerShowEvents from "../components/Customer/CustomerShowEvents";
import CustomerShowReservations from "../components/Customer/CustomerShowReservations";
import { CustomerEmpty, DiscountListEmpty } from "../entity/empties";
import { DiscountListType, QueryCustomerArgs } from "../entity/types";
import CustomerShowOfferSelect from "../components/Customer/CustomerShowOfferSelect";
import CustomerShowOfferNew from "../components/Customer/CustomerShowOfferNew";
import { ID_EMPTY } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function CustomerShow({ classes }: Props) {
  const { t } = useTranslation();
  let customer_id: string, tab: string;
  ({ customer_id, tab } = useParams());
  const [customer, setCustomer] = useState(CustomerEmpty);

  const [discountListSelected, setDiscountListSelected] =
    useState<DiscountListType>(DiscountListEmpty);

  const { loading, error } = useQuery<QueryResultCustomer, QueryCustomerArgs>(
    GET_CUSTOMER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customer"),
      variables: {
        id: customer_id,
      },
      onCompleted: (result) => {
        if (result.customer) {
          setCustomer(result.customer);
          setDiscountListSelected(
            result.customer.discountListSelected
              ? result.customer.discountListSelected
              : DiscountListEmpty
          );
        }
      },
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  // Customer is set after the query is completed so let's wait for it
  if (customer.id === ID_EMPTY) return <Loading />;

  const renderSwitch = (tab: string) => {
    switch (tab) {
      case "basic":
        return (
          <CustomerShowBasic customer={customer} setCustomer={setCustomer} />
        );
      case "events":
        return (
          <CustomerShowEvents customer={customer} setCustomer={setCustomer} />
        );
      case "reservations":
        return <CustomerShowReservations customer={customer} />;
      case "offer":
        if (
          customer.discountListSelected &&
          !customer.discountListSelected.locked
        ) {
          return (
            <CustomerShowOfferNew
              customer={customer}
              discountList={discountListSelected}
              setDiscountList={setDiscountListSelected}
            />
          );
        } else {
          return <CustomerShowOfferSelect customer={customer} />;
        }
      default:
        return <Alert variant="danger">{t("error_page_not_found")}</Alert>;
    }
  };

  return (
    <Container className="containerMain">
      <NavigationCustomer customer={customer} tab={tab} />
      <div className="containerInner">{renderSwitch(tab)}</div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerShow);
