import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { checkPermission } from "../../utils/permissions";
import { useMutation, useQuery } from "@apollo/client";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import {
  CosttypeForRentThroughType,
  CosttypeType,
  MutationUpdateCosttypeForRentThroughArgs,
} from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import {
  MutationResultUpdateCosttypeForRentThrough,
  UPDATE_COSTTYPE_FOR_RENT_THROUGH_MUTATION,
} from "../../apollo/mutations/costtypes";
import {
  GET_COSTTYPES_FOR_RENT_THROUGH_QUERY,
  GET_COSTTYPES_QUERY,
  QueryResultCosttypes,
  QueryResultCosttypesForRentThrough,
} from "../../apollo/queries/costtypes";
import { CosttypeEmpty } from "../../entity/empties";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {}

function DimensionsCosttypesForRentThrough({ classes }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  const { loading, error, data } = useQuery<QueryResultCosttypesForRentThrough>(
    GET_COSTTYPES_FOR_RENT_THROUGH_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("costtypesForRentThrough"),
    }
  );

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = useQuery<QueryResultCosttypes>(GET_COSTTYPES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costtypes"),
  });

  const [updateCosttypeForRentThrough, { loading: loadingUpdate }] =
    useMutation<
      MutationResultUpdateCosttypeForRentThrough,
      MutationUpdateCosttypeForRentThroughArgs
    >(UPDATE_COSTTYPE_FOR_RENT_THROUGH_MUTATION, {
      onError: (error) => {
        handleError(error);
      },
      update: (cache) => {
        cache.evict({
          id: ROOT_QUERY,
          fieldName: getQueryKey("costtypesForRentThrough"),
        });
      },
    });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "costtypes.change_costtypeforrentthrough",
  ]);

  if (loadingLocations || loadingTypes || loading) return <LoadingSimple />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (error) return <Error error={error} />;
  if (errorTypes) return <Error error={errorTypes} />;
  if (!dataLocations || !data || !dataTypes) {
    return <Error error={t("error_query_failed")} />;
  }

  let costtypesByLocation: {
    [location_id: string]: CosttypeForRentThroughType;
  } = {};
  data.costtypesForRentThrough?.forEach((costtypeForRentThrough) => {
    costtypesByLocation[costtypeForRentThrough.location.id] =
      costtypeForRentThrough;
  });

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      {dataLocations.locations?.map((location) => {
        const costtypeSelected: CosttypeType = costtypesByLocation[location.id]
          ? costtypesByLocation[location.id].costtype
          : CosttypeEmpty;

        return (
          <FormControl key={location.id} fullWidth className="mt-2">
            <InputLabel id={`lblCosttypeOpHours` + location.id}>
              {t("costtype_rent_through_for_location", {
                location: location.name,
              })}
            </InputLabel>
            <Select
              labelId={`lblCosttypeOpHours` + location.id}
              value={costtypeSelected.id}
              onChange={(event) => {
                updateCosttypeForRentThrough({
                  variables: {
                    locationId: location.id,
                    costtypeId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {dataTypes.costtypes?.map((costtype) => (
                <MenuItem key={costtype.id} value={costtype.id}>
                  {costtype.number} - {costtype.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DimensionsCosttypesForRentThrough);
