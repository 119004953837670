import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { handleError } from "../../entity/ErrorHandler";
import { DiscountListEmpty } from "../../entity/empties";
import {
  CatalogType,
  DiscountListType,
  Mutation,
  MutationCreateDiscountListArgs,
} from "../../entity/types";
import { GET_DISCOUNT_LISTS_QUERY } from "../../apollo/queries/discounts";
import { CREATE_DISCOUNT_LIST_MUTATION } from "../../apollo/mutations/discounts";
import DialogContentDiscountList from "./DialogContentDiscountList";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function CreateDiscountList({ classes, catalog }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [discountList, setDiscountList] =
    useState<DiscountListType>(DiscountListEmpty);

  const [createDiscountList, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateDiscountListArgs
  >(CREATE_DISCOUNT_LIST_MUTATION, {
    refetchQueries: [
      {
        query: GET_DISCOUNT_LISTS_QUERY,
        variables: {
          catalogId: catalog.id,
        },
      },
    ],
    onCompleted: (result) => {
      setDiscountList(DiscountListEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createDiscountList({
      variables: {
        catalogId: catalog.id,
        name: discountList.name,
        isBusiness: discountList.isBusiness,
      },
    });
    setOpen(false);
  };

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "discounts.add_discountlist",
  ]);

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="addButton"
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_discount_list")}</DialogTitle>
          <DialogContentDiscountList
            discountList={discountList}
            setDiscountList={setDiscountList}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <ButtonLoad
              loading={loadingCreate}
              disabled={!discountList.name.trim()}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </ButtonLoad>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateDiscountList);
