import React, { useContext, useMemo, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  CatalogCategoryUpperType,
  CatalogRowType,
  CatalogType,
  Mutation,
  MutationDeleteCatalogRowArgs,
  MutationUpdateCatalogRowArgs,
  MutationUpdateCatalogRowLockedArgs,
  MutationUpdateCatalogRowSourceArgs,
} from "../../../entity/types";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { Button } from "react-bootstrap";
import { faEdit, faPaperclip, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import { handleError } from "../../../entity/ErrorHandler";
import {
  DELETE_CATALOG_ROW_MUTATION,
  UPDATE_CATALOG_ROW_LOCKED_MUTATION,
  UPDATE_CATALOG_ROW_MUTATION,
  UPDATE_CATALOG_ROW_SOURCE_MUTATION,
} from "../../../apollo/mutations/catalogs";
import DialogCatalogRow from "../DialogCatalogRow";
import DialogCatalogRowUpload from "../DialogCatalogRowUpload";
import { checkPermission } from "../../../utils/permissions";
import { dialogConfirm } from "../../../utils/dialogs";
import { COLOR_MUTED, ID_EMPTY } from "../../../utils/constants";
import { isLocked } from "../../../utils/catalogs/locked";
import { renderSelectGroupCategoryRow } from "../../../utils/catalogs/select_group";
import {
  updateCacheCatalogEdit,
  updateCacheCatalogRowEdit,
} from "../../../utils/cache";
import TextFieldFocus from "../../Shared/TextFieldFocus";
import { CatalogRowEmpty } from "../../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  catalogRow: CatalogRowType;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
}

function CatalogRow({
  classes,
  catalog,
  catalogRow,
  catalogCategoriesUpper,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [updateCatalogRow, { loading: loadingUpdateRow }] = useMutation<
    Mutation,
    MutationUpdateCatalogRowArgs
  >(UPDATE_CATALOG_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogRowEdit(cache);
    },
  });
  const [updateCatalogRowLocked, { loading: loadingUpdateRowLocked }] =
    useMutation<Mutation, MutationUpdateCatalogRowLockedArgs>(
      UPDATE_CATALOG_ROW_LOCKED_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );
  const [updateCatalogRowSource, { loading: loadingUpdateRowSource }] =
    useMutation<Mutation, MutationUpdateCatalogRowSourceArgs>(
      UPDATE_CATALOG_ROW_SOURCE_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );
  const [deleteCatalogRow, { loading: loadingDeleteRow }] = useMutation<
    Mutation,
    MutationDeleteCatalogRowArgs
  >(DELETE_CATALOG_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  const [openDialogCatalogRow, setOpenDialogCatalogRow] = useState(false);
  const [openDialogCatalogRowUpload, setOpenDialogCatalogRowUpload] =
    useState(false);

  const [catalogRowEdited, setCatalogRowEdited] = useState<CatalogRowType>({
    ...catalogRow,
    priceDayPrivate: formatNumber(catalogRow.priceDayPrivate, 2),
    priceMonthPrivate: formatNumber(catalogRow.priceMonthPrivate, 2),
    priceDayCompany: formatNumber(catalogRow.priceDayCompany, 2),
    priceMonthCompany: formatNumber(catalogRow.priceMonthCompany, 2),
  });

  const [priceDayPrivateVat, setPriceDayPrivateVat] = useState<string>(
    formatNumber(catalogRow.priceDayPrivate * settings.vatFactor, 2)
  );
  const [priceMonthPrivateVat, setPriceMonthPrivateVat] = useState<string>(
    formatNumber(Number(catalogRow.priceMonthPrivate) * settings.vatFactor, 2)
  );
  const [priceDayCompanyVat, setPriceDayCompanyVat] = useState<string>(
    formatNumber(catalogRow.priceDayCompany * settings.vatFactor, 2)
  );
  const [priceMonthCompanyVat, setPriceMonthCompanyVat] = useState<string>(
    formatNumber(Number(catalogRow.priceMonthCompany) * settings.vatFactor, 2)
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs.delete_catalogrow",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogrow",
  ]);

  const lockedCatalog = isLocked(catalog);

  const handleUpdateCatalogRow = (values: {
    [key: string]: string | number;
  }) => {
    if (lockedCatalog) {
      handleUpdateCatalogRowLocked(values);
    } else {
      handleUpdateCatalogRowNotLocked(values);
    }
  };

  const handleUpdateCatalogRowLocked = (
    values: Partial<MutationUpdateCatalogRowLockedArgs>
  ) => {
    const base: MutationUpdateCatalogRowLockedArgs = {
      catalogRowId: catalogRowEdited.id,
      name: catalogRowEdited.name,
      information: catalogRowEdited.information,
    };

    const changed = Object.entries(values).some(
      ([key, value]) =>
        value !== base[key as keyof MutationUpdateCatalogRowLockedArgs]
    );

    if (changed) {
      const options = {
        variables: { ...base, ...values },
      };

      updateCatalogRowLocked(options);
    }
  };

  const handleUpdateCatalogRowNotLocked = (
    values: Partial<MutationUpdateCatalogRowArgs>
  ) => {
    const base: MutationUpdateCatalogRowArgs = {
      catalogRowId: catalogRowEdited.id,
      name: catalogRowEdited.name,
      catalogCategoryId: catalogRowEdited.catalogCategory
        ? catalogRowEdited.catalogCategory.id
        : ID_EMPTY,
      information: catalogRowEdited.information,
      billingDaysWeekCompany: catalogRowEdited.billingDaysWeekCompany,
      priceDayCompany: parseNumber(catalogRowEdited.priceDayCompany),
      priceMonthCompany: parseNumber(catalogRowEdited.priceMonthCompany),
      billingDaysWeekPrivate: catalogRowEdited.billingDaysWeekPrivate,
      priceDayPrivate: parseNumber(catalogRowEdited.priceDayPrivate),
      priceMonthPrivate: parseNumber(catalogRowEdited.priceMonthPrivate),
      hourLimitDayCompany: catalogRowEdited.hourLimitDayCompany,
      hourLimitDayPrivate: catalogRowEdited.hourLimitDayPrivate,
      hourLimitMonthCompany: catalogRowEdited.hourLimitMonthCompany,
      hourLimitMonthPrivate: catalogRowEdited.hourLimitMonthPrivate,
      extraHoursCompany: catalogRowEdited.extraHoursCompany,
      extraHoursPrivate: catalogRowEdited.extraHoursPrivate,
    };

    const changed = Object.entries(values).some(
      ([key, value]) =>
        value !== base[key as keyof MutationUpdateCatalogRowArgs]
    );

    if (changed) {
      const options = {
        variables: { ...base, ...values },
      };

      updateCatalogRow(options);
    }
  };

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogRow({ variables: { catalogRowId: catalogRowEdited.id } });
    });
  };
  const handleClickInfo = () => {
    setOpenDialogCatalogRow(true);
  };
  const handleClickUpload = () => {
    setOpenDialogCatalogRowUpload(true);
  };

  const getTitleRowEdit = () => {
    return (
      catalogRowEdited.name +
      "\n\n" +
      t("hour_limit_day_company") +
      "\t" +
      catalogRowEdited.hourLimitDayCompany +
      "\n" +
      t("hour_limit_day_private") +
      "\t" +
      catalogRowEdited.hourLimitDayPrivate +
      "\n" +
      t("hour_limit_month_company") +
      "\t" +
      catalogRowEdited.hourLimitMonthCompany +
      "\n" +
      t("hour_limit_month_private") +
      "\t" +
      catalogRowEdited.hourLimitMonthPrivate +
      "\n" +
      t("extra_hours_company") +
      "\t" +
      catalogRowEdited.extraHoursCompany +
      "\n" +
      t("extra_hours_private") +
      "\t" +
      catalogRowEdited.extraHoursPrivate +
      (catalogRowEdited.information
        ? "\n\n" + t("information") + "\n" + catalogRowEdited.information
        : "")
    );
  };

  const onChangeRowSource = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const catalogRowIdSource =
      event.target.value === "" ? undefined : String(event.target.value);

    if (catalogRowIdSource) {
      const catalogRowSource = catalogRowEdited.catalogRowSource
        ? catalogRowEdited.catalogRowSource
        : CatalogRowEmpty;
      setCatalogRowEdited({
        ...catalogRowEdited,
        catalogRowSource: {
          ...catalogRowSource,
          id: catalogRowIdSource,
        },
      });
    } else {
      setCatalogRowEdited({
        ...catalogRowEdited,
        catalogRowSource: undefined,
      });
    }

    updateCatalogRowSource({
      variables: {
        catalogRowId: catalogRowEdited.id,
        catalogRowIdSource: catalogRowIdSource,
      },
    });
  };

  return useMemo(() => {
    return (
      <tr
        className={`hideOnCategorySort ${
          loadingUpdateRow || loadingUpdateRowLocked || loadingDeleteRow
            ? "loading"
            : ""
        }`}
      >
        <td>
          <FormControl fullWidth>
            <TextField
              value={catalogRowEdited.name}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  name: event.target.value,
                });
              }}
              onBlur={() => {
                handleUpdateCatalogRow({ name: catalogRowEdited.name });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={catalogRowEdited.priceDayCompany}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceDayCompany: event.target.value,
                });
                setPriceDayCompanyVat(
                  formatNumber(
                    parseNumber(event.target.value) * settings.vatFactor,
                    2
                  )
                );
              }}
              onBlur={() => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceDayCompany: formatNumber(
                    catalogRowEdited.priceDayCompany,
                    2
                  ),
                });
                handleUpdateCatalogRow({
                  priceDayCompany: parseNumber(
                    catalogRowEdited.priceDayCompany
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={priceDayCompanyVat}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setPriceDayCompanyVat(event.target.value);
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceDayCompany: formatNumber(
                    parseNumber(event.target.value) / settings.vatFactor,
                    2
                  ),
                });
              }}
              onBlur={() => {
                setPriceDayCompanyVat(formatNumber(priceDayCompanyVat, 2));
                handleUpdateCatalogRow({
                  priceDayCompany: (
                    parseNumber(priceDayCompanyVat) / settings.vatFactor
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={catalogRowEdited.priceMonthCompany}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceMonthCompany: event.target.value,
                });
                setPriceMonthCompanyVat(
                  formatNumber(
                    parseNumber(event.target.value) * settings.vatFactor,
                    2
                  )
                );
              }}
              onBlur={() => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceMonthCompany: formatNumber(
                    catalogRowEdited.priceMonthCompany,
                    2
                  ),
                });
                handleUpdateCatalogRow({
                  priceMonthCompany: parseNumber(
                    catalogRowEdited.priceMonthCompany
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={priceMonthCompanyVat}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setPriceMonthCompanyVat(event.target.value);
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceMonthCompany: formatNumber(
                    parseNumber(event.target.value) / settings.vatFactor,
                    2
                  ),
                });
              }}
              onBlur={() => {
                setPriceMonthCompanyVat(formatNumber(priceMonthCompanyVat, 2));
                handleUpdateCatalogRow({
                  priceMonthCompany: (
                    parseNumber(priceMonthCompanyVat) / settings.vatFactor
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={catalogRowEdited.billingDaysWeekCompany}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  billingDaysWeekCompany: parseInt(event.target.value),
                });
              }}
              onBlur={() => {
                handleUpdateCatalogRow({
                  billingDaysWeekCompany:
                    catalogRowEdited.billingDaysWeekCompany,
                });
              }}
            />
          </FormControl>
        </td>
        <td></td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={catalogRowEdited.priceDayPrivate}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceDayPrivate: event.target.value,
                });
                setPriceDayPrivateVat(
                  formatNumber(
                    parseNumber(event.target.value) * settings.vatFactor,
                    2
                  )
                );
              }}
              onBlur={() => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceDayPrivate: formatNumber(
                    catalogRowEdited.priceDayPrivate,
                    2
                  ),
                });
                handleUpdateCatalogRow({
                  priceDayPrivate: parseNumber(
                    catalogRowEdited.priceDayPrivate
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={priceDayPrivateVat}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setPriceDayPrivateVat(event.target.value);
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceDayPrivate: formatNumber(
                    parseNumber(event.target.value) / settings.vatFactor,
                    2
                  ),
                });
              }}
              onBlur={() => {
                setPriceDayPrivateVat(formatNumber(priceDayPrivateVat, 2));
                handleUpdateCatalogRow({
                  priceDayPrivate: (
                    parseNumber(priceDayPrivateVat) / settings.vatFactor
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={catalogRowEdited.priceMonthPrivate}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceMonthPrivate: event.target.value,
                });
                setPriceMonthPrivateVat(
                  formatNumber(
                    parseNumber(event.target.value) * settings.vatFactor,
                    2
                  )
                );
              }}
              onBlur={() => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceMonthPrivate: formatNumber(
                    catalogRowEdited.priceMonthPrivate,
                    2
                  ),
                });
                handleUpdateCatalogRow({
                  priceMonthPrivate: parseNumber(
                    catalogRowEdited.priceMonthPrivate
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={priceMonthPrivateVat}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setPriceMonthPrivateVat(event.target.value);
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  priceMonthPrivate: formatNumber(
                    parseNumber(event.target.value) / settings.vatFactor,
                    2
                  ),
                });
              }}
              onBlur={() => {
                setPriceMonthPrivateVat(formatNumber(priceMonthPrivateVat, 2));
                handleUpdateCatalogRow({
                  priceMonthPrivate: (
                    parseNumber(priceMonthPrivateVat) / settings.vatFactor
                  ).toFixed(2),
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl fullWidth>
            <TextFieldFocus
              value={catalogRowEdited.billingDaysWeekPrivate}
              disabled={!hasPermissionEdit || lockedCatalog}
              onChange={(event) => {
                setCatalogRowEdited({
                  ...catalogRowEdited,
                  billingDaysWeekPrivate: parseInt(event.target.value),
                });
              }}
              onBlur={() => {
                handleUpdateCatalogRow({
                  billingDaysWeekPrivate:
                    catalogRowEdited.billingDaysWeekPrivate,
                });
              }}
            />
          </FormControl>
        </td>
        {!lockedCatalog && (
          <td>
            <FormControl fullWidth>
              <Select
                className={loadingUpdateRowSource ? "loading" : ""}
                value={
                  catalogRowEdited.catalogRowSource
                    ? catalogRowEdited.catalogRowSource.id
                    : ID_EMPTY
                }
                disabled={!hasPermissionEdit || lockedCatalog}
                onChange={onChangeRowSource}
              >
                <MenuItem value={ID_EMPTY}>
                  {t("no_source_use_catalog_price")}
                </MenuItem>
                {catalog.catalogSource?.catalogcategoryupperSet.map(
                  (catalogCategoryUpper) =>
                    renderSelectGroupCategoryRow(catalogCategoryUpper)
                )}
              </Select>
            </FormControl>
          </td>
        )}
        <td>
          {hasPermissionEdit && (
            <Button
              variant="light"
              size="sm"
              className={`${classes.btn}`}
              onClick={handleClickInfo}
              title={getTitleRowEdit()}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          )}
          {hasPermissionEdit && (
            <Button
              variant="light"
              size="sm"
              className={`${classes.btn} ${
                catalogRowEdited.image ? classes.btnHasData : classes.btnNoData
              }`}
              onClick={handleClickUpload}
            >
              <FontAwesomeIcon icon={faPaperclip} />
            </Button>
          )}
          {hasPermissionDelete && (
            <Button
              variant="light"
              size="sm"
              onClick={handleClickDelete}
              className={classes.btn}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
          {openDialogCatalogRow && (
            <DialogCatalogRow
              open={openDialogCatalogRow}
              setOpen={setOpenDialogCatalogRow}
              catalogCategoriesUpper={catalogCategoriesUpper}
              catalogRowOrig={catalogRowEdited}
              setCatalogRowOrig={setCatalogRowEdited}
              catalog={catalog}
            />
          )}
          {openDialogCatalogRowUpload && (
            <DialogCatalogRowUpload
              open={openDialogCatalogRowUpload}
              setOpen={setOpenDialogCatalogRowUpload}
              catalogRow={catalogRowEdited}
            />
          )}
        </td>
      </tr>
    );
    // eslint-disable-next-line
  }, [
    catalogRowEdited,
    priceDayCompanyVat,
    priceMonthCompanyVat,
    priceDayPrivateVat,
    priceMonthPrivateVat,
    openDialogCatalogRow,
    openDialogCatalogRowUpload,
    loadingUpdateRow,
    loadingUpdateRowLocked,
    loadingDeleteRow,
    loadingUpdateRowSource,
  ]);
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    btn: {
      marginRight: spacing(0.5),
    },
    btnHasData: {},
    btnNoData: {
      color: COLOR_MUTED,
    },
  });

export default withStyles(styles)(CatalogRow);
