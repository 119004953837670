import * as Types from '../../entity/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDrivesQueryQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
}>;


export type GetDrivesQueryQuery = (
  { __typename?: 'Query' }
  & { drives: Array<(
    { __typename?: 'DriveType' }
    & Pick<Types.DriveType, 'id' | 'createdAt'>
    & { createdBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, drivecontentSet: Array<(
      { __typename?: 'DriveContentType' }
      & Pick<Types.DriveContentType, 'id'>
      & { transport: (
        { __typename?: 'TransportType' }
        & Pick<Types.TransportType, 'id' | 'method' | 'address' | 'postcode' | 'district' | 'dateDeadline' | 'timeDeadline'>
      ), reservation: (
        { __typename?: 'ReservationType' }
        & Pick<Types.ReservationType, 'id'>
        & { order: (
          { __typename?: 'OrderType' }
          & Pick<Types.OrderType, 'id'>
        ), catalogExtraRowRental?: Types.Maybe<(
          { __typename?: 'CatalogExtraRowRentalType' }
          & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
        )>, machine?: Types.Maybe<(
          { __typename?: 'MachineType' }
          & Pick<Types.MachineType, 'id' | 'identifier'>
          & { machineModel: (
            { __typename?: 'MachineModelType' }
            & Pick<Types.MachineModelType, 'id' | 'title'>
            & { machineMake: (
              { __typename?: 'MachineMakeType' }
              & Pick<Types.MachineMakeType, 'id' | 'title'>
            ) }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GetDrivesForWorkQueuesQueryQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  userId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetDrivesForWorkQueuesQueryQuery = (
  { __typename?: 'Query' }
  & { drivesForWorkQueues: Array<(
    { __typename?: 'DriveType' }
    & Pick<Types.DriveType, 'id'>
    & { drivecontentSet: Array<(
      { __typename?: 'DriveContentType' }
      & Pick<Types.DriveContentType, 'id'>
      & { transport: (
        { __typename?: 'TransportType' }
        & Pick<Types.TransportType, 'id' | 'method' | 'address' | 'postcode' | 'district' | 'dateDeadline' | 'timeDeadline' | 'information' | 'dateEndUsage' | 'timeEndUsage'>
      ), reservation: (
        { __typename?: 'ReservationType' }
        & Pick<Types.ReservationType, 'id' | 'status' | 'dateRented' | 'timeRented' | 'dateReturned' | 'timeReturned' | 'bulkAmount' | 'bulkAmountReturned' | 'information' | 'giveCheckedAt' | 'giveImagesAt' | 'giveInstructedAt' | 'giveAt' | 'returnAt' | 'returnImagesAt' | 'returnCheckedCatalogAt' | 'returnCheckedPreAt' | 'returnCheckedAt' | 'returnStoredAt'>
        & { userGive?: Types.Maybe<(
          { __typename?: 'UserType' }
          & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
        )>, userReturn?: Types.Maybe<(
          { __typename?: 'UserType' }
          & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
        )>, machine?: Types.Maybe<(
          { __typename?: 'MachineType' }
          & Pick<Types.MachineType, 'id' | 'identifier' | 'bulkProduct' | 'hourTracking'>
          & { machineModel: (
            { __typename?: 'MachineModelType' }
            & Pick<Types.MachineModelType, 'id' | 'title'>
            & { machineMake: (
              { __typename?: 'MachineMakeType' }
              & Pick<Types.MachineMakeType, 'id' | 'title'>
            ) }
          ) }
        )>, catalogExtraRowRental?: Types.Maybe<(
          { __typename?: 'CatalogExtraRowRentalType' }
          & Pick<Types.CatalogExtraRowRentalType, 'id' | 'identifier' | 'name'>
          & { location: (
            { __typename?: 'LocationType' }
            & Pick<Types.LocationType, 'id'>
          ) }
        )>, reservationcommentSet: Array<(
          { __typename?: 'ReservationCommentType' }
          & Pick<Types.ReservationCommentType, 'id'>
        )>, order: (
          { __typename?: 'OrderType' }
          & Pick<Types.OrderType, 'id'>
          & { location: (
            { __typename?: 'LocationType' }
            & Pick<Types.LocationType, 'id' | 'name'>
          ), customer?: Types.Maybe<(
            { __typename?: 'CustomerType' }
            & Pick<Types.CustomerType, 'id' | 'name'>
          )> }
        ) }
      ) }
    )> }
  )> }
);


export const GetDrivesQueryDocument = gql`
    query getDrivesQuery($locationIds: [ID]!, $dateFrom: Date!, $dateTo: Date!) {
  drives(locationIds: $locationIds, dateFrom: $dateFrom, dateTo: $dateTo) {
    id
    createdBy {
      id
      firstName
      lastName
    }
    createdAt
    drivecontentSet {
      id
      transport {
        id
        method
        address
        postcode
        district
        dateDeadline
        timeDeadline
      }
      reservation {
        id
        order {
          id
        }
        catalogExtraRowRental {
          id
          identifier
          name
        }
        machine {
          id
          identifier
          machineModel {
            id
            title
            machineMake {
              id
              title
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDrivesQueryQuery__
 *
 * To run a query within a React component, call `useGetDrivesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrivesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrivesQueryQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useGetDrivesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetDrivesQueryQuery, GetDrivesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDrivesQueryQuery, GetDrivesQueryQueryVariables>(GetDrivesQueryDocument, options);
      }
export function useGetDrivesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDrivesQueryQuery, GetDrivesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDrivesQueryQuery, GetDrivesQueryQueryVariables>(GetDrivesQueryDocument, options);
        }
export type GetDrivesQueryQueryHookResult = ReturnType<typeof useGetDrivesQueryQuery>;
export type GetDrivesQueryLazyQueryHookResult = ReturnType<typeof useGetDrivesQueryLazyQuery>;
export type GetDrivesQueryQueryResult = Apollo.QueryResult<GetDrivesQueryQuery, GetDrivesQueryQueryVariables>;
export const GetDrivesForWorkQueuesQueryDocument = gql`
    query getDrivesForWorkQueuesQuery($locationIds: [ID]!, $userId: ID) {
  drivesForWorkQueues(locationIds: $locationIds, userId: $userId) {
    id
    drivecontentSet {
      id
      transport {
        id
        method
        address
        postcode
        district
        dateDeadline
        timeDeadline
        information
        dateEndUsage
        timeEndUsage
      }
      reservation {
        id
        status
        dateRented
        timeRented
        dateReturned
        timeReturned
        bulkAmount
        bulkAmountReturned
        information
        giveCheckedAt
        giveImagesAt
        giveInstructedAt
        giveAt
        returnAt
        returnImagesAt
        returnCheckedCatalogAt
        returnCheckedPreAt
        returnCheckedAt
        returnStoredAt
        userGive {
          id
          firstName
          lastName
        }
        userReturn {
          id
          firstName
          lastName
        }
        machine {
          id
          identifier
          bulkProduct
          hourTracking
          machineModel {
            id
            title
            machineMake {
              id
              title
            }
          }
        }
        catalogExtraRowRental {
          id
          identifier
          name
          location {
            id
          }
        }
        reservationcommentSet {
          id
        }
        order {
          id
          location {
            id
            name
          }
          customer {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetDrivesForWorkQueuesQueryQuery__
 *
 * To run a query within a React component, call `useGetDrivesForWorkQueuesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrivesForWorkQueuesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrivesForWorkQueuesQueryQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDrivesForWorkQueuesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetDrivesForWorkQueuesQueryQuery, GetDrivesForWorkQueuesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDrivesForWorkQueuesQueryQuery, GetDrivesForWorkQueuesQueryQueryVariables>(GetDrivesForWorkQueuesQueryDocument, options);
      }
export function useGetDrivesForWorkQueuesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDrivesForWorkQueuesQueryQuery, GetDrivesForWorkQueuesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDrivesForWorkQueuesQueryQuery, GetDrivesForWorkQueuesQueryQueryVariables>(GetDrivesForWorkQueuesQueryDocument, options);
        }
export type GetDrivesForWorkQueuesQueryQueryHookResult = ReturnType<typeof useGetDrivesForWorkQueuesQueryQuery>;
export type GetDrivesForWorkQueuesQueryLazyQueryHookResult = ReturnType<typeof useGetDrivesForWorkQueuesQueryLazyQuery>;
export type GetDrivesForWorkQueuesQueryQueryResult = Apollo.QueryResult<GetDrivesForWorkQueuesQueryQuery, GetDrivesForWorkQueuesQueryQueryVariables>;