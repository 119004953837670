import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  Theme,
} from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import {
  GET_INVOICEABLE_ROWS_QUERY,
  QueryResultInvoiceableRows,
} from "../../apollo/queries/invoices";
import {
  InvoiceableRowType,
  OrderType,
  QueryInvoiceableRowsArgs,
} from "../../entity/types";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../../utils/constants";
import format from "date-fns/format";
import InvoiceListFilter from "./InvoiceListFilter";
import { resetDateTime } from "../../utils/dates";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import { UserContext } from "../../Root";
import { handleError } from "../../entity/ErrorHandler";
import { getLocationSelected } from "../../utils/locations/location";
import InvoiceableRows from "./InvoiceableRows";
import { getInvoiceableRowsByReference } from "../../utils/invoices/invoices";

export type InvoiceableRowsByReferenceType = {
  [referenceId: string]: InvoiceableRowType[];
};

interface Props extends WithStyles<typeof styles> {
  order?: OrderType;
  isAdvance?: Boolean;
}

function InvoicesCreateListReferences({ classes, order, isAdvance }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const locationIdDefault = getLocationSelected(user);
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [dateTo, setDateTo] = useState(resetDateTime(new Date()));
  const [hideSurchargeInvoices, setHideSurchargeInvoices] = useState(false);
  const [onlyEndedReservationsCurrent, setOnlyEndedReservationsCurrent] =
    useState(false);
  const [onlyEndedReservationsLastQuery, setOnlyEndedReservationsLastQuery] =
    useState(false);
  const [onlyPreinvoicingCustomers, setOnlyPreinvoicingCustomers] =
    useState(false);
  const [invoiceableRowsByReference, setInvoiceableRowsByReference] =
    useState<InvoiceableRowsByReferenceType>({});
  const [referenceIdsAllFetched, setReferenceIdsAllFetched] = useState<
    string[]
  >([]);

  const variablesForGetInvoices: QueryInvoiceableRowsArgs = {
    orderId: order ? order.id : ID_EMPTY,
    isAdvance: Boolean(isAdvance),
    locationIds: order ? [] : locationIdsSelected,
    dateTo: format(dateTo, DATE_FORMAT_ISO),
    onlyEndedReservations: onlyEndedReservationsCurrent ? true : undefined,
    onlyPreinvoicingCustomers: onlyPreinvoicingCustomers ? true : undefined,
  };

  const [getInvoices, { loading: loadingInvoices }] = useLazyQuery<
    QueryResultInvoiceableRows,
    QueryInvoiceableRowsArgs
  >(GET_INVOICEABLE_ROWS_QUERY, {
    fetchPolicy: "cache-and-network", // We want to always fetch up-to-date data when searching
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOnlyEndedReservationsLastQuery(onlyEndedReservationsCurrent);
    },
  });

  useEffect(() => {
    if (order) {
      getInvoices({
        variables: variablesForGetInvoices,
      }).then((result) => {
        callbackInvoiceableRows(result.data?.invoiceableRows);
      });
    }
    // eslint-disable-next-line
  }, [order, locationIdsSelected, dateTo]);

  const callbackInvoiceableRows = (
    invoiceableRows: InvoiceableRowType[] | undefined
  ) => {
    setInvoiceableRowsByReference(
      invoiceableRows ? getInvoiceableRowsByReference(invoiceableRows) : {}
    );
  };

  const onClickCallback = () => {
    getInvoices({
      variables: variablesForGetInvoices,
    }).then((result) => {
      callbackInvoiceableRows(result.data?.invoiceableRows);
    });
  };

  const fetchAllRowsForReference = (referenceId: string) => {
    setReferenceIdsAllFetched([...referenceIdsAllFetched, referenceId]);

    getInvoices({
      variables: {
        ...variablesForGetInvoices,
        referenceId: referenceId,
        onlyEndedReservations: false,
      },
    }).then((result) => {
      const rows = result.data?.invoiceableRows;
      if (rows) {
        setInvoiceableRowsByReference((prevState) => ({
          ...prevState,
          [referenceId]: rows,
        }));
      }
    });
  };

  return (
    <div className={loadingInvoices ? "loading" : ""}>
      {!isAdvance && (
        <>
          <InvoiceListFilter
            dateTo={dateTo}
            setDateTo={setDateTo}
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
            onlyEndedReservations={onlyEndedReservationsCurrent}
            setOnlyEndedReservations={setOnlyEndedReservationsCurrent}
            onlyPreinvoicingCustomers={onlyPreinvoicingCustomers}
            setOnlyPreinvoicingCustomers={setOnlyPreinvoicingCustomers}
            onClickCallback={onClickCallback}
          />
          <Row className="mb-4">
            <Col>
              <FormControl fullWidth>
                <FormControlLabel
                  label={t("invoiceable_filter_surcharge")}
                  control={
                    <Checkbox
                      checked={hideSurchargeInvoices}
                      onChange={(event) => {
                        setHideSurchargeInvoices(event.target.checked);
                      }}
                    />
                  }
                />
              </FormControl>
            </Col>
          </Row>
        </>
      )}
      <InvoiceableRows
        invoiceableRowsByReference={invoiceableRowsByReference}
        hideSurchargeInvoices={hideSurchargeInvoices}
        isAdvance={isAdvance}
        dateTo={dateTo}
        fetchAllRowsForReference={
          onlyEndedReservationsLastQuery ? fetchAllRowsForReference : undefined
        }
        referenceIdsAllFetched={referenceIdsAllFetched}
      />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoicesCreateListReferences);
