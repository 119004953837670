import { ApolloCache } from "@apollo/client";
import { Query } from "../entity/types";
import { ROOT_QUERY } from "./constants";

export const getQueryKey = (key: keyof Query) => {
  return key;
};

export const updateCacheReservationStampsMutation = (
  cache: ApolloCache<any>
) => {
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationsWorkQueues"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationsWorkQueuesReturn"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("drivesForWorkQueues"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("transportsUnhandled"),
  });
};

export const updateCacheReservationAndBreakdowns = (
  cache: ApolloCache<any>
) => {
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("order"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationsCalendar"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationsWorkQueues"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("machineBreakdowns"),
  });
};

export const updateCacheOrder = (cache: ApolloCache<any>) => {
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationsWorkQueues"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("orderTotal"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("invoiceableRows"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("bulkAmountsByDate"),
  });
};

export const updateCacheReservation = (cache: ApolloCache<any>) => {
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("order"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("bulkAmountsByDate"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("orderTotal"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationsWorkQueues"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("reservationPrice"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("invoicingPriceForReservation"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("invoiceableRows"),
  });
};

export const updateCacheCatalogEdit = (cache: ApolloCache<any>) => {
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("catalog"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("catalogs"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("machinesWithoutCatalogRow"),
  });
};

export const updateCacheCatalogRowEdit = (cache: ApolloCache<any>) => {
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("machinesWithoutCatalogRow"),
  });
  cache.evict({
    id: ROOT_QUERY,
    fieldName: getQueryKey("machinesForCatalogRow"),
  });
};
