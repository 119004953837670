import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { CONNECT_MACHINE_CATALOG_EXTRA_ROW_RENTAL } from "../../apollo/mutations/machines";
import {
  CatalogExtraRowRentalType,
  MachineType,
  Mutation,
  MutationConnectMachineCatalogExtraRowRentalArgs,
} from "../../entity/types";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import MachineCatalogExtraRowRentalCopy from "./MachineCatalogExtraRowRentalCopy";
import { getQueryKey } from "../../utils/cache";
import { ROOT_QUERY } from "../../utils/constants";
import {
  GET_CATALOG_EXTRA_ROWS_RENTAL_QUERY,
  QueryResultCatalogExtraRowsRental,
} from "../../apollo/queries/catalogs_extra";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ButtonLoad from "../Shared/ButtonLoad";

interface CatalogExtraRowRentalWithTableDataType
  extends CatalogExtraRowRentalType {
  tableData: { checked: boolean };
}

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machine: MachineType;
}

function DialogMachineCatalogExtraRowRental({
  classes,
  open,
  setOpen,
  machine,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultCatalogExtraRowsRental>(
    GET_CATALOG_EXTRA_ROWS_RENTAL_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogExtraRowsRental"),
    }
  );

  const [connectMachineCatalogExtraRowRental, { loading: loadingConnect }] =
    useMutation<Mutation, MutationConnectMachineCatalogExtraRowRentalArgs>(
      CONNECT_MACHINE_CATALOG_EXTRA_ROW_RENTAL,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("catalogExtraRowsRental"),
          });
        },
      }
    );

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  let catalogExtraRowsRentalWithTabledata: CatalogExtraRowRentalWithTableDataType[] =
    [];
  data.catalogExtraRowsRental?.forEach(function (catalogExtraRowRental) {
    const checked = catalogExtraRowRental.machineSet.some(
      (machineLooped) => machineLooped.id === machine.id
    );

    catalogExtraRowsRentalWithTabledata.push({
      ...catalogExtraRowRental,
      tableData: {
        checked: checked,
      },
    });
  });

  let changed: boolean = false;
  let catalogExtraRowRentalIds: string[] = [];
  const onSelectionChange = (rows: CatalogExtraRowRentalType[]) => {
    catalogExtraRowRentalIds = [];
    rows.forEach(function (catalogExtraRowRental) {
      catalogExtraRowRentalIds.push(catalogExtraRowRental.id);
    });
    changed = true;
  };

  const onClickSave = () => {
    setOpen(false);
    if (changed) {
      connectMachineCatalogExtraRowRental({
        variables: {
          data: [
            {
              machineId: machine.id,
              catalogExtraRowIds: catalogExtraRowRentalIds,
            },
          ],
        },
      });
    }
  };

  const onClickCancel = () => {
    setOpen(false);
    changed = false;
    catalogExtraRowRentalIds = [];
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitle>{t("catalog_machines_extra_rows")}</DialogTitle>
      <DialogContent>
        <MachineCatalogExtraRowRentalCopy machine={machine} />
        <MaterialTable<CatalogExtraRowRentalWithTableDataType>
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            { title: t("catalog_extra_row"), field: "name" },
            {
              title: t("catalog_extra_category"),
              field: "catalogExtraCategoryRental.name",
            },
          ]}
          data={catalogExtraRowsRentalWithTabledata}
          options={getTableOptions({ selection: true })}
          onSelectionChange={onSelectionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickCancel} variant="secondary">
          {t("cancel")}
        </Button>
        <ButtonLoad
          loading={loadingConnect}
          onClick={onClickSave}
          variant="primary"
        >
          {t("save")}
        </ButtonLoad>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogMachineCatalogExtraRowRental);
