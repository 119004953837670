import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { MachineType } from "../../../entity/types";
import DialogContentMachineInfo from "./DialogContentMachineInfo";
import { TabPanel } from "@material-ui/lab";
import { ProductCardTabs } from "./DialogMachineProductCard";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCardHoursInfos({ classes, machine }: Props) {
  return (
    <TabPanel value={ProductCardTabs.MachineInfo}>
      <DialogContentMachineInfo machine={machine} editable={false} />
    </TabPanel>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TabMachineCardHoursInfos);
