import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Popper,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { findFromSetById } from "../../utils/collections";
import { LocationEmpty } from "../../entity/empties";
import { format, parse } from "date-fns";
import { LocationType } from "../../entity/types";
import { Button, ButtonGroup } from "react-bootstrap";
import {
  faCalendarAlt,
  faCalendarExclamation,
  faCalendarPlus,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateAddDays, dateDiffInDays } from "../../utils/dates";
import { CALENDAR_INSERT_MODES, DATE_FORMAT_ISO } from "../../utils/constants";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

interface Props extends WithStyles<typeof styles> {
  dateFrom: Date;
  setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
  dateTo: Date;
  setDateTo: React.Dispatch<React.SetStateAction<Date>>;
  location: LocationType;
  setLocation: React.Dispatch<React.SetStateAction<LocationType>>;
  locations: LocationType[];
  insertMode: CALENDAR_INSERT_MODES;
  setInsertMode: React.Dispatch<React.SetStateAction<CALENDAR_INSERT_MODES>>;
}

function CalendarFilter({
  classes,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  location,
  setLocation,
  locations,
  insertMode,
  setInsertMode,
}: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [dateFromInput, setDateFromInput] = useState(
    format(dateFrom, DATE_FORMAT_ISO)
  );
  const [dateToInput, setDateToInput] = useState(
    format(dateTo, DATE_FORMAT_ISO)
  );

  const onClickBackward = () => {
    const step = dateDiffInDays(dateFrom, dateTo) + 1;

    const dateFromNew = dateAddDays(dateFrom, -step);
    setDateFrom(dateFromNew);
    setDateFromInput(format(dateFromNew, DATE_FORMAT_ISO));

    const dateToNew = dateAddDays(dateTo, -step);
    setDateTo(dateToNew);
    setDateToInput(format(dateToNew, DATE_FORMAT_ISO));
  };

  const onClickForward = () => {
    const step = dateDiffInDays(dateFrom, dateTo) + 1;

    const dateFromNew = dateAddDays(dateFrom, step);
    setDateFrom(dateFromNew);
    setDateFromInput(format(dateFromNew, DATE_FORMAT_ISO));

    const dateToNew = dateAddDays(dateTo, step);
    setDateTo(dateToNew);
    setDateToInput(format(dateToNew, DATE_FORMAT_ISO));
  };

  return (
    <div className="mt-3">
      <div className="d-inline-block mb-2 me-4">
        <ButtonGroup aria-label={t("calendar_controls")}>
          <Button variant="primary" size="lg" onClick={onClickBackward}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </Button>
          <Button
            variant={Boolean(anchorEl) ? "outline-primary" : "primary"}
            size="lg"
            onClick={(event) => {
              if (Boolean(anchorEl)) {
                setAnchorEl(null);
              } else {
                setAnchorEl(event.currentTarget);
              }
            }}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Button>
          <Button variant="primary" size="lg" onClick={onClickForward}>
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        </ButtonGroup>
        <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom">
          <Paper className="p-3">
            <FormControl className="mb-2" fullWidth>
              <TextField
                type="date"
                label={t("date_from")}
                value={dateFromInput}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  let date = new Date();
                  if (event.target.value !== "") {
                    date = parse(
                      event.target.value,
                      DATE_FORMAT_ISO,
                      new Date()
                    );
                  }
                  setDateFrom(date);
                  setDateFromInput(format(date, DATE_FORMAT_ISO));
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                type="date"
                label={t("date_to")}
                value={dateToInput}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  let date = new Date();
                  if (event.target.value !== "") {
                    date = parse(
                      event.target.value,
                      DATE_FORMAT_ISO,
                      new Date()
                    );
                  }
                  setDateTo(date);
                  setDateToInput(format(date, DATE_FORMAT_ISO));
                }}
              />
            </FormControl>
          </Paper>
        </Popper>
      </div>
      <FormControl className="mb-2 me-4">
        <InputLabel id="lblCalendarLocation">{t("location")}</InputLabel>
        <Select
          autoWidth
          labelId="lblCalendarLocation"
          value={location.id}
          onChange={(event) => {
            setLocation(
              findFromSetById(
                String(event.target.value),
                locations,
                LocationEmpty
              )
            );
          }}
        >
          <MenuItem value="0">{t("all")}</MenuItem>
          {locations.map((locationLooped) => (
            <MenuItem key={locationLooped.id} value={locationLooped.id}>
              {locationLooped.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ToggleButtonGroup
        className="mb-2 d-inline-block"
        exclusive
        size="large"
        value={insertMode}
        onChange={(event, value) => {
          setInsertMode(value);
        }}
      >
        <ToggleButton
          value={CALENDAR_INSERT_MODES.RESERVATION}
          title={t("calendar_insert_mode_" + CALENDAR_INSERT_MODES.RESERVATION)}
        >
          <FontAwesomeIcon
            className={
              insertMode === CALENDAR_INSERT_MODES.RESERVATION
                ? classes.iconSelected
                : ""
            }
            size="lg"
            icon={faCalendarPlus}
          />
        </ToggleButton>
        <ToggleButton
          value={CALENDAR_INSERT_MODES.BREAKDOWN}
          title={t("calendar_insert_mode_" + CALENDAR_INSERT_MODES.BREAKDOWN)}
        >
          <FontAwesomeIcon
            className={
              insertMode === CALENDAR_INSERT_MODES.BREAKDOWN
                ? classes.iconSelected
                : ""
            }
            size="lg"
            icon={faCalendarExclamation}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    iconSelected: {
      color: theme.palette.primary.dark,
    },
  });

export default withStyles(styles)(CalendarFilter);
