import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { CatalogType } from "../../entity/types";
import FormControl from "@material-ui/core/FormControl";
import { setCatalogIdSelected } from "../../utils/catalogs/selected_catalog";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  catalogs: CatalogType[];
  catalogId: string;
  setCatalogId: React.Dispatch<React.SetStateAction<string>>;
}

function CatalogSelect({ classes, catalogs, catalogId, setCatalogId }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classes.con}>
      <FormControl className={classes.formControl}>
        <InputLabel id="lblCatalogSelect">{t("catalog")}</InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogSelect"
          value={catalogId}
          onChange={(event: React.ChangeEvent<{ value: any }>) => {
            setCatalogId(event.target.value);
            setCatalogIdSelected(event.target.value);
          }}
        >
          {catalogId === ID_EMPTY && (
            <MenuItem key={ID_EMPTY} value={ID_EMPTY}>
              {t("not_selected")}
            </MenuItem>
          )}
          {catalogs.map((catalog: CatalogType) => (
            <MenuItem key={catalog.id} value={catalog.id}>
              {catalog.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    con: {
      textAlign: "right",
      marginRight: "1.2vw",
    },
    formControl: {
      minWidth: "200px",
    },
  });

export default withStyles(styles)(CatalogSelect);
