import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import LoadingSimple from "./LoadingSimple";
import { ID_EMPTY } from "../../utils/constants";
import { getValuesUsingAllOption } from "../../utils/collections";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_CATALOG_WITH_ROWS_QUERY,
  QueryResultCatalogWithRows,
} from "../../apollo/queries/catalogs";
import {
  getCatalogIdActive,
  setCatalogIdSelected,
} from "../../utils/catalogs/selected_catalog";
import { renderSelectGroupCategoryUpperCategory } from "../../utils/catalogs/select_group";
import { QueryCatalogArgs } from "../../entity/types";
import { SettingsContext } from "../../Root";

interface Props extends WithStyles<typeof styles> {
  catalogCategoryIdsSelected: string[];
  setCatalogCategoryIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function CatalogCategoryFilter({
  classes,
  catalogCategoryIdsSelected,
  setCatalogCategoryIdsSelected,
}: Props) {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);
  const catalogId = getCatalogIdActive(settings);

  const [
    catalogCategoryIdsSelectedEdited,
    setCatalogCategoryIdsSelectedEdited,
  ] = useState(
    catalogCategoryIdsSelected.length === 0
      ? [ID_EMPTY]
      : catalogCategoryIdsSelected
  );

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: dataCategories,
  } = useQuery<QueryResultCatalogWithRows, QueryCatalogArgs>(
    GET_CATALOG_WITH_ROWS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalog"),
      variables: {
        catalogId: catalogId,
      },
    }
  );

  if (loadingCategories) return <LoadingSimple />;
  if (errorCategories) return <Error error={errorCategories} />;
  if (!dataCategories) return <Error error={t("error_query_failed")} />;

  return (
    <FormControl fullWidth>
      <InputLabel id="lblCatalogCategoryFilter">
        {t("catalog_category")}
      </InputLabel>
      <Select
        autoWidth
        labelId="lblCatalogCategoryFilter"
        value={catalogCategoryIdsSelectedEdited}
        multiple={true}
        onChange={(event) => {
          setCatalogCategoryIdsSelectedEdited(
            getValuesUsingAllOption(
              event.target.value as string[],
              catalogCategoryIdsSelectedEdited
            )
          );
        }}
        onClose={() => {
          const hasOnlyAll =
            catalogCategoryIdsSelectedEdited.length === 1 &&
            catalogCategoryIdsSelectedEdited.includes(ID_EMPTY);
          setCatalogCategoryIdsSelected(
            hasOnlyAll ? [] : catalogCategoryIdsSelectedEdited
          );

          if (catalogCategoryIdsSelectedEdited.length === 1) {
            setCatalogIdSelected(catalogCategoryIdsSelectedEdited[0]);
          }
        }}
      >
        <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
        {dataCategories.catalog?.catalogcategoryupperSet.map(
          (catalogCategoryUpper) =>
            renderSelectGroupCategoryUpperCategory(catalogCategoryUpper)
        )}
      </Select>
    </FormControl>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogCategoryFilter);
