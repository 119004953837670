import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import {
  MutationGetConfirmationPdfArgs,
  MutationGetContractPdfArgs,
  MutationGetDrivePdfArgs,
  OrderType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import {
  GET_DRIVE_PDF_MUTATION,
  MutationResultDrivePdf,
} from "../../apollo/mutations/drives";
import { popNewWindow } from "../../utils/dialogs";
import {
  GET_CONFIRMATION_PDF_MUTATION,
  GET_CONTRACT_PDF_MUTATION,
  MutationResultConfirmationPdf,
  MutationResultContractPdf,
} from "../../apollo/mutations/orders";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import { ID_EMPTY } from "../../utils/constants";
import DialogOrderOffer from "../Shared/DialogOrderOffer";
import { OrderFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  orders: OrderFromReservationsWorkQueuesType[];
  driveId: string;
}

function OrderPdfButtons({ classes, driveId, orders }: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const [orderId, setOrderId] = useState(ID_EMPTY);
  const [openDialogOffer, setOpenDialogOffer] = useState(false);

  const [getDrivePdf, { loading: loadingDrive }] = useMutation<
    MutationResultDrivePdf,
    MutationGetDrivePdfArgs
  >(GET_DRIVE_PDF_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      popNewWindow(t, String(result.getDrivePdf?.url));
    },
  });

  const [getContractPdf, { loading: loadingContract }] = useMutation<
    MutationResultContractPdf,
    MutationGetContractPdfArgs
  >(GET_CONTRACT_PDF_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      popNewWindow(t, String(result.getContractPdf?.url));
    },
  });

  const [getConfirmationPdf, { loading: loadingConfirmation }] = useMutation<
    MutationResultConfirmationPdf,
    MutationGetConfirmationPdfArgs
  >(GET_CONFIRMATION_PDF_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      popNewWindow(t, String(result.getConfirmationPdf?.url));
    },
  });

  const onClickCreateWaybill = () => {
    getDrivePdf({
      variables: {
        driveId: driveId,
      },
    });
    setAnchorEl(undefined);
  };

  const onClickCreateOrderOffer = (order: Pick<OrderType, "id">) => {
    setOrderId(order.id);
    setOpenDialogOffer(true);
    setAnchorEl(undefined);
  };

  const onClickCreateContract = (order: Pick<OrderType, "id">) => {
    getContractPdf({
      variables: {
        orderId: order.id,
      },
    });
    setAnchorEl(undefined);
  };

  const onClickCreateConfirmation = (order: Pick<OrderType, "id">) => {
    getConfirmationPdf({
      variables: {
        orderId: order.id,
      },
    });
    setAnchorEl(undefined);
  };

  const showWaybill = driveId !== ID_EMPTY;

  const items: JSX.Element[] = [];

  if (showWaybill) {
    items.push(
      <MenuItem
        key="waybill"
        className={loadingDrive ? "loading" : ""}
        onClick={onClickCreateWaybill}
      >
        {t("create_waybill_pdf")}
      </MenuItem>
    );
  }

  orders.forEach((order) => {
    items.push(
      <MenuItem
        key={"order_offer_" + order.id}
        onClick={() => onClickCreateOrderOffer(order)}
      >
        {t("create_order_offer_pdf_for_id", { order: order.id })}
      </MenuItem>
    );
    items.push(
      <MenuItem
        key={"contract_" + order.id}
        className={loadingContract ? "loading" : ""}
        onClick={() => onClickCreateContract(order)}
      >
        {t("create_contract_pdf_for_id", { order: order.id })}
      </MenuItem>
    );
    items.push(
      <MenuItem
        key={"confirmation_" + order.id}
        className={loadingConfirmation ? "loading" : ""}
        onClick={() => onClickCreateConfirmation(order)}
      >
        {t("create_confirmation_pdf_for_id", { order: order.id })}
      </MenuItem>
    );
  });

  return (
    <>
      <ButtonLoad
        loading={loadingDrive || loadingContract || loadingConfirmation}
        variant="light"
        title={t("create_waybill_or_contract_pdf")}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <FontAwesomeIcon className="ms-2" icon={faFilePdf} />
      </ButtonLoad>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(undefined)}
      >
        {items}
      </Menu>
      {openDialogOffer && (
        <DialogOrderOffer
          orderId={orderId}
          open={openDialogOffer}
          setOpen={setOpenDialogOffer}
        />
      )}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(OrderPdfButtons);
