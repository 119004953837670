import {
  setLocalStorageCustomerId,
  setLocalStorageOrderId,
} from "./calendar/calendar";

export const onLogin = (token: string) => {
  setLocalStorageOrderId("");
  setLocalStorageCustomerId("");
  localStorage.setItem("authToken", token);
};

export const onLogout = () => {
  setLocalStorageOrderId("");
  setLocalStorageCustomerId("");
  localStorage.removeItem("authToken");
};
