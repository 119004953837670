import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultCommissionTemplates {
  commissionTemplates: Query["commissionTemplates"];
}
export const GET_COMMISSION_TEMPLATES_QUERY = gql`
  query getCommissionTemplates {
    commissionTemplates {
      id
      name
      baseSalary
      commissionrangeSet {
        id
        daysMax
      }
    }
  }
`;

export interface QueryResultCommissionTemplatesWithLevels {
  commissionTemplates: Query["commissionTemplates"];
}
export const GET_COMMISSION_TEMPLATES_WITH_LEVELS_QUERY = gql`
  query getCommissionTemplatesWithLevels {
    commissionTemplates {
      id
      name
      commissionrangeSet {
        id
        daysMax
      }
      commissionlevelSet {
        id
        maxDiscount
        maxCommission
        minCommission
        commissionRange {
          id
        }
      }
    }
  }
`;

export interface QueryResultCommissionRowsForUser {
  commissionRowsForUser: Query["commissionRowsForUser"];
  commissionRowsRentThroughForUser: Query["commissionRowsRentThroughForUser"];
}
export const GET_COMMISSION_ROWS_FOR_USER_QUERY = gql`
  query getCommissionRowsForUser(
    $userId: ID!
    $yearFrom: Int!
    $monthFrom: Int!
    $yearTo: Int!
    $monthTo: Int!
  ) {
    commissionRowsForUser(
      userId: $userId
      yearFrom: $yearFrom
      monthFrom: $monthFrom
      yearTo: $yearTo
      monthTo: $monthTo
    ) {
      orderId
      reservationId
      customerId
      customerName
      machineName
      dateFrom
      dateTo
      revenue
      discountPercent
      commissionPercent
      userSellerName
      commission
      case
    }
    commissionRowsRentThroughForUser(
      userId: $userId
      yearFrom: $yearFrom
      monthFrom: $monthFrom
      yearTo: $yearTo
      monthTo: $monthTo
    ) {
      orderId
      reservationId
      customerId
      customerName
      machineName
      dateFrom
      dateTo
      revenue
      hasCommission
      commissionPercent
      userSellerName
      commission
      case
      substituteMachine
    }
  }
`;

export interface QueryResultCommissionUserSettings {
  commissionUserSettings: Query["commissionUserSettings"];
}
export const GET_COMMISSION_USER_SETTINGS_QUERY = gql`
  query getCommissionUserSettingsQuery {
    commissionUserSettings {
      id
      user {
        id
      }
      commissionTemplate {
        id
        baseSalary
      }
      createdAt
      showCommissions
    }
  }
`;

export interface QueryResultCommissionUserSetting {
  commissionUserSetting: Query["commissionUserSetting"];
}
export const GET_COMMISSION_USER_SETTING_QUERY = gql`
  query getCommissionUserSetting($userId: ID!) {
    commissionUserSetting(userId: $userId) {
      id
      showCommissions
      user {
        id
      }
      commissionTemplate {
        id
      }
      createdAt
    }
  }
`;

export interface QueryResultCommissionReportForMonth {
  commissionReportForMonth: Query["commissionReportForMonth"];
}
export const GET_COMMISSION_REPORT_FOR_MONTH_QUERY = gql`
  query getCommissionReportForMonth(
    $year: Int!
    $month: Int!
    $userIds: [ID]!
  ) {
    commissionReportForMonth(year: $year, month: $month, userIds: $userIds) {
      id
      user {
        id
      }
      commissionreportrowSet {
        id
        case
        dateFrom
        dateTo
        revenue
        discountPercent
        commissionPercent
        commission
        source
        reservation {
          id
          dateRented
          dateReturned
          dateBillingMin
          order {
            id
            customer {
              id
              name
            }
          }
          catalogExtraRowRental {
            id
            identifier
            name
          }
          machine {
            id
            identifier
            machineModel {
              id
              title
              machineMake {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export interface QueryResultCommissionsForOrder {
  commissionsForOrder: Query["commissionsForOrder"];
}
export const GET_COMMISSIONS_FOR_ORDER_QUERY = gql`
  query getCommissionsForOrder($orderId: ID!) {
    commissionsForOrder(orderId: $orderId) {
      id
      case
      userSeller {
        id
        firstName
        lastName
      }
    }
  }
`;
