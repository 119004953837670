import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export interface MutationResultCreateCostcenter {
  createCostcenter: Mutation["createCostcenter"];
}
export const CREATE_COSTCENTER_MUTATION = gql`
  mutation createCostcenter($number: String!, $name: String!) {
    createCostcenter(number: $number, name: $name) {
      costcenter {
        id
        number
        name
        createdAt
      }
    }
  }
`;

export interface MutationResultUpdateCostcenter {
  updateCostcenter: Mutation["updateCostcenter"];
}
export const UPDATE_COSTCENTER_MUTATION = gql`
  mutation updateCostcenter(
    $costcenterId: ID!
    $number: String!
    $name: String!
  ) {
    updateCostcenter(
      costcenterId: $costcenterId
      number: $number
      name: $name
    ) {
      costcenter {
        id
        number
        name
      }
    }
  }
`;

export interface MutationResultDeleteCostcenter {
  deleteCostcenter: Mutation["deleteCostcenter"];
}
export const DELETE_COSTCENTER_MUTATION = gql`
  mutation deleteCostcenter($costcenterId: ID!) {
    deleteCostcenter(costcenterId: $costcenterId) {
      costcenterId
    }
  }
`;
