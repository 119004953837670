import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  MaintenanceMaintenanceMaintenanceTypeChoices,
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  MaintenanceType,
  Mutation,
  MutationCreateMaintenanceCommentArgs,
  MutationCreateOperationHourArgs,
  MutationDeleteMaintenanceArgs,
  MutationUpdateMaintenanceArgs,
  MutationUpdateOperationHourArgs,
  OperationHoursOperationHourSourceChoices,
  OperationHourType,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faCommentAlt,
  faExclamationTriangle,
  faPaperclip,
  faTrash,
  faUser,
  faWrench,
} from "@fortawesome/pro-light-svg-icons";
import { ApolloCache, useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import format from "date-fns/format";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  DATE_FORMAT_ISO,
  ID_EMPTY,
  MAINTENANCE_CONTRACTOR_ID,
  ROOT_QUERY,
} from "../../utils/constants";
import { dateDiffInDays, newDate } from "../../utils/dates";
import { getMachineName } from "../../utils/machines/machine";
import { getQueryKey } from "../../utils/cache";
import {
  CREATE_MAINTENANCE_COMMENT,
  DELETE_MAINTENANCE_MUTATION,
  UPDATE_MAINTENANCE_MUTATION,
} from "../../apollo/mutations/maintenances";
import {
  formCurrentLocation,
  formCurrentRentTime,
  formMaintenanceIntervalData,
} from "../../utils/maintenances/maintenance";
import DialogMaintenanceImages from "../Maintenance/DialogMaintenanceImages";
import DialogMaintenanceSchedule from "../Maintenance/DialogMaintenanceSchedule";
import { dialogConfirm } from "../../utils/dialogs";
import {
  CREATE_OPERATION_HOUR_MUTATION,
  UPDATE_OPERATION_HOUR_MUTATION,
} from "../../apollo/mutations/operation_hours";
import {
  CatalogExtraRowRentalEmpty,
  OperationHourEmpty,
} from "../../entity/empties";
import ButtonLoad from "../Shared/ButtonLoad";
import TextFieldFocus from "../Shared/TextFieldFocus";
import DialogMachineProductCard from "../Machine/MachineInfo/DialogMachineProductCard";
import DialogCatalogExtraRowRentalInfo from "../CatalogExtraRental/DialogCatalogExtraRowRentalInfo";

interface Props extends WithStyles<typeof styles> {
  maintenance: MaintenanceType;
  reservationCurrent: ReservationType;
  reservationNext: ReservationType;
  machineBreakdown: MachineBreakdownType;
  recordedOperationHours?: OperationHourType;
}

function WorkQueueCardMaintenance({
  classes,
  maintenance,
  reservationCurrent,
  reservationNext,
  machineBreakdown,
  recordedOperationHours,
}: Props) {
  const { t } = useTranslation();

  const [showAssignment, setShowAssignment] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [openProductCardForMachine, setOpenProductCardForMachine] =
    useState(false);
  const [
    openProductCardForExtraRowRental,
    setOpenProductCardForExtraRowRental,
  ] = useState(false);
  const [comment, setComment] = useState("");
  const [operationHours, setOperationHours] = useState(
    maintenance.operationhour
      ? maintenance.operationhour
      : {
          ...OperationHourEmpty,
          maintenance: maintenance,
          source: OperationHoursOperationHourSourceChoices.Maintenance,
        }
  );

  const [createMaintenanceComment, { loading: loadingCreateComment }] =
    useMutation<Mutation, MutationCreateMaintenanceCommentArgs>(
      CREATE_MAINTENANCE_COMMENT,
      {
        onCompleted: () => {
          setComment("");
        },
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          evictCache(cache);
        },
      }
    );

  const [updateMaintenance, { loading: loadingUpdateMaintenance }] =
    useMutation<Mutation, MutationUpdateMaintenanceArgs>(
      UPDATE_MAINTENANCE_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          evictCache(cache);
        },
      }
    );

  const [createMaintenanceHours, { loading: loadingCreateHours }] = useMutation<
    Mutation,
    MutationCreateOperationHourArgs
  >(CREATE_OPERATION_HOUR_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache, result) => {
      evictCache(cache);

      setOperationHours({
        ...operationHours,
        id: result.data?.createOperationHour?.operationHour?.id
          ? result.data.createOperationHour.operationHour.id
          : ID_EMPTY,
        hours: result.data?.createOperationHour?.operationHour?.hours
          ? result.data.createOperationHour.operationHour.hours
          : 0,
        dateChecked: result.data?.createOperationHour?.operationHour
          ?.dateChecked
          ? result.data.createOperationHour.operationHour.dateChecked
          : "",
      });
    },
  });

  const [updateMaintenanceHours, { loading: loadingUpdateHours }] = useMutation<
    Mutation,
    MutationUpdateOperationHourArgs
  >(UPDATE_OPERATION_HOUR_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      evictCache(cache);
    },
  });

  const [deleteMaintenance] = useMutation<
    Mutation,
    MutationDeleteMaintenanceArgs
  >(DELETE_MAINTENANCE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      evictCache(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "maintenance.change_maintenance",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "maintenance.delete_maintenance",
  ]);
  const hasPermissionShowComment = checkPermission(myPermissions, [
    "maintenance.view_maintenancecomment",
  ]);
  const hasPermissionAddComment = checkPermission(myPermissions, [
    "maintenance.add_maintenancecomment",
  ]);
  const hasPermissionShowImage = checkPermission(myPermissions, [
    "maintenance.view_maintenanceimage",
  ]);
  const hasPermissionAddImage = checkPermission(myPermissions, [
    "maintenance.add_maintenanceimage",
  ]);
  const hasPermissionMarkServiced = checkPermission(myPermissions, [
    "maintenance.allow_mark_maintenance_serviced",
  ]);
  const hasPermissionMarkVerified = checkPermission(myPermissions, [
    "maintenance.allow_mark_maintenance_verified",
  ]);
  const hasPermissionProductCard = checkPermission(myPermissions, [
    "machines.view_machine",
  ]);

  const evictCache = (cache: ApolloCache<any>) => {
    if (
      maintenance.maintenanceType ===
      MaintenanceMaintenanceMaintenanceTypeChoices.Scheduled
    ) {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenances"),
      });
    } else {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenancesUpcoming"),
      });
    }
  };

  const hoursMin = recordedOperationHours ? recordedOperationHours.hours : 0;

  const onClickFixed = () => {
    if (
      maintenance.machine?.id &&
      (maintenance.machine.maintenancesetting?.scheduleType ===
        MaintenanceMaintenanceSettingScheduleTypeChoices.Hours ||
        maintenance.machine.maintenancesetting?.scheduleType ===
          MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays) &&
      !parseInt(operationHours.id)
    ) {
      alert(t("maintenance_operation_hours_mandatory"));
    } else {
      dialogConfirm(
        t,
        t("mark_service_completed", {
          service_technician: maintenance.serviceTechnician?.firstName
            ? maintenance.serviceTechnician?.firstName +
              " " +
              maintenance.serviceTechnician?.lastName
            : maintenance.serviceContractor.length
            ? maintenance.serviceContractor
            : "-",
        }),
        () => {
          updateMaintenance({
            variables: {
              maintenanceId: maintenance.id,
              servicedAt: new Date(),
            },
          });
        }
      );
    }
  };

  const onClickVerified = () => {
    dialogConfirm(
      t,
      t("confirm_service_completed", {
        service_technician: maintenance.serviceTechnician?.firstName
          ? maintenance.serviceTechnician?.firstName +
            " " +
            maintenance.serviceTechnician?.lastName
          : maintenance.serviceContractor.length
          ? maintenance.serviceContractor
          : "-",
      }),
      () => {
        updateMaintenance({
          variables: {
            maintenanceId: maintenance.id,
            verifiedAt: new Date(),
          },
        });
      }
    );
  };

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete_maintenance"), () => {
      deleteMaintenance({
        variables: {
          maintenanceId: maintenance.id,
        },
      });
    });
  };

  const sendNewComment = () => {
    createMaintenanceComment({
      variables: {
        maintenanceId: maintenance.id,
        comment: comment,
      },
    });
  };

  const saveMaintenanceHours = () => {
    if (parseInt(operationHours.id)) {
      updateMaintenanceHours({
        variables: {
          operationHourId: operationHours.id,
          hours: operationHours.hours,
          dateChecked: format(new Date(), DATE_FORMAT_ISO),
        },
      });
    } else {
      createMaintenanceHours({
        variables: {
          reservationId: ID_EMPTY,
          machineId: maintenance.machine?.id
            ? maintenance.machine.id
            : ID_EMPTY,
          maintenanceId: maintenance.id,
          dateChecked: format(new Date(), DATE_FORMAT_ISO),
          hours: operationHours.hours,
          source: OperationHoursOperationHourSourceChoices.Maintenance,
        },
      });
    }
  };

  let DaysUntilReservation = 0;
  let dateRentedNext = new Date();
  let classNameReservationNext = "";
  if (reservationNext.id !== ID_EMPTY) {
    dateRentedNext = newDate(reservationNext.dateRented);
    DaysUntilReservation = dateDiffInDays(new Date(), dateRentedNext);

    if (DaysUntilReservation >= 14) {
      classNameReservationNext = "text-success";
    } else if (DaysUntilReservation >= 7) {
      classNameReservationNext = "text-warning";
    } else {
      classNameReservationNext = "text-danger";
    }
  }

  let classNameBreakdown = "";
  let showBreakdown = false;
  if (machineBreakdown.id !== ID_EMPTY && machineBreakdown.fixedAt === null) {
    showBreakdown = true;
    if (machineBreakdown.stillRentable) {
      classNameBreakdown = "iconBreakdownRentableYes";
    } else {
      classNameBreakdown = "iconBreakdownRentableNo";
    }
  }

  return (
    <div className="cardWorkQueue">
      <div className="cardWorkQueueActions">
        {maintenance.maintenanceType ===
          MaintenanceMaintenanceMaintenanceTypeChoices.Scheduled &&
          ((maintenance.servicedAt === null && hasPermissionMarkServiced) ||
            (maintenance.verifiedAt === null && hasPermissionMarkVerified)) && (
            <div className="cardWorkQueueActionCol left">
              <ButtonLoad
                loading={loadingUpdateMaintenance}
                variant={maintenance.servicedAt === null ? "danger" : "warning"}
                title={
                  maintenance.servicedAt === null ? t("fixed") : t("confirmed")
                }
                onClick={
                  maintenance.servicedAt === null
                    ? onClickFixed
                    : onClickVerified
                }
                disabled={
                  !hasPermissionEdit ||
                  (maintenance.servicedAt === null &&
                    !hasPermissionMarkServiced) ||
                  (maintenance.verifiedAt === null &&
                    !hasPermissionMarkVerified)
                }
              >
                <FontAwesomeIcon icon={faWrench} />
              </ButtonLoad>
            </div>
          )}
        <div className="cardWorkQueueActionCol right">
          {maintenance.maintenanceType ===
            MaintenanceMaintenanceMaintenanceTypeChoices.Upcoming &&
            maintenance?.overdueHours && (
              <>
                <b className="text-danger">
                  {t("overdue_hours", { hours: maintenance.overdueHours })}
                </b>
                {" / "}
              </>
            )}
          {maintenance.maintenanceType ===
          MaintenanceMaintenanceMaintenanceTypeChoices.Upcoming
            ? format(newDate(maintenance.estimatedDate), t("format_date"))
            : maintenance.serviceStartBy === maintenance.serviceEndBy
            ? format(newDate(maintenance.serviceStartBy), t("format_date"))
            : t("date_range", {
                start: format(
                  newDate(maintenance.serviceStartBy),
                  t("format_date")
                ),
                end: format(
                  newDate(maintenance.serviceEndBy),
                  t("format_date")
                ),
              })}
        </div>
      </div>
      {maintenance.machine && (
        <span className="text-muted">{maintenance.machine.identifier}</span>
      )}
      {maintenance.machine && (
        <b className="ms-2">{getMachineName(maintenance.machine)}</b>
      )}
      {maintenance.catalogExtraRowRental && (
        <b>{maintenance.catalogExtraRowRental.name}</b>
      )}
      <span className="text-muted ms-2">
        {formMaintenanceIntervalData(t, maintenance.maintenanceInterval)}
      </span>
      <p className="p-3 mb-0">{maintenance.maintenanceInterval.description}</p>
      {reservationCurrent.id !== ID_EMPTY ? (
        <div className="mb-3">
          <span>{formCurrentLocation(t, reservationCurrent)}</span>
          <br />
          <span>{formCurrentRentTime(t, reservationCurrent)}</span>
        </div>
      ) : (
        <div className="mb-3">
          {maintenance.machine?.location?.name
            ? maintenance.machine.location.name
            : maintenance.catalogExtraRowRental?.location?.name
            ? maintenance.catalogExtraRowRental.location.name
            : t("machine_location_unknown")}
          {reservationNext.id !== ID_EMPTY && (
            <>
              {", "}
              <span className={classNameReservationNext}>
                {t("next_reservation_begins") +
                  " " +
                  format(newDate(reservationNext.dateRented), t("format_date"))}
              </span>
            </>
          )}
        </div>
      )}
      {maintenance.machine &&
        maintenance.maintenanceType ===
          MaintenanceMaintenanceMaintenanceTypeChoices.Scheduled &&
        maintenance.maintenanceInterval.maintenanceSetting.scheduleType !==
          MaintenanceMaintenanceSettingScheduleTypeChoices.Days && (
          <div className="mb-4">
            <div className="d-flex">
              <FormControl
                className={`me-3 ${
                  loadingCreateHours || loadingUpdateHours ? "loading" : ""
                }`}
              >
                <TextFieldFocus
                  type="number"
                  label={t("operation_hours")}
                  inputProps={{ min: hoursMin }}
                  onChange={(event) => {
                    setOperationHours({
                      ...operationHours,
                      hours: Number(event.target.value),
                    });
                  }}
                  onBlur={() => {
                    saveMaintenanceHours();
                  }}
                  value={operationHours.hours}
                  disabled={!hasPermissionEdit}
                  required={true}
                />
              </FormControl>
            </div>
          </div>
        )}
      {(hasPermissionShowComment ||
        hasPermissionShowImage ||
        hasPermissionEdit ||
        hasPermissionDelete) &&
        (hasPermissionAddComment ||
          hasPermissionAddImage ||
          hasPermissionEdit ||
          hasPermissionDelete ||
          maintenance.maintenancecommentSet.length > 0) && (
          <div className="cardWorkQueueBottom">
            {hasPermissionShowComment && (
              <Button
                variant={
                  maintenance.maintenancecommentSet.length > 0
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setShowComments(!showComments)}
              >
                {maintenance.maintenancecommentSet.length}
                <FontAwesomeIcon className="ms-2" icon={faCommentAlt} />
                <FontAwesomeIcon
                  className="ms-2"
                  icon={showComments ? faChevronUp : faChevronDown}
                />
              </Button>
            )}
            {hasPermissionShowImage && (
              <Button
                className="ms-2"
                variant={
                  maintenance.album.maintenanceimageSet.length > 0
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setShowImages(!showImages)}
              >
                {maintenance.album.maintenanceimageSet.length}
                <FontAwesomeIcon className="ms-2" icon={faPaperclip} />
              </Button>
            )}
            {hasPermissionEdit && (
              <Button
                className="ms-2"
                variant={
                  parseInt(
                    maintenance.serviceTechnician?.id
                      ? maintenance.serviceTechnician.id
                      : maintenance.serviceContractor.length
                      ? MAINTENANCE_CONTRACTOR_ID
                      : ID_EMPTY
                  ) !== 0
                    ? "primary"
                    : "outline-primary"
                }
                onClick={() => setShowAssignment(!showAssignment)}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
            )}
            {showBreakdown && (
              <FontAwesomeIcon
                className={classes.breakdownIcon + " " + classNameBreakdown}
                icon={faExclamationTriangle}
                title={machineBreakdown.title}
              />
            )}
            {hasPermissionDelete && maintenance.createdManually && (
              <Button
                className="ms-2 fa-pull-right"
                variant="outline-danger"
                title={t("open_product_card")}
                onClick={() => {
                  onClickDelete();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
            {hasPermissionProductCard && (
              <Button
                className="ms-2"
                variant="outline-primary"
                title={t("open_product_card")}
                onClick={() => {
                  if (maintenance.machine) {
                    setOpenProductCardForMachine(!openProductCardForMachine);
                  } else {
                    setOpenProductCardForExtraRowRental(
                      !openProductCardForExtraRowRental
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={faClipboardList} />
              </Button>
            )}
            {openProductCardForMachine && maintenance.machine && (
              <DialogMachineProductCard
                open={openProductCardForMachine}
                setOpen={setOpenProductCardForMachine}
                machineId={maintenance.machine.id}
              />
            )}
            {openProductCardForExtraRowRental && (
              <DialogCatalogExtraRowRentalInfo
                open={openProductCardForExtraRowRental}
                setOpen={setOpenProductCardForExtraRowRental}
                catalogExtraRowRental={
                  maintenance.catalogExtraRowRental
                    ? maintenance.catalogExtraRowRental
                    : CatalogExtraRowRentalEmpty
                }
              />
            )}
            {showComments && (
              <div className="cardWorkQueueComments">
                {maintenance.maintenancecommentSet.map((maintenanceComment) => (
                  <div
                    key={maintenanceComment.id}
                    className="cardWorkQueueComment"
                  >
                    <div className="cardWorkQueueCommentUser">
                      {maintenanceComment.createdBy && (
                        <span className="user me-2">
                          {maintenanceComment.createdBy.lastName +
                            " " +
                            maintenanceComment.createdBy.firstName}
                        </span>
                      )}
                      <span className="text-muted">
                        {format(
                          newDate(maintenanceComment.createdAt),
                          t("format_datetime")
                        )}
                      </span>
                    </div>
                    {maintenanceComment.comment}
                  </div>
                ))}
                {hasPermissionAddComment && (
                  <div className="cardWorkQueueComment new">
                    <TextField
                      label={t("new_maintenance_comment")}
                      fullWidth
                      value={comment}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <ButtonLoad
                            loading={loadingCreateComment}
                            variant="light"
                            onClick={sendNewComment}
                          >
                            <FontAwesomeIcon icon={faChevronRight} />
                          </ButtonLoad>
                        ),
                      }}
                      inputProps={{
                        maxLength: 200,
                      }}
                      onChange={(event) =>
                        setComment(event.currentTarget.value)
                      }
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          sendNewComment();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {hasPermissionShowImage && showImages && (
              <DialogMaintenanceImages
                maintenance={maintenance}
                open={showImages}
                setOpen={setShowImages}
              />
            )}
            {hasPermissionEdit && showAssignment && (
              <DialogMaintenanceSchedule
                maintenance={maintenance}
                open={showAssignment}
                setOpen={setShowAssignment}
              />
            )}
          </div>
        )}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    breakdownIcon: {
      verticalAlign: "middle !important",
      marginLeft: ".5rem !important",
      fontSize: "2em",
    },
    textContainer: {
      position: "relative",
      height: "1em",
      width: "9em",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
  });

export default withStyles(styles)(WorkQueueCardMaintenance);
