import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withRoot from "./withRoot";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import App from "./pages/App";
import Profile from "./pages/Profile";
import Customers from "./pages/Customers";
import SettingsLogs from "./pages/SettingsLogs";
import SettingsHolidays from "./pages/SettingsHolidays";
import SettingsLocations from "./pages/SettingsLocations";
import Header from "./components/Shared/Header";
import Loading from "./components/Shared/Loading";
import Error from "./components/Shared/Error";
import i18n from "./localization/i18n";
import { I18nextProvider, useTranslation } from "react-i18next";
import { ME_QUERY, QueryResultMe } from "./apollo/queries/login";
import { useQuery } from "@apollo/client";
import SettingsProfiles from "./pages/SettingsProfiles";
import SettingsUsers from "./pages/SettingsUsers";
import Authorization from "./components/Auth/Authorization";
import Unauthorized from "./pages/Unauthorized";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { AuthEmpty, SettingsEmpty, UserEmpty } from "./entity/empties";
import SettingsCatalog from "./pages/SettingsCatalog";
import SettingsMachines from "./pages/SettingsMachines";
import SettingsCatalogExtraRental from "./pages/SettingsCatalogExtraRental";
import SettingsCatalogExtraUnitPrice from "./pages/SettingsCatalogExtraUnitPrice";
import SettingsOther from "./pages/SettingsOther";
import CustomerShow from "./pages/CustomerShow";
import SettingsDiscounts from "./pages/SettingsDiscounts";
import SettingsDiscountsSelected from "./pages/SettingsDiscountsSelected";
import CustomerOfferShow from "./pages/CustomerOfferShow";
import CustomerOfferCatalogShow from "./pages/CustomerOfferCatalogShow";
import Calendar from "./pages/Calendar";
import ErrorNotFound from "./pages/ErrorNotFound";
import CalendarShow from "./pages/CalendarShow";
import WorkQueuesRenting from "./pages/WorkQueuesRenting";
import WorkQueuesMaintenance from "./pages/WorkQueuesMaintenance";
import ManagementInvoicesOpen from "./pages/ManagementInvoicesOpen";
import ManagementInvoicesSent from "./pages/ManagementInvoicesSent";
import SettingsMachineInformation from "./pages/SettingsMachineInformation";
import SettingsCatalogNew from "./pages/SettingsCatalogNew";
import SettingsCatalogEdit from "./pages/SettingsCatalogEdit";
import {
  getUrlCalendar,
  getUrlCustomerTab,
  getUrlOfferCatalog,
  getUrlOfferDiscountList,
  getUrlProfile,
  getUrlSettingsCatalogEdit,
  getUrlSettingsDiscount,
  URL_404,
  URL_CALENDAR,
  URL_CUSTOMERS,
  URL_INVOICES_CREATE,
  URL_INVOICES_OPEN,
  URL_INVOICES_SENT,
  URL_REPORTS_COMMISSIONS,
  URL_REPORTS_DAY,
  URL_ROOT,
  URL_SETTINGS_CATALOG,
  URL_SETTINGS_CATALOG_EXTRA_RENTAL,
  URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE,
  URL_SETTINGS_CATALOG_NEW,
  URL_SETTINGS_CATALOG_TRANSFER,
  URL_SETTINGS_COMMISSIONS,
  URL_SETTINGS_DIMENSIONS,
  URL_SETTINGS_CUSTOMER_AREAS,
  URL_SETTINGS_CUSTOMER_RANKS,
  URL_SETTINGS_CUSTOMER_INDUSTRIES,
  URL_SETTINGS_DISCOUNTS,
  URL_SETTINGS_HOLIDAYS,
  URL_SETTINGS_INSPECTION_GIVE,
  URL_SETTINGS_INSPECTION_RETURN,
  URL_SETTINGS_LOCATIONS,
  URL_SETTINGS_LOGS,
  URL_SETTINGS_MACHINE_INFORMATION,
  URL_SETTINGS_MACHINES,
  URL_SETTINGS_OTHER,
  URL_SETTINGS_PROFILES,
  URL_SETTINGS_USERS,
  URL_WORK_QUEUES_DRIVING,
  URL_WORK_QUEUES_MAINTENANCE,
  URL_WORK_QUEUES_RENTING,
  URL_WORK_QUEUES_ON_RENT,
} from "./utils/urls";
import SettingsCatalogTransfer from "./pages/SettingsCatalogTransfer";
import WorkQueuesDriving from "./pages/WorkQueuesDriving";
import ManagementCommissions from "./pages/ManagementCommissions";
import SettingsCommissions from "./pages/SettingsCommissions";
import SettingsInspectionReturn from "./pages/SettingsInspectionReturn";
import SettingsInspectionGive from "./pages/SettingsInspectionGive";
import { onLogout } from "./utils/login";
import { getQueryFetchPolicy } from "./utils/getQueryFetchPolicy";
import ManagementInvoicesCreate from "./pages/ManagementInvoicesCreate";
import ManagementReportsDay from "./pages/ManagementReportsDay";
import SettingsDimensions from "./pages/SettingsDimensions";
import WorkQueuesOnRent from "./pages/WorkQueuesOnRent";
import SettingsCustomerAreas from "./pages/SettingsCustomerAreas";
import SettingsCustomerRanks from "./pages/SettingsCustomerRanks";
import SettingsCustomerIndustries from "./pages/SettingsCustomerIndustries";

export const UserContext = React.createContext(UserEmpty);
export const PermissionsContext = React.createContext<string[]>([]);
export const SettingsContext = React.createContext(SettingsEmpty);
export const AuthContext = React.createContext<AuthType>(AuthEmpty);

export type AuthType = {
  Calendar: any;
  Customers: any;
  Profiles: any;
  Users: any;
  Logs: any;
  Holidays: any;
  Locations: any;
  CatalogNew: any;
  Catalogs: any;
  CatalogsExtraRental: any;
  CatalogsExtraUnitPrice: any;
  CatalogsTransfer: any;
  Settings: any;
  Machines: any;
  MachineInformation: any;
  InspectionReturn: any;
  InspectionGive: any;
  SettingsCommissions: any;
  SettingsCustomerAreas: any;
  SettingsCustomerRanks: any;
  SettingsCustomerIndustries: any;
  SettingsOther: any;
  Discounts: any;
  WorkQueues: any;
  WorkQueuesDrivingCreate: any;
  WorkQueuesRenting: any;
  WorkQueuesMaintenance: any;
  WorkQueuesOnRent: any;
  Management: any;
  ManagementInvoicesCreate: any;
  ManagementInvoicesOpen: any;
  ManagementInvoicesSent: any;
  ManagementCommissions: any;
  ManagementReportsDay: any;
  Dimensions: any;
};

interface Props extends WithStyles<typeof styles> {}

const Root = ({ classes }: Props) => {
  const { t } = useTranslation();

  const {
    loading: loadingMe,
    error: errorMe,
    data: dataMe,
  } = useQuery<QueryResultMe>(ME_QUERY, {
    fetchPolicy: getQueryFetchPolicy("me"),
  });

  if (loadingMe) return <Loading />;
  if (errorMe || !dataMe) {
    onLogout();
    window.location.reload();
    return <Error error={t("unauthorized")} />;
  }

  const currentUser = dataMe.me ? dataMe.me : UserEmpty;
  const permissions = dataMe.myPermissions ? dataMe.myPermissions : [];
  let settings = dataMe.settings ? dataMe.settings : SettingsEmpty;

  if (currentUser.UserInfo) {
    i18n.changeLanguage(currentUser.UserInfo.language.toLowerCase());
  }

  settings = {
    ...settings,
    vatFactor: Number(settings.vatFactor),
  };

  const Auth: AuthType = {
    Calendar: Authorization(
      permissions,
      ["reservations.view_reservation", "orders.view_order"],
      "AND"
    ),
    Customers: Authorization(permissions, ["customers.view_customer"]),
    Profiles: Authorization(
      permissions,
      ["auth.view_permission", "auth.view_group"],
      "AND"
    ),
    Users: Authorization(permissions, ["auth.view_user"]),
    Logs: Authorization(permissions, ["logs.view_log"]),
    Holidays: Authorization(permissions, ["holidays.view_holiday"]),
    Locations: Authorization(permissions, ["locations.view_location"]),
    CatalogNew: Authorization(permissions, ["catalogs.add_catalog"]),
    Catalogs: Authorization(permissions, ["catalogs.view_catalog"]),
    CatalogsExtraRental: Authorization(permissions, [
      "catalogs_extra.view_catalogextracategoryrental",
    ]),
    CatalogsExtraUnitPrice: Authorization(permissions, [
      "catalogs_extra.view_catalogextracategoryunitprice",
    ]),
    CatalogsTransfer: Authorization(
      permissions,
      [
        "catalogs_transfer.view_catalogtransfermethod",
        "catalogs_transfer.view_catalogtransferprice",
      ],
      "AND"
    ),
    Settings: Authorization(permissions, [
      "auth.view_permission",
      "auth.view_user",
      "logs.view_log",
      "holidays.view_holiday",
      "locations.view_location",
      "catalogs.view_catalog",
      "catalogs_extra.view_catalogextracategoryrental",
      "catalogs_extra.view_catalogextracategoryunitprice",
      "catalogs_transfer.view_catalogtransfermethod",
      "discounts.view_discountlist",
      "machines.view_machine",
      "machines.view_machineinfocategory",
      "inspections.view_inspectionreturncategory",
      "settings.view_settings",
    ]),
    Machines: Authorization(permissions, ["machines.view_machine"]),
    MachineInformation: Authorization(
      permissions,
      [
        "machines.view_machineinfocategory",
        "machines.view_machineinfoquestion",
        "machines.view_machineinfotag",
      ],
      "AND"
    ),
    InspectionReturn: Authorization(
      permissions,
      [
        "inspections.view_inspectionreturncategory",
        "inspections.view_inspectionreturnquestion",
        "inspections.view_inspectionreturntag",
      ],
      "AND"
    ),
    InspectionGive: Authorization(
      permissions,
      [
        "inspections.view_inspectiongivecategory",
        "inspections.view_inspectiongivequestion",
        "inspections.view_inspectiongivetag",
      ],
      "AND"
    ),
    SettingsCommissions: Authorization(
      permissions,
      [
        "commissions.view_commissionusersetting",
        "commissions.view_commissionlevel",
      ],
      "AND"
    ),
    SettingsCustomerAreas: Authorization(permissions, [
      "customers.view_customerarea",
    ]),
    SettingsCustomerRanks: Authorization(permissions, [
      "customers.view_customerrank",
    ]),
    SettingsCustomerIndustries: Authorization(permissions, [
      "customers.view_customerindustry",
    ]),
    SettingsOther: Authorization(permissions, ["settings.view_settings"]),
    Discounts: Authorization(permissions, ["discounts.view_discountlist"]),
    WorkQueues: Authorization(
      permissions,
      ["reservations.view_reservation", "machines.view_machinebreakdown"],
      "AND"
    ),
    WorkQueuesDrivingCreate: Authorization(
      permissions,
      [
        "transports.view_transport",
        "drives.add_drive",
        "drives.add_drivecontent",
      ],
      "AND"
    ),
    WorkQueuesRenting: Authorization(permissions, [
      "reservations.view_reservation",
    ]),
    WorkQueuesMaintenance: Authorization(permissions, [
      "machines.view_machinebreakdown",
    ]),
    WorkQueuesOnRent: Authorization(permissions, [
      "reservations.view_reservation",
    ]),
    Management: Authorization(permissions, [
      "reservations.add_invoice",
      "invoices.view_invoice",
      "commissions.view_commission",
    ]),
    ManagementInvoicesCreate: Authorization(permissions, [
      "invoices.add_invoice",
    ]),
    ManagementInvoicesOpen: Authorization(
      permissions,
      ["invoices.view_invoice", "invoices.view_invoicerow"],
      "AND"
    ),
    ManagementInvoicesSent: Authorization(
      permissions,
      ["invoices.view_invoice", "invoices.view_invoicerow"],
      "AND"
    ),
    ManagementCommissions: Authorization(
      permissions,
      [
        "reservations.view_reservation",
        "commissions.view_commission",
        "commissions.view_commissionlevel",
        "commissions.view_commissionusersetting",
      ],
      "AND"
    ),
    ManagementReportsDay: Authorization(
      permissions,
      [
        "reports.view_reportprice",
        "reports.view_reportpricemonth",
        "reports.view_reportpricedayskipped",
      ],
      "AND"
    ),
    Dimensions: Authorization(
      permissions,
      [
        "costcenters.view_costcenter",
        "costtypes.view_costtype",
        "costtypes.view_costtypeforrentthrough",
      ],
      "OR"
    ),
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <UserContext.Provider value={currentUser}>
          <PermissionsContext.Provider value={permissions}>
            <SettingsContext.Provider value={settings}>
              <AuthContext.Provider value={Auth}>
                <Header currentUser={currentUser} Auth={Auth} />
                <Switch>
                  <Route exact path={URL_ROOT}>
                    <App Auth={Auth} />
                  </Route>
                  <Route path={getUrlProfile()} component={Profile} />
                  <Route
                    path={getUrlCalendar()}
                    component={Auth.Calendar(CalendarShow, Unauthorized)}
                  />
                  <Route
                    path={URL_CALENDAR}
                    component={Auth.Calendar(Calendar, Unauthorized)}
                  />
                  <Route
                    path={getUrlOfferDiscountList()}
                    component={Auth.Customers(CustomerOfferShow, Unauthorized)}
                  />
                  <Route
                    path={getUrlOfferCatalog()}
                    component={Auth.Customers(
                      CustomerOfferCatalogShow,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={getUrlCustomerTab()}
                    component={Auth.Customers(CustomerShow, Unauthorized)}
                  />
                  <Route
                    path={URL_CUSTOMERS}
                    component={Auth.Customers(Customers, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_PROFILES}
                    component={Auth.Profiles(SettingsProfiles, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_USERS}
                    component={Auth.Users(SettingsUsers, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_LOGS}
                    component={Auth.Logs(SettingsLogs, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_HOLIDAYS}
                    component={Auth.Holidays(SettingsHolidays, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_LOCATIONS}
                    component={Auth.Locations(SettingsLocations, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_CATALOG_NEW}
                    component={Auth.CatalogNew(
                      SettingsCatalogNew,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={getUrlSettingsCatalogEdit()}
                    component={Auth.Catalogs(SettingsCatalogEdit, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_CATALOG}
                    component={Auth.Catalogs(SettingsCatalog, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_CATALOG_EXTRA_RENTAL}
                    component={Auth.CatalogsExtraRental(
                      SettingsCatalogExtraRental,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_CATALOG_EXTRA_UNIT_PRICE}
                    component={Auth.CatalogsExtraUnitPrice(
                      SettingsCatalogExtraUnitPrice,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_CATALOG_TRANSFER}
                    component={Auth.CatalogsTransfer(
                      SettingsCatalogTransfer,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={getUrlSettingsDiscount()}
                    component={Auth.Discounts(
                      SettingsDiscountsSelected,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_DISCOUNTS}
                    component={Auth.Discounts(SettingsDiscounts, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_MACHINES}
                    component={Auth.Machines(SettingsMachines, Unauthorized)}
                  />
                  <Route
                    path={URL_SETTINGS_MACHINE_INFORMATION}
                    component={Auth.MachineInformation(
                      SettingsMachineInformation,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_INSPECTION_RETURN}
                    component={Auth.InspectionReturn(
                      SettingsInspectionReturn,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_INSPECTION_GIVE}
                    component={Auth.InspectionGive(
                      SettingsInspectionGive,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_DIMENSIONS}
                    component={Auth.Dimensions(
                      SettingsDimensions,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_CUSTOMER_AREAS}
                    component={Auth.SettingsCustomerAreas(
                      SettingsCustomerAreas,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_CUSTOMER_RANKS}
                    component={Auth.SettingsCustomerRanks(
                      SettingsCustomerRanks,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_CUSTOMER_INDUSTRIES}
                    component={Auth.SettingsCustomerIndustries(
                      SettingsCustomerIndustries,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_OTHER}
                    component={Auth.SettingsOther(SettingsOther, Unauthorized)}
                  />
                  <Route
                    path={URL_WORK_QUEUES_DRIVING}
                    component={Auth.WorkQueuesDrivingCreate(
                      WorkQueuesDriving,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_WORK_QUEUES_RENTING}
                    component={Auth.WorkQueuesRenting(
                      WorkQueuesRenting,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_WORK_QUEUES_MAINTENANCE}
                    component={Auth.WorkQueuesMaintenance(
                      WorkQueuesMaintenance,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_WORK_QUEUES_ON_RENT}
                    component={Auth.WorkQueuesOnRent(
                      WorkQueuesOnRent,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_INVOICES_CREATE}
                    component={Auth.ManagementInvoicesCreate(
                      ManagementInvoicesCreate,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_INVOICES_OPEN}
                    component={Auth.ManagementInvoicesOpen(
                      ManagementInvoicesOpen,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_INVOICES_SENT}
                    component={Auth.ManagementInvoicesSent(
                      ManagementInvoicesSent,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_REPORTS_COMMISSIONS}
                    component={Auth.ManagementCommissions(
                      ManagementCommissions,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_SETTINGS_COMMISSIONS}
                    component={Auth.SettingsCommissions(
                      SettingsCommissions,
                      Unauthorized
                    )}
                  />
                  <Route
                    path={URL_REPORTS_DAY}
                    component={Auth.ManagementReportsDay(
                      ManagementReportsDay,
                      Unauthorized
                    )}
                  />
                  <Route path={URL_404} component={ErrorNotFound} />
                  <Redirect to={URL_404} />
                </Switch>
              </AuthContext.Provider>
            </SettingsContext.Provider>
          </PermissionsContext.Provider>
        </UserContext.Provider>
      </Router>
    </I18nextProvider>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(withRoot(Root));
