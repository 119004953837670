import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Button, Container } from "react-bootstrap";
import { DiscountListEmpty } from "../entity/empties";
import { useParams } from "react-router-dom";
import {
  GET_DISCOUNT_LIST_QUERY,
  QueryResultDiscountList,
} from "../apollo/queries/discounts";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { updateVisibility } from "../utils/discounts/search";
import DiscountTable from "../components/Discount/table/DiscountTable";
import { URL_SETTINGS_DISCOUNTS } from "../utils/urls";
import { CONTAINER_MAIN_WIDTH_L } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function SettingsDiscountsSelected({ classes }: Props) {
  const { t } = useTranslation();

  let discount_list_id: string | undefined;
  ({ discount_list_id } = useParams());

  const [discountList, setDiscountList] = useState(DiscountListEmpty);
  const [search, setSearch] = useState("");
  const [catalogCategoriesUpperVisible, setCatalogCategoriesUpperVisible] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [catalogCategoriesVisible, setCatalogCategoriesVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [catalogRowsVisible, setCatalogRowsVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const { loading, error } = useQuery<QueryResultDiscountList>(
    GET_DISCOUNT_LIST_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("discountList"),
      variables: {
        discountListId: discount_list_id,
      },
      onCompleted: (data) => {
        if (data.discountList) {
          setDiscountList(data.discountList);

          let {
            catalogCategoryUpperVisible,
            catalogCategoryVisible,
            catalogRowVisible,
          } = updateVisibility(data.discountList.catalog, search);
          setCatalogCategoriesUpperVisible(catalogCategoryUpperVisible);
          setCatalogCategoriesVisible(catalogCategoryVisible);
          setCatalogRowsVisible(catalogRowVisible);
        }
      },
    }
  );

  useEffect(() => {
    let {
      catalogCategoryUpperVisible,
      catalogCategoryVisible,
      catalogRowVisible,
    } = updateVisibility(discountList.catalog, search);
    setCatalogCategoriesUpperVisible(catalogCategoryUpperVisible);
    setCatalogCategoriesVisible(catalogCategoryVisible);
    setCatalogRowsVisible(catalogRowVisible);
  }, [discountList, search]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <h2 className="headerPage">
        <LinkContainer to={URL_SETTINGS_DISCOUNTS}>
          <Button variant="outline-light">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </LinkContainer>
        &nbsp;
        {discountList.name}
        &nbsp;
        <small className="text-muted">
          - {discountList.isBusiness ? t("business") : t("private")}
        </small>
      </h2>
      <div className="containerInner">
        <DiscountTable
          catalog={discountList.catalog}
          discountList={discountList}
          setDiscountList={setDiscountList}
          search={search}
          setSearch={setSearch}
          catalogRowsVisible={catalogRowsVisible}
          catalogCategoriesUpperVisible={catalogCategoriesUpperVisible}
          catalogCategoriesVisible={catalogCategoriesVisible}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: { maxWidth: CONTAINER_MAIN_WIDTH_L },
    txtInputSemi: { minWidth: "300px" },
  });

export default withStyles(styles)(SettingsDiscountsSelected);
