import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Link, MenuItem, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { getUrlOffer } from "../../utils/urls";
import { getCustomerPriceLists } from "../../utils/customers/customer";
import { ID_EMPTY } from "../../utils/constants";
import { CustomerForCalendarType } from "../../apollo/queries/customers";

interface Props extends WithStyles<typeof styles> {
  customer: CustomerForCalendarType;
  menuItem?: string;
  catalogId?: string;
  discountListId?: string;
}

const CustomerPriceSource = ({
  classes,
  customer,
  menuItem = "",
  catalogId = ID_EMPTY,
  discountListId = ID_EMPTY,
}: Props) => {
  const { t } = useTranslation();

  let { catalogSelected, discountListSelected } =
    getCustomerPriceLists(customer);

  const renderLinkItem = () => {
    return (
      <p className="mb-4">
        {catalogSelected.id !== ID_EMPTY ? (
          <LinkContainer
            to={getUrlOffer(customer.id, ID_EMPTY, catalogSelected.id)}
          >
            <Link>{catalogSelected.name}</Link>
          </LinkContainer>
        ) : discountListSelected.id !== ID_EMPTY ? (
          <LinkContainer
            to={getUrlOffer(customer.id, discountListSelected.id, ID_EMPTY)}
          >
            <Link>{discountListSelected.name}</Link>
          </LinkContainer>
        ) : (
          t("not_selected")
        )}
      </p>
    );
  };

  const renderMenuItem = () => {
    return (
      <>
        {catalogId !== ID_EMPTY ? (
          <LinkContainer to={getUrlOffer(customer.id, ID_EMPTY, catalogId)}>
            <MenuItem>{menuItem}</MenuItem>
          </LinkContainer>
        ) : discountListId !== ID_EMPTY ? (
          <LinkContainer
            to={getUrlOffer(customer.id, discountListId, ID_EMPTY)}
          >
            <MenuItem>{menuItem}</MenuItem>
          </LinkContainer>
        ) : (
          t("not_selected")
        )}
      </>
    );
  };

  return menuItem?.length ? renderMenuItem() : renderLinkItem();
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerPriceSource);
