import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineInfoAnswerType,
  MachineInfoQuestionType,
  MachinesMachineInfoQuestionAnswerTypeChoices,
} from "../../entity/types";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  machineInfoQuestion: MachineInfoQuestionType;
  machineInfoAnswer: MachineInfoAnswerType;
}

function MachineInfoProductCard({
  classes,
  machineInfoQuestion,
  machineInfoAnswer,
}: Props) {
  const { t } = useTranslation();

  if (!machineInfoQuestion.showProductCard) {
    return <></>;
  }

  const answer =
    machineInfoQuestion.answerType ===
    MachinesMachineInfoQuestionAnswerTypeChoices.Options
      ? machineInfoAnswer.machineInfoQuestionOption
        ? machineInfoAnswer.machineInfoQuestionOption.title
        : t("not_selected")
      : machineInfoAnswer.answer;

  return (
    <div>
      <small>{machineInfoQuestion.question}</small>
      <p>{answer === "" ? <>&nbsp;</> : answer}</p>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineInfoProductCard);
