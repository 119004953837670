import { UserType } from "../../entity/types";
import {
  CatalogEmpty,
  CustomerEmpty,
  DiscountListEmpty,
  UserEmpty,
} from "../../entity/empties";
import {
  CUSTOMER_ID_CATALOG_COMPANY,
  CUSTOMER_ID_CATALOG_PRIVATE,
  DATE_FORMAT_ISO,
  ID_EMPTY,
} from "../constants";
import { TFunction } from "react-i18next";
import { format } from "date-fns";
import {
  CatalogSelectedFromCustomerForCalendarType,
  CustomerForCalendarType,
  DiscountListSelectedFromCustomerForCalendarType,
} from "../../apollo/queries/customers";

export function getCustomerPriceLists(customer: CustomerForCalendarType) {
  let catalogSelected: CatalogSelectedFromCustomerForCalendarType =
    CatalogEmpty;
  if (customer.catalogSelected) {
    catalogSelected = customer.catalogSelected;
  }
  let discountListSelected: DiscountListSelectedFromCustomerForCalendarType =
    DiscountListEmpty;
  if (catalogSelected.id === ID_EMPTY && customer.discountListSelected) {
    discountListSelected = customer.discountListSelected;
  }
  return { catalogSelected, discountListSelected };
}

export function getCustomerCatalogDefault(t: TFunction<"translation">) {
  return getCustomerCatalogPrivate(t);
}

export function getCustomerCatalogPrivate(t: TFunction<"translation">) {
  return {
    ...CustomerEmpty,
    id: CUSTOMER_ID_CATALOG_PRIVATE,
    name: t("catalog_private"),
    isBusiness: false,
    hasInsurance: CustomerEmpty.hasInsurance,
  };
}

export function getCustomerCatalogCompany(t: TFunction<"translation">) {
  return {
    ...CustomerEmpty,
    id: CUSTOMER_ID_CATALOG_COMPANY,
    name: t("catalog_company"),
    isBusiness: true,
    hasInsurance: CustomerEmpty.hasInsurance,
  };
}

export const getCustomerEmptyWithOwner = (user: UserType) => ({
  ...CustomerEmpty,
  userOwner: { ...UserEmpty, id: user.id },
});

export type CustomerListFiltersType = {
  canQuery: boolean;
  userIdsSelected: string[];
  isBusiness: string[];
  customerIndustryIds: string[];
  customerAreaIds: string[];
  customerRankIds: string[];
};

export const CustomerListFilterDefaults: CustomerListFiltersType = {
  canQuery: false,
  userIdsSelected: [ID_EMPTY],
  isBusiness: ["1"],
  customerIndustryIds: [ID_EMPTY],
  customerAreaIds: [ID_EMPTY],
  customerRankIds: [ID_EMPTY],
};

export const setCustomerListFilterDefaults = (
  defaults: CustomerListFiltersType
) => {
  localStorage.setItem("customerListFilterDefaults", JSON.stringify(defaults));
};

export const getCustomerListFilterDefaults = (): CustomerListFiltersType => {
  const jsonStr = localStorage.getItem("customerListFilterDefaults");
  return jsonStr === null ? CustomerListFilterDefaults : JSON.parse(jsonStr);
};

export const getContactNextDefault = (): string => {
  const today = new Date();
  const contactNextDefault = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    today.getDate()
  );
  return format(contactNextDefault, DATE_FORMAT_ISO);
};
