import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Paper, Theme } from "@material-ui/core";
import WorkQueueCardRenting from "./WorkQueueCardRenting";
import WorkQueueCardRentingTiny from "./WorkQueueCardRentingTiny";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import WorkQueuePdfButtons from "./WorkQueuePdfButtons";
import { ID_EMPTY } from "../../utils/constants";
import ButtonOrderDialog from "../Shared/ButtonOrderDialog";
import { getUrlCustomerTab } from "../../utils/urls";
import { LinkContainer } from "react-router-bootstrap";
import {
  OrderFromReservationsWorkQueuesType,
  ReservationFromReservationsWorkQueuesType,
} from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  order: OrderFromReservationsWorkQueuesType;
  reservations: ReservationFromReservationsWorkQueuesType[];
}

function WorkQueueCardGroupGive({ classes, order, reservations }: Props) {
  const { t } = useTranslation();

  const [showLargeCards, setShowLargeCards] = useState(false);

  return (
    <Paper className={classes.cardDrive} variant="outlined">
      <div className="pt-3 pb-2">
        <span className="text-muted" title={t("order_id_header")}>
          <ButtonOrderDialog orderId={order.id} />
        </span>{" "}
        {order.customer && (
          <LinkContainer to={getUrlCustomerTab(order.customer.id, "basic")}>
            <Button size="sm" variant="light">
              {order.customer.name}
            </Button>
          </LinkContainer>
        )}
      </div>
      <Button
        className={classes.btnToggle}
        variant="light"
        onClick={() => setShowLargeCards(!showLargeCards)}
      >
        {reservations.length}
        <FontAwesomeIcon
          className="ms-2"
          icon={showLargeCards ? faChevronUp : faChevronDown}
        />
      </Button>
      <WorkQueuePdfButtons driveId={ID_EMPTY} orders={[order]} />
      {reservations.map((reservation) => (
        <div key={reservation.id} className="mt-3">
          {showLargeCards && (
            <WorkQueueCardRenting reservation={reservation} type="give" />
          )}
          {!showLargeCards && (
            <WorkQueueCardRentingTiny reservation={reservation} type="give" />
          )}
        </div>
      ))}
    </Paper>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    cardDrive: {
      padding: spacing(2),
      paddingTop: 0,
      marginTop: spacing(2),
      position: "relative",
    },
    btnPdf: { position: "absolute", top: spacing(1), right: spacing(10) },
    btnToggle: {
      position: "absolute",
      top: spacing(1),
      right: spacing(1),
    },
  });

export default withStyles(styles)(WorkQueueCardGroupGive);
