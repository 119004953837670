import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { PermissionsContext, SettingsContext } from "../../Root";
import { Col, Row } from "react-bootstrap";
import { ID_EMPTY } from "../../utils/constants";
import { checkPermission } from "../../utils/permissions";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_COSTCENTERS_QUERY,
  QueryResultCostcenters,
} from "../../apollo/queries/costcenters";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import {
  GET_COSTTYPES_QUERY,
  QueryResultCosttypes,
} from "../../apollo/queries/costtypes";
import { Mutation, MutationUpdateSettingsArgs } from "../../entity/types";
import { UPDATE_SETTINGS_MUTATION } from "../../apollo/mutations/settings";
import { handleError } from "../../entity/ErrorHandler";
import DimensionsCosttypesForRentThrough from "./DimensionsCosttypesForRentThrough";

interface Props extends WithStyles<typeof styles> {}

function DimensionsSet({ classes }: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const {
    loading: loadingCenters,
    error: errorCenters,
    data: dataCenters,
  } = useQuery<QueryResultCostcenters>(GET_COSTCENTERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costcenters"),
  });

  const {
    loading: loadingTypes,
    error: errorTypes,
    data: dataTypes,
  } = useQuery<QueryResultCosttypes>(GET_COSTTYPES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costtypes"),
  });

  const [updateSettings, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateSettingsArgs
  >(UPDATE_SETTINGS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "settings.change_settings",
  ]);

  if (loadingCenters || loadingTypes || loadingUpdate) return <LoadingSimple />;
  if (errorCenters) return <Error error={errorCenters} />;
  if (errorTypes) return <Error error={errorTypes} />;
  if (!dataCenters || !dataTypes)
    return <Error error={t("error_query_failed")} />;

  const menuItemsCenters = dataCenters.costcenters?.map((costcenter) => (
    <MenuItem key={costcenter.id} value={costcenter.id}>
      {costcenter.number} - {costcenter.name}
    </MenuItem>
  ));

  const menuItemsTypes = dataTypes.costtypes?.map((costtype) => (
    <MenuItem key={costtype.id} value={costtype.id}>
      {costtype.number} - {costtype.name}
    </MenuItem>
  ));

  return (
    <div>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterCatalog">
              {t("costcenter_catalog")}
            </InputLabel>
            <Select
              labelId="lblCostcenterCatalog"
              value={
                settings.costcenterCatalog
                  ? settings.costcenterCatalog.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterCatalogId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeCatalog">
              {t("costtype_catalog")}
            </InputLabel>
            <Select
              labelId="lblCosttypeCatalog"
              value={
                settings.costtypeCatalog
                  ? settings.costtypeCatalog.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeCatalogId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterCatalogExtraRental">
              {t("costcenter_catalog_extra_rental")}
            </InputLabel>
            <Select
              labelId="lblCostcenterCatalogExtraRental"
              value={
                settings.costcenterCatalogExtraRental
                  ? settings.costcenterCatalogExtraRental.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterCatalogExtraRentalId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeCatalogExtraRental">
              {t("costtype_catalog_extra_rental")}
            </InputLabel>
            <Select
              labelId="lblCosttypeCatalogExtraRental"
              value={
                settings.costtypeCatalogExtraRental
                  ? settings.costtypeCatalogExtraRental.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeCatalogExtraRentalId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterCatalogTransfer">
              {t("costcenter_catalog_transfer")}
            </InputLabel>
            <Select
              labelId="lblCostcenterCatalogTransfer"
              value={
                settings.costcenterCatalogTransfer
                  ? settings.costcenterCatalogTransfer.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterCatalogTransferId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeCatalogTransfer">
              {t("costtype_catalog_transfer")}
            </InputLabel>
            <Select
              labelId="lblCosttypeCatalogTransfer"
              value={
                settings.costtypeCatalogTransfer
                  ? settings.costtypeCatalogTransfer.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeCatalogTransferId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterInsurance">
              {t("costcenter_insurance")}
            </InputLabel>
            <Select
              labelId="lblCostcenterInsurance"
              value={
                settings.costcenterInsurance
                  ? settings.costcenterInsurance.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterInsuranceId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeInsurance">
              {t("costtype_insurance")}
            </InputLabel>
            <Select
              labelId="lblCosttypeInsurance"
              value={
                settings.costtypeInsurance
                  ? settings.costtypeInsurance.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeInsuranceId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterBillingSurcharge">
              {t("costcenter_billing_surcharge")}
            </InputLabel>
            <Select
              labelId="lblCostcenterBillingSurcharge"
              value={
                settings.costcenterBillingSurcharge
                  ? settings.costcenterBillingSurcharge.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterBillingSurchargeId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeBillingSurcharge">
              {t("costtype_billing_surcharge")}
            </InputLabel>
            <Select
              labelId="lblCosttypeBillingSurcharge"
              value={
                settings.costtypeBillingSurcharge
                  ? settings.costtypeBillingSurcharge.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeBillingSurchargeId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterOpeningFee">
              {t("costcenter_opening_fee")}
            </InputLabel>
            <Select
              labelId="lblCostcenterOpeningFee"
              value={
                settings.costcenterOpeningFee
                  ? settings.costcenterOpeningFee.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterOpeningFeeId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeOpeningFee">
              {t("costtype_opening_fee")}
            </InputLabel>
            <Select
              labelId="lblCosttypeOpeningFee"
              value={
                settings.costtypeOpeningFee
                  ? settings.costtypeOpeningFee.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeOpeningFeeId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterBreakdownSingle">
              {t("costcenter_breakdown_single")}
            </InputLabel>
            <Select
              labelId="lblCostcenterBreakdownSingle"
              value={
                settings.costcenterBreakdownSingle
                  ? settings.costcenterBreakdownSingle.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterBreakdownSingleId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeBreakdownSingle">
              {t("costtype_breakdown_single")}
            </InputLabel>
            <Select
              labelId="lblCosttypeBreakdownSingle"
              value={
                settings.costtypeBreakdownSingle
                  ? settings.costtypeBreakdownSingle.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeBreakdownSingleId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterOpHours">
              {t("costcenter_op_hours")}
            </InputLabel>
            <Select
              labelId="lblCostcenterOpHours"
              value={
                settings.costcenterOpHours
                  ? settings.costcenterOpHours.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterOpHoursId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCosttypeOpHours">
              {t("costtype_op_hours")}
            </InputLabel>
            <Select
              labelId="lblCosttypeOpHours"
              value={
                settings.costtypeOpHours
                  ? settings.costtypeOpHours.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costtypeOpHoursId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {menuItemsTypes}
            </Select>
          </FormControl>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <FormControl fullWidth className="mt-2">
            <InputLabel id="lblCostcenterMachineRentThrough">
              {t("costcenter_machine_rent_through")}
            </InputLabel>
            <Select
              labelId="lblCostcenterMachineRentThrough"
              value={
                settings.costcenterMachineRentThrough
                  ? settings.costcenterMachineRentThrough.id
                  : ID_EMPTY
              }
              onChange={(event) => {
                updateSettings({
                  variables: {
                    costcenterMachineRentThroughId: String(event.target.value),
                  },
                });
              }}
              disabled={!hasPermissionEdit}
            >
              <MenuItem value={ID_EMPTY}>
                {t("costcenter_from_location")}
              </MenuItem>
              {menuItemsCenters}
            </Select>
          </FormControl>
        </Col>
        <Col>
          <DimensionsCosttypesForRentThrough />
        </Col>
      </Row>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DimensionsSet);
