import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  createStyles,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  Theme,
} from "@material-ui/core";
import {
  MachineInfoCategoryType,
  MachineInfoTagType,
  MachineType,
  Mutation,
  MutationAddMachineInfoTagToMachineArgs,
  MutationCopyMachineInfoTagsArgs,
  MutationCreateMachineInfoAnswerArgs,
} from "../../../entity/types";
import { useMutation } from "@apollo/client";
import { GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY } from "../../../apollo/queries/machines";
import { findFromSetById } from "../../../utils/collections";
import {
  MachineInfoQuestionEmpty,
  MachineInfoTagEmpty,
} from "../../../entity/empties";
import {
  ADD_MACHINE_INFO_TAG_TO_MACHINE_MUTATION,
  COPY_MACHINE_INFO_TAGS_MUTATION,
  CREATE_MACHINE_INFO_ANSWER_MUTATION,
} from "../../../apollo/mutations/machines";
import { handleError } from "../../../entity/ErrorHandler";
import { ID_EMPTY } from "../../../utils/constants";
import { getMachineName } from "../../../utils/machines/machine";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  machines: MachineType[];
  machineInfoTags: MachineInfoTagType[];
  machineInfoCategories: MachineInfoCategoryType[];
}

function DialogContentMachineInfoActions({
  classes,
  machine,
  machines,
  machineInfoTags,
  machineInfoCategories,
}: Props) {
  const { t } = useTranslation();

  const [machineInfoTagIdsToAdd, setMachineInfoTagIdsToAdd] = useState<
    string[]
  >([]);
  const [machineInfoQuestionIdsToAdd, setMachineInfoQuestionIdsToAdd] =
    useState<string[]>([]);

  const [addMachineInfoTagToMachine, resultAdd] = useMutation<
    Mutation,
    MutationAddMachineInfoTagToMachineArgs
  >(ADD_MACHINE_INFO_TAG_TO_MACHINE_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onCompleted: () => {
      setMachineInfoTagIdsToAdd([]);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [createMachineInfoAnswer, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateMachineInfoAnswerArgs
  >(CREATE_MACHINE_INFO_ANSWER_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onCompleted: () => {
      setMachineInfoQuestionIdsToAdd([]);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [copyMachineInfoTags, resultCopy] = useMutation<
    Mutation,
    MutationCopyMachineInfoTagsArgs
  >(COPY_MACHINE_INFO_TAGS_MUTATION, {
    refetchQueries: [
      {
        query: GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
        variables: {
          machineId: machine.id,
        },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddInfoAnswer = checkPermission(myPermissions, [
    "machines.add_machineinfoanswer",
  ]);

  if (!hasPermissionAddInfoAnswer) {
    return null;
  }

  const handleAddTagsToMachine = () => {
    if (machineInfoTagIdsToAdd.length > 0) {
      addMachineInfoTagToMachine({
        variables: {
          machineId: machine.id,
          machineInfoTagIds: machineInfoTagIdsToAdd,
        },
      });
    }
  };

  const handleAddQuestionToMachine = () => {
    if (machineInfoQuestionIdsToAdd.length > 0) {
      createMachineInfoAnswer({
        variables: {
          machineId: machine.id,
          machineInfoQuestionIds: machineInfoQuestionIdsToAdd,
          answer: "",
        },
      });
    }
  };

  const onChangeCopyMachineInfo = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    copyMachineInfoTags({
      variables: {
        machineIdFrom: String(event.target.value),
        machineIdTo: machine.id,
      },
    });
  };

  const renderSelectGroup = (
    machineInfoCategories: MachineInfoCategoryType[]
  ) => {
    return machineInfoCategories.map((machineInfoCategory) => {
      const itemsInner = machineInfoCategory.machineinfoquestionSet.map(
        (machineInfoQuestion) => {
          return (
            <MenuItem
              key={machineInfoQuestion.id}
              value={machineInfoQuestion.id}
            >
              <Checkbox
                checked={
                  machineInfoQuestionIdsToAdd.indexOf(machineInfoQuestion.id) >
                  -1
                }
              />
              {machineInfoQuestion.question}
            </MenuItem>
          );
        }
      );

      return [
        <ListSubheader>{machineInfoCategory.name}</ListSubheader>,
        itemsInner,
      ];
    });
  };

  return (
    <Paper
      className={`${classes.paper} ${
        resultAdd.loading || resultCopy.loading ? "loading" : ""
      } mb-5`}
    >
      <FormControl fullWidth>
        <InputLabel id="lblDialogMachineInfoTags">
          {t("machine_infos_from_tag")}
        </InputLabel>
        <Select
          value={machineInfoTagIdsToAdd}
          labelId="lblDialogMachineInfoTags"
          multiple={true}
          renderValue={(value) => {
            let names: string[] = [];
            (value as string[]).forEach((id) => {
              const machineInfoTag = findFromSetById(
                id,
                machineInfoTags,
                MachineInfoTagEmpty
              );
              if (machineInfoTag.id !== ID_EMPTY) {
                names.push(machineInfoTag.name);
              }
            });
            return names.join(", ");
          }}
          onChange={(event) => {
            setMachineInfoTagIdsToAdd(event.target.value as string[]);
          }}
          onClose={() => {
            handleAddTagsToMachine();
          }}
        >
          {machineInfoTags.map((machineInfoTag) => (
            <MenuItem key={machineInfoTag.id} value={machineInfoTag.id}>
              <Checkbox
                checked={machineInfoTagIdsToAdd.indexOf(machineInfoTag.id) > -1}
              />
              {machineInfoTag.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth className={loadingCreate ? "loading" : ""}>
        <InputLabel id="lblDialogMachineInfoAdd">
          {t("add_machine_info_questions")}
        </InputLabel>
        <Select
          labelId="lblDialogMachineInfoAdd"
          value={machineInfoQuestionIdsToAdd}
          multiple={true}
          renderValue={(value) => {
            let names: string[] = [];
            (value as string[]).forEach((id) => {
              let machineInfoQuestion = MachineInfoQuestionEmpty;
              machineInfoCategories.forEach((machineInfoCategory) => {
                const machineInfoQuestionFound = findFromSetById(
                  id,
                  machineInfoCategory.machineinfoquestionSet,
                  MachineInfoTagEmpty
                );
                if (machineInfoQuestionFound.id !== ID_EMPTY) {
                  machineInfoQuestion = machineInfoQuestionFound;
                  return;
                }
              });
              if (machineInfoQuestion.id !== ID_EMPTY) {
                names.push(machineInfoQuestion.question);
              }
            });
            return names.join(", ");
          }}
          onChange={(event) => {
            setMachineInfoQuestionIdsToAdd(event.target.value as string[]);
          }}
          onClose={() => {
            handleAddQuestionToMachine();
          }}
        >
          {renderSelectGroup(machineInfoCategories)}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblDialogMachineInfoCopy">
          {t("add_machine_info_copy")}
        </InputLabel>
        <Select
          value=""
          labelId="lblDialogMachineInfoCopy"
          onChange={onChangeCopyMachineInfo}
        >
          {machines.map((machineLooped) => {
            if (machineLooped.id === machine.id) {
              return undefined;
            }
            return (
              <MenuItem key={machineLooped.id} value={machineLooped.id}>
                {getMachineName(machineLooped, true)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Paper>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogContentMachineInfoActions);
