import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraRowRentalType,
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  QueryMaintenanceHistoryForCatalogExtraRowRentalArgs,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import { NextMaintenanceTypeEmpty } from "../../entity/empties";
import { getCatalogExtraRowRentalName } from "../../utils/machines/machine";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_MAINTENANCE_HISTORY_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY,
  QueryResultMaintenanceHistoryForCatalogExtraRowRental,
} from "../../apollo/queries/catalogs_extra";
import LoadingDialog from "../Shared/LoadingDialog";
import MaintenanceHistoryCatalogExtraRowRental from "../Maintenance/MaintenanceHistoryCatalogExtraRowRental";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogExtraRowRental: CatalogExtraRowRentalType;
}

function DialogCatalogExtraRowRentalInfo({
  classes,
  open,
  setOpen,
  catalogExtraRowRental,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultMaintenanceHistoryForCatalogExtraRowRental,
    QueryMaintenanceHistoryForCatalogExtraRowRentalArgs
  >(GET_MAINTENANCE_HISTORY_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machine"),
    variables: {
      catalogExtraRowRentalId: catalogExtraRowRental.id,
    },
  });

  if (error) return <Error error={error} />;
  if (loading) return <LoadingDialog />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose
        id="dialogTitleCatalogExtraRowRentalInfo"
        onClose={onClickClose}
      >
        {t("catalog_extra_row_information")}{" "}
        <small className="text-muted ms-3">
          {getCatalogExtraRowRentalName(catalogExtraRowRental)}
          {catalogExtraRowRental.identifier
            ? ", " + catalogExtraRowRental.identifier
            : ""}
        </small>
      </DialogTitleWithClose>
      <DialogContent className={loading ? "loading" : ""}>
        {catalogExtraRowRental.maintenancesetting?.scheduleType &&
          catalogExtraRowRental.maintenancesetting.scheduleType !==
            MaintenanceMaintenanceSettingScheduleTypeChoices.None && (
            <MaintenanceHistoryCatalogExtraRowRental
              catalogExtraRowRental={catalogExtraRowRental}
              nextMaintenance={
                data.maintenanceNextForCatalogExtraRowRental
                  ? data.maintenanceNextForCatalogExtraRowRental
                  : NextMaintenanceTypeEmpty
              }
              maintenances={
                data.maintenanceHistoryForCatalogExtraRowRental
                  ? data.maintenanceHistoryForCatalogExtraRowRental
                  : []
              }
              breakdowns={
                catalogExtraRowRental.machinebreakdownSet
                  ? catalogExtraRowRental.machinebreakdownSet
                  : []
              }
            />
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
  });

export default withStyles(styles)(DialogCatalogExtraRowRentalInfo);
