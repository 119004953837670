import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CostcenterType,
  MutationCreateCostcenterArgs,
  MutationDeleteCostcenterArgs,
  MutationUpdateCostcenterArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";
import {
  CREATE_COSTCENTER_MUTATION,
  DELETE_COSTCENTER_MUTATION,
  MutationResultCreateCostcenter,
  MutationResultDeleteCostcenter,
  MutationResultUpdateCostcenter,
  UPDATE_COSTCENTER_MUTATION,
} from "../../apollo/mutations/costcenters";
import TextField from "@material-ui/core/TextField";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { CostcenterEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  costcenter: CostcenterType;
  setCostcenter: React.Dispatch<React.SetStateAction<CostcenterType>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogCostcenter({
  classes,
  costcenter,
  setCostcenter,
  open,
  setOpen,
}: Props) {
  const { t } = useTranslation();

  const [createCostcenter, { loading: loadingCreate }] = useMutation<
    MutationResultCreateCostcenter,
    MutationCreateCostcenterArgs
  >(CREATE_COSTCENTER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("costcenters"),
      });
    },
  });

  const [updateCostcenter, { loading: loadingUpdate }] = useMutation<
    MutationResultUpdateCostcenter,
    MutationUpdateCostcenterArgs
  >(UPDATE_COSTCENTER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("costcenters"),
      });
    },
  });

  const [deleteCostcenter, { loading: loadingDelete }] = useMutation<
    MutationResultDeleteCostcenter,
    MutationDeleteCostcenterArgs
  >(DELETE_COSTCENTER_MUTATION, {
    onCompleted: () => {
      setOpen(false);
      setCostcenter(CostcenterEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("costcenters"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "costcenters.add_costcenter",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "costcenters.change_costcenter",
  ]);
  const disabled =
    costcenter.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (costcenter.id === ID_EMPTY) {
      createCostcenter({
        variables: {
          number: costcenter.number,
          name: costcenter.name,
        },
      });
    } else {
      updateCostcenter({
        variables: {
          costcenterId: costcenter.id,
          number: costcenter.number,
          name: costcenter.name,
        },
      });
    }
    setOpen(false);
  };

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCostcenter({
        variables: {
          costcenterId: costcenter.id,
        },
      });
    });
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("costcenter")}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t("identifier")}
            onChange={(event) => {
              setCostcenter({ ...costcenter, number: event.target.value });
            }}
            inputProps={{ maxLength: 10 }}
            value={costcenter.number}
            disabled={disabled}
            required={true}
          />
          <TextField
            fullWidth
            label={t("name")}
            onChange={(event) => {
              setCostcenter({ ...costcenter, name: event.target.value });
            }}
            inputProps={{ maxLength: 100 }}
            value={costcenter.name}
            disabled={disabled}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingDelete}
            type="button"
            onClick={onClickDelete}
            variant="light"
          >
            {t("delete")}
          </ButtonLoad>
          <ButtonLoad
            loading={loadingCreate || loadingUpdate}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCostcenter);
