import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogExtraCategoryRentalType,
  CatalogExtraCategoryUpperRentalType,
  Mutation,
  MutationDeleteCatalogExtraCategoryRentalArgs,
} from "../../../entity/types";
import { Button, Table } from "react-bootstrap";
import { formatNumber } from "../../../utils/formatting";
import { PermissionsContext, SettingsContext } from "../../../Root";
import CatalogExtraRentalRow from "./CatalogExtraRentalRow";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../../entity/ErrorHandler";
import { checkPermission } from "../../../utils/permissions";
import { DELETE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION } from "../../../apollo/mutations/catalogs_extra";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import DialogCatalogExtraCategoryRental from "../DialogCatalogExtraCategoryRental";
import { dialogConfirm } from "../../../utils/dialogs";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../../apollo/queries/locations";
import Loading from "../../Shared/Loading";
import Error from "../../Shared/Error";
import { ROOT_QUERY } from "../../../utils/constants";
import { getQueryKey } from "../../../utils/cache";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import ButtonLoad from "../../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  index: number;
  catalogExtraCategoryRental: CatalogExtraCategoryRentalType;
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
  locationIdsSelected: string[];
}

function CatalogExtraRentalTable({
  classes,
  index,
  catalogExtraCategoryRental,
  catalogExtraCategoriesUpperRental,
  locationIdsSelected,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const { loading, error, data } = useQuery<QueryResultLocations>(
    GET_LOCATIONS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("locations"),
    }
  );

  const [deleteCatalogExtraCategory, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogExtraCategoryRentalArgs
  >(DELETE_CATALOG_EXTRA_CATEGORY_RENTAL_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextracategoryrental",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_extra.delete_catalogextracategoryrental",
  ]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickEdit = () => {
    setOpenDialogEdit(true);
  };
  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogExtraCategory({
        variables: {
          catalogExtraCategoryRentalId: catalogExtraCategoryRental.id,
        },
      });
    });
  };

  const catalogExtraRowsRental =
    catalogExtraCategoryRental.catalogextrarowrentalSet.filter(
      (catalogExtraRowRental) =>
        locationIdsSelected.length === 0 ||
        locationIdsSelected.includes(catalogExtraRowRental.location.id)
    );

  if (locationIdsSelected.length > 0 && catalogExtraRowsRental.length === 0) {
    return <></>;
  }

  return (
    <Table
      className="tblCatalog extra rental"
      borderless={true}
      key={catalogExtraCategoryRental.id}
    >
      <thead>
        {index === 0 && (
          <tr className="text-muted">
            <th></th>
            <th>{t("company")}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>{t("private")}</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        )}
        <tr>
          <th className={classes.thTitle}>{catalogExtraCategoryRental.name}</th>
          <th>{t("price_day_company_vat0")}</th>
          <th>
            {t("price_day_company_vat1", {
              vat: formatNumber(settings.vat),
            })}
          </th>
          <th>{t("price_month_company_vat0")}</th>
          <th>
            {t("price_month_company_vat1", {
              vat: formatNumber(settings.vat),
            })}
          </th>
          <th>{t("billing_days_week_company")}</th>
          <th></th>
          <th>{t("price_day_private_vat0")}</th>
          <th>
            {t("price_day_private_vat1", {
              vat: formatNumber(settings.vat),
            })}
          </th>
          <th>{t("price_month_private_vat0")}</th>
          <th>
            {t("price_month_private_vat1", {
              vat: formatNumber(settings.vat),
            })}
          </th>
          <th>{t("billing_days_week_private")}</th>
          <th></th>
          <th>{t("location")}</th>
          <th>{t("show_print")}</th>
          <th>
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickEdit}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionDelete && (
              <ButtonLoad
                loading={loadingDelete}
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonLoad>
            )}
            {hasPermissionEdit && openDialogEdit && (
              <DialogCatalogExtraCategoryRental
                open={openDialogEdit}
                setOpen={setOpenDialogEdit}
                catalogExtraCategoryRental={catalogExtraCategoryRental}
                catalogExtraCategoriesUpperRental={
                  catalogExtraCategoriesUpperRental
                }
              />
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {catalogExtraRowsRental.map((catalogExtraRowRental) => {
          return (
            <CatalogExtraRentalRow
              key={catalogExtraRowRental.id}
              catalogExtraRowRental={catalogExtraRowRental}
              locations={data.locations ? data.locations : []}
              catalogExtraCategoriesUpperRental={
                catalogExtraCategoriesUpperRental
              }
            />
          );
        })}
      </tbody>
    </Table>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    conCatalogExtra: {
      backgroundColor: "#FFFFFF",
      padding: spacing(3),
    },
    thTitle: {
      color: palette.primary.main,
    },
    btn: {
      marginRight: spacing(0.5),
    },
  });

export default withStyles(styles)(CatalogExtraRentalTable);
