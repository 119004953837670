import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import WorkQueueCardDrive from "./WorkQueueCardDrive";
import { DriveFromDrivesForWorkQueuesType } from "../../apollo/queries/drives";

interface Props extends WithStyles<typeof styles> {
  drives: DriveFromDrivesForWorkQueuesType[];
  id?: string;
}

function WorkQueueDriveRetrieve({ classes, drives, id }: Props) {
  const { t } = useTranslation();

  return (
    <div id={id} className="conWorkQueue">
      <div className="titleWorkQueue">
        {t("work_queues_renting_transport_retrieval")}
      </div>
      {drives.map((drive) => {
        return <WorkQueueCardDrive key={drive.id} drive={drive} />;
      })}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueDriveRetrieve);
