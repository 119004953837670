import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";
import { FRAG_CUSTOMER_FULL } from "../queries/customers";

export interface MutationResultCreateCustomer {
  createCustomer: Mutation["createCustomer"];
}
export const CREATE_CUSTOMER_MUTATION = gql`
  ${FRAG_CUSTOMER_FULL}
  mutation createCustomer(
    $customerAreaIds: [ID]!
    $customerRankId: ID!
    $customerIndustryIds: [ID]!
    $businessId: String!
    $name: String!
    $contactPersonFirstname: String!
    $contactPersonLastname: String!
    $email: String!
    $phone: String!
    $visitAddress: String!
    $visitPostcode: String!
    $visitDistrict: String!
    $invoicingAddress: String!
    $invoicingDistrict: String!
    $invoicingEmail: String!
    $invoicingPostcode: String!
    $invoicingEAddress: String!
    $invoicingEOperator: String!
    $contactNext: Date
    $isBusiness: Boolean!
    $creditInformationOk: Boolean!
    $hasInsurance: Boolean!
    $creditInformationCheckedAt: Date
    $creditRiskClass: String!
    $blockedAt: DateTime
    $blockedReason: String!
    $preInvoicingAt: DateTime
    $preInvoicingReason: String!
    $userOwnerId: ID!
    $customerIdParent: ID
    $useParentInvoicing: Boolean!
    $useParentPricing: Boolean!
    $paymentTerm: Int
    $noBillingSurchargeAt: DateTime
  ) {
    createCustomer(
      customerAreaIds: $customerAreaIds
      customerRankId: $customerRankId
      customerIndustryIds: $customerIndustryIds
      businessId: $businessId
      name: $name
      contactPersonFirstname: $contactPersonFirstname
      contactPersonLastname: $contactPersonLastname
      email: $email
      phone: $phone
      visitAddress: $visitAddress
      visitPostcode: $visitPostcode
      visitDistrict: $visitDistrict
      invoicingAddress: $invoicingAddress
      invoicingDistrict: $invoicingDistrict
      invoicingEmail: $invoicingEmail
      invoicingPostcode: $invoicingPostcode
      invoicingEAddress: $invoicingEAddress
      invoicingEOperator: $invoicingEOperator
      contactNext: $contactNext
      isBusiness: $isBusiness
      creditInformationOk: $creditInformationOk
      hasInsurance: $hasInsurance
      creditInformationCheckedAt: $creditInformationCheckedAt
      creditRiskClass: $creditRiskClass
      blockedAt: $blockedAt
      blockedReason: $blockedReason
      preInvoicingAt: $preInvoicingAt
      preInvoicingReason: $preInvoicingReason
      userOwnerId: $userOwnerId
      customerIdParent: $customerIdParent
      useParentInvoicing: $useParentInvoicing
      useParentPricing: $useParentPricing
      paymentTerm: $paymentTerm
      noBillingSurchargeAt: $noBillingSurchargeAt
    ) {
      customer {
        ...CustomerFullFields
      }
    }
  }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
  ${FRAG_CUSTOMER_FULL}
  mutation updateCustomer(
    $customerId: ID!
    $customerAreaIds: [ID]
    $customerRankId: ID
    $customerIndustryIds: [ID]
    $businessId: String
    $name: String
    $contactPersonFirstname: String
    $contactPersonLastname: String
    $email: String
    $phone: String
    $visitAddress: String
    $visitPostcode: String
    $visitDistrict: String
    $invoicingAddress: String
    $invoicingDistrict: String
    $invoicingEmail: String
    $invoicingPostcode: String
    $invoicingEAddress: String
    $invoicingEOperator: String
    $contactNext: Date
    $isBusiness: Boolean
    $creditInformationOk: Boolean
    $hasInsurance: Boolean
    $creditInformationCheckedAt: Date
    $creditRiskClass: String
    $blockedAt: DateTime
    $blockedReason: String
    $preInvoicingAt: DateTime
    $preInvoicingReason: String
    $userOwnerId: ID
    $customerIdParent: ID
    $useParentInvoicing: Boolean
    $useParentPricing: Boolean
    $handledInvoicingAt: Date
    $discountGlobalCatalogRow: Decimal
    $discountGlobalCatalogExtra: Decimal
    $paymentTerm: Int
    $noBillingSurchargeAt: DateTime
  ) {
    updateCustomer(
      customerId: $customerId
      customerAreaIds: $customerAreaIds
      customerRankId: $customerRankId
      customerIndustryIds: $customerIndustryIds
      businessId: $businessId
      name: $name
      contactPersonFirstname: $contactPersonFirstname
      contactPersonLastname: $contactPersonLastname
      email: $email
      phone: $phone
      visitAddress: $visitAddress
      visitPostcode: $visitPostcode
      visitDistrict: $visitDistrict
      invoicingAddress: $invoicingAddress
      invoicingDistrict: $invoicingDistrict
      invoicingEmail: $invoicingEmail
      invoicingPostcode: $invoicingPostcode
      invoicingEAddress: $invoicingEAddress
      invoicingEOperator: $invoicingEOperator
      contactNext: $contactNext
      isBusiness: $isBusiness
      creditInformationOk: $creditInformationOk
      hasInsurance: $hasInsurance
      creditInformationCheckedAt: $creditInformationCheckedAt
      creditRiskClass: $creditRiskClass
      blockedAt: $blockedAt
      blockedReason: $blockedReason
      preInvoicingAt: $preInvoicingAt
      preInvoicingReason: $preInvoicingReason
      userOwnerId: $userOwnerId
      customerIdParent: $customerIdParent
      useParentInvoicing: $useParentInvoicing
      useParentPricing: $useParentPricing
      handledInvoicingAt: $handledInvoicingAt
      discountGlobalCatalogRow: $discountGlobalCatalogRow
      discountGlobalCatalogExtra: $discountGlobalCatalogExtra
      paymentTerm: $paymentTerm
      noBillingSurchargeAt: $noBillingSurchargeAt
    ) {
      customer {
        ...CustomerFullFields
      }
    }
  }
`;

export const UPDATE_CUSTOMER_PARENT_MUTATION = gql`
  mutation updateCustomerParent($customerId: ID!, $customerIdParent: ID!) {
    updateCustomerParent(
      customerId: $customerId
      customerIdParent: $customerIdParent
    ) {
      customer {
        id
        customerParent {
          id
        }
      }
    }
  }
`;

export const DELETE_CUSTOMER_MUTATION = gql`
  mutation deleteCustomer($customerId: ID!) {
    deleteCustomer(customerId: $customerId) {
      customerId
    }
  }
`;

export const SELECT_CUSTOMER_DISCOUNT_LIST_MUTATION = gql`
  mutation selectCustomerDiscountList($customerId: ID!, $discountListId: ID!) {
    selectCustomerDiscountList(
      customerId: $customerId
      discountListId: $discountListId
    ) {
      customer {
        id
      }
    }
  }
`;

export const SELECT_CUSTOMER_CATALOG_MUTATION = gql`
  mutation selectCustomerCatalog($customerId: ID!, $catalogId: ID!) {
    selectCustomerCatalog(customerId: $customerId, catalogId: $catalogId) {
      customer {
        id
      }
    }
  }
`;

export const SELECT_CUSTOMER_CATALOG_ACTIVE_MUTATION = gql`
  mutation selectCustomerCatalogActive($customerId: ID!) {
    selectCustomerCatalogActive(customerId: $customerId) {
      customer {
        id
      }
    }
  }
`;

export const SELECT_LINKED_CUSTOMER_PRICING = gql`
  mutation selectLinkedCustomerPricing(
    $customerId: ID!
    $useParentPricing: Boolean!
  ) {
    selectLinkedCustomerPricing(
      customerId: $customerId
      useParentPricing: $useParentPricing
    ) {
      customer {
        name
        useParentPricing
        catalogSelected {
          id
        }
        discountListSelected {
          id
        }
        customerParent {
          catalogSelected {
            id
          }
          discountListSelected {
            id
          }
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER_RANK_MUTATION = gql`
  mutation createCustomerRank($order: Int!, $title: String!) {
    createCustomerRank(order: $order, title: $title) {
      customerRank {
        id
        order
        title
        createdAt
      }
    }
  }
`;

export const UPDATE_CUSTOMER_RANK_MUTATION = gql`
  mutation updateCustomerRank($id: ID!, $order: Int, $title: String) {
    updateCustomerRank(id: $id, order: $order, title: $title) {
      customerRank {
        id
        order
        title
      }
    }
  }
`;

export const DELETE_CUSTOMER_RANK_MUTATION = gql`
  mutation deleteCustomerRank($id: ID!) {
    deleteCustomerRank(id: $id) {
      id
    }
  }
`;

export const CREATE_CUSTOMER_AREA_MUTATION = gql`
  mutation createCustomerArea($title: String!) {
    createCustomerArea(title: $title) {
      customerArea {
        id
        title
        createdAt
      }
    }
  }
`;

export const UPDATE_CUSTOMER_AREA_MUTATION = gql`
  mutation updateCustomerArea($id: ID!, $title: String) {
    updateCustomerArea(id: $id, title: $title) {
      customerArea {
        id
        title
      }
    }
  }
`;

export const DELETE_CUSTOMER_AREA_MUTATION = gql`
  mutation deleteCustomerArea($id: ID!) {
    deleteCustomerArea(id: $id) {
      id
    }
  }
`;

export const CREATE_CUSTOMER_INDUSTRY_MUTATION = gql`
  mutation createCustomerIndustry($title: String!) {
    createCustomerIndustry(title: $title) {
      customerIndustry {
        id
        title
        createdAt
      }
    }
  }
`;

export const UPDATE_CUSTOMER_INDUSTRY_MUTATION = gql`
  mutation updateCustomerIndustry($id: ID!, $title: String) {
    updateCustomerIndustry(id: $id, title: $title) {
      customerIndustry {
        id
        title
      }
    }
  }
`;

export const DELETE_CUSTOMER_INDUSTRY_MUTATION = gql`
  mutation deleteCustomerIndustry($id: ID!) {
    deleteCustomerIndustry(id: $id) {
      id
    }
  }
`;
