import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import {
  InspectionReturnCategoryType,
  InspectionReturnQuestionType,
  Mutation,
  MutationUpdateInspectionReturnQuestionOrderArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getCatalogExtraSortStyles } from "../../utils/catalog_extra/catalog_extra";
import { getQueryKey } from "../../utils/cache";
import { findFromSetById, IdToIndexType } from "../../utils/collections";
import { InspectionReturnCategoryEmpty } from "../../entity/empties";
import { UPDATE_INSPECTION_RETURN_QUESTION_ORDER_MUTATION } from "../../apollo/mutations/inspections";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnCategories: InspectionReturnCategoryType[];
}

function InspectionReturnSortQuestions({
  classes,
  inspectionReturnCategories,
}: Props) {
  const { t } = useTranslation();

  const [
    inspectionReturnCategoryIdSelected,
    setInspectionReturnCategoryIdSelected,
  ] = useState(ID_EMPTY);

  const [updateCatalogExtraRowRentalOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateInspectionReturnQuestionOrderArgs
  >(UPDATE_INSPECTION_RETURN_QUESTION_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("inspectionReturnCategories"),
      });
    },
  });

  const inspectionReturnCategory: InspectionReturnCategoryType =
    findFromSetById(
      inspectionReturnCategoryIdSelected,
      inspectionReturnCategories,
      InspectionReturnCategoryEmpty
    );

  const inspectionReturnQuestions: InspectionReturnQuestionType[] =
    inspectionReturnCategory.inspectionreturnquestionSet
      ? inspectionReturnCategory.inspectionreturnquestionSet
      : [];

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let idToIndex: IdToIndexType[] = [];
    arrayMove(inspectionReturnQuestions, oldIndex, newIndex).map(
      (inspectionReturnQuestion: InspectionReturnQuestionType, index) => {
        idToIndex.push({
          id: inspectionReturnQuestion.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogExtraRowRentalOrder({
      variables: { idOrderInput: idToIndex },
    });
  };

  const DragHandleRow = SortableHandle(() => (
    <span className={classes.elSort}>
      <FontAwesomeIcon icon={faArrowsV} size="lg" />
    </span>
  ));

  const RowContainer = SortableElement(({ inspectionReturnQuestion }: any) => (
    <div key={inspectionReturnQuestion.id} className="mb-3">
      <DragHandleRow />
      {inspectionReturnQuestion.question}
    </div>
  ));

  const RowsContainer = SortableContainer(({ items }: any) => {
    return (
      <div className={loading ? "loading" : ""}>
        {items.map(
          (
            inspectionReturnQuestion: InspectionReturnQuestionType,
            index: number
          ) => (
            <RowContainer
              key={inspectionReturnQuestion.id}
              index={index}
              inspectionReturnQuestion={inspectionReturnQuestion}
            />
          )
        )}
      </div>
    );
  });

  return (
    <div>
      <h5 className="mb-4">{t("sort_inspection_questions")}</h5>
      <FormControl className="mb-4" fullWidth>
        <InputLabel id="lblCatalogExtraRowCategoryFilter">
          {t("inspection_category")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogExtraRowCategoryFilter"
          value={inspectionReturnCategoryIdSelected}
          onChange={(event) => {
            setInspectionReturnCategoryIdSelected(String(event.target.value));
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {inspectionReturnCategories.map((inspectionReturnCategory) => (
            <MenuItem
              key={inspectionReturnCategory.id}
              value={inspectionReturnCategory.id}
            >
              {inspectionReturnCategory.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <RowsContainer
        items={inspectionReturnQuestions}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    elSort: getCatalogExtraSortStyles(theme),
  });

export default withStyles(styles)(InspectionReturnSortQuestions);
