import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/formatting";
import {
  CatalogCategoryUpperType,
  CatalogRowType,
  DiscountListType,
  DiscountRowType,
} from "../../../entity/types";
import { PermissionsContext, SettingsContext } from "../../../Root";
import {
  addStringToArr,
  removeStringFromArr,
} from "../../../utils/collections";
import { checkPermission } from "../../../utils/permissions";
import { isDiscountRowDisabled } from "./DiscountRow";

const getIsCheckedPrintDefault = (
  catalogRows: CatalogRowType[],
  catalogRowIdsPrinted: string[]
) =>
  !catalogRows.some(
    (catalogRow) => !catalogRowIdsPrinted.includes(catalogRow.id)
  );

interface Props extends WithStyles<typeof styles> {
  discountList: DiscountListType;
  catalogCategoryUpper: CatalogCategoryUpperType;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  catalogRowsVisible: { [key: string]: boolean };
  catalogRowIdsPrinted: string[];
  setCatalogRowIdsPrinted: React.Dispatch<React.SetStateAction<string[]>>;
}

const DiscountTheadCategoryUpper = ({
  classes,
  discountList,
  catalogCategoryUpper,
  selectedRows,
  setSelectedRows,
  catalogRowsVisible,
  catalogRowIdsPrinted,
  setCatalogRowIdsPrinted,
}: Props) => {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);

  let catalogRows: CatalogRowType[] = [];
  catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) => {
    catalogRows = [...catalogRows, ...catalogCategory.catalogrowSet];
  });

  const [isCheckedPrint, setIsCheckedPrint] = useState(
    getIsCheckedPrintDefault(catalogRows, catalogRowIdsPrinted)
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  useEffect(() => {
    setIsCheckedPrint(
      getIsCheckedPrintDefault(catalogRows, catalogRowIdsPrinted)
    );
    // eslint-disable-next-line
  }, [catalogRowIdsPrinted]);

  const onChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    let rowIds: string[] = [];
    discountList.discountrowSet.forEach(function (
      discountRowLooped: DiscountRowType
    ) {
      if (
        discountRowLooped.catalogRow.catalogCategory.catalogCategoryUpper.id ===
          catalogCategoryUpper.id &&
        catalogRowsVisible[discountRowLooped.catalogRow.id]
      ) {
        rowIds.push(discountRowLooped.id);
      }
    });

    if (event.target.checked) {
      setSelectedRows(addStringToArr(selectedRows, rowIds));
    } else {
      setSelectedRows(removeStringFromArr(selectedRows, rowIds));
    }
  };

  const checkboxShow = catalogCategoryUpper.catalogcategorySet.length > 1;
  let checkboxSelected = false;
  if (checkboxShow) {
    checkboxSelected = true;
    discountList.discountrowSet.forEach(function (discountRowLooped) {
      if (
        discountRowLooped.catalogRow.catalogCategory.catalogCategoryUpper.id ===
          catalogCategoryUpper.id &&
        selectedRows.indexOf(discountRowLooped.id) < 0
      ) {
        checkboxSelected = false;
        return;
      }
    });
  }

  const disabledDiscountRow = isDiscountRowDisabled(
    discountList,
    hasPermissionEdit
  );
  const useStateForPrint = disabledDiscountRow === true;

  const onChangePrint = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckedPrint(event.target.checked);

    const rowIds: string[] = catalogRows.map((catalogRow) => catalogRow.id);

    if (event.target.checked) {
      setCatalogRowIdsPrinted(addStringToArr(catalogRowIdsPrinted, rowIds));
    } else {
      setCatalogRowIdsPrinted(
        removeStringFromArr(catalogRowIdsPrinted, rowIds)
      );
    }
  };

  return (
    <thead>
      <tr className={`noBorder ${classes.tr}`}>
        {hasPermissionEdit && !discountList.locked && (
          <th>
            {checkboxShow && (
              <Checkbox
                checked={checkboxSelected}
                onChange={(event) => {
                  onChangeSelect(event);
                }}
              />
            )}
          </th>
        )}
        <th className={classes.thCategoryUpper}>
          <div className="pt-2 pb-1">{catalogCategoryUpper.name}</div>
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_day_company_vat0"
              : "price_day_private_vat0"
          )}
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_day_company_vat1"
              : "price_day_private_vat1",
            {
              vat: formatNumber(settings.vat),
            }
          )}
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_month_company_vat0"
              : "price_month_private_vat0"
          )}
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_month_company_vat1"
              : "price_month_private_vat1",
            {
              vat: formatNumber(settings.vat),
            }
          )}
        </th>
        <th className="cellPriceChange"></th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_day_company_vat0"
              : "price_day_private_vat0"
          )}
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_day_company_vat1"
              : "price_day_private_vat1",
            {
              vat: formatNumber(settings.vat),
            }
          )}
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_month_company_vat0"
              : "price_month_private_vat0"
          )}
        </th>
        <th>
          {t(
            discountList.isBusiness
              ? "price_month_company_vat1"
              : "price_month_private_vat1",
            {
              vat: formatNumber(settings.vat),
            }
          )}
        </th>
        <th className="cellPriceChange">
          {useStateForPrint && (
            <Checkbox checked={isCheckedPrint} onChange={onChangePrint} />
          )}
        </th>
      </tr>
    </thead>
  );
};

const styles = ({ palette }: Theme) =>
  createStyles({
    tr: {
      backgroundColor: "#FAFAFA",
    },
    thCategoryUpper: {
      fontSize: "1.3rem",
      color: palette.primary.main,
    },
  });

export default withStyles(styles)(DiscountTheadCategoryUpper);
