import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineInfoAnswerType,
  MachineType,
  QueryMachineArgs,
  QueryOperationHoursForMachineArgs,
} from "../../../entity/types";
import { useQuery } from "@apollo/client";
import { MachineEmpty } from "../../../entity/empties";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import LoadingSimple from "../../Shared/LoadingSimple";
import Error from "../../Shared/Error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import DialogContentMachineInfoActions from "./DialogContentMachineInfoActions";
import MachineInfoProductCard from "../MachineInfoProductCard";
import DialogContentMachineInfoAnswer from "./DialogContentMachineInfoAnswer";
import format from "date-fns/format";
import {
  GET_ESTIMATE_HOURS_FOR_MACHINE_QUERY,
  GET_OPERATION_HOURS_FOR_MACHINE_QUERY,
  QueryResultEstimatedHoursForMachine,
  QueryResultOperationHoursForMachine,
} from "../../../apollo/queries/operation_hours";
import { ID_EMPTY } from "../../../utils/constants";
import {
  GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY,
  QueryResultMachineWithMachineInfoCategories,
} from "../../../apollo/queries/machines";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  editable: boolean;
}

function DialogContentMachineInfo({ classes, machine, editable }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultMachineWithMachineInfoCategories,
    QueryMachineArgs
  >(GET_MACHINE_WITH_MACHINE_INFO_CATEGORIES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machine"),
    variables: {
      machineId: machine.id,
    },
  });

  const {
    loading: loadingOperationHours,
    error: errorOperationHours,
    data: dataOperationHours,
  } = useQuery<
    QueryResultOperationHoursForMachine,
    QueryOperationHoursForMachineArgs
  >(GET_OPERATION_HOURS_FOR_MACHINE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("operationHoursForMachine"),
    variables: {
      machineId: machine.id,
      reservationId: ID_EMPTY,
    },
  });

  const {
    loading: loadingEstimatedHours,
    error: errorEstimatedHours,
    data: dataEstimatedHours,
  } = useQuery<QueryResultEstimatedHoursForMachine, QueryMachineArgs>(
    GET_ESTIMATE_HOURS_FOR_MACHINE_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("estimatedHoursForMachine"),
      variables: {
        machineId: machine.id,
      },
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewOperationHours = checkPermission(myPermissions, [
    "operation_hours.view_operationhour",
  ]);
  const hasPermissionChangeAnswer = checkPermission(myPermissions, [
    "machines.change_machineinfoanswer",
  ]);

  if (!hasPermissionChangeAnswer) {
    editable = false;
  }

  if (loading || loadingOperationHours || loadingEstimatedHours) {
    return <LoadingSimple />;
  }
  if (errorOperationHours) return <Error error={errorOperationHours} />;
  if (errorEstimatedHours) return <Error error={errorEstimatedHours} />;
  if (error) return <Error error={error} />;
  if (!data || !dataOperationHours || !dataEstimatedHours) {
    return <Error error={t("error_query_failed")} />;
  }

  let machineInfoCategoryIdsVisible: string[] = [];
  let machineInfoQuestionToAnswer: {
    [question_id: string]: MachineInfoAnswerType;
  } = {};
  data?.machine?.machineinfoanswerSet.forEach((machineInfoAnswer) => {
    const question_id = machineInfoAnswer.machineInfoQuestion.id;
    const category_id =
      machineInfoAnswer.machineInfoQuestion.machineInfoCategory.id;
    machineInfoQuestionToAnswer[question_id] = machineInfoAnswer;
    if (!machineInfoCategoryIdsVisible.includes(category_id)) {
      machineInfoCategoryIdsVisible.push(category_id);
    }
  });

  const getHoursText = () => {
    let hoursText = "";
    if (
      hasPermissionViewOperationHours &&
      dataOperationHours.operationHoursForMachine?.length
    ) {
      hoursText = t(
        "operation_hours_" +
          dataOperationHours.operationHoursForMachine[0].source,
        {
          date: format(
            new Date(
              dataOperationHours.operationHoursForMachine[0].dateChecked
            ),
            t("format_date")
          ),
          hours: dataOperationHours.operationHoursForMachine[0].hours,
        }
      );

      if (
        dataEstimatedHours.estimatedHoursForMachine?.hours &&
        dataEstimatedHours.estimatedHoursForMachine.hours !== null &&
        dataEstimatedHours.estimatedHoursForMachine.hours > 0
      ) {
        hoursText +=
          " (" +
          t("hour_estimate", {
            hours: dataEstimatedHours.estimatedHoursForMachine.hours,
          }) +
          ")";
      }
    }

    return hoursText;
  };

  const hoursText = getHoursText();

  return (
    <div>
      {hoursText.length > 0 && (
        <div className="mb-5">
          <h5>{t("hour_reading")}</h5>
          <small>{hoursText}</small>
        </div>
      )}
      {editable && (
        <>
          <p className="text-muted mb-4">
            <FontAwesomeIcon
              className={`${classes.info} me-1`}
              icon={faInfoCircle}
            />
            {t("explain_machine_info_to_machine")}
          </p>
          <DialogContentMachineInfoActions
            machine={data?.machine ? data.machine : MachineEmpty}
            machines={data?.machines ? data.machines : []}
            machineInfoTags={data?.machineInfoTags ? data.machineInfoTags : []}
            machineInfoCategories={
              data?.machineInfoCategories ? data.machineInfoCategories : []
            }
          />
        </>
      )}
      {data?.machineInfoCategories?.map((machineInfoCategory) => {
        if (!machineInfoCategoryIdsVisible.includes(machineInfoCategory.id)) {
          return undefined;
        }
        return (
          <div key={machineInfoCategory.id} className="mb-5">
            <h5>{machineInfoCategory.name}</h5>
            {machineInfoCategory.machineinfoquestionSet.map(
              (machineInfoQuestion) => {
                if (
                  typeof machineInfoQuestionToAnswer[machineInfoQuestion.id] ===
                  "undefined"
                ) {
                  return undefined;
                }
                if (!editable) {
                  return (
                    <MachineInfoProductCard
                      key={machineInfoQuestion.id}
                      machineInfoQuestion={machineInfoQuestion}
                      machineInfoAnswer={
                        machineInfoQuestionToAnswer[machineInfoQuestion.id]
                      }
                    />
                  );
                }
                return (
                  <DialogContentMachineInfoAnswer
                    key={machineInfoQuestion.id}
                    machine={machine}
                    machineInfoQuestion={machineInfoQuestion}
                    machineInfoAnswer={
                      machineInfoQuestionToAnswer[machineInfoQuestion.id]
                    }
                  />
                );
              }
            )}
          </div>
        );
      })}
    </div>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
  });

export default withStyles(styles)(DialogContentMachineInfo);
