import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  GroupType,
  Mutation,
  MutationUpdateGroupArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ButtonLoad from "../Shared/ButtonLoad";
import Dialog from "@material-ui/core/Dialog";
import { UPDATE_PROFILE_MUTATION } from "../../apollo/mutations/profiles";

interface Props extends WithStyles<typeof styles> {
  group: GroupType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogProfileEdit({ classes, group, open, setOpen }: Props) {
  const { t } = useTranslation();

  const [groupEdited, setGroupEdited] = useState(group);

  const [updateProfile, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateGroupArgs
  >(UPDATE_PROFILE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateProfile({
      variables: {
        groupId: groupEdited.id,
        name: groupEdited.name,
      },
    });
    setOpen(false);
  };

  const permissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(permissions, ["auth.change_group"]);

  if (!hasPermissionEdit) {
    return null;
  }

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("edit_profile")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label={t("name")}
              onChange={(event) =>
                setGroupEdited({ ...groupEdited, name: event.target.value })
              }
              value={groupEdited.name}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingUpdate}
            disabled={!groupEdited.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogProfileEdit);
