import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import {
  InspectionReturnAnswerType,
  InspectionsInspectionReturnQuestionAnswerTypeChoices,
  InspectionReturnQuestionType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnAnswer: InspectionReturnAnswerType;
  inspectionReturnQuestion: InspectionReturnQuestionType;
}

function InspectionReturnAnswerReport({
  classes,
  inspectionReturnAnswer,
  inspectionReturnQuestion,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <FormControl fullWidth className="mb-4">
        {inspectionReturnQuestion.answerType ===
          InspectionsInspectionReturnQuestionAnswerTypeChoices.Text && (
          <div>
            <label className="small">{inspectionReturnQuestion.question}</label>
            <div>{inspectionReturnAnswer.answer}</div>
          </div>
        )}
        {inspectionReturnQuestion.answerType ===
          InspectionsInspectionReturnQuestionAnswerTypeChoices.Options && (
          <div>
            <label className="small">{inspectionReturnQuestion.question}</label>
            <div>
              {inspectionReturnAnswer.inspectionReturnQuestionOption?.title}
            </div>
          </div>
        )}
        {inspectionReturnQuestion.answerType ===
          InspectionsInspectionReturnQuestionAnswerTypeChoices.Check && (
          <>
            <div>
              <label className="small">
                {inspectionReturnQuestion.question}
              </label>
              {inspectionReturnAnswer.checked === true && (
                <div>{t("check_ok")}</div>
              )}
              {inspectionReturnAnswer.checked === false && (
                <div>
                  {t("check_not_ok")}
                  <div className="text-muted">
                    {inspectionReturnAnswer.answer}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionReturnAnswerReport);
