import {
  MachinesMachineBreakdownBillingChoices,
  MaintenanceMaintenanceMaintenanceTypeChoices,
  OrdersOrderStatusChoices,
} from "../../entity/types";
import { datesEqual, newDate, resetDateTime } from "../dates";
import React from "react";
import format from "date-fns/format";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faExclamationTriangle,
  faTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { TFunction } from "react-i18next";
import {
  MachineBreakdownFromCalendarType,
  MaintenanceFromCalendarType,
  ReservationFromCalendarType,
} from "../../apollo/queries/catalogs";
import { LocationOfMachineForCatalogRowsType } from "../../apollo/queries/machines";

export const getCellClassName = (
  date: Date,
  reservations: ReservationFromCalendarType[]
): string => {
  let className = ``;
  let today = resetDateTime(new Date());

  let countReservationsOffer = 0;
  let countReservationsAdvance = 0;
  let countReservationsReserved = 0;
  let countReservationsStored = 0;
  reservations.forEach((reservationLooped) => {
    if (reservationLooped.order.status === OrdersOrderStatusChoices.Offer) {
      countReservationsOffer++;
    } else if (
      reservationLooped.order.status === OrdersOrderStatusChoices.Advance
    ) {
      countReservationsAdvance++;
    } else {
      countReservationsReserved++;
    }

    if (reservationLooped.returnStoredAt) {
      countReservationsStored++;
    }
  });

  const allReservationsStored =
    reservations.length > 0 && reservations.length === countReservationsStored;

  if (date.getDay() === 0) {
    className += " calCellSunday";
  }
  if (date.getDay() === 6) {
    className += " calCellSaturday";
  }
  if (datesEqual(date, today)) {
    className += " calCellToday";
  }
  if (countReservationsOffer > 0) {
    className += " calCellWithOffer";
  }
  if (countReservationsAdvance > 0) {
    className += " calCellWithAdvance";
  }
  if (countReservationsReserved > 0) {
    className += " calCellWithReservation";
  }
  if (allReservationsStored) {
    className += " calCellWithReservationStored";
  }

  return className;
};

export const getCellTitle = (
  date: Date,
  reservations: ReservationFromCalendarType[],
  location?: LocationOfMachineForCatalogRowsType
): string => {
  let title = format(date, "dd.MM.yyyy");
  reservations.forEach((reservationLooped) => {
    title = title + "\n" + reservationLooped.order.customer?.name;

    if (location && reservationLooped.order.location.id !== location.id) {
      title += " (" + reservationLooped.order.location.name + ")";
    }
  });
  return title;
};

export const getLocalStorageOrderId = () => {
  const orderId = localStorage.getItem("orderId");
  return orderId === null || orderId === "" ? ID_EMPTY : String(orderId);
};

export const setLocalStorageOrderId = (orderId: string) => {
  localStorage.setItem("orderId", orderId);
};

export const setLocalStorageCustomerId = (customerId: string) => {
  localStorage.setItem("customerId", customerId);
};

export const getLocalStorageCustomerId = () =>
  localStorage.getItem("customerId") === null ||
  localStorage.getItem("customerId") === ""
    ? ID_EMPTY
    : String(localStorage.getItem("customerId"));

export const selectOrder = (
  orderId: string,
  setOrderId: React.Dispatch<React.SetStateAction<string>>
) => {
  setLocalStorageOrderId(orderId);
  setOrderId(orderId);
};

export type reservationsByDateType = {
  [keyId: string]: { [date: string]: ReservationFromCalendarType[] };
};

const createReservationsByDate = (
  reservationsByDate: reservationsByDateType,
  reservation: ReservationFromCalendarType,
  keyId: string
) => {
  let date = newDate(reservation.dateRented);
  // check for reservation that has not been returned ....
  let dateReturned =
    newDate(reservation.dateReturned) >= new Date() || !reservation.giveAt
      ? newDate(reservation.dateReturned)
      : reservation.returnAt
      ? newDate(reservation.returnAt)
      : new Date();

  while (date <= dateReturned) {
    let keyDate = format(date, DATE_FORMAT_ISO);
    if (typeof reservationsByDate[keyId] === "undefined") {
      reservationsByDate[keyId] = {};
    }
    if (typeof reservationsByDate[keyId][keyDate] === "undefined") {
      reservationsByDate[keyId][keyDate] = [];
    }

    reservationsByDate[keyId][keyDate].push(reservation);

    date.setDate(date.getDate() + 1);
  }
  return reservationsByDate;
};

export const createReservationsByMachineDate = (
  reservations: ReservationFromCalendarType[]
) => {
  let reservationsByDate: reservationsByDateType = {};

  for (let reservation of reservations) {
    if (!reservation.catalogExtraRowRental && reservation.machine) {
      reservationsByDate = createReservationsByDate(
        reservationsByDate,
        reservation,
        reservation.machine.id
      );
    }
  }

  return reservationsByDate;
};

export const createReservationsByRowExtraDate = (
  reservations: ReservationFromCalendarType[]
) => {
  let reservationsByDate: reservationsByDateType = {};

  for (let reservation of reservations) {
    if (reservation.catalogExtraRowRental) {
      reservationsByDate = createReservationsByDate(
        reservationsByDate,
        reservation,
        reservation.catalogExtraRowRental.id
      );
    }
  }

  return reservationsByDate;
};

type breakdownsByDateType = {
  [machine_id: string]: { [date: string]: MachineBreakdownFromCalendarType[] };
};

const createBreakdownsByDate = (
  breakdownsByDate: breakdownsByDateType,
  machineBreakdown: MachineBreakdownFromCalendarType,
  keyId: string
) => {
  let date = newDate(machineBreakdown.fixByStart);
  let dateReturned = newDate(machineBreakdown.fixByEnd);
  if (machineBreakdown.fixedAt !== null) {
    let dateFixedAt = newDate(machineBreakdown.fixedAt);
    if (dateFixedAt < date) {
      date = dateFixedAt;
    } else if (dateFixedAt > dateReturned) {
      dateReturned = dateFixedAt;
    }
  }

  date = resetDateTime(date);
  dateReturned = resetDateTime(dateReturned);

  while (date <= dateReturned) {
    let keyDate = format(date, DATE_FORMAT_ISO);
    if (typeof breakdownsByDate[keyId] === "undefined") {
      breakdownsByDate[keyId] = {};
    }
    if (typeof breakdownsByDate[keyId][keyDate] === "undefined") {
      breakdownsByDate[keyId][keyDate] = [];
    }

    breakdownsByDate[keyId][keyDate].push(machineBreakdown);

    date.setDate(date.getDate() + 1);
  }

  return breakdownsByDate;
};

export const createBreakdownsByMachineDate = (
  machineBreakdowns: MachineBreakdownFromCalendarType[]
) => {
  let breakdownsByDate: breakdownsByDateType = {};

  machineBreakdowns.forEach((machineBreakdown) => {
    if (!machineBreakdown.catalogExtraRowRental && machineBreakdown.machine) {
      breakdownsByDate = createBreakdownsByDate(
        breakdownsByDate,
        machineBreakdown,
        machineBreakdown.machine.id
      );
    }
  });

  return breakdownsByDate;
};

export const createBreakdownsByRowExtraDate = (
  machineBreakdowns: MachineBreakdownFromCalendarType[]
) => {
  let breakdownsByDate: breakdownsByDateType = {};

  machineBreakdowns.forEach((machineBreakdown) => {
    if (machineBreakdown.catalogExtraRowRental) {
      breakdownsByDate = createBreakdownsByDate(
        breakdownsByDate,
        machineBreakdown,
        machineBreakdown.catalogExtraRowRental.id
      );
    }
  });

  return breakdownsByDate;
};

type maintenanceByDateType = {
  [machine_id: string]: { [date: string]: MaintenanceFromCalendarType[] };
};

const createMaintenancesByDate = (
  maintenancesByDate: maintenanceByDateType,
  maintenance: MaintenanceFromCalendarType,
  keyId: string
) => {
  let date = newDate(
    maintenance.maintenanceType ===
      MaintenanceMaintenanceMaintenanceTypeChoices.Scheduled
      ? maintenance.serviceStartBy
      : maintenance.estimatedDate
  );
  let dateReturned = newDate(
    maintenance.maintenanceType ===
      MaintenanceMaintenanceMaintenanceTypeChoices.Scheduled
      ? maintenance.serviceEndBy
      : maintenance.estimatedDate
  );
  if (maintenance.servicedAt !== null) {
    let dateServicedAt = newDate(maintenance.servicedAt);
    if (dateServicedAt < date) {
      date = dateServicedAt;
    } else if (dateServicedAt > dateReturned) {
      dateReturned = dateServicedAt;
    }
  }

  date = resetDateTime(date);
  dateReturned = resetDateTime(dateReturned);

  while (date <= dateReturned) {
    let keyDate = format(date, DATE_FORMAT_ISO);
    if (typeof maintenancesByDate[keyId] === "undefined") {
      maintenancesByDate[keyId] = {};
    }
    if (typeof maintenancesByDate[keyId][keyDate] === "undefined") {
      maintenancesByDate[keyId][keyDate] = [];
    }

    maintenancesByDate[keyId][keyDate].push(maintenance);

    date.setDate(date.getDate() + 1);
  }

  return maintenancesByDate;
};

export const createMaintenancesByMachineDate = (
  maintenances: MaintenanceFromCalendarType[]
) => {
  let maintenancesByDate: maintenanceByDateType = {};

  maintenances.forEach((maintenance) => {
    if (!maintenance.catalogExtraRowRental && maintenance.machine) {
      maintenancesByDate = createMaintenancesByDate(
        maintenancesByDate,
        maintenance,
        maintenance.machine.id
      );
    }
  });

  return maintenancesByDate;
};

export const createMaintenancesByRowExtraDate = (
  maintenances: MaintenanceFromCalendarType[]
) => {
  let maintenancesByDate: maintenanceByDateType = {};

  maintenances.forEach((maintenance) => {
    if (maintenance.catalogExtraRowRental) {
      maintenancesByDate = createMaintenancesByDate(
        maintenancesByDate,
        maintenance,
        maintenance.catalogExtraRowRental.id
      );
    }
  });

  return maintenancesByDate;
};

export const getCalendarCellBreakdowns = (
  t: TFunction<"translation">,
  date: Date,
  content: JSX.Element[],
  machineBreakdowns: MachineBreakdownFromCalendarType[],
  key: string
) => {
  let countBreakdownsRentableYes = 0;
  let countBreakdownsRentableNo = 0;
  let countBreakdownsFixed = 0;
  let countBreakdownsNotUsed = 0;
  let titlesBreakdown: string[] = [];
  let titlesBreakdownNotUsed: string[] = [];
  machineBreakdowns.forEach((machineBreakdown) => {
    if (
      machineBreakdown.billing ===
      MachinesMachineBreakdownBillingChoices.NotInUse
    ) {
      countBreakdownsNotUsed++;
      titlesBreakdownNotUsed.push(machineBreakdown.title);
    } else {
      if (machineBreakdown.fixedAt) {
        if (
          format(newDate(machineBreakdown.fixedAt), t("format_date")) ===
          format(newDate(date), t("format_date"))
        ) {
          countBreakdownsFixed++;
        }
      } else if (machineBreakdown.stillRentable) {
        countBreakdownsRentableYes++;
      } else {
        countBreakdownsRentableNo++;
      }
      titlesBreakdown.push(machineBreakdown.title);
    }
  });

  if (countBreakdownsNotUsed > 0) {
    content.push(
      <FontAwesomeIcon
        key={`${key}_breakdown_not_used`}
        className=""
        icon={faTriangle}
        title={titlesBreakdownNotUsed.join("\n")}
      />
    );
  }
  if (
    countBreakdownsRentableYes > 0 ||
    countBreakdownsRentableNo > 0 ||
    countBreakdownsFixed > 0
  ) {
    let classNameBreakdown = "";
    if (countBreakdownsFixed > 0) {
      classNameBreakdown = "iconBreakdownFixed";
    }
    if (countBreakdownsRentableYes > 0) {
      classNameBreakdown = "iconBreakdownRentableYes";
    }
    if (countBreakdownsRentableNo > 0) {
      classNameBreakdown = "iconBreakdownRentableNo";
    }
    content.push(
      <FontAwesomeIcon
        key={`${key}_breakdown`}
        className={classNameBreakdown}
        icon={faExclamationTriangle}
        title={titlesBreakdown.join("\n")}
      />
    );
  }

  return content;
};

export const getCalendarCellMaintenances = (
  t: TFunction<"translation">,
  date: Date,
  content: JSX.Element[],
  maintenances: MaintenanceFromCalendarType[],
  key: string
) => {
  let countMaintenancesDone = 0;
  let countMaintenancesScheduled = 0;
  let countMaintenancesUpcoming = 0;
  let titlesMaintenance: string[] = [];
  maintenances.forEach((maintenance) => {
    if (maintenance.servicedAt !== null) {
      if (
        // servicedAt is timestamp when service done button clicked.
        // serviceStartBy is when service is actually scheduled
        format(newDate(maintenance.serviceStartBy), t("format_date")) ===
        format(newDate(date), t("format_date"))
      ) {
        countMaintenancesDone++;
      }
    } else if (
      maintenance.maintenanceType ===
      MaintenanceMaintenanceMaintenanceTypeChoices.Scheduled
    ) {
      countMaintenancesScheduled++;
    } else {
      countMaintenancesUpcoming++;
    }
    titlesMaintenance.push(maintenance.maintenanceInterval.description);
  });

  if (
    countMaintenancesDone > 0 ||
    countMaintenancesScheduled > 0 ||
    countMaintenancesUpcoming > 0
  ) {
    let classNameMaintenance = "";
    if (countMaintenancesDone > 0) {
      classNameMaintenance = "iconBreakdownFixed";
    }
    if (countMaintenancesUpcoming > 0) {
      classNameMaintenance = "iconBreakdownRentableYes";
    }
    if (countMaintenancesScheduled > 0) {
      classNameMaintenance = "iconBreakdownRentableNo";
    }
    content.push(
      <FontAwesomeIcon
        key={`${key}_maintenance`}
        className={classNameMaintenance}
        icon={faExclamationCircle}
        title={titlesMaintenance.join("\n")}
      />
    );
  }

  return content;
};
