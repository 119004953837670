import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationDeleteOrderArgs,
  OrdersOrderStatusChoices,
  OrderType,
  ReservationType,
} from "../../entity/types";
import ButtonLoad from "../Shared/ButtonLoad";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { orderCanBeDeleted } from "../../utils/reservations/reservation";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import { DELETE_ORDER_MUTATION } from "../../apollo/mutations/orders";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservationAndBreakdowns } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  reservations: ReservationType[];
  callbackOnDelete?: Function;
}

function ButtonOrderDelete({
  classes,
  order,
  reservations,
  callbackOnDelete,
}: Props) {
  const { t } = useTranslation();

  const [deleteOrder, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteOrderArgs
  >(DELETE_ORDER_MUTATION, {
    onCompleted: () => {
      if (callbackOnDelete) {
        callbackOnDelete();
      }
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationAndBreakdowns(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDeleteOrder = checkPermission(myPermissions, [
    "orders.delete_order",
  ]);
  const hasPermissionDeleteOrderReserved = checkPermission(myPermissions, [
    "orders.delete_order_reserved",
  ]);

  if (!hasPermissionDeleteOrder || !orderCanBeDeleted(reservations)) {
    return null;
  }
  if (
    order.status === OrdersOrderStatusChoices.Reserved &&
    !hasPermissionDeleteOrderReserved
  ) {
    return null;
  }

  const onClickOrderDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteOrder({
        variables: { orderId: order.id },
      });
    });
  };

  return (
    <ButtonLoad
      loading={loadingDelete}
      className="me-2"
      onClick={onClickOrderDelete}
      variant="light"
    >
      {t("delete_order")}
    </ButtonLoad>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonOrderDelete);
