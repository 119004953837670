import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  IdPriceType,
  Mutation,
  MutationUpdateReservationArgs,
  ReservationType,
} from "../../entity/types";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { getPercentDifference } from "../../utils/calc";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservation } from "../../utils/cache";
import { getPercentPrefixUserInput } from "../../utils/discounts/prefixes";

const getFormattedReservation = (reservation: ReservationType) => ({
  ...reservation,
  discountPercentDay: formatNumber(reservation.discountPercentDay, 4),
  discountPercentMonth: formatNumber(reservation.discountPercentMonth, 4),
});

const getFormattedReservationPrice = (reservationPrice: IdPriceType) => ({
  ...reservationPrice,
  totalReservation: formatNumber(reservationPrice.totalReservation, 2),
});

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationType;
  reservationPrice: IdPriceType;
}

function ReservationDiscountInputs({
  classes,
  reservation,
  reservationPrice,
}: Props) {
  const { t } = useTranslation();

  const [reservationEdited, setReservationEdited] = useState(
    getFormattedReservation(reservation)
  );
  const [reservationPriceEdited, setReservationPriceEdited] = useState(
    getFormattedReservationPrice(reservationPrice)
  );

  const [updateReservation, { loading }] = useMutation<
    Mutation,
    MutationUpdateReservationArgs
  >(UPDATE_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservation(cache);
    },
  });

  useEffect(() => {
    setReservationEdited(getFormattedReservation(reservation));
    setReservationPriceEdited(getFormattedReservationPrice(reservationPrice));
  }, [reservation, reservationPrice]);

  const hasDiscount =
    reservationPrice.totalReservation !==
    reservationPrice.totalReservationWithoutDiscount;

  const updateReservationDiscountMonth = (discountPercentMonth: number) => {
    setReservationEdited({
      ...reservationEdited,
      discountPercentMonth: formatNumber(discountPercentMonth, 4),
    });
    updateReservation({
      variables: {
        reservationId: reservation.id,
        discountPercentMonth: discountPercentMonth,
      },
    });
  };

  const updateReservationDiscountDay = (discountPercentDay: number) => {
    setReservationEdited({
      ...reservationEdited,
      discountPercentDay: formatNumber(discountPercentDay, 4),
    });
    updateReservation({
      variables: {
        reservationId: reservation.id,
        discountPercentDay: discountPercentDay,
      },
    });
  };

  return (
    <div className={`mb-3 ${loading ? "loading" : ""}`}>
      {hasDiscount && (
        <FormControl className="me-3">
          <TextFieldFocus
            label={t("total_without_discount")}
            value={formatNumber(
              reservationPriceEdited.totalReservationWithoutDiscount,
              2
            )}
            disabled={true}
          />
        </FormControl>
      )}
      <FormControl className="me-3">
        <TextFieldFocus
          label={t(hasDiscount ? "total_after_discount" : "total")}
          value={reservationPriceEdited.totalReservation}
          onChange={(event) => {
            setReservationPriceEdited({
              ...reservationPriceEdited,
              totalReservation: event.target.value,
            });
          }}
          onBlur={() => {
            const priceNew = parseNumber(
              reservationPriceEdited.totalReservation
            );
            const discountPercentNew = getPercentDifference(
              reservationPriceEdited.totalReservationWithoutDiscount,
              priceNew
            );

            if (reservationPriceEdited.isDayPrice) {
              updateReservationDiscountDay(discountPercentNew);
            } else {
              updateReservationDiscountMonth(discountPercentNew);
            }

            window.setTimeout(() => {
              setReservationPriceEdited({
                ...reservationPriceEdited,
                totalReservation: formatNumber(priceNew, 2),
              });
            }, 100);
          }}
        />
      </FormControl>
      <FormControl>
        <TextFieldFocus
          label={t("discount_percent_day")}
          value={reservationEdited.discountPercentDay}
          onChange={(event) => {
            setReservationEdited({
              ...reservationEdited,
              discountPercentDay: event.target.value,
            });
          }}
          onBlur={() => {
            const prefix = getPercentPrefixUserInput(
              reservationEdited.discountPercentDay
            );
            const discountPercentDayNew = parseNumber(
              prefix + reservationEdited.discountPercentDay
            );
            updateReservationDiscountDay(discountPercentDayNew);
          }}
        />
      </FormControl>
      <FormControl>
        <TextFieldFocus
          label={t("discount_percent_month")}
          value={reservationEdited.discountPercentMonth}
          onChange={(event) => {
            setReservationEdited({
              ...reservationEdited,
              discountPercentMonth: event.target.value,
            });
          }}
          onBlur={() => {
            const prefix = getPercentPrefixUserInput(
              reservationEdited.discountPercentDay
            );
            const discountPercentMonthNew = parseNumber(
              prefix + reservationEdited.discountPercentMonth
            );
            updateReservationDiscountMonth(discountPercentMonthNew);
          }}
        />
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationDiscountInputs);
