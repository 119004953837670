import {
  CatalogExtraRowRentalType,
  MachineMakeType,
  MachineModelType,
  MachineType,
} from "../../entity/types";

export function getMachineName(
  machine: Pick<MachineType, "identifier"> & {
    machineModel: Pick<MachineModelType, "title"> & {
      machineMake: Pick<MachineMakeType, "title">;
    };
  },
  showMachineIdentifier = false
) {
  return (
    (showMachineIdentifier && machine.identifier !== ""
      ? machine.identifier + " "
      : "") +
    machine.machineModel.machineMake.title +
    (machine.machineModel.machineMake.title !== "" ? " " : "") +
    machine.machineModel.title
  );
}

export function getCatalogExtraRowRentalName(
  catalogExtraRowRental: Pick<
    CatalogExtraRowRentalType,
    "name" | "productNumber"
  >
) {
  return (
    (catalogExtraRowRental.productNumber
      ? catalogExtraRowRental.productNumber + " "
      : "") + catalogExtraRowRental.name
  );
}
