import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
  CatalogRowType,
  CatalogType,
  Mutation,
  MutationDeleteCatalogCategoryArgs,
} from "../../../entity/types";
import { Button } from "react-bootstrap";
import CatalogRow from "./CatalogRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPaperclip, faTrash } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { DELETE_CATALOG_CATEGORY_MUTATION } from "../../../apollo/mutations/catalogs";
import { handleError } from "../../../entity/ErrorHandler";
import DialogCatalogCategory from "../DialogCatalogCategory";
import DialogCatalogCategoryUpload from "../DialogCatalogCategoryUpload";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { dialogConfirm } from "../../../utils/dialogs";
import { isLocked } from "../../../utils/catalogs/locked";
import { COLOR_MUTED } from "../../../utils/constants";
import { updateCacheCatalogEdit } from "../../../utils/cache";
import ButtonLoad from "../../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
  catalogCategory: CatalogCategoryType;
}

function CatalogCategory({
  classes,
  catalog,
  catalogCategoriesUpper,
  catalogCategory,
}: Props) {
  const { t } = useTranslation();

  const [openDialogCatalogCategory, setOpenDialogCatalogCategory] =
    useState(false);
  const [openDialogCatalogCategoryUpload, setOpenDialogCatalogCategoryUpload] =
    useState(false);

  const [deleteCatalogCategory, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogCategoryArgs
  >(DELETE_CATALOG_CATEGORY_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs.delete_catalogcategory",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogcategory",
  ]);

  const handleClickCategoryDelete = (catalogCategoryId: string) => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogCategory({
        variables: { catalogCategoryId: catalogCategoryId },
      });
    });
  };
  const handleClickCategoryInfo = () => {
    setOpenDialogCatalogCategory(true);
  };
  const handleClickCategoryUpload = () => {
    setOpenDialogCatalogCategoryUpload(true);
  };

  return (
    <>
      <thead>
        <tr>
          <th>
            <span className={classes.spnCatalogCategory}>
              {catalogCategory.name}
            </span>
          </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          {!isLocked(catalog) && <th></th>}
          <th>
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={`${classes.btn} ${
                  catalogCategory.information
                    ? classes.btnHasData
                    : classes.btnNoData
                }`}
                onClick={handleClickCategoryInfo}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={`${classes.btn} ${
                  catalogCategory.image ? classes.btnHasData : classes.btnNoData
                }`}
                onClick={handleClickCategoryUpload}
              >
                <FontAwesomeIcon icon={faPaperclip} />
              </Button>
            )}
            {hasPermissionDelete && (
              <ButtonLoad
                loading={loadingDelete}
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={() => handleClickCategoryDelete(catalogCategory.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonLoad>
            )}
            <DialogCatalogCategory
              open={openDialogCatalogCategory}
              setOpen={setOpenDialogCatalogCategory}
              catalogCategoryOrig={catalogCategory}
              catalogCategoriesUpper={catalogCategoriesUpper}
            />
            <DialogCatalogCategoryUpload
              open={openDialogCatalogCategoryUpload}
              setOpen={setOpenDialogCatalogCategoryUpload}
              catalogCategory={catalogCategory}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {catalogCategory.catalogrowSet.map((catalogRow: CatalogRowType) => (
          <CatalogRow
            key={catalogRow.id}
            catalog={catalog}
            catalogRow={catalogRow}
            catalogCategoriesUpper={catalogCategoriesUpper}
          />
        ))}
      </tbody>
    </>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    spnCatalogCategory: {
      color: palette.primary.main,
      marginRight: spacing(2),
    },
    btn: {
      marginLeft: spacing(0.5),
    },
    btnHasData: {},
    btnNoData: {
      color: COLOR_MUTED,
    },
  });

export default withStyles(styles)(CatalogCategory);
