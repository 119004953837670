import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faUsers } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { AuthType, UserContext } from "../Root";
import format from "date-fns/format";
import { createStyles, Theme } from "@material-ui/core";
import AppButtonSettings from "../components/Shared/AppButtonSettings";
import AppButtonWorkQueues from "../components/Shared/AppButtonWorkQueues";
import AppButtonManagement from "../components/Shared/AppButtonManagement";
import { URL_CALENDAR, URL_CUSTOMERS } from "../utils/urls";
import { newDate } from "../utils/dates";

interface Props extends WithStyles<typeof styles> {
  Auth: AuthType;
}

function App({ classes, Auth }: Props) {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);

  let message;
  if (currentUser.lastLogin) {
    message = (
      <div>
        {t("welcome_back")} <br />{" "}
        {t("last_logged_in_at", {
          time: format(newDate(currentUser.lastLogin), t("format_datetime")),
        })}
      </div>
    );
  } else {
    message = t("welcome_new");
  }

  const ColCalendar = Auth.Calendar(() => {
    return (
      <Col lg={4} sm={6} xs={12}>
        <Button
          className={classes.btn}
          component={Link}
          to={URL_CALENDAR}
          color="primary"
        >
          <div className={classes.faIconCon}>
            <FontAwesomeIcon size="lg" icon={faCalendarAlt} />
          </div>
          <div>{t("calendar")}</div>
        </Button>
      </Col>
    );
  });

  const ColCustomers = Auth.Customers(() => {
    return (
      <Col lg={4} sm={6} xs={12}>
        <Button
          className={classes.btn}
          component={Link}
          to={URL_CUSTOMERS}
          color="primary"
        >
          <div className={classes.faIconCon}>
            <FontAwesomeIcon size="lg" icon={faUsers} />
          </div>
          <div>{t("customers")}</div>
        </Button>
      </Col>
    );
  });

  const ColWorkQueues = Auth.WorkQueues(() => {
    return (
      <Col lg={4} sm={6} xs={12}>
        <AppButtonWorkQueues classes={classes} Auth={Auth} />
      </Col>
    );
  });

  const ColManagement = Auth.Management(() => {
    return (
      <Col lg={4} sm={6} xs={12}>
        <AppButtonManagement classes={classes} Auth={Auth} />
      </Col>
    );
  });

  const ColSettings = Auth.Settings(() => {
    return (
      <Col lg={4} sm={6} xs={12}>
        <AppButtonSettings classes={classes} Auth={Auth} />
      </Col>
    );
  });

  return (
    <Container className="containerMain">
      <h2 className={classes.headerMain}>
        {t("hello_x", { name: currentUser.firstName })}
      </h2>
      <div className={classes.subHeaderMain}>{message}</div>

      <Row>
        <ColCalendar />
        <ColCustomers />
        <ColWorkQueues />
        <ColManagement />
        <ColSettings />
      </Row>

      <footer className={classes.footer}>{t("footer_text")}</footer>
    </Container>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    headerMain: {
      color: palette.primary.main,
      textAlign: "center",
      marginTop: "3vh",
      marginBottom: "3vh",
    },
    subHeaderMain: {
      fontSize: "1.1rem",
      textAlign: "center",
      marginBottom: "6vh",
      color: palette.grey["600"],
    },
    btn: {
      backgroundColor: "#FFFFFF",
      color: palette.grey["600"],
      width: "100%",
      height: "20vh",
      fontSize: "2.5vh",
      borderRadius: "30px",
      boxShadow: "0px 0px 20px #D3D3D3",
      textTransform: "inherit",
      marginBottom: "3vh",

      "& :hover": {
        color: palette.primary.dark,
      },
      "& .MuiButton-label": {
        flexDirection: "column",
      },
    },
    faIconCon: {
      fontSize: "6vh",
      display: "block",
    },
    footer: {
      margin: "40px 0 10px 0",
      textAlign: "center",
      color: palette.grey["600"],
    },
  });

export default withStyles(styles)(App);
