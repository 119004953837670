import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineBulkBreakdownType,
  Mutation,
  MutationUpdateMachineBulkBreakdownArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWrench } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_MACHINE_BULK_BREAKDOWN_MUTATION } from "../../apollo/mutations/machines";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { getMachineName } from "../../utils/machines/machine";
import format from "date-fns/format";
import { newDate } from "../../utils/dates";
import { dialogConfirm } from "../../utils/dialogs";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  machineBulkBreakdown: MachineBulkBreakdownType;
}

function WorkQueueCardBreakdownBulk({ classes, machineBulkBreakdown }: Props) {
  const { t } = useTranslation();

  const [updateMachineBreakdown, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineBulkBreakdownArgs
  >(UPDATE_MACHINE_BULK_BREAKDOWN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machineBreakdownsUnfixed"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "machines.change_machinebulkbreakdown",
  ]);

  const onClickFixed = () => {
    dialogConfirm(t, t("confirm_maintenance_completed"), () => {
      updateMachineBreakdown({
        variables: {
          machineBulkBreakdownId: machineBulkBreakdown.id,
          fixedAt: machineBulkBreakdown.fixedAt ? null : new Date(),
        },
      });
    });
  };

  return (
    <div className="cardWorkQueue">
      <div className="cardWorkQueueActions">
        <div className="cardWorkQueueActionCol left">
          <ButtonLoad
            loading={loadingUpdate}
            variant={machineBulkBreakdown.fixedAt ? "primary" : "danger"}
            title={t("fixed")}
            onClick={onClickFixed}
            disabled={!hasPermissionEdit}
          >
            <FontAwesomeIcon icon={faWrench} />
          </ButtonLoad>
        </div>
        <div className="cardWorkQueueActionCol right" title={t("created_at")}>
          {format(newDate(machineBulkBreakdown.createdAt), t("format_date"))}
        </div>
      </div>
      {machineBulkBreakdown.machine && (
        <span className="text-muted">
          {machineBulkBreakdown.machine.identifier}
        </span>
      )}
      {machineBulkBreakdown.machine && (
        <b className="ms-2">{getMachineName(machineBulkBreakdown.machine)}</b>
      )}
      <span className="ms-2">
        {t("x_pcs", { pcs: machineBulkBreakdown.amountBroken })}
      </span>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueCardBreakdownBulk);
