import { gql } from "@apollo/client";
import { FRAG_RESERVATION_FULL } from "../queries/reservations";
import { Mutation } from "../../entity/types";

export const CREATE_RESERVATION_MUTATION = gql`
  ${FRAG_RESERVATION_FULL}
  mutation createReservation(
    $orderId: ID!
    $machineId: ID
    $catalogRowId: ID
    $catalogExtraRowRentalId: ID
    $dateRented: Date!
    $timeRented: Time
    $dateReturned: Date!
    $timeReturned: Time
    $information: String!
    $deliveryMethod: String!
    $bulkAmount: Int!
  ) {
    createReservation(
      orderId: $orderId
      machineId: $machineId
      catalogRowId: $catalogRowId
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      dateRented: $dateRented
      timeRented: $timeRented
      dateReturned: $dateReturned
      timeReturned: $timeReturned
      information: $information
      deliveryMethod: $deliveryMethod
      bulkAmount: $bulkAmount
    ) {
      reservation {
        ...ReservationFieldsFull
      }
    }
  }
`;

export const UPDATE_RESERVATION_MUTATION = gql`
  ${FRAG_RESERVATION_FULL}
  mutation updateReservation(
    $reservationId: ID!
    $orderId: ID
    $machineId: ID
    $catalogRowId: ID
    $dateRented: Date
    $timeRented: Time
    $dateReturned: Date
    $timeReturned: Time
    $dateBillingMin: Date
    $information: String
    $deliveryMethod: String
    $bulkAmount: Int
    $discountPercentDay: Decimal
    $discountPercentMonth: Decimal
    $estimatedMachineHoursPerDay: Decimal
    $billingDaysWeekCompany: Int
    $billingDaysWeekPrivate: Int
    $hourLimitDayCompany: Int
    $hourLimitDayPrivate: Int
    $hourLimitMonthCompany: Int
    $hourLimitMonthPrivate: Int
  ) {
    updateReservation(
      reservationId: $reservationId
      orderId: $orderId
      machineId: $machineId
      catalogRowId: $catalogRowId
      dateRented: $dateRented
      timeRented: $timeRented
      dateReturned: $dateReturned
      timeReturned: $timeReturned
      dateBillingMin: $dateBillingMin
      information: $information
      deliveryMethod: $deliveryMethod
      bulkAmount: $bulkAmount
      discountPercentDay: $discountPercentDay
      discountPercentMonth: $discountPercentMonth
      estimatedMachineHoursPerDay: $estimatedMachineHoursPerDay
      billingDaysWeekCompany: $billingDaysWeekCompany
      billingDaysWeekPrivate: $billingDaysWeekPrivate
      hourLimitDayCompany: $hourLimitDayCompany
      hourLimitDayPrivate: $hourLimitDayPrivate
      hourLimitMonthCompany: $hourLimitMonthCompany
      hourLimitMonthPrivate: $hourLimitMonthPrivate
    ) {
      reservation {
        ...ReservationFieldsFull
      }
    }
  }
`;

export const UPDATE_RESERVATION_STAMPS_MUTATION = gql`
  mutation updateReservationStamps(
    $reservationId: ID!
    $userGiveId: ID
    $userReturnId: ID
    $giveCheckedAt: DateTime
    $giveImagesAt: DateTime
    $giveInstructedAt: DateTime
    $giveAt: DateTime
    $returnAt: DateTime
    $returnImagesAt: DateTime
    $returnCheckedCatalogAt: DateTime
    $returnCheckedPreAt: DateTime
    $returnCheckedAt: DateTime
    $returnStoredAt: DateTime
  ) {
    updateReservationStamps(
      reservationId: $reservationId
      userGiveId: $userGiveId
      userReturnId: $userReturnId
      giveCheckedAt: $giveCheckedAt
      giveImagesAt: $giveImagesAt
      giveInstructedAt: $giveInstructedAt
      giveAt: $giveAt
      returnAt: $returnAt
      returnImagesAt: $returnImagesAt
      returnCheckedCatalogAt: $returnCheckedCatalogAt
      returnCheckedPreAt: $returnCheckedPreAt
      returnCheckedAt: $returnCheckedAt
      returnStoredAt: $returnStoredAt
    ) {
      reservation {
        id
        userGive {
          id
        }
        userReturn {
          id
        }
        giveCheckedAt
        giveImagesAt
        giveInstructedAt
        giveAt
        returnAt
        returnImagesAt
        returnCheckedCatalogAt
        returnCheckedPreAt
        returnCheckedAt
        returnStoredAt
      }
    }
  }
`;

export const UPDATE_RESERVATIONS_RETURN_AT_MUTATION = gql`
  mutation updateReservationsReturnAt(
    $reservationIds: [ID]!
    $returnAt: DateTime!
  ) {
    updateReservationsReturnAt(
      reservationIds: $reservationIds
      returnAt: $returnAt
    ) {
      reservations {
        id
        returnAt
      }
    }
  }
`;

export const DELETE_RESERVATION_MUTATION = gql`
  mutation deleteReservation($reservationId: ID!) {
    deleteReservation(reservationId: $reservationId) {
      reservationId
    }
  }
`;

export const CREATE_RESERVATION_COMMENT_MUTATION = gql`
  mutation createReservationComment($reservationId: ID!, $comment: String!) {
    createReservationComment(reservationId: $reservationId, comment: $comment) {
      reservationComment {
        id
      }
    }
  }
`;

export const RETURN_PARTIAL_RESERVATION_MUTATION = gql`
  ${FRAG_RESERVATION_FULL}
  mutation returnPartialReservation(
    $reservationId: ID!
    $machineId: ID
    $catalogExtraRowRentalId: ID
    $dateEnd: Date!
    $bulkAmountReturned: Int!
  ) {
    returnPartialReservation(
      reservationId: $reservationId
      machineId: $machineId
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      dateEnd: $dateEnd
      bulkAmountReturned: $bulkAmountReturned
    ) {
      reservation {
        ...ReservationFieldsFull
      }
    }
  }
`;

export const SPLIT_RESERVATION_MUTATION = gql`
  ${FRAG_RESERVATION_FULL}
  mutation splitReservation(
    $reservationId: ID!
    $machineId: ID
    $catalogExtraRowRentalId: ID
    $dateEnd: Date!
    $bulkAmountOld: Int
    $catalogRowId: ID
  ) {
    splitReservation(
      reservationId: $reservationId
      machineId: $machineId
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      dateEnd: $dateEnd
      bulkAmountOld: $bulkAmountOld
      catalogRowId: $catalogRowId
    ) {
      reservation {
        ...ReservationFieldsFull
      }
    }
  }
`;

export interface MutationResultUploadReservationImages {
  uploadReservationImages: Mutation["uploadReservationImages"];
}
export const UPLOAD_RESERVATION_IMAGES_MUTATION = gql`
  mutation uploadReservationImages(
    $reservationAlbumId: ID!
    $files: [Upload]!
  ) {
    uploadReservationImages(
      reservationAlbumId: $reservationAlbumId
      files: $files
    ) {
      reservationImages {
        id
      }
    }
  }
`;

export const DELETE_RESERVATION_IMAGE_MUTATION = gql`
  mutation deleteReservationImage($reservationImageId: ID!) {
    deleteReservationImage(reservationImageId: $reservationImageId) {
      reservationImageId
    }
  }
`;

export const UPLOAD_RESERVATION_IMAGE_BASIC_MUTATION = gql`
  mutation uploadReservationImageBasic($reservationId: ID!, $file: Upload!) {
    uploadReservationImageBasic(reservationId: $reservationId, file: $file) {
      reservationImageBasic {
        id
      }
    }
  }
`;

export const DELETE_RESERVATION_IMAGE_BASIC_MUTATION = gql`
  mutation deleteReservationImageBasic($reservationImageBasicId: ID!) {
    deleteReservationImageBasic(
      reservationImageBasicId: $reservationImageBasicId
    ) {
      reservationImageBasicId
    }
  }
`;

export const UPDATE_RESERVATION_IMAGE_BASIC_MUTATION = gql`
  mutation updateReservationImageBasic(
    $reservationImageBasicId: ID!
    $information: String!
    $shootingDate: Date!
  ) {
    updateReservationImageBasic(
      reservationImageBasicId: $reservationImageBasicId
      information: $information
      shootingDate: $shootingDate
    ) {
      reservationImageBasic {
        id
        information
        shootingDate
      }
    }
  }
`;
