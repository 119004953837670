import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { Button } from "react-bootstrap";
import { GET_INSPECTION_GIVE_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import {
  InspectionGiveCategoryType,
  InspectionGiveQuestionType,
  Mutation,
  MutationCreateInspectionGiveQuestionArgs,
} from "../../entity/types";
import { InspectionGiveQuestionEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import DialogContentInspectionGiveQuestion from "./DialogContentInspectionGiveQuestion";
import { CREATE_INSPECTION_GIVE_QUESTION_MUTATION } from "../../apollo/mutations/inspections";
import { WithStyles } from "@material-ui/core/styles";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveCategory: InspectionGiveCategoryType;
}

function CreateInspectionGiveQuestion({
  classes,
  inspectionGiveCategory,
}: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [inspectionGiveQuestion, setInspectionGiveQuestion] =
    useState<InspectionGiveQuestionType>(InspectionGiveQuestionEmpty);

  const [createInspectionGiveQuestion, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateInspectionGiveQuestionArgs>(
      CREATE_INSPECTION_GIVE_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_GIVE_CATEGORIES_QUERY }],
        onCompleted: (result) => {
          setInspectionGiveQuestion(InspectionGiveQuestionEmpty);
        },
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "inspections.add_inspectiongivequestion",
  ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createInspectionGiveQuestion({
      variables: {
        inspectionGiveCategoryId: inspectionGiveCategory.id,
        question: inspectionGiveQuestion.question,
        answerType: inspectionGiveQuestion.answerType,
      },
    });
    setOpen(false);
  };

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className="btnRoundSmall"
        variant="primary"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open}>
        <form onSubmit={(event) => handleSubmit(event)}>
          <DialogTitle>{t("new_inspection_question")}</DialogTitle>
          <DialogContentInspectionGiveQuestion
            inspectionGiveQuestion={inspectionGiveQuestion}
            setInspectionGiveQuestion={setInspectionGiveQuestion}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <ButtonLoad
              loading={loadingCreate}
              disabled={!inspectionGiveQuestion.question.trim()}
              type="submit"
              variant="primary"
            >
              {t("save")}
            </ButtonLoad>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CreateInspectionGiveQuestion);
