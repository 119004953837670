import { getPercentPrefixCalc } from "./discounts/prefixes";
import { parseNumber } from "./formatting";

export const getPercentFactorDiscount = (value: string | null) => {
  if (value === null) return 1;
  let prefix = getPercentPrefixCalc(value);
  return parseNumber(prefix + value) / 100 + 1;
};

export const getPercentFactor = (value: string) => {
  return parseNumber(value) / 100;
};

export const getVatFactor = (value: string) => {
  return parseNumber(value) / 100 + 1;
};

/* Half price returns 50% */
export const getDiscountPercent = (valueOrig: number, valueNew: number) => {
  if (valueOrig === 0) return 0;
  return ((valueOrig - valueNew) / valueOrig) * 100;
};

/* Half price returns -50% */
export const getPercentDifference = (valueOrig: number, valueNew: number) => {
  if (valueOrig === 0) return 0;
  return (valueNew / valueOrig) * 100 - 100;
};

/* 50% discount returns half price */
export const getDiscountPrice = (amount: number, discount: number) => {
  return amount - (amount * discount) / 100;
};

/* -50% discount returns half price */
export const getPercentAmount = (
  amount: number | string,
  percent: number | string
) => {
  const amountNumber = parseNumber(amount);
  const percentNumber = parseNumber(percent);
  return amountNumber + (amountNumber * percentNumber) / 100;
};
