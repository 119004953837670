import format from "date-fns/format";
import { TFunction } from "react-i18next";
import { DATE_FORMAT_ISO } from "./constants";
import { parseISO } from "date-fns";

export const MS_PER_MINUTE = 1000 * 60;
const MS_PER_HOUR = MS_PER_MINUTE * 60;
const MS_PER_DAY = MS_PER_HOUR * 24;

export function resetDateTime(date: Date): Date {
  date.setHours(0, 0, 0, 0);
  return date;
}

export const datesEqual = (first: Date, second: Date): Boolean =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const dateDiffInDays = (a: Date, b: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / MS_PER_DAY);
};

export const newDate = (source: string | number | Date): Date => {
  if (source instanceof Date || typeof source === "number") {
    return new Date(source);
  }
  return parseISO(source);
};

export const dateAddDays = (date: Date, days: number) => {
  let dateNew = newDate(date);
  dateNew.setDate(dateNew.getDate() + days);
  return dateNew;
};

export const mergeDateTime = (
  t: TFunction<"translation">,
  date: string,
  time: string
): string => {
  let dateStr = date;
  let f = t("format_date");
  if (time) {
    dateStr += " " + time;
    f = t("format_datetime");
  }

  return format(newDate(dateStr), f);
};

export const dateDiffDaysHumanReadable = (
  t: TFunction<"translation">,
  dateA: Date,
  dateB: Date
) => {
  const diffReturn = dateDiffInDays(dateA, dateB);

  if (diffReturn === 1) {
    return t("x_day", { day: diffReturn });
  } else if (diffReturn === 0) {
    return t("today");
  } else {
    return t("x_days", { days: diffReturn });
  }
};

export const createTimeline = (dateFrom: Date, dateTo: Date): Date[] => {
  let timeline: Date[] = [];

  let date: Date = newDate(dateFrom);

  while (date <= dateTo) {
    timeline.push(newDate(date));
    date.setDate(date.getDate() + 1);
  }

  return timeline;
};

export const createTimelineIsoStrings = (
  dateFrom: Date,
  dateTo: Date
): string[] => {
  let timeline: string[] = [];

  let date: Date = newDate(dateFrom);

  while (date <= dateTo) {
    timeline.push(format(date, DATE_FORMAT_ISO));
    date.setDate(date.getDate() + 1);
  }

  return timeline;
};

export const datesRangesOverlap = (
  dateRangeAStart: Date,
  dateRangeAEnd: Date,
  dateRangeBStart: Date,
  dateRangeBEnd: Date
): Date[] => {
  const timeLineA = createTimelineIsoStrings(dateRangeAStart, dateRangeAEnd);
  const timeLineB = createTimelineIsoStrings(dateRangeBStart, dateRangeBEnd);

  const intersection = timeLineA.filter((date) => timeLineB.includes(date));

  return intersection.map((dateStr) => newDate(dateStr));
};

export const isDateOlderThanHours = (date: Date, hours: number) => {
  const dateOld = newDate(new Date().getTime() - MS_PER_HOUR * hours);
  return date < dateOld;
};

export const isInvalidDate = (date: string | null) => {
  return (
    date === null || date === "" || new Date(date).toString() === "Invalid Date"
  );
};

export const yearMonthToDateDayFirst = (year: number, month: number) =>
  new Date(year, month - 1, 1);

export const yearMonthToDateDayLast = (year: number, month: number) =>
  new Date(year, month, 0);
