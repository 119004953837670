import React from "react";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { fiFI, enUS } from "@material-ui/core/locale";
import i18n from "./localization/i18n";
import { COLOR_PRIMARY } from "./utils/constants";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const themeLocale = i18n.language && i18n.language === "en" ? enUS : fiFI;

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
});

const theme = createTheme(
  {
    overrides: {
      MuiDialog: {
        paperFullWidth: {
          [breakpoints.down("sm")]: {
            width: "calc(100% - 10px)",
            margin: "5px",
          },
        },
      },
      // Override bootstrap styles
      MuiOutlinedInput: {
        notchedOutline: {
          "& legend": {
            float: "unset",
          },
        },
      },
    },
    palette: {
      primary: {
        main: COLOR_PRIMARY,
      },
      secondary: {
        main: "#337AB7",
      },
    },
    breakpoints: breakpoints,
    typography: {
      fontFamily: "'Open Sans', sans-serif",
      h6: {
        // Material-table header, modal header
        color: COLOR_PRIMARY,
      },
    },
  },
  themeLocale
);

function withRoot(Component: any) {
  function WithRoot(props: any) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        {/* https://material-ui.com/getting-started/usage/#cssbaseline */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
