import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import MachineList from "../components/Machine/MachineList";
import {
  GET_MACHINES_FOR_LOCATION_QUERY,
  QueryResultMachinesForLocation,
} from "../apollo/queries/machines";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import CreateMachine from "../components/Machine/CreateMachine";
import { Alert, Col, Container, Row } from "react-bootstrap";
import CatalogSelect from "../components/Machine/CatalogSelect";
import {
  getCatalogIdSelected,
  getSelectedCatalog,
} from "../utils/catalogs/selected_catalog";
import { useTranslation } from "react-i18next";
import { QueryMachinesForLocationArgs } from "../entity/types";
import { SettingsContext, UserContext } from "../Root";
import { getLocationSelected } from "../utils/locations/location";
import MachineListFilter from "../components/Machine/MachineListFilter";
import {
  GET_CATALOGS_QUERY,
  QueryResultCatalogs,
} from "../apollo/queries/catalogs";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function SettingsMachines({ classes }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const locationIdDefault = getLocationSelected(user);

  const settings = useContext(SettingsContext);
  const [catalogId, setCatalogId] = useState(getCatalogIdSelected(settings));
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [filterRentThrough, setFilterRentThrough] = useState([0]);
  const [catalogRowIdsSelected, setCatalogRowIdsSelected] = useState<string[]>(
    []
  );
  const [canQuery, setCanQuery] = useState(false);
  const [search, setSearch] = useState("");

  const {
    loading: loadingCatalogs,
    error: errorCatalogs,
    data: dataCatalogs,
  } = useQuery<QueryResultCatalogs>(GET_CATALOGS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogs"),
  });

  const { loading, error, data } = useQuery<
    QueryResultMachinesForLocation,
    QueryMachinesForLocationArgs
  >(GET_MACHINES_FOR_LOCATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("machinesForLocation"),
    skip: !canQuery,
    variables: {
      locationIds: locationIdsSelected,
      rentThroughs: filterRentThrough,
      catalogRowIds: catalogRowIdsSelected,
      search: search,
    },
  });

  if (loading || loadingCatalogs) return <Loading />;
  if (error) return <Error error={error} />;
  if (errorCatalogs) return <Error error={errorCatalogs} />;
  if (!dataCatalogs || (canQuery && !data)) {
    return <Error error={t("error_query_failed")} />;
  }

  if (!dataCatalogs.catalogs || dataCatalogs.catalogs.length === 0) {
    return (
      <Container className="containerMain">
        <Alert variant="danger">{t("error_add_catalog_first")}</Alert>
      </Container>
    );
  }

  const catalog = getSelectedCatalog(
    settings,
    dataCatalogs.catalogs ? dataCatalogs.catalogs : [],
    catalogId,
    setCatalogId
  );

  return (
    <Container className="containerMain">
      <Row>
        <Col>
          <h2 className="headerPage">{t("machines")}</h2>
        </Col>
        <Col>
          <CatalogSelect
            catalogs={dataCatalogs.catalogs ? dataCatalogs.catalogs : []}
            catalogId={catalogId}
            setCatalogId={setCatalogId}
          />
        </Col>
      </Row>
      <CreateMachine catalog={catalog} />
      <div className="containerInner">
        <MachineListFilter
          catalog={catalog}
          locationIdsSelected={locationIdsSelected}
          setLocationIdsSelected={setLocationIdsSelected}
          filterRentThrough={filterRentThrough}
          setFilterRentThrough={setFilterRentThrough}
          catalogRowIdsSelected={catalogRowIdsSelected}
          setCatalogRowIdsSelected={setCatalogRowIdsSelected}
          canQuery={canQuery}
          setCanQuery={setCanQuery}
          search={search}
          setSearch={setSearch}
        />
        {canQuery && (
          <MachineList
            machines={data?.machinesForLocation ? data.machinesForLocation : []}
            catalog={catalog}
          />
        )}
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsMachines);
