import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultCostcenters {
  costcenters: Query["costcenters"];
}
export const GET_COSTCENTERS_QUERY = gql`
  query getCostcentersQuery {
    costcenters {
      id
      number
      name
    }
  }
`;
