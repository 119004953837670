import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export const FRAG_SETTINGS = gql`
  fragment SettingsFields on SettingsType {
    id
    vat
    vatFactor
    productNumberSingle
    productNumberOpHours
    productNumberInsurance
    productNumberBillingSurcharge
    productNumberOpeningFee
    insurancePercent
    billingSurchargeAmount
    billingSurchargeLimit
    openingFeeAmount
    openingFeeLimit
    commissionOwner
    commissionOrderer
    commissionRentThrough
    textConfirmation1
    textConfirmation2
    textOffer0
    textOffer1
    textOffer2
    textContract1
    textContract2
    helpTextImagesGive
    helpTextImagesReturn
    fileCatalogCoverFront
    fileCatalogCoverBack
    fileCatalogCustomerCoverFront
    fileCatalogCustomerCoverBack
    fileConfirmationCoverFront
    fileConfirmationCoverBack
    catalogActive {
      id
    }
    costcenterCatalog {
      id
    }
    costtypeCatalog {
      id
    }
    costcenterCatalogExtraRental {
      id
    }
    costtypeCatalogExtraRental {
      id
    }
    costcenterCatalogTransfer {
      id
    }
    costtypeCatalogTransfer {
      id
    }
    costcenterInsurance {
      id
    }
    costtypeInsurance {
      id
    }
    costcenterBillingSurcharge {
      id
    }
    costtypeBillingSurcharge {
      id
    }
    costcenterOpeningFee {
      id
    }
    costtypeOpeningFee {
      id
    }
    costcenterBreakdownSingle {
      id
    }
    costtypeBreakdownSingle {
      id
    }
    costcenterOpHours {
      id
    }
    costtypeOpHours {
      id
    }
    costcenterMachineRentThrough {
      id
    }
  }
`;

export const UPDATE_SETTINGS_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation updateSettings(
    $vat: Decimal
    $productNumberSingle: String
    $productNumberOpHours: String
    $productNumberInsurance: String
    $productNumberBillingSurcharge: String
    $productNumberOpeningFee: String
    $insurancePercent: Decimal
    $billingSurchargeAmount: Decimal
    $billingSurchargeLimit: Decimal
    $openingFeeAmount: Decimal
    $openingFeeLimit: Decimal
    $commissionOwner: Decimal
    $commissionOrderer: Decimal
    $commissionRentThrough: Decimal
    $textConfirmation1: String
    $textConfirmation2: String
    $textOffer0: String
    $textOffer1: String
    $textOffer2: String
    $textContract1: String
    $textContract2: String
    $helpTextImagesGive: String
    $helpTextImagesReturn: String
    $costcenterCatalogId: ID
    $costtypeCatalogId: ID
    $costcenterCatalogExtraRentalId: ID
    $costtypeCatalogExtraRentalId: ID
    $costcenterCatalogTransferId: ID
    $costtypeCatalogTransferId: ID
    $costcenterInsuranceId: ID
    $costtypeInsuranceId: ID
    $costcenterBillingSurchargeId: ID
    $costtypeBillingSurchargeId: ID
    $costcenterOpeningFeeId: ID
    $costcenterBreakdownSingleId: ID
    $costtypeBreakdownSingleId: ID
    $costcenterOpHoursId: ID
    $costtypeOpHoursId: ID
    $costtypeOpeningFeeId: ID
    $costcenterMachineRentThroughId: ID
  ) {
    updateSettings(
      vat: $vat
      productNumberSingle: $productNumberSingle
      productNumberOpHours: $productNumberOpHours
      productNumberInsurance: $productNumberInsurance
      productNumberBillingSurcharge: $productNumberBillingSurcharge
      productNumberOpeningFee: $productNumberOpeningFee
      insurancePercent: $insurancePercent
      billingSurchargeAmount: $billingSurchargeAmount
      billingSurchargeLimit: $billingSurchargeLimit
      openingFeeAmount: $openingFeeAmount
      openingFeeLimit: $openingFeeLimit
      commissionOwner: $commissionOwner
      commissionOrderer: $commissionOrderer
      commissionRentThrough: $commissionRentThrough
      textConfirmation1: $textConfirmation1
      textConfirmation2: $textConfirmation2
      textOffer0: $textOffer0
      textOffer1: $textOffer1
      textOffer2: $textOffer2
      textContract1: $textContract1
      textContract2: $textContract2
      helpTextImagesGive: $helpTextImagesGive
      helpTextImagesReturn: $helpTextImagesReturn
      costcenterCatalogId: $costcenterCatalogId
      costtypeCatalogId: $costtypeCatalogId
      costcenterCatalogExtraRentalId: $costcenterCatalogExtraRentalId
      costtypeCatalogExtraRentalId: $costtypeCatalogExtraRentalId
      costcenterCatalogTransferId: $costcenterCatalogTransferId
      costtypeCatalogTransferId: $costtypeCatalogTransferId
      costcenterInsuranceId: $costcenterInsuranceId
      costtypeInsuranceId: $costtypeInsuranceId
      costcenterBillingSurchargeId: $costcenterBillingSurchargeId
      costtypeBillingSurchargeId: $costtypeBillingSurchargeId
      costcenterOpeningFeeId: $costcenterOpeningFeeId
      costcenterBreakdownSingleId: $costcenterBreakdownSingleId
      costtypeBreakdownSingleId: $costtypeBreakdownSingleId
      costcenterOpHoursId: $costcenterOpHoursId
      costtypeOpHoursId: $costtypeOpHoursId
      costtypeOpeningFeeId: $costtypeOpeningFeeId
      costcenterMachineRentThroughId: $costcenterMachineRentThroughId
    ) {
      settings {
        ...SettingsFields
      }
    }
  }
`;

export interface MutationResultUploadFileCatalogCoverFront {
  uploadFileCatalogCoverFront: Mutation["uploadFileCatalogCoverFront"];
}
export const UPLOAD_FILE_CATALOG_COVER_FRONT_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation uploadFileCatalogCoverFront($fileCatalogCoverFront: Upload) {
    uploadFileCatalogCoverFront(fileCatalogCoverFront: $fileCatalogCoverFront) {
      settings {
        ...SettingsFields
      }
    }
  }
`;

export interface MutationResultUploadFileCatalogCoverBack {
  uploadFileCatalogCoverBack: Mutation["uploadFileCatalogCoverBack"];
}
export const UPLOAD_FILE_CATALOG_COVER_BACK_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation uploadFileCatalogCoverBack($fileCatalogCoverBack: Upload) {
    uploadFileCatalogCoverBack(fileCatalogCoverBack: $fileCatalogCoverBack) {
      settings {
        ...SettingsFields
      }
    }
  }
`;

export interface MutationResultUploadFileCatalogCustomerCoverFront {
  uploadFileCatalogCustomerCoverFront: Mutation["uploadFileCatalogCustomerCoverFront"];
}
export const UPLOAD_FILE_CATALOG_CUSTOMER_COVER_FRONT_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation uploadFileCatalogCustomerCoverFront(
    $fileCatalogCustomerCoverFront: Upload
  ) {
    uploadFileCatalogCustomerCoverFront(
      fileCatalogCustomerCoverFront: $fileCatalogCustomerCoverFront
    ) {
      settings {
        ...SettingsFields
      }
    }
  }
`;

export interface MutationResultUploadFileCatalogCustomerCoverBack {
  uploadFileCatalogCustomerCoverBack: Mutation["uploadFileCatalogCustomerCoverBack"];
}
export const UPLOAD_FILE_CATALOG_CUSTOMER_COVER_BACK_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation uploadFileCatalogCustomerCoverBack(
    $fileCatalogCustomerCoverBack: Upload
  ) {
    uploadFileCatalogCustomerCoverBack(
      fileCatalogCustomerCoverBack: $fileCatalogCustomerCoverBack
    ) {
      settings {
        ...SettingsFields
      }
    }
  }
`;

export interface MutationResultUploadFileConfirmationCoverFront {
  uploadFileConfirmationCoverFront: Mutation["uploadFileConfirmationCoverFront"];
}
export const UPLOAD_FILE_CONFIRMATION_COVER_FRONT_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation uploadFileConfirmationCoverFront(
    $fileConfirmationCoverFront: Upload
  ) {
    uploadFileConfirmationCoverFront(
      fileConfirmationCoverFront: $fileConfirmationCoverFront
    ) {
      settings {
        ...SettingsFields
      }
    }
  }
`;

export interface MutationResultUploadFileConfirmationCoverBack {
  uploadFileConfirmationCoverBack: Mutation["uploadFileConfirmationCoverBack"];
}
export const UPLOAD_FILE_CONFIRMATION_COVER_BACK_MUTATION = gql`
  ${FRAG_SETTINGS}
  mutation uploadFileConfirmationCoverBack($fileConfirmationCoverBack: Upload) {
    uploadFileConfirmationCoverBack(
      fileConfirmationCoverBack: $fileConfirmationCoverBack
    ) {
      settings {
        ...SettingsFields
      }
    }
  }
`;
