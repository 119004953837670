import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import {
  MachineBulkBreakdownType,
  MachineType,
  Mutation,
  MutationCreateMachineBulkBreakdownArgs,
  MutationDeleteMachineBulkBreakdownArgs,
  ReservationType,
} from "../../entity/types";
import { Button } from "react-bootstrap";

import { faSave, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";
import { useMutation } from "@apollo/client";
import {
  CREATE_MACHINE_BULK_BREAKDOWN_MUTATION,
  DELETE_MACHINE_BULK_BREAKDOWN_MUTATION,
} from "../../apollo/mutations/machines";
import { handleError } from "../../entity/ErrorHandler";
import { dialogConfirm } from "../../utils/dialogs";
import { parseNumber } from "../../utils/formatting";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { MachineBulkBreakdownEmpty } from "../../entity/empties";
import { getQueryKey } from "../../utils/cache";
import { newDate } from "../../utils/dates";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  machine: Pick<MachineType, "id">;
  reservation: Pick<ReservationType, "id">;
  machineBulkBreakdown: MachineBulkBreakdownType;
  setShowInsertRow: React.Dispatch<React.SetStateAction<boolean>>;
}

function MachineBulkBreakdownRow({
  classes,
  machine,
  reservation,
  machineBulkBreakdown,
  setShowInsertRow,
}: Props) {
  const { t } = useTranslation();

  const [machineBulkBreakdownEdited, setMachineBulkBreakdownEdited] =
    useState(machineBulkBreakdown);

  const [createMachineBulkBreakdown, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateMachineBulkBreakdownArgs
  >(CREATE_MACHINE_BULK_BREAKDOWN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setMachineBulkBreakdownEdited(MachineBulkBreakdownEmpty);
      setShowInsertRow(false);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machineBreakdownsUnfixed"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machineBulkBreakdownsForMachine"),
      });
    },
  });

  const [deleteMachineBulkBreakdown, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMachineBulkBreakdownArgs
  >(DELETE_MACHINE_BULK_BREAKDOWN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machineBreakdownsUnfixed"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("machineBulkBreakdownsForMachine"),
      });
    },
  });

  const onClickAdd = () => {
    createMachineBulkBreakdown({
      variables: {
        machineId: machine.id,
        reservationId: reservation.id,
        amountBroken: machineBulkBreakdownEdited.amountBroken,
        fixable: machineBulkBreakdownEdited.fixable,
      },
    });
  };

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMachineBulkBreakdown({
        variables: {
          machineBulkBreakdownId: machineBulkBreakdownEdited.id,
        },
      });
    });
  };

  return (
    <tr className={loadingCreate || loadingDelete ? "loading" : ""}>
      <td>
        <FormControl>
          <TextFieldFocus
            type="number"
            value={machineBulkBreakdownEdited.amountBroken}
            onChange={(event) => {
              const amountBroken = parseNumber(event.target.value);
              setMachineBulkBreakdownEdited({
                ...machineBulkBreakdownEdited,
                amountBroken: amountBroken,
              });
            }}
            inputProps={{ maxLength: 13, min: 0 }}
            disabled={machineBulkBreakdownEdited.id !== ID_EMPTY}
          />
        </FormControl>
      </td>
      <td>
        <FormControl fullWidth>
          <Select
            autoWidth
            value={machineBulkBreakdownEdited.fixable ? 1 : 0}
            disabled={machineBulkBreakdownEdited.id !== ID_EMPTY}
            onChange={(event) => {
              const fixable = Boolean(event.target.value);
              setMachineBulkBreakdownEdited({
                ...machineBulkBreakdownEdited,
                fixable: fixable,
              });
            }}
          >
            <MenuItem value={1}>{t("yes")}</MenuItem>
            <MenuItem value={0}>{t("no")}</MenuItem>
          </Select>
        </FormControl>
      </td>
      <td>
        {machineBulkBreakdownEdited.fixedAt
          ? format(
              newDate(machineBulkBreakdownEdited.fixedAt),
              t("format_datetime")
            )
          : ""}
      </td>
      <td className={classes.cellButtons}>
        {machineBulkBreakdownEdited.id !== ID_EMPTY && (
          <Button variant="light" size="sm" onClick={onClickDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
        {machineBulkBreakdownEdited.id === ID_EMPTY && (
          <>
            <Button
              variant="primary"
              size="sm"
              onClick={onClickAdd}
              className="me-1"
            >
              <FontAwesomeIcon icon={faSave} />
            </Button>
            <Button
              variant="light"
              size="sm"
              onClick={() => setShowInsertRow(false)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    cellButtons: {
      minWidth: "6rem",
    },
  });

export default withStyles(styles)(MachineBulkBreakdownRow);
