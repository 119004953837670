import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  CustomersCustomerCreditRiskClassChoices,
  CustomerType,
} from "../../entity/types";
import MaterialTable, { Column } from "material-table";
import {
  getDefaultSortCustomers,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  setDefaultSortCustomers,
  SortingDirectionType,
  SortingType,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { faSitemap, faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { getUrlCustomerTab } from "../../utils/urls";
import { doesMatchAllTerms } from "../../utils/search";
import format from "date-fns/format";
import { newDate } from "../../utils/dates";
import { DATE_FORMAT_ISO } from "../../utils/constants";

export const defaultSortField = "name";
export const defaultSortDirection = "asc";

interface Props extends WithStyles<typeof styles> {
  customers: CustomerType[];
}

const CustomerList = ({ classes, customers }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [sorting, setSorting] = useState<SortingType>(
    getDefaultSortCustomers()
  );

  const data = customers.map((o) => ({ ...o }));

  data.sort((a, b) => {
    if (a.contactNext === b.contactNext) {
      return a.name.localeCompare(b.name);
    }
    if (a.contactNext === null) {
      return 1;
    }
    if (b.contactNext === null) {
      return 0;
    }
    return a.contactNext.localeCompare(b.contactNext);
  });

  const getCreditCheckValue = (
    customer: CustomerType,
    useSortableDateFormat = false
  ) => {
    const creditInformationOk = customer.customerParent
      ? customer.customerParent.creditInformationOk
      : customer.creditInformationOk;
    const creditInformationCheckedAt = customer.customerParent
      ? customer.customerParent.creditInformationCheckedAt
      : customer.creditInformationCheckedAt;
    const creditRiskClass = customer.customerParent
      ? customer.customerParent.creditRiskClass
      : customer.creditRiskClass;
    const f = useSortableDateFormat ? DATE_FORMAT_ISO : t("format_date");
    const dateChecked = creditInformationCheckedAt
      ? format(newDate(creditInformationCheckedAt), f)
      : "";
    return (
      (creditInformationOk
        ? dateChecked !== ""
          ? dateChecked
          : t("yes")
        : t("no")) +
      (creditRiskClass !== CustomersCustomerCreditRiskClassChoices.ClassNone
        ? " " + t("credit_risk_class_" + creditRiskClass)
        : "")
    );
  };

  const columns: Column<CustomerType>[] = [
    {
      title: "",
      render: (customer) => {
        if (customer.customerParent)
          return <FontAwesomeIcon icon={faSitemap} />;
        if (customer.customersChildren.length > 0)
          return <FontAwesomeIcon icon={faStar} />;
        return "";
      },
      width: "1%",
      sorting: false,
    },
    {
      title: t("name"),
      field: "name",
      customFilterAndSearch: (term: string, customer) =>
        doesMatchAllTerms(term, customer.name),
      defaultSort:
        sorting.field === "name" ? sorting.orderDirection : undefined,
    },
    {
      title: t("customer_contact"),
      field: "contactPersonLastname",
      render: (customer) => {
        return `${customer.contactPersonLastname} ${customer.contactPersonFirstname}`;
      },
      customFilterAndSearch: (term: string, customer) => {
        const name =
          customer.contactPersonLastname +
          " " +
          customer.contactPersonFirstname;
        return doesMatchAllTerms(term, name);
      },
      defaultSort:
        sorting.field === "contactPersonLastname"
          ? sorting.orderDirection
          : undefined,
    },
    {
      title: t("customer_owner"),
      field: "userOwner.firstName",
      defaultSort:
        sorting.field === "userOwner.firstName"
          ? sorting.orderDirection
          : undefined,
    },
    {
      title: t("contact_next"),
      field: "contactNext",
      type: "date",
      defaultSort:
        sorting.field === "contactNext" ? sorting.orderDirection : undefined,
    },
    {
      title: t("customer_area"),
      field: "customerArea.title",
      defaultSort:
        sorting.field === "customerArea.title"
          ? sorting.orderDirection
          : undefined,
    },
    {
      title: t("district"),
      field: "visitDistrict",
      defaultSort:
        sorting.field === "visitDistrict" ? sorting.orderDirection : undefined,
    },
    {
      title: t("customer_rank"),
      field: "customerRank.order",
      render: (customer) => {
        return customer.customerRank ? customer.customerRank.title : "";
      },
      customFilterAndSearch: (term: string, customer) => {
        const rank = customer.customerRank ? customer.customerRank.title : "";
        return rank.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      defaultSort:
        sorting.field === "customerRank.order"
          ? sorting.orderDirection
          : undefined,
    },
    {
      title: t("credit_information_ok"),
      field: "creditInformationOk",
      render: (customer) => {
        return getCreditCheckValue(customer);
      },
      customFilterAndSearch: (term: string, customer) => {
        const answer = getCreditCheckValue(customer);
        return answer.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (customerA, customerB) => {
        const valA = getCreditCheckValue(customerA, true);
        const valB = getCreditCheckValue(customerB, true);
        return valA.localeCompare(valB);
      },
      defaultSort:
        sorting.field === "creditInformationOk"
          ? sorting.orderDirection
          : undefined,
    },
  ];

  const handleOrderChange = (
    orderBy: number,
    orderDirection: "asc" | "desc" | ""
  ) => {
    const field = columns[orderBy]
      ? String(columns[orderBy].field)
      : defaultSortField;
    const orderDirectionNew: SortingDirectionType =
      orderDirection === "" ? defaultSortDirection : orderDirection;

    setSorting({
      field: field,
      orderDirection: orderDirectionNew,
    });

    setDefaultSortCustomers(field, orderDirectionNew);
  };

  return (
    <MaterialTable<CustomerType>
      style={getTableStyle()}
      title={""}
      icons={tableIcons}
      localization={GetTableLocalization(t)}
      columns={columns}
      data={data}
      options={getTableOptions({
        paging: true,
        pageSize: 25,
        pageSizeOptions: [25, 50, 100],
      })}
      onRowClick={(evt, selectedRow) => {
        if (selectedRow) {
          history.push(getUrlCustomerTab(selectedRow.id, "basic"));
        }
      }}
      onOrderChange={handleOrderChange}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerList);
