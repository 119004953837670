import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  CatalogTransferMethodType,
  Mutation,
  MutationDeleteCatalogTransferMethodArgs,
  MutationUpdateCatalogTransferMethodArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { GET_CATALOG_TRANSFER_QUERY } from "../../apollo/queries/catalogs_transfer";
import {
  DELETE_CATALOG_TRANSFER_METHOD_MUTATION,
  UPDATE_CATALOG_TRANSFER_METHOD_MUTATION,
} from "../../apollo/mutations/catalogs_transfer";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { useTranslation } from "react-i18next";

interface Props extends WithStyles<typeof styles> {
  catalogTransferMethod: CatalogTransferMethodType;
}

function CatalogTransferMethodRow({ classes, catalogTransferMethod }: Props) {
  const { t } = useTranslation();

  const [catalogTransferMethodEdited, setCatalogTransferMethodEdited] =
    useState(catalogTransferMethod);

  const [updateCatalogTransferMethod, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogTransferMethodArgs
  >(UPDATE_CATALOG_TRANSFER_METHOD_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_TRANSFER_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });
  const [deleteCatalogTransferMethod, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogTransferMethodArgs
  >(DELETE_CATALOG_TRANSFER_METHOD_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_TRANSFER_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_transfer.change_catalogtransfermethod",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_transfer.delete_catalogtransfermethod",
  ]);

  const handleUpdate = () => {
    updateCatalogTransferMethod({
      variables: {
        catalogTransferMethodId: catalogTransferMethodEdited.id,
        name: catalogTransferMethodEdited.name,
      },
    });
  };

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogTransferMethod({
        variables: {
          catalogTransferMethodId: catalogTransferMethodEdited.id,
        },
      });
    });
  };

  return (
    <tr>
      <td>
        <FormControl fullWidth className={loadingUpdate ? "loading" : ""}>
          <TextField
            value={catalogTransferMethodEdited.name}
            inputProps={{
              maxLength: 100,
            }}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              setCatalogTransferMethodEdited({
                ...catalogTransferMethodEdited,
                name: event.target.value,
              });
            }}
            onBlur={handleUpdate}
          />
        </FormControl>
      </td>
      <td>
        {hasPermissionDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            onClick={onClickDelete}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogTransferMethodRow);
