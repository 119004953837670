import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Box,
  Checkbox,
  createStyles,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import {
  LocationType,
  MachineType,
  Mutation,
  MutationSplitReservationArgs,
  QueryCatalogArgs,
  QueryMachinesForCatalogRowAvailableArgs,
  QueryMachinesSearchAvailableArgs,
  QueryReservationEditableDatesArgs,
  ReservationType,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_RESERVATION_EDITABLE_DATES_QUERY,
  QueryResultReservationEditableDates,
} from "../../apollo/queries/reservations";
import format from "date-fns/format";
import { DATE_FORMAT_ISO, ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { handleError } from "../../entity/ErrorHandler";
import { SPLIT_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import Error from "../Shared/Error";
import { getMachineName } from "../../utils/machines/machine";
import {
  GET_MACHINES_FOR_CATALOG_ROW_AVAILABLE_QUERY,
  GET_MACHINES_SEARCH_AVAILABLE_QUERY,
  QueryResultMachinesForCatalogRowAvailable,
  QueryResultMachinesSearchAvailable,
} from "../../apollo/queries/machines";
import { LocationEmpty, ReservationEmpty } from "../../entity/empties";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { dateDiffInDays, newDate, resetDateTime } from "../../utils/dates";
import { renderSelectGroupCategoryRow } from "../../utils/catalogs/select_group";
import { SettingsContext } from "../../Root";
import { getCatalogIdActive } from "../../utils/catalogs/selected_catalog";
import {
  GET_CATALOG_QUERY,
  QueryResultCatalog,
} from "../../apollo/queries/catalogs";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
  setReservation: React.Dispatch<React.SetStateAction<ReservationType>>;
}

function DialogReservationSplitMachine({
  classes,
  open,
  setOpen,
  reservation,
  setReservation,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const catalogId = getCatalogIdActive(settings);

  const [machineIdSelected, setMachineIdSelected] = useState("");
  const [dateReservationEnd, setDateReservationEnd] = useState(
    format(new Date(), DATE_FORMAT_ISO)
  );
  const [search, setSearch] = useState("");
  const [doCatalogRowChange, setDoCatalogRowChange] = useState(false);
  const [catalogRowIdSelected, setCatalogRowIdSelected] = useState<string>(
    reservation.catalogRow ? reservation.catalogRow.id : ID_EMPTY
  );

  const {
    loading: loadingMachines,
    error: errorMachines,
    data: dataMachines,
  } = useQuery<
    QueryResultMachinesForCatalogRowAvailable,
    QueryMachinesForCatalogRowAvailableArgs
  >(GET_MACHINES_FOR_CATALOG_ROW_AVAILABLE_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      catalogRowId: reservation.catalogRow
        ? reservation.catalogRow.id
        : ID_EMPTY,
      dateFrom: dateReservationEnd,
      dateTo: reservation.dateReturned,
    },
    skip: !reservation.catalogRow,
  });

  const {
    loading: loadingMachinesSearch,
    error: errorMachinesSearch,
    data: dataMachinesSearch,
  } = useQuery<
    QueryResultMachinesSearchAvailable,
    QueryMachinesSearchAvailableArgs
  >(GET_MACHINES_SEARCH_AVAILABLE_QUERY, {
    fetchPolicy: "no-cache",
    variables: {
      dateFrom: dateReservationEnd,
      dateTo: reservation.dateReturned,
      search: search,
    },
    skip: !search,
  });

  const {
    loading: loadingDates,
    error: errorDates,
    data: dataDates,
  } = useQuery<
    QueryResultReservationEditableDates,
    QueryReservationEditableDatesArgs
  >(GET_RESERVATION_EDITABLE_DATES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationEditableDates"),
    variables: {
      reservationIds: [reservation.id],
    },
  });

  const {
    loading: loadingCatalog,
    error: errorCatalog,
    data: dataCatalog,
  } = useQuery<QueryResultCatalog, QueryCatalogArgs>(GET_CATALOG_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalog"),
    variables: {
      catalogId: catalogId,
    },
  });

  const [splitReservation] = useMutation<
    Mutation,
    MutationSplitReservationArgs
  >(SPLIT_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOpen(false);
      // Close the open reservation so data has to be reloaded
      // Need manual reload if only machine changes without copying reservation
      setReservation(ReservationEmpty);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("order"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationsCalendar"),
      });
    },
  });

  if (errorCatalog) return <Error error={errorCatalog} />;
  if (errorDates) return <Error error={errorDates} />;
  if (errorMachines) return <Error error={errorMachines} />;
  if (errorMachinesSearch) return <Error error={errorMachinesSearch} />;

  const onClickMove = () => {
    splitReservation({
      variables: {
        reservationId: reservation.id,
        machineId: machineIdSelected,
        dateEnd: dateReservationEnd,
        catalogRowId: catalogRowIdSelected,
      },
    });
  };

  const dateSelectionStart = dataDates?.reservationEditableDates
    ? dataDates.reservationEditableDates.dateStart
    : null;
  const dateSelectionEnd = dataDates?.reservationEditableDates
    ? dataDates.reservationEditableDates.dateEnd
    : null;

  const dataMachinesSource = search
    ? dataMachinesSearch?.machinesSearchAvailable
    : dataMachines?.machinesForCatalogRowAvailable;

  let machinesByLocation: {
    [location_id: string]: { location: LocationType; machines: MachineType[] };
  } = {};
  let countMachines = 0;
  dataMachinesSource?.forEach((machine) => {
    const key = machine.location ? machine.location.id : ID_EMPTY;
    if (machinesByLocation[key] === undefined) {
      machinesByLocation[key] = {
        location: machine.location ? machine.location : LocationEmpty,
        machines: [],
      };
    }
    if (reservation.machine && machine.id !== reservation.machine.id) {
      machinesByLocation[key]["machines"].push(machine);
      countMachines++;
    }
  });

  const today = resetDateTime(new Date());
  const rentedAt = newDate(reservation.dateRented);
  const showDate = dateDiffInDays(rentedAt, today) > 0;

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <form>
        <DialogTitle>{t("reservation_split")}</DialogTitle>
        <DialogContent
          className={loadingDates || loadingMachines ? "loading" : ""}
        >
          <FormControl fullWidth>
            <TextField
              label={t("search_from_all_available_machines")}
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </FormControl>
          <FormControl
            className={loadingMachinesSearch ? "loading" : ""}
            fullWidth
          >
            <InputLabel id="lblReservationSplitMachines">
              {search
                ? t("machines_search_result", {
                    search: search,
                    count: countMachines,
                  })
                : t("select_from_catalog_row_machines")}
            </InputLabel>
            <Select
              labelId="lblReservationSplitMachines"
              value={machineIdSelected}
              onChange={(event: React.ChangeEvent<{ value: any }>) => {
                setMachineIdSelected(event.target.value);
              }}
            >
              {Object.entries(machinesByLocation).map(([location_id, row]) => {
                return [
                  <ListSubheader>{row.location.name}</ListSubheader>,
                  row.machines.map((machine) => (
                    <MenuItem key={machine.id} value={machine.id}>
                      {getMachineName(machine)}{" "}
                      <small className="text-muted ms-2" title={machine.serial}>
                        {machine.identifier}
                      </small>
                    </MenuItem>
                  )),
                ];
              })}
            </Select>
          </FormControl>
          {showDate && (
            <>
              <FormControl fullWidth>
                <TextField
                  label={t("date_reservation_split")}
                  type="date"
                  value={dateReservationEnd}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: dateSelectionStart,
                    max: dateSelectionEnd,
                  }}
                  onChange={(event) => {
                    setDateReservationEnd(event.target.value);
                  }}
                />
              </FormControl>
              <Box className="text-muted mb-3">
                {t("date_reservation_split_explained")}
              </Box>
            </>
          )}
          <FormControl fullWidth>
            <FormControlLabel
              label={t("reservation_split_change_catalog_row")}
              control={
                <Checkbox
                  checked={doCatalogRowChange}
                  onChange={(event) => {
                    setDoCatalogRowChange(event.target.checked);
                  }}
                />
              }
            />
          </FormControl>
          {doCatalogRowChange && (
            <div>
              <p className="text-muted">
                {t("reservation_split_catalog_row_help_text")}
              </p>
              <FormControl
                fullWidth
                className={loadingCatalog ? "loading" : ""}
              >
                <InputLabel id="lblReservationSplitCatalogRow">
                  {t("catalog_row")}
                </InputLabel>
                <Select
                  labelId="lblReservationSplitCatalogRow"
                  value={catalogRowIdSelected}
                  onChange={(event) => {
                    setCatalogRowIdSelected(event.target.value as string);
                  }}
                  disabled={loadingCatalog}
                >
                  {dataCatalog?.catalog?.catalogcategoryupperSet.map(
                    (catalogCategoryUpper) =>
                      renderSelectGroupCategoryRow(catalogCategoryUpper)
                  )}
                </Select>
              </FormControl>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={onClickMove}>
            {t("switch_reservation_machine")}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogReservationSplitMachine);
