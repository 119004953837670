import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CatalogEmpty } from "../entity/empties";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationCreateCatalogArgs } from "../entity/types";
import {
  GET_CATALOGS_WITHOUT_ROWS_QUERY,
  QueryResultCatalogsWithoutRows,
} from "../apollo/queries/catalogs";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { CREATE_CATALOG_MUTATION } from "../apollo/mutations/catalogs";
import { handleError } from "../entity/ErrorHandler";
import { useHistory } from "react-router-dom";
import { getUrlSettingsCatalogEdit } from "../utils/urls";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import { ID_EMPTY } from "../utils/constants";
import { updateCacheCatalogEdit } from "../utils/cache";
import { getCatalogIdActive } from "../utils/catalogs/selected_catalog";
import HeaderPage from "../components/Shared/HeaderPage";
import SelectRequired from "../components/Shared/SelectRequired";
import ButtonLoad from "../components/Shared/ButtonLoad";
import { SettingsContext } from "../Root";

interface Props extends WithStyles<typeof styles> {}

function SettingsCatalogNew({ classes }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const settings = useContext(SettingsContext);
  const catalogIdActive = getCatalogIdActive(settings);

  const [catalog, setCatalog] = useState(CatalogEmpty);
  const [catalogIdBase, setCatalogIdBase] = useState(catalogIdActive);

  const {
    loading: loadingGet,
    error,
    data,
  } = useQuery<QueryResultCatalogsWithoutRows>(
    GET_CATALOGS_WITHOUT_ROWS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogs"),
    }
  );

  const [createCatalog, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCatalogArgs
  >(CREATE_CATALOG_MUTATION, {
    onCompleted: (result) => {
      if (result.createCatalog?.catalog?.id) {
        history.push(
          getUrlSettingsCatalogEdit(result.createCatalog.catalog.id)
        );
      }
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  if (loadingGet) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickSave = () => {
    createCatalog({
      variables: {
        name: catalog.name,
        catalogIdBase: catalogIdBase,
      },
    });
  };

  return (
    <Container className="containerMain">
      <HeaderPage title={t("new_catalog")} />
      <div className="containerInner">
        <div className="p-3">
          <Row className="mb-3">
            <Col lg={4} md={6}>
              <FormControl fullWidth>
                <TextField
                  className={classes.textField}
                  label={t("name")}
                  onChange={(event) => {
                    setCatalog({ ...catalog, name: event.target.value });
                  }}
                  value={catalog.name}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="lblSettingsCatalogNewCatalogs">
                  {t("catalog_copied_from")}
                </InputLabel>
                <SelectRequired
                  labelId="lblSettingsCatalogNewCatalogs"
                  className={classes.textField}
                  value={catalogIdBase}
                  onChange={(event) => {
                    setCatalogIdBase(String(event.target.value));
                  }}
                >
                  <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
                  {data.catalogs
                    ?.filter(
                      (catalogLooped) => catalogLooped.id === catalogIdActive
                    )
                    .map((catalogLooped) => (
                      <MenuItem key={catalogLooped.id} value={catalogLooped.id}>
                        {catalogLooped.name}
                      </MenuItem>
                    ))}
                </SelectRequired>
              </FormControl>
            </Col>
          </Row>
          <div className="buttons">
            <ButtonLoad loading={loadingCreate} onClick={onClickSave}>
              {t("save")}
            </ButtonLoad>
          </div>
        </div>
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export default withStyles(styles)(SettingsCatalogNew);
