import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  InspectionGiveAnswerType,
  InspectionsInspectionGiveQuestionAnswerTypeChoices,
  InspectionGiveQuestionType,
  Mutation,
  MutationUpdateInspectionGiveAnswerArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { UPDATE_INSPECTION_GIVE_ANSWER_MUTATION } from "../../apollo/mutations/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { InspectionGiveQuestionOptionEmpty } from "../../entity/empties";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveAnswer: InspectionGiveAnswerType;
  inspectionGiveQuestion: InspectionGiveQuestionType;
}

function InspectionGiveAnswer({
  classes,
  inspectionGiveAnswer,
  inspectionGiveQuestion,
}: Props) {
  const { t } = useTranslation();

  const [inspectionGiveAnswerEdited, setInspectionGiveAnswerEdited] =
    useState(inspectionGiveAnswer);

  const [updateInspectionGiveAnswer, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateInspectionGiveAnswerArgs
  >(UPDATE_INSPECTION_GIVE_ANSWER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeAnswer = checkPermission(myPermissions, [
    "inspections.change_inspectiongiveanswer",
  ]);

  const handleUpdateAnswer = (
    values: Partial<MutationUpdateInspectionGiveAnswerArgs> = {}
  ) => {
    let base: MutationUpdateInspectionGiveAnswerArgs = {
      inspectionGiveAnswerId: inspectionGiveAnswerEdited.id,
      inspectionGiveQuestionOptionId:
        inspectionGiveAnswerEdited.inspectionGiveQuestionOption
          ? inspectionGiveAnswerEdited.inspectionGiveQuestionOption.id
          : undefined,
      answer: inspectionGiveAnswerEdited.answer,
      checked: inspectionGiveAnswerEdited.checked,
    };

    updateInspectionGiveAnswer({
      variables: { ...base, ...values },
    });
  };

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      <FormControl fullWidth className="mb-4">
        {inspectionGiveQuestion.answerType ===
          InspectionsInspectionGiveQuestionAnswerTypeChoices.Text && (
          <TextField
            label={inspectionGiveQuestion.question}
            value={inspectionGiveAnswerEdited.answer}
            required={true}
            inputProps={{
              maxLength: 200,
            }}
            disabled={!hasPermissionChangeAnswer}
            onChange={(event) => {
              setInspectionGiveAnswerEdited({
                ...inspectionGiveAnswerEdited,
                answer: event.target.value,
              });
            }}
            onBlur={() => {
              handleUpdateAnswer();
            }}
          />
        )}
        {inspectionGiveQuestion.answerType ===
          InspectionsInspectionGiveQuestionAnswerTypeChoices.Options && (
          <>
            <InputLabel id="lblDialogInspectionGiveQuestion">
              {inspectionGiveQuestion.question}
            </InputLabel>
            <Select
              fullWidth
              labelId="lblDialogInspectionGiveQuestion"
              disabled={!hasPermissionChangeAnswer}
              value={
                inspectionGiveAnswerEdited.inspectionGiveQuestionOption
                  ? inspectionGiveAnswerEdited.inspectionGiveQuestionOption.id
                  : ID_EMPTY
              }
              required={true}
              onChange={(event) => {
                let inspectionGiveQuestionOptionId = String(event.target.value);
                let inspectionGiveQuestionOption =
                  inspectionGiveAnswerEdited.inspectionGiveQuestionOption;
                if (!inspectionGiveQuestionOption) {
                  inspectionGiveQuestionOption =
                    InspectionGiveQuestionOptionEmpty;
                }

                setInspectionGiveAnswerEdited({
                  ...inspectionGiveAnswerEdited,
                  inspectionGiveQuestionOption: {
                    ...inspectionGiveQuestionOption,
                    id: inspectionGiveQuestionOptionId,
                  },
                });

                handleUpdateAnswer({
                  inspectionGiveQuestionOptionId:
                    inspectionGiveQuestionOptionId !== ID_EMPTY
                      ? inspectionGiveQuestionOptionId
                      : undefined,
                });
              }}
            >
              <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
              {inspectionGiveQuestion.inspectiongivequestionoptionSet.map(
                (inspectionGiveQuestionOption) => (
                  <MenuItem
                    key={inspectionGiveQuestionOption.id}
                    value={inspectionGiveQuestionOption.id}
                  >
                    {inspectionGiveQuestionOption.title}
                  </MenuItem>
                )
              )}
            </Select>
          </>
        )}
        {inspectionGiveQuestion.answerType ===
          InspectionsInspectionGiveQuestionAnswerTypeChoices.Check && (
          <>
            <FormLabel>{inspectionGiveQuestion.question}</FormLabel>
            <RadioGroup
              aria-label={inspectionGiveQuestion.question}
              name="inspectionGiveAnswerCheck"
              value={
                inspectionGiveAnswerEdited.checked === true
                  ? "1"
                  : inspectionGiveAnswerEdited.checked === false
                  ? "0"
                  : ""
              }
              onChange={(event, value) => {
                const checkedNew = value === "1";
                setInspectionGiveAnswerEdited({
                  ...inspectionGiveAnswerEdited,
                  checked: checkedNew,
                });
                handleUpdateAnswer({ checked: checkedNew });
              }}
            >
              <FormControlLabel
                value="1"
                label={t("check_ok")}
                control={<Radio />}
              />
              <FormControlLabel
                value="0"
                label={t("check_not_ok")}
                control={<Radio />}
              />
            </RadioGroup>
            {inspectionGiveAnswerEdited.checked === false && (
              <TextField
                value={inspectionGiveAnswerEdited.answer}
                required={true}
                inputProps={{
                  maxLength: 200,
                }}
                disabled={!hasPermissionChangeAnswer}
                onChange={(event) => {
                  setInspectionGiveAnswerEdited({
                    ...inspectionGiveAnswerEdited,
                    answer: event.target.value,
                  });
                }}
                onBlur={() => {
                  handleUpdateAnswer();
                }}
              />
            )}
          </>
        )}
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionGiveAnswer);
