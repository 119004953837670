import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import {
  DriveContentType,
  DriveType,
  Mutation,
  MutationDeleteDriveArgs,
  MutationDeleteDriveContentArgs,
  QueryDrivesArgs,
} from "../../entity/types";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  GET_DRIVES_QUERY,
  QueryResultDrives,
} from "../../apollo/queries/drives";
import { newDate, resetDateTime } from "../../utils/dates";
import { format, parse } from "date-fns";
import { DATE_FORMAT_ISO, ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { Col, Row, Table } from "react-bootstrap";
import { getReservationProductName } from "../../utils/reservations/reservation";
import {
  getAddressSingleLine,
  timeWithoutSeconds,
} from "../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { handleError } from "../../entity/ErrorHandler";
import {
  DELETE_DRIVE_CONTENT_MUTATION,
  DELETE_DRIVE_MUTATION,
} from "../../apollo/mutations/drives";
import { dialogConfirm } from "../../utils/dialogs";
import { getQueryKey } from "../../utils/cache";
import LocationFilter from "../Shared/LocationFilter";
import { getLocationSelected } from "../../utils/locations/location";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ButtonLoad from "../Shared/ButtonLoad";
import ButtonOrderReservation from "../Shared/ButtonOrderReservation";
import DialogOrderId from "../Customer/DialogOrderId";

interface Props extends WithStyles<typeof styles> {}

function DrivingList({ classes }: Props) {
  const { t } = useTranslation();

  const dateFromDefault = resetDateTime(new Date());
  dateFromDefault.setDate(dateFromDefault.getDate() - 7);
  const dateToDefault = resetDateTime(new Date());

  const user = useContext(UserContext);
  const locationIdDefault = getLocationSelected(user);

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);
  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [openDialogOrder, setOpenDialogOrder] = useState(false);
  const [orderIdSelected, setOrderIdSelected] = useState(ID_EMPTY);
  const [reservationIdSelected, setReservationIdSelected] = useState(ID_EMPTY);

  const { loading, error, data } = useQuery<QueryResultDrives, QueryDrivesArgs>(
    GET_DRIVES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("drives"),
      variables: {
        locationIds: locationIdsSelected,
        dateFrom: format(dateFrom, DATE_FORMAT_ISO),
        dateTo: format(dateTo, DATE_FORMAT_ISO),
      },
    }
  );

  const [deleteDrive, { loading: loadingDeleteDrive }] = useMutation<
    Mutation,
    MutationDeleteDriveArgs
  >(DELETE_DRIVE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportsUnhandled"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("drives"),
      });
    },
  });

  const [deleteDriveContent, { loading: loadingDeleteDriveContent }] =
    useMutation<Mutation, MutationDeleteDriveContentArgs>(
      DELETE_DRIVE_CONTENT_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("transportsUnhandled"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("drives"),
          });
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionDeleteDrive = checkPermission(myPermissions, [
    "drives.delete_drive",
  ]);
  const hasPermissionDeleteDriveContent = checkPermission(myPermissions, [
    "drives.delete_drivecontent",
  ]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const drives: DriveType[] = (data.drives ? data.drives : []).map((o) => ({
    ...o,
  }));

  drives.sort((a, b) => {
    const getSmallest = (driveContents: DriveContentType[]) => {
      let dateDeadline = "";
      driveContents.forEach((dc) => {
        if (dc.transport.dateDeadline) {
          const dateDeadlineLooped =
            dc.transport.dateDeadline +
            " " +
            (dc.transport.timeDeadline
              ? dc.transport.timeDeadline
              : "23:59:59");
          if (dateDeadline === "" || dateDeadlineLooped < dateDeadline) {
            dateDeadline = dateDeadlineLooped;
          }
        }
      });
      return dateDeadline;
    };

    const dateDeadlineA = getSmallest(a.drivecontentSet);
    const dateDeadlineB = getSmallest(b.drivecontentSet);

    return dateDeadlineA.localeCompare(dateDeadlineB);
  });

  const onClickDeleteDrive = (driveId: string) => {
    dialogConfirm(t, t("confirm_delete_drive"), () => {
      deleteDrive({
        variables: {
          driveId: driveId,
        },
      });
    });
  };

  const onClickDeleteDriveContent = (driveContentId: string) => {
    dialogConfirm(t, t("confirm_delete_drive_content"), () => {
      deleteDriveContent({
        variables: {
          driveContentId: driveContentId,
        },
      });
    });
  };

  const onClickReservation = (orderId: string, reservationId: string) => {
    setOrderIdSelected(orderId);
    setReservationIdSelected(reservationId);
    setOpenDialogOrder(true);
  };

  return (
    <div>
      <Row className="mb-4">
        <Col sm={6} md={6} lg={4} xl={3}>
          <LocationFilter
            locationIdsSelected={locationIdsSelected}
            setLocationIdsSelected={setLocationIdsSelected}
          />
        </Col>
        <Col>
          <div>
            <FormControl className="mb-2">
              <TextField
                className="me-4"
                type="date"
                label={t("date_from")}
                value={format(dateFrom, DATE_FORMAT_ISO)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  let date = parse(
                    event.target.value,
                    DATE_FORMAT_ISO,
                    new Date()
                  );
                  setDateFrom(date);
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                type="date"
                label={t("date_to")}
                value={format(dateTo, DATE_FORMAT_ISO)}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  let date = parse(
                    event.target.value,
                    DATE_FORMAT_ISO,
                    new Date()
                  );
                  setDateTo(date);
                }}
              />
            </FormControl>
          </div>
        </Col>
      </Row>
      <div>
        <Table>
          <thead>
            <tr>
              <th>{t("transports")}</th>
              <th>{t("created_by")}</th>
              <th>{t("created_at")}</th>
            </tr>
          </thead>
          <tbody>
            {drives.map((drive) => (
              <tr key={drive.id}>
                <td>
                  {drive.id}
                  <Table className="m-0 w-auto" borderless>
                    <tbody>
                      {drive.drivecontentSet.map((driveContent) => (
                        <tr key={driveContent.id}>
                          <td>
                            {driveContent.transport.dateDeadline
                              ? format(
                                  newDate(driveContent.transport.dateDeadline),
                                  t("format_date")
                                )
                              : ""}
                          </td>
                          <td>
                            {timeWithoutSeconds(
                              driveContent.transport.timeDeadline
                            )}
                          </td>
                          <td title={t("reservation_id_header")}>
                            <ButtonOrderReservation
                              reservationId={driveContent.reservation.id}
                              callbackOnClick={() => {
                                onClickReservation(
                                  driveContent.reservation.order.id,
                                  driveContent.reservation.id
                                );
                              }}
                            />
                          </td>
                          <td>
                            {getReservationProductName(
                              t,
                              driveContent.reservation,
                              true
                            )}
                          </td>
                          <td>
                            {t(
                              "transport_method_" +
                                driveContent.transport.method
                            )}
                          </td>
                          <td>
                            {getAddressSingleLine(
                              t,
                              driveContent.transport.address,
                              driveContent.transport.postcode,
                              driveContent.transport.district
                            )}
                          </td>
                          <td>
                            {hasPermissionDeleteDriveContent && (
                              <ButtonLoad
                                loading={loadingDeleteDriveContent}
                                variant="light"
                                size="sm"
                                onClick={() =>
                                  onClickDeleteDriveContent(driveContent.id)
                                }
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </ButtonLoad>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </td>
                <td>
                  {drive.createdBy && (
                    <span>
                      {drive.createdBy.lastName} {drive.createdBy.firstName}
                    </span>
                  )}
                </td>
                <td>
                  {format(newDate(drive.createdAt), t("format_datetime"))}
                </td>
                <td>
                  {hasPermissionDeleteDrive && (
                    <ButtonLoad
                      loading={loadingDeleteDrive}
                      variant="light"
                      size="sm"
                      onClick={() => onClickDeleteDrive(drive.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonLoad>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {openDialogOrder && (
          <DialogOrderId
            open={openDialogOrder}
            setOpen={setOpenDialogOrder}
            orderId={orderIdSelected}
            reservationIdSelected={reservationIdSelected}
          />
        )}
      </div>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DrivingList);
