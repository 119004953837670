import React, { useContext, useState } from "react";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { createStyles, Theme } from "@material-ui/core";
import { Button } from "react-bootstrap";
import {
  MaintenanceType,
  MutationCreateMaintenanceArgs,
} from "../../entity/types";
import { MaintenanceTypeEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  CREATE_MAINTENANCE_MUTATION,
  MutationResultCreateMaintenance,
} from "../../apollo/mutations/maintenances";
import { useMutation } from "@apollo/client";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryKey } from "../../utils/cache";
import { useTranslation } from "react-i18next";
import DialogContentMaintenance from "./DialogContentMaintenance";

interface Props extends WithStyles<typeof styles> {
  btnClass: string;
  locations: string[];
}

function CreateMaintenance({ classes, btnClass, locations }: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [maintenance, setMaintenance] =
    useState<MaintenanceType>(MaintenanceTypeEmpty);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "maintenance.add_maintenance",
  ]);

  const [createMaintenance, { loading: loadingCreate }] = useMutation<
    MutationResultCreateMaintenance,
    MutationCreateMaintenanceArgs
  >(CREATE_MAINTENANCE_MUTATION, {
    onCompleted: () => {
      setMaintenance(MaintenanceTypeEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenancesUpcoming"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceNextForMachine"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceNextForCatalogExtraRowRental"),
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    createMaintenance({
      variables: {
        machineId: maintenance?.machine ? maintenance.machine.id : ID_EMPTY,
        catalogExtraRowRentalId: maintenance?.catalogExtraRowRental
          ? maintenance.catalogExtraRowRental.id
          : ID_EMPTY,
        maintenanceIntervalId: maintenance?.maintenanceInterval
          ? maintenance.maintenanceInterval.id
          : ID_EMPTY,
        estimatedDate: maintenance.estimatedDate,
        createdManually: true,
      },
    });
    setOpen(false);
  };

  if (!hasPermissionAdd) {
    return <></>;
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        className={btnClass}
        variant="primary"
        size="lg"
      >
        {open ? <ClearIcon /> : <AddIcon />}
      </Button>
      <Dialog open={open} fullWidth={true} maxWidth="xs">
        <form
          className={loadingCreate ? "loading" : ""}
          onSubmit={(event) => handleSubmit(event)}
        >
          <DialogTitle>{t("create_maintenance")}</DialogTitle>
          <DialogContentMaintenance
            maintenance={maintenance}
            setMaintenance={setMaintenance}
            locations={locations}
          />
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="secondary">
              {t("cancel")}
            </Button>
            <Button type="submit" variant="primary">
              {t("save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    addButton: {
      marginTop: "-0.5rem",
    },
    textField: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
    },
  });

export default withStyles(styles)(CreateMaintenance);
