import { Query } from "../../entity/types";
import { gql } from "@apollo/client";

export interface QueryResultInspectionReturnCategories {
  inspectionReturnCategories: Query["inspectionReturnCategories"];
  inspectionReturnTags: Query["inspectionReturnTags"];
}
export const GET_INSPECTION_RETURN_CATEGORIES_QUERY = gql`
  query getInspectionReturnCategoriesQuery {
    inspectionReturnCategories {
      id
      name
      inspectionreturnquestionSet {
        id
        question
        answerType
        inspectionReturnCategory {
          id
        }
        inspectionreturntagSet {
          id
          name
        }
        inspectionreturnquestionoptionSet {
          id
          title
        }
      }
    }
    inspectionReturnTags {
      id
      name
    }
  }
`;

export interface QueryResultMachineWithInspectionReturnCategories {
  machine: Query["machine"];
  inspectionReturnCategories: Query["inspectionReturnCategories"];
  inspectionReturnTags: Query["inspectionReturnTags"];
  machines: Query["machines"];
}
export const GET_MACHINE_WITH_INSPECTION_RETURN_CATEGORIES_QUERY = gql`
  query getMachineWithInspectionReturnCategoriesQuery($machineId: ID!) {
    machine(machineId: $machineId) {
      id
      inspectionreturntomachineSet {
        id
        inspectionReturnQuestion {
          id
          inspectionReturnCategory {
            id
          }
        }
      }
    }
    inspectionReturnCategories {
      id
      name
      inspectionreturnquestionSet {
        id
        question
        answerType
        inspectionReturnCategory {
          id
        }
        inspectionreturntagSet {
          id
          name
        }
        inspectionreturnquestionoptionSet {
          id
          title
        }
      }
    }
    inspectionReturnTags {
      id
      name
    }
    machines {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
  }
`;

export interface QueryResultInspectionReturnAnswersForReservation {
  inspectionReturnAnswersForReservation: Query["inspectionReturnAnswersForReservation"];
}
export const GET_INSPECTION_RETURN_ANSWERS_FOR_RESERVATION_QUERY = gql`
  query getInspectionReturnAnswersForReservationQuery($reservationId: ID!) {
    inspectionReturnAnswersForReservation(reservationId: $reservationId) {
      id
      answer
      checked
      inspectionReturnQuestionOption {
        id
        title
      }
      inspectionReturnToMachine {
        id
        inspectionReturnQuestion {
          id
          answerType
          question
          inspectionreturnquestionoptionSet {
            id
            title
          }
        }
      }
    }
  }
`;

export interface QueryResultInspectionGiveCategories {
  inspectionGiveCategories: Query["inspectionGiveCategories"];
  inspectionGiveTags: Query["inspectionGiveTags"];
}
export const GET_INSPECTION_GIVE_CATEGORIES_QUERY = gql`
  query getInspectionGiveCategoriesQuery {
    inspectionGiveCategories {
      id
      name
      inspectiongivequestionSet {
        id
        question
        answerType
        inspectionGiveCategory {
          id
        }
        inspectiongivetagSet {
          id
          name
        }
        inspectiongivequestionoptionSet {
          id
          title
        }
      }
    }
    inspectionGiveTags {
      id
      name
    }
  }
`;

export interface QueryResultMachineWithInspectionGiveCategories {
  machine: Query["machine"];
  inspectionGiveCategories: Query["inspectionGiveCategories"];
  inspectionGiveTags: Query["inspectionGiveTags"];
  machines: Query["machines"];
}
export const GET_MACHINE_WITH_INSPECTION_GIVE_CATEGORIES_QUERY = gql`
  query getMachineWithInspectionGiveCategoriesQuery($machineId: ID!) {
    machine(machineId: $machineId) {
      id
      inspectiongivetomachineSet {
        id
        inspectionGiveQuestion {
          id
          inspectionGiveCategory {
            id
          }
        }
      }
    }
    inspectionGiveCategories {
      id
      name
      inspectiongivequestionSet {
        id
        question
        answerType
        inspectionGiveCategory {
          id
        }
        inspectiongivetagSet {
          id
          name
        }
        inspectiongivequestionoptionSet {
          id
          title
        }
      }
    }
    inspectionGiveTags {
      id
      name
    }
    machines {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
  }
`;

export interface QueryResultInspectionGiveAnswersForReservation {
  inspectionGiveAnswersForReservation: Query["inspectionGiveAnswersForReservation"];
}
export const GET_INSPECTION_GIVE_ANSWERS_FOR_RESERVATION_QUERY = gql`
  query getInspectionGiveAnswersForReservationQuery($reservationId: ID!) {
    inspectionGiveAnswersForReservation(reservationId: $reservationId) {
      id
      answer
      checked
      inspectionGiveQuestionOption {
        id
        title
      }
      inspectionGiveToMachine {
        id
        inspectionGiveQuestion {
          id
          answerType
          question
          inspectiongivequestionoptionSet {
            id
            title
          }
        }
      }
    }
  }
`;
