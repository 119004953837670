import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import WorkQueueRentingCustomerPickup from "../components/WorkQueues/WorkQueueRentingCustomerPickup";
import { TransportsTransportMethodChoices } from "../entity/types";
import {
  ReservationFromReservationsQueueReturnType,
  ReservationFromReservationsWorkQueuesType,
} from "../apollo/queries/reservations";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import WorkQueueRentingCustomerReturn from "../components/WorkQueues/WorkQueueRentingCustomerReturn";
import {
  CONTAINER_MAIN_WIDTH_XL,
  ID_EMPTY,
  WORK_QUEUE_MODES,
} from "../utils/constants";
import WorkQueueDriveSend from "../components/WorkQueues/WorkQueueDriveSend";
import WorkQueueDriveRetrieve from "../components/WorkQueues/WorkQueueDriveRetrieve";
import LocationFilter from "../components/Shared/LocationFilter";
import { UserContext } from "../Root";
import {
  getLocationIdDefault,
  getLocationSelected,
} from "../utils/locations/location";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import HeaderPage from "../components/Shared/HeaderPage";
import ButtonLoad from "../components/Shared/ButtonLoad";
import AddIcon from "@material-ui/icons/Add";
import DialogReservationReturn from "../components/WorkQueues/DialogReservationReturn";
import DialogReservationWizard from "../components/WorkQueues/DialogReservationWizard";
import { findFromSetById } from "../utils/collections";
import { ReservationEmpty } from "../entity/empties";
import {
  useGetReservationsWorkQueuesQueryQuery,
  useGetReservationsWorkQueuesReturnQueryQuery,
} from "../apollo/queries/reservations.generated";
import { useGetDrivesForWorkQueuesQueryQuery } from "../apollo/queries/drives.generated";
import { DriveFromDrivesForWorkQueuesType } from "../apollo/queries/drives";

interface Props extends WithStyles<typeof styles> {}

function WorkQueuesRenting({ classes }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const locationIdDefault = getLocationSelected(user);

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);
  const [showMode, setShowMode] = useState<WORK_QUEUE_MODES>(
    WORK_QUEUE_MODES.ALL
  );
  const [openDialogReservationReturn, setOpenDialogReservationReturn] =
    useState(false);
  const [reservationIdWizard, setReservationIdWizard] = useState(ID_EMPTY);
  const [openDialogWizard, setOpenDialogWizard] = useState(false);

  const {
    loading: loadingReservations,
    error: errorReservations,
    data: dataReservations,
  } = useGetReservationsWorkQueuesQueryQuery({
    fetchPolicy: getQueryFetchPolicy("reservationsWorkQueues"),
    variables: {
      userId: showMode === WORK_QUEUE_MODES.OWN ? user.id : ID_EMPTY,
      locationIds: locationIdsSelected,
    },
  });

  const {
    loading: loadingDrives,
    error: errorDrives,
    data: dataDrives,
  } = useGetDrivesForWorkQueuesQueryQuery({
    fetchPolicy: getQueryFetchPolicy("drivesForWorkQueues"),
    variables: {
      userId: showMode === WORK_QUEUE_MODES.OWN ? user.id : ID_EMPTY,
      locationIds: locationIdsSelected,
    },
  });

  const { loading, error, data } = useGetReservationsWorkQueuesReturnQueryQuery(
    {
      fetchPolicy: getQueryFetchPolicy("reservationsWorkQueuesReturn"),
    }
  );

  if (error) return <Error error={error} />;
  if (loadingReservations || loadingDrives) return <Loading />;
  if (errorReservations) return <Error error={errorReservations} />;
  if (errorDrives) return <Error error={errorDrives} />;
  if (!dataReservations || !dataDrives) {
    return <Error error={t("error_query_failed")} />;
  }

  let reservationsCustomerPickup: ReservationFromReservationsWorkQueuesType[] =
    [];
  let reservationsCustomerReturn: ReservationFromReservationsWorkQueuesType[] =
    [];
  let drivesSend: DriveFromDrivesForWorkQueuesType[] = [];
  let drivesRetrieval: DriveFromDrivesForWorkQueuesType[] = [];

  dataReservations.reservationsWorkQueues.forEach((reservation) => {
    if (reservation.giveAt) {
      /* Reservations are only shown in the return queue when they have been returned and need imaging */
      if (reservation.returnAt) {
        reservationsCustomerReturn.push(reservation);
      }
    } else {
      reservationsCustomerPickup.push(reservation);
    }
  });

  dataDrives.drivesForWorkQueues?.forEach((drive) => {
    let hasSend = false;
    drive.drivecontentSet.forEach((driveContent) => {
      if (
        driveContent.transport.method === TransportsTransportMethodChoices.Send
      ) {
        hasSend = true;
      }
    });

    if (hasSend) {
      drivesSend.push(drive);
    } else {
      drivesRetrieval.push(drive);
    }
  });

  reservationsCustomerPickup.sort((a, b) =>
    (a.dateRented + " " + a.timeRented).localeCompare(
      b.dateRented + " " + b.timeRented
    )
  );

  reservationsCustomerReturn.sort((a, b) =>
    (a.dateReturned + " " + a.timeReturned).localeCompare(
      b.dateReturned + " " + b.timeReturned
    )
  );

  const reservationsReturnPre: ReservationFromReservationsQueueReturnType[] =
    data && data.reservationsWorkQueuesReturn
      ? data.reservationsWorkQueuesReturn
      : [];

  const getStamp = (
    drive: DriveFromDrivesForWorkQueuesType,
    fieldDate: "dateRented" | "dateReturned",
    fieldTime: "timeRented" | "timeReturned"
  ) => {
    let stampSmallest = "";
    drive.drivecontentSet.forEach((drive_content) => {
      const stamp =
        drive_content.reservation[fieldDate] +
        " " +
        drive_content.reservation[fieldTime];
      if (stampSmallest === "" || stamp < stampSmallest) {
        stampSmallest = stamp;
      }
    });
    return stampSmallest;
  };

  drivesSend.sort((a, b) => {
    const stampA = getStamp(a, "dateRented", "timeRented");
    const stampB = getStamp(b, "dateRented", "timeRented");

    return stampA.localeCompare(stampB);
  });

  drivesRetrieval.sort((a, b) => {
    const stampA = getStamp(a, "dateReturned", "timeReturned");
    const stampB = getStamp(b, "dateReturned", "timeReturned");

    return stampA.localeCompare(stampB);
  });

  const xs = 12;
  const sm = 12;
  const md = 6;
  const lg = 6;
  const xl = 3;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("work_queues_renting")} />
      <div className="containerInner">
        <Row className="mb-3">
          <Col className="mb-2" sm={6} md={6} lg={4} xl={3}>
            <LocationFilter
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
            />
          </Col>
          <Col sm={6} md={6} lg={4} xl={3}>
            <ToggleButtonGroup
              exclusive
              size="large"
              value={showMode}
              onChange={(event, value) => {
                setShowMode(value);
              }}
            >
              <ToggleButton
                value={WORK_QUEUE_MODES.ALL}
                className={
                  showMode === WORK_QUEUE_MODES.ALL ? classes.btnSelected : ""
                }
              >
                {t("work_queue_mode_" + WORK_QUEUE_MODES.ALL)}
              </ToggleButton>
              <ToggleButton
                value={WORK_QUEUE_MODES.OWN}
                className={
                  showMode === WORK_QUEUE_MODES.OWN ? classes.btnSelected : ""
                }
              >
                {t("work_queue_mode_" + WORK_QUEUE_MODES.OWN)}
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
          <Col className="mb-2" sm={6} md={6} lg={4} xl={3}>
            <ButtonLoad
              className="mt-2"
              loading={loading}
              onClick={() =>
                setOpenDialogReservationReturn(!openDialogReservationReturn)
              }
              disabled={reservationsReturnPre.length === 0}
            >
              <AddIcon /> {t("work_queues_renting_customer_return")}
            </ButtonLoad>
            {openDialogReservationReturn && (
              <DialogReservationReturn
                open={openDialogReservationReturn}
                setOpen={setOpenDialogReservationReturn}
                reservations={reservationsReturnPre}
                locationIdSelected={
                  locationIdsSelected.length === 1
                    ? locationIdsSelected[0]
                    : getLocationIdDefault(user)
                }
                callbackOnUpdate={(reservationId) => {
                  setReservationIdWizard(reservationId);
                  setOpenDialogWizard(true);
                }}
              />
            )}
          </Col>
        </Row>
        <ListGroup className="d-block d-md-none mb-3">
          <ListGroup.Item action href="#customer_pickup">
            {t("work_queues_renting_customer_pickup")}
          </ListGroup.Item>
          <ListGroup.Item action href="#customer_return">
            {t("work_queues_renting_customer_return")}
          </ListGroup.Item>
          <ListGroup.Item action href="#transport_delivery">
            {t("work_queues_renting_transport_delivery")}
          </ListGroup.Item>
          <ListGroup.Item action href="#transport_retrieval">
            {t("work_queues_renting_transport_retrieval")}
          </ListGroup.Item>
        </ListGroup>
        <Row>
          <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <WorkQueueRentingCustomerPickup
              id="customer_pickup"
              reservations={reservationsCustomerPickup}
            />
          </Col>
          <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <WorkQueueRentingCustomerReturn
              id="customer_return"
              reservations={reservationsCustomerReturn}
            />
          </Col>
          <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <WorkQueueDriveSend id="transport_delivery" drives={drivesSend} />
          </Col>
          <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
            <WorkQueueDriveRetrieve
              id="transport_retrieval"
              drives={drivesRetrieval}
            />
          </Col>
        </Row>
        {openDialogWizard && reservationIdWizard !== ID_EMPTY && (
          <DialogReservationWizard
            open={openDialogWizard}
            setOpen={setOpenDialogWizard}
            reservation={findFromSetById(
              reservationIdWizard,
              dataReservations.reservationsWorkQueues,
              ReservationEmpty
            )}
          />
        )}
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
    btnSelected: {
      "& .MuiToggleButton-label": {
        color: theme.palette.primary.dark,
      },
    },
  });

export default withStyles(styles)(WorkQueuesRenting);
