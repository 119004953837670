import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogExtraCategoryUnitPriceType,
  CatalogExtraRowUnitPriceType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import SelectRequired from "../Shared/SelectRequired";
import { useQuery } from "@apollo/client";
import {
  GET_PRODUCT_CODES_QUERY,
  QueryResultProductCodes,
} from "../../apollo/queries/product_codes";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_COSTCENTERS_QUERY,
  QueryResultCostcenters,
} from "../../apollo/queries/costcenters";
import {
  GET_COSTTYPES_QUERY,
  QueryResultCosttypes,
} from "../../apollo/queries/costtypes";
import { CostcenterEmpty, CosttypeEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowUnitPrice: CatalogExtraRowUnitPriceType;
  setCatalogExtraRowUnitPrice: React.Dispatch<
    React.SetStateAction<CatalogExtraRowUnitPriceType>
  >;
  catalogExtraCategoriesUnitPrice: CatalogExtraCategoryUnitPriceType[];
}

function DialogContentCatalogExtraRowUnitPrice({
  classes,
  catalogExtraRowUnitPrice,
  setCatalogExtraRowUnitPrice,
  catalogExtraCategoriesUnitPrice,
}: Props) {
  const { t } = useTranslation();
  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowunitprice",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextrarowunitprice",
  ]);
  const disabled = catalogExtraRowUnitPrice.id
    ? !hasPermissionEdit
    : !hasPermissionAdd;

  const {
    loading: loadingCostcenters,
    error: errorCostcenters,
    data: dataCostcenters,
  } = useQuery<QueryResultCostcenters>(GET_COSTCENTERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costcenters"),
  });

  const {
    loading: loadingCosttypes,
    error: errorCosttypes,
    data: dataCosttypes,
  } = useQuery<QueryResultCosttypes>(GET_COSTTYPES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("costtypes"),
  });

  const {
    loading: loadingProductCodes,
    error: errorProductCodes,
    data: dataProductCodes,
  } = useQuery<QueryResultProductCodes>(GET_PRODUCT_CODES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("productCodes"),
  });

  if (loadingProductCodes || loadingCostcenters || loadingCosttypes) {
    return <LoadingSimple />;
  }
  if (errorProductCodes) return <Error error={errorProductCodes} />;
  if (errorCostcenters) return <Error error={errorCostcenters} />;
  if (errorCosttypes) return <Error error={errorCosttypes} />;
  if (!dataProductCodes || !dataCostcenters || !dataCosttypes) {
    return <Error error={t("error_query_failed")} />;
  }

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraRowCategory">
          {t("catalog_extra_category")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCatalogExtraRowCategory"
          value={
            catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice
              ? catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice.id
              : ID_EMPTY
          }
          disabled={disabled}
          onChange={(event) => {
            setCatalogExtraRowUnitPrice({
              ...catalogExtraRowUnitPrice,
              catalogExtraCategoryUnitPrice: {
                ...catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice,
                id: String(event.target.value),
              },
            });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogExtraCategoriesUnitPrice.map(
            (catalogExtraCategoryUnitPrice) => (
              <MenuItem
                key={catalogExtraCategoryUnitPrice.id}
                value={catalogExtraCategoryUnitPrice.id}
              >
                {catalogExtraCategoryUnitPrice.name}
              </MenuItem>
            )
          )}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("name")}
          onChange={(event) => {
            setCatalogExtraRowUnitPrice({
              ...catalogExtraRowUnitPrice,
              name: event.target.value,
            });
          }}
          value={catalogExtraRowUnitPrice.name}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraRowProductCode">
          {t("select_product_number")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCatalogExtraRowProductCode"
          disabled={disabled}
          value={
            catalogExtraRowUnitPrice.productCode
              ? catalogExtraRowUnitPrice.productCode.id
              : ID_EMPTY
          }
          onChange={(event) => {
            setCatalogExtraRowUnitPrice({
              ...catalogExtraRowUnitPrice,
              productCode: {
                ...catalogExtraRowUnitPrice.productCode,
                id: String(event.target.value),
              },
            });
          }}
        >
          {dataProductCodes.productCodes?.map((productCode) => {
            return (
              <MenuItem key={productCode.id} value={productCode.id}>
                {productCode.code} - {productCode.description}
              </MenuItem>
            );
          })}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraRowCostcenter">
          {t("costcenter")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogExtraRowCostcenter"
          disabled={disabled}
          value={
            catalogExtraRowUnitPrice.costcenter
              ? catalogExtraRowUnitPrice.costcenter.id
              : ID_EMPTY
          }
          onChange={(event) => {
            const costcenterNew = catalogExtraRowUnitPrice.costcenter
              ? catalogExtraRowUnitPrice.costcenter
              : CostcenterEmpty;
            setCatalogExtraRowUnitPrice({
              ...catalogExtraRowUnitPrice,
              costcenter: {
                ...costcenterNew,
                id: String(event.target.value),
              },
            });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("costcenter_from_location")}</MenuItem>
          {dataCostcenters.costcenters?.map((costcenter) => {
            return (
              <MenuItem key={costcenter.id} value={costcenter.id}>
                {costcenter.number} - {costcenter.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblCatalogExtraRowCosttype">{t("costtype")}</InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogExtraRowCosttype"
          disabled={disabled}
          value={
            catalogExtraRowUnitPrice.costtype
              ? catalogExtraRowUnitPrice.costtype.id
              : ID_EMPTY
          }
          onChange={(event) => {
            const costtypeNew = catalogExtraRowUnitPrice.costtype
              ? catalogExtraRowUnitPrice.costtype
              : CosttypeEmpty;
            setCatalogExtraRowUnitPrice({
              ...catalogExtraRowUnitPrice,
              costtype: {
                ...costtypeNew,
                id: String(event.target.value),
              },
            });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {dataCosttypes.costtypes?.map((costtype) => {
            return (
              <MenuItem key={costtype.id} value={costtype.id}>
                {costtype.number} - {costtype.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentCatalogExtraRowUnitPrice);
