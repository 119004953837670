import React, { ReactElement } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DriveListType } from "./WorkQueueCardDrive";
import { getReturnCheckedAtColor } from "../../utils/reservations/reservation";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  type: DriveListType;
}

function WorkQueueCardIconsRenting({ classes, reservation, type }: Props) {
  const { t } = useTranslation();

  const getReturnCheckedAtColorClass = () => {
    switch (getReturnCheckedAtColor(reservation)) {
      case "danger":
        return classes.iconOff;
      case "primary":
        return classes.iconOn;
      case "warning":
        return classes.iconSemi;
    }
    return "";
  };

  let buttons: ReactElement[] = [];

  /* 1. Customer collects */
  if (type === "give") {
    buttons.push(
      <div
        key="giveCheckedAt"
        className={`${classes.icon} ${
          reservation.giveCheckedAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_give_checked_at")}
      ></div>
    );
    buttons.push(
      <div
        key="giveImagesAt"
        className={`${classes.icon} ${
          reservation.giveImagesAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_give_images_at")}
      ></div>
    );
    buttons.push(
      <div
        key="giveInstructedAt"
        className={`${classes.icon} ${
          reservation.giveInstructedAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_give_instructed_at")}
      ></div>
    );
    buttons.push(
      <div
        key="giveAt"
        className={`${classes.icon} ${
          reservation.giveAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_give_at")}
      ></div>
    );
  } else {
    /* 2. Customer returns */

    buttons.push(
      <div
        key="returnAt"
        className={`${classes.icon} ${
          reservation.returnAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_return_at")}
      ></div>
    );
    buttons.push(
      <div
        key="returnImagesAt"
        className={`${classes.icon} ${
          reservation.returnImagesAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_return_images_at")}
      ></div>
    );
    buttons.push(
      <div
        key="returnCheckedAt"
        className={`${classes.icon} ${getReturnCheckedAtColorClass()}`}
        title={t("toggle_return_checked_at")}
      ></div>
    );
    buttons.push(
      <div
        key="returnStoredAt"
        className={`${classes.icon} ${
          reservation.returnStoredAt ? classes.iconOn : classes.iconOff
        }`}
        title={t("toggle_return_stored_at")}
      ></div>
    );
  }

  return <div className="d-inline-block">{buttons}</div>;
}

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    icon: {
      backgroundColor: palette.grey.A400,
      width: spacing(2),
      height: spacing(2),
      borderRadius: spacing(1),
      display: "inline-block",
      marginRight: spacing(0.5),
    },
    iconOn: {
      backgroundColor: palette.primary.main,
    },
    iconSemi: {
      backgroundColor: palette.warning.main,
    },
    iconOff: {
      backgroundColor: palette.error.main,
    },
  });

export default withStyles(styles)(WorkQueueCardIconsRenting);
