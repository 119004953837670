import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import LoadingSimple from "./LoadingSimple";
import { ID_EMPTY } from "../../utils/constants";
import { getValuesUsingAllOption } from "../../utils/collections";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  GET_USERS_SIMPLE_QUERY,
  QueryResultUsersSimple,
} from "../../apollo/queries/users";
import { setUserIdSelected } from "../../utils/users/user";

interface Props extends WithStyles<typeof styles> {
  userIdsSelected: string[];
  setUserIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

function UserFilter({ classes, userIdsSelected, setUserIdsSelected }: Props) {
  const { t } = useTranslation();

  const [userIdsSelectedEdited, setCatalogCategoryIdsSelectedEdited] = useState(
    userIdsSelected.length === 0 ? [ID_EMPTY] : userIdsSelected
  );

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery<QueryResultUsersSimple>(GET_USERS_SIMPLE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  if (loadingUsers) return <LoadingSimple />;
  if (errorUsers) return <Error error={errorUsers} />;
  if (!dataUsers) return <Error error={t("error_query_failed")} />;

  return (
    <FormControl fullWidth>
      <InputLabel id="lblUserFilter">{t("users")}</InputLabel>
      <Select
        autoWidth
        labelId="lblUserFilter"
        value={userIdsSelectedEdited}
        multiple={true}
        onChange={(event) => {
          setCatalogCategoryIdsSelectedEdited(
            getValuesUsingAllOption(
              event.target.value as string[],
              userIdsSelectedEdited
            )
          );
        }}
        onClose={() => {
          const hasOnlyAll =
            userIdsSelectedEdited.length === 1 &&
            userIdsSelectedEdited.includes(ID_EMPTY);
          setUserIdsSelected(hasOnlyAll ? [] : userIdsSelectedEdited);

          if (userIdsSelectedEdited.length === 1) {
            setUserIdSelected(userIdsSelectedEdited[0]);
          }
        }}
      >
        <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
        {dataUsers.users?.map((user) => {
          return (
            <MenuItem key={user.id} value={user.id}>
              {user.firstName + " " + user.lastName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(UserFilter);
