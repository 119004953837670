import { gql } from "@apollo/client";
import { Query } from "../../entity/types";
import { FRAG_RESERVATION_FIELDS_FOR_TABLE } from "./reservations";
import {
  GetCustomerForCalendarQuery,
  GetCustomersCalendarQuery,
} from "./customers.generated";

const FRAG_CUSTOMER_SHARED = gql`
  fragment CustomerFields on CustomerType {
    id
    customerNumber
    name
    contactPersonFirstname
    contactPersonLastname
    visitAddress
    visitPostcode
    visitDistrict
    customerIndustries {
      id
      title
    }
    contactNext
    isBusiness
    creditInformationOk
    creditInformationCheckedAt
    creditRiskClass
  }
`;

export const FRAG_CUSTOMER_FULL = gql`
  fragment CustomerFullFields on CustomerType {
    id
    customerNumber
    businessId
    name
    contactPersonFirstname
    contactPersonLastname
    email
    phone
    visitAddress
    visitPostcode
    visitDistrict
    invoicingAddress
    invoicingDistrict
    invoicingEmail
    invoicingPostcode
    invoicingEAddress
    invoicingEOperator
    customerIndustries {
      id
      title
    }
    customerRank {
      id
    }
    customerAreas {
      id
    }
    contactNext
    isBusiness
    creditInformationOk
    creditInformationCheckedAt
    creditRiskClass
    blockedAt
    blockedReason
    preInvoicingAt
    preInvoicingReason
    hasInsurance
    useParentInvoicing
    useParentPricing
    handledInvoicingAt
    discountGlobalCatalogRow
    discountGlobalCatalogExtra
    paymentTerm
    noBillingSurchargeAt
    userOwner {
      id
    }
    customerParent {
      id
    }
    discountListSelected {
      id
    }
    catalogSelected {
      id
    }
    createdBy {
      id
    }
    customersDiscountLists {
      id
    }
    customersChildren {
      id
    }
    referenceSet {
      id
    }
    orderSet {
      id
    }
    createdAt
  }
`;

export const FRAG_CUSTOMER_CALENDAR_ORDER = gql`
  fragment CustomerCalendarOrderFields on CustomerType {
    id
    name
    contactPersonFirstname
    contactPersonLastname
    businessId
    email
    isBusiness
    blockedAt
    creditInformationOk
    preInvoicingAt
    discountListSelected {
      id
      name
    }
    catalogSelected {
      id
      name
    }
    customerParent {
      id
      blockedAt
      creditInformationOk
      preInvoicingAt
    }
  }
`;

export interface QueryResultCustomersForList {
  customers: Query["customers"];
}
export const GET_CUSTOMERS_FOR_LIST_QUERY = gql`
  query getCustomersForList(
    $userOwnerIds: [ID]
    $isBusiness: [String]
    $customerIndustryIds: [ID]
    $customerAreaIds: [ID]
    $customerRankIds: [ID]
  ) {
    customers(
      userOwnerIds: $userOwnerIds
      isBusiness: $isBusiness
      customerIndustryIds: $customerIndustryIds
      customerAreaIds: $customerAreaIds
      customerRankIds: $customerRankIds
    ) {
      id
      customerNumber
      name
      contactPersonFirstname
      contactPersonLastname
      visitAddress
      visitPostcode
      visitDistrict
      contactNext
      creditInformationOk
      creditInformationCheckedAt
      creditRiskClass
      customerAreas {
        id
        title
      }
      customerRank {
        id
        title
        order
      }
      userOwner {
        id
        firstName
        lastName
      }
      customerParent {
        id
        creditInformationOk
        creditInformationCheckedAt
        creditRiskClass
      }
      customersChildren {
        id
      }
    }
  }
`;

export interface QueryResultCustomer {
  customer: Query["customer"];
}
export const GET_CUSTOMER_QUERY = gql`
  ${FRAG_CUSTOMER_SHARED}
  ${FRAG_RESERVATION_FIELDS_FOR_TABLE}
  query getCustomer($id: ID!) {
    customer(id: $id) {
      ...CustomerFields
      businessId
      email
      phone
      invoicingAddress
      invoicingDistrict
      invoicingEmail
      invoicingPostcode
      invoicingEAddress
      invoicingEOperator
      blockedAt
      blockedReason
      preInvoicingAt
      preInvoicingReason
      hasInsurance
      useParentInvoicing
      useParentPricing
      discountGlobalCatalogRow
      discountGlobalCatalogExtra
      paymentTerm
      noBillingSurchargeAt
      userOwner {
        id
        firstName
        lastName
      }
      customerAreas {
        id
      }
      customerRank {
        id
      }
      discountListSelected {
        id
        name
        locked
        isOffer
        isBusiness
        discountrowSet {
          id
          percent
          showPrint
          catalogRow {
            id
            catalogCategory {
              id
              catalogCategoryUpper {
                id
                catalog {
                  id
                }
              }
            }
          }
        }
        catalog {
          id
          catalogcategoryupperSet {
            id
            name
            catalogcategorySet {
              id
              name
              catalogrowSet {
                id
                name
                priceDayCompany
                priceMonthCompany
                priceDayPrivate
                priceMonthPrivate
              }
            }
          }
        }
      }
      catalogSelected {
        id
        name
      }
      customerParent {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        discountGlobalCatalogRow
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
        customersChildren {
          id
          name
          contactPersonLastname
          contactPersonFirstname
          userOwner {
            id
            lastName
            firstName
          }
          contactNext
        }
        useParentInvoicing
        catalogSelected {
          id
        }
        discountListSelected {
          id
        }
      }
      customersChildren {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
      }
      orderSet {
        id
        reference {
          id
          name
        }
        status
        reservationSet {
          ...ReservationFieldsForTable
        }
        createdAt
      }
      createdAt
      createdBy {
        id
        username
      }
    }
  }
`;

export type CustomerForCalendarType = GetCustomerForCalendarQuery["customer"];
export type CatalogSelectedFromCustomerForCalendarType =
  CustomerForCalendarType["catalogSelected"];
export type DiscountListSelectedFromCustomerForCalendarType =
  CustomerForCalendarType["discountListSelected"];
export const GET_CUSTOMER_FOR_CALENDAR = gql`
  ${FRAG_RESERVATION_FIELDS_FOR_TABLE}
  query getCustomerForCalendar($id: ID!) {
    customer(id: $id) {
      id
      customerNumber
      name
      contactPersonFirstname
      contactPersonLastname
      visitAddress
      visitPostcode
      visitDistrict
      customerIndustries {
        id
        title
      }
      contactNext
      isBusiness
      creditInformationOk
      creditInformationCheckedAt
      creditRiskClass
      businessId
      email
      phone
      invoicingAddress
      invoicingDistrict
      invoicingEmail
      invoicingPostcode
      invoicingEAddress
      invoicingEOperator
      blockedAt
      blockedReason
      preInvoicingAt
      preInvoicingReason
      hasInsurance
      useParentInvoicing
      useParentPricing
      discountGlobalCatalogRow
      discountGlobalCatalogExtra
      paymentTerm
      noBillingSurchargeAt
      userOwner {
        id
        firstName
        lastName
      }
      customerAreas {
        id
      }
      customerRank {
        id
      }
      discountListSelected {
        id
        name
        locked
        isOffer
        isBusiness
        discountrowSet {
          id
          percent
          showPrint
          catalogRow {
            id
            catalogCategory {
              id
              catalogCategoryUpper {
                id
                catalog {
                  id
                }
              }
            }
          }
        }
        catalog {
          id
          catalogcategoryupperSet {
            id
            name
            catalogcategorySet {
              id
              name
              catalogrowSet {
                id
                name
                priceDayCompany
                priceMonthCompany
                priceDayPrivate
                priceMonthPrivate
              }
            }
          }
        }
      }
      catalogSelected {
        id
        name
      }
      customerParent {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        discountGlobalCatalogRow
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
        customersChildren {
          id
          name
          contactPersonLastname
          contactPersonFirstname
          userOwner {
            id
            lastName
            firstName
          }
          contactNext
        }
        useParentInvoicing
        catalogSelected {
          id
        }
        discountListSelected {
          id
        }
      }
      customersChildren {
        id
        name
        contactPersonLastname
        contactPersonFirstname
        userOwner {
          id
          lastName
          firstName
        }
        contactNext
      }
      orderSet {
        id
        reference {
          id
          name
        }
        status
        reservationSet {
          ...ReservationFieldsForTable
        }
        createdAt
      }
      createdAt
      createdBy {
        id
        username
      }
    }
  }
`;

export interface QueryResultCustomerPriceForCatalogRow {
  customerPriceForCatalogRow: Query["customerPriceForCatalogRow"];
}
export const GET_CUSTOMER_PRICE_FOR_CATALOG_ROW_QUERY = gql`
  query getCustomerPriceForCatalogRow($customerId: ID!, $catalogRowId: ID!) {
    customerPriceForCatalogRow(
      customerId: $customerId
      catalogRowId: $catalogRowId
    ) {
      priceDay
      priceMonth
      discountPercent
    }
  }
`;

export interface QueryResultCustomersPrimary {
  customersPrimary: Query["customersPrimary"];
}
export const GET_CUSTOMERS_PRIMARY_QUERY = gql`
  query getCustomersPrimary {
    customersPrimary {
      id
      businessId
      name
    }
  }
`;

export type CustomerFromCustomersCalendarType =
  GetCustomersCalendarQuery["customers"][0];
export const GET_CUSTOMERS_CALENDAR_QUERY = gql`
  query getCustomersCalendar {
    customers {
      id
      name
      contactPersonFirstname
      contactPersonLastname
      blockedAt
      creditInformationOk
      preInvoicingAt
      customerParent {
        id
        blockedAt
        creditInformationOk
        preInvoicingAt
      }
    }
  }
`;

export interface QueryResultCustomersOpenReservations {
  customersOpenReservations: Query["customersOpenReservations"];
}
export const GET_CUSTOMERS_OPEN_RESERVATIONS_QUERY = gql`
  query getCustomersOpenReservations(
    $locationIds: [ID]!
    $onlyEndedReservations: Boolean!
    $onlyPreinvoicingCustomers: Boolean!
    $dateHandled: Date
  ) {
    customersOpenReservations(
      locationIds: $locationIds
      onlyEndedReservations: $onlyEndedReservations
      onlyPreinvoicingCustomers: $onlyPreinvoicingCustomers
      dateHandled: $dateHandled
    ) {
      id
      name
      contactPersonFirstname
      contactPersonLastname
      handledInvoicingAt
    }
  }
`;

export interface QueryResultCustomerRanks {
  customerRanks: Query["customerRanks"];
}
export const GET_CUSTOMER_RANKS_QUERY = gql`
  query getCustomerRanks {
    customerRanks {
      id
      title
      order
    }
  }
`;

export interface QueryResultCustomerAreas {
  customerAreas: Query["customerAreas"];
}
export const GET_CUSTOMER_AREAS_QUERY = gql`
  query getCustomerAreas {
    customerAreas {
      id
      title
    }
  }
`;

export interface QueryResultCustomers {
  customers: Query["customers"];
}
export const GET_CUSTOMERS_QUERY = gql`
  query getCustomers {
    customers {
      id
      name
      contactPersonFirstname
      contactPersonLastname
    }
  }
`;

export interface QueryResultCustomerIndustries {
  customerIndustries: Query["customerIndustries"];
}
export const GET_CUSTOMER_INDUSTRIES_QUERY = gql`
  query getCustomerIndustries {
    customerIndustries {
      id
      title
    }
  }
`;
