export const DATE_FORMAT_ISO = "yyyy-MM-dd";
export const TIME_FORMAT_ISO = "HH:mm";
export const DATETIME_FORMAT_ISO = "yyyy-MM-dd HH:mm";

export const COLOR_PRIMARY = "#3BD42C";
export const COLOR_MUTED = "#AAAAAA";
export const COLOR_MUTED_LIGHT = "#DDDDDD";

export const INT_UNSIGNED_VALUE_MAX = 4294967295;

export const PAYMENT_TERM_DEFAULT = 14;

export enum WORK_QUEUE_MODES {
  ALL = "0",
  OWN = "1",
}
export enum CALENDAR_INSERT_MODES {
  RESERVATION = "0",
  BREAKDOWN = "1",
}
export const LOG_TYPES = [
  "Catalog",
  "CatalogCategory",
  "CatalogCategoryUpper",
  "CatalogExtraCategoryRental",
  "CatalogExtraCategoryUnitPrice",
  "CatalogExtraRowRental",
  "CatalogExtraRowUnitPrice",
  "CatalogRow",
  "Customer",
  "DiscountList",
  "DiscountRow",
  "Event",
  "EventOfferCatalog",
  "Group",
  "Holiday",
  "Invoice",
  "Location",
  "Log",
  "Machine",
  "MachineBreakdown",
  "MachineBreakdownComment",
  "MachineInfoAnswer",
  "MachineInfoCategory",
  "MachineInfoQuestion",
  "MachineInfoQuestionOption",
  "MachineInfoTag",
  "Order",
  "Permission",
  "Reference",
  "Reservation",
  "ReservationComment",
  "User",
];
export const ID_EMPTY = "0";
export const MAINTENANCE_CONTRACTOR_ID = "-1";
export const CUSTOMER_ID_CATALOG_PRIVATE = "-1";
export const CUSTOMER_ID_CATALOG_COMPANY = "-2";

export const CONTAINER_MAIN_WIDTH_XL = "2000px";
export const CONTAINER_MAIN_WIDTH_L = "1600px";
export const CONTAINER_MAIN_WIDTH_S = "800px";

export const ROOT_QUERY = "ROOT_QUERY";
