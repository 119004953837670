import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, TextField, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogRowType,
  DiscountListType,
  DiscountRowType,
} from "../../entity/types";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { discountRowPercentsType } from "./DialogCatalogRow";
import {
  getPercentPrefixDB,
  getPercentPrefixUserInput,
} from "../../utils/discounts/prefixes";
import {
  getPercentDifference,
  getPercentFactorDiscount,
} from "../../utils/calc";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  discountList: DiscountListType;
  discountRow: DiscountRowType;
  discountRowPercents: discountRowPercentsType;
  setDiscountRowPercents: React.Dispatch<
    React.SetStateAction<discountRowPercentsType>
  >;
  catalogRow: CatalogRowType;
}

function DiscountRowPercent({
  classes,
  discountList,
  discountRow,
  discountRowPercents,
  setDiscountRowPercents,
  catalogRow,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditDiscountRow = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  const percentFactor = getPercentFactorDiscount(
    typeof discountRowPercents[discountRow.id] !== "undefined"
      ? discountRowPercents[discountRow.id]["percent"]
      : "0"
  );

  const [priceDayCompany, setPriceDayCompany] = useState<string>(
    formatNumber(catalogRow.priceDayCompany * percentFactor, 2)
  );
  const [priceMonthCompany, setPriceMonthCompany] = useState<string>(
    formatNumber(catalogRow.priceMonthCompany * percentFactor, 2)
  );
  const [priceDayPrivate, setPriceDayPrivate] = useState<string>(
    formatNumber(catalogRow.priceDayPrivate * percentFactor, 2)
  );
  const [priceMonthPrivate, setPriceMonthPrivate] = useState<string>(
    formatNumber(catalogRow.priceMonthPrivate * percentFactor, 2)
  );

  const disabled = Boolean(
    discountList.locked ||
      typeof discountRowPercents[discountRow.id] === "undefined" ||
      !hasPermissionEditDiscountRow
  );

  const calculateNewPrices = (percent: string, changedField = "") => {
    const percentFactorNew = getPercentFactorDiscount(percent);

    if (changedField !== "priceDayCompany") {
      setPriceDayCompany(
        formatNumber(catalogRow.priceDayCompany * percentFactorNew, 2)
      );
    }
    if (changedField !== "priceMonthCompany") {
      setPriceMonthCompany(
        formatNumber(catalogRow.priceMonthCompany * percentFactorNew, 2)
      );
    }

    if (changedField !== "priceDayPrivate") {
      setPriceDayPrivate(
        formatNumber(catalogRow.priceDayPrivate * percentFactorNew, 2)
      );
    }
    if (changedField !== "priceMonthPrivate") {
      setPriceMonthPrivate(
        formatNumber(catalogRow.priceMonthPrivate * percentFactorNew, 2)
      );
    }
  };

  const calculateNewPercent = (
    valueOld: number,
    valueNew: number,
    changedField = ""
  ) => {
    let percentNew = getPercentDifference(valueOld, valueNew);
    let percentFormatted = formatNumber(percentNew, 2);
    let prefix = getPercentPrefixDB(String(percentNew));

    handlePercentChange(String(percentNew), false);
    calculateNewPrices(prefix + percentFormatted, changedField);
  };

  function handlePercentChange(value: string, isUserInput: boolean) {
    let valueFormatted = value === "" ? "" : formatNumber(value, 2);

    let discountRowPercentsNew = { ...discountRowPercents };
    discountRowPercentsNew[discountRow.id]["percent"] =
      (isUserInput
        ? getPercentPrefixUserInput(value)
        : getPercentPrefixDB(value)) + valueFormatted;
    setDiscountRowPercents(discountRowPercentsNew);
  }

  return (
    <div className={classes.accordionDiscountList}>
      <b>{discountList.name}</b>
      <span className="ms-2">
        {discountList.customer
          ? discountList.customer.name
          : t("discount_list")}
      </span>
      <div className="d-flex flex-row">
        <div className="me-3 text-end" title={t("company")}>
          <div className={classes.valSm}>
            {formatNumber(catalogRow.priceDayCompany, 2)}
          </div>
          <div className={classes.valSm}>
            {formatNumber(catalogRow.priceMonthCompany, 2)}
          </div>
        </div>
        <div className="me-4 text-end" title={t("private")}>
          <div className={classes.valSm}>
            {formatNumber(catalogRow.priceDayPrivate, 2)}
          </div>
          <div className={classes.valSm}>
            {formatNumber(catalogRow.priceMonthPrivate, 2)}
          </div>
        </div>
        <div>
          <TextField
            className={classes.txt}
            value={
              typeof discountRowPercents[discountRow.id] !== "undefined"
                ? discountRowPercents[discountRow.id]["percent"]
                : ""
            }
            disabled={disabled}
            onChange={(event) => {
              let discountRowPercentsNew = { ...discountRowPercents };
              discountRowPercentsNew[discountRow.id]["percent"] =
                event.target.value;
              setDiscountRowPercents(discountRowPercentsNew);
            }}
            onBlur={(event) => {
              handlePercentChange(event.target.value, true);
              calculateNewPrices(event.target.value);
            }}
          />
        </div>
        <div className="pt-3">%</div>
        <div className="ms-4" title={t("company")}>
          <div>
            <TextField
              size="small"
              className={classes.txtSm}
              value={priceDayCompany}
              disabled={disabled}
              onChange={(event) => {
                setPriceDayCompany(event.target.value);
                calculateNewPercent(
                  parseNumber(catalogRow.priceDayCompany),
                  parseNumber(event.target.value),
                  "priceDayCompany"
                );
              }}
              onBlur={(event) => {
                setPriceDayCompany(formatNumber(priceDayCompany, 2));
                calculateNewPercent(
                  parseNumber(catalogRow.priceDayCompany),
                  parseNumber(event.target.value),
                  "priceDayCompany"
                );
              }}
            />
          </div>
          <div>
            <TextField
              size="small"
              className={classes.txtSm}
              value={priceMonthCompany}
              disabled={disabled}
              onChange={(event) => {
                setPriceMonthCompany(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="ms-4" title={t("private")}>
          <div>
            <TextField
              size="small"
              className={classes.txtSm}
              value={priceDayPrivate}
              disabled={disabled}
              onChange={(event) => {
                setPriceDayPrivate(event.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              size="small"
              className={classes.txtSm}
              value={priceMonthPrivate}
              disabled={disabled}
              onChange={(event) => {
                setPriceMonthPrivate(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    accordionDiscountList: {
      marginBottom: spacing(3),
    },
    txt: {
      width: "75px",
      marginTop: spacing(1.5),
    },
    txtSm: {
      width: "75px",
    },
    valSm: {
      padding: "4px 0",
    },
  });

export default withStyles(styles)(DiscountRowPercent);
