import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, TextField, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  MaintenanceMaintenanceImageImageTypeChoices,
  MaintenanceMaintenanceIntervalIntervalTypeChoices,
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  MaintenanceSettingType,
  MaintenanceType,
  Mutation,
  MutationCreateMaintenanceCommentArgs,
  NextMaintenanceType,
} from "../../entity/types";
import format from "date-fns/format";
import { newDate } from "../../utils/dates";
import { formMaintenanceTimeRange } from "../../utils/maintenances/maintenance";
import { checkPermission } from "../../utils/permissions";
import { PermissionsContext } from "../../Root";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { Button } from "react-bootstrap";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import DialogMaintenanceImages from "./DialogMaintenanceImages";
import { MaintenanceTypeEmpty } from "../../entity/empties";
import ButtonLoad from "../Shared/ButtonLoad";
import { useMutation } from "@apollo/client";
import { CREATE_MAINTENANCE_COMMENT } from "../../apollo/mutations/maintenances";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  maintenanceSettings: MaintenanceSettingType;
  maintenances: MaintenanceType[];
  nextMaintenance: NextMaintenanceType;
  breakdowns: MachineBreakdownType[];
}

function MaintenanceHistory({
  classes,
  maintenanceSettings,
  maintenances,
  nextMaintenance,
  breakdowns,
}: Props) {
  const { t } = useTranslation();

  const [comment, setComment] = useState("");
  const [openAddDocument, setOpenAddDocument] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] =
    useState(MaintenanceTypeEmpty);

  const [createMaintenanceComment, { loading: loadingCreateComment }] =
    useMutation<Mutation, MutationCreateMaintenanceCommentArgs>(
      CREATE_MAINTENANCE_COMMENT,
      {
        onCompleted: () => {
          setComment("");
        },
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("maintenanceHistoryForMachine"),
          });
        },
      }
    );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddComment = checkPermission(myPermissions, [
    "maintenance.add_maintenancecomment",
  ]);
  const hasPermissionAddDocument = checkPermission(myPermissions, [
    "maintenance.add_maintenanceimage",
  ]);

  const getMaintenanceTypeInfo = (maintenance: MaintenanceType) => {
    if (
      maintenanceSettings.scheduleType ===
      MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays
    ) {
      return t("machine_maintenance_type_2", {
        hours: maintenance.maintenanceInterval.hours,
        days: maintenance.maintenanceInterval.days,
        timeRangeHours: formMaintenanceTimeRange(
          t,
          maintenance.maintenanceInterval.intervalType,
          MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
        ),
        timeRangeDays: formMaintenanceTimeRange(
          t,
          maintenance.maintenanceInterval.intervalType,
          MaintenanceMaintenanceSettingScheduleTypeChoices.Days
        ),
      });
    } else {
      return t("machine_maintenance_type", {
        hours_days:
          maintenanceSettings.scheduleType ===
          MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
            ? maintenance.maintenanceInterval.hours
            : maintenance.maintenanceInterval.days,
        timeRange: formMaintenanceTimeRange(
          t,
          maintenance.maintenanceInterval.intervalType,
          maintenanceSettings.scheduleType
            ? maintenanceSettings.scheduleType
            : MaintenanceMaintenanceSettingScheduleTypeChoices.None
        ),
      });
    }
  };

  const printNextMaintenance = () => {
    if (
      maintenanceSettings.scheduleType ===
      MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays
    ) {
      return t("next_maintenance_estimate_2", {
        hours: nextMaintenance.hours,
        days: nextMaintenance.days,
        timeRangeHours: formMaintenanceTimeRange(
          t,
          nextMaintenance.intervalType as MaintenanceMaintenanceIntervalIntervalTypeChoices,
          MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
        ),
        timeRangeDays: formMaintenanceTimeRange(
          t,
          nextMaintenance.intervalType as MaintenanceMaintenanceIntervalIntervalTypeChoices,
          MaintenanceMaintenanceSettingScheduleTypeChoices.Days
        ),
        estimateDate: nextMaintenance.nextMaintenanceDate
          ? format(
              newDate(nextMaintenance.nextMaintenanceDate),
              t("format_date")
            )
          : "",
      });
    } else {
      return t("next_maintenance_estimate", {
        hoursOrDays:
          maintenanceSettings.scheduleType ===
          MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
            ? nextMaintenance.hours
            : nextMaintenance.days,
        timeRange: formMaintenanceTimeRange(
          t,
          nextMaintenance.intervalType as MaintenanceMaintenanceIntervalIntervalTypeChoices,
          maintenanceSettings.scheduleType
        ),
        estimateDate: nextMaintenance.nextMaintenanceDate
          ? format(
              newDate(nextMaintenance.nextMaintenanceDate),
              t("format_date")
            )
          : "",
      });
    }
  };

  const handleOpenAddDocument = (maintenance: MaintenanceType) => {
    setOpenAddDocument(true);
    setSelectedMaintenance(maintenance);
  };

  const handleCloseAddDocument = () => {
    setOpenAddDocument(false);
    setSelectedMaintenance(MaintenanceTypeEmpty);
  };

  const sendNewComment = () => {
    if (comment.length) {
      createMaintenanceComment({
        variables: {
          maintenanceId: selectedMaintenance.id,
          comment: comment,
        },
      });
    }
  };

  const handleCommentFocus = (maintenance: MaintenanceType) => {
    setComment("");
    setSelectedMaintenance(maintenance);
  };

  return (
    <>
      {nextMaintenance.nextMaintenanceDate && (
        <p className="mb-2">{printNextMaintenance()}</p>
      )}
      <p className="mb-2">{t("maintenance_history")}:</p>
      {maintenances &&
        !maintenances.length &&
        maintenanceSettings &&
        maintenanceSettings.startDate?.length && (
          <div className={"alert " + classes.alertLight}>
            {t("maintenance_start_date")}:{" "}
            {format(newDate(maintenanceSettings.startDate), t("format_date"))}
          </div>
        )}
      {maintenances &&
        maintenances.map((maintenance) => {
          return (
            <div
              className={"alert " + classes.alertLight}
              key={maintenance.maintenanceInterval.id}
            >
              <div className="row">
                <div className="col-sm-12">
                  <p>
                    <b>{t("service_type")}: </b>
                    {getMaintenanceTypeInfo(maintenance)}
                  </p>
                  <p>
                    <b>{t("operation_hours")}: </b>
                    {maintenance.operationhour?.hours
                      ? t("x_hours_abbr", {
                          hours: maintenance.operationhour.hours,
                        })
                      : ""}
                  </p>
                  <p>
                    <b>{t("service_date")}: </b>
                    {format(
                      newDate(maintenance.serviceEndBy),
                      t("format_date")
                    )}
                  </p>
                  <p>
                    <b>{t("service_registered_by")}: </b>
                    {(maintenance.verifiedBy?.firstName
                      ? maintenance.verifiedBy.firstName + " "
                      : "") +
                      (maintenance.verifiedBy?.lastName
                        ? maintenance.verifiedBy.lastName
                        : "")}
                  </p>
                  <p>
                    <b>{t("service_done_by")}: </b>
                    {maintenance.serviceContractor.length
                      ? maintenance.serviceContractor
                      : (maintenance.serviceTechnician?.firstName
                          ? maintenance.serviceTechnician.firstName + " "
                          : "") +
                        (maintenance.serviceTechnician?.lastName
                          ? maintenance.serviceTechnician.lastName
                          : "")}
                  </p>
                </div>
              </div>
              <div className="row">
                {maintenance.maintenancecommentSet && (
                  <div className="col-sm-12 col-md-7 mt-3">
                    <p className="fw-bold">{t("comments")}</p>
                    <div>
                      {maintenance.maintenancecommentSet.map((comment) => {
                        return (
                          <div key={comment.id}>
                            <div className="mt-2">
                              {comment.createdBy && (
                                <span className="user me-2">
                                  {comment.createdBy.lastName +
                                    " " +
                                    comment.createdBy.firstName}
                                </span>
                              )}
                              <span className="text-muted">
                                {format(
                                  newDate(comment.createdAt),
                                  t("format_datetime")
                                )}
                              </span>
                            </div>
                            {comment.comment}
                          </div>
                        );
                      })}
                    </div>
                    {hasPermissionAddComment && (
                      <div className="cardWorkQueueComment new mt-4">
                        <TextField
                          size="small"
                          label={t("new_maintenance_comment")}
                          fullWidth
                          value={
                            maintenance.id === selectedMaintenance.id
                              ? comment
                              : ""
                          }
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <ButtonLoad
                                loading={loadingCreateComment}
                                variant="light"
                                onClick={sendNewComment}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </ButtonLoad>
                            ),
                          }}
                          inputProps={{
                            maxLength: 200,
                          }}
                          onChange={(event) =>
                            setComment(event.currentTarget.value)
                          }
                          onKeyUp={(event) => {
                            if (event.key === "Enter") {
                              sendNewComment();
                            }
                          }}
                          onFocus={(event) => handleCommentFocus(maintenance)}
                        />
                      </div>
                    )}
                  </div>
                )}
                {maintenance.album.maintenanceimageSet && (
                  <div className="col-sm-12 col-md-5 mt-3">
                    <div className="row">
                      <div className="col-8">
                        <p className="fw-bold">{t("attachments")}</p>
                      </div>
                      <div className="col-4">
                        {hasPermissionAddDocument && (
                          <Button
                            onClick={() => handleOpenAddDocument(maintenance)}
                            className="btnRoundVerySmall fa-pull-right"
                            variant="primary"
                            size="sm"
                          >
                            {openAddDocument &&
                            maintenance === selectedMaintenance ? (
                              <ClearIcon fontSize="small" />
                            ) : (
                              <AddIcon fontSize="small" />
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                    {maintenance.album.maintenanceimageSet.map((image) => {
                      return (
                        <div key={image.id} className="mb-3">
                          <a
                            href={image.image}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {image.imageName}
                          </a>
                          {image.imageType ===
                            MaintenanceMaintenanceImageImageTypeChoices.Invoice && (
                            <>
                              <p className="mt-1 mb-1">
                                {image.eurosWarranty} {t("euros_warranty")}
                              </p>
                              <p className="mb-1">
                                {image.eurosOwnCosts} {t("euros_own_costs")}
                              </p>
                            </>
                          )}
                          {image.information && (
                            <p className="mb-0">{image.information}</p>
                          )}
                        </div>
                      );
                    })}

                    {openAddDocument &&
                      selectedMaintenance.id === maintenance.id && (
                        <DialogMaintenanceImages
                          maintenance={maintenance}
                          open={openAddDocument}
                          setOpen={handleCloseAddDocument}
                        />
                      )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      {breakdowns &&
        breakdowns.map((breakdown) => {
          return (
            <div className={"alert " + classes.alertLight} key={breakdown.id}>
              <div className="row">
                <div className="col-sm-12">
                  {breakdown.title +
                    " - " +
                    breakdown.information +
                    " / " +
                    (breakdown.fixedAt
                      ? format(newDate(breakdown.fixedAt), t("format_date"))
                      : t("no")) +
                    " " +
                    breakdown.fixedBy?.firstName +
                    " " +
                    breakdown.fixedBy?.lastName}
                </div>
              </div>
              <div className="row">
                {breakdown.machinebreakdowncommentSet && (
                  <div className="col-sm-12 col-md-7 mt-3">
                    <h5>{t("comments")}</h5>
                    <div>
                      {breakdown.machinebreakdowncommentSet.map((comment) => {
                        return (
                          <div key={comment.id}>
                            <div className="mt-2">
                              {comment.createdBy && (
                                <span className="user me-2">
                                  {comment.createdBy.lastName +
                                    " " +
                                    comment.createdBy.firstName}
                                </span>
                              )}
                              <span className="text-muted">
                                {format(
                                  newDate(comment.createdAt),
                                  t("format_datetime")
                                )}
                              </span>
                            </div>
                            {comment.comment}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
    tabStyle: {
      width: "50%",
      minWidth: "50%",
      padding: "0",
      margin: "0",
    },
    alertLight: {
      backgroundColor: "#f4f4f4",
    },
  });

export default withStyles(styles)(MaintenanceHistory);
