import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineType,
  MutationUploadFileMachineTypePlateArgs,
} from "../../../entity/types";
import { useMutation } from "@apollo/client";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { TabPanel } from "@material-ui/lab";
import { handleError } from "../../../entity/ErrorHandler";
import {
  MutationResultUploadFileMachineTypePlate,
  UPLOAD_FILE_MACHINE_TYPE_PLATE_MUTATION,
} from "../../../apollo/mutations/machines";
import ButtonLoad from "../../Shared/ButtonLoad";
import { Image } from "react-bootstrap";
import { ProductCardTabs } from "./DialogMachineProductCard";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCardTypePlate({ classes, machine }: Props) {
  const { t } = useTranslation();

  const [uploadFileTypePlate, { loading }] = useMutation<
    MutationResultUploadFileMachineTypePlate,
    MutationUploadFileMachineTypePlateArgs
  >(UPLOAD_FILE_MACHINE_TYPE_PLATE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);

  const hasPermissionViewMachines = checkPermission(myPermissions, [
    "machines.view_machine",
  ]);
  const hasPermissionEditMachines = checkPermission(myPermissions, [
    "machines.change_machine",
  ]);

  if (!hasPermissionViewMachines) {
    return null;
  }

  const onChangeFileTypePlate = ({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && validity.valid) {
      let file = files[0];
      uploadFileTypePlate({
        variables: {
          machineId: machine.id,
          fileTypePlate: file,
        },
      });
    }
  };

  const onDeleteFileTypePlate = () => {
    uploadFileTypePlate({
      variables: {
        machineId: machine.id,
        fileTypePlate: null,
      },
    });
  };

  return (
    <TabPanel
      value={ProductCardTabs.TypePlate}
      className={loading ? "loading" : ""}
    >
      {machine.fileTypePlate && (
        <div className="mb-3">
          <a href={machine.fileTypePlate} target="_blank" rel="noreferrer">
            <Image
              src={machine.fileTypePlate}
              alt={t("type_plate")}
              thumbnail
              fluid
            />
          </a>
        </div>
      )}
      <input
        id="fileMachineTypePlate"
        type="file"
        onChange={onChangeFileTypePlate}
        accept="image/*"
        disabled={!hasPermissionEditMachines}
      />
      {machine.fileTypePlate && (
        <ButtonLoad
          className="ms-3"
          variant="light"
          loading={false}
          onClick={onDeleteFileTypePlate}
          disabled={!hasPermissionEditMachines}
        >
          {t("delete")}
        </ButtonLoad>
      )}
    </TabPanel>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TabMachineCardTypePlate);
