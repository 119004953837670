import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Alert, Col, Container, Row } from "react-bootstrap";
import DiscountList from "../components/Discount/DiscountList";
import { CatalogEmpty } from "../entity/empties";
import CatalogSelect from "../components/Machine/CatalogSelect";
import {
  GET_CATALOGS_QUERY,
  QueryResultCatalogs,
} from "../apollo/queries/catalogs";
import { useTranslation } from "react-i18next";
import { CatalogType } from "../entity/types";
import { parseNumber } from "../utils/formatting";
import { getCatalogIdSelected } from "../utils/catalogs/selected_catalog";
import { ID_EMPTY } from "../utils/constants";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import { SettingsContext } from "../Root";

interface Props extends WithStyles<typeof styles> {}

function SettingsDiscounts({ classes }: Props) {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);
  const [catalogId, setCatalogId] = useState(getCatalogIdSelected(settings));

  const { loading, error, data } = useQuery<QueryResultCatalogs>(
    GET_CATALOGS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogs"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  if (!data.catalogs || data.catalogs.length === 0) {
    return (
      <Container className="containerMain">
        <Alert variant="danger">{t("error_add_catalog_first")}</Alert>
      </Container>
    );
  }

  let catalog = CatalogEmpty;

  if (catalogId !== ID_EMPTY) {
    (data.catalogs ? data.catalogs : []).forEach(function (
      catalogLooped: CatalogType
    ) {
      if (parseNumber(catalogId) === parseNumber(catalogLooped.id)) {
        catalog = catalogLooped;
      }
    });
  }

  return (
    <Container className="containerMain">
      <Row>
        <Col>
          <h2 className="headerPage">{t("discounts")}</h2>
        </Col>
        <Col>
          <CatalogSelect
            catalogs={data.catalogs ? data.catalogs : []}
            catalogId={catalogId}
            setCatalogId={setCatalogId}
          />
        </Col>
      </Row>
      {catalog.id !== ID_EMPTY && <DiscountList catalog={catalog} />}
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsDiscounts);
