import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachinesMachineInfoQuestionAnswerTypeChoices,
  MachineInfoQuestionType,
  Mutation,
  MutationCreateMachineInfoQuestionOptionArgs,
} from "../../entity/types";
import MachineInformationQuestionOption from "./MachineInformationQuestionOption";
import { Button } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_MACHINE_INFO_QUESTION_OPTION_MUTATION } from "../../apollo/mutations/machines";
import { handleError } from "../../entity/ErrorHandler";
import { GET_MACHINE_INFO_CATEGORIES_QUERY } from "../../apollo/queries/machines";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  machineInfoQuestion: MachineInfoQuestionType;
}

function MachineInformationQuestionOptions({
  classes,
  machineInfoQuestion,
}: Props) {
  const { t } = useTranslation();

  const [showOptions, setShowOptions] = useState(false);

  const [createMachineInfoQuestionOption, { loading: loadingCreateOption }] =
    useMutation<Mutation, MutationCreateMachineInfoQuestionOptionArgs>(
      CREATE_MACHINE_INFO_QUESTION_OPTION_MUTATION,
      {
        refetchQueries: [{ query: GET_MACHINE_INFO_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  if (
    machineInfoQuestion.answerType !==
    MachinesMachineInfoQuestionAnswerTypeChoices.Options
  ) {
    return <></>;
  }

  const onClickOptionAdd = () => {
    createMachineInfoQuestionOption({
      variables: {
        machineInfoQuestionId: machineInfoQuestion.id,
        title: "",
      },
    });
  };

  return (
    <>
      <tr>
        <td colSpan={5}>
          <Button
            variant="light"
            className="ms-3"
            size="sm"
            onClick={() => setShowOptions(!showOptions)}
          >
            <FontAwesomeIcon icon={showOptions ? faChevronUp : faChevronDown} />
          </Button>
          <span className="text-muted ms-3">
            {t("options")}{" "}
            <span className="ms-1">
              ({machineInfoQuestion.machineinfoquestionoptionSet.length})
            </span>
          </span>
        </td>
      </tr>
      {machineInfoQuestion.machineinfoquestionoptionSet.map(
        (machineInfoQuestionOption) => (
          <MachineInformationQuestionOption
            className={showOptions ? "" : "d-none"}
            key={machineInfoQuestionOption.id}
            machineInfoQuestionOption={machineInfoQuestionOption}
          />
        )
      )}
      <tr className={showOptions ? "" : "d-none"}>
        <td colSpan={5}>
          <ButtonLoad
            loading={loadingCreateOption}
            onClick={onClickOptionAdd}
            className="btnRoundSmall ms-5"
            variant="primary"
          >
            <AddIcon />
          </ButtonLoad>
        </td>
      </tr>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineInformationQuestionOptions);
