import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable, {
  Action,
  Column,
  Components,
  Options,
} from "material-table";
import {
  getDefaultSortMachines,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  setDefaultSortMachines,
  SortingDirectionType,
  SortingType,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { CatalogRowType, MachineType } from "../../entity/types";
import { createStyles, Theme } from "@material-ui/core";
import MachineInformationAnswered, {
  countMachineInformationAnswered,
  countMachineInformationTotal,
} from "./MachineInformationAnswered";
import { doesMatchAllTerms } from "../../utils/search";

export const defaultSortField = "machineModel.machineMake.title";
export const defaultSortDirection = "asc";

interface Props extends WithStyles<typeof styles> {
  machines: MachineType[];
  catalogIdSelected: string;
  actions?: Action<MachineType>[];
  options?: Options<MachineType>;
  onSelectionChange?:
    | ((data: MachineType[], rowData?: MachineType) => void)
    | undefined;
  components?: Components;
  showExtendedList?: boolean;
  selectionEnabled?: boolean;
}

const MaterialTableMachine = ({
  classes,
  machines,
  catalogIdSelected,
  actions = [],
  options = {},
  onSelectionChange = undefined,
  components = {},
  showExtendedList = false,
  selectionEnabled = true,
}: Props) => {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingType>(getDefaultSortMachines());

  const optionsDefault: Options<MachineType> = {
    paging: true,
    pageSize: 50,
    pageSizeOptions: [25, 50, 100],
    selectionProps: () => ({
      disabled: !selectionEnabled,
    }),
  };

  const data = machines.map((o) => ({ ...o }));

  const renderCatalogRows = (machine: MachineType) => {
    let rowNames: string[] = [];
    machine.catalogRows.forEach(function (catalogRow: CatalogRowType) {
      if (
        !catalogIdSelected ||
        catalogRow.catalogCategory.catalogCategoryUpper.catalog.id ===
          catalogIdSelected
      ) {
        rowNames.push(catalogRow.name);
      }
    });
    return rowNames.join(", ");
  };

  const getLocationText = (machine: MachineType) =>
    String(machine.bulkProduct ? t("bulk_product") : machine.location?.name);

  let columns: Column<MachineType>[] = [
    {
      title: t("machine_make"),
      field: "machineModel.machineMake.title",
      customFilterAndSearch: (term: string, machine) =>
        doesMatchAllTerms(term, machine.machineModel.machineMake.title),
      defaultSort:
        sorting.field === "machineModel.machineMake.title"
          ? sorting.orderDirection
          : undefined,
    },
    {
      title: t("machine_model"),
      field: "machineModel.title",
      customFilterAndSearch: (term: string, machine) =>
        doesMatchAllTerms(term, machine.machineModel.title),
      defaultSort:
        sorting.field === "machineModel.title"
          ? sorting.orderDirection
          : undefined,
    },
    {
      title: t("information"),
      field: "information",
      defaultSort:
        sorting.field === "information" ? sorting.orderDirection : undefined,
    },
    {
      title: t("identifier"),
      field: "identifier",
      defaultSort:
        sorting.field === "identifier" ? sorting.orderDirection : undefined,
    },
    {
      title: t("serial"),
      field: "serial",
      defaultSort:
        sorting.field === "serial" ? sorting.orderDirection : undefined,
    },
    {
      title: t("location"),
      field: "location.name",
      render: (machine) => getLocationText(machine),
      customFilterAndSearch: (term: string, machine) =>
        doesMatchAllTerms(term, getLocationText(machine)),
      defaultSort:
        sorting.field === "location.name" ? sorting.orderDirection : undefined,
    },
  ];
  if (showExtendedList) {
    columns.push({
      title: t("catalog_rows"),
      field: "catalogRows",
      render: (machine) => {
        let rowNames = renderCatalogRows(machine);
        return rowNames;
      },
      customFilterAndSearch: (term: string, machine) => {
        let rowNames = renderCatalogRows(machine);
        return doesMatchAllTerms(term, rowNames);
      },
      customSort: (machineA, machineB) => {
        let rowNamesA = renderCatalogRows(machineA);
        let rowNamesB = renderCatalogRows(machineB);
        return rowNamesA.localeCompare(rowNamesB);
      },
      defaultSort:
        sorting.field === "catalogRows" ? sorting.orderDirection : undefined,
    });
    columns.push({
      title: t("machine_information"),
      field: "machineinfoanswerSet",
      render: (machine) => {
        return <MachineInformationAnswered machine={machine} />;
      },
      customFilterAndSearch: (term: string, machine) => {
        let total = countMachineInformationTotal(machine);
        let count = countMachineInformationAnswered(machine);

        const x_of_x = t("x_of_x_slim", {
          count: count,
          total: total,
        });
        return x_of_x.toLowerCase().indexOf(term.toLowerCase()) !== -1;
      },
      customSort: (machineA, machineB) => {
        const machineAUnanswered =
          countMachineInformationTotal(machineA) -
          countMachineInformationAnswered(machineA);
        const machineBUnanswered =
          countMachineInformationTotal(machineB) -
          countMachineInformationAnswered(machineB);

        const unansweredMachineInformationCountDifference =
          machineAUnanswered - machineBUnanswered;

        if (unansweredMachineInformationCountDifference !== 0) {
          return unansweredMachineInformationCountDifference;
        }

        return (
          countMachineInformationTotal(machineA) -
          countMachineInformationTotal(machineB)
        );
      },
      defaultSort:
        sorting.field === "machineinfoanswerSet"
          ? sorting.orderDirection
          : undefined,
    });
  }

  const handleOrderChange = (
    orderBy: number,
    orderDirection: "asc" | "desc" | ""
  ) => {
    const field = columns[orderBy]
      ? String(columns[orderBy].field)
      : defaultSortField;
    const orderDirectionNew: SortingDirectionType =
      orderDirection === "" ? defaultSortDirection : orderDirection;

    setSorting({
      field: field,
      orderDirection: orderDirectionNew,
    });

    setDefaultSortMachines(field, orderDirectionNew);
  };

  return (
    <MaterialTable<MachineType>
      style={getTableStyle()}
      title={""}
      icons={tableIcons}
      localization={GetTableLocalization(t)}
      columns={columns}
      data={data}
      options={getTableOptions({ ...optionsDefault, ...options })}
      actions={actions}
      onSelectionChange={onSelectionChange}
      components={components}
      onOrderChange={handleOrderChange}
    />
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MaterialTableMachine);
