import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Link, Popover, Theme } from "@material-ui/core";
import {
  CatalogExtraRowRentalType,
  LocationType,
  MachineType,
  OrderType,
} from "../../entity/types";
import { Button, Table } from "react-bootstrap";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import { createTimeline, datesEqual } from "../../utils/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowFromLeft,
  faArrowFromRight,
  faClipboardList,
  faClone,
  faEuroSign,
  faRecycle,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { handleError } from "../../entity/ErrorHandler";
import {
  CALENDAR_INSERT_MODES,
  DATE_FORMAT_ISO,
  ID_EMPTY,
} from "../../utils/constants";
import { MachineEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { LinkContainer } from "react-router-bootstrap";
import { getUrlCalendar } from "../../utils/urls";
import CalendarCustomerPrices from "./CalendarCustomerPrices";
import { getMachineName } from "../../utils/machines/machine";
import CalendarCatalogExtraRows from "./CalendarCatalogExtraRows";
import CalendarTheadTh from "./CalendarTheadTh";
import {
  createBreakdownsByMachineDate,
  createMaintenancesByMachineDate,
  getCalendarCellBreakdowns,
  getCalendarCellMaintenances,
  getCellClassName,
  getCellTitle,
  reservationsByDateType,
  selectOrder,
} from "../../utils/calendar/calendar";
import MenuCalendarCellOptions from "./MenuCalendarCellOptions";
import DialogMachineProductCard from "../Machine/MachineInfo/DialogMachineProductCard";
import CalendarTrMonth from "./CalendarTrMonth";
import PopoverCalendarDateReturned from "./PopoverCalendarDateReturned";
import { CustomerForCalendarType } from "../../apollo/queries/customers";
import {
  BulkAmountByDateFromCalendarType,
  CatalogRowFromCalendarType,
  MachineBreakdownFromCalendarType,
  MaintenanceFromCalendarType,
  ReservationFromCalendarType,
} from "../../apollo/queries/catalogs";
import { MachineForCatalogRowsType } from "../../apollo/queries/machines";

type MachineWithReservationType = MachineForCatalogRowsType & {
  reservationIdSelected: string;
};

interface Props extends WithStyles<typeof styles> {
  catalogRow: CatalogRowFromCalendarType;
  machinesForThisCatalogRow: MachineForCatalogRowsType[];
  dateFrom: Date;
  dateTo: Date;
  setOrderId: React.Dispatch<React.SetStateAction<string>>;
  order: OrderType;
  customer: CustomerForCalendarType;
  reservations: ReservationFromCalendarType[];
  reservationsByDate: reservationsByDateType;
  dateSelectionStart: Date | null;
  setDateSelectionStart: React.Dispatch<React.SetStateAction<Date | null>>;
  dateSelectionEnd: Date | null;
  setDateSelectionEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  machineSelected: MachineForCatalogRowsType | null;
  setMachineSelected: React.Dispatch<
    React.SetStateAction<MachineForCatalogRowsType | null>
  >;
  setCatalogRowIdSelected: React.Dispatch<React.SetStateAction<string>>;
  machineBreakdowns: MachineBreakdownFromCalendarType[];
  maintenances: MaintenanceFromCalendarType[];
  insertMode: CALENDAR_INSERT_MODES;
  catalogId: string;
  catalogCategoryUpperId: string;
  catalogCategoryId: string;
  catalogRowId: string;
  catalogExtraRowRentalSelected: CatalogExtraRowRentalType | null;
  setCatalogExtraRowRentalSelected: React.Dispatch<
    React.SetStateAction<CatalogExtraRowRentalType | null>
  >;
  bulkAmount: number;
  setBulkAmount: React.Dispatch<React.SetStateAction<number>>;
  location: LocationType;
  bulkAmountsByDate: BulkAmountByDateFromCalendarType[];
  clearSelection: Function;
  setMachineBreakdown: React.Dispatch<
    React.SetStateAction<MachineBreakdownFromCalendarType>
  >;
  setOpenDialogMachineBreakdown: React.Dispatch<React.SetStateAction<boolean>>;
}

function CalendarCatalogRow({
  classes,
  catalogRow,
  machinesForThisCatalogRow,
  dateFrom,
  dateTo,
  setOrderId,
  order,
  customer,
  reservations,
  reservationsByDate,
  dateSelectionStart,
  setDateSelectionStart,
  dateSelectionEnd,
  setDateSelectionEnd,
  machineSelected,
  setMachineSelected,
  setCatalogRowIdSelected,
  machineBreakdowns,
  maintenances,
  insertMode,
  catalogId,
  catalogCategoryUpperId,
  catalogCategoryId,
  catalogRowId,
  catalogExtraRowRentalSelected,
  setCatalogExtraRowRentalSelected,
  bulkAmount,
  setBulkAmount,
  location,
  bulkAmountsByDate,
  clearSelection,
  setMachineBreakdown,
  setOpenDialogMachineBreakdown,
}: Props) {
  const { t } = useTranslation();

  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [anchorElPopoverDate, setAnchorElPopoverDate] =
    useState<null | HTMLElement>(null);
  const [reservationsMenu, setReservationsMenu] = useState<
    ReservationFromCalendarType[]
  >([]);
  const [breakdownsMenu, setBreakdownsMenu] = useState<
    MachineBreakdownFromCalendarType[]
  >([]);
  const [anchorElPrices, setAnchorElPrices] = useState<null | HTMLElement>(
    null
  );
  const [openProductCard, setOpenProductCard] = useState(false);
  const [machineForMachineInfo, setMachineForMachineInfo] =
    useState<MachineWithReservationType>({
      ...MachineEmpty,
      reservationIdSelected: ID_EMPTY,
    });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddOrder = checkPermission(myPermissions, [
    "orders.add_order",
  ]);

  const timeline = createTimeline(dateFrom, dateTo);
  const breakdownsByDate = createBreakdownsByMachineDate(machineBreakdowns);
  const maintenancesByDate = createMaintenancesByMachineDate(maintenances);
  let reservationIdsTitlePrinted: string[] = [];

  const isCellSelected = (
    date: Date,
    dateSelection: null | Date,
    machine: MachineWithReservationType
  ) =>
    machine.reservationIdSelected === ID_EMPTY &&
    dateSelection &&
    datesEqual(date, dateSelection) &&
    machineSelected &&
    machine.id === machineSelected.id &&
    !catalogExtraRowRentalSelected;

  const getCellContent = (
    date: Date,
    machine: MachineWithReservationType,
    reservations: ReservationFromCalendarType[],
    machineBreakdowns: MachineBreakdownFromCalendarType[],
    bulkAmountsByDateForMachine: BulkAmountByDateFromCalendarType[],
    maintenances: MaintenanceFromCalendarType[]
  ) => {
    let content: JSX.Element[] = [];
    const dateIso = format(date, DATE_FORMAT_ISO);

    const isAvailable =
      (!machine.dateRemoved || dateIso < machine.dateRemoved) &&
      (!machine.acquisitionDate || dateIso >= machine.acquisitionDate);
    if (!isAvailable) {
      content.push(
        <FontAwesomeIcon
          key={`${machine.id}_removed`}
          className="iconCell iconCellRemoved"
          size="lg"
          icon={faTimes}
        />
      );
    }

    content = getCalendarCellBreakdowns(
      t,
      date,
      content,
      machineBreakdowns,
      machine.id
    );

    content = getCalendarCellMaintenances(
      t,
      date,
      content,
      maintenances,
      machine.id
    );

    const hasSelectionStart = isCellSelected(date, dateSelectionStart, machine);
    if (hasSelectionStart) {
      content.push(
        <FontAwesomeIcon
          key={`${machine.id}_arrow_start`}
          className={`iconCell ${
            insertMode === CALENDAR_INSERT_MODES.BREAKDOWN
              ? "iconCellBreakdown"
              : ""
          }`}
          size="lg"
          icon={faArrowFromLeft}
        />
      );
    }
    const hasSelectionEnd = isCellSelected(date, dateSelectionEnd, machine);
    if (hasSelectionEnd) {
      content.push(
        <FontAwesomeIcon
          key={`${machine.id}_arrow_end`}
          className={`iconCell ${
            insertMode === CALENDAR_INSERT_MODES.BREAKDOWN
              ? "iconCellBreakdown"
              : ""
          }`}
          size="lg"
          icon={faArrowFromRight}
        />
      );
    }
    let counter = 0;
    reservations.forEach((reservation) => {
      if (!reservationIdsTitlePrinted.includes(reservation.id)) {
        const clipContent = datesEqual(new Date(reservation.returnAt), date);
        content.push(
          <div
            key={reservation.id}
            className="cellTitle"
            style={{
              marginTop: counter * 1.3 + "em",
              position: clipContent ? "unset" : "absolute",
              overflow: clipContent ? "clip" : "inherit",
            }}
          >
            {machine.bulkProduct ? (
              <b className="me-1">
                {t("x_pcs", { pcs: reservation.bulkAmount })}
              </b>
            ) : (
              ""
            )}
            {reservation.order.customer ? reservation.order.customer.name : ""}
            {reservations.length > 1 ? " - " : <br />}
            {reservation.order.customer && (
              <span>
                {reservation.order.customer.contactPersonLastname}{" "}
                {reservation.order.customer.contactPersonFirstname}
              </span>
            )}
            {machine.location &&
              reservation.order.location.id !== machine.location.id && (
                <span> ({reservation.order.location.name})</span>
              )}
          </div>
        );
        reservationIdsTitlePrinted.push(reservation.id);
        counter++;
      }
    });
    if (reservations.length > 1) {
      content.push(
        <span
          key={`spnMarkingCounter_${machine.id}`}
          className="spnMarkingCounter"
        >
          {reservations.length}
        </span>
      );
    }
    if (
      isAvailable &&
      machine.bulkProduct &&
      machine.reservationIdSelected === ID_EMPTY
    ) {
      const bulkAmountsByDateForMachineToday =
        bulkAmountsByDateForMachine.filter(
          (bulkAmountDate) => bulkAmountDate.date === dateIso
        );
      if (bulkAmountsByDateForMachineToday.length > 0) {
        const bulkAmountDate = bulkAmountsByDateForMachineToday[0];
        content.push(
          <span
            key={`spnBulkCounter_${machine.id}`}
            className={`spnBulkCounter ${
              hasSelectionStart || hasSelectionEnd ? "hasSelection" : ""
            }`}
          >
            {bulkAmountDate.amountFree}
          </span>
        );
      }
    }
    return content;
  };

  const getReservationsToday = (
    date: Date,
    machine: MachineWithReservationType
  ) => {
    const keyDate = format(date, DATE_FORMAT_ISO);
    const reservationsToday =
      typeof reservationsByDate[machine.id] !== "undefined" &&
      typeof reservationsByDate[machine.id][keyDate] !== "undefined"
        ? reservationsByDate[machine.id][keyDate]
        : [];
    if (machine.bulkProduct) {
      return reservationsToday.filter(
        (reservation) => reservation.id === machine.reservationIdSelected
      );
    } else {
      return reservationsToday;
    }
  };

  const getBreakdownsToday = (date: Date, machine: Pick<MachineType, "id">) => {
    const keyDate = format(date, DATE_FORMAT_ISO);
    return typeof breakdownsByDate[machine.id] !== "undefined" &&
      typeof breakdownsByDate[machine.id][keyDate] !== "undefined"
      ? breakdownsByDate[machine.id][keyDate]
      : [];
  };

  const getMaintenancesToday = (
    date: Date,
    machine: Pick<MachineType, "id">
  ) => {
    const keyDate = format(date, DATE_FORMAT_ISO);
    return typeof maintenancesByDate[machine.id] !== "undefined" &&
      typeof maintenancesByDate[machine.id][keyDate] !== "undefined"
      ? maintenancesByDate[machine.id][keyDate]
      : [];
  };

  const onClickCell = (
    event: React.MouseEvent<HTMLTableDataCellElement>,
    date: Date,
    machine: MachineWithReservationType
  ) => {
    const reservationsToday = getReservationsToday(date, machine);
    const machineBreakdownsToday = getBreakdownsToday(date, machine);

    let forceSelectionStart = false;
    if (catalogExtraRowRentalSelected) {
      setCatalogExtraRowRentalSelected(null);
      clearSelection();
      forceSelectionStart = true;
    }

    if (anchorElPopoverDate) {
      setAnchorElPopoverDate(null);
    }

    // Clicked existing order and/or machine breakdown without selection or
    // clicked machine after selecting extra rental row
    // so open order/breakdown or menu
    if (
      (!dateSelectionStart || catalogExtraRowRentalSelected) &&
      ((insertMode === CALENDAR_INSERT_MODES.RESERVATION &&
        reservationsToday.length > 0) ||
        (insertMode === CALENDAR_INSERT_MODES.BREAKDOWN &&
          machineBreakdownsToday.length > 0))
    ) {
      const countOpts =
        reservationsToday.length + machineBreakdownsToday.length;
      if (countOpts > 1) {
        setReservationsMenu(reservationsToday);
        setBreakdownsMenu(machineBreakdownsToday);
        setAnchorElMenu(event.currentTarget);
      } else if (reservationsToday.length > 0) {
        selectOrder(reservationsToday[0].order.id, setOrderId);
      } else if (machineBreakdownsToday.length > 0) {
        setMachineBreakdown(machineBreakdownsToday[0]);
        setOpenDialogMachineBreakdown(true);
      }
      return;
    }

    if (machine.bulkProduct && location.id === ID_EMPTY) {
      handleError(t("error_select_location_for_bulk_reservation"));
      return;
    }

    if (!hasPermissionAddOrder) {
      handleError(t("no_permission_create_order"));
      return;
    }

    if (
      customer.id === ID_EMPTY &&
      insertMode === CALENDAR_INSERT_MODES.RESERVATION
    ) {
      handleError(t("error_customer_not_selected"));
      return;
    }

    // Clicked selected cell again: remove selection
    if (
      !forceSelectionStart &&
      isCellSelected(date, dateSelectionStart, machine)
    ) {
      setDateSelectionStart(null);
      setDateSelectionEnd(null);
      setMachineSelected(null);
      setCatalogRowIdSelected(ID_EMPTY);
    }
    // Clicked cell in different machine than selected: reset selection to new machine
    else if (
      !forceSelectionStart &&
      machineSelected &&
      machineSelected.id !== machine.id
    ) {
      setDateSelectionStart(date);
      setDateSelectionEnd(null);
      setMachineSelected(machine);
      setCatalogRowIdSelected(catalogRow.id);
    }
    // Made a selection end click
    else if (
      !forceSelectionStart &&
      dateSelectionStart &&
      !dateSelectionEnd &&
      machineSelected &&
      machineSelected.id === machine.id
    ) {
      if (date > dateSelectionStart) {
        setDateSelectionEnd(date);
      } else {
        handleError(t("error_selected_date_before_start"));
      }
    }
    // Made a selection start click
    else {
      setDateSelectionStart(date);
      setDateSelectionEnd(null);
      setMachineSelected(machine);
      setCatalogRowIdSelected(catalogRow.id);
      setAnchorElPopoverDate(event.currentTarget);
    }
  };

  const dateTodayStr = format(new Date(), DATE_FORMAT_ISO);

  let machinesForThisCatalogRowBulksDuplicated: MachineWithReservationType[] =
    [];
  machinesForThisCatalogRow.forEach((machine) => {
    let machineWithReservation: MachineWithReservationType = {
      ...machine,
      reservationIdSelected: ID_EMPTY,
    };
    machinesForThisCatalogRowBulksDuplicated.push(machineWithReservation);

    if (machine.bulkProduct) {
      const reservationsForMachine = reservations.filter(
        (reservation) =>
          reservation.machine &&
          reservation.machine.id === machine.id &&
          !reservation.catalogExtraRowRental
      );
      reservationsForMachine.forEach((reservation) => {
        machinesForThisCatalogRowBulksDuplicated.push({
          ...machineWithReservation,
          reservationIdSelected: reservation.id,
        });
      });
    }
  });

  return (
    <>
      {machinesForThisCatalogRowBulksDuplicated.length > 0 && (
        <Table className="mb-5 tblCalendar">
          <thead>
            <CalendarTrMonth timeline={timeline} />
            <tr>
              <th className="calCellFirst" title={catalogRow.name}>
                {catalogRowId === ID_EMPTY ? (
                  <>
                    <Button
                      variant="light"
                      size="sm"
                      className={classes.btnCatalogRowPrice}
                      onClick={(event) =>
                        setAnchorElPrices(event.currentTarget)
                      }
                    >
                      <FontAwesomeIcon icon={faEuroSign} />
                    </Button>
                    <Popover
                      open={Boolean(anchorElPrices)}
                      anchorEl={anchorElPrices}
                      onClose={() => setAnchorElPrices(null)}
                    >
                      <div className={classes.popoverContentPrices}>
                        {Boolean(anchorElPrices) && (
                          <CalendarCustomerPrices
                            order={order}
                            customer={customer}
                            catalogRowId={catalogRow.id}
                            catalogExtraRowRental={undefined}
                          />
                        )}
                      </div>
                    </Popover>
                    <LinkContainer
                      to={getUrlCalendar(
                        catalogId,
                        catalogCategoryUpperId,
                        catalogCategoryId,
                        catalogRow.id
                      )}
                    >
                      <Link>{catalogRow.name}</Link>
                    </LinkContainer>
                  </>
                ) : (
                  catalogRow.name
                )}
              </th>
              {timeline.map((date, i) => (
                <CalendarTheadTh key={i} date={date} />
              ))}
            </tr>
          </thead>
          <tbody>
            {machinesForThisCatalogRowBulksDuplicated.map((machine) => {
              const reservationForThisMachine = reservations.filter(
                (reservation) =>
                  reservation.machine &&
                  reservation.machine.id === machine.id &&
                  reservation.dateReturned <= dateTodayStr &&
                  !reservation.catalogExtraRowRental
              );
              const machineHasUnfinishedReservation =
                reservationForThisMachine.some(
                  (reservation) => !reservation.returnAt && reservation.giveAt
                );
              const machineHasUncheckedReservation =
                reservationForThisMachine.some(
                  (reservation) =>
                    reservation.returnAt && !reservation.returnCheckedAt
                );
              const bulkAmountsByDateForMachine = bulkAmountsByDate.filter(
                (bulkAmountDate) => bulkAmountDate.machineId === machine.id
              );
              const isBulkResRowOrNotBulk =
                !machine.bulkProduct ||
                machine.reservationIdSelected !== ID_EMPTY;
              return (
                <tr key={`${machine.id}_${machine.reservationIdSelected}`}>
                  <td
                    className="calCellFirst"
                    title={getMachineName(machine, true)}
                  >
                    {getMachineName(machine)}
                    {machine.location && (
                      <small className="ms-1">({machine.location.name})</small>
                    )}
                    <br />
                    <small className="text-muted" title={machine.serial}>
                      {machine.identifier}&nbsp;
                    </small>
                    <Button
                      className="me-1"
                      variant="light"
                      size="sm"
                      title={t("open_product_card")}
                      onClick={() => {
                        setMachineForMachineInfo(machine);
                        setOpenProductCard(!openProductCard);
                      }}
                    >
                      <FontAwesomeIcon icon={faClipboardList} />
                    </Button>
                    {isBulkResRowOrNotBulk &&
                      machineHasUnfinishedReservation && (
                        <small
                          className="text-danger"
                          title={t("machine_not_returned")}
                        >
                          {t("machine_not_returned")}
                        </small>
                      )}
                    {isBulkResRowOrNotBulk &&
                      !machineHasUnfinishedReservation &&
                      machineHasUncheckedReservation && (
                        <small
                          className="text-danger"
                          title={t("machine_not_return_checked")}
                        >
                          {t("machine_not_return_checked")}
                        </small>
                      )}
                    {machine.rentThrough && (
                      <FontAwesomeIcon
                        className="ms-1"
                        title={
                          t("rent_through") +
                          (machine.rentThroughCompany
                            ? "\n" + machine.rentThroughCompany
                            : "") +
                          (machine.rentThroughInformation
                            ? "\n" + machine.rentThroughInformation
                            : "")
                        }
                        icon={faRecycle}
                      />
                    )}
                    {machine.bulkProduct && (
                      <FontAwesomeIcon
                        className="ms-1"
                        title={t("bulk_product")}
                        icon={faClone}
                      />
                    )}
                  </td>
                  {timeline.map((date) => {
                    const reservationsToday = getReservationsToday(
                      date,
                      machine
                    );
                    const breakdownsToday = getBreakdownsToday(date, machine);
                    const maintenancesToday = getMaintenancesToday(
                      date,
                      machine
                    );

                    return (
                      <td
                        key={format(date, DATE_FORMAT_ISO)}
                        className={`calCell ${getCellClassName(
                          date,
                          reservationsToday
                        )}`}
                        title={getCellTitle(
                          date,
                          reservationsToday,
                          machine.location
                        )}
                        onClick={(event) => {
                          onClickCell(event, date, machine);
                        }}
                      >
                        {getCellContent(
                          date,
                          machine,
                          reservationsToday,
                          breakdownsToday,
                          bulkAmountsByDateForMachine,
                          maintenancesToday
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {openProductCard && (
        <DialogMachineProductCard
          open={openProductCard}
          setOpen={setOpenProductCard}
          machineId={machineForMachineInfo.id}
        />
      )}
      <CalendarCatalogExtraRows
        machinesForThisCatalogRow={machinesForThisCatalogRow}
        timeline={timeline}
        dateSelectionStart={dateSelectionStart}
        setDateSelectionStart={setDateSelectionStart}
        dateSelectionEnd={dateSelectionEnd}
        setDateSelectionEnd={setDateSelectionEnd}
        catalogExtraRowRentalSelected={catalogExtraRowRentalSelected}
        setCatalogExtraRowRentalSelected={setCatalogExtraRowRentalSelected}
        customer={customer}
        insertMode={insertMode}
        order={order}
        setOrderId={setOrderId}
        setMachineSelected={setMachineSelected}
        reservations={reservations}
        machineBreakdowns={machineBreakdowns}
        maintenances={maintenances}
        setMachineBreakdown={setMachineBreakdown}
        setOpenDialogMachineBreakdown={setOpenDialogMachineBreakdown}
        location={location}
      />
      <MenuCalendarCellOptions
        anchorElMenu={anchorElMenu}
        setAnchorElMenu={setAnchorElMenu}
        setOrderId={setOrderId}
        reservationsMenu={reservationsMenu}
        breakdownsMenu={breakdownsMenu}
        setMachineBreakdown={setMachineBreakdown}
        setOpenDialogMachineBreakdown={setOpenDialogMachineBreakdown}
        setReservationsMenu={setReservationsMenu}
        setBreakdownsMenu={setBreakdownsMenu}
        machinesMenu={[]}
        reservationsInOrder={[]}
      />
      {Boolean(anchorElPopoverDate) && (
        <PopoverCalendarDateReturned
          anchorEl={anchorElPopoverDate}
          setAnchorEl={setAnchorElPopoverDate}
          reservations={order.id !== ID_EMPTY ? order.reservationSet : []}
          dateSelectionStart={dateSelectionStart}
          setDateSelectionEnd={setDateSelectionEnd}
          machine={machineSelected ? machineSelected : MachineEmpty}
          bulkAmount={bulkAmount}
          setBulkAmount={setBulkAmount}
        />
      )}
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    btnCatalogRowPrice: {
      marginLeft: "-10px",
      marginRight: spacing(1),
    },
    popoverContentPrices: {
      paddingTop: spacing(2),
      paddingRight: spacing(3),
      paddingBottom: spacing(1),
      paddingLeft: spacing(3),
    },
  });

export default withStyles(styles)(CalendarCatalogRow);
