import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import {
  tableIcons,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import { UserType, GroupType, LocationType } from "../../entity/types";
import UserListMenu from "./UserListMenu";
import HeaderPage from "../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {
  users: UserType[];
  groups: GroupType[];
  locations: LocationType[];
}

const UserList = ({ classes, users, groups, locations }: Props) => {
  const { t } = useTranslation();

  const data = users.map((o) => ({ ...o }));

  return (
    <>
      <HeaderPage title={t("users")} />
      <div className="containerInner">
        <MaterialTable<UserType>
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            { title: t("firstname"), field: "firstName" },
            { title: t("lastname"), field: "lastName" },
            { title: t("email"), field: "email" },
            { title: t("phone"), field: "UserInfo.phone" },
            { title: t("postcode"), field: "UserInfo.postcode" },
            { title: t("district"), field: "UserInfo.district" },
            {
              title: t("profile"),
              field: "groups",
              render: (user) =>
                user.groups.map(function (group) {
                  return group.name;
                }),
            },
          ]}
          data={data}
          options={getTableOptions()}
          components={{
            Action: (props) => (
              <UserListMenu
                user={props.data}
                groups={groups}
                locations={locations}
              />
            ),
          }}
          actions={[
            {
              icon: "menu",
              onClick: () => {},
            },
          ]}
        />
      </div>
    </>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(UserList);
