import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  Mutation,
  MutationUpdateReservationArgs,
  OrderType,
  ReservationsReservationDeliveryMethodChoices,
  ReservationsReservationStatusChoices,
  ReservationType,
} from "../../entity/types";
import CustomerPriceSource from "../Customer/CustomerPriceSource";
import CalendarCustomerPrices from "./CalendarCustomerPrices";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { parseNumber, timeWithoutSeconds } from "../../utils/formatting";
import { Button, Col, Image, Row } from "react-bootstrap";
import { COLOR_MUTED, ID_EMPTY } from "../../utils/constants";
import DialogMachineProductCard from "../Machine/MachineInfo/DialogMachineProductCard";
import {
  getReservationProductName,
  reservationCanBeDeleted,
} from "../../utils/reservations/reservation";
import ButtonReservationDelete from "./ButtonReservationDelete";
import DialogImage from "../Shared/DialogImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faClipboardList,
  faClock,
  faEuroSign,
  faInfo,
} from "@fortawesome/pro-light-svg-icons";
import DialogCatalogExtraReservation from "../CatalogExtraUnitPrice/DialogCatalogExtraReservation";
import { getMachineName } from "../../utils/machines/machine";
import ButtonOrderSplit from "./ButtonOrderSplit";
import CalendarCustomerPriceMin from "./CalendarCustomerPriceMin";
import ButtonReservationSplit from "./ButtonReservationSplit";
import DialogOperationHours from "../OperationHour/DialogOperationHours";
import { updateCacheReservation } from "../../utils/cache";
import ReservationDiscount from "./ReservationDiscount";
import MaintenanceEstimatedHours from "../Maintenance/MaintenanceEstimatedHours";
import TextFieldFocus from "../Shared/TextFieldFocus";
import DialogReservationInfo from "../Reservation/DialogReservationInfo";
import { getUrlCalendar } from "../../utils/urls";
import { setLocalStorageOrderId } from "../../utils/calendar/calendar";
import { useHistory } from "react-router-dom";
import DialogCatalogExtraRowRentalInfo from "../CatalogExtraRental/DialogCatalogExtraRowRentalInfo";
import { CustomerForCalendarType } from "../../apollo/queries/customers";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
  setOrderId?: React.Dispatch<React.SetStateAction<string>>;
  reservation: ReservationType;
  setReservation: React.Dispatch<React.SetStateAction<ReservationType>>;
  customer: CustomerForCalendarType;
}

function ReservationFields({
  classes,
  order,
  setOrderId,
  reservation,
  setReservation,
  customer,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [reservationEdited, setReservationEdited] = useState(reservation);
  const [openDialogImage, setOpenDialogImage] = useState(false);
  const [openProductCard, setOpenProductCard] = useState(false);
  const [openExtraProductCard, setOpenExtraProductCard] = useState(false);
  const [openCatalogExtra, setOpenCatalogExtra] = useState(false);
  const [openOperationHours, setOpenOperationHours] = useState(false);
  const [openReservationInfo, setOpenReservationInfo] = useState(false);
  const [overrideHours, setOverrideHours] = useState(
    reservation.billingDaysWeekCompany !== null ||
      reservation.billingDaysWeekPrivate !== null ||
      reservation.hourLimitDayCompany !== null ||
      reservation.hourLimitDayPrivate !== null ||
      reservation.hourLimitMonthCompany !== null ||
      reservation.hourLimitMonthPrivate !== null
  );

  const isMachineReservation = !reservationEdited.catalogExtraRowRental;

  const [updateReservation, { loading }] = useMutation<
    Mutation,
    MutationUpdateReservationArgs
  >(UPDATE_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);

      // Revert changes
      setReservationEdited(reservation);
    },
    update: (cache) => {
      updateCacheReservation(cache);
    },
  });

  useEffect(() => {
    setReservationEdited(reservation);
  }, [reservation]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);
  const hasPermissionDeleteReservation = checkPermission(myPermissions, [
    "reservations.delete_reservation",
  ]);
  const hasPermissionAddReservation = checkPermission(myPermissions, [
    "reservations.add_reservation",
  ]);
  const hasPermissionAddOrder = checkPermission(myPermissions, [
    "orders.add_order",
  ]);
  const hasPermissionChangeOrder = checkPermission(myPermissions, [
    "orders.change_order",
  ]);
  const hasPermissionViewOperationHour = checkPermission(myPermissions, [
    "operation_hours.view_operationhour",
  ]);
  const hasPermissionChangeReturnDate = checkPermission(myPermissions, [
    "reservations.change_return_after_return",
  ]);

  const hasPermissionOrderSplit =
    hasPermissionAddReservation &&
    hasPermissionChangeReservation &&
    hasPermissionAddOrder &&
    hasPermissionChangeOrder;

  const hasPermissionReservationSplit =
    hasPermissionAddReservation && hasPermissionChangeReservation;

  const disabledReturnDate =
    (reservationEdited.returnAt !== null && !hasPermissionChangeReturnDate) ||
    reservationEdited.status === ReservationsReservationStatusChoices.Invoiced;

  const toggleOverrideHours = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setOverrideHours(checked);
  };

  const onClickCalendar = () => {
    if (reservationEdited.catalogRow) {
      setLocalStorageOrderId(order.id);
      history.push(
        getUrlCalendar(
          reservationEdited.catalogRow.catalogCategory.catalogCategoryUpper
            .catalog.id,
          reservationEdited.catalogRow.catalogCategory.catalogCategoryUpper.id,
          reservationEdited.catalogRow.catalogCategory.id,
          reservationEdited.catalogRow.id
        )
      );
    }
  };
  return (
    <div className={loading ? "loading" : ""}>
      <h4 className="mb-3">
        {getReservationProductName(t, reservationEdited)}
        {reservationEdited.catalogExtraRowRental &&
          reservationEdited.catalogExtraRowRental.identifier !== "" && (
            <small className="text-muted ms-3">
              {reservationEdited.catalogExtraRowRental.identifier}
            </small>
          )}
        {!reservationEdited.catalogExtraRowRental &&
          reservationEdited.machine && (
            <small
              className="text-muted ms-3"
              title={reservationEdited.machine.serial}
            >
              {reservationEdited.machine.identifier}
            </small>
          )}
        <br />
        <small className={`${classes.smlResId} text-muted`}>
          {t("reservation_id", { id: reservationEdited.id })}
        </small>
        {reservationEdited.catalogExtraRowRental &&
          reservationEdited.machine && (
            <small
              className={`${classes.smlResId} text-muted ms-1`}
              title={reservationEdited.machine.serial}
            >
              - {getMachineName(reservationEdited.machine, true)}
            </small>
          )}
      </h4>
      <div className="d-flex">
        <FormControl fullWidth>
          <TextField
            type="date"
            label={t("date_rented")}
            value={reservationEdited.dateRented}
            inputProps={{
              min: "2020-01-01",
              max: reservationEdited.dateReturned,
            }}
            disabled={
              !hasPermissionChangeReservation ||
              reservationEdited.giveAt !== null
            }
            onChange={(event) => {
              const dateRentedNew = event.currentTarget.value;
              setReservationEdited({
                ...reservationEdited,
                dateRented: dateRentedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservationEdited.id,
                  dateRented: dateRentedNew,
                },
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth className="ms-3">
          <TextField
            type="time"
            label={t("time_rented")}
            value={timeWithoutSeconds(reservationEdited.timeRented)}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={
              !hasPermissionChangeReservation ||
              reservationEdited.giveAt !== null
            }
            onChange={(event) => {
              const timeRentedNew = event.currentTarget.value
                ? event.currentTarget.value
                : null;
              setReservationEdited({
                ...reservationEdited,
                timeRented: timeRentedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservationEdited.id,
                  timeRented: timeRentedNew,
                },
              });
            }}
          />
        </FormControl>
      </div>
      <div className="d-flex">
        <FormControl fullWidth>
          <TextField
            type="date"
            label={t("date_returned")}
            value={
              reservationEdited.dateReturned
                ? reservationEdited.dateReturned
                : undefined
            }
            inputProps={{
              min: reservationEdited.dateRented,
              max: "2099-12-31",
            }}
            disabled={!hasPermissionChangeReservation || disabledReturnDate}
            onChange={(event) => {
              const dateReturnedNew = event.currentTarget.value;
              setReservationEdited({
                ...reservationEdited,
                dateReturned: dateReturnedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservationEdited.id,
                  dateReturned: dateReturnedNew,
                },
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth className="ms-3">
          <TextField
            type="time"
            label={t("time_returned")}
            value={timeWithoutSeconds(reservationEdited.timeReturned)}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!hasPermissionChangeReservation || disabledReturnDate}
            onChange={(event) => {
              const timeReturnedNew = event.currentTarget.value
                ? event.currentTarget.value
                : null;
              setReservationEdited({
                ...reservationEdited,
                timeReturned: timeReturnedNew,
              });
              updateReservation({
                variables: {
                  reservationId: reservationEdited.id,
                  timeReturned: timeReturnedNew,
                },
              });
            }}
          />
        </FormControl>
      </div>
      <div className="d-flex">
        <div className="w-50 pe-2">
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_billing_min")}
              value={
                reservationEdited.dateBillingMin
                  ? reservationEdited.dateBillingMin
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: reservationEdited.dateRented,
              }}
              disabled={!hasPermissionChangeReservation}
              onChange={(event) => {
                const dateBillingMinNew = event.currentTarget.value;
                setReservationEdited({
                  ...reservationEdited,
                  dateBillingMin: dateBillingMinNew,
                });
                updateReservation({
                  variables: {
                    reservationId: reservationEdited.id,
                    dateBillingMin:
                      dateBillingMinNew === "" ? null : dateBillingMinNew,
                  },
                });
              }}
            />
          </FormControl>
        </div>
        {reservationEdited.machine?.maintenancesetting?.scheduleType &&
          (reservationEdited.machine.maintenancesetting.scheduleType ===
            MaintenanceMaintenanceSettingScheduleTypeChoices.Hours ||
            reservationEdited.machine.maintenancesetting.scheduleType ===
              MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays) && (
            <div className="w-50 pe-2">
              <MaintenanceEstimatedHours
                estimatedHoursPerDay={
                  reservationEdited.estimatedMachineHoursPerDay !== "0.00"
                    ? reservationEdited.estimatedMachineHoursPerDay
                    : reservationEdited.machine?.maintenancesetting
                        ?.estimatedHoursPerDay
                }
                disabled={!hasPermissionChangeReservation}
                setStateVariable={(value: Number) =>
                  setReservationEdited({
                    ...reservationEdited,
                    estimatedMachineHoursPerDay: value,
                  })
                }
                saveStateVariable={() => {
                  updateReservation({
                    variables: {
                      reservationId: reservationEdited.id,
                      estimatedMachineHoursPerDay:
                        reservationEdited.estimatedMachineHoursPerDay,
                    },
                  });
                }}
              />
            </div>
          )}
        {reservationEdited.dateBillingMin && (
          <CalendarCustomerPriceMin reservation={reservationEdited} />
        )}
      </div>
      {reservationEdited.machine &&
        reservationEdited.machine.bulkProduct &&
        !reservationEdited.catalogExtraRowRental && (
          <div className="d-flex">
            <div className="w-50">
              <FormControl fullWidth>
                <TextFieldFocus
                  type="number"
                  label={t("bulk_amount")}
                  value={reservationEdited.bulkAmount}
                  disabled={!hasPermissionChangeReservation}
                  onChange={(event) => {
                    const bulkAmountNew = parseNumber(
                      event.currentTarget.value
                    );
                    setReservationEdited({
                      ...reservationEdited,
                      bulkAmount: bulkAmountNew,
                    });
                    updateReservation({
                      variables: {
                        reservationId: reservationEdited.id,
                        bulkAmount: bulkAmountNew,
                      },
                    });
                  }}
                />
              </FormControl>
            </div>
          </div>
        )}
      <div className="d-flex">
        <div>
          <FormLabel>{t("customer_active_price_list")}</FormLabel>
          <CustomerPriceSource customer={customer} />
        </div>
        <div className="flex-grow-1">
          {!reservationEdited.catalogExtraRowRental &&
            reservationEdited.catalogRow &&
            reservationEdited.catalogRow.image && (
              <div className="float-end">
                <Image
                  className={classes.imgMachine}
                  src={reservationEdited.catalogRow.image}
                  onClick={() => setOpenDialogImage(!openDialogImage)}
                />
                <DialogImage
                  open={openDialogImage}
                  setOpen={setOpenDialogImage}
                  src={reservationEdited.catalogRow.image}
                />
              </div>
            )}
          {!reservationEdited.catalogExtraRowRental &&
            reservationEdited.machine &&
            reservationEdited.machine.id !== ID_EMPTY && (
              <div className="float-end me-3 mb-2">
                <Button
                  variant="light"
                  onClick={() => setOpenProductCard(!openProductCard)}
                >
                  <FontAwesomeIcon className="me-2" icon={faClipboardList} />
                  {t("open_product_card")}
                </Button>
                {openProductCard && (
                  <DialogMachineProductCard
                    open={openProductCard}
                    setOpen={setOpenProductCard}
                    machineId={reservationEdited.machine.id}
                  />
                )}
              </div>
            )}
          {reservationEdited.catalogExtraRowRental && (
            <div className="float-end me-3 mb-2">
              <Button
                variant="light"
                onClick={() => setOpenExtraProductCard(!openExtraProductCard)}
              >
                <FontAwesomeIcon className="me-2" icon={faClipboardList} />
                {t("open_product_card")}
              </Button>
              {openExtraProductCard && (
                <DialogCatalogExtraRowRentalInfo
                  open={openExtraProductCard}
                  setOpen={setOpenExtraProductCard}
                  catalogExtraRowRental={
                    reservationEdited.catalogExtraRowRental
                  }
                />
              )}
            </div>
          )}
          {hasPermissionViewOperationHour &&
            !reservationEdited.catalogExtraRowRental &&
            reservationEdited.machine &&
            reservationEdited.machine.id !== ID_EMPTY && (
              <div className="float-end me-3 mb-2">
                <Button
                  variant="light"
                  onClick={() => setOpenOperationHours(!openCatalogExtra)}
                >
                  <FontAwesomeIcon className="me-2" icon={faClock} />
                  {t("operation_hours")}
                </Button>
                {openOperationHours && (
                  <DialogOperationHours
                    open={openOperationHours}
                    setOpen={setOpenOperationHours}
                    reservation={reservationEdited}
                    machine={reservationEdited.machine}
                  />
                )}
              </div>
            )}
          <div className="float-end me-3 mb-2">
            <Button
              variant="light"
              onClick={() => setOpenCatalogExtra(!openCatalogExtra)}
            >
              <FontAwesomeIcon className="me-2" icon={faEuroSign} />
              {t("catalog_extra_unit_price")}
            </Button>
            {openCatalogExtra && (
              <DialogCatalogExtraReservation
                open={openCatalogExtra}
                setOpen={setOpenCatalogExtra}
                reservation={reservationEdited}
              />
            )}
          </div>
          <div className="float-end me-3 mb-2">
            <Button
              variant="light"
              onClick={() => setOpenReservationInfo(!openReservationInfo)}
            >
              <FontAwesomeIcon className="me-2" icon={faInfo} />
              {t("reservation_info")}
            </Button>
            {openReservationInfo && (
              <DialogReservationInfo
                open={openReservationInfo}
                setOpen={setOpenReservationInfo}
                reservation={reservationEdited}
              />
            )}
          </div>
        </div>
      </div>
      <CalendarCustomerPrices
        order={order}
        customer={customer}
        catalogRowId={
          reservationEdited.catalogRow
            ? reservationEdited.catalogRow.id
            : ID_EMPTY
        }
        catalogExtraRowRental={reservationEdited.catalogExtraRowRental}
        reservation={reservationEdited}
      />
      <ReservationDiscount reservation={reservationEdited} />
      <FormControl component="fieldset">
        <FormLabel>{t("delivery_method")}</FormLabel>
        <RadioGroup
          aria-label={t("delivery_method")}
          name="deliveryMethod"
          value={reservationEdited.deliveryMethod}
          onChange={(event, value) => {
            const deliveryMethodNew =
              value as ReservationsReservationDeliveryMethodChoices;
            setReservationEdited({
              ...reservationEdited,
              deliveryMethod: deliveryMethodNew,
            });
            updateReservation({
              variables: {
                reservationId: reservationEdited.id,
                deliveryMethod: deliveryMethodNew,
              },
            });
          }}
        >
          <FormControlLabel
            value={ReservationsReservationDeliveryMethodChoices.Customer}
            control={<Radio />}
            label={t(
              "delivery_method_" +
                ReservationsReservationDeliveryMethodChoices.Customer
            )}
            disabled={!hasPermissionChangeReservation}
          />
          <FormControlLabel
            value={ReservationsReservationDeliveryMethodChoices.Transport}
            control={<Radio />}
            label={t(
              "delivery_method_" +
                ReservationsReservationDeliveryMethodChoices.Transport
            )}
            disabled={!hasPermissionChangeReservation}
          />
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          multiline
          label={t("information")}
          value={reservationEdited.information}
          minRows={2}
          disabled={!hasPermissionChangeReservation}
          onChange={(event) => {
            setReservationEdited({
              ...reservationEdited,
              information: event.currentTarget.value,
            });
          }}
          onBlur={() => {
            updateReservation({
              variables: {
                reservationId: reservationEdited.id,
                information: reservationEdited.information,
              },
            });
          }}
        />
      </FormControl>
      {isMachineReservation && (
        <div>
          <FormControl fullWidth>
            <FormControlLabel
              label={t("reservation_override_hours")}
              control={
                <Checkbox
                  checked={overrideHours}
                  onChange={toggleOverrideHours}
                />
              }
            />
          </FormControl>
          {overrideHours && (
            <div className="mb-3">
              {order.customer?.isBusiness && (
                <Row>
                  <Col>
                    <FormControl fullWidth>
                      <TextFieldFocus
                        label={t("billing_days_week_company")}
                        onChange={(event) => {
                          setReservationEdited({
                            ...reservationEdited,
                            billingDaysWeekCompany:
                              event.target.value === ""
                                ? null
                                : Number(event.target.value),
                          });
                        }}
                        onBlur={() => {
                          updateReservation({
                            variables: {
                              reservationId: reservationEdited.id,
                              billingDaysWeekCompany:
                                reservationEdited.billingDaysWeekCompany,
                            },
                          });
                        }}
                        value={
                          reservationEdited.billingDaysWeekCompany === null
                            ? ""
                            : reservationEdited.billingDaysWeekCompany
                        }
                        disabled={!hasPermissionChangeReservation}
                      />
                    </FormControl>
                    <p className={classes.catalogRowDefault}>
                      {t("catalog_row_default_is", {
                        default:
                          reservationEdited.catalogRow?.billingDaysWeekCompany,
                      })}
                    </p>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextFieldFocus
                        label={t("hour_limit_month_company")}
                        onChange={(event) => {
                          setReservationEdited({
                            ...reservationEdited,
                            hourLimitMonthCompany:
                              event.target.value === ""
                                ? null
                                : Number(event.target.value),
                          });
                        }}
                        onBlur={() => {
                          updateReservation({
                            variables: {
                              reservationId: reservationEdited.id,
                              hourLimitMonthCompany:
                                reservationEdited.hourLimitMonthCompany,
                            },
                          });
                        }}
                        value={
                          reservationEdited.hourLimitMonthCompany === null
                            ? ""
                            : reservationEdited.hourLimitMonthCompany
                        }
                        disabled={!hasPermissionChangeReservation}
                      />
                    </FormControl>
                    <p className={classes.catalogRowDefault}>
                      {t("catalog_row_default_is", {
                        default:
                          reservationEdited.catalogRow?.hourLimitMonthCompany,
                      })}
                    </p>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextFieldFocus
                        label={t("hour_limit_day_company")}
                        onChange={(event) => {
                          setReservationEdited({
                            ...reservationEdited,
                            hourLimitDayCompany:
                              event.target.value === ""
                                ? null
                                : Number(event.target.value),
                          });
                        }}
                        onBlur={() => {
                          updateReservation({
                            variables: {
                              reservationId: reservationEdited.id,
                              hourLimitDayCompany:
                                reservationEdited.hourLimitDayCompany,
                            },
                          });
                        }}
                        value={
                          reservationEdited.hourLimitDayCompany === null
                            ? ""
                            : reservationEdited.hourLimitDayCompany
                        }
                        disabled={!hasPermissionChangeReservation}
                      />
                    </FormControl>
                    <p className={classes.catalogRowDefault}>
                      {t("catalog_row_default_is", {
                        default:
                          reservationEdited.catalogRow?.hourLimitDayCompany,
                      })}
                    </p>
                  </Col>
                </Row>
              )}
              {!order.customer?.isBusiness && (
                <Row>
                  <Col>
                    <FormControl fullWidth>
                      <TextFieldFocus
                        label={t("billing_days_week_private")}
                        onChange={(event) => {
                          setReservationEdited({
                            ...reservationEdited,
                            billingDaysWeekPrivate:
                              event.target.value === ""
                                ? null
                                : Number(event.target.value),
                          });
                        }}
                        onBlur={() => {
                          updateReservation({
                            variables: {
                              reservationId: reservationEdited.id,
                              billingDaysWeekPrivate:
                                reservationEdited.billingDaysWeekPrivate,
                            },
                          });
                        }}
                        value={
                          reservationEdited.billingDaysWeekPrivate === null
                            ? ""
                            : reservationEdited.billingDaysWeekPrivate
                        }
                        disabled={!hasPermissionChangeReservation}
                      />
                    </FormControl>
                    <p className={classes.catalogRowDefault}>
                      {t("catalog_row_default_is", {
                        default:
                          reservationEdited.catalogRow?.billingDaysWeekPrivate,
                      })}
                    </p>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextFieldFocus
                        label={t("hour_limit_month_private")}
                        onChange={(event) => {
                          setReservationEdited({
                            ...reservationEdited,
                            hourLimitMonthPrivate:
                              event.target.value === ""
                                ? null
                                : Number(event.target.value),
                          });
                        }}
                        onBlur={() => {
                          updateReservation({
                            variables: {
                              reservationId: reservationEdited.id,
                              hourLimitMonthPrivate:
                                reservationEdited.hourLimitMonthPrivate,
                            },
                          });
                        }}
                        value={
                          reservationEdited.hourLimitMonthPrivate === null
                            ? ""
                            : reservationEdited.hourLimitMonthPrivate
                        }
                        disabled={!hasPermissionChangeReservation}
                      />
                    </FormControl>
                    <p className={classes.catalogRowDefault}>
                      {t("catalog_row_default_is", {
                        default:
                          reservationEdited.catalogRow?.hourLimitMonthPrivate,
                      })}
                    </p>
                  </Col>
                  <Col>
                    <FormControl fullWidth>
                      <TextFieldFocus
                        label={t("hour_limit_day_private")}
                        onChange={(event) => {
                          setReservationEdited({
                            ...reservationEdited,
                            hourLimitDayPrivate:
                              event.target.value === ""
                                ? null
                                : Number(event.target.value),
                          });
                        }}
                        onBlur={() => {
                          updateReservation({
                            variables: {
                              reservationId: reservationEdited.id,
                              hourLimitDayPrivate:
                                reservationEdited.hourLimitDayPrivate,
                            },
                          });
                        }}
                        value={
                          reservationEdited.hourLimitDayPrivate === null
                            ? ""
                            : reservationEdited.hourLimitDayPrivate
                        }
                        disabled={!hasPermissionChangeReservation}
                      />
                    </FormControl>
                    <p className={classes.catalogRowDefault}>
                      {t("catalog_row_default_is", {
                        default:
                          reservationEdited.catalogRow?.hourLimitDayPrivate,
                      })}
                    </p>
                  </Col>
                </Row>
              )}
            </div>
          )}
        </div>
      )}
      {!reservationEdited.catalogExtraRowRental &&
        reservationEdited.catalogRow &&
        reservationEdited.catalogRow.information && (
          <p className={classes.elRowInformation}>
            {reservationEdited.catalogRow.information}
          </p>
        )}
      {reservationEdited.catalogExtraRowRental &&
        reservationEdited.catalogExtraRowRental.information !== "" && (
          <p className={classes.elRowInformation}>
            {reservationEdited.catalogExtraRowRental.information}
          </p>
        )}
      <div className="buttons mb-0">
        {hasPermissionDeleteReservation &&
          reservationCanBeDeleted(reservationEdited) && (
            <ButtonReservationDelete reservation={reservationEdited} />
          )}
        {hasPermissionOrderSplit && (
          <ButtonOrderSplit order={order} setOrderId={setOrderId} />
        )}
        {hasPermissionReservationSplit && (
          <ButtonReservationSplit
            order={order}
            reservation={reservationEdited}
            setReservation={setReservation}
          />
        )}
        {reservationEdited.catalogRow?.catalogCategory.catalogCategoryUpper
          .catalog && (
          <Button
            variant="light"
            title={t("calendar_for_reservation")}
            onClick={onClickCalendar}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </Button>
        )}
      </div>
    </div>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    imgMachine: {
      width: "auto",
      height: "5rem",
      cursor: "pointer",
    },
    elRowInformation: {
      backgroundColor: palette.grey["100"],
      padding: "10px 15px",
      marginTop: "15px",
    },
    smlResId: {
      fontSize: "0.8rem",
    },
    catalogRowDefault: {
      color: COLOR_MUTED,
    },
  });

export default withStyles(styles)(ReservationFields);
