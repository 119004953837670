import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { ButtonProps } from "react-bootstrap/Button";
import { useMutation } from "@apollo/client";
import {
  GET_OFFER_PDF_MUTATION,
  MutationResultOfferPdf,
} from "../../apollo/mutations/catalogs";
import {
  CatalogType,
  CustomerType,
  DiscountListType,
  MutationGetOfferPdfArgs,
} from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { popNewWindow } from "../../utils/dialogs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/pro-light-svg-icons";
import ButtonLoad from "./ButtonLoad";
import { useTranslation } from "react-i18next";
import { ID_EMPTY } from "../../utils/constants";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  customer?: CustomerType;
  discountList?: DiscountListType;
  catalogRowIdsPrinted?: string[];
}

const ButtonPdfOffer = ({
  classes,
  catalog,
  customer,
  discountList,
  catalogRowIdsPrinted,
}: Props & ButtonProps) => {
  const { t } = useTranslation();

  const [getOfferPdf, { loading: loadingGetOfferPdf }] = useMutation<
    MutationResultOfferPdf,
    MutationGetOfferPdfArgs
  >(GET_OFFER_PDF_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      popNewWindow(t, String(result.getOfferPdf?.url));
    },
  });

  const onClickCreateOffer = () => {
    getOfferPdf({
      variables: {
        catalogId: catalog.id,
        customerId: customer ? customer.id : ID_EMPTY,
        discountListId: discountList ? discountList.id : ID_EMPTY,
        catalogRowIdsPrinted: catalogRowIdsPrinted,
      },
    });
  };

  return (
    <ButtonLoad
      loading={loadingGetOfferPdf}
      target="_blank"
      onClick={onClickCreateOffer}
    >
      <FontAwesomeIcon className="me-2" icon={faFilePdf} />
      {t("create_pdf")}
    </ButtonLoad>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonPdfOffer);
