import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import format from "date-fns/format";
import CalendarCatalog from "./CalendarCatalog";
import {
  getLocationIdDefault,
  setLocalStorageLocationId,
} from "../../utils/locations/location";
import {
  CALENDAR_INSERT_MODES,
  DATE_FORMAT_ISO,
  ID_EMPTY,
} from "../../utils/constants";
import { CatalogExtraRowRentalType, LocationType } from "../../entity/types";
import { LocationEmpty, ReservationEmpty } from "../../entity/empties";
import Loading from "../Shared/Loading";
import { resetDateTime } from "../../utils/dates";
import Error from "../Shared/Error";
import { UserContext } from "../../Root";
import { findFromSetById } from "../../utils/collections";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { createReservationsByMachineDate } from "../../utils/calendar/calendar";
import { useGetCalendarQuery } from "../../apollo/queries/catalogs.generated";
import { CatalogCategoryUpperFromCalendarType } from "../../apollo/queries/catalogs";
import { MachineForCatalogRowsType } from "../../apollo/queries/machines";

interface Props extends WithStyles<typeof styles> {
  catalogId: string;
  catalogCategoryUpperId: string;
  catalogCategoryId: string;
  catalogRowId: string;
  locations: LocationType[];
}

function Calendar({
  classes,
  catalogId,
  catalogCategoryUpperId,
  catalogCategoryId,
  catalogRowId,
  locations,
}: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const [location, setLocation] = useState(
    findFromSetById(
      getLocationIdDefault(user),
      locations,
      locations.length > 0 ? locations[0] : LocationEmpty
    )
  );

  const dateFromDefault = resetDateTime(new Date());
  dateFromDefault.setDate(dateFromDefault.getDate() - 7);
  const dateToDefault = resetDateTime(new Date());
  dateToDefault.setDate(dateToDefault.getDate() + 14);

  const [dateFrom, setDateFrom] = useState(dateFromDefault);
  const [dateTo, setDateTo] = useState(dateToDefault);

  /*******************************************************************************************/
  /* These are needed on row level but they are here so we don't lose data on rerender */
  const [dateSelectionStart, setDateSelectionStart] = useState<null | Date>(
    null
  );
  const [dateSelectionEnd, setDateSelectionEnd] = useState<null | Date>(null);
  const [machineSelected, setMachineSelected] =
    useState<null | MachineForCatalogRowsType>(null);
  const [catalogRowIdSelected, setCatalogRowIdSelected] = useState(ID_EMPTY);
  const [catalogExtraRowRentalSelected, setCatalogExtraRowRentalSelected] =
    useState<null | CatalogExtraRowRentalType>(null);
  const [bulkAmount, setBulkAmount] = useState(ReservationEmpty.bulkAmount);

  /*******************************************************************************************/
  /* Needed in the next component but it's here so we don't lose data on rerender */
  const [insertMode, setInsertMode] = useState<CALENDAR_INSERT_MODES>(
    CALENDAR_INSERT_MODES.RESERVATION
  );

  /*******************************************************************************************/

  const { loading, error, data } = useGetCalendarQuery({
    fetchPolicy: getQueryFetchPolicy("reservationsCalendar"),
    variables: {
      catalogId: catalogId,
      catalogCategoryUpperId: catalogCategoryUpperId,
      dateFrom: format(dateFrom, DATE_FORMAT_ISO),
      dateTo: format(dateTo, DATE_FORMAT_ISO),
      locationId: location.id,
      catalogCategoryId: catalogCategoryId,
      catalogRowId: catalogRowId,
    },
  });

  useEffect(() => {
    if (location.id !== ID_EMPTY) {
      setLocalStorageLocationId(location.id);
    }
  }, [location]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const reservations = data.reservationsCalendar
    ? data.reservationsCalendar
    : [];
  const reservationsByDate = createReservationsByMachineDate(reservations);

  let catalogCategoriesUpper: CatalogCategoryUpperFromCalendarType[] =
    data.catalogCategoriesUpper ? data.catalogCategoriesUpper : [];

  if (catalogCategoryId !== ID_EMPTY) {
    catalogCategoriesUpper = catalogCategoriesUpper.map(
      (catalogCategoryUpper) => ({
        ...catalogCategoryUpper,
        catalogcategorySet: catalogCategoryUpper.catalogcategorySet.filter(
          (catalogCategory) => catalogCategory.id === catalogCategoryId
        ),
      })
    );
  }

  if (catalogRowId !== ID_EMPTY) {
    catalogCategoriesUpper = catalogCategoriesUpper.map(
      (catalogCategoryUpper) => ({
        ...catalogCategoryUpper,
        catalogcategorySet: catalogCategoryUpper.catalogcategorySet.map(
          (catalogCategory) => ({
            ...catalogCategory,
            catalogrowSet: catalogCategory.catalogrowSet.filter(
              (catalogRow) => catalogRow.id === catalogRowId
            ),
          })
        ),
      })
    );
  }

  return (
    <CalendarCatalog
      catalogId={catalogId}
      catalogCategoryId={catalogCategoryId}
      catalogRowId={catalogRowId}
      catalogCategoriesUpper={catalogCategoriesUpper}
      location={location}
      setLocation={setLocation}
      locations={locations}
      dateFrom={dateFrom}
      setDateFrom={setDateFrom}
      dateTo={dateTo}
      setDateTo={setDateTo}
      bulkAmountsByDate={data.bulkAmountsByDate ? data.bulkAmountsByDate : []}
      reservations={reservations}
      reservationsByDate={reservationsByDate}
      machineBreakdowns={data.machineBreakdowns ? data.machineBreakdowns : []}
      maintenances={data.maintenancesCalendar ? data.maintenancesCalendar : []}
      dateSelectionStart={dateSelectionStart}
      setDateSelectionStart={setDateSelectionStart}
      dateSelectionEnd={dateSelectionEnd}
      setDateSelectionEnd={setDateSelectionEnd}
      machineSelected={machineSelected}
      setMachineSelected={setMachineSelected}
      catalogRowIdSelected={catalogRowIdSelected}
      setCatalogRowIdSelected={setCatalogRowIdSelected}
      catalogExtraRowRentalSelected={catalogExtraRowRentalSelected}
      setCatalogExtraRowRentalSelected={setCatalogExtraRowRentalSelected}
      bulkAmount={bulkAmount}
      setBulkAmount={setBulkAmount}
      insertMode={insertMode}
      setInsertMode={setInsertMode}
    />
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Calendar);
