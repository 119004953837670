import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultCosttypes {
  costtypes: Query["costtypes"];
}
export const GET_COSTTYPES_QUERY = gql`
  query getCosttypesQuery {
    costtypes {
      id
      number
      name
    }
  }
`;

export interface QueryResultCosttypesForRentThrough {
  costtypesForRentThrough: Query["costtypesForRentThrough"];
}
export const GET_COSTTYPES_FOR_RENT_THROUGH_QUERY = gql`
  query getCosttypesForRentThroughQuery {
    costtypesForRentThrough {
      id
      location {
        id
      }
      costtype {
        id
      }
    }
  }
`;
