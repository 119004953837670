import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraCategoryUpperRentalType,
  CatalogExtraRowRentalType,
  Mutation,
  MutationUpdateCatalogExtraRowRentalOrderArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsV } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_CATALOG_EXTRA_ROW_RENTAL_ORDER_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import {
  getCatalogExtraSortStyles,
  renderSelectGroup,
} from "../../utils/catalog_extra/catalog_extra";
import { getQueryKey } from "../../utils/cache";
import { IdToIndexType } from "../../utils/collections";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoriesUpperRental: CatalogExtraCategoryUpperRentalType[];
  catalogExtraCategoryRentalIdSelected: string;
  setCatalogExtraCategoryRentalIdSelected: React.Dispatch<
    React.SetStateAction<string>
  >;
}

function CatalogExtraRentalSortRows({
  classes,
  catalogExtraCategoriesUpperRental,
  catalogExtraCategoryRentalIdSelected,
  setCatalogExtraCategoryRentalIdSelected,
}: Props) {
  const { t } = useTranslation();

  const [updateCatalogExtraRowRentalOrder, { loading }] = useMutation<
    Mutation,
    MutationUpdateCatalogExtraRowRentalOrderArgs
  >(UPDATE_CATALOG_EXTRA_ROW_RENTAL_ORDER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("catalogExtraCategoriesUpperRental"),
      });
    },
  });

  let catalogExtraRowsRental: CatalogExtraRowRentalType[] = [];
  catalogExtraCategoriesUpperRental.forEach(
    (catalogExtraCategoryUpperRental) => {
      catalogExtraCategoryUpperRental.catalogextracategoryrentalSet.forEach(
        (catalogExtraCategoryRental) => {
          if (
            catalogExtraCategoryRental.id ===
            catalogExtraCategoryRentalIdSelected
          ) {
            catalogExtraRowsRental =
              catalogExtraCategoryRental.catalogextrarowrentalSet;
          }
        }
      );
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    let idToIndex: IdToIndexType[] = [];
    arrayMove(catalogExtraRowsRental, oldIndex, newIndex).map(
      (catalogExtraRowRental: CatalogExtraRowRentalType, index) => {
        idToIndex.push({
          id: catalogExtraRowRental.id,
          order: index,
        });
        return null;
      }
    );
    updateCatalogExtraRowRentalOrder({
      variables: { idOrderInput: idToIndex },
    });
  };

  const DragHandleRow = SortableHandle(() => (
    <span className={classes.elSort}>
      <FontAwesomeIcon icon={faArrowsV} size="lg" />
    </span>
  ));

  const RowContainer = SortableElement(({ catalogExtraRowRental }: any) => (
    <div key={catalogExtraRowRental.id} className="mb-3">
      <DragHandleRow />
      {catalogExtraRowRental.name}
    </div>
  ));

  const CategoriesContainer = SortableContainer(({ items }: any) => {
    return (
      <div className={loading ? "loading" : ""}>
        {items.map(
          (catalogExtraRowRental: CatalogExtraRowRentalType, index: number) => (
            <RowContainer
              key={catalogExtraRowRental.id}
              index={index}
              catalogExtraRowRental={catalogExtraRowRental}
            />
          )
        )}
      </div>
    );
  });

  return (
    <div>
      <h5 className="mb-4">{t("sort_catalog_extra_rows")}</h5>
      <FormControl className="mb-4" fullWidth>
        <InputLabel id="lblCatalogExtraRowCategoryFilter">
          {t("catalog_extra_row")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblCatalogExtraRowCategoryFilter"
          value={catalogExtraCategoryRentalIdSelected}
          onChange={(event) => {
            setCatalogExtraCategoryRentalIdSelected(String(event.target.value));
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogExtraCategoriesUpperRental.map(
            (catalogExtraCategoryUpperRental) =>
              renderSelectGroup(catalogExtraCategoryUpperRental)
          )}
        </Select>
      </FormControl>
      <CategoriesContainer
        items={catalogExtraRowsRental}
        onSortEnd={onSortEnd}
        useDragHandle
      />
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    elSort: getCatalogExtraSortStyles(theme),
  });

export default withStyles(styles)(CatalogExtraRentalSortRows);
