import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InvoiceableRowSources,
  InvoiceableRowType,
  InvoiceType,
  Mutation,
  MutationCreateInvoiceArgs,
  ReferenceType,
} from "../../entity/types";
import { Table } from "react-bootstrap";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { useMutation } from "@apollo/client";
import { CREATE_INVOICE_MUTATION } from "../../apollo/mutations/invoices";
import { handleError } from "../../entity/ErrorHandler";
import { DATE_FORMAT_ISO, ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt, faTruck } from "@fortawesome/pro-light-svg-icons";
import InvoiceableRowCellFirst from "./InvoiceableRowCellFirst";
import format from "date-fns/format";
import ButtonLoad from "../Shared/ButtonLoad";
import { InvoiceEmpty } from "../../entity/empties";
import DialogInvoice from "../Invoice/DialogInvoice";
import ButtonOrderDialog from "../Shared/ButtonOrderDialog";

interface Props extends WithStyles<typeof styles> {
  reference: ReferenceType;
  invoiceableRows: InvoiceableRowType[];
  isAdvance: boolean;
  dateTo: Date;
}

function ManagementInvoicesOpenReference({
  classes,
  reference,
  invoiceableRows,
  isAdvance,
  dateTo,
}: Props) {
  const { t } = useTranslation();

  const [reservationIds, setReservationIds] = useState<string[]>([]);
  const [transportInvoicingIds, setTransportInvoicingIds] = useState<string[]>(
    []
  );
  const [transportInvoicingSummaryIds, setTransportInvoicingSummaryIds] =
    useState<string[]>([]);
  const [catalogExtraRowInvoiceIds, setCatalogExtraRowInvoiceIds] = useState<
    string[]
  >([]);
  const [operationHourIds, setOperationHourIds] = useState<string[]>([]);
  const [invoiceCreated, setInvoiceCreated] =
    useState<InvoiceType>(InvoiceEmpty);
  const [openDialogInvoice, setOpenDialogInvoice] = useState(false);
  const [visible, setVisible] = useState(true);

  const [createInvoice, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateInvoiceArgs
  >(CREATE_INVOICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (result) => {
      setReservationIds([]);
      setTransportInvoicingIds([]);
      setTransportInvoicingSummaryIds([]);
      setVisible(false);

      if (result.createInvoice?.invoice) {
        setInvoiceCreated(result.createInvoice.invoice);
        setOpenDialogInvoice(true);
      }
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationPrice"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("invoicesOpen"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddInvoice =
    checkPermission(myPermissions, ["invoices.add_invoice"]) ||
    (isAdvance &&
      checkPermission(myPermissions, [
        "invoices.allow_advance_invoicing_without_perms",
      ]));

  let totalPriceAll = 0;
  let totalPriceSelected = 0;

  invoiceableRows.forEach((invoiceableRow) => {
    const price = parseNumber(invoiceableRow.price);
    totalPriceAll += price;

    switch (invoiceableRow.rowSource) {
      case InvoiceableRowSources.Reservation:
        if (reservationIds.includes(invoiceableRow.identifier)) {
          totalPriceSelected += price;
        }
        break;
      case InvoiceableRowSources.TransportInvoicing:
        if (transportInvoicingIds.includes(invoiceableRow.identifier)) {
          totalPriceSelected += price;
        }
        break;
      case InvoiceableRowSources.TransportInvoicingSummary:
        if (transportInvoicingSummaryIds.includes(invoiceableRow.identifier)) {
          totalPriceSelected += price;
        }
        break;
      case InvoiceableRowSources.OperationHour:
        if (operationHourIds.includes(invoiceableRow.identifier)) {
          totalPriceSelected += price;
        }
        break;
      case InvoiceableRowSources.CatalogExtraRowInvoice:
        if (catalogExtraRowInvoiceIds.includes(invoiceableRow.identifier)) {
          totalPriceSelected += price;
        }
        break;
      default:
        if (
          invoiceableRow.subrowReservationId !== ID_EMPTY &&
          reservationIds.includes(invoiceableRow.subrowReservationId)
        ) {
          totalPriceSelected += price;
        }
        break;
    }
  });

  const countSelectedRows =
    reservationIds.length +
    transportInvoicingIds.length +
    transportInvoicingSummaryIds.length +
    catalogExtraRowInvoiceIds.length +
    operationHourIds.length;

  const onClickCreateInvoice = () => {
    if (countSelectedRows === 0) {
      handleError(t("error_no_reservations_selected"));
      return false;
    }

    createInvoice({
      variables: {
        referenceId: reference.id,
        reservationIds: reservationIds,
        transportInvoicingIds: transportInvoicingIds,
        transportInvoicingSummaryIds: transportInvoicingSummaryIds,
        catalogExtraRowInvoiceIds: catalogExtraRowInvoiceIds,
        operationHourIds: operationHourIds,
        isAdvance: isAdvance,
        dateTo: format(dateTo, DATE_FORMAT_ISO),
      },
    });
  };

  const hasReference = reference.id !== ID_EMPTY;

  return (
    <div
      className={`${classes.conInvoiceReference} ${!visible ? "d-none" : ""}`}
    >
      <Table className="tblInvoice" borderless responsive={true}>
        <thead>
          <tr>
            <th className={classes.cellFirst}>
              <FontAwesomeIcon className={classes.icon} icon={faReceipt} />
            </th>
            <th colSpan={4}>
              {!hasReference && (
                <span className="text-danger">{t("no_reference")}</span>
              )}
              {reference.customer.name}
              <br />
              {reference.name}
            </th>
            <th className={classes.cellSixth}></th>
            <th className={classes.cellSeventh}></th>
            <th className={classes.cellEighth}></th>
          </tr>
          <tr>
            <th className={classes.cellFirst}></th>
            <th className={classes.cellSecond}>{t("product_number")}</th>
            <th className={classes.cellThird}>{t("quantity")}</th>
            <th className={classes.cellFourth}>{t("unit_price")}</th>
            <th className={classes.cellFifth}>{t("price")}</th>
            <th className={classes.cellSixth}>{t("title")}</th>
            <th className={classes.cellSeventh}>{t("description")}</th>
            <th className={classes.cellEighth}></th>
          </tr>
        </thead>
        <tbody>
          {invoiceableRows.map((invoiceableRow, key) => (
            <tr
              key={`${key}_${invoiceableRow.rowSource}_${invoiceableRow.identifier}`}
            >
              <td className={classes.cellFirst}>
                {hasPermissionAddInvoice && (
                  <InvoiceableRowCellFirst
                    invoiceableRow={invoiceableRow}
                    reservationIds={reservationIds}
                    setReservationIds={setReservationIds}
                    transportInvoicingIds={transportInvoicingIds}
                    setTransportInvoicingIds={setTransportInvoicingIds}
                    transportInvoicingSummaryIds={transportInvoicingSummaryIds}
                    setTransportInvoicingSummaryIds={
                      setTransportInvoicingSummaryIds
                    }
                    catalogExtraRowInvoiceIds={catalogExtraRowInvoiceIds}
                    setCatalogExtraRowInvoiceIds={setCatalogExtraRowInvoiceIds}
                    operationHourIds={operationHourIds}
                    setOperationHourIds={setOperationHourIds}
                  />
                )}
              </td>
              <td className={classes.cellSecond}>
                {invoiceableRow.productNumber}
              </td>
              <td className={classes.cellThird}>
                {formatNumber(invoiceableRow.quantity, 2)}
              </td>
              <td className={classes.cellFourth}>
                {formatNumber(invoiceableRow.unitPrice, 2)}
              </td>
              <td className={classes.cellFifth}>
                {formatNumber(invoiceableRow.price, 2)}
              </td>
              <td className={classes.cellSixth}>
                {[
                  InvoiceableRowSources.TransportInvoicing,
                  InvoiceableRowSources.TransportInvoicingSummary,
                ].includes(invoiceableRow.rowSource) && (
                  <FontAwesomeIcon icon={faTruck} className="me-1" />
                )}
                {invoiceableRow.title}
              </td>
              <td className={classes.cellSeventh}>
                {invoiceableRow.description}
              </td>
              <td className={classes.cellEighth}>
                {invoiceableRow.orderId !== ID_EMPTY &&
                  invoiceableRow.reservationId !== ID_EMPTY && (
                    <ButtonOrderDialog
                      orderId={invoiceableRow.orderId}
                      reservationId={invoiceableRow.reservationId}
                      buttonText={t("reservation_id", {
                        id: invoiceableRow.reservationId,
                      })}
                    />
                  )}
                {invoiceableRow.orderId !== ID_EMPTY &&
                  invoiceableRow.reservationId === ID_EMPTY && (
                    <ButtonOrderDialog
                      orderId={invoiceableRow.orderId}
                      buttonText={t("order_id", { id: invoiceableRow.orderId })}
                    />
                  )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td className={classes.cellFirst}></td>
            <td className={classes.cellSecond}></td>
            <th colSpan={3} className={classes.cellTotalSum}>
              {t("x_from_total_y_currency", {
                x: formatNumber(totalPriceSelected, 2),
                y: formatNumber(totalPriceAll, 2),
              })}
            </th>
            <td colSpan={2} className={`${classes.cellFifth} text-end`}>
              {hasPermissionAddInvoice && (
                <ButtonLoad
                  loading={loadingCreate}
                  onClick={onClickCreateInvoice}
                  disabled={!hasReference || countSelectedRows === 0}
                >
                  {t("create_invoice")}
                </ButtonLoad>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      {openDialogInvoice && (
        <DialogInvoice
          invoice={invoiceCreated}
          open={openDialogInvoice}
          setOpen={setOpenDialogInvoice}
        />
      )}
    </div>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    conInvoiceReference: {
      marginBottom: spacing(5),
      borderBottom: "1px solid #EEEEEE",
    },
    icon: {
      fontSize: "2.5rem",
      color: palette.primary.main,
    },
    cellFirst: {
      width: "75px",
      textAlign: "center",
    },
    cellSecond: {
      width: "8%",
    },
    cellThird: {
      width: "8%",
      textAlign: "right",
    },
    cellFourth: {
      width: "8%",
      textAlign: "right",
    },
    cellFifth: {
      width: "8%",
      textAlign: "right",
    },
    cellSixth: {
      width: "calc(35% - 75px)",
    },
    cellSeventh: {
      width: "25%",
    },
    cellEighth: {
      width: "8%",
    },
    cellTotalSum: {
      fontSize: "1.4rem",
      textAlign: "right",
    },
  });

export default withStyles(styles)(ManagementInvoicesOpenReference);
