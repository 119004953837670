import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import WorkQueueCardGroupGive from "./WorkQueueCardGroupGive";
import {
  OrderFromReservationsWorkQueuesType,
  ReservationFromReservationsWorkQueuesType,
} from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservations: ReservationFromReservationsWorkQueuesType[];
  id?: string;
}

function WorkQueueRentingCustomerPickup({ classes, reservations, id }: Props) {
  const { t } = useTranslation();

  let reservationsByOrder: {
    [orderId: string]: {
      order: OrderFromReservationsWorkQueuesType;
      reservations: ReservationFromReservationsWorkQueuesType[];
    };
  } = {};
  reservations.forEach((reservation) => {
    // Add a prefix so result is not automatically sorted by order_id
    const key = " " + reservation.order.id;
    if (reservationsByOrder[key] === undefined) {
      reservationsByOrder[key] = {
        order: reservation.order,
        reservations: [],
      };
    }
    reservationsByOrder[key].reservations.push(reservation);
  });

  return (
    <div id={id} className="conWorkQueue">
      <div className="titleWorkQueue">
        {t("work_queues_renting_customer_pickup")}
      </div>
      {Object.entries(reservationsByOrder).map(([order_id, row]) => (
        <WorkQueueCardGroupGive
          key={order_id}
          order={row.order}
          reservations={row.reservations}
        />
      ))}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueRentingCustomerPickup);
