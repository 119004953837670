import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  DELETE_PRODUCT_CODE_MUTATION,
  UPDATE_PRODUCT_CODE_MUTATION,
} from "../../apollo/mutations/product_codes";
import {
  CostcenterType,
  CosttypeType,
  Mutation,
  MutationDeleteProductCodeArgs,
  MutationUpdateProductCodeArgs,
  ProductCodeType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  createStyles,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import ButtonLoad from "../Shared/ButtonLoad";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { CostcenterEmpty, CosttypeEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  productCode: ProductCodeType;
  costcenters: CostcenterType[];
  costtypes: CosttypeType[];
}

const ProductCodeRow = ({
  classes,
  productCode,
  costcenters,
  costtypes,
}: Props) => {
  const { t } = useTranslation();

  const [deleteProductCode, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteProductCodeArgs
  >(DELETE_PRODUCT_CODE_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("productCodes"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateProductCode, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateProductCodeArgs
  >(UPDATE_PRODUCT_CODE_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("productCodes"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionProductRowChange = checkPermission(myPermissions, [
    "product_codes.change_productcode",
  ]);
  const hasPermissionProductCodeDelete = checkPermission(myPermissions, [
    "product_codes.delete_productcode",
  ]);

  const [productCodeEdit, setProductCodeEdit] = useState({
    ...productCode,
    code: productCode.code,
    description: productCode.description,
  });

  const handleUpdate = (
    values: Partial<MutationUpdateProductCodeArgs> = {}
  ) => {
    const base: MutationUpdateProductCodeArgs = {
      productCodeId: productCodeEdit.id,
      costcenterId: productCodeEdit.costcenter
        ? productCodeEdit.costcenter.id
        : ID_EMPTY,
      costtypeId: productCodeEdit.costtype
        ? productCodeEdit.costtype.id
        : ID_EMPTY,
      code: productCodeEdit.code,
      description: productCodeEdit.description,
    };
    updateProductCode({
      variables: { ...base, ...values },
    });
  };

  const onClickDeleteProductCode = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteProductCode({
        variables: {
          productCodeId: productCode.id,
        },
      });
    });
  };

  return (
    <tr className={loadingUpdate ? "loading" : ""}>
      <td>
        <FormControl>
          <TextField
            fullWidth
            value={productCodeEdit.code}
            disabled={!hasPermissionProductRowChange}
            onChange={(event) => {
              setProductCodeEdit({
                ...productCodeEdit,
                code: event.target.value,
              });
            }}
            onBlur={(event) => {
              handleUpdate({ code: event.target.value });
            }}
          />
        </FormControl>
      </td>
      <td>
        <FormControl>
          <TextField
            className={classes.txtDescription}
            fullWidth
            value={productCodeEdit.description}
            disabled={!hasPermissionProductRowChange}
            onChange={(event) => {
              setProductCodeEdit({
                ...productCodeEdit,
                description: event.target.value,
              });
            }}
            onBlur={(event) => {
              handleUpdate({ description: event.target.value });
            }}
          />
        </FormControl>
      </td>
      <td>
        <Select
          fullWidth
          value={
            productCodeEdit.costcenter
              ? productCodeEdit.costcenter.id
              : ID_EMPTY
          }
          onChange={(event) => {
            const costcenterNew = productCodeEdit.costcenter
              ? productCodeEdit.costcenter
              : CostcenterEmpty;
            const costcenterIdNew = String(event.target.value);
            setProductCodeEdit({
              ...productCodeEdit,
              costcenter: {
                ...costcenterNew,
                id: costcenterIdNew,
              },
            });
            handleUpdate({ costcenterId: costcenterIdNew });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("costcenter_from_location")}</MenuItem>
          {costcenters.map((costcenter) => (
            <MenuItem key={costcenter.id} value={costcenter.id}>
              {costcenter.number} - {costcenter.name}
            </MenuItem>
          ))}
        </Select>
      </td>
      <td>
        <Select
          fullWidth
          value={
            productCodeEdit.costtype ? productCodeEdit.costtype.id : ID_EMPTY
          }
          onChange={(event) => {
            const costtypeNew = productCodeEdit.costtype
              ? productCodeEdit.costtype
              : CosttypeEmpty;
            const costtypeIdNew = String(event.target.value);
            setProductCodeEdit({
              ...productCodeEdit,
              costtype: {
                ...costtypeNew,
                id: costtypeIdNew,
              },
            });
            handleUpdate({ costtypeId: costtypeIdNew });
          }}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {costtypes.map((costtype) => (
            <MenuItem key={costtype.id} value={costtype.id}>
              {costtype.number} - {costtype.name}
            </MenuItem>
          ))}
        </Select>
      </td>
      <td>
        {hasPermissionProductCodeDelete && (
          <ButtonLoad
            loading={loadingDelete}
            variant="light"
            size="sm"
            className={classes.btn}
            onClick={onClickDeleteProductCode}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonLoad>
        )}
      </td>
    </tr>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "2rem",
    },
    txtDescription: {
      minWidth: "20vw",
    },
  });

export default withStyles(styles)(ProductCodeRow);
