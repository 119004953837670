import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  LocationType,
  Mutation,
  MutationDeleteLocationArgs,
  MutationUpdateLocationArgs,
} from "../../entity/types";
import DialogContentLocation from "./DialogContentLocation";
import { Button } from "react-bootstrap";
import { dialogConfirm } from "../../utils/dialogs";
import { useMutation } from "@apollo/client";
import {
  DELETE_LOCATION_MUTATION,
  UPDATE_LOCATION_MUTATION,
} from "../../apollo/mutations/locations";
import { LocationEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  location: LocationType;
  setLocation: React.Dispatch<React.SetStateAction<LocationType>>;
}

function DialogLocation({
  classes,
  open,
  setOpen,
  location,
  setLocation,
}: Props) {
  const { t } = useTranslation();

  const [updateLocation, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateLocationArgs
  >(UPDATE_LOCATION_MUTATION, {
    onCompleted: (result) => {
      setLocation(LocationEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteLocation, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteLocationArgs
  >(DELETE_LOCATION_MUTATION, {
    onCompleted: (result) => {
      setLocation(LocationEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("locations"),
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateLocation({
      variables: {
        locationId: location.id,
        name: location.name,
        dimIdentifier: location.dimIdentifier,
      },
    }).then(() => {
      setOpen(false);
    });
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteLocation({ variables: { locationId: location.id } }).then(() => {
        setOpen(false);
      });
    });
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("edit_location")}</DialogTitle>
        <DialogContentLocation location={location} setLocation={setLocation} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingDelete}
            onClick={(event) => handleDelete(event)}
            variant="light"
          >
            {t("delete")}
          </ButtonLoad>
          <ButtonLoad loading={loadingUpdate} type="submit" variant="primary">
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogLocation);
