import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import {
  Checkbox,
  createStyles,
  FormControl,
  FormControlLabel,
  TextField,
  Theme,
} from "@material-ui/core";
import LocationFilter from "../Shared/LocationFilter";
import format from "date-fns/format";
import { DATE_FORMAT_ISO } from "../../utils/constants";
import { parse } from "date-fns";

interface Props extends WithStyles<typeof styles> {
  dateTo: Date;
  setDateTo: React.Dispatch<React.SetStateAction<Date>>;
  locationIdsSelected: string[];
  setLocationIdsSelected: React.Dispatch<React.SetStateAction<string[]>>;
  onlyEndedReservations?: boolean;
  setOnlyEndedReservations?: React.Dispatch<React.SetStateAction<boolean>>;
  onlyIncludingEndedReservations?: boolean;
  setOnlyIncludingEndedReservations?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onlyPreinvoicingCustomers?: boolean;
  setOnlyPreinvoicingCustomers?: React.Dispatch<React.SetStateAction<boolean>>;
  onClickCallback?: Function;
}

const InvoiceListFilter = ({
  classes,
  dateTo,
  setDateTo,
  locationIdsSelected,
  setLocationIdsSelected,
  onlyEndedReservations,
  setOnlyEndedReservations,
  onlyIncludingEndedReservations,
  setOnlyIncludingEndedReservations,
  onlyPreinvoicingCustomers,
  setOnlyPreinvoicingCustomers,
  onClickCallback,
}: Props) => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (onClickCallback) {
      onClickCallback();
    }
  };

  return (
    <Row className="mb-2">
      <Col sm={6} md={6} lg={4} xl={3} className="mb-2">
        <LocationFilter
          locationIdsSelected={locationIdsSelected}
          setLocationIdsSelected={setLocationIdsSelected}
        />
      </Col>
      <Col sm={3} md={3} lg={2} xl={2} className="mb-2">
        <div>
          <FormControl fullWidth>
            <TextField
              type="date"
              label={t("date_to")}
              value={format(dateTo, DATE_FORMAT_ISO)}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                let date = parse(
                  event.target.value,
                  DATE_FORMAT_ISO,
                  new Date()
                );
                setDateTo(date);
              }}
            />
          </FormControl>
        </div>
      </Col>
      {setOnlyIncludingEndedReservations && (
        <Col sm={3} md={3} lg={2} xl={2} className="mb-2">
          <FormControl fullWidth>
            <FormControlLabel
              label={t("invoiceable_filter_including_ended_reservations")}
              control={
                <Checkbox
                  checked={onlyIncludingEndedReservations}
                  onChange={(event) => {
                    setOnlyIncludingEndedReservations(event.target.checked);
                  }}
                />
              }
            />
          </FormControl>
        </Col>
      )}
      {setOnlyEndedReservations && (
        <Col sm={3} md={3} lg={2} xl={2} className="mb-2">
          <FormControl fullWidth>
            <FormControlLabel
              label={t("invoiceable_filter_ended_reservations")}
              control={
                <Checkbox
                  checked={onlyEndedReservations}
                  onChange={(event) => {
                    setOnlyEndedReservations(event.target.checked);
                  }}
                />
              }
            />
          </FormControl>
        </Col>
      )}
      {setOnlyPreinvoicingCustomers && (
        <Col sm={3} md={3} lg={2} xl={2} className="mb-2">
          <FormControl fullWidth>
            <FormControlLabel
              label={t("invoiceable_filter_preinvoicing_reservations")}
              control={
                <Checkbox
                  checked={onlyPreinvoicingCustomers}
                  onChange={(event) => {
                    setOnlyPreinvoicingCustomers(event.target.checked);
                  }}
                />
              }
            />
          </FormControl>
        </Col>
      )}
      {onClickCallback && (
        <Col className="mb-2">
          <Button variant="primary" onClick={handleButtonClick}>
            {t("search")}
          </Button>
        </Col>
      )}
    </Row>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InvoiceListFilter);
