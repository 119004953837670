import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  CatalogExtraRowRentalType,
  CatalogRowType,
  Mutation,
  MutationUpdateReservationArgs,
  ReservationType,
} from "../../entity/types";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { getPercentDifference, getPercentAmount } from "../../utils/calc";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservation } from "../../utils/cache";
import { CALENDAR_CUSTOMER_PRICE_ROW } from "./styles";

interface Props extends WithStyles<typeof styles> {
  priceMonth: number;
  isBusiness: boolean;
  catalogRow: CatalogRowType;
  catalogExtraRowRental: CatalogExtraRowRentalType | undefined | null;
  reservation?: ReservationType;
  discountPercent?: number;
}

const getPriceMonthEdited = (
  priceMonth: number,
  reservation?: ReservationType
) =>
  reservation
    ? formatNumber(
        getPercentAmount(priceMonth, reservation.discountPercentMonth),
        2
      )
    : priceMonth;

function CalendarCustomerPriceRowMonth({
  classes,
  priceMonth,
  isBusiness,
  catalogRow,
  catalogExtraRowRental,
  reservation,
  discountPercent,
}: Props) {
  const { t } = useTranslation();

  const [priceMonthEdited, setPriceMonthEdited] = useState(
    getPriceMonthEdited(priceMonth, reservation)
  );

  const [updateReservation, { loading }] = useMutation<
    Mutation,
    MutationUpdateReservationArgs
  >(UPDATE_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservation(cache);
    },
  });

  useEffect(() => {
    setPriceMonthEdited(getPriceMonthEdited(priceMonth, reservation));
  }, [priceMonth, reservation]);

  const showDiscount = !!reservation;

  return (
    <tr className={loading ? "loading" : ""}>
      <th className={classes.th}>
        {t(
          isBusiness ? "price_month_company_vat0" : "price_month_private_vat0"
        )}
      </th>
      <td className={classes.td1} align="right">
        {discountPercent ? (
          <>
            <small>
              {t("currency", { amount: formatNumber(priceMonth, 2) })}
            </small>
            <div>
              {t("currency", {
                amount: formatNumber(
                  getPercentAmount(priceMonth, discountPercent),
                  2
                ),
              })}
            </div>
          </>
        ) : (
          t("currency", { amount: formatNumber(priceMonth, 2) })
        )}
      </td>
      {showDiscount && (
        <td className="ps-4">
          <FormControl className="m-0">
            <TextFieldFocus
              size="small"
              value={priceMonthEdited}
              onChange={(event) => {
                setPriceMonthEdited(event.target.value);
              }}
              onBlur={() => {
                const priceMonthEditedParsed = parseNumber(priceMonthEdited);
                const discountPercentMonth = getPercentDifference(
                  priceMonth,
                  priceMonthEditedParsed
                );
                setPriceMonthEdited(formatNumber(priceMonthEditedParsed, 2));

                updateReservation({
                  variables: {
                    reservationId: reservation.id,
                    discountPercentMonth: discountPercentMonth,
                  },
                });
              }}
            />
          </FormControl>
        </td>
      )}
      <td className={classes.tdSpacing}></td>
      {!catalogExtraRowRental && (
        <th className={classes.th}>{t("hour_limits")}</th>
      )}
      {!catalogExtraRowRental && (
        <td className={classes.td2}>
          {t("hour_limits_all", {
            limit_day: isBusiness
              ? catalogRow.hourLimitDayCompany
              : catalogRow.hourLimitDayPrivate,
            limit_month: isBusiness
              ? catalogRow.hourLimitMonthCompany
              : catalogRow.hourLimitMonthPrivate,
            extra_hours: formatNumber(
              isBusiness
                ? catalogRow.extraHoursCompany
                : catalogRow.extraHoursPrivate,
              2
            ),
          })}
        </td>
      )}
    </tr>
  );
}

const styles = (theme: Theme) => createStyles(CALENDAR_CUSTOMER_PRICE_ROW);

export default withStyles(styles)(CalendarCustomerPriceRowMonth);
