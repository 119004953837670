import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  Mutation,
  MutationMoveCatalogExtraRowRentalToLocationArgs,
  MutationMoveMachineToLocationArgs,
  MutationReturnPartialReservationArgs,
  MutationUpdateReservationsReturnAtArgs,
  MutationUpdateReservationStampsArgs,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { Autocomplete } from "@material-ui/lab";
import { ReservationEmpty } from "../../entity/empties";
import { getReservationProductName } from "../../utils/reservations/reservation";
import {
  DATE_FORMAT_ISO,
  ID_EMPTY,
  ROOT_QUERY,
  TIME_FORMAT_ISO,
} from "../../utils/constants";
import { useMutation } from "@apollo/client";
import {
  RETURN_PARTIAL_RESERVATION_MUTATION,
  UPDATE_RESERVATION_STAMPS_MUTATION,
  UPDATE_RESERVATIONS_RETURN_AT_MUTATION,
} from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import ButtonLoad from "../Shared/ButtonLoad";
import format from "date-fns/format";
import {
  getQueryKey,
  updateCacheReservationStampsMutation,
} from "../../utils/cache";
import LoadingDialog from "../Shared/LoadingDialog";
import Error from "../Shared/Error";
import { newDate } from "../../utils/dates";
import { MOVE_MACHINE_TO_LOCATION_MUTATION } from "../../apollo/mutations/machines";
import { MOVE_CATALOG_EXTRA_ROW_RENTAL_TO_LOCATION_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  ReservationFromForOrderType,
  ReservationFromReservationsQueueReturnType,
} from "../../apollo/queries/reservations";
import { removeFromSetById } from "../../utils/collections";
import ReservationBulkAmountReturned from "./ReservationBulkAmountReturned";
import { useGetLocationsQueryQuery } from "../../apollo/queries/locations.generated";
import { useGetReservationsForOrderQueryQuery } from "../../apollo/queries/reservations.generated";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservations?: ReservationFromReservationsQueueReturnType[];
  locationIdSelected: string;
  reservation?: ReservationFromReservationsQueueReturnType;
  returnAtForced?: string;
  returnAtTimeForced?: string;
  callbackOnUpdate?: (reservationId: string) => void;
}

function DialogReservationReturn({
  classes,
  open,
  setOpen,
  reservations,
  locationIdSelected,
  reservation = ReservationEmpty,
  returnAtForced,
  returnAtTimeForced,
  callbackOnUpdate,
}: Props) {
  const { t } = useTranslation();

  const hasForcedReturnAt: boolean = Boolean(returnAtForced);
  const hasForcedReturnAtTime: boolean = Boolean(returnAtTimeForced);

  const [reservationSelected, setReservationSelected] = useState(reservation);
  const [reservationsSelectedExtra, setReservationsSelectedExtra] = useState<
    ReservationFromForOrderType[]
  >([]);
  const [bulkReservationsSelectedExtra, setBulkReservationsSelectedExtra] =
    useState<ReservationFromForOrderType[]>([]);
  const [bulkAmountReturned, setBulkAmountReturned] = useState(
    reservationSelected.bulkAmount
  );
  const [bulkAmountReturnedExtra, setBulkAmountReturnedExtra] = useState<{
    [reservation_id: string]: number;
  }>({});
  const [locationId, setLocationId] = useState(locationIdSelected);
  const [returnAt, setReturnAt] = useState<string>(
    returnAtForced ? returnAtForced : format(new Date(), DATE_FORMAT_ISO)
  );
  const [returnAtTime, setReturnAtTime] = useState<string>(
    returnAtTimeForced
      ? returnAtTimeForced
      : format(new Date(), TIME_FORMAT_ISO)
  );

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useGetLocationsQueryQuery({
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  const {
    loading: loadingReservations,
    error: errorReservations,
    data: dataReservations,
  } = useGetReservationsForOrderQueryQuery({
    fetchPolicy: getQueryFetchPolicy("reservationsForOrder"),
    variables: {
      orderId: reservationSelected.order.id,
    },
    skip: reservationSelected.id === ID_EMPTY,
  });

  const [updateReservationStamps, { loading: loadingUpdateStamps }] =
    useMutation<Mutation, MutationUpdateReservationStampsArgs>(
      UPDATE_RESERVATION_STAMPS_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        onCompleted: () => {
          callbackStampsUpdated(reservationSelected);
        },
        update: (cache) => {
          updateCacheReservationStampsMutation(cache);
        },
      }
    );

  const [updateReservationsReturnAt, { loading: loadingUpdateReturnAt }] =
    useMutation<Mutation, MutationUpdateReservationsReturnAtArgs>(
      UPDATE_RESERVATIONS_RETURN_AT_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
        onCompleted: () => {
          reservationsSelectedExtra.forEach((reservationLooped) => {
            callbackStampsUpdated(reservationLooped);
          });
        },
        update: (cache) => {
          updateCacheReservationStampsMutation(cache);
        },
      }
    );

  const [moveMachineToLocation, { loading: loadingMoveMachine }] = useMutation<
    Mutation,
    MutationMoveMachineToLocationArgs
  >(MOVE_MACHINE_TO_LOCATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationsWorkQueues"),
      });
    },
  });

  const [moveCatalogExtraRowRentalToLocation, { loading: loadingMoveExtra }] =
    useMutation<Mutation, MutationMoveCatalogExtraRowRentalToLocationArgs>(
      MOVE_CATALOG_EXTRA_ROW_RENTAL_TO_LOCATION_MUTATION,
      {
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [returnPartialReservation] = useMutation<
    Mutation,
    MutationReturnPartialReservationArgs
  >(RETURN_PARTIAL_RESERVATION_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("order"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationsCalendar"),
      });
    },
  });

  const callbackClose = () => {
    setReservationSelected(ReservationEmpty);
    setOpen(false);
  };

  const callbackStampsUpdated = (
    reservationLooped: ReservationFromReservationsQueueReturnType
  ) => {
    if (
      reservationLooped.catalogExtraRowRental &&
      locationId !== reservationLooped.catalogExtraRowRental.location.id
    ) {
      moveCatalogExtraRowRentalToLocation({
        variables: {
          catalogExtraRowRentalId: reservationLooped.catalogExtraRowRental.id,
          locationId: locationId,
        },
      }).then(() => callbackClose());
    } else if (
      reservationLooped.machine &&
      (!reservationLooped.machine.location ||
        locationId !== reservationLooped.machine.location.id) &&
      !reservationLooped.machine?.bulkProduct
    ) {
      moveMachineToLocation({
        variables: {
          machineId: reservationLooped.machine.id,
          locationId: locationId,
        },
      }).then(() => callbackClose());
    } else {
      callbackClose();
    }
  };

  if (loadingLocations) return <LoadingDialog />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (errorReservations) return <Error error={errorReservations} />;
  if (!dataLocations) return <Error error={t("error_query_failed")} />;

  const handleUpdateStamps = () => {
    updateReservationStamps({
      variables: {
        reservationId: reservationSelected.id,
        returnAt: reservationSelected.returnAt
          ? null
          : newDate(returnAt + " " + returnAtTime),
      },
    });
    if (callbackOnUpdate) {
      callbackOnUpdate(reservationSelected.id);
    }
  };

  let options: ReservationFromReservationsQueueReturnType[] = [
    ReservationEmpty,
  ];
  if (reservations) {
    const reservationsSorted = [...reservations];

    reservationsSorted.sort((a, b) => {
      const aLocId = a.machine?.location ? a.machine.location.id : ID_EMPTY;
      const bLocId = b.machine?.location ? b.machine.location.id : ID_EMPTY;

      if (aLocId === bLocId && aLocId === locationId) {
        return 0;
      } else if (aLocId === locationId) {
        return -1;
      } else if (bLocId === locationId) {
        return 1;
      } else {
        return String(a.machine?.location?.name).localeCompare(
          String(b.machine?.location?.name)
        );
      }
    });

    options.push(...reservationsSorted);
  }

  const handleUpdateReservationsReturnAt = (
    reservationsSelected: ReservationFromForOrderType[]
  ) => {
    updateReservationsReturnAt({
      variables: {
        reservationIds: reservationsSelected.reduce(
          (arr, reservationLooped) => [...arr, reservationLooped.id],
          [] as string[]
        ),
        returnAt: newDate(returnAt + " " + returnAtTime),
      },
    });
  };

  const onClickBtn = () => {
    if (bulkAmountReturned >= reservationSelected.bulkAmount) {
      handleUpdateStamps();
    } else {
      returnPartialReservation({
        variables: {
          reservationId: reservationSelected.id,
          machineId: reservationSelected.machine
            ? reservationSelected.machine.id
            : ID_EMPTY,
          dateEnd: format(new Date(), DATE_FORMAT_ISO),
          bulkAmountReturned: bulkAmountReturned,
        },
      }).then(() => {
        handleUpdateStamps();
      });
    }

    if (reservationsSelectedExtra.length > 0) {
      handleUpdateReservationsReturnAt(reservationsSelectedExtra);
    }

    if (bulkReservationsSelectedExtra.length > 0) {
      bulkReservationsSelectedExtra.forEach((reservationLooped) => {
        const bulkAmountExtra =
          reservationLooped.id in bulkAmountReturnedExtra
            ? bulkAmountReturnedExtra[reservationLooped.id]
            : reservationLooped.bulkAmount;

        if (bulkAmountExtra >= reservationLooped.bulkAmount) {
          handleUpdateReservationsReturnAt([reservationLooped]);
        } else {
          returnPartialReservation({
            variables: {
              reservationId: reservationLooped.id,
              machineId: reservationLooped.machine
                ? reservationLooped.machine.id
                : ID_EMPTY,
              dateEnd: format(new Date(), DATE_FORMAT_ISO),
              bulkAmountReturned: bulkAmountExtra,
            },
          }).then(() => {
            handleUpdateReservationsReturnAt([reservationLooped]);
          });
        }
      });
    }
  };

  const getReservationText = (
    reservationLooped: ReservationFromReservationsQueueReturnType
  ) =>
    "#" +
    reservationLooped.id +
    " - " +
    getReservationProductName(t, reservationLooped, true) +
    (reservationLooped.machine?.location
      ? " (" + reservationLooped.machine.location.name + ")"
      : "") +
    (reservationLooped.machine?.bulkProduct
      ? " (" + reservationLooped.order.customer?.name + ")"
      : "");

  const reservationsForOrder: ReservationFromForOrderType[] =
    dataReservations?.reservationsForOrder
      ? dataReservations.reservationsForOrder.filter((reservationLooped) => {
          return (
            reservationLooped.id !== reservationSelected.id &&
            reservationLooped.giveAt &&
            !reservationLooped.returnAt
          );
        })
      : [];

  return (
    <Dialog open={open}>
      <DialogTitleWithClose
        id="dialogTitleReservationReturn"
        onClose={() => setOpen(false)}
      >
        {t("set_reservation_as_returned")}
      </DialogTitleWithClose>
      <DialogContent>
        {reservations && (
          <Autocomplete<ReservationFromReservationsQueueReturnType>
            className="mb-2"
            options={options}
            getOptionLabel={(reservationLooped) => {
              if (reservationLooped.id === ID_EMPTY) {
                return t("not_selected");
              } else {
                return getReservationText(reservationLooped);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("reservations")}
                variant="outlined"
              />
            )}
            value={reservationSelected}
            getOptionSelected={(a, b) => {
              return a.id === b.id;
            }}
            onChange={(event, reservationLooped) => {
              setReservationSelected(
                reservationLooped ? reservationLooped : ReservationEmpty
              );
              setReservationsSelectedExtra([]);
              setBulkReservationsSelectedExtra([]);

              if (
                reservationLooped &&
                reservationLooped.machine &&
                reservationLooped.machine.bulkProduct
              ) {
                setBulkAmountReturned(reservationLooped.bulkAmount);
              }
            }}
          />
        )}
        {reservationSelected.id !== ID_EMPTY && (
          <div className="mt-3 mb-2">
            <div className="d-flex">
              <FormControl fullWidth>
                <TextField
                  type="date"
                  label={t("date_returned")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setReturnAt(
                      event.target.value === ""
                        ? format(new Date(), DATE_FORMAT_ISO)
                        : event.target.value
                    );
                  }}
                  value={returnAt}
                  disabled={hasForcedReturnAt}
                />
              </FormControl>
              <FormControl fullWidth className="ms-3">
                <TextField
                  type="time"
                  label={t("time_returned")}
                  value={returnAtTime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(event) => {
                    setReturnAtTime(
                      event.target.value === "" ? "00:00" : event.target.value
                    );
                  }}
                  disabled={hasForcedReturnAtTime}
                />
              </FormControl>
            </div>
            {(hasForcedReturnAt || hasForcedReturnAtTime) && (
              <div className="mb-4 text-muted">
                {t("reservation_return_at_locked")}
              </div>
            )}
            {(reservationSelected.catalogExtraRowRental ||
              !reservationSelected.machine?.bulkProduct) && (
              <div>
                <FormControl fullWidth>
                  <InputLabel id="lblReservationReturnLocation">
                    {t("location")}
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="lblReservationReturnLocation"
                    value={locationId}
                    onChange={(event) => {
                      setLocationId(String(event.target.value));
                    }}
                  >
                    {dataLocations.locations?.map((location) => (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {!reservationSelected.catalogExtraRowRental &&
              reservationSelected.machine?.bulkProduct && (
                <ReservationBulkAmountReturned
                  reservation={reservationSelected}
                  bulkAmount={bulkAmountReturned}
                  callbackSetBulkAmount={(bulkAmountNew) =>
                    setBulkAmountReturned(bulkAmountNew)
                  }
                />
              )}
            <div className={loadingReservations ? "loading" : ""}>
              {reservationsForOrder.length > 0 && (
                <div className="mt-2 mb-3">
                  <div>
                    <b>{t("question_return_reservations")}</b>
                  </div>
                  {reservationsForOrder.map((reservationForOrder) => {
                    const isBulk =
                      !reservationForOrder.catalogExtraRowRental &&
                      reservationForOrder.machine?.bulkProduct;
                    const checked: boolean = (
                      isBulk
                        ? bulkReservationsSelectedExtra
                        : reservationsSelectedExtra
                    ).some(
                      (reservationLooped) =>
                        reservationLooped.id === reservationForOrder.id
                    );
                    return (
                      <div key={reservationForOrder.id}>
                        <FormControlLabel
                          label={getReservationText(reservationForOrder)}
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={() => {
                                if (checked) {
                                  if (isBulk) {
                                    setBulkReservationsSelectedExtra(
                                      removeFromSetById(
                                        reservationForOrder.id,
                                        bulkReservationsSelectedExtra
                                      )
                                    );
                                  } else {
                                    setReservationsSelectedExtra(
                                      removeFromSetById(
                                        reservationForOrder.id,
                                        reservationsSelectedExtra
                                      )
                                    );
                                  }
                                } else {
                                  if (isBulk) {
                                    setBulkReservationsSelectedExtra([
                                      ...bulkReservationsSelectedExtra,
                                      reservationForOrder,
                                    ]);
                                  } else {
                                    setReservationsSelectedExtra([
                                      ...reservationsSelectedExtra,
                                      reservationForOrder,
                                    ]);
                                  }
                                }
                              }}
                            />
                          }
                        />
                        {isBulk && checked && (
                          <ReservationBulkAmountReturned
                            reservation={reservationForOrder}
                            bulkAmount={
                              bulkAmountReturnedExtra.hasOwnProperty(
                                reservationForOrder.id
                              )
                                ? bulkAmountReturnedExtra[
                                    reservationForOrder.id
                                  ]
                                : reservationForOrder.bulkAmount
                            }
                            callbackSetBulkAmount={(bulkAmountNew) => {
                              const valueNew = {
                                ...bulkAmountReturnedExtra,
                              };
                              valueNew[reservationForOrder.id] = bulkAmountNew;
                              setBulkAmountReturnedExtra(valueNew);
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="mt-1">
              <ButtonLoad
                loading={
                  loadingUpdateStamps ||
                  loadingUpdateReturnAt ||
                  loadingMoveMachine ||
                  loadingMoveExtra
                }
                variant="primary"
                onClick={onClickBtn}
              >
                {t("set_reservation_as_returned")}
              </ButtonLoad>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogReservationReturn);
