import React, { useContext } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import {
  CatalogExtraRowRentalType,
  MachineType,
  MaintenanceMaintenanceIntervalIntervalTypeChoices,
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  MaintenanceSettingType,
  Mutation,
  MutationUpdateMaintenanceSettingsArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { CatalogExtraRowRentalEmpty, MachineEmpty } from "../../entity/empties";
import {
  getCatalogExtraRowRentalName,
  getMachineName,
} from "../../utils/machines/machine";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { WithStyles } from "@material-ui/core/styles";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { DATE_FORMAT_ISO, ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_MAINTENANCE_SETTINGS_MUTATION } from "../../apollo/mutations/maintenances";
import MaintenanceIntervalRow from "./MaintenanceIntervalRow";
import MaintenanceEstimatedHours from "./MaintenanceEstimatedHours";
import { parseNumber } from "../../utils/formatting";
import format from "date-fns/format";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  settingsEdit: MaintenanceSettingType;
  setSettingsEdit: Function;
  catalogExtraRowRental?: CatalogExtraRowRentalType;
  machine?: MachineType;
}

function DialogMaintenanceSettings({
  classes,
  open,
  setOpen,
  settingsEdit,
  setSettingsEdit,
  machine = MachineEmpty,
  catalogExtraRowRental = CatalogExtraRowRentalEmpty,
}: Props) {
  const { t } = useTranslation();

  const [updateMaintenanceSettings, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMaintenanceSettingsArgs
  >(UPDATE_MAINTENANCE_SETTINGS_MUTATION, {
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("maintenanceSettingsForMachine"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "maintenance.change_maintenancesetting",
  ]);

  const onClickClose = () => {
    setOpen(false);
  };

  const handleUpdate = (
    values: Partial<MutationUpdateMaintenanceSettingsArgs> = {}
  ) => {
    const base: MutationUpdateMaintenanceSettingsArgs = {
      maintenanceSettingId: settingsEdit.id,
      estimatedHoursPerDay: settingsEdit.estimatedHoursPerDay,
      scheduleType: settingsEdit.scheduleType,
      startDate: settingsEdit.startDate,
    };
    updateMaintenanceSettings({
      variables: { ...base, ...values },
    });
  };

  const handleEstimatedHours = (value: number) => {
    setSettingsEdit({
      ...settingsEdit,
      estimatedHoursPerDay: parseNumber(value, 2),
    });
  };

  const handleStartDate = (startDate: string) => {
    setSettingsEdit({
      ...settingsEdit,
      startDate: format(new Date(startDate), DATE_FORMAT_ISO),
    });
    handleUpdate();
  };

  let numberOfNormalRows = 1;

  const title =
    machine.id !== ID_EMPTY
      ? getMachineName(machine)
      : getCatalogExtraRowRentalName(catalogExtraRowRental);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth={
        settingsEdit.scheduleType ===
        MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays
          ? "lg"
          : "md"
      }
    >
      <DialogTitleWithClose
        id="dialogTitleMaintenanceSettings"
        onClose={onClickClose}
      >
        {t("maintenance_settings")}{" "}
        <small className="text-muted ms-3">{title}</small>
      </DialogTitleWithClose>
      <DialogContent className={loadingUpdate ? "loading" : ""}>
        <FormControl fullWidth>
          <InputLabel id="lblMaintenanceScheduleType">
            {t("maintenance_schedule_type")}
          </InputLabel>
          <Select
            autoWidth
            labelId="lblMaintenanceScheduleType"
            value={settingsEdit.scheduleType}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              handleUpdate({
                scheduleType:
                  typeof event.target.value === "string"
                    ? event.target.value
                    : MaintenanceMaintenanceSettingScheduleTypeChoices.None,
              });
            }}
          >
            <MenuItem
              value={MaintenanceMaintenanceSettingScheduleTypeChoices.None}
            >
              {t(
                "maintenance_setting_schedule_" +
                  MaintenanceMaintenanceSettingScheduleTypeChoices.None
              )}
            </MenuItem>
            {machine.id !== ID_EMPTY && (
              <MenuItem
                value={MaintenanceMaintenanceSettingScheduleTypeChoices.Hours}
              >
                {t(
                  "maintenance_setting_schedule_" +
                    MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
                )}
              </MenuItem>
            )}
            {machine.id !== ID_EMPTY && (
              <MenuItem
                value={
                  MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays
                }
              >
                {t(
                  "maintenance_setting_schedule_" +
                    MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays
                )}
              </MenuItem>
            )}
            <MenuItem
              value={MaintenanceMaintenanceSettingScheduleTypeChoices.Days}
            >
              {t(
                "maintenance_setting_schedule_" +
                  MaintenanceMaintenanceSettingScheduleTypeChoices.Days
              )}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            type="date"
            label={t("maintenance_start_date")}
            value={settingsEdit.startDate}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!hasPermissionEdit}
            onChange={(event) => {
              handleStartDate(event.currentTarget.value);
              handleUpdate({
                startDate: event.currentTarget.value,
              });
            }}
          />
        </FormControl>
        {settingsEdit.scheduleType !==
          MaintenanceMaintenanceSettingScheduleTypeChoices.None && (
          <div className="mt-3">
            <h5>{t("maintenance_scheduling")}</h5>
            {settingsEdit.maintenanceintervalSet.map(
              (maintenanceInterval, index, inputArray) => {
                let showAddRow = false;
                if (inputArray[index + 1]) {
                  showAddRow =
                    inputArray[index + 1].intervalType ===
                    MaintenanceMaintenanceIntervalIntervalTypeChoices.Last;
                  if (
                    inputArray[index + 1].intervalType ===
                    MaintenanceMaintenanceIntervalIntervalTypeChoices.Normal
                  ) {
                    numberOfNormalRows++;
                  }
                }
                return (
                  <MaintenanceIntervalRow
                    key={maintenanceInterval.id}
                    maintenanceSetting={settingsEdit}
                    maintenanceInterval={maintenanceInterval}
                    showAddRow={showAddRow}
                    numberOfNormalRows={numberOfNormalRows}
                  />
                );
              }
            )}
          </div>
        )}
        {settingsEdit.scheduleType !==
          MaintenanceMaintenanceSettingScheduleTypeChoices.Days && (
          <MaintenanceEstimatedHours
            estimatedHoursPerDay={settingsEdit.estimatedHoursPerDay}
            disabled={!hasPermissionEdit}
            setStateVariable={handleEstimatedHours}
            saveStateVariable={handleUpdate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    info: {
      color: palette.secondary.main,
    },
    textContainer: {
      position: "relative",
      height: "1em",
      width: "9em",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
  });

export default withStyles(styles)(DialogMaintenanceSettings);
