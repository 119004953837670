import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import { UserType } from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import {
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import CommissionPreviewForUser from "./CommissionPreviewForUser";
import { formatNumber, parseNumber } from "../../utils/formatting";
import withStyles from "@material-ui/core/styles/withStyles";
import { WithStyles } from "@material-ui/core/styles";
import { CommissionUserSettingsByUserType } from "../../pages/ManagementCommissions";
import ButtonLoad from "../Shared/ButtonLoad";
import TextFieldMonth from "../Shared/TextFieldMonth";
import TextFieldYear from "../Shared/TextFieldYear";
import { yearMonthToDateDayFirst } from "../../utils/dates";

interface Props extends WithStyles<typeof styles> {
  users: UserType[];
  commissionUserSettingsByUser: CommissionUserSettingsByUserType;
}

function CommissionReportRealtime({
  classes,
  users,
  commissionUserSettingsByUser,
}: Props) {
  const { t } = useTranslation();

  const yearCurrent = new Date().getFullYear();
  const [yearFrom, setYearFrom] = useState<number>(yearCurrent);
  const [yearTo, setYearTo] = useState<number>(yearCurrent);

  const monthDefault = new Date().getMonth();
  const [monthFrom, setMonthFrom] = useState<number>(monthDefault);
  const [monthTo, setMonthTo] = useState<number>(monthDefault);

  const [timestampSearched, setTimestampSearched] = useState(0);
  const [loading, setLoading] = useState(false);

  const [userIdsSelected, setUserIdsSelected] = useState<string[]>([]);
  const [usersSelected, setUsersSelected] = useState<UserType[]>([]);

  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);

  const addToRevenue = useCallback(
    (result) => {
      setTotalRevenue((prev) => prev + result);
    },
    [setTotalRevenue]
  );

  const addToCommission = useCallback(
    (result) => {
      setTotalCommission((prev) => prev + result);
    },
    [setTotalCommission]
  );

  useEffect(() => {
    setUsersSelected(users.filter((user) => userIdsSelected.includes(user.id)));
    // eslint-disable-next-line
  }, [timestampSearched]);

  const hookFromChanged = (year: number, month: number) => {
    const dateFrom = yearMonthToDateDayFirst(year, month);
    const dateTo = yearMonthToDateDayFirst(yearTo, monthTo);
    if (dateFrom > dateTo) {
      setYearTo(year);
      setMonthTo(month);
    }
  };
  const hookToChanged = (year: number, month: number) => {
    const dateFrom = yearMonthToDateDayFirst(yearFrom, monthFrom);
    const dateTo = yearMonthToDateDayFirst(year, month);
    if (dateFrom > dateTo) {
      setYearFrom(year);
      setMonthFrom(month);
    }
  };

  return (
    <div>
      <Row>
        <Col sm={6} md={6} lg={3} className="mb-2">
          <div className="d-flex">
            <div className="w-50 pe-2">
              <FormControl fullWidth>
                <TextFieldYear
                  label={t("year_from")}
                  value={yearFrom}
                  inputProps={{ max: yearCurrent }}
                  onChange={(event) => {
                    const yearFromNew = parseNumber(event.target.value);
                    setYearFrom(yearFromNew);
                    hookFromChanged(yearFromNew, monthFrom);
                  }}
                />
              </FormControl>
            </div>
            <div className="w-50 pe-2">
              <FormControl fullWidth>
                <TextFieldMonth
                  label={t("month_from")}
                  value={monthFrom}
                  onChange={(event) => {
                    const monthFromNew = parseNumber(event.target.value);
                    setMonthFrom(monthFromNew);
                    hookFromChanged(yearFrom, monthFromNew);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </Col>
        <Col sm={6} md={6} lg={3} className="mb-2">
          <div className="d-flex">
            <div className="w-50 pe-2">
              <FormControl fullWidth>
                <TextFieldYear
                  label={t("year_to")}
                  value={yearTo}
                  inputProps={{ max: yearCurrent }}
                  onChange={(event) => {
                    const yearToNew = parseNumber(event.target.value);
                    setYearTo(yearToNew);
                    hookToChanged(yearToNew, monthTo);
                  }}
                />
              </FormControl>
            </div>
            <div className="w-50 pe-2">
              <FormControl fullWidth>
                <TextFieldMonth
                  label={t("month_to")}
                  value={monthTo}
                  onChange={(event) => {
                    const monthToNew = parseNumber(event.target.value);
                    setMonthTo(monthToNew);
                    hookToChanged(yearTo, monthToNew);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </Col>
        <Col sm={6} md={6} lg={3} className="mb-2">
          <FormControl fullWidth>
            <InputLabel id="lblUserListDataType">{t("user")}</InputLabel>
            <Select
              autoWidth
              labelId="lblUserListFilter"
              value={userIdsSelected}
              multiple={true}
              onChange={(event) => {
                setUserIdsSelected(event.target.value as string[]);
              }}
            >
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.firstName + " " + user.lastName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Col>
        <Col sm={6} md={6} lg={3} className="mb-2">
          <ButtonLoad
            loading={loading}
            className="mt-2"
            onClick={() => {
              setLoading(true);
              setTimestampSearched(Date.now());
              window.setTimeout(() => {
                setLoading(false);
              }, 1500);
            }}
          >
            {t("search")}
          </ButtonLoad>
        </Col>
      </Row>
      {usersSelected.map((userSelected) => {
        return (
          <CommissionPreviewForUser
            key={userSelected.id}
            userSelected={userSelected}
            commissionUserSetting={
              commissionUserSettingsByUser[userSelected.id]
            }
            yearFrom={yearFrom}
            monthFrom={monthFrom}
            yearTo={yearTo}
            monthTo={monthTo}
            addToRevenue={addToRevenue}
            addToCommission={addToCommission}
            timestampSearched={timestampSearched}
          />
        );
      })}
      <div className="mt-4">
        <b className={classes.total}>
          {t("revenues_total", {
            total: t("currency", {
              amount: formatNumber(totalRevenue, 2),
            }),
          })}
        </b>
      </div>
      <div className="mt-1">
        <b className={classes.total}>
          {t("commissions_total", {
            total: t("currency", {
              amount: formatNumber(totalCommission, 2),
            }),
          })}
        </b>
      </div>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    total: {
      fontSize: "1rem",
    },
  });

export default withStyles(styles)(CommissionReportRealtime);
