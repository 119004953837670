import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Dialog, DialogContent, Theme } from "@material-ui/core";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import { ReservationType } from "../../entity/types";
import WorkQueueCardRenting from "./WorkQueueCardRenting";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: ReservationType;
}

function DialogReservationWizard({
  classes,
  open,
  setOpen,
  reservation,
}: Props) {
  return (
    <Dialog open={open}>
      <DialogTitleWithClose
        id="dialogTitleReservationWizard"
        onClose={() => setOpen(false)}
      >
        #{reservation.id}
      </DialogTitleWithClose>
      <DialogContent>
        <div className="conWorkQueue">
          <WorkQueueCardRenting reservation={reservation} type="return" />
        </div>
      </DialogContent>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogReservationWizard);
