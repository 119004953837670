import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { useQuery } from "@apollo/client";
import { createStyles, Theme } from "@material-ui/core";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import {
  GET_CUSTOMER_RANKS_QUERY,
  QueryResultCustomerRanks,
} from "../apollo/queries/customers";
import CustomerRankList from "../components/CustomerRank/CustomerRankList";
import CreateCustomerRank from "../components/CustomerRank/CreateCustomerRank";

interface Props extends WithStyles<typeof styles> {}

function SettingsCustomerRanks({ classes }: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryResultCustomerRanks>(
    GET_CUSTOMER_RANKS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("customerRanks"),
    }
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container className="containerMain">
      <CreateCustomerRank />
      <CustomerRankList customerRanks={data.customerRanks} />
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(SettingsCustomerRanks);
