import { gql } from "@apollo/client";
import { Query } from "../../entity/types";

export interface QueryResultProductCodes {
  productCodes: Query["productCodes"];
}
export const GET_PRODUCT_CODES_QUERY = gql`
  query getProductCodesQuery {
    productCodes {
      id
      code
      description
      costcenter {
        id
      }
      costtype {
        id
      }
    }
  }
`;

export interface QueryResultProductCode {
  productCode: Query["productCode"];
}
export const GET_PRODUCT_CODE_QUERY = gql`
  query getProductCodeQuery($id: ID!) {
    productCode(id: $id) {
      id
      code
      description
    }
  }
`;
