import React, { useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Container } from "react-bootstrap";
import DiscountTable from "../components/Discount/table/DiscountTable";
import { updateVisibility } from "../utils/discounts/search";
import { useQuery } from "@apollo/client";
import { QueryCustomerArgs, QueryDiscountListArgs } from "../entity/types";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import {
  GET_DISCOUNT_LIST_QUERY,
  QueryResultDiscountList,
} from "../apollo/queries/discounts";
import { CustomerEmpty, DiscountListEmpty } from "../entity/empties";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import {
  GET_CUSTOMER_QUERY,
  QueryResultCustomer,
} from "../apollo/queries/customers";
import { ID_EMPTY } from "../utils/constants";
import { getUrlCustomerTab } from "../utils/urls";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";

interface Props extends WithStyles<typeof styles> {}

function CustomerOfferShow({ classes }: Props) {
  const { t } = useTranslation();
  let customer_id: string, discount_list_id: string;
  ({ customer_id, discount_list_id } = useParams());

  const [customer, setCustomer] = useState(CustomerEmpty);
  const [discountList, setDiscountList] = useState(DiscountListEmpty);

  const { loading: loadingDiscountList, error: errorDiscountList } = useQuery<
    QueryResultDiscountList,
    QueryDiscountListArgs
  >(GET_DISCOUNT_LIST_QUERY, {
    fetchPolicy: getQueryFetchPolicy("discountList"),
    variables: {
      discountListId: discount_list_id,
    },
    onCompleted: (data) => {
      if (data.discountList) {
        setDiscountList(data.discountList);
      }
    },
  });

  const { loading: loadingCustomer, error: errorCustomer } = useQuery<
    QueryResultCustomer,
    QueryCustomerArgs
  >(GET_CUSTOMER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("customer"),
    variables: {
      id: customer_id,
    },
    onCompleted: (data) => {
      if (data.customer) {
        setCustomer(data.customer);
      }
    },
  });

  const [search, setSearch] = useState("");
  const [catalogCategoriesUpperVisible, setCatalogCategoriesUpperVisible] =
    useState<{
      [key: string]: boolean;
    }>({});
  const [catalogCategoriesVisible, setCatalogCategoriesVisible] = useState<{
    [key: string]: boolean;
  }>({});
  const [catalogRowsVisible, setCatalogRowsVisible] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    let {
      catalogCategoryUpperVisible,
      catalogCategoryVisible,
      catalogRowVisible,
    } = updateVisibility(discountList.catalog, search);
    setCatalogCategoriesUpperVisible(catalogCategoryUpperVisible);
    setCatalogCategoriesVisible(catalogCategoryVisible);
    setCatalogRowsVisible(catalogRowVisible);
  }, [discountList, search]);

  if (loadingDiscountList || loadingCustomer) return <Loading />;
  if (errorDiscountList) return <Error error={errorDiscountList} />;
  if (errorCustomer) return <Error error={errorCustomer} />;

  if (customer.id === ID_EMPTY || discountList.id === ID_EMPTY) {
    return <Error error={t("error_discount_list_not_found")} />;
  }
  if (
    discountList.customer &&
    discountList.customer.id !== customer.id &&
    !discountList.customer.customersChildren.some(
      (customerLooped) => customerLooped.id === customer.id
    )
  ) {
    return <Error error={t("error_discount_list_not_found")} />;
  }

  return (
    <Container className="containerMain">
      <h2 className="headerPage">
        <LinkContainer to={getUrlCustomerTab(customer.id, "events")}>
          <Button variant="outline-light" title={t("back_to_customer_events")}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </LinkContainer>
        &nbsp;
        {discountList.name}
      </h2>
      <div className="containerInner">
        <DiscountTable
          catalog={discountList.catalog}
          discountList={discountList}
          setDiscountList={setDiscountList}
          search={search}
          setSearch={setSearch}
          catalogRowsVisible={catalogRowsVisible}
          catalogCategoriesVisible={catalogCategoriesVisible}
          catalogCategoriesUpperVisible={catalogCategoriesUpperVisible}
          customer={customer}
        />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CustomerOfferShow);
