import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  Box,
  createStyles,
  DialogContent,
  Tab,
  Theme,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { QueryMachineArgs } from "../../../entity/types";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { getMachineName } from "../../../utils/machines/machine";
import DialogTitleWithClose from "../../Shared/DialogTitleWithClose";
import { TabContext, TabList } from "@material-ui/lab";
import TabMachineInfoMaintenance from "./TabMachineCardMaintenance";
import TabMachineInfoHoursInfos from "./TabMachineCardHoursInfos";
import TabMachineInfoReservations from "./TabMachineCardReservations";
import TabMachineInfoTypePlate from "./TabMachineCardTypePlate";
import { useQuery } from "@apollo/client";
import {
  GET_MACHINE_QUERY,
  QueryResultMachine,
} from "../../../apollo/queries/machines";
import { getQueryFetchPolicy } from "../../../utils/getQueryFetchPolicy";
import Error from "../../Shared/Error";
import LoadingDialog from "../../Shared/LoadingDialog";
import TabMachineInfoPurchase from "./TabMachineCardPurchase";
import TabMachineInfoFinancing from "./TabMachineCardFinancing";
import TabMachineCheckList from "./TabMachineCheckList";

export enum ProductCardTabs {
  MachineInfo = "MachineInfo",
  Maintenance = "Maintenance",
  Reservations = "Reservations",
  TypePlate = "TypePlate",
  PurchaseInfo = "PurchaseInfo",
  FinancingInfo = "FinancingInfo",
  CheckList = "CheckList",
}

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  machineId: string;
}

function DialogMachineProductCard({
  classes,
  open,
  setOpen,
  machineId,
}: Props) {
  const { t } = useTranslation();

  const [tabSelected, setTabSelected] = useState<ProductCardTabs>(
    ProductCardTabs.MachineInfo
  );
  const [loadedTabs, setLoadedTabs] = useState<ProductCardTabs[]>([
    ProductCardTabs.MachineInfo,
  ]);

  const { loading, error, data } = useQuery<
    QueryResultMachine,
    QueryMachineArgs
  >(GET_MACHINE_QUERY, {
    variables: {
      machineId: machineId,
    },
    fetchPolicy: getQueryFetchPolicy("machine"),
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewMachine = checkPermission(myPermissions, [
    "machines.view_machine",
  ]);
  const hasPermissionViewMachineInfo = checkPermission(myPermissions, [
    "machines.view_machineinfocategory",
  ]);
  const hasPermissionViewServiceMaintenance = checkPermission(myPermissions, [
    "maintenance.view_maintenance",
  ]);
  const hasPermissionViewReservations = checkPermission(myPermissions, [
    "reservations.view_reservation",
  ]);
  const hasPermissionViewMachinePurchaseInfo = checkPermission(myPermissions, [
    "machines.show_machine_purchase_info",
  ]);
  const hasPermissionViewMachineFinancingInfo = checkPermission(myPermissions, [
    "machines.show_machine_financing_info",
  ]);

  if (loading) return <LoadingDialog />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const machine = data.machine;

  const onClickClose = () => {
    setOpen(false);
  };

  const handleTabClick = (tabValue: ProductCardTabs) => {
    if (!loadedTabs.includes(tabValue)) {
      setLoadedTabs([...loadedTabs, tabValue]);
    }
    setTabSelected(tabValue);
  };

  let tabsA: JSX.Element[] = [];
  let tabsB: JSX.Element[] = [];
  let panels: (false | JSX.Element)[] = [];

  if (hasPermissionViewMachine && hasPermissionViewMachineInfo) {
    tabsA.push(
      <Tab
        key={ProductCardTabs.MachineInfo}
        label={t("machine_information")}
        onClick={() => handleTabClick(ProductCardTabs.MachineInfo)}
        value={ProductCardTabs.MachineInfo}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.MachineInfo) && (
        <TabMachineInfoHoursInfos
          key={ProductCardTabs.MachineInfo}
          machine={machine}
        />
      )
    );
  }

  if (hasPermissionViewServiceMaintenance) {
    tabsA.push(
      <Tab
        key={ProductCardTabs.Maintenance}
        label={t("maintenances_and_breakdowns")}
        onClick={() => handleTabClick(ProductCardTabs.Maintenance)}
        value={ProductCardTabs.Maintenance}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.Maintenance) && (
        <TabMachineInfoMaintenance
          key={ProductCardTabs.Maintenance}
          machine={machine}
        />
      )
    );
  }

  if (hasPermissionViewReservations) {
    tabsA.push(
      <Tab
        key={ProductCardTabs.Reservations}
        label={t("reservations")}
        onClick={() => handleTabClick(ProductCardTabs.Reservations)}
        value={ProductCardTabs.Reservations}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.Reservations) && (
        <TabMachineInfoReservations
          key={ProductCardTabs.Reservations}
          machine={machine}
        />
      )
    );
  }

  if (hasPermissionViewMachine) {
    tabsB.push(
      <Tab
        key={ProductCardTabs.TypePlate}
        label={t("type_plate")}
        onClick={() => handleTabClick(ProductCardTabs.TypePlate)}
        value={ProductCardTabs.TypePlate}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.TypePlate) && (
        <TabMachineInfoTypePlate
          key={ProductCardTabs.TypePlate}
          machine={machine}
        />
      )
    );
  }

  if (hasPermissionViewMachinePurchaseInfo) {
    tabsB.push(
      <Tab
        key={ProductCardTabs.PurchaseInfo}
        label={t("machine_purchase_information")}
        onClick={() => handleTabClick(ProductCardTabs.PurchaseInfo)}
        value={ProductCardTabs.PurchaseInfo}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.PurchaseInfo) && (
        <TabMachineInfoPurchase
          key={ProductCardTabs.PurchaseInfo}
          machine={machine}
        />
      )
    );
  }

  if (hasPermissionViewMachineFinancingInfo) {
    tabsB.push(
      <Tab
        key={ProductCardTabs.FinancingInfo}
        label={t("machine_financing_information")}
        onClick={() => handleTabClick(ProductCardTabs.FinancingInfo)}
        value={ProductCardTabs.FinancingInfo}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.FinancingInfo) && (
        <TabMachineInfoFinancing
          key={ProductCardTabs.FinancingInfo}
          machine={machine}
        />
      )
    );
  }

  if (hasPermissionViewMachine) {
    tabsB.push(
      <Tab
        key={ProductCardTabs.CheckList}
        label={t("machine_check_list")}
        onClick={() => handleTabClick(ProductCardTabs.CheckList)}
        value={ProductCardTabs.CheckList}
      />
    );
    panels.push(
      loadedTabs.includes(ProductCardTabs.CheckList) && (
        <TabMachineCheckList
          key={ProductCardTabs.CheckList}
          machine={machine}
        />
      )
    );
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitleWithClose id="dialogTitleMachineInfo" onClose={onClickClose}>
        {t("product_card")}{" "}
        <small className="text-muted ms-3">
          {getMachineName(machine)}, {machine.identifier} ({machine.yearModel}){" "}
          {machine.serial}
        </small>
      </DialogTitleWithClose>
      <DialogContent>
        <TabContext value={tabSelected}>
          <Box>
            <TabList variant="fullWidth">{tabsA}</TabList>
            <TabList variant="fullWidth">{tabsB}</TabList>
          </Box>
          {panels}
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickClose} variant="secondary">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogMachineProductCard);
