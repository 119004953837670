import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogCategoryUpperType,
  CatalogType,
  Mutation,
  MutationCreateCatalogCategoryUpperArgs,
  MutationUpdateCatalogCategoryUpperArgs,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/client";
import {
  CREATE_CATALOG_CATEGORY_UPPER_MUTATION,
  UPDATE_CATALOG_CATEGORY_UPPER_MUTATION,
} from "../../apollo/mutations/catalogs";
import { CatalogCategoryUpperEmpty } from "../../entity/empties";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentCatalogCategoryUpper from "./DialogContentCatalogCategoryUpper";
import { ID_EMPTY } from "../../utils/constants";
import { updateCacheCatalogEdit } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  catalogCategoryUpperOrig: CatalogCategoryUpperType;
}

function DialogCatalogCategoryUpper({
  classes,
  catalog,
  open,
  setOpen,
  catalogCategoryUpperOrig,
}: Props) {
  const { t } = useTranslation();
  const [catalogCategoryUpper, setCatalogCategoryUpper] =
    useState<CatalogCategoryUpperType>(catalogCategoryUpperOrig);

  const [createCatalogCategoryUpper, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCatalogCategoryUpperArgs
  >(CREATE_CATALOG_CATEGORY_UPPER_MUTATION, {
    onCompleted: (result) => {
      setCatalogCategoryUpper(CatalogCategoryUpperEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  const [updateCatalogCategoryUpper, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogCategoryUpperArgs
  >(UPDATE_CATALOG_CATEGORY_UPPER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmitCatalog = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (catalogCategoryUpper.id !== ID_EMPTY) {
      updateCatalogCategoryUpper({
        variables: {
          catalogCategoryUpperId: catalogCategoryUpper.id,
          name: catalogCategoryUpper.name,
        },
      });
    } else {
      createCatalogCategoryUpper({
        variables: {
          catalogId: catalog.id,
          name: catalogCategoryUpper.name,
        },
      });

      setOpen(false);
    }
  };

  return (
    <Dialog open={open}>
      <form
        className={classes.form}
        onSubmit={(event) => handleSubmitCatalog(event)}
      >
        <DialogTitle>{t("new_catalog_category_upper")}</DialogTitle>
        <DialogContentCatalogCategoryUpper
          catalogCategoryUpper={catalogCategoryUpper}
          setCatalogCategoryUpper={setCatalogCategoryUpper}
        />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingCreate || loadingUpdate}
            disabled={!catalogCategoryUpper.name.trim()}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: spacing(2),
    },
  });

export default withStyles(styles)(DialogCatalogCategoryUpper);
