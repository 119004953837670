import { confirmAlert } from "react-confirm-alert";
import { Button } from "react-bootstrap";
import { TFunction } from "react-i18next";
import { handleError } from "../entity/ErrorHandler";

export const dialogConfirm = (
  t: TFunction<"translation">,
  message: string,
  callbackConfirm: Function,
  callbackCancel?: Function
) => {
  confirmAlert({
    closeOnClickOutside: false,
    customUI: ({ onClose }) => (
      <div className="react-confirm-alert-body">
        <h2>{t("title_confirm")}</h2>
        <p className="white-space-pre-line">{message}</p>
        <div className="react-confirm-alert-button-group">
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
              if (callbackCancel) callbackCancel();
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              onClose();
              callbackConfirm();
            }}
          >
            {t("yes")}
          </Button>
        </div>
      </div>
    ),
  });
};

export const popNewWindow = (t: TFunction<"translation">, url: string) => {
  const win = window.open(url, "_blank");
  if (win) {
    win.focus();
  } else {
    handleError(t("popups_not_allowed"));
  }
};
