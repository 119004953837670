import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineInfoCategoryType,
  MachinesMachineInfoQuestionAnswerTypeChoices,
  MachineInfoQuestionType,
  MachineInfoTagType,
} from "../../entity/types";
import CreateMachineInfoQuestion from "./CreateMachineInfoQuestion";
import MachineInformationQuestion from "./MachineInformationQuestion";
import MachineInformationQuestionOptions from "./MachineInformationQuestionOptions";

interface Props extends WithStyles<typeof styles> {
  machineInfoCategory: MachineInfoCategoryType;
  machineInfoQuestions: MachineInfoQuestionType[];
  machineInfoTags: MachineInfoTagType[];
}

function MachineInformationCategoryQuestions({
  classes,
  machineInfoCategory,
  machineInfoQuestions,
  machineInfoTags,
}: Props) {
  return (
    <>
      <tbody>
        {machineInfoQuestions.map((machineInfoQuestion) => (
          <React.Fragment key={machineInfoQuestion.id}>
            <MachineInformationQuestion
              machineInfoTags={machineInfoTags}
              machineInfoQuestion={machineInfoQuestion}
            />
            {machineInfoQuestion.answerType ===
              MachinesMachineInfoQuestionAnswerTypeChoices.Options && (
              <MachineInformationQuestionOptions
                machineInfoQuestion={machineInfoQuestion}
              />
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            <CreateMachineInfoQuestion
              machineInfoCategory={machineInfoCategory}
            />
          </td>
        </tr>
      </tfoot>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineInformationCategoryQuestions);
