import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  MachineType,
  MaintenanceType,
  NextMaintenanceType,
} from "../../entity/types";
import { MaintenanceSettingTypeEmpty } from "../../entity/empties";
import MaintenanceHistory from "./MaintenanceHistory";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  maintenances: MaintenanceType[];
  nextMaintenance: NextMaintenanceType;
  breakdowns: MachineBreakdownType[];
}

function MaintenanceHistoryMachine({
  classes,
  machine,
  maintenances,
  nextMaintenance,
  breakdowns,
}: Props) {
  return (
    <MaintenanceHistory
      maintenances={maintenances}
      maintenanceSettings={
        machine?.maintenancesetting
          ? machine.maintenancesetting
          : MaintenanceSettingTypeEmpty!
      }
      breakdowns={breakdowns}
      nextMaintenance={nextMaintenance}
    />
  );
}

const styles = ({ palette }: Theme) => createStyles({});

export default withStyles(styles)(MaintenanceHistoryMachine);
