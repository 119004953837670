import React, { useContext, useEffect } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  DialogContent,
  FormControl,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  CatalogExtraRowRentalType,
  MachineType,
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  MaintenanceType,
  QueryCatalogExtraRowsRentalByLocationsArgs,
  QueryMachinesForLocationsWithMaintenanceSettingsArgs,
} from "../../entity/types";
import { useLazyQuery, useQuery } from "@apollo/client";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import {
  CatalogExtraRowRentalEmpty,
  MachineEmpty,
  MaintenanceIntervalTypeEmpty,
} from "../../entity/empties";
import {
  GET_MACHINES_FOR_LOCATIONS_WITH_MAINTENANCE_SETTINGS,
  GET_MAINTENANCE_NEXT_FOR_MACHINE,
  QueryResultMachinesForLocationsWithMaintenanceSettings,
  QueryResultMaintenanceNextForMachine,
} from "../../apollo/queries/machines";
import LoadingSimple from "../Shared/LoadingSimple";
import { Autocomplete } from "@material-ui/lab";
import {
  GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATIONS_QUERY,
  GET_MAINTENANCE_NEXT_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY,
  QueryResultCatalogExtraRowsRentalByLocations,
  QueryResultMaintenanceNextForCatalogExtraRowRental,
} from "../../apollo/queries/catalogs_extra";
import {
  getCatalogExtraRowRentalName,
  getMachineName,
} from "../../utils/machines/machine";

interface Props extends WithStyles<typeof styles> {
  maintenance: MaintenanceType;
  setMaintenance: React.Dispatch<React.SetStateAction<MaintenanceType>>;
  locations: string[];
}

function DialogContentMaintenance({
  classes,
  maintenance,
  setMaintenance,
  locations,
}: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingMachines,
    error: errorMachines,
    data: dataMachines,
  } = useQuery<
    QueryResultMachinesForLocationsWithMaintenanceSettings,
    QueryMachinesForLocationsWithMaintenanceSettingsArgs
  >(GET_MACHINES_FOR_LOCATIONS_WITH_MAINTENANCE_SETTINGS, {
    fetchPolicy: getQueryFetchPolicy(
      "machinesForLocationsWithMaintenanceSettings"
    ),
    variables: {
      locationIds: locations,
    },
  });

  const {
    loading: loadingExtraRentals,
    error: errorExtraRentals,
    data: dataExtraRentals,
  } = useQuery<
    QueryResultCatalogExtraRowsRentalByLocations,
    QueryCatalogExtraRowsRentalByLocationsArgs
  >(GET_CATALOG_EXTRA_ROWS_RENTAL_BY_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraRowsRentalByLocations"),
    variables: {
      locationIds: locations,
    },
  });

  const [
    getMaintenanceNextForMachine,
    {
      loading: loadingMaintenanceNextForMachine,
      error: errorMaintenanceNextForMachine,
    },
  ] = useLazyQuery<QueryResultMaintenanceNextForMachine>(
    GET_MAINTENANCE_NEXT_FOR_MACHINE,
    {
      fetchPolicy: getQueryFetchPolicy("maintenanceNextForMachine"),
      variables: {
        machineId: maintenance.machine?.id,
      },
    }
  );

  useEffect(() => {
    if (maintenance.machine?.id && parseInt(maintenance.machine.id)) {
      getMaintenanceNextForMachine({
        variables: {
          machineId: maintenance.machine.id,
        },
      }).then((result) =>
        setMaintenance({
          ...maintenance,
          maintenanceInterval: result.data?.maintenanceNextForMachine
            ?.nextMaintenanceInterval
            ? result.data?.maintenanceNextForMachine?.nextMaintenanceInterval
            : MaintenanceIntervalTypeEmpty,
          estimatedDate: result.data?.maintenanceNextForMachine
            ?.nextMaintenanceDate
            ? result.data?.maintenanceNextForMachine?.nextMaintenanceDate
            : "",
        })
      );
    }
    // eslint-disable-next-line
  }, [maintenance.machine]);

  const [
    getMaintenanceNextForCatalogExtraRowRental,
    {
      loading: loadingMaintenanceNextForCatalogExtraRowRental,
      error: errorMaintenanceNextForCatalogExtraRowRental,
    },
  ] = useLazyQuery<QueryResultMaintenanceNextForCatalogExtraRowRental>(
    GET_MAINTENANCE_NEXT_FOR_CATALOG_EXTRA_ROW_RENTAL_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy(
        "maintenanceNextForCatalogExtraRowRental"
      ),
      variables: {
        catalogExtraRowRentalId: maintenance.catalogExtraRowRental?.id,
      },
    }
  );

  useEffect(() => {
    if (
      maintenance.catalogExtraRowRental?.id &&
      parseInt(maintenance.catalogExtraRowRental.id)
    ) {
      getMaintenanceNextForCatalogExtraRowRental({
        variables: {
          catalogExtraRowRentalId: maintenance.catalogExtraRowRental.id,
        },
      }).then((result) =>
        setMaintenance({
          ...maintenance,
          maintenanceInterval: result.data
            ?.maintenanceNextForCatalogExtraRowRental?.nextMaintenanceInterval
            ? result.data?.maintenanceNextForCatalogExtraRowRental
                ?.nextMaintenanceInterval
            : MaintenanceIntervalTypeEmpty,
          estimatedDate: result.data?.maintenanceNextForCatalogExtraRowRental
            ?.nextMaintenanceDate
            ? result.data?.maintenanceNextForCatalogExtraRowRental
                ?.nextMaintenanceDate
            : "",
        })
      );
    }
    // eslint-disable-next-line
  }, [maintenance.catalogExtraRowRental]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "maintenance.add_maintenance",
  ]);

  if (
    loadingMachines ||
    loadingExtraRentals ||
    loadingMaintenanceNextForCatalogExtraRowRental ||
    loadingMaintenanceNextForMachine
  )
    return <LoadingSimple />;
  if (errorMachines) return <Error error={errorMachines} />;
  if (errorExtraRentals) return <Error error={errorExtraRentals} />;
  if (errorMaintenanceNextForCatalogExtraRowRental)
    return <Error error={errorMaintenanceNextForCatalogExtraRowRental} />;
  if (errorMaintenanceNextForMachine)
    return <Error error={errorMaintenanceNextForMachine} />;
  if (!dataMachines || !dataExtraRentals)
    return <Error error={t("error_query_failed")} />;

  const machines: MachineType[] =
    dataMachines.machinesForLocationsWithMaintenanceSettings
      ? dataMachines.machinesForLocationsWithMaintenanceSettings
      : [];

  const extraRentals: CatalogExtraRowRentalType[] = (
    dataExtraRentals.catalogExtraRowsRentalByLocations
      ? dataExtraRentals.catalogExtraRowsRentalByLocations
      : []
  ).filter((extraRental) => {
    return (
      extraRental.maintenancesetting &&
      extraRental.maintenancesetting.scheduleType !==
        MaintenanceMaintenanceSettingScheduleTypeChoices.None
    );
  });

  return !hasPermissionAdd ? (
    <></>
  ) : (
    <DialogContent>
      <div className="d-flex">
        <FormControl fullWidth>
          <Autocomplete
            options={machines}
            getOptionLabel={(machine) => getMachineName(machine, true)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("machine")}
                required={
                  maintenance.catalogExtraRowRental ===
                  CatalogExtraRowRentalEmpty
                    ? true
                    : false
                }
              />
            )}
            value={maintenance.machine}
            getOptionSelected={(a, b) => {
              return a.id === b.id;
            }}
            onChange={(event, machineSelected) => {
              if (machineSelected) {
                setMaintenance({
                  ...maintenance,
                  catalogExtraRowRental: CatalogExtraRowRentalEmpty,
                  machine: machineSelected,
                  maintenanceInterval: MaintenanceIntervalTypeEmpty,
                });
              }
            }}
          />
        </FormControl>
      </div>
      <div className="d-flex">
        <FormControl fullWidth>
          <Autocomplete
            options={extraRentals}
            getOptionLabel={(extraRental) =>
              getCatalogExtraRowRentalName(extraRental)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("catalog_extra_rental")}
                required={maintenance.machine === MachineEmpty ? true : false}
              />
            )}
            value={maintenance.catalogExtraRowRental}
            getOptionSelected={(a, b) => {
              return a.id === b.id;
            }}
            onChange={(event, extraRentalSelected) => {
              if (extraRentalSelected) {
                setMaintenance({
                  ...maintenance,
                  catalogExtraRowRental: extraRentalSelected,
                  machine: MachineEmpty,
                  maintenanceInterval: MaintenanceIntervalTypeEmpty,
                });
              }
            }}
          />
        </FormControl>
      </div>
      <FormControl fullWidth>
        <TextField
          type="date"
          label={t("estimated_service_date")}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setMaintenance({
              ...maintenance,
              estimatedDate: event.target.value,
            });
          }}
          value={maintenance.estimatedDate ? maintenance.estimatedDate : ""}
          required={true}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "300px",
      marginBottom: spacing(2),
    },
    conImage: {
      position: "relative",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },
    radioContainer: {
      display: "block",
      marginTop: "2em",
    },
    textContainer: {
      position: "relative",
    },
    textContent: {
      position: "absolute",
      top: "0.5em",
    },
  });

export default withStyles(styles)(DialogContentMaintenance);
