import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import {
  DELETE_CUSTOMER_INDUSTRY_MUTATION,
  UPDATE_CUSTOMER_INDUSTRY_MUTATION,
} from "../../apollo/mutations/customers";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentCustomerIndustry from "./DialogContentCustomerIndustry";
import { Button } from "react-bootstrap";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import {
  CustomerIndustryType,
  Mutation,
  MutationDeleteCustomerIndustryArgs,
  MutationUpdateCustomerIndustryArgs,
} from "../../entity/types";
import { CustomerIndustryEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import ButtonLoad from "../Shared/ButtonLoad";
import HeaderPage from "../Shared/HeaderPage";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  customerIndustries: CustomerIndustryType[];
}

const CustomerIndustryList = ({ classes, customerIndustries }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [customerIndustry, setCustomerIndustry] = useState(
    CustomerIndustryEmpty
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customerindustry",
  ]);

  const data = customerIndustries.map((o) => ({ ...o }));

  const [updateCustomerIndustry, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCustomerIndustryArgs
  >(UPDATE_CUSTOMER_INDUSTRY_MUTATION, {
    onCompleted: (result) => {
      setCustomerIndustry(CustomerIndustryEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteCustomerIndustry, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCustomerIndustryArgs
  >(DELETE_CUSTOMER_INDUSTRY_MUTATION, {
    onCompleted: (result) => {
      setCustomerIndustry(CustomerIndustryEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customerIndustries"),
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateCustomerIndustry({
      variables: {
        id: customerIndustry.id,
        title: customerIndustry.title,
      },
    }).then(() => {
      setOpen(false);
    });
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCustomerIndustry({
        variables: { id: customerIndustry.id },
      }).then(() => {
        setOpen(false);
      });
    });
  };

  const getTableActions = () => {
    if (!hasPermissionEdit) {
      return [];
    }

    return [
      {
        icon: () => <FontAwesomeIcon icon={faEllipsisV} />,
        tooltip: t("edit"),
        onClick: (
          event: any,
          rowData: CustomerIndustryType | CustomerIndustryType[]
        ) => {
          const dataSingle: CustomerIndustryType = Array.isArray(rowData)
            ? rowData[0]
            : rowData;
          setCustomerIndustry(dataSingle);
          setOpen(true);
        },
      },
    ];
  };

  return (
    <>
      <HeaderPage title={t("customer_industries")} />
      <div className="containerInner">
        <MaterialTable<CustomerIndustryType>
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[{ title: t("title"), field: "title" }]}
          data={data}
          options={getTableOptions()}
          actions={getTableActions()}
        />
        <Dialog open={open}>
          <form onSubmit={(event) => handleSubmit(event)}>
            <DialogTitle>{t("edit_customer_industry")}</DialogTitle>
            <DialogContentCustomerIndustry
              customerIndustry={customerIndustry}
              setCustomerIndustry={setCustomerIndustry}
            />
            <DialogActions>
              <Button onClick={() => setOpen(false)} variant="secondary">
                {t("cancel")}
              </Button>
              <ButtonLoad
                loading={loadingDelete}
                onClick={(event) => handleDelete(event)}
                variant="light"
              >
                {t("delete")}
              </ButtonLoad>
              <ButtonLoad
                loading={loadingUpdate}
                disabled={!customerIndustry.title.trim()}
                type="submit"
                variant="primary"
              >
                {t("save")}
              </ButtonLoad>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  );
};

const styles = {};

export default withStyles(styles)(CustomerIndustryList);
