import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import { Button, Table } from "react-bootstrap";
import { useMutation, useQuery } from "@apollo/client";
import {
  CatalogExtraCategoryUnitPriceType,
  CatalogExtraRowInvoiceType,
  CatalogExtraRowUnitPriceType,
  Mutation,
  MutationCreateCatalogExtraRowInvoiceArgs,
  QueryCatalogExtraRowsInvoiceReservationArgs,
  QueryCatalogExtraRowsUnitPriceArgs,
} from "../../entity/types";
import {
  GET_CATALOG_EXTRA_ROWS_INVOICE_RESERVATION_QUERY,
  GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY,
  QueryResultCatalogExtraRowsInvoiceReservation,
  QueryResultCatalogExtraRowsUnitPrice,
} from "../../apollo/queries/catalogs_extra";
import Error from "../Shared/Error";
import { PermissionsContext } from "../../Root";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION } from "../../apollo/mutations/catalogs_extra";
import { checkPermission } from "../../utils/permissions";
import CatalogExtraRowUnitPriceTr from "./CatalogExtraRowUnitPriceTr";
import { ID_EMPTY } from "../../utils/constants";
import AddIcon from "@material-ui/icons/Add";
import {
  CatalogExtraRowInvoiceEmpty,
  ProductCodeEmpty,
} from "../../entity/empties";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/pro-light-svg-icons";
import { formatNumber, parseNumber } from "../../utils/formatting";
import ReservationButtonReturnCheckedCatalog from "../WorkQueues/ReservationButtonReturnCheckedCatalog";
import { WithStyles } from "@material-ui/core/styles";
import { hasReservationCatalogExtra } from "../../utils/reservations/reservation";
import SelectRequired from "../Shared/SelectRequired";
import {
  GET_PRODUCT_CODES_QUERY,
  QueryResultProductCodes,
} from "../../apollo/queries/product_codes";
import LoadingSimple from "../Shared/LoadingSimple";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import TextFieldFocus from "../Shared/TextFieldFocus";
import CatalogExtraRowInvoiceTbody from "./CatalogExtraRowInvoiceTbody";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface CatsByIds {
  [category_id: string]: CatalogExtraCategoryUnitPriceType;
}

const getCatalogExtraRowInvoiceNew = () => ({
  ...CatalogExtraRowInvoiceEmpty,
  quantity: formatNumber(parseNumber(CatalogExtraRowInvoiceEmpty.quantity), 2),
  unitPriceOverride: formatNumber(
    parseNumber(CatalogExtraRowInvoiceEmpty.unitPriceOverride),
    2
  ),
});

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  showReservationButtonReturnCheckedCatalog: boolean;
  className?: string;
}

function DialogContentCatalogExtraReservation({
  classes,
  reservation,
  showReservationButtonReturnCheckedCatalog,
  className,
}: Props) {
  const { t } = useTranslation();

  const [catalogExtraRowInvoiceNew, setCatalogExtraRowInvoiceNew] =
    useState<CatalogExtraRowInvoiceType>(getCatalogExtraRowInvoiceNew());
  const [showCreate, setShowCreate] = useState(false);

  const {
    loading: loadingRowsUnitPrice,
    error: errorRowsUnitPrice,
    data: dataRowsUnitPrice,
  } = useQuery<
    QueryResultCatalogExtraRowsUnitPrice,
    QueryCatalogExtraRowsUnitPriceArgs
  >(GET_CATALOG_EXTRA_ROWS_UNIT_PRICE_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraRowsUnitPrice"),
  });

  const {
    loading: loadingRowsInvoice,
    error: errorRowsInvoice,
    data: dataRowsInvoice,
  } = useQuery<
    QueryResultCatalogExtraRowsInvoiceReservation,
    QueryCatalogExtraRowsInvoiceReservationArgs
  >(GET_CATALOG_EXTRA_ROWS_INVOICE_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraRowsInvoiceReservation"),
    variables: {
      reservationId: reservation.id,
    },
  });

  const [createCatalogExtraRowInvoice, { loading: loadingCreate }] =
    useMutation<Mutation, MutationCreateCatalogExtraRowInvoiceArgs>(
      CREATE_CATALOG_EXTRA_ROW_INVOICE_MUTATION,
      {
        refetchQueries: [
          {
            query: GET_CATALOG_EXTRA_ROWS_INVOICE_RESERVATION_QUERY,
            variables: {
              reservationId: reservation.id,
            },
          },
        ],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const {
    loading: loadingProductCodes,
    error: errorProductRows,
    data: dataProductRows,
  } = useQuery<QueryResultProductCodes>(GET_PRODUCT_CODES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("productCodes"),
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_extra.add_catalogextrarowinvoice",
  ]);

  if (loadingRowsUnitPrice || loadingRowsInvoice || loadingProductCodes) {
    return <LoadingSimple />;
  }
  if (errorRowsUnitPrice) return <Error error={errorRowsUnitPrice} />;
  if (errorRowsInvoice) return <Error error={errorRowsInvoice} />;
  if (errorProductRows) return <Error error={errorProductRows} />;
  if (!dataRowsUnitPrice || !dataRowsInvoice || !dataProductRows) {
    return <Error error={t("error_query_failed")} />;
  }

  let catalogExtraRowsUnitPriceRequired: CatalogExtraRowUnitPriceType[] =
    dataRowsUnitPrice.catalogExtraRowsUnitPrice &&
    !hasReservationCatalogExtra(reservation)
      ? dataRowsUnitPrice.catalogExtraRowsUnitPrice.filter(
          (catalogExtraRowUnitPrice) =>
            catalogExtraRowUnitPrice.machineSet.some(
              (machine) => machine.id === reservation.machine?.id
            )
        )
      : [];

  let catalogExtraCategoriesUnitPriceById: CatsByIds = {};
  dataRowsUnitPrice.catalogExtraRowsUnitPrice?.forEach(
    (catalogExtraRowUnitPrice) => {
      const cat = catalogExtraRowUnitPrice.catalogExtraCategoryUnitPrice;
      if (catalogExtraCategoriesUnitPriceById[cat.id] === undefined) {
        catalogExtraCategoriesUnitPriceById[cat.id] = {
          ...cat,
          catalogextrarowunitpriceSet: [],
        };
      }
      catalogExtraCategoriesUnitPriceById[
        cat.id
      ].catalogextrarowunitpriceSet.push(catalogExtraRowUnitPrice);
    }
  );

  const renderSelectGroup = (
    catalogExtraCategoryUnitPrice: CatalogExtraCategoryUnitPriceType
  ) => {
    const itemsInner =
      catalogExtraCategoryUnitPrice.catalogextrarowunitpriceSet.map(
        (catalogExtraRowUnitPrice) => {
          return (
            <MenuItem
              key={catalogExtraRowUnitPrice.id}
              value={catalogExtraRowUnitPrice.id}
            >
              {catalogExtraRowUnitPrice.name}
            </MenuItem>
          );
        }
      );

    return [
      <ListSubheader>{catalogExtraCategoryUnitPrice.name}</ListSubheader>,
      itemsInner,
    ];
  };

  const onChangeRows = (catalogExtraRowUnitPriceId: string) => {
    if (dataRowsUnitPrice.catalogExtraRowsUnitPrice) {
      const unitPriceData = dataRowsUnitPrice.catalogExtraRowsUnitPrice.find(
        (e) => e.id === catalogExtraRowUnitPriceId
      );

      if (unitPriceData) {
        createCatalogExtraRowInvoice({
          variables: {
            catalogExtraRowUnitPriceId: catalogExtraRowUnitPriceId,
            reservationId: reservation.id,
            productCodeId: unitPriceData.productCode.id,
            titleOverride: CatalogExtraRowInvoiceEmpty.titleOverride,
            quantity: CatalogExtraRowInvoiceEmpty.quantity,
            unitPriceOverride: CatalogExtraRowInvoiceEmpty.unitPriceOverride,
            answer: CatalogExtraRowInvoiceEmpty.answer,
          },
        });
      }
    }
  };

  let hasRequiredAnswers = false;
  let hasMinCharges = false;
  for (let catalogExtraRowInvoiceReservation of dataRowsInvoice.catalogExtraRowsInvoiceReservation ||
    []) {
    if (
      catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice &&
      catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice.requireAnswer
    ) {
      hasRequiredAnswers = true;
    }
    if (
      catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice &&
      catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice.minCharge > 0
    ) {
      hasMinCharges = true;
    }

    catalogExtraRowsUnitPriceRequired =
      catalogExtraRowsUnitPriceRequired.filter(
        (el) =>
          !catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice ||
          (catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice &&
            el.id !==
              catalogExtraRowInvoiceReservation.catalogExtraRowUnitPrice.id)
      );
  }

  const onClickNewManualRow = () => {
    createCatalogExtraRowInvoice({
      variables: {
        reservationId: reservation.id,
        productCodeId: catalogExtraRowInvoiceNew.productCode
          ? catalogExtraRowInvoiceNew.productCode.id
          : ID_EMPTY,
        titleOverride: catalogExtraRowInvoiceNew.titleOverride,
        quantity: parseNumber(catalogExtraRowInvoiceNew.quantity),
        unitPriceOverride: parseNumber(
          catalogExtraRowInvoiceNew.unitPriceOverride
        ),
        answer: catalogExtraRowInvoiceNew.answer,
      },
    }).then(() => {
      setShowCreate(false);
      setCatalogExtraRowInvoiceNew(getCatalogExtraRowInvoiceNew());
    });
  };

  return (
    <DialogContent
      className={`${
        loadingRowsUnitPrice || loadingRowsInvoice || loadingCreate
          ? "loading"
          : ""
      } ${className}`}
    >
      {hasPermissionAdd && (
        <div>
          <FormControl fullWidth>
            <InputLabel id="lblDlgCatalogExtraRowsReservation">
              {t("add_catalog_extra_unit_price_row_to_reservation")}
            </InputLabel>
            <Select
              labelId="lblDlgCatalogExtraRowsReservation"
              value=""
              disabled={!hasPermissionAdd}
              onChange={(event) => {
                if (event.target.value !== undefined) {
                  onChangeRows(String(event.target.value));
                }
              }}
            >
              {Object.entries(catalogExtraCategoriesUnitPriceById).map(
                ([catalogId, catalogExtraCategoryUnitPrice]) => {
                  return renderSelectGroup(catalogExtraCategoryUnitPrice);
                }
              )}
            </Select>
          </FormControl>
        </div>
      )}
      <Table className="w-auto mt-3" borderless>
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell"></th>
            <th>{t("quantity")}</th>
            <th>
              <span className="d-none d-sm-table-cell">{t("unit_price")}</span>
              <span className="d-inline d-sm-none" title={t("unit_price")}>
                {t("unit_price_short")}
              </span>
            </th>
            <th>
              {hasRequiredAnswers ? t("answer_catalog_extra_row_invoice") : ""}
            </th>
            <th>
              {hasMinCharges || catalogExtraRowsUnitPriceRequired.length > 0
                ? t("min_charge")
                : ""}
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <CatalogExtraRowInvoiceTbody
          catalogExtraRowsInvoice={
            dataRowsInvoice.catalogExtraRowsInvoiceReservation
              ? dataRowsInvoice.catalogExtraRowsInvoiceReservation
              : []
          }
        />
        <tbody>
          {catalogExtraRowsUnitPriceRequired.map((catalogExtraRowUnitPrice) => (
            <CatalogExtraRowUnitPriceTr
              key={catalogExtraRowUnitPrice.id}
              catalogExtraRowUnitPrice={catalogExtraRowUnitPrice}
              reservation={reservation}
              productCodeId={catalogExtraRowUnitPrice.productCode.id}
            />
          ))}
        </tbody>
      </Table>
      {hasPermissionAdd && (
        <div className="mt-4">
          <b>{t("catalog_extra_row_invoice_manual")}</b>
          {showCreate && (
            <Table
              className={`${classes.tblCatalogExtraRowInvoice} mt-3`}
              borderless
            >
              <tbody>
                <tr>
                  <td>
                    <FormControl fullWidth>
                      <InputLabel id="lblCatalogExtraRowInvoiceNewProductCode">
                        {t("product_number")}
                      </InputLabel>
                      <SelectRequired
                        autoWidth
                        labelId="lblCatalogExtraRowInvoiceNewProductCode"
                        disabled={!hasPermissionAdd}
                        value={
                          catalogExtraRowInvoiceNew.productCode
                            ? catalogExtraRowInvoiceNew.productCode.id
                            : ID_EMPTY
                        }
                        onChange={(event) => {
                          setCatalogExtraRowInvoiceNew({
                            ...catalogExtraRowInvoiceNew,
                            productCode: {
                              ...(catalogExtraRowInvoiceNew.productCode
                                ? catalogExtraRowInvoiceNew.productCode
                                : ProductCodeEmpty),
                              id: String(event.target.value),
                            },
                          });
                        }}
                      >
                        {dataProductRows.productCodes?.map(
                          (productCode, index) => {
                            return (
                              <MenuItem
                                key={productCode.id}
                                value={productCode.id}
                              >
                                {productCode.description} - {productCode.code}
                              </MenuItem>
                            );
                          }
                        )}
                      </SelectRequired>
                    </FormControl>
                  </td>
                  <td>
                    <FormControl>
                      <TextField
                        label={t("description")}
                        value={catalogExtraRowInvoiceNew.titleOverride}
                        disabled={!hasPermissionAdd}
                        onChange={(event) => {
                          setCatalogExtraRowInvoiceNew({
                            ...catalogExtraRowInvoiceNew,
                            titleOverride: event.target.value,
                          });
                        }}
                        inputProps={{ maxLength: 100 }}
                        required={true}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl>
                      <TextFieldFocus
                        label={t("quantity")}
                        value={catalogExtraRowInvoiceNew.quantity}
                        disabled={!hasPermissionAdd}
                        onChange={(event) => {
                          setCatalogExtraRowInvoiceNew({
                            ...catalogExtraRowInvoiceNew,
                            quantity: event.target.value,
                          });
                        }}
                        onBlur={(event) => {
                          setCatalogExtraRowInvoiceNew({
                            ...catalogExtraRowInvoiceNew,
                            quantity: formatNumber(
                              parseNumber(event.target.value),
                              2
                            ),
                          });
                        }}
                        inputProps={{ maxLength: 10 }}
                        required={true}
                      />
                    </FormControl>
                  </td>
                  <td>
                    <FormControl>
                      <TextFieldFocus
                        label={t("unit_price")}
                        value={catalogExtraRowInvoiceNew.unitPriceOverride}
                        disabled={!hasPermissionAdd}
                        onChange={(event) => {
                          setCatalogExtraRowInvoiceNew({
                            ...catalogExtraRowInvoiceNew,
                            unitPriceOverride: event.target.value,
                          });
                        }}
                        onBlur={(event) => {
                          setCatalogExtraRowInvoiceNew({
                            ...catalogExtraRowInvoiceNew,
                            unitPriceOverride: formatNumber(
                              parseNumber(event.target.value),
                              2
                            ),
                          });
                        }}
                        inputProps={{ maxLength: 10 }}
                        required={true}
                      />
                    </FormControl>
                  </td>
                  <td className={classes.tdButtons}>
                    <Button
                      variant="primary"
                      onClick={onClickNewManualRow}
                      className="me-1"
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => setShowCreate(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
          {!showCreate && (
            <div className="mt-2">
              <Button
                className="btnRoundSmall"
                variant="primary"
                onClick={() => setShowCreate(!showCreate)}
              >
                <AddIcon />
              </Button>
            </div>
          )}
        </div>
      )}
      {showReservationButtonReturnCheckedCatalog && (
        <div className="mt-3 float-end">
          <ReservationButtonReturnCheckedCatalog reservation={reservation} />
        </div>
      )}
    </DialogContent>
  );
}

const styles = ({ breakpoints }: Theme) =>
  createStyles({
    tblCatalogExtraRowInvoice: {
      [breakpoints.down("sm")]: {
        "& tbody tr": {
          display: "block",

          "& td": {
            display: "block",
          },
        },
      },
    },
    tdButtons: {
      minWidth: "6.5rem",
    },
  });

export default withStyles(styles)(DialogContentCatalogExtraReservation);
