import React from "react";
import ReactDOM from "react-dom";
import Root from "./Root";
import * as serviceWorker from "./serviceWorker";

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
} from "@apollo/client";
import Auth from "./components/Auth";
import { IS_LOGGED_IN_QUERY } from "./apollo/client/is_logged_in";
import { retryLink } from "./apollo/links/RetryLink";
import { setAuthorizationLink } from "./apollo/links/AuthLink";
import { uploadLink } from "./apollo/links/UploadLink";
import { errorLink } from "./apollo/links/ErrorLink";

import "./styles/fonts.css";
import "toastify-js/src/toastify.css";
import "./styles/app.scss";
import "./styles/calendar.scss";
import "./styles/dialog_confirm.scss";

const link = from([retryLink, setAuthorizationLink, errorLink, uploadLink]);
const cache = new InMemoryCache();

const client = new ApolloClient({
  cache: cache,
  link: link,
});

const isLoggedIn = !!localStorage.getItem("authToken");

client.writeQuery({
  query: IS_LOGGED_IN_QUERY,
  data: {
    isLoggedIn: isLoggedIn,
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    {isLoggedIn ? <Root /> : <Auth />}
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
