import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { LinkContainer } from "react-router-bootstrap";
import { Image, Navbar } from "react-bootstrap";
import imgLogo from "../../pictures/logo.jpg";
import { useTranslation } from "react-i18next";
import { URL_ROOT } from "../../utils/urls";

interface Props extends WithStyles<typeof styles> {
  navClass: string;
  handleNavSelect: Function;
}

const Logo = ({ classes, navClass, handleNavSelect }: Props) => {
  const { t } = useTranslation();

  return (
    <LinkContainer to={URL_ROOT}>
      <Navbar.Brand className={navClass} onClick={() => handleNavSelect("")}>
        <Image src={imgLogo} alt={t("app_name")} className={classes.logo} />
      </Navbar.Brand>
    </LinkContainer>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    logo: {
      height: 40,
      marginLeft: "1.3vw",
    },
  });

export default withStyles(styles)(Logo);
