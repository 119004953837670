import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import {
  InspectionGiveAnswerType,
  InspectionsInspectionGiveQuestionAnswerTypeChoices,
  InspectionGiveQuestionType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { WithStyles } from "@material-ui/core/styles";

interface Props extends WithStyles<typeof styles> {
  inspectionGiveAnswer: InspectionGiveAnswerType;
  inspectionGiveQuestion: InspectionGiveQuestionType;
}

function InspectionGiveAnswerReport({
  classes,
  inspectionGiveAnswer,
  inspectionGiveQuestion,
}: Props) {
  const { t } = useTranslation();

  return (
    <div>
      <FormControl fullWidth className="mb-4">
        {inspectionGiveQuestion.answerType ===
          InspectionsInspectionGiveQuestionAnswerTypeChoices.Text && (
          <div>
            <label className="small">{inspectionGiveQuestion.question}</label>
            <div>{inspectionGiveAnswer.answer}</div>
          </div>
        )}
        {inspectionGiveQuestion.answerType ===
          InspectionsInspectionGiveQuestionAnswerTypeChoices.Options && (
          <div>
            <label className="small">{inspectionGiveQuestion.question}</label>
            <div>
              {inspectionGiveAnswer.inspectionGiveQuestionOption?.title}
            </div>
          </div>
        )}
        {inspectionGiveQuestion.answerType ===
          InspectionsInspectionGiveQuestionAnswerTypeChoices.Check && (
          <>
            <div>
              <label className="small">{inspectionGiveQuestion.question}</label>
              {inspectionGiveAnswer.checked === true && (
                <div>{t("check_ok")}</div>
              )}
              {inspectionGiveAnswer.checked === false && (
                <div>
                  {t("check_not_ok")}
                  <div className="text-muted">
                    {inspectionGiveAnswer.answer}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </FormControl>
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(InspectionGiveAnswerReport);
