import React, { useContext, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Chip,
  createStyles,
  FormControl,
  MenuItem,
  MenuList,
  Popover,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  InspectionReturnQuestionType,
  InspectionReturnTagType,
  Mutation,
  MutationAddInspectionReturnTagToQuestionArgs,
  MutationCreateInspectionReturnTagArgs,
  MutationDeleteInspectionReturnTagArgs,
  MutationRemoveInspectionReturnTagFromQuestionArgs,
  MutationUpdateInspectionReturnTagArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCheck,
  faEdit,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  ADD_INSPECTION_RETURN_TAG_TO_QUESTION_MUTATION,
  CREATE_INSPECTION_RETURN_TAG_MUTATION,
  DELETE_INSPECTION_RETURN_TAG_MUTATION,
  REMOVE_INSPECTION_RETURN_TAG_FROM_QUESTION_MUTATION,
  UPDATE_INSPECTION_RETURN_TAG_MUTATION,
} from "../../apollo/mutations/inspections";
import { GET_INSPECTION_RETURN_CATEGORIES_QUERY } from "../../apollo/queries/inspections";
import { handleError } from "../../entity/ErrorHandler";
import { InspectionReturnTagEmpty } from "../../entity/empties";
import { dialogConfirm } from "../../utils/dialogs";
import { ID_EMPTY } from "../../utils/constants";
import { WithStyles } from "@material-ui/core/styles";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  inspectionReturnQuestion: InspectionReturnQuestionType;
  inspectionReturnTags: InspectionReturnTagType[];
}

function InspectionReturnQuestionTags({
  classes,
  inspectionReturnQuestion,
  inspectionReturnTags,
}: Props) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tagNew, setTagNew] = useState("");
  const [inspectionReturnTagEdited, setInspectionReturnTagEdited] = useState(
    InspectionReturnTagEmpty
  );

  const [deleteInspectionReturnTag, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteInspectionReturnTagArgs
  >(DELETE_INSPECTION_RETURN_TAG_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
    onCompleted: () => {
      setInspectionReturnTagEdited(InspectionReturnTagEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [createInspectionReturnTag, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateInspectionReturnTagArgs
  >(CREATE_INSPECTION_RETURN_TAG_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
    onCompleted: () => {
      setTagNew("");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [removeInspectionReturnTagFromQuestion, { loading: loadingRemove }] =
    useMutation<Mutation, MutationRemoveInspectionReturnTagFromQuestionArgs>(
      REMOVE_INSPECTION_RETURN_TAG_FROM_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [addInspectionReturnTagToQuestion, { loading: loadingAdd }] =
    useMutation<Mutation, MutationAddInspectionReturnTagToQuestionArgs>(
      ADD_INSPECTION_RETURN_TAG_TO_QUESTION_MUTATION,
      {
        refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
        onError: (error) => {
          handleError(error);
        },
      }
    );

  const [updateInspectionReturnTag, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateInspectionReturnTagArgs
  >(UPDATE_INSPECTION_RETURN_TAG_MUTATION, {
    refetchQueries: [{ query: GET_INSPECTION_RETURN_CATEGORIES_QUERY }],
    onCompleted: () => {
      setInspectionReturnTagEdited(InspectionReturnTagEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddTag = checkPermission(myPermissions, [
    "inspections.add_inspectionreturntag",
  ]);
  const hasPermissionEditTag = checkPermission(myPermissions, [
    "inspections.change_inspectionreturntag",
  ]);

  const onClickTags = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCreateTag = () => {
    createInspectionReturnTag({
      variables: {
        inspectionReturnQuestionId: inspectionReturnQuestion.id,
        name: tagNew,
      },
    });
  };

  const handleRemoveTag = (inspectionReturnTag: InspectionReturnTagType) => {
    removeInspectionReturnTagFromQuestion({
      variables: {
        inspectionReturnQuestionId: inspectionReturnQuestion.id,
        inspectionReturnTagId: inspectionReturnTag.id,
      },
    });
  };

  const onClickMenuTag = (inspectionReturnTag: InspectionReturnTagType) => {
    if (isTagSelected(inspectionReturnTag)) {
      handleRemoveTag(inspectionReturnTag);
    } else {
      addInspectionReturnTagToQuestion({
        variables: {
          inspectionReturnQuestionId: inspectionReturnQuestion.id,
          inspectionReturnTagId: inspectionReturnTag.id,
        },
      });
    }
  };

  const onClickMenuTagEdit = (
    event: React.MouseEvent<HTMLElement>,
    inspectionReturnTag: InspectionReturnTagType
  ) => {
    event.stopPropagation();
    setInspectionReturnTagEdited(inspectionReturnTag);
  };

  const isTagSelected = (inspectionReturnTag: InspectionReturnTagType) => {
    let has = false;
    inspectionReturnQuestion.inspectionreturntagSet.forEach(
      (inspectionReturnTagLooped) => {
        if (inspectionReturnTagLooped.id === inspectionReturnTag.id) {
          has = true;
          return;
        }
      }
    );
    return has;
  };

  const onClickTagDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteInspectionReturnTag({
        variables: {
          inspectionReturnTagId: inspectionReturnTagEdited.id,
        },
      });
    });
  };

  const onClickTagSave = () => {
    updateInspectionReturnTag({
      variables: {
        inspectionReturnTagId: inspectionReturnTagEdited.id,
        name: inspectionReturnTagEdited.name,
      },
    });
  };

  return (
    <td className={loadingAdd || loadingRemove ? "loading" : ""}>
      {inspectionReturnQuestion.inspectionreturntagSet.map(
        (inspectionReturnTag) => (
          <Chip
            key={inspectionReturnTag.id}
            className="me-1 mb-1"
            label={inspectionReturnTag.name}
            onDelete={
              hasPermissionEditTag
                ? () => {
                    handleRemoveTag(inspectionReturnTag);
                  }
                : undefined
            }
          />
        )
      )}
      {hasPermissionAddTag && (
        <>
          <Button variant="light" size="sm" onClick={onClickTags}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <div className={classes.popContent}>
              {inspectionReturnTagEdited.id !== ID_EMPTY && (
                <div>
                  <FormControl fullWidth>
                    <TextField
                      label={t("name")}
                      value={inspectionReturnTagEdited.name}
                      autoFocus
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={(event) => {
                        setInspectionReturnTagEdited({
                          ...inspectionReturnTagEdited,
                          name: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                  <div className="buttons mt-3 mb-0">
                    <ButtonLoad
                      loading={loadingUpdate}
                      onClick={onClickTagSave}
                    >
                      {t("save")}
                    </ButtonLoad>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        setInspectionReturnTagEdited(InspectionReturnTagEmpty)
                      }
                    >
                      {t("cancel")}
                    </Button>
                    <ButtonLoad
                      loading={loadingDelete}
                      variant="light"
                      onClick={onClickTagDelete}
                    >
                      {t("delete")}
                    </ButtonLoad>
                  </div>
                </div>
              )}
              {inspectionReturnTagEdited.id === ID_EMPTY && (
                <>
                  <MenuList>
                    {inspectionReturnTags.map((inspectionReturnTag) => (
                      <MenuItem
                        key={inspectionReturnTag.id}
                        className={classes.tagMenuItem}
                        onClick={() => {
                          onClickMenuTag(inspectionReturnTag);
                        }}
                      >
                        {isTagSelected(inspectionReturnTag) && (
                          <FontAwesomeIcon className="me-2" icon={faCheck} />
                        )}
                        {inspectionReturnTag.name}
                        <Button
                          className={classes.btnMenuTagEdit}
                          variant="light"
                          onClick={(event) =>
                            onClickMenuTagEdit(event, inspectionReturnTag)
                          }
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                      </MenuItem>
                    ))}
                  </MenuList>
                  <FormControl fullWidth>
                    <TextField
                      label={t("new_tag")}
                      value={tagNew}
                      inputProps={{
                        maxLength: 100,
                      }}
                      InputProps={{
                        endAdornment: (
                          <ButtonLoad
                            loading={loadingCreate}
                            variant="link"
                            onClick={handleCreateTag}
                          >
                            <FontAwesomeIcon icon={faCaretRight} />
                          </ButtonLoad>
                        ),
                      }}
                      onChange={(event) => {
                        setTagNew(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          handleCreateTag();
                        }
                      }}
                    />
                  </FormControl>
                </>
              )}
            </div>
          </Popover>
        </>
      )}
    </td>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    popContent: {
      padding: spacing(2),
    },
    tagMenuItem: {
      height: "40px",
    },
    btnMenuTagEdit: {
      position: "absolute",
      right: 0,
    },
  });

export default withStyles(styles)(InspectionReturnQuestionTags);
