import * as Types from '../../entity/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MachineListFieldsFragment = (
  { __typename?: 'MachineType' }
  & Pick<Types.MachineType, 'id' | 'identifier' | 'serial' | 'acquisitionDate' | 'dateRemoved' | 'yearModel' | 'hourTracking' | 'rentThrough' | 'rentThroughCompany' | 'rentThroughInformation' | 'rentThroughCommissionCase' | 'information' | 'bulkProduct'>
  & { machineModel: (
    { __typename?: 'MachineModelType' }
    & Pick<Types.MachineModelType, 'id' | 'title'>
    & { machineMake: (
      { __typename?: 'MachineMakeType' }
      & Pick<Types.MachineMakeType, 'id' | 'title'>
    ) }
  ), catalogExtraRowsUnitPrice: Array<(
    { __typename?: 'CatalogExtraRowUnitPriceType' }
    & Pick<Types.CatalogExtraRowUnitPriceType, 'id'>
  )>, catalogExtraRowsRental: Array<(
    { __typename?: 'CatalogExtraRowRentalType' }
    & Pick<Types.CatalogExtraRowRentalType, 'id'>
  )>, location?: Types.Maybe<(
    { __typename?: 'LocationType' }
    & Pick<Types.LocationType, 'id' | 'name'>
  )>, catalogRows: Array<(
    { __typename?: 'CatalogRowType' }
    & Pick<Types.CatalogRowType, 'id' | 'name'>
    & { catalogCategory: (
      { __typename?: 'CatalogCategoryType' }
      & { catalogCategoryUpper: (
        { __typename?: 'CatalogCategoryUpperType' }
        & { catalog: (
          { __typename?: 'CatalogType' }
          & Pick<Types.CatalogType, 'id'>
        ) }
      ) }
    ) }
  )> }
);

export type MachinesAvailableFieldsFragment = (
  { __typename?: 'MachineType' }
  & Pick<Types.MachineType, 'id' | 'identifier' | 'serial'>
  & { machineModel: (
    { __typename?: 'MachineModelType' }
    & Pick<Types.MachineModelType, 'id' | 'title'>
    & { machineMake: (
      { __typename?: 'MachineMakeType' }
      & Pick<Types.MachineMakeType, 'id' | 'title'>
    ) }
  ), location?: Types.Maybe<(
    { __typename?: 'LocationType' }
    & Pick<Types.LocationType, 'id' | 'name'>
  )> }
);

export type GetMachinesForLocationQueryQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  rentThroughs: Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>;
  catalogRowIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetMachinesForLocationQueryQuery = (
  { __typename?: 'Query' }
  & { machinesForLocation: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial' | 'acquisitionDate' | 'dateRemoved' | 'yearModel' | 'hourTracking' | 'rentThrough' | 'rentThroughCompany' | 'rentThroughInformation' | 'rentThroughCommissionCase' | 'information' | 'bulkProduct'>
    & { machinebulkamountSet: Array<(
      { __typename?: 'MachineBulkAmountType' }
      & Pick<Types.MachineBulkAmountType, 'id' | 'bulkAmount'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id'>
      ) }
    )>, machineinfoanswerSet: Array<(
      { __typename?: 'MachineInfoAnswerType' }
      & Pick<Types.MachineInfoAnswerType, 'id' | 'answer'>
      & { machineInfoQuestion: (
        { __typename?: 'MachineInfoQuestionType' }
        & Pick<Types.MachineInfoQuestionType, 'id' | 'answerType'>
      ), machineInfoQuestionOption?: Types.Maybe<(
        { __typename?: 'MachineInfoQuestionOptionType' }
        & Pick<Types.MachineInfoQuestionOptionType, 'id'>
      )> }
    )>, machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ), catalogExtraRowsUnitPrice: Array<(
      { __typename?: 'CatalogExtraRowUnitPriceType' }
      & Pick<Types.CatalogExtraRowUnitPriceType, 'id'>
    )>, catalogExtraRowsRental: Array<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id'>
    )>, location?: Types.Maybe<(
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id' | 'name'>
    )>, catalogRows: Array<(
      { __typename?: 'CatalogRowType' }
      & Pick<Types.CatalogRowType, 'id' | 'name'>
      & { catalogCategory: (
        { __typename?: 'CatalogCategoryType' }
        & { catalogCategoryUpper: (
          { __typename?: 'CatalogCategoryUpperType' }
          & { catalog: (
            { __typename?: 'CatalogType' }
            & Pick<Types.CatalogType, 'id'>
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type GetMachineQueryQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type GetMachineQueryQuery = (
  { __typename?: 'Query' }
  & { machine: (
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial' | 'yearModel' | 'fileTypePlate' | 'fileCheckList' | 'purchasePrice' | 'purchaseSupplier' | 'purchaseInformation' | 'filePurchase' | 'financingType' | 'financingLoanType' | 'financingInterestType' | 'financingInterestVariableType' | 'financingInformation' | 'financingCompany' | 'financingAgreementNumber' | 'financingDateStart' | 'financingDateEnd' | 'financingInterestRate' | 'financingInterestMargin' | 'fileFinancing'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ) }
  ) }
);

export type GetMachinesQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMachinesQueryQuery = (
  { __typename?: 'Query' }
  & { machines: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial' | 'acquisitionDate' | 'dateRemoved' | 'yearModel' | 'hourTracking' | 'rentThrough' | 'rentThroughCompany' | 'rentThroughInformation' | 'rentThroughCommissionCase' | 'information' | 'bulkProduct'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ), catalogExtraRowsUnitPrice: Array<(
      { __typename?: 'CatalogExtraRowUnitPriceType' }
      & Pick<Types.CatalogExtraRowUnitPriceType, 'id'>
    )>, catalogExtraRowsRental: Array<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id'>
    )>, location?: Types.Maybe<(
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id' | 'name'>
    )>, catalogRows: Array<(
      { __typename?: 'CatalogRowType' }
      & Pick<Types.CatalogRowType, 'id' | 'name'>
      & { catalogCategory: (
        { __typename?: 'CatalogCategoryType' }
        & { catalogCategoryUpper: (
          { __typename?: 'CatalogCategoryUpperType' }
          & { catalog: (
            { __typename?: 'CatalogType' }
            & Pick<Types.CatalogType, 'id'>
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type GetMachinesSimpleQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMachinesSimpleQueryQuery = (
  { __typename?: 'Query' }
  & { machines: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ) }
  )> }
);

export type GetMachinesBreakdownsUnfixedQueryQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  categoryIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  userIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetMachinesBreakdownsUnfixedQueryQuery = (
  { __typename?: 'Query' }
  & { machineBreakdownsUnfixed: Array<(
    { __typename?: 'MachineBreakdownType' }
    & Pick<Types.MachineBreakdownType, 'id' | 'stillRentable' | 'title' | 'information' | 'fixByStart' | 'fixByEnd' | 'billing' | 'amountBilledSingle' | 'fixedAt' | 'createdAt'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'identifier'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ) }
    )>, catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id' | 'name'>
    )>, machinebreakdowncommentSet: Array<(
      { __typename?: 'MachineBreakdownCommentType' }
      & Pick<Types.MachineBreakdownCommentType, 'id' | 'comment' | 'createdAt'>
      & { createdBy?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'firstName' | 'lastName'>
      )> }
    )> }
  )>, reservationsCurrentByMachine: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id'>
    )>, order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id'>
      & { customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name' | 'visitAddress' | 'visitPostcode' | 'visitDistrict'>
      )> }
    ) }
  )>, reservationsUpcomingByMachine: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'dateRented'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id'>
    )> }
  )>, machineBulkBreakdownsUnfixed: Array<(
    { __typename?: 'MachineBulkBreakdownType' }
    & Pick<Types.MachineBulkBreakdownType, 'id' | 'fixedAt' | 'amountBroken' | 'createdAt'>
    & { machine: (
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id' | 'identifier'>
      & { machineModel: (
        { __typename?: 'MachineModelType' }
        & Pick<Types.MachineModelType, 'id' | 'title'>
        & { machineMake: (
          { __typename?: 'MachineMakeType' }
          & Pick<Types.MachineMakeType, 'id' | 'title'>
        ) }
      ) }
    ) }
  )> }
);

export type GetMachineInfoCategoriesQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMachineInfoCategoriesQueryQuery = (
  { __typename?: 'Query' }
  & { machineInfoCategories: Array<(
    { __typename?: 'MachineInfoCategoryType' }
    & Pick<Types.MachineInfoCategoryType, 'id' | 'name'>
    & { machineinfoquestionSet: Array<(
      { __typename?: 'MachineInfoQuestionType' }
      & Pick<Types.MachineInfoQuestionType, 'id' | 'question' | 'answerType' | 'showProductCard' | 'showDrive'>
      & { machineInfoCategory: (
        { __typename?: 'MachineInfoCategoryType' }
        & Pick<Types.MachineInfoCategoryType, 'id'>
      ), machineinfotagSet: Array<(
        { __typename?: 'MachineInfoTagType' }
        & Pick<Types.MachineInfoTagType, 'id' | 'name'>
      )>, machineinfoquestionoptionSet: Array<(
        { __typename?: 'MachineInfoQuestionOptionType' }
        & Pick<Types.MachineInfoQuestionOptionType, 'id' | 'title'>
      )> }
    )> }
  )>, machineInfoTags: Array<(
    { __typename?: 'MachineInfoTagType' }
    & Pick<Types.MachineInfoTagType, 'id' | 'name'>
  )> }
);

export type GetMachineWithMachineInfoCategoriesQueryQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type GetMachineWithMachineInfoCategoriesQueryQuery = (
  { __typename?: 'Query' }
  & { machine: (
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id'>
    & { machineinfoanswerSet: Array<(
      { __typename?: 'MachineInfoAnswerType' }
      & Pick<Types.MachineInfoAnswerType, 'id' | 'answer'>
      & { machineInfoQuestionOption?: Types.Maybe<(
        { __typename?: 'MachineInfoQuestionOptionType' }
        & Pick<Types.MachineInfoQuestionOptionType, 'id' | 'title'>
      )>, machineInfoQuestion: (
        { __typename?: 'MachineInfoQuestionType' }
        & Pick<Types.MachineInfoQuestionType, 'id'>
        & { machineInfoCategory: (
          { __typename?: 'MachineInfoCategoryType' }
          & Pick<Types.MachineInfoCategoryType, 'id'>
        ) }
      ) }
    )>, maintenancesetting?: Types.Maybe<(
      { __typename?: 'MaintenanceSettingType' }
      & Pick<Types.MaintenanceSettingType, 'id' | 'scheduleType' | 'startDate'>
    )> }
  ), machineInfoCategories: Array<(
    { __typename?: 'MachineInfoCategoryType' }
    & Pick<Types.MachineInfoCategoryType, 'id' | 'name'>
    & { machineinfoquestionSet: Array<(
      { __typename?: 'MachineInfoQuestionType' }
      & Pick<Types.MachineInfoQuestionType, 'id' | 'question' | 'answerType' | 'showProductCard' | 'showDrive'>
      & { machineInfoCategory: (
        { __typename?: 'MachineInfoCategoryType' }
        & Pick<Types.MachineInfoCategoryType, 'id'>
      ), machineinfotagSet: Array<(
        { __typename?: 'MachineInfoTagType' }
        & Pick<Types.MachineInfoTagType, 'id' | 'name'>
      )>, machineinfoquestionoptionSet: Array<(
        { __typename?: 'MachineInfoQuestionOptionType' }
        & Pick<Types.MachineInfoQuestionOptionType, 'id' | 'title'>
      )> }
    )> }
  )>, machineInfoTags: Array<(
    { __typename?: 'MachineInfoTagType' }
    & Pick<Types.MachineInfoTagType, 'id' | 'name'>
  )>, machines: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ) }
  )> }
);

export type GetMachineBreakdownsForMachineQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type GetMachineBreakdownsForMachineQuery = (
  { __typename?: 'Query' }
  & { machineBreakdownsForMachine: Array<(
    { __typename?: 'MachineBreakdownType' }
    & Pick<Types.MachineBreakdownType, 'id' | 'title' | 'information' | 'fixedAt'>
    & { fixedBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, machinebreakdowncommentSet: Array<(
      { __typename?: 'MachineBreakdownCommentType' }
      & Pick<Types.MachineBreakdownCommentType, 'id' | 'comment' | 'createdAt'>
      & { createdBy?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type GetMakesAndModelsQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMakesAndModelsQueryQuery = (
  { __typename?: 'Query' }
  & { machineMakes: Array<(
    { __typename?: 'MachineMakeType' }
    & Pick<Types.MachineMakeType, 'id' | 'title'>
    & { machinemodelSet: Array<(
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
    )> }
  )> }
);

export type GetMachineInfoQuestionsForDrivesQueryQueryVariables = Types.Exact<{
  machineIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetMachineInfoQuestionsForDrivesQueryQuery = (
  { __typename?: 'Query' }
  & { machineInfoQuestionsForDrives: Array<(
    { __typename?: 'MachineInfoQuestionType' }
    & Pick<Types.MachineInfoQuestionType, 'id' | 'question'>
    & { machineinfoanswerSet: Array<(
      { __typename?: 'MachineInfoAnswerType' }
      & Pick<Types.MachineInfoAnswerType, 'id' | 'answer'>
      & { machine: (
        { __typename?: 'MachineType' }
        & Pick<Types.MachineType, 'id'>
      ) }
    )> }
  )> }
);

export type GetMachinesForCatalogRowAvailableQueryQueryVariables = Types.Exact<{
  catalogRowId: Types.Scalars['ID'];
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
}>;


export type GetMachinesForCatalogRowAvailableQueryQuery = (
  { __typename?: 'Query' }
  & { machinesForCatalogRowAvailable: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ), location?: Types.Maybe<(
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id' | 'name'>
    )> }
  )> }
);

export type GetMachinesSearchAvailableQueryQueryVariables = Types.Exact<{
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
  search: Types.Scalars['String'];
}>;


export type GetMachinesSearchAvailableQueryQuery = (
  { __typename?: 'Query' }
  & { machinesSearchAvailable: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ), location?: Types.Maybe<(
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id' | 'name'>
    )> }
  )> }
);

export type GetMachineBulkAmountsForMachineQueryQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type GetMachineBulkAmountsForMachineQueryQuery = (
  { __typename?: 'Query' }
  & { machineBulkAmountsForMachine: Array<(
    { __typename?: 'MachineBulkAmountType' }
    & Pick<Types.MachineBulkAmountType, 'id' | 'bulkAmount'>
    & { location: (
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id'>
    ) }
  )> }
);

export type GetMachineBulkBreakdownsForMachineQueryQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
  reservationId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetMachineBulkBreakdownsForMachineQueryQuery = (
  { __typename?: 'Query' }
  & { machineBulkBreakdownsForMachine: Array<(
    { __typename?: 'MachineBulkBreakdownType' }
    & Pick<Types.MachineBulkBreakdownType, 'id' | 'amountBroken' | 'fixable' | 'fixedAt'>
  )> }
);

export type GetMachinesForCatalogRowsQueryVariables = Types.Exact<{
  catalogRowIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  locationId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetMachinesForCatalogRowsQuery = (
  { __typename?: 'Query' }
  & { machinesForCatalogRows: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial' | 'rentThrough' | 'rentThroughCompany' | 'rentThroughInformation' | 'acquisitionDate' | 'dateRemoved' | 'bulkProduct'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ), location?: Types.Maybe<(
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id' | 'name'>
    )>, catalogRows: Array<(
      { __typename?: 'CatalogRowType' }
      & Pick<Types.CatalogRowType, 'id'>
    )> }
  )> }
);

export type GetMachinesWithoutCatalogRowQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
}>;


export type GetMachinesWithoutCatalogRowQuery = (
  { __typename?: 'Query' }
  & { machinesWithoutCatalogRow: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ) }
  )> }
);

export type MaintenanceHistoryForMachineQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type MaintenanceHistoryForMachineQuery = (
  { __typename?: 'Query' }
  & { maintenanceHistoryForMachine: Array<(
    { __typename?: 'MaintenanceType' }
    & Pick<Types.MaintenanceType, 'id' | 'serviceContractor' | 'servicedAt' | 'serviceEndBy' | 'verifiedAt'>
    & { album: (
      { __typename?: 'MaintenanceAlbumType' }
      & Pick<Types.MaintenanceAlbumType, 'id'>
      & { maintenanceimageSet: Array<(
        { __typename?: 'MaintenanceImageType' }
        & Pick<Types.MaintenanceImageType, 'id' | 'image' | 'imageType' | 'imageName' | 'eurosWarranty' | 'eurosOwnCosts' | 'information' | 'createdAt'>
        & { createdBy?: Types.Maybe<(
          { __typename?: 'UserType' }
          & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
        )> }
      )> }
    ), maintenanceInterval: (
      { __typename?: 'MaintenanceIntervalType' }
      & Pick<Types.MaintenanceIntervalType, 'id' | 'intervalType' | 'hours' | 'days' | 'description'>
    ), operationhour?: Types.Maybe<(
      { __typename?: 'OperationHourType' }
      & Pick<Types.OperationHourType, 'id' | 'hours'>
    )>, serviceTechnician?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, maintenancecommentSet: Array<(
      { __typename?: 'MaintenanceCommentType' }
      & Pick<Types.MaintenanceCommentType, 'id' | 'comment' | 'createdAt'>
      & { createdBy?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
      )> }
    )>, verifiedBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )> }
  )>, maintenanceNextForMachine?: Types.Maybe<(
    { __typename?: 'NextMaintenanceType' }
    & Pick<Types.NextMaintenanceType, 'nextMaintenanceDate' | 'intervalType' | 'hours' | 'days' | 'description'>
  )> }
);

export type MaintenanceNextForMachineQueryVariables = Types.Exact<{
  machineId: Types.Scalars['ID'];
}>;


export type MaintenanceNextForMachineQuery = (
  { __typename?: 'Query' }
  & { maintenanceNextForMachine?: Types.Maybe<(
    { __typename?: 'NextMaintenanceType' }
    & Pick<Types.NextMaintenanceType, 'nextMaintenanceDate'>
    & { nextMaintenanceInterval?: Types.Maybe<(
      { __typename?: 'MaintenanceIntervalType' }
      & Pick<Types.MaintenanceIntervalType, 'id'>
    )> }
  )> }
);

export type GetMachinesAndOperationHoursQueryQueryVariables = Types.Exact<{
  machineIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetMachinesAndOperationHoursQueryQuery = (
  { __typename?: 'Query' }
  & { machinesWithIds: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id'>
    & { operationhourSet: Array<(
      { __typename?: 'OperationHourType' }
      & Pick<Types.OperationHourType, 'id' | 'dateChecked' | 'hours' | 'hoursExtra'>
    )> }
  )> }
);

export type GetMachinesForLocationsWithMaintenanceSettingsQueryVariables = Types.Exact<{
  locationIds: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetMachinesForLocationsWithMaintenanceSettingsQuery = (
  { __typename?: 'Query' }
  & { machinesForLocationsWithMaintenanceSettings: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier' | 'serial' | 'dateRemoved' | 'yearModel' | 'information'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ), location?: Types.Maybe<(
      { __typename?: 'LocationType' }
      & Pick<Types.LocationType, 'id' | 'name'>
    )>, maintenancesetting?: Types.Maybe<(
      { __typename?: 'MaintenanceSettingType' }
      & Pick<Types.MaintenanceSettingType, 'id' | 'scheduleType'>
      & { maintenanceintervalSet: Array<(
        { __typename?: 'MaintenanceIntervalType' }
        & Pick<Types.MaintenanceIntervalType, 'id' | 'intervalType' | 'description' | 'hours' | 'days'>
      )> }
    )> }
  )> }
);

export const MachineListFieldsFragmentDoc = gql`
    fragment MachineListFields on MachineType {
  id
  identifier
  serial
  acquisitionDate
  dateRemoved
  yearModel
  hourTracking
  rentThrough
  rentThroughCompany
  rentThroughInformation
  rentThroughCommissionCase
  information
  bulkProduct
  machineModel {
    id
    title
    machineMake {
      id
      title
    }
  }
  catalogExtraRowsUnitPrice {
    id
  }
  catalogExtraRowsRental {
    id
  }
  location {
    id
    name
  }
  catalogRows {
    id
    name
    catalogCategory {
      catalogCategoryUpper {
        catalog {
          id
        }
      }
    }
  }
}
    `;
export const MachinesAvailableFieldsFragmentDoc = gql`
    fragment MachinesAvailableFields on MachineType {
  id
  identifier
  serial
  machineModel {
    id
    title
    machineMake {
      id
      title
    }
  }
  location {
    id
    name
  }
}
    `;
export const GetMachinesForLocationQueryDocument = gql`
    query getMachinesForLocationQuery($locationIds: [ID]!, $rentThroughs: [Int]!, $catalogRowIds: [ID]!, $search: String) {
  machinesForLocation(
    locationIds: $locationIds
    rentThroughs: $rentThroughs
    catalogRowIds: $catalogRowIds
    search: $search
  ) {
    ...MachineListFields
    machinebulkamountSet {
      id
      location {
        id
      }
      bulkAmount
    }
    machineinfoanswerSet {
      id
      machineInfoQuestion {
        id
        answerType
      }
      machineInfoQuestionOption {
        id
      }
      answer
    }
  }
}
    ${MachineListFieldsFragmentDoc}`;

/**
 * __useGetMachinesForLocationQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesForLocationQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesForLocationQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesForLocationQueryQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      rentThroughs: // value for 'rentThroughs'
 *      catalogRowIds: // value for 'catalogRowIds'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetMachinesForLocationQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesForLocationQueryQuery, GetMachinesForLocationQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesForLocationQueryQuery, GetMachinesForLocationQueryQueryVariables>(GetMachinesForLocationQueryDocument, options);
      }
export function useGetMachinesForLocationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesForLocationQueryQuery, GetMachinesForLocationQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesForLocationQueryQuery, GetMachinesForLocationQueryQueryVariables>(GetMachinesForLocationQueryDocument, options);
        }
export type GetMachinesForLocationQueryQueryHookResult = ReturnType<typeof useGetMachinesForLocationQueryQuery>;
export type GetMachinesForLocationQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesForLocationQueryLazyQuery>;
export type GetMachinesForLocationQueryQueryResult = Apollo.QueryResult<GetMachinesForLocationQueryQuery, GetMachinesForLocationQueryQueryVariables>;
export const GetMachineQueryDocument = gql`
    query getMachineQuery($machineId: ID!) {
  machine(machineId: $machineId) {
    id
    identifier
    serial
    yearModel
    fileTypePlate
    fileCheckList
    purchasePrice
    purchaseSupplier
    purchaseInformation
    filePurchase
    financingType
    financingLoanType
    financingInterestType
    financingInterestVariableType
    financingInformation
    financingCompany
    financingAgreementNumber
    financingDateStart
    financingDateEnd
    financingInterestRate
    financingInterestMargin
    fileFinancing
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetMachineQueryQuery__
 *
 * To run a query within a React component, call `useGetMachineQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineQueryQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetMachineQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachineQueryQuery, GetMachineQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineQueryQuery, GetMachineQueryQueryVariables>(GetMachineQueryDocument, options);
      }
export function useGetMachineQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineQueryQuery, GetMachineQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineQueryQuery, GetMachineQueryQueryVariables>(GetMachineQueryDocument, options);
        }
export type GetMachineQueryQueryHookResult = ReturnType<typeof useGetMachineQueryQuery>;
export type GetMachineQueryLazyQueryHookResult = ReturnType<typeof useGetMachineQueryLazyQuery>;
export type GetMachineQueryQueryResult = Apollo.QueryResult<GetMachineQueryQuery, GetMachineQueryQueryVariables>;
export const GetMachinesQueryDocument = gql`
    query getMachinesQuery {
  machines {
    ...MachineListFields
  }
}
    ${MachineListFieldsFragmentDoc}`;

/**
 * __useGetMachinesQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMachinesQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMachinesQueryQuery, GetMachinesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesQueryQuery, GetMachinesQueryQueryVariables>(GetMachinesQueryDocument, options);
      }
export function useGetMachinesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesQueryQuery, GetMachinesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesQueryQuery, GetMachinesQueryQueryVariables>(GetMachinesQueryDocument, options);
        }
export type GetMachinesQueryQueryHookResult = ReturnType<typeof useGetMachinesQueryQuery>;
export type GetMachinesQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesQueryLazyQuery>;
export type GetMachinesQueryQueryResult = Apollo.QueryResult<GetMachinesQueryQuery, GetMachinesQueryQueryVariables>;
export const GetMachinesSimpleQueryDocument = gql`
    query getMachinesSimpleQuery {
  machines {
    id
    identifier
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetMachinesSimpleQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesSimpleQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesSimpleQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesSimpleQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMachinesSimpleQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMachinesSimpleQueryQuery, GetMachinesSimpleQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesSimpleQueryQuery, GetMachinesSimpleQueryQueryVariables>(GetMachinesSimpleQueryDocument, options);
      }
export function useGetMachinesSimpleQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesSimpleQueryQuery, GetMachinesSimpleQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesSimpleQueryQuery, GetMachinesSimpleQueryQueryVariables>(GetMachinesSimpleQueryDocument, options);
        }
export type GetMachinesSimpleQueryQueryHookResult = ReturnType<typeof useGetMachinesSimpleQueryQuery>;
export type GetMachinesSimpleQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesSimpleQueryLazyQuery>;
export type GetMachinesSimpleQueryQueryResult = Apollo.QueryResult<GetMachinesSimpleQueryQuery, GetMachinesSimpleQueryQueryVariables>;
export const GetMachinesBreakdownsUnfixedQueryDocument = gql`
    query getMachinesBreakdownsUnfixedQuery($locationIds: [ID]!, $categoryIds: [ID]!, $userIds: [ID]!) {
  machineBreakdownsUnfixed(
    locationIds: $locationIds
    categoryIds: $categoryIds
    userIds: $userIds
  ) {
    id
    stillRentable
    title
    information
    fixByStart
    fixByEnd
    billing
    amountBilledSingle
    fixedAt
    machine {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
    catalogExtraRowRental {
      id
      name
    }
    machinebreakdowncommentSet {
      id
      comment
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
    createdAt
  }
  reservationsCurrentByMachine {
    id
    machine {
      id
    }
    order {
      id
      customer {
        id
        name
        visitAddress
        visitPostcode
        visitDistrict
      }
    }
    dateRented
    dateReturned
  }
  reservationsUpcomingByMachine {
    id
    machine {
      id
    }
    dateRented
  }
  machineBulkBreakdownsUnfixed(
    locationIds: $locationIds
    categoryIds: $categoryIds
    userIds: $userIds
  ) {
    id
    machine {
      id
      identifier
      machineModel {
        id
        title
        machineMake {
          id
          title
        }
      }
    }
    fixedAt
    amountBroken
    createdAt
  }
}
    `;

/**
 * __useGetMachinesBreakdownsUnfixedQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesBreakdownsUnfixedQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesBreakdownsUnfixedQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesBreakdownsUnfixedQueryQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *      categoryIds: // value for 'categoryIds'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useGetMachinesBreakdownsUnfixedQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesBreakdownsUnfixedQueryQuery, GetMachinesBreakdownsUnfixedQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesBreakdownsUnfixedQueryQuery, GetMachinesBreakdownsUnfixedQueryQueryVariables>(GetMachinesBreakdownsUnfixedQueryDocument, options);
      }
export function useGetMachinesBreakdownsUnfixedQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesBreakdownsUnfixedQueryQuery, GetMachinesBreakdownsUnfixedQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesBreakdownsUnfixedQueryQuery, GetMachinesBreakdownsUnfixedQueryQueryVariables>(GetMachinesBreakdownsUnfixedQueryDocument, options);
        }
export type GetMachinesBreakdownsUnfixedQueryQueryHookResult = ReturnType<typeof useGetMachinesBreakdownsUnfixedQueryQuery>;
export type GetMachinesBreakdownsUnfixedQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesBreakdownsUnfixedQueryLazyQuery>;
export type GetMachinesBreakdownsUnfixedQueryQueryResult = Apollo.QueryResult<GetMachinesBreakdownsUnfixedQueryQuery, GetMachinesBreakdownsUnfixedQueryQueryVariables>;
export const GetMachineInfoCategoriesQueryDocument = gql`
    query getMachineInfoCategoriesQuery {
  machineInfoCategories {
    id
    name
    machineinfoquestionSet {
      id
      question
      answerType
      showProductCard
      showDrive
      machineInfoCategory {
        id
      }
      machineinfotagSet {
        id
        name
      }
      machineinfoquestionoptionSet {
        id
        title
      }
    }
  }
  machineInfoTags {
    id
    name
  }
}
    `;

/**
 * __useGetMachineInfoCategoriesQueryQuery__
 *
 * To run a query within a React component, call `useGetMachineInfoCategoriesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineInfoCategoriesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineInfoCategoriesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMachineInfoCategoriesQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMachineInfoCategoriesQueryQuery, GetMachineInfoCategoriesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineInfoCategoriesQueryQuery, GetMachineInfoCategoriesQueryQueryVariables>(GetMachineInfoCategoriesQueryDocument, options);
      }
export function useGetMachineInfoCategoriesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineInfoCategoriesQueryQuery, GetMachineInfoCategoriesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineInfoCategoriesQueryQuery, GetMachineInfoCategoriesQueryQueryVariables>(GetMachineInfoCategoriesQueryDocument, options);
        }
export type GetMachineInfoCategoriesQueryQueryHookResult = ReturnType<typeof useGetMachineInfoCategoriesQueryQuery>;
export type GetMachineInfoCategoriesQueryLazyQueryHookResult = ReturnType<typeof useGetMachineInfoCategoriesQueryLazyQuery>;
export type GetMachineInfoCategoriesQueryQueryResult = Apollo.QueryResult<GetMachineInfoCategoriesQueryQuery, GetMachineInfoCategoriesQueryQueryVariables>;
export const GetMachineWithMachineInfoCategoriesQueryDocument = gql`
    query getMachineWithMachineInfoCategoriesQuery($machineId: ID!) {
  machine(machineId: $machineId) {
    id
    machineinfoanswerSet {
      id
      answer
      machineInfoQuestionOption {
        id
        title
      }
      machineInfoQuestion {
        id
        machineInfoCategory {
          id
        }
      }
    }
    maintenancesetting {
      id
      scheduleType
      startDate
    }
  }
  machineInfoCategories {
    id
    name
    machineinfoquestionSet {
      id
      question
      answerType
      showProductCard
      showDrive
      machineInfoCategory {
        id
      }
      machineinfotagSet {
        id
        name
      }
      machineinfoquestionoptionSet {
        id
        title
      }
    }
  }
  machineInfoTags {
    id
    name
  }
  machines {
    id
    identifier
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetMachineWithMachineInfoCategoriesQueryQuery__
 *
 * To run a query within a React component, call `useGetMachineWithMachineInfoCategoriesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineWithMachineInfoCategoriesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineWithMachineInfoCategoriesQueryQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetMachineWithMachineInfoCategoriesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachineWithMachineInfoCategoriesQueryQuery, GetMachineWithMachineInfoCategoriesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineWithMachineInfoCategoriesQueryQuery, GetMachineWithMachineInfoCategoriesQueryQueryVariables>(GetMachineWithMachineInfoCategoriesQueryDocument, options);
      }
export function useGetMachineWithMachineInfoCategoriesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineWithMachineInfoCategoriesQueryQuery, GetMachineWithMachineInfoCategoriesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineWithMachineInfoCategoriesQueryQuery, GetMachineWithMachineInfoCategoriesQueryQueryVariables>(GetMachineWithMachineInfoCategoriesQueryDocument, options);
        }
export type GetMachineWithMachineInfoCategoriesQueryQueryHookResult = ReturnType<typeof useGetMachineWithMachineInfoCategoriesQueryQuery>;
export type GetMachineWithMachineInfoCategoriesQueryLazyQueryHookResult = ReturnType<typeof useGetMachineWithMachineInfoCategoriesQueryLazyQuery>;
export type GetMachineWithMachineInfoCategoriesQueryQueryResult = Apollo.QueryResult<GetMachineWithMachineInfoCategoriesQueryQuery, GetMachineWithMachineInfoCategoriesQueryQueryVariables>;
export const GetMachineBreakdownsForMachineDocument = gql`
    query getMachineBreakdownsForMachine($machineId: ID!) {
  machineBreakdownsForMachine(machineId: $machineId) {
    id
    title
    information
    fixedAt
    fixedBy {
      id
      firstName
      lastName
    }
    machinebreakdowncommentSet {
      id
      comment
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetMachineBreakdownsForMachineQuery__
 *
 * To run a query within a React component, call `useGetMachineBreakdownsForMachineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineBreakdownsForMachineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineBreakdownsForMachineQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetMachineBreakdownsForMachineQuery(baseOptions: Apollo.QueryHookOptions<GetMachineBreakdownsForMachineQuery, GetMachineBreakdownsForMachineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineBreakdownsForMachineQuery, GetMachineBreakdownsForMachineQueryVariables>(GetMachineBreakdownsForMachineDocument, options);
      }
export function useGetMachineBreakdownsForMachineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineBreakdownsForMachineQuery, GetMachineBreakdownsForMachineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineBreakdownsForMachineQuery, GetMachineBreakdownsForMachineQueryVariables>(GetMachineBreakdownsForMachineDocument, options);
        }
export type GetMachineBreakdownsForMachineQueryHookResult = ReturnType<typeof useGetMachineBreakdownsForMachineQuery>;
export type GetMachineBreakdownsForMachineLazyQueryHookResult = ReturnType<typeof useGetMachineBreakdownsForMachineLazyQuery>;
export type GetMachineBreakdownsForMachineQueryResult = Apollo.QueryResult<GetMachineBreakdownsForMachineQuery, GetMachineBreakdownsForMachineQueryVariables>;
export const GetMakesAndModelsQueryDocument = gql`
    query getMakesAndModelsQuery {
  machineMakes {
    id
    title
    machinemodelSet {
      id
      title
    }
  }
}
    `;

/**
 * __useGetMakesAndModelsQueryQuery__
 *
 * To run a query within a React component, call `useGetMakesAndModelsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMakesAndModelsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMakesAndModelsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMakesAndModelsQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetMakesAndModelsQueryQuery, GetMakesAndModelsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMakesAndModelsQueryQuery, GetMakesAndModelsQueryQueryVariables>(GetMakesAndModelsQueryDocument, options);
      }
export function useGetMakesAndModelsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMakesAndModelsQueryQuery, GetMakesAndModelsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMakesAndModelsQueryQuery, GetMakesAndModelsQueryQueryVariables>(GetMakesAndModelsQueryDocument, options);
        }
export type GetMakesAndModelsQueryQueryHookResult = ReturnType<typeof useGetMakesAndModelsQueryQuery>;
export type GetMakesAndModelsQueryLazyQueryHookResult = ReturnType<typeof useGetMakesAndModelsQueryLazyQuery>;
export type GetMakesAndModelsQueryQueryResult = Apollo.QueryResult<GetMakesAndModelsQueryQuery, GetMakesAndModelsQueryQueryVariables>;
export const GetMachineInfoQuestionsForDrivesQueryDocument = gql`
    query getMachineInfoQuestionsForDrivesQuery($machineIds: [ID]!) {
  machineInfoQuestionsForDrives(machineIds: $machineIds) {
    id
    question
    machineinfoanswerSet {
      id
      answer
      machine {
        id
      }
    }
  }
}
    `;

/**
 * __useGetMachineInfoQuestionsForDrivesQueryQuery__
 *
 * To run a query within a React component, call `useGetMachineInfoQuestionsForDrivesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineInfoQuestionsForDrivesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineInfoQuestionsForDrivesQueryQuery({
 *   variables: {
 *      machineIds: // value for 'machineIds'
 *   },
 * });
 */
export function useGetMachineInfoQuestionsForDrivesQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachineInfoQuestionsForDrivesQueryQuery, GetMachineInfoQuestionsForDrivesQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineInfoQuestionsForDrivesQueryQuery, GetMachineInfoQuestionsForDrivesQueryQueryVariables>(GetMachineInfoQuestionsForDrivesQueryDocument, options);
      }
export function useGetMachineInfoQuestionsForDrivesQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineInfoQuestionsForDrivesQueryQuery, GetMachineInfoQuestionsForDrivesQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineInfoQuestionsForDrivesQueryQuery, GetMachineInfoQuestionsForDrivesQueryQueryVariables>(GetMachineInfoQuestionsForDrivesQueryDocument, options);
        }
export type GetMachineInfoQuestionsForDrivesQueryQueryHookResult = ReturnType<typeof useGetMachineInfoQuestionsForDrivesQueryQuery>;
export type GetMachineInfoQuestionsForDrivesQueryLazyQueryHookResult = ReturnType<typeof useGetMachineInfoQuestionsForDrivesQueryLazyQuery>;
export type GetMachineInfoQuestionsForDrivesQueryQueryResult = Apollo.QueryResult<GetMachineInfoQuestionsForDrivesQueryQuery, GetMachineInfoQuestionsForDrivesQueryQueryVariables>;
export const GetMachinesForCatalogRowAvailableQueryDocument = gql`
    query getMachinesForCatalogRowAvailableQuery($catalogRowId: ID!, $dateFrom: Date!, $dateTo: Date!) {
  machinesForCatalogRowAvailable(
    catalogRowId: $catalogRowId
    dateFrom: $dateFrom
    dateTo: $dateTo
  ) {
    ...MachinesAvailableFields
  }
}
    ${MachinesAvailableFieldsFragmentDoc}`;

/**
 * __useGetMachinesForCatalogRowAvailableQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesForCatalogRowAvailableQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesForCatalogRowAvailableQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesForCatalogRowAvailableQueryQuery({
 *   variables: {
 *      catalogRowId: // value for 'catalogRowId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useGetMachinesForCatalogRowAvailableQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesForCatalogRowAvailableQueryQuery, GetMachinesForCatalogRowAvailableQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesForCatalogRowAvailableQueryQuery, GetMachinesForCatalogRowAvailableQueryQueryVariables>(GetMachinesForCatalogRowAvailableQueryDocument, options);
      }
export function useGetMachinesForCatalogRowAvailableQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesForCatalogRowAvailableQueryQuery, GetMachinesForCatalogRowAvailableQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesForCatalogRowAvailableQueryQuery, GetMachinesForCatalogRowAvailableQueryQueryVariables>(GetMachinesForCatalogRowAvailableQueryDocument, options);
        }
export type GetMachinesForCatalogRowAvailableQueryQueryHookResult = ReturnType<typeof useGetMachinesForCatalogRowAvailableQueryQuery>;
export type GetMachinesForCatalogRowAvailableQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesForCatalogRowAvailableQueryLazyQuery>;
export type GetMachinesForCatalogRowAvailableQueryQueryResult = Apollo.QueryResult<GetMachinesForCatalogRowAvailableQueryQuery, GetMachinesForCatalogRowAvailableQueryQueryVariables>;
export const GetMachinesSearchAvailableQueryDocument = gql`
    query getMachinesSearchAvailableQuery($dateFrom: Date!, $dateTo: Date!, $search: String!) {
  machinesSearchAvailable(dateFrom: $dateFrom, dateTo: $dateTo, search: $search) {
    ...MachinesAvailableFields
  }
}
    ${MachinesAvailableFieldsFragmentDoc}`;

/**
 * __useGetMachinesSearchAvailableQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesSearchAvailableQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesSearchAvailableQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesSearchAvailableQueryQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetMachinesSearchAvailableQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesSearchAvailableQueryQuery, GetMachinesSearchAvailableQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesSearchAvailableQueryQuery, GetMachinesSearchAvailableQueryQueryVariables>(GetMachinesSearchAvailableQueryDocument, options);
      }
export function useGetMachinesSearchAvailableQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesSearchAvailableQueryQuery, GetMachinesSearchAvailableQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesSearchAvailableQueryQuery, GetMachinesSearchAvailableQueryQueryVariables>(GetMachinesSearchAvailableQueryDocument, options);
        }
export type GetMachinesSearchAvailableQueryQueryHookResult = ReturnType<typeof useGetMachinesSearchAvailableQueryQuery>;
export type GetMachinesSearchAvailableQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesSearchAvailableQueryLazyQuery>;
export type GetMachinesSearchAvailableQueryQueryResult = Apollo.QueryResult<GetMachinesSearchAvailableQueryQuery, GetMachinesSearchAvailableQueryQueryVariables>;
export const GetMachineBulkAmountsForMachineQueryDocument = gql`
    query getMachineBulkAmountsForMachineQuery($machineId: ID!) {
  machineBulkAmountsForMachine(machineId: $machineId) {
    id
    bulkAmount
    location {
      id
    }
  }
}
    `;

/**
 * __useGetMachineBulkAmountsForMachineQueryQuery__
 *
 * To run a query within a React component, call `useGetMachineBulkAmountsForMachineQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineBulkAmountsForMachineQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineBulkAmountsForMachineQueryQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useGetMachineBulkAmountsForMachineQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachineBulkAmountsForMachineQueryQuery, GetMachineBulkAmountsForMachineQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineBulkAmountsForMachineQueryQuery, GetMachineBulkAmountsForMachineQueryQueryVariables>(GetMachineBulkAmountsForMachineQueryDocument, options);
      }
export function useGetMachineBulkAmountsForMachineQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineBulkAmountsForMachineQueryQuery, GetMachineBulkAmountsForMachineQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineBulkAmountsForMachineQueryQuery, GetMachineBulkAmountsForMachineQueryQueryVariables>(GetMachineBulkAmountsForMachineQueryDocument, options);
        }
export type GetMachineBulkAmountsForMachineQueryQueryHookResult = ReturnType<typeof useGetMachineBulkAmountsForMachineQueryQuery>;
export type GetMachineBulkAmountsForMachineQueryLazyQueryHookResult = ReturnType<typeof useGetMachineBulkAmountsForMachineQueryLazyQuery>;
export type GetMachineBulkAmountsForMachineQueryQueryResult = Apollo.QueryResult<GetMachineBulkAmountsForMachineQueryQuery, GetMachineBulkAmountsForMachineQueryQueryVariables>;
export const GetMachineBulkBreakdownsForMachineQueryDocument = gql`
    query getMachineBulkBreakdownsForMachineQuery($machineId: ID!, $reservationId: ID) {
  machineBulkBreakdownsForMachine(
    machineId: $machineId
    reservationId: $reservationId
  ) {
    id
    amountBroken
    fixable
    fixedAt
  }
}
    `;

/**
 * __useGetMachineBulkBreakdownsForMachineQueryQuery__
 *
 * To run a query within a React component, call `useGetMachineBulkBreakdownsForMachineQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachineBulkBreakdownsForMachineQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachineBulkBreakdownsForMachineQueryQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *      reservationId: // value for 'reservationId'
 *   },
 * });
 */
export function useGetMachineBulkBreakdownsForMachineQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachineBulkBreakdownsForMachineQueryQuery, GetMachineBulkBreakdownsForMachineQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachineBulkBreakdownsForMachineQueryQuery, GetMachineBulkBreakdownsForMachineQueryQueryVariables>(GetMachineBulkBreakdownsForMachineQueryDocument, options);
      }
export function useGetMachineBulkBreakdownsForMachineQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachineBulkBreakdownsForMachineQueryQuery, GetMachineBulkBreakdownsForMachineQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachineBulkBreakdownsForMachineQueryQuery, GetMachineBulkBreakdownsForMachineQueryQueryVariables>(GetMachineBulkBreakdownsForMachineQueryDocument, options);
        }
export type GetMachineBulkBreakdownsForMachineQueryQueryHookResult = ReturnType<typeof useGetMachineBulkBreakdownsForMachineQueryQuery>;
export type GetMachineBulkBreakdownsForMachineQueryLazyQueryHookResult = ReturnType<typeof useGetMachineBulkBreakdownsForMachineQueryLazyQuery>;
export type GetMachineBulkBreakdownsForMachineQueryQueryResult = Apollo.QueryResult<GetMachineBulkBreakdownsForMachineQueryQuery, GetMachineBulkBreakdownsForMachineQueryQueryVariables>;
export const GetMachinesForCatalogRowsDocument = gql`
    query getMachinesForCatalogRows($catalogRowIds: [ID]!, $locationId: ID) {
  machinesForCatalogRows(catalogRowIds: $catalogRowIds, locationId: $locationId) {
    id
    identifier
    serial
    rentThrough
    rentThroughCompany
    rentThroughInformation
    acquisitionDate
    dateRemoved
    bulkProduct
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
    location {
      id
      name
    }
    catalogRows {
      id
    }
  }
}
    `;

/**
 * __useGetMachinesForCatalogRowsQuery__
 *
 * To run a query within a React component, call `useGetMachinesForCatalogRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesForCatalogRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesForCatalogRowsQuery({
 *   variables: {
 *      catalogRowIds: // value for 'catalogRowIds'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetMachinesForCatalogRowsQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesForCatalogRowsQuery, GetMachinesForCatalogRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesForCatalogRowsQuery, GetMachinesForCatalogRowsQueryVariables>(GetMachinesForCatalogRowsDocument, options);
      }
export function useGetMachinesForCatalogRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesForCatalogRowsQuery, GetMachinesForCatalogRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesForCatalogRowsQuery, GetMachinesForCatalogRowsQueryVariables>(GetMachinesForCatalogRowsDocument, options);
        }
export type GetMachinesForCatalogRowsQueryHookResult = ReturnType<typeof useGetMachinesForCatalogRowsQuery>;
export type GetMachinesForCatalogRowsLazyQueryHookResult = ReturnType<typeof useGetMachinesForCatalogRowsLazyQuery>;
export type GetMachinesForCatalogRowsQueryResult = Apollo.QueryResult<GetMachinesForCatalogRowsQuery, GetMachinesForCatalogRowsQueryVariables>;
export const GetMachinesWithoutCatalogRowDocument = gql`
    query getMachinesWithoutCatalogRow($catalogId: ID!) {
  machinesWithoutCatalogRow(catalogId: $catalogId) {
    id
    identifier
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useGetMachinesWithoutCatalogRowQuery__
 *
 * To run a query within a React component, call `useGetMachinesWithoutCatalogRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesWithoutCatalogRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesWithoutCatalogRowQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetMachinesWithoutCatalogRowQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesWithoutCatalogRowQuery, GetMachinesWithoutCatalogRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesWithoutCatalogRowQuery, GetMachinesWithoutCatalogRowQueryVariables>(GetMachinesWithoutCatalogRowDocument, options);
      }
export function useGetMachinesWithoutCatalogRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesWithoutCatalogRowQuery, GetMachinesWithoutCatalogRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesWithoutCatalogRowQuery, GetMachinesWithoutCatalogRowQueryVariables>(GetMachinesWithoutCatalogRowDocument, options);
        }
export type GetMachinesWithoutCatalogRowQueryHookResult = ReturnType<typeof useGetMachinesWithoutCatalogRowQuery>;
export type GetMachinesWithoutCatalogRowLazyQueryHookResult = ReturnType<typeof useGetMachinesWithoutCatalogRowLazyQuery>;
export type GetMachinesWithoutCatalogRowQueryResult = Apollo.QueryResult<GetMachinesWithoutCatalogRowQuery, GetMachinesWithoutCatalogRowQueryVariables>;
export const MaintenanceHistoryForMachineDocument = gql`
    query maintenanceHistoryForMachine($machineId: ID!) {
  maintenanceHistoryForMachine(machineId: $machineId) {
    id
    album {
      id
      maintenanceimageSet {
        id
        image
        imageType
        imageName
        eurosWarranty
        eurosOwnCosts
        information
        createdBy {
          id
          firstName
          lastName
        }
        createdAt
      }
    }
    maintenanceInterval {
      id
      intervalType
      hours
      days
      description
    }
    operationhour {
      id
      hours
    }
    serviceTechnician {
      id
      firstName
      lastName
    }
    serviceContractor
    maintenancecommentSet {
      id
      comment
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
    }
    servicedAt
    serviceEndBy
    verifiedAt
    verifiedBy {
      id
      firstName
      lastName
    }
  }
  maintenanceNextForMachine(machineId: $machineId) {
    nextMaintenanceDate
    intervalType
    hours
    days
    description
  }
}
    `;

/**
 * __useMaintenanceHistoryForMachineQuery__
 *
 * To run a query within a React component, call `useMaintenanceHistoryForMachineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceHistoryForMachineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceHistoryForMachineQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useMaintenanceHistoryForMachineQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceHistoryForMachineQuery, MaintenanceHistoryForMachineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceHistoryForMachineQuery, MaintenanceHistoryForMachineQueryVariables>(MaintenanceHistoryForMachineDocument, options);
      }
export function useMaintenanceHistoryForMachineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceHistoryForMachineQuery, MaintenanceHistoryForMachineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceHistoryForMachineQuery, MaintenanceHistoryForMachineQueryVariables>(MaintenanceHistoryForMachineDocument, options);
        }
export type MaintenanceHistoryForMachineQueryHookResult = ReturnType<typeof useMaintenanceHistoryForMachineQuery>;
export type MaintenanceHistoryForMachineLazyQueryHookResult = ReturnType<typeof useMaintenanceHistoryForMachineLazyQuery>;
export type MaintenanceHistoryForMachineQueryResult = Apollo.QueryResult<MaintenanceHistoryForMachineQuery, MaintenanceHistoryForMachineQueryVariables>;
export const MaintenanceNextForMachineDocument = gql`
    query maintenanceNextForMachine($machineId: ID!) {
  maintenanceNextForMachine(machineId: $machineId) {
    nextMaintenanceDate
    nextMaintenanceInterval {
      id
    }
  }
}
    `;

/**
 * __useMaintenanceNextForMachineQuery__
 *
 * To run a query within a React component, call `useMaintenanceNextForMachineQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceNextForMachineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceNextForMachineQuery({
 *   variables: {
 *      machineId: // value for 'machineId'
 *   },
 * });
 */
export function useMaintenanceNextForMachineQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceNextForMachineQuery, MaintenanceNextForMachineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceNextForMachineQuery, MaintenanceNextForMachineQueryVariables>(MaintenanceNextForMachineDocument, options);
      }
export function useMaintenanceNextForMachineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceNextForMachineQuery, MaintenanceNextForMachineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceNextForMachineQuery, MaintenanceNextForMachineQueryVariables>(MaintenanceNextForMachineDocument, options);
        }
export type MaintenanceNextForMachineQueryHookResult = ReturnType<typeof useMaintenanceNextForMachineQuery>;
export type MaintenanceNextForMachineLazyQueryHookResult = ReturnType<typeof useMaintenanceNextForMachineLazyQuery>;
export type MaintenanceNextForMachineQueryResult = Apollo.QueryResult<MaintenanceNextForMachineQuery, MaintenanceNextForMachineQueryVariables>;
export const GetMachinesAndOperationHoursQueryDocument = gql`
    query getMachinesAndOperationHoursQuery($machineIds: [ID]!) {
  machinesWithIds(machineIds: $machineIds) {
    id
    operationhourSet {
      id
      dateChecked
      hours
      hoursExtra
    }
  }
}
    `;

/**
 * __useGetMachinesAndOperationHoursQueryQuery__
 *
 * To run a query within a React component, call `useGetMachinesAndOperationHoursQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesAndOperationHoursQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesAndOperationHoursQueryQuery({
 *   variables: {
 *      machineIds: // value for 'machineIds'
 *   },
 * });
 */
export function useGetMachinesAndOperationHoursQueryQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesAndOperationHoursQueryQuery, GetMachinesAndOperationHoursQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesAndOperationHoursQueryQuery, GetMachinesAndOperationHoursQueryQueryVariables>(GetMachinesAndOperationHoursQueryDocument, options);
      }
export function useGetMachinesAndOperationHoursQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesAndOperationHoursQueryQuery, GetMachinesAndOperationHoursQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesAndOperationHoursQueryQuery, GetMachinesAndOperationHoursQueryQueryVariables>(GetMachinesAndOperationHoursQueryDocument, options);
        }
export type GetMachinesAndOperationHoursQueryQueryHookResult = ReturnType<typeof useGetMachinesAndOperationHoursQueryQuery>;
export type GetMachinesAndOperationHoursQueryLazyQueryHookResult = ReturnType<typeof useGetMachinesAndOperationHoursQueryLazyQuery>;
export type GetMachinesAndOperationHoursQueryQueryResult = Apollo.QueryResult<GetMachinesAndOperationHoursQueryQuery, GetMachinesAndOperationHoursQueryQueryVariables>;
export const GetMachinesForLocationsWithMaintenanceSettingsDocument = gql`
    query getMachinesForLocationsWithMaintenanceSettings($locationIds: [ID]!) {
  machinesForLocationsWithMaintenanceSettings(locationIds: $locationIds) {
    id
    identifier
    serial
    dateRemoved
    yearModel
    information
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
    location {
      id
      name
    }
    maintenancesetting {
      id
      scheduleType
      maintenanceintervalSet {
        id
        intervalType
        description
        hours
        days
      }
    }
  }
}
    `;

/**
 * __useGetMachinesForLocationsWithMaintenanceSettingsQuery__
 *
 * To run a query within a React component, call `useGetMachinesForLocationsWithMaintenanceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMachinesForLocationsWithMaintenanceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMachinesForLocationsWithMaintenanceSettingsQuery({
 *   variables: {
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useGetMachinesForLocationsWithMaintenanceSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetMachinesForLocationsWithMaintenanceSettingsQuery, GetMachinesForLocationsWithMaintenanceSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMachinesForLocationsWithMaintenanceSettingsQuery, GetMachinesForLocationsWithMaintenanceSettingsQueryVariables>(GetMachinesForLocationsWithMaintenanceSettingsDocument, options);
      }
export function useGetMachinesForLocationsWithMaintenanceSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMachinesForLocationsWithMaintenanceSettingsQuery, GetMachinesForLocationsWithMaintenanceSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMachinesForLocationsWithMaintenanceSettingsQuery, GetMachinesForLocationsWithMaintenanceSettingsQueryVariables>(GetMachinesForLocationsWithMaintenanceSettingsDocument, options);
        }
export type GetMachinesForLocationsWithMaintenanceSettingsQueryHookResult = ReturnType<typeof useGetMachinesForLocationsWithMaintenanceSettingsQuery>;
export type GetMachinesForLocationsWithMaintenanceSettingsLazyQueryHookResult = ReturnType<typeof useGetMachinesForLocationsWithMaintenanceSettingsLazyQuery>;
export type GetMachinesForLocationsWithMaintenanceSettingsQueryResult = Apollo.QueryResult<GetMachinesForLocationsWithMaintenanceSettingsQuery, GetMachinesForLocationsWithMaintenanceSettingsQueryVariables>;