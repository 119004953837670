import { gql } from "@apollo/client";
import { Query } from "../../entity/types";
import { FRAG_SETTINGS } from "../mutations/settings";

/* Nothing requiring permissions should be added to me query
   so we don't log user out after login if no permissions have been set. */
export interface QueryResultMe {
  me: Query["me"];
  myPermissions: Query["myPermissions"];
  settings: Query["settings"];
}
export const ME_QUERY = gql`
  ${FRAG_SETTINGS}
  query getMe {
    me {
      id
      username
      firstName
      lastName
      email
      dateJoined
      lastLogin
      groups {
        id
        name
      }
      UserInfo {
        phone
        address
        postcode
        district
        language
        location {
          id
        }
      }
    }
    myPermissions
    settings {
      ...SettingsFields
    }
  }
`;

export interface QueryResultProfile {
  user: Query["user"];
}
export const PROFILE_QUERY = gql`
  query getProfile($id: ID!) {
    user(id: $id) {
      id
      username
      firstName
      lastName
      email
      dateJoined
      lastLogin
      UserInfo {
        phone
        address
        postcode
        district
        language
        location {
          id
        }
      }
      groups {
        id
        name
      }
    }
  }
`;

export interface QueryResultProfileForeigns {
  groups: Query["groups"];
  locations: Query["locations"];
}
export const PROFILE_FOREIGNS_QUERY = gql`
  query getProfileForeigns {
    groups {
      id
      name
    }
    locations {
      id
      name
    }
  }
`;
