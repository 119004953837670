import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import { useLazyQuery, useMutation } from "@apollo/client";
import { checkPermission } from "../../utils/permissions";
import {
  CatalogType,
  Mutation,
  MutationDeleteCatalogArgs,
  MutationUpdateCatalogArgs,
  QueryErrorsSettingCatalogActiveArgs,
} from "../../entity/types";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import {
  DELETE_CATALOG_MUTATION,
  UPDATE_CATALOG_MUTATION,
} from "../../apollo/mutations/catalogs";
import { DATE_FORMAT_ISO, TIME_FORMAT_ISO } from "../../utils/constants";
import { Button } from "react-bootstrap";
import { format } from "date-fns";
import { dialogConfirm } from "../../utils/dialogs";
import { useHistory } from "react-router-dom";
import { URL_SETTINGS_CATALOG } from "../../utils/urls";
import { newDate } from "../../utils/dates";
import { updateCacheCatalogEdit } from "../../utils/cache";
import {
  GET_ERRORS_SETTING_CATALOG_ACTIVE_QUERY,
  QueryResultErrorsSettingCatalogActive,
} from "../../apollo/queries/catalogs";
import ButtonPdfOffer from "../Shared/ButtonPdfOffer";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function CatalogSetActive({ classes, catalog }: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const [catalogEdited, setCatalogEdited] = useState(catalog);

  const [getErrorsSettingActive, { loading: loadingErrorsSettingActive }] =
    useLazyQuery<
      QueryResultErrorsSettingCatalogActive,
      QueryErrorsSettingCatalogActiveArgs
    >(GET_ERRORS_SETTING_CATALOG_ACTIVE_QUERY, {
      fetchPolicy: "network-only",
      variables: {
        catalogId: catalogEdited.id,
      },
      onError: (error) => {
        handleError(error);
      },
    });

  const [updateCatalog, { loading: loadingUpdateCatalog }] = useMutation<
    Mutation,
    MutationUpdateCatalogArgs
  >(UPDATE_CATALOG_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteCatalog, { loading: loadingDeleteCatalog }] = useMutation<
    Mutation,
    MutationDeleteCatalogArgs
  >(DELETE_CATALOG_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      history.push(URL_SETTINGS_CATALOG);
    },
    update: (cache) => {
      updateCacheCatalogEdit(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalog",
  ]);

  const disabled = !hasPermissionEdit;
  const locked = Boolean(catalog.setActiveAt);

  const onSave = () => {
    const callbackSave = () => {
      updateCatalog({
        variables: {
          catalogId: catalogEdited.id,
          name: catalogEdited.name,
          setActiveAt: catalogEdited.setActiveAt,
        },
      });
    };

    if (catalogEdited.setActiveAt) {
      getErrorsSettingActive().then((result) => {
        const errors: string[] = result.data?.errorsSettingCatalogActive
          ? result.data.errorsSettingCatalogActive
          : [];
        if (errors.length === 0) {
          callbackSave();
        } else {
          dialogConfirm(t, errors.join("\n\n"), () => callbackSave());
        }
      });
    } else {
      callbackSave();
    }
  };

  const onDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalog({
        variables: {
          catalogId: catalogEdited.id,
        },
      });
    });
  };

  return (
    <div
      className={`mb-3 ${
        loadingUpdateCatalog ||
        loadingDeleteCatalog ||
        loadingErrorsSettingActive
          ? "loading"
          : ""
      }`}
    >
      <FormControl>
        <TextField
          className={`me-2`}
          label={t("name")}
          value={catalogEdited.name}
          onChange={(event) =>
            setCatalogEdited({ ...catalogEdited, name: event.target.value })
          }
          onBlur={onSave}
          inputProps={{ maxLength: 100 }}
          disabled={disabled}
        />
      </FormControl>
      <FormControl>
        <TextField
          className={`me-2`}
          type="date"
          label={t("set_active_at_date")}
          value={
            catalogEdited.setActiveAt
              ? format(newDate(catalogEdited.setActiveAt), DATE_FORMAT_ISO)
              : ""
          }
          onChange={(event) => {
            let date = null;
            if (event.target.value) {
              date = newDate(event.target.value);
            }
            if (date && catalogEdited.setActiveAt) {
              let oldSetActiveAt = newDate(catalogEdited.setActiveAt);
              date.setHours(
                oldSetActiveAt.getHours(),
                oldSetActiveAt.getMinutes(),
                oldSetActiveAt.getSeconds(),
                oldSetActiveAt.getMilliseconds()
              );
            }
            setCatalogEdited({ ...catalogEdited, setActiveAt: date });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled || locked}
        />
      </FormControl>
      <FormControl>
        <TextField
          className={`me-3 ${classes.txtTime}`}
          type="time"
          label={t("set_active_at_time")}
          value={
            catalogEdited.setActiveAt
              ? format(newDate(catalogEdited.setActiveAt), TIME_FORMAT_ISO)
              : ""
          }
          onChange={(event) => {
            let date = newDate(
              catalogEdited.setActiveAt ? catalogEdited.setActiveAt : undefined
            );
            const dateStr =
              format(date, DATE_FORMAT_ISO) + " " + event.target.value;
            setCatalogEdited({
              ...catalogEdited,
              setActiveAt: newDate(dateStr),
            });
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled || locked}
        />
      </FormControl>
      {!catalog.setActiveAt && !disabled && !locked && (
        <>
          <Button className="mt-2 me-2" onClick={onSave}>
            {t("catalog_set_active")}
          </Button>
          <Button className="mt-2 me-2" onClick={onDelete} variant="light">
            {t("delete")}
          </Button>
        </>
      )}
      <div className="d-inline-block mt-2">
        <ButtonPdfOffer catalog={catalogEdited} />
      </div>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    txtTime: {
      minWidth: "100px",
    },
  });

export default withStyles(styles)(CatalogSetActive);
