import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ButtonLoad from "../Shared/ButtonLoad";
import Lock from "@material-ui/icons/Lock";
import Error from "../Shared/Error";
import { useTranslation } from "react-i18next";
import { LOGIN_MUTATION } from "../../apollo/mutations/login";
import { createStyles, Theme } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { SET_IS_LOGGED_IN_MUTATION } from "../../apollo/client/is_logged_in";
import { handleError } from "../../entity/ErrorHandler";
import { onLogin } from "../../utils/login";

interface Props extends WithStyles<typeof styles> {}

function Login({ classes }: Props) {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loading = false;
  const error = false;

  const [login, { loading: loadingLogin }] = useMutation(LOGIN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: (response) => {
      onLogin(response.tokenAuth.token);
      setIsLoggedIn({ variables: { isLoggedIn: true } });
      window.location.reload();
    },
  });
  const [setIsLoggedIn] = useMutation(SET_IS_LOGGED_IN_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await login({ variables: { username, password } });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <Lock />
        </Avatar>
        <Typography variant="subtitle1">{t("login")}</Typography>

        <form
          onSubmit={(event) => handleSubmit(event)}
          className={classes.form}
        >
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">{t("username")}</InputLabel>
            <Input
              id="username"
              onChange={(event) => setUsername(event.target.value)}
            ></Input>
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">{t("password")}</InputLabel>
            <Input
              id="password"
              type="password"
              onChange={(event) => setPassword(event.target.value)}
            ></Input>
          </FormControl>
          <ButtonLoad
            type="submit"
            className={`w-100 ${classes.submit} ${
              loadingLogin ? "loading" : ""
            }`}
            loading={loading}
            disabled={!username.trim() || !password.trim()}
          >
            {loading ? t("logging_in") : t("login")}
          </ButtonLoad>

          {/* Error handling */}
          {error && <Error error={error} />}
        </form>
      </Paper>
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "auto",
      display: "block",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.up("md")]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    submit: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  });

export default withStyles(styles)(Login);
