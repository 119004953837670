import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUpdateReservationStampsArgs,
  ReservationsReservationStatusChoices,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/pro-light-svg-icons";
import ButtonLoad from "../Shared/ButtonLoad";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { UPDATE_RESERVATION_STAMPS_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservationStampsMutation } from "../../utils/cache";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  setOpenDialogReturnCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

function ReservationButtonReturnChecked({
  classes,
  reservation,
  setOpenDialogReturnCheck,
}: Props) {
  const { t } = useTranslation();

  const [updateReservationStamps, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationStampsArgs
  >(UPDATE_RESERVATION_STAMPS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    onCompleted: () => {
      setOpenDialogReturnCheck(false);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEditReservation = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);
  const hasPermissionSetReturnCheckedAt = checkPermission(myPermissions, [
    "reservations.set_return_checked_at",
  ]);

  const onClickReturnCheckedAt = () => {
    updateReservationStamps({
      variables: {
        reservationId: reservation.id,
        returnCheckedAt: reservation.returnCheckedAt ? null : new Date(),
      },
    });
  };

  const onClickReturnCheckedPreAt = () => {
    updateReservationStamps({
      variables: {
        reservationId: reservation.id,
        returnCheckedPreAt: reservation.returnCheckedPreAt ? null : new Date(),
      },
    });
  };

  const isDonePre = Boolean(reservation.returnCheckedPreAt);
  const isDone = Boolean(reservation.returnCheckedAt);

  return (
    <>
      <ButtonLoad
        loading={loadingUpdate}
        className="mb-1 me-1"
        key="returnCheckedPreAt"
        variant={isDonePre ? "primary" : "danger"}
        onClick={onClickReturnCheckedPreAt}
        disabled={
          !hasPermissionEditReservation ||
          (isDonePre &&
            reservation.status ===
              ReservationsReservationStatusChoices.Invoiced) ||
          !reservation.returnCheckedCatalogAt
        }
      >
        <FontAwesomeIcon icon={faTasks} className="d-none d-sm-inline" />
        <span className="ms-2">{t("toggle_return_checked_pre_at")}</span>
      </ButtonLoad>
      <ButtonLoad
        loading={loadingUpdate}
        className="mb-1 me-1"
        key="returnCheckedAt"
        variant={isDone ? "primary" : "danger"}
        onClick={onClickReturnCheckedAt}
        disabled={
          !hasPermissionEditReservation ||
          (isDone &&
            reservation.status ===
              ReservationsReservationStatusChoices.Invoiced) ||
          !reservation.returnCheckedCatalogAt ||
          !reservation.returnCheckedPreAt ||
          !hasPermissionSetReturnCheckedAt
        }
      >
        <FontAwesomeIcon icon={faTasks} className="d-none d-sm-inline" />
        <span className="ms-2">{t("toggle_return_checked_at")}</span>
      </ButtonLoad>
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationButtonReturnChecked);
