import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  Mutation,
  MutationUploadReservationImageBasicArgs,
  QueryReservationImageBasicsArgs,
} from "../../entity/types";
import { Col, Row } from "react-bootstrap";
import ReservationImageBasic from "./ReservationImageBasic";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_RESERVATION_IMAGE_BASICS_QUERY,
  QueryResultReservationImageBasics,
} from "../../apollo/queries/reservations";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import { useTranslation } from "react-i18next";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { UPLOAD_RESERVATION_IMAGE_BASIC_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";

interface Props extends WithStyles<typeof styles> {
  reservationId: string;
}

function ReservationImagesBasic({ classes, reservationId }: Props) {
  const { t } = useTranslation();

  const {
    loading: loadingImages,
    error: errorImages,
    data: dataImages,
  } = useQuery<
    QueryResultReservationImageBasics,
    QueryReservationImageBasicsArgs
  >(GET_RESERVATION_IMAGE_BASICS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageBasics"),
    variables: {
      reservationId: reservationId,
    },
  });

  const [uploadReservationImageBasic, { loading: loadingUpload }] = useMutation<
    Mutation,
    MutationUploadReservationImageBasicArgs
  >(UPLOAD_RESERVATION_IMAGE_BASIC_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("reservationImageBasics"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "reservations.add_reservationimagebasic",
  ]);

  if (loadingImages) return <LoadingSimple />;
  if (errorImages) return <Error error={errorImages} />;
  if (!dataImages) return <Error error={t("error_query_failed")} />;

  const reservationImagesBasic = dataImages?.reservationImageBasics
    ? dataImages.reservationImageBasics
    : [];

  function onChange({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) {
    if (files && validity.valid) {
      uploadReservationImageBasic({
        variables: {
          reservationId: reservationId,
          file: files[0],
        },
      });
    }
  }

  return (
    <div className={loadingUpload ? "loading" : ""}>
      {hasPermissionAdd && (
        <div className={classes.conUpload}>
          <input type="file" required onChange={onChange} accept="image/*" />
        </div>
      )}
      <Row className={classes.conImages}>
        {reservationImagesBasic.map((reservationImageBasic) => (
          <Col key={reservationImageBasic.id} xs={12} sm={6}>
            <ReservationImageBasic
              reservationImageBasic={reservationImageBasic}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "100%",
      marginBottom: spacing(2),
    },
    conUpload: {
      marginBottom: spacing(2),
    },
  });

export default withStyles(styles)(ReservationImagesBasic);
