import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export interface MutationResultCreateMachine {
  createMachine: Mutation["createMachine"];
}
export const CREATE_MACHINE_MUTATION = gql`
  mutation createMachine(
    $machineModelId: ID!
    $information: String!
    $identifier: String!
    $serial: String!
    $acquisitionDate: Date!
    $dateRemoved: Date
    $yearModel: Int!
    $hourTracking: Boolean!
    $rentThrough: Boolean!
    $rentThroughCompany: String!
    $rentThroughInformation: String!
    $rentThroughCommissionCase: String!
    $bulkProduct: Boolean!
    $catalogRowIds: [ID]!
    $locationId: ID!
  ) {
    createMachine(
      machineModelId: $machineModelId
      information: $information
      identifier: $identifier
      serial: $serial
      acquisitionDate: $acquisitionDate
      dateRemoved: $dateRemoved
      yearModel: $yearModel
      hourTracking: $hourTracking
      rentThrough: $rentThrough
      rentThroughCompany: $rentThroughCompany
      rentThroughInformation: $rentThroughInformation
      rentThroughCommissionCase: $rentThroughCommissionCase
      bulkProduct: $bulkProduct
      catalogRowIds: $catalogRowIds
      locationId: $locationId
    ) {
      machine {
        id
      }
    }
  }
`;

export const UPDATE_MACHINE_MUTATION = gql`
  mutation updateMachine(
    $machineId: ID!
    $machineModelId: ID
    $information: String
    $identifier: String
    $serial: String
    $acquisitionDate: Date
    $dateRemoved: Date
    $yearModel: Int
    $hourTracking: Boolean
    $rentThrough: Boolean
    $rentThroughCompany: String
    $rentThroughInformation: String
    $rentThroughCommissionCase: String
    $bulkProduct: Boolean
    $purchasePrice: Decimal
    $purchaseSupplier: String
    $purchaseInformation: String
    $financingType: String
    $financingLoanType: String
    $financingInterestType: String
    $financingInterestVariableType: String
    $financingInformation: String
    $financingCompany: String
    $financingAgreementNumber: String
    $financingDateStart: Date
    $financingDateEnd: Date
    $financingInterestRate: Decimal
    $financingInterestMargin: Decimal
    $catalogRowIds: [ID]
    $locationId: ID
  ) {
    updateMachine(
      machineId: $machineId
      machineModelId: $machineModelId
      information: $information
      identifier: $identifier
      serial: $serial
      acquisitionDate: $acquisitionDate
      dateRemoved: $dateRemoved
      yearModel: $yearModel
      hourTracking: $hourTracking
      rentThrough: $rentThrough
      rentThroughCompany: $rentThroughCompany
      rentThroughInformation: $rentThroughInformation
      rentThroughCommissionCase: $rentThroughCommissionCase
      bulkProduct: $bulkProduct
      purchasePrice: $purchasePrice
      purchaseSupplier: $purchaseSupplier
      purchaseInformation: $purchaseInformation
      financingType: $financingType
      financingLoanType: $financingLoanType
      financingInterestType: $financingInterestType
      financingInterestVariableType: $financingInterestVariableType
      financingInformation: $financingInformation
      financingCompany: $financingCompany
      financingAgreementNumber: $financingAgreementNumber
      financingDateStart: $financingDateStart
      financingDateEnd: $financingDateEnd
      financingInterestRate: $financingInterestRate
      financingInterestMargin: $financingInterestMargin
      catalogRowIds: $catalogRowIds
      locationId: $locationId
    ) {
      machine {
        id
        machineModel {
          id
        }
        information
        identifier
        serial
        acquisitionDate
        yearModel
        hourTracking
        bulkProduct
        purchasePrice
        purchaseSupplier
        purchaseInformation
        financingType
        financingLoanType
        financingInterestType
        financingInterestVariableType
        financingInformation
        financingCompany
        financingAgreementNumber
        financingDateStart
        financingDateEnd
        financingInterestRate
        financingInterestMargin
        catalogRows {
          id
        }
        location {
          id
        }
      }
    }
  }
`;

export const MOVE_MACHINE_TO_LOCATION_MUTATION = gql`
  mutation moveMachineToLocation($machineId: ID!, $locationId: ID!) {
    moveMachineToLocation(machineId: $machineId, locationId: $locationId) {
      machine {
        id
        location {
          id
        }
      }
    }
  }
`;

export const DELETE_MACHINE_MUTATION = gql`
  mutation deleteMachine($machineId: ID!) {
    deleteMachine(machineId: $machineId) {
      machineId
    }
  }
`;

export const CONNECT_MACHINE_CATALOG_EXTRA_ROW_RENTAL = gql`
  mutation connectMachineCatalogExtraRowRental($data: [ConnectIdsType]) {
    connectMachineCatalogExtraRowRental(data: $data) {
      machines {
        id
        catalogExtraRowsRental {
          id
        }
      }
    }
  }
`;

export const CONNECT_MACHINE_CATALOG_EXTRA_ROW_UNIT_PRICE = gql`
  mutation connectMachineCatalogExtraRowUnitPrice($data: [ConnectIdsType]) {
    connectMachineCatalogExtraRowUnitPrice(data: $data) {
      machines {
        id
        catalogExtraRowsUnitPrice {
          id
        }
      }
    }
  }
`;

export const COPY_MACHINE_CATALOG_EXTRA_ROW_RENTAL = gql`
  mutation copyMachineCatalogExtraRowRental(
    $machineIdCopyFrom: ID!
    $machineIdCopyTo: ID!
  ) {
    copyMachineCatalogExtraRowRental(
      machineIdCopyFrom: $machineIdCopyFrom
      machineIdCopyTo: $machineIdCopyTo
    ) {
      machine {
        id
        catalogExtraRowsRental {
          id
        }
      }
    }
  }
`;

export const COPY_MACHINE_CATALOG_EXTRA_ROW_UNIT_PRICE = gql`
  mutation copyMachineCatalogExtraRowUnitPrice(
    $machineIdCopyFrom: ID!
    $machineIdCopyTo: ID!
  ) {
    copyMachineCatalogExtraRowUnitPrice(
      machineIdCopyFrom: $machineIdCopyFrom
      machineIdCopyTo: $machineIdCopyTo
    ) {
      machine {
        id
        catalogExtraRowsUnitPrice {
          id
        }
      }
    }
  }
`;

export const CREATE_MACHINE_BREAKDOWN_MUTATION = gql`
  mutation createMachineBreakdown(
    $machineId: ID
    $catalogExtraRowRentalId: ID
    $stillRentable: Boolean!
    $title: String!
    $information: String!
    $fixByStart: Date!
    $fixByEnd: Date!
    $billing: String!
    $amountBilledSingle: Decimal!
  ) {
    createMachineBreakdown(
      machineId: $machineId
      catalogExtraRowRentalId: $catalogExtraRowRentalId
      stillRentable: $stillRentable
      title: $title
      information: $information
      fixByStart: $fixByStart
      fixByEnd: $fixByEnd
      billing: $billing
      amountBilledSingle: $amountBilledSingle
    ) {
      machineBreakdown {
        id
      }
    }
  }
`;

export const UPDATE_MACHINE_BREAKDOWN_MUTATION = gql`
  mutation updateMachineBreakdown(
    $machineBreakdownId: ID!
    $stillRentable: Boolean!
    $title: String!
    $information: String!
    $fixByStart: Date!
    $fixByEnd: Date!
    $billing: String!
    $amountBilledSingle: Decimal!
    $fixedAt: DateTime
  ) {
    updateMachineBreakdown(
      machineBreakdownId: $machineBreakdownId
      stillRentable: $stillRentable
      title: $title
      information: $information
      fixByStart: $fixByStart
      fixByEnd: $fixByEnd
      billing: $billing
      amountBilledSingle: $amountBilledSingle
      fixedAt: $fixedAt
    ) {
      machineBreakdown {
        id
        stillRentable
        title
        information
        fixByStart
        fixByEnd
        billing
        amountBilledSingle
        fixedAt
      }
    }
  }
`;

export const CREATE_MACHINE_BREAKDOWN_COMMENT_MUTATION = gql`
  mutation createMachineBreakdownComment(
    $machineBreakdownId: ID!
    $comment: String!
  ) {
    createMachineBreakdownComment(
      machineBreakdownId: $machineBreakdownId
      comment: $comment
    ) {
      machineBreakdownComment {
        id
      }
    }
  }
`;

export const CREATE_MACHINE_INFO_CATEGORY_MUTATION = gql`
  mutation createMachineInfoCategory($name: String!) {
    createMachineInfoCategory(name: $name) {
      machineInfoCategory {
        id
      }
    }
  }
`;

export const DELETE_MACHINE_INFO_CATEGORY_MUTATION = gql`
  mutation deleteMachineInfoCategory($machineInfoCategoryId: ID!) {
    deleteMachineInfoCategory(machineInfoCategoryId: $machineInfoCategoryId) {
      machineInfoCategoryId
    }
  }
`;

export const UPDATE_MACHINE_INFO_CATEGORY_MUTATION = gql`
  mutation updateMachineInfoCategory(
    $machineInfoCategoryId: ID!
    $name: String!
  ) {
    updateMachineInfoCategory(
      machineInfoCategoryId: $machineInfoCategoryId
      name: $name
    ) {
      machineInfoCategory {
        id
        name
      }
    }
  }
`;

export const CREATE_MACHINE_INFO_QUESTION_MUTATION = gql`
  mutation createMachineInfoQuestion(
    $machineInfoCategoryId: ID!
    $question: String!
    $answerType: String!
    $showProductCard: Boolean!
    $showDrive: Boolean!
  ) {
    createMachineInfoQuestion(
      machineInfoCategoryId: $machineInfoCategoryId
      question: $question
      answerType: $answerType
      showProductCard: $showProductCard
      showDrive: $showDrive
    ) {
      machineInfoQuestion {
        id
      }
    }
  }
`;

export const DELETE_MACHINE_INFO_QUESTION_MUTATION = gql`
  mutation deleteMachineInfoQuestion($machineInfoQuestionId: ID!) {
    deleteMachineInfoQuestion(machineInfoQuestionId: $machineInfoQuestionId) {
      machineInfoQuestionId
    }
  }
`;

export const UPDATE_MACHINE_INFO_QUESTION_MUTATION = gql`
  mutation updateMachineInfoQuestion(
    $machineInfoQuestionId: ID!
    $machineInfoCategoryId: ID!
    $question: String!
    $answerType: String!
    $showProductCard: Boolean!
    $showDrive: Boolean!
  ) {
    updateMachineInfoQuestion(
      machineInfoQuestionId: $machineInfoQuestionId
      machineInfoCategoryId: $machineInfoCategoryId
      question: $question
      answerType: $answerType
      showProductCard: $showProductCard
      showDrive: $showDrive
    ) {
      machineInfoQuestion {
        id
        machineInfoCategory {
          id
        }
        question
        answerType
        showProductCard
        showDrive
      }
    }
  }
`;

export const CREATE_MACHINE_INFO_TAG_MUTATION = gql`
  mutation createMachineInfoTag($machineInfoQuestionId: ID!, $name: String!) {
    createMachineInfoTag(
      machineInfoQuestionId: $machineInfoQuestionId
      name: $name
    ) {
      machineInfoTag {
        id
      }
    }
  }
`;

export const REMOVE_MACHINE_INFO_TAG_FROM_QUESTION_MUTATION = gql`
  mutation removeMachineInfoTagFromQuestion(
    $machineInfoTagId: ID!
    $machineInfoQuestionId: ID!
  ) {
    removeMachineInfoTagFromQuestion(
      machineInfoTagId: $machineInfoTagId
      machineInfoQuestionId: $machineInfoQuestionId
    ) {
      machineInfoTagId
    }
  }
`;

export const ADD_MACHINE_INFO_TAG_TO_QUESTION_MUTATION = gql`
  mutation addMachineInfoTagToQuestion(
    $machineInfoTagId: ID!
    $machineInfoQuestionId: ID!
  ) {
    addMachineInfoTagToQuestion(
      machineInfoTagId: $machineInfoTagId
      machineInfoQuestionId: $machineInfoQuestionId
    ) {
      machineInfoTagId
    }
  }
`;

export const DELETE_MACHINE_INFO_TAG_MUTATION = gql`
  mutation deleteMachineInfoTag($machineInfoTagId: ID!) {
    deleteMachineInfoTag(machineInfoTagId: $machineInfoTagId) {
      machineInfoTagId
    }
  }
`;

export const UPDATE_MACHINE_INFO_TAG_MUTATION = gql`
  mutation updateMachineInfoTag($machineInfoTagId: ID!, $name: String!) {
    updateMachineInfoTag(machineInfoTagId: $machineInfoTagId, name: $name) {
      machineInfoTag {
        id
        name
      }
    }
  }
`;

export const ADD_MACHINE_INFO_TAG_TO_MACHINE_MUTATION = gql`
  mutation addMachineInfoTagToMachine(
    $machineId: ID!
    $machineInfoTagIds: [ID]!
  ) {
    addMachineInfoTagToMachine(
      machineId: $machineId
      machineInfoTagIds: $machineInfoTagIds
    ) {
      machineId
    }
  }
`;

export const DELETE_MACHINE_INFO_ANSWER_MUTATION = gql`
  mutation deleteMachineInfoAnswer($machineInfoAnswerId: ID!) {
    deleteMachineInfoAnswer(machineInfoAnswerId: $machineInfoAnswerId) {
      machineInfoAnswerId
    }
  }
`;

export const CREATE_MACHINE_INFO_ANSWER_MUTATION = gql`
  mutation createMachineInfoAnswer(
    $machineInfoQuestionIds: [ID]!
    $machineId: ID!
    $answer: String!
  ) {
    createMachineInfoAnswer(
      machineInfoQuestionIds: $machineInfoQuestionIds
      machineId: $machineId
      answer: $answer
    ) {
      success
    }
  }
`;

export const CREATE_MACHINE_INFO_QUESTION_OPTION_MUTATION = gql`
  mutation createMachineInfoQuestionOption(
    $machineInfoQuestionId: ID!
    $title: String!
  ) {
    createMachineInfoQuestionOption(
      machineInfoQuestionId: $machineInfoQuestionId
      title: $title
    ) {
      machineInfoQuestionOption {
        id
      }
    }
  }
`;

export const DELETE_MACHINE_INFO_QUESTION_OPTION_MUTATION = gql`
  mutation deleteMachineInfoQuestionOption($machineInfoQuestionOptionId: ID!) {
    deleteMachineInfoQuestionOption(
      machineInfoQuestionOptionId: $machineInfoQuestionOptionId
    ) {
      machineInfoQuestionOptionId
    }
  }
`;

export const UPDATE_MACHINE_INFO_QUESTION_OPTION_MUTATION = gql`
  mutation updateMachineInfoQuestionOption(
    $machineInfoQuestionOptionId: ID!
    $title: String!
  ) {
    updateMachineInfoQuestionOption(
      machineInfoQuestionOptionId: $machineInfoQuestionOptionId
      title: $title
    ) {
      machineInfoQuestionOption {
        id
        title
      }
    }
  }
`;

export const UPDATE_MACHINE_INFO_ANSWER_MUTATION = gql`
  mutation updateMachineInfoAnswer(
    $machineInfoAnswerId: ID!
    $machineInfoQuestionId: ID!
    $machineInfoQuestionOptionId: ID
    $machineId: ID!
    $answer: String!
  ) {
    updateMachineInfoAnswer(
      machineInfoAnswerId: $machineInfoAnswerId
      machineInfoQuestionId: $machineInfoQuestionId
      machineInfoQuestionOptionId: $machineInfoQuestionOptionId
      machineId: $machineId
      answer: $answer
    ) {
      machineInfoAnswer {
        id
        machineInfoQuestion {
          id
        }
        machineInfoQuestionOption {
          id
        }
        machine {
          id
        }
        answer
      }
    }
  }
`;

export const COPY_MACHINE_INFO_TAGS_MUTATION = gql`
  mutation copyMachineInfoTags($machineIdFrom: ID!, $machineIdTo: ID!) {
    copyMachineInfoTags(
      machineIdFrom: $machineIdFrom
      machineIdTo: $machineIdTo
    ) {
      countAnswersAdded
    }
  }
`;

export const DELETE_MACHINE_BREAKDOWN_MUTATION = gql`
  mutation deleteMachineBreakdown($machineBreakdownId: ID!) {
    deleteMachineBreakdown(machineBreakdownId: $machineBreakdownId) {
      machineBreakdownId
    }
  }
`;

export const CREATE_MACHINE_MAKE_MUTATION = gql`
  mutation createMachineMake($title: String!) {
    createMachineMake(title: $title) {
      machineMake {
        id
      }
    }
  }
`;

export const CREATE_MACHINE_MODEL_MUTATION = gql`
  mutation createMachineModel($machineMakeId: ID!, $title: String!) {
    createMachineModel(machineMakeId: $machineMakeId, title: $title) {
      machineModel {
        id
      }
    }
  }
`;

export const DELETE_MACHINE_MAKE_MUTATION = gql`
  mutation deleteMachineMake($machineMakeId: ID!) {
    deleteMachineMake(machineMakeId: $machineMakeId) {
      machineMakeId
    }
  }
`;

export const DELETE_MACHINE_MODEL_MUTATION = gql`
  mutation deleteMachineModel($machineModelId: ID!) {
    deleteMachineModel(machineModelId: $machineModelId) {
      machineModelId
    }
  }
`;

export const UPDATE_MACHINE_MAKE_MUTATION = gql`
  mutation updateMachineMake($machineMakeId: ID!, $title: String!) {
    updateMachineMake(machineMakeId: $machineMakeId, title: $title) {
      machineMake {
        id
        title
      }
    }
  }
`;

export const UPDATE_MACHINE_MODEL_MUTATION = gql`
  mutation updateMachineModel($machineModelId: ID!, $title: String!) {
    updateMachineModel(machineModelId: $machineModelId, title: $title) {
      machineModel {
        id
        title
      }
    }
  }
`;

export const SAVE_MACHINE_BULK_AMOUNT_MUTATION = gql`
  mutation saveMachineBulkAmount(
    $machineId: ID!
    $locationId: ID!
    $bulkAmount: Int!
  ) {
    saveMachineBulkAmount(
      machineId: $machineId
      locationId: $locationId
      bulkAmount: $bulkAmount
    ) {
      machineBulkAmount {
        id
        machine {
          id
        }
        location {
          id
        }
        bulkAmount
      }
    }
  }
`;

export const CREATE_MACHINE_BULK_BREAKDOWN_MUTATION = gql`
  mutation createMachineBulkBreakdown(
    $machineId: ID!
    $reservationId: ID!
    $amountBroken: Int!
    $fixable: Boolean!
    $fixedAt: DateTime
  ) {
    createMachineBulkBreakdown(
      machineId: $machineId
      reservationId: $reservationId
      amountBroken: $amountBroken
      fixable: $fixable
      fixedAt: $fixedAt
    ) {
      machineBulkBreakdown {
        id
      }
    }
  }
`;

export const UPDATE_MACHINE_BULK_BREAKDOWN_MUTATION = gql`
  mutation updateMachineBulkBreakdown(
    $machineBulkBreakdownId: ID!
    $fixedAt: DateTime
  ) {
    updateMachineBulkBreakdown(
      machineBulkBreakdownId: $machineBulkBreakdownId
      fixedAt: $fixedAt
    ) {
      machineBulkBreakdown {
        id
        fixedAt
      }
    }
  }
`;

export const DELETE_MACHINE_BULK_BREAKDOWN_MUTATION = gql`
  mutation deleteMachineBulkBreakdown($machineBulkBreakdownId: ID!) {
    deleteMachineBulkBreakdown(
      machineBulkBreakdownId: $machineBulkBreakdownId
    ) {
      machineBulkBreakdownId
    }
  }
`;

export interface MutationResultUploadFileMachineTypePlate {
  uploadFileMachineTypePlate: Mutation["uploadFileMachineTypePlate"];
}
export const UPLOAD_FILE_MACHINE_TYPE_PLATE_MUTATION = gql`
  mutation uploadFileMachineTypePlate($machineId: ID!, $fileTypePlate: Upload) {
    uploadFileMachineTypePlate(
      machineId: $machineId
      fileTypePlate: $fileTypePlate
    ) {
      machine {
        id
        fileTypePlate
      }
    }
  }
`;

export interface MutationResultUploadFileMachineCheckList {
  uploadFileMachineCheckList: Mutation["uploadFileMachineCheckList"];
}
export const UPLOAD_FILE_MACHINE_CHECK_LIST_MUTATION = gql`
  mutation uploadFileMachineCheckList($machineId: ID!, $fileCheckList: Upload) {
    uploadFileMachineCheckList(
      machineId: $machineId
      fileCheckList: $fileCheckList
    ) {
      machine {
        id
        fileCheckList
      }
    }
  }
`;

export interface MutationResultUploadFileMachinePurchase {
  uploadFileMachinePurchase: Mutation["uploadFileMachinePurchase"];
}
export const UPLOAD_FILE_MACHINE_PURCHASE_MUTATION = gql`
  mutation uploadFileMachinePurchase($machineId: ID!, $filePurchase: Upload) {
    uploadFileMachinePurchase(
      machineId: $machineId
      filePurchase: $filePurchase
    ) {
      machine {
        id
        filePurchase
      }
    }
  }
`;

export interface MutationResultUploadFileMachineFinancing {
  uploadFileMachineFinancing: Mutation["uploadFileMachineFinancing"];
}
export const UPLOAD_FILE_MACHINE_FINANCING_MUTATION = gql`
  mutation uploadFileMachineFinancing($machineId: ID!, $fileFinancing: Upload) {
    uploadFileMachineFinancing(
      machineId: $machineId
      fileFinancing: $fileFinancing
    ) {
      machine {
        id
        fileFinancing
      }
    }
  }
`;
