import { gql } from "@apollo/client";

export const IS_LOGGED_IN_QUERY = gql`
  query getIsLoggedIn {
    isLoggedIn @client
  }
`;

export const SET_IS_LOGGED_IN_MUTATION = gql`
  mutation setIsLoggedIn($isLoggedIn: Boolean) {
    setIsLoggedIn(isLoggedIn: $isLoggedIn) @client
  }
`;
