import * as Types from '../../entity/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLocationsQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLocationsQueryQuery = (
  { __typename?: 'Query' }
  & { locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<Types.LocationType, 'id' | 'name' | 'transferDistanceIncluded' | 'transferDistanceLimit' | 'dimIdentifier' | 'createdAt'>
  )> }
);


export const GetLocationsQueryDocument = gql`
    query getLocationsQuery {
  locations {
    id
    name
    transferDistanceIncluded
    transferDistanceLimit
    dimIdentifier
    createdAt
  }
}
    `;

/**
 * __useGetLocationsQueryQuery__
 *
 * To run a query within a React component, call `useGetLocationsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQueryQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQueryQuery, GetLocationsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQueryQuery, GetLocationsQueryQueryVariables>(GetLocationsQueryDocument, options);
      }
export function useGetLocationsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQueryQuery, GetLocationsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQueryQuery, GetLocationsQueryQueryVariables>(GetLocationsQueryDocument, options);
        }
export type GetLocationsQueryQueryHookResult = ReturnType<typeof useGetLocationsQueryQuery>;
export type GetLocationsQueryLazyQueryHookResult = ReturnType<typeof useGetLocationsQueryLazyQuery>;
export type GetLocationsQueryQueryResult = Apollo.QueryResult<GetLocationsQueryQuery, GetLocationsQueryQueryVariables>;