import * as Types from '../../entity/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CatalogRowFieldsFragment = (
  { __typename?: 'CatalogRowType' }
  & Pick<Types.CatalogRowType, 'id' | 'name' | 'information' | 'image' | 'billingDaysWeekCompany' | 'priceDayCompany' | 'priceMonthCompany' | 'billingDaysWeekPrivate' | 'priceDayPrivate' | 'priceMonthPrivate' | 'hourLimitDayCompany' | 'hourLimitDayPrivate' | 'hourLimitMonthCompany' | 'hourLimitMonthPrivate' | 'extraHoursCompany' | 'extraHoursPrivate' | 'productNumberDay' | 'productNumberMonth'>
);

export type GetCatalogsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCatalogsQuery = (
  { __typename?: 'Query' }
  & { catalogs: Array<(
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name'>
    & { catalogcategoryupperSet: Array<(
      { __typename?: 'CatalogCategoryUpperType' }
      & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
      & { catalogcategorySet: Array<(
        { __typename?: 'CatalogCategoryType' }
        & Pick<Types.CatalogCategoryType, 'id' | 'name'>
        & { catalogrowSet: Array<(
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type GetCatalogsWithoutRowsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCatalogsWithoutRowsQuery = (
  { __typename?: 'Query' }
  & { catalogs: Array<(
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name' | 'setActiveAt' | 'createdAt' | 'creationCompletedAt'>
    & { createdBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )> }
  )> }
);

export type GetCatalogWithRowsQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
}>;


export type GetCatalogWithRowsQuery = (
  { __typename?: 'Query' }
  & { catalog: (
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name'>
    & { catalogcategoryupperSet: Array<(
      { __typename?: 'CatalogCategoryUpperType' }
      & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
      & { catalogcategorySet: Array<(
        { __typename?: 'CatalogCategoryType' }
        & Pick<Types.CatalogCategoryType, 'id' | 'name'>
        & { catalogrowSet: Array<(
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type GetCatalogQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
}>;


export type GetCatalogQuery = (
  { __typename?: 'Query' }
  & { catalog: (
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name'>
    & { catalogcategoryupperSet: Array<(
      { __typename?: 'CatalogCategoryUpperType' }
      & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
      & { catalogcategorySet: Array<(
        { __typename?: 'CatalogCategoryType' }
        & Pick<Types.CatalogCategoryType, 'id' | 'name' | 'information' | 'image'>
        & { catalogCategoryUpper: (
          { __typename?: 'CatalogCategoryUpperType' }
          & Pick<Types.CatalogCategoryUpperType, 'id'>
        ), catalogrowSet: Array<(
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id' | 'name' | 'information' | 'image' | 'billingDaysWeekCompany' | 'priceDayCompany' | 'priceMonthCompany' | 'billingDaysWeekPrivate' | 'priceDayPrivate' | 'priceMonthPrivate' | 'hourLimitDayCompany' | 'hourLimitDayPrivate' | 'hourLimitMonthCompany' | 'hourLimitMonthPrivate' | 'extraHoursCompany' | 'extraHoursPrivate' | 'productNumberDay' | 'productNumberMonth'>
          & { catalogCategory: (
            { __typename?: 'CatalogCategoryType' }
            & Pick<Types.CatalogCategoryType, 'id'>
          ), machineSet: Array<(
            { __typename?: 'MachineType' }
            & Pick<Types.MachineType, 'id'>
            & { location?: Types.Maybe<(
              { __typename?: 'LocationType' }
              & Pick<Types.LocationType, 'id' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type GetCatalogWithCatsQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
}>;


export type GetCatalogWithCatsQuery = (
  { __typename?: 'Query' }
  & { catalog: (
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name'>
    & { catalogcategoryupperSet: Array<(
      { __typename?: 'CatalogCategoryUpperType' }
      & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
    )> }
  ) }
);

export type GetCatalogCategoriesForUpperQueryVariables = Types.Exact<{
  catalogCategoryUpperId: Types.Scalars['ID'];
}>;


export type GetCatalogCategoriesForUpperQuery = (
  { __typename?: 'Query' }
  & { catalogCategoriesForUpper: Array<(
    { __typename?: 'CatalogCategoryType' }
    & Pick<Types.CatalogCategoryType, 'id' | 'name'>
    & { catalogrowSet: Array<(
      { __typename?: 'CatalogRowType' }
      & Pick<Types.CatalogRowType, 'id' | 'name'>
      & { machineSet: Array<(
        { __typename?: 'MachineType' }
        & Pick<Types.MachineType, 'id'>
        & { location?: Types.Maybe<(
          { __typename?: 'LocationType' }
          & Pick<Types.LocationType, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type GetCatalogWithSourceQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
}>;


export type GetCatalogWithSourceQuery = (
  { __typename?: 'Query' }
  & { catalog: (
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name' | 'setActiveAt' | 'createdAt' | 'creationCompletedAt'>
    & { catalogcategoryupperSet: Array<(
      { __typename?: 'CatalogCategoryUpperType' }
      & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
      & { catalogcategorySet: Array<(
        { __typename?: 'CatalogCategoryType' }
        & Pick<Types.CatalogCategoryType, 'id' | 'name' | 'information' | 'image'>
        & { catalogCategoryUpper: (
          { __typename?: 'CatalogCategoryUpperType' }
          & Pick<Types.CatalogCategoryUpperType, 'id'>
        ), catalogrowSet: Array<(
          { __typename?: 'CatalogRowType' }
          & Pick<Types.CatalogRowType, 'id' | 'name' | 'information' | 'image' | 'billingDaysWeekCompany' | 'priceDayCompany' | 'priceMonthCompany' | 'billingDaysWeekPrivate' | 'priceDayPrivate' | 'priceMonthPrivate' | 'hourLimitDayCompany' | 'hourLimitDayPrivate' | 'hourLimitMonthCompany' | 'hourLimitMonthPrivate' | 'extraHoursCompany' | 'extraHoursPrivate' | 'productNumberDay' | 'productNumberMonth'>
          & { catalogCategory: (
            { __typename?: 'CatalogCategoryType' }
            & Pick<Types.CatalogCategoryType, 'id'>
          ), catalogRowSource?: Types.Maybe<(
            { __typename?: 'CatalogRowType' }
            & Pick<Types.CatalogRowType, 'id'>
          )> }
        )> }
      )> }
    )>, catalogSource?: Types.Maybe<(
      { __typename?: 'CatalogType' }
      & Pick<Types.CatalogType, 'id' | 'name'>
      & { catalogcategoryupperSet: Array<(
        { __typename?: 'CatalogCategoryUpperType' }
        & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
        & { catalogcategorySet: Array<(
          { __typename?: 'CatalogCategoryType' }
          & Pick<Types.CatalogCategoryType, 'id' | 'name'>
          & { catalogrowSet: Array<(
            { __typename?: 'CatalogRowType' }
            & Pick<Types.CatalogRowType, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  ) }
);

export type GetCalendarQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
  catalogCategoryUpperId?: Types.InputMaybe<Types.Scalars['ID']>;
  dateFrom: Types.Scalars['Date'];
  dateTo: Types.Scalars['Date'];
  locationId: Types.Scalars['ID'];
  catalogCategoryId?: Types.InputMaybe<Types.Scalars['ID']>;
  catalogRowId?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetCalendarQuery = (
  { __typename?: 'Query' }
  & { catalogCategoriesUpper: Array<(
    { __typename?: 'CatalogCategoryUpperType' }
    & Pick<Types.CatalogCategoryUpperType, 'id' | 'name'>
    & { catalogcategorySet: Array<(
      { __typename?: 'CatalogCategoryType' }
      & Pick<Types.CatalogCategoryType, 'id' | 'name' | 'information'>
      & { catalogrowSet: Array<(
        { __typename?: 'CatalogRowType' }
        & Pick<Types.CatalogRowType, 'id' | 'name' | 'information'>
      )> }
    )> }
  )>, bulkAmountsByDate: Array<(
    { __typename?: 'BulkAmountDate' }
    & Pick<Types.BulkAmountDate, 'machineId' | 'date' | 'amountFree'>
  )>, reservationsCalendar: Array<(
    { __typename?: 'ReservationType' }
    & Pick<Types.ReservationType, 'id' | 'dateRented' | 'dateReturned' | 'bulkAmount' | 'giveAt' | 'returnAt' | 'returnCheckedAt' | 'returnStoredAt'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id'>
    )>, catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id'>
    )>, order: (
      { __typename?: 'OrderType' }
      & Pick<Types.OrderType, 'id' | 'status'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<Types.LocationType, 'id' | 'name'>
      ), customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id' | 'name' | 'contactPersonLastname' | 'contactPersonFirstname'>
      )> }
    ) }
  )>, machineBreakdowns: Array<(
    { __typename?: 'MachineBreakdownType' }
    & Pick<Types.MachineBreakdownType, 'id' | 'title' | 'information' | 'fixByStart' | 'fixByEnd' | 'fixedAt' | 'stillRentable' | 'billing' | 'amountBilledSingle'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id'>
    )>, catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id'>
    )> }
  )>, maintenancesCalendar: Array<(
    { __typename?: 'MaintenanceType' }
    & Pick<Types.MaintenanceType, 'id' | 'maintenanceType' | 'estimatedDate' | 'serviceStartBy' | 'serviceEndBy' | 'servicedAt'>
    & { machine?: Types.Maybe<(
      { __typename?: 'MachineType' }
      & Pick<Types.MachineType, 'id'>
    )>, catalogExtraRowRental?: Types.Maybe<(
      { __typename?: 'CatalogExtraRowRentalType' }
      & Pick<Types.CatalogExtraRowRentalType, 'id'>
    )>, maintenanceInterval: (
      { __typename?: 'MaintenanceIntervalType' }
      & Pick<Types.MaintenanceIntervalType, 'id' | 'description'>
    ) }
  )> }
);

export type GetCatalogsWithDiscountListsQueryVariables = Types.Exact<{
  catalogIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>>;
}>;


export type GetCatalogsWithDiscountListsQuery = (
  { __typename?: 'Query' }
  & { catalogs: Array<(
    { __typename?: 'CatalogType' }
    & Pick<Types.CatalogType, 'id' | 'name' | 'createdAt'>
    & { createdBy?: Types.Maybe<(
      { __typename?: 'UserType' }
      & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
    )>, discountlistSet: Array<(
      { __typename?: 'DiscountListType' }
      & Pick<Types.DiscountListType, 'id' | 'name' | 'isBusiness' | 'locked' | 'isOffer' | 'createdAt'>
      & { customer?: Types.Maybe<(
        { __typename?: 'CustomerType' }
        & Pick<Types.CustomerType, 'id'>
      )>, createdBy?: Types.Maybe<(
        { __typename?: 'UserType' }
        & Pick<Types.UserType, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
  )> }
);

export type GetCatalogRowDiscountsMachinesQueryVariables = Types.Exact<{
  catalogRowId: Types.Scalars['ID'];
}>;


export type GetCatalogRowDiscountsMachinesQuery = (
  { __typename?: 'Query' }
  & { machines: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id' | 'identifier'>
    & { machineModel: (
      { __typename?: 'MachineModelType' }
      & Pick<Types.MachineModelType, 'id' | 'title'>
      & { machineMake: (
        { __typename?: 'MachineMakeType' }
        & Pick<Types.MachineMakeType, 'id' | 'title'>
      ) }
    ) }
  )>, machinesForCatalogRow: Array<(
    { __typename?: 'MachineType' }
    & Pick<Types.MachineType, 'id'>
  )> }
);

export type GetDiscountListsForCatalogRowQueryVariables = Types.Exact<{
  catalogRowId: Types.Scalars['ID'];
}>;


export type GetDiscountListsForCatalogRowQuery = (
  { __typename?: 'Query' }
  & { discountListsForCatalogRow: Array<(
    { __typename?: 'DiscountListType' }
    & Pick<Types.DiscountListType, 'id' | 'name' | 'locked'>
    & { customer?: Types.Maybe<(
      { __typename?: 'CustomerType' }
      & Pick<Types.CustomerType, 'id' | 'name'>
    )>, discountrowSetForCatalogRow?: Types.Maybe<Array<(
      { __typename?: 'DiscountRowType' }
      & Pick<Types.DiscountRowType, 'id' | 'percent'>
    )>> }
  )> }
);

export type GetCatalogRowQueryVariables = Types.Exact<{
  catalogRowId: Types.Scalars['ID'];
}>;


export type GetCatalogRowQuery = (
  { __typename?: 'Query' }
  & { catalogRow: (
    { __typename?: 'CatalogRowType' }
    & Pick<Types.CatalogRowType, 'id' | 'billingDaysWeekCompany' | 'billingDaysWeekPrivate' | 'hourLimitDayCompany' | 'hourLimitDayPrivate' | 'hourLimitMonthCompany' | 'hourLimitMonthPrivate' | 'extraHoursCompany' | 'extraHoursPrivate'>
  ) }
);

export type GetErrorsSettingCatalogActiveQueryVariables = Types.Exact<{
  catalogId: Types.Scalars['ID'];
}>;


export type GetErrorsSettingCatalogActiveQuery = (
  { __typename?: 'Query' }
  & Pick<Types.Query, 'errorsSettingCatalogActive'>
);

export const CatalogRowFieldsFragmentDoc = gql`
    fragment CatalogRowFields on CatalogRowType {
  id
  name
  information
  image
  billingDaysWeekCompany
  priceDayCompany
  priceMonthCompany
  billingDaysWeekPrivate
  priceDayPrivate
  priceMonthPrivate
  hourLimitDayCompany
  hourLimitDayPrivate
  hourLimitMonthCompany
  hourLimitMonthPrivate
  extraHoursCompany
  extraHoursPrivate
  productNumberDay
  productNumberMonth
}
    `;
export const GetCatalogsDocument = gql`
    query getCatalogs {
  catalogs {
    id
    name
    catalogcategoryupperSet {
      id
      name
      catalogcategorySet {
        id
        name
        catalogrowSet {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCatalogsQuery__
 *
 * To run a query within a React component, call `useGetCatalogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCatalogsQuery(baseOptions?: Apollo.QueryHookOptions<GetCatalogsQuery, GetCatalogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogsQuery, GetCatalogsQueryVariables>(GetCatalogsDocument, options);
      }
export function useGetCatalogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogsQuery, GetCatalogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogsQuery, GetCatalogsQueryVariables>(GetCatalogsDocument, options);
        }
export type GetCatalogsQueryHookResult = ReturnType<typeof useGetCatalogsQuery>;
export type GetCatalogsLazyQueryHookResult = ReturnType<typeof useGetCatalogsLazyQuery>;
export type GetCatalogsQueryResult = Apollo.QueryResult<GetCatalogsQuery, GetCatalogsQueryVariables>;
export const GetCatalogsWithoutRowsDocument = gql`
    query getCatalogsWithoutRows {
  catalogs {
    id
    name
    setActiveAt
    createdAt
    creationCompletedAt
    createdBy {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useGetCatalogsWithoutRowsQuery__
 *
 * To run a query within a React component, call `useGetCatalogsWithoutRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogsWithoutRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogsWithoutRowsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCatalogsWithoutRowsQuery(baseOptions?: Apollo.QueryHookOptions<GetCatalogsWithoutRowsQuery, GetCatalogsWithoutRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogsWithoutRowsQuery, GetCatalogsWithoutRowsQueryVariables>(GetCatalogsWithoutRowsDocument, options);
      }
export function useGetCatalogsWithoutRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogsWithoutRowsQuery, GetCatalogsWithoutRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogsWithoutRowsQuery, GetCatalogsWithoutRowsQueryVariables>(GetCatalogsWithoutRowsDocument, options);
        }
export type GetCatalogsWithoutRowsQueryHookResult = ReturnType<typeof useGetCatalogsWithoutRowsQuery>;
export type GetCatalogsWithoutRowsLazyQueryHookResult = ReturnType<typeof useGetCatalogsWithoutRowsLazyQuery>;
export type GetCatalogsWithoutRowsQueryResult = Apollo.QueryResult<GetCatalogsWithoutRowsQuery, GetCatalogsWithoutRowsQueryVariables>;
export const GetCatalogWithRowsDocument = gql`
    query getCatalogWithRows($catalogId: ID!) {
  catalog(catalogId: $catalogId) {
    id
    name
    catalogcategoryupperSet {
      id
      name
      catalogcategorySet {
        id
        name
        catalogrowSet {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCatalogWithRowsQuery__
 *
 * To run a query within a React component, call `useGetCatalogWithRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogWithRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogWithRowsQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetCatalogWithRowsQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogWithRowsQuery, GetCatalogWithRowsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogWithRowsQuery, GetCatalogWithRowsQueryVariables>(GetCatalogWithRowsDocument, options);
      }
export function useGetCatalogWithRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogWithRowsQuery, GetCatalogWithRowsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogWithRowsQuery, GetCatalogWithRowsQueryVariables>(GetCatalogWithRowsDocument, options);
        }
export type GetCatalogWithRowsQueryHookResult = ReturnType<typeof useGetCatalogWithRowsQuery>;
export type GetCatalogWithRowsLazyQueryHookResult = ReturnType<typeof useGetCatalogWithRowsLazyQuery>;
export type GetCatalogWithRowsQueryResult = Apollo.QueryResult<GetCatalogWithRowsQuery, GetCatalogWithRowsQueryVariables>;
export const GetCatalogDocument = gql`
    query getCatalog($catalogId: ID!) {
  catalog(catalogId: $catalogId) {
    id
    name
    catalogcategoryupperSet {
      id
      name
      catalogcategorySet {
        id
        name
        information
        image
        catalogCategoryUpper {
          id
        }
        catalogrowSet {
          ...CatalogRowFields
          catalogCategory {
            id
          }
          machineSet {
            id
            location {
              id
              name
            }
          }
        }
      }
    }
  }
}
    ${CatalogRowFieldsFragmentDoc}`;

/**
 * __useGetCatalogQuery__
 *
 * To run a query within a React component, call `useGetCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetCatalogQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogQuery, GetCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogQuery, GetCatalogQueryVariables>(GetCatalogDocument, options);
      }
export function useGetCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogQuery, GetCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogQuery, GetCatalogQueryVariables>(GetCatalogDocument, options);
        }
export type GetCatalogQueryHookResult = ReturnType<typeof useGetCatalogQuery>;
export type GetCatalogLazyQueryHookResult = ReturnType<typeof useGetCatalogLazyQuery>;
export type GetCatalogQueryResult = Apollo.QueryResult<GetCatalogQuery, GetCatalogQueryVariables>;
export const GetCatalogWithCatsDocument = gql`
    query getCatalogWithCats($catalogId: ID!) {
  catalog(catalogId: $catalogId) {
    id
    name
    catalogcategoryupperSet {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCatalogWithCatsQuery__
 *
 * To run a query within a React component, call `useGetCatalogWithCatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogWithCatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogWithCatsQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetCatalogWithCatsQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogWithCatsQuery, GetCatalogWithCatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogWithCatsQuery, GetCatalogWithCatsQueryVariables>(GetCatalogWithCatsDocument, options);
      }
export function useGetCatalogWithCatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogWithCatsQuery, GetCatalogWithCatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogWithCatsQuery, GetCatalogWithCatsQueryVariables>(GetCatalogWithCatsDocument, options);
        }
export type GetCatalogWithCatsQueryHookResult = ReturnType<typeof useGetCatalogWithCatsQuery>;
export type GetCatalogWithCatsLazyQueryHookResult = ReturnType<typeof useGetCatalogWithCatsLazyQuery>;
export type GetCatalogWithCatsQueryResult = Apollo.QueryResult<GetCatalogWithCatsQuery, GetCatalogWithCatsQueryVariables>;
export const GetCatalogCategoriesForUpperDocument = gql`
    query getCatalogCategoriesForUpper($catalogCategoryUpperId: ID!) {
  catalogCategoriesForUpper(catalogCategoryUpperId: $catalogCategoryUpperId) {
    id
    name
    catalogrowSet {
      id
      name
      machineSet {
        id
        location {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCatalogCategoriesForUpperQuery__
 *
 * To run a query within a React component, call `useGetCatalogCategoriesForUpperQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogCategoriesForUpperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogCategoriesForUpperQuery({
 *   variables: {
 *      catalogCategoryUpperId: // value for 'catalogCategoryUpperId'
 *   },
 * });
 */
export function useGetCatalogCategoriesForUpperQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogCategoriesForUpperQuery, GetCatalogCategoriesForUpperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogCategoriesForUpperQuery, GetCatalogCategoriesForUpperQueryVariables>(GetCatalogCategoriesForUpperDocument, options);
      }
export function useGetCatalogCategoriesForUpperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogCategoriesForUpperQuery, GetCatalogCategoriesForUpperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogCategoriesForUpperQuery, GetCatalogCategoriesForUpperQueryVariables>(GetCatalogCategoriesForUpperDocument, options);
        }
export type GetCatalogCategoriesForUpperQueryHookResult = ReturnType<typeof useGetCatalogCategoriesForUpperQuery>;
export type GetCatalogCategoriesForUpperLazyQueryHookResult = ReturnType<typeof useGetCatalogCategoriesForUpperLazyQuery>;
export type GetCatalogCategoriesForUpperQueryResult = Apollo.QueryResult<GetCatalogCategoriesForUpperQuery, GetCatalogCategoriesForUpperQueryVariables>;
export const GetCatalogWithSourceDocument = gql`
    query getCatalogWithSource($catalogId: ID!) {
  catalog(catalogId: $catalogId) {
    id
    name
    setActiveAt
    createdAt
    creationCompletedAt
    catalogcategoryupperSet {
      id
      name
      catalogcategorySet {
        id
        name
        information
        image
        catalogCategoryUpper {
          id
        }
        catalogrowSet {
          ...CatalogRowFields
          catalogCategory {
            id
          }
          catalogRowSource {
            id
          }
        }
      }
    }
    catalogSource {
      id
      name
      catalogcategoryupperSet {
        id
        name
        catalogcategorySet {
          id
          name
          catalogrowSet {
            id
            name
          }
        }
      }
    }
  }
}
    ${CatalogRowFieldsFragmentDoc}`;

/**
 * __useGetCatalogWithSourceQuery__
 *
 * To run a query within a React component, call `useGetCatalogWithSourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogWithSourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogWithSourceQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetCatalogWithSourceQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogWithSourceQuery, GetCatalogWithSourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogWithSourceQuery, GetCatalogWithSourceQueryVariables>(GetCatalogWithSourceDocument, options);
      }
export function useGetCatalogWithSourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogWithSourceQuery, GetCatalogWithSourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogWithSourceQuery, GetCatalogWithSourceQueryVariables>(GetCatalogWithSourceDocument, options);
        }
export type GetCatalogWithSourceQueryHookResult = ReturnType<typeof useGetCatalogWithSourceQuery>;
export type GetCatalogWithSourceLazyQueryHookResult = ReturnType<typeof useGetCatalogWithSourceLazyQuery>;
export type GetCatalogWithSourceQueryResult = Apollo.QueryResult<GetCatalogWithSourceQuery, GetCatalogWithSourceQueryVariables>;
export const GetCalendarDocument = gql`
    query getCalendar($catalogId: ID!, $catalogCategoryUpperId: ID, $dateFrom: Date!, $dateTo: Date!, $locationId: ID!, $catalogCategoryId: ID, $catalogRowId: ID) {
  catalogCategoriesUpper(
    catalogId: $catalogId
    catalogCategoryUpperId: $catalogCategoryUpperId
  ) {
    id
    name
    catalogcategorySet {
      id
      name
      information
      catalogrowSet {
        id
        name
        information
      }
    }
  }
  bulkAmountsByDate(dateFrom: $dateFrom, dateTo: $dateTo, locationId: $locationId) {
    machineId
    date
    amountFree
  }
  reservationsCalendar(
    dateFrom: $dateFrom
    dateTo: $dateTo
    locationId: $locationId
    catalogCategoryUpperId: $catalogCategoryUpperId
    catalogCategoryId: $catalogCategoryId
    catalogRowId: $catalogRowId
  ) {
    id
    dateRented
    dateReturned
    bulkAmount
    giveAt
    returnAt
    returnCheckedAt
    returnStoredAt
    machine {
      id
    }
    catalogExtraRowRental {
      id
    }
    order {
      id
      status
      location {
        id
        name
      }
      customer {
        id
        name
        contactPersonLastname
        contactPersonFirstname
      }
    }
  }
  machineBreakdowns(dateFrom: $dateFrom, dateTo: $dateTo) {
    id
    title
    information
    fixByStart
    fixByEnd
    fixedAt
    stillRentable
    billing
    amountBilledSingle
    machine {
      id
    }
    catalogExtraRowRental {
      id
    }
  }
  maintenancesCalendar(
    dateFrom: $dateFrom
    dateTo: $dateTo
    locationId: $locationId
  ) {
    id
    maintenanceType
    estimatedDate
    serviceStartBy
    serviceEndBy
    servicedAt
    machine {
      id
    }
    catalogExtraRowRental {
      id
    }
    maintenanceInterval {
      id
      description
    }
  }
}
    `;

/**
 * __useGetCalendarQuery__
 *
 * To run a query within a React component, call `useGetCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *      catalogCategoryUpperId: // value for 'catalogCategoryUpperId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      locationId: // value for 'locationId'
 *      catalogCategoryId: // value for 'catalogCategoryId'
 *      catalogRowId: // value for 'catalogRowId'
 *   },
 * });
 */
export function useGetCalendarQuery(baseOptions: Apollo.QueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, options);
      }
export function useGetCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCalendarQuery, GetCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCalendarQuery, GetCalendarQueryVariables>(GetCalendarDocument, options);
        }
export type GetCalendarQueryHookResult = ReturnType<typeof useGetCalendarQuery>;
export type GetCalendarLazyQueryHookResult = ReturnType<typeof useGetCalendarLazyQuery>;
export type GetCalendarQueryResult = Apollo.QueryResult<GetCalendarQuery, GetCalendarQueryVariables>;
export const GetCatalogsWithDiscountListsDocument = gql`
    query getCatalogsWithDiscountLists($catalogIds: [ID]) {
  catalogs(catalogIds: $catalogIds) {
    id
    name
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    discountlistSet {
      id
      name
      isBusiness
      locked
      isOffer
      createdAt
      customer {
        id
      }
      createdBy {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useGetCatalogsWithDiscountListsQuery__
 *
 * To run a query within a React component, call `useGetCatalogsWithDiscountListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogsWithDiscountListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogsWithDiscountListsQuery({
 *   variables: {
 *      catalogIds: // value for 'catalogIds'
 *   },
 * });
 */
export function useGetCatalogsWithDiscountListsQuery(baseOptions?: Apollo.QueryHookOptions<GetCatalogsWithDiscountListsQuery, GetCatalogsWithDiscountListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogsWithDiscountListsQuery, GetCatalogsWithDiscountListsQueryVariables>(GetCatalogsWithDiscountListsDocument, options);
      }
export function useGetCatalogsWithDiscountListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogsWithDiscountListsQuery, GetCatalogsWithDiscountListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogsWithDiscountListsQuery, GetCatalogsWithDiscountListsQueryVariables>(GetCatalogsWithDiscountListsDocument, options);
        }
export type GetCatalogsWithDiscountListsQueryHookResult = ReturnType<typeof useGetCatalogsWithDiscountListsQuery>;
export type GetCatalogsWithDiscountListsLazyQueryHookResult = ReturnType<typeof useGetCatalogsWithDiscountListsLazyQuery>;
export type GetCatalogsWithDiscountListsQueryResult = Apollo.QueryResult<GetCatalogsWithDiscountListsQuery, GetCatalogsWithDiscountListsQueryVariables>;
export const GetCatalogRowDiscountsMachinesDocument = gql`
    query getCatalogRowDiscountsMachines($catalogRowId: ID!) {
  machines {
    id
    identifier
    machineModel {
      id
      title
      machineMake {
        id
        title
      }
    }
  }
  machinesForCatalogRow(catalogRowId: $catalogRowId) {
    id
  }
}
    `;

/**
 * __useGetCatalogRowDiscountsMachinesQuery__
 *
 * To run a query within a React component, call `useGetCatalogRowDiscountsMachinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogRowDiscountsMachinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogRowDiscountsMachinesQuery({
 *   variables: {
 *      catalogRowId: // value for 'catalogRowId'
 *   },
 * });
 */
export function useGetCatalogRowDiscountsMachinesQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogRowDiscountsMachinesQuery, GetCatalogRowDiscountsMachinesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogRowDiscountsMachinesQuery, GetCatalogRowDiscountsMachinesQueryVariables>(GetCatalogRowDiscountsMachinesDocument, options);
      }
export function useGetCatalogRowDiscountsMachinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogRowDiscountsMachinesQuery, GetCatalogRowDiscountsMachinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogRowDiscountsMachinesQuery, GetCatalogRowDiscountsMachinesQueryVariables>(GetCatalogRowDiscountsMachinesDocument, options);
        }
export type GetCatalogRowDiscountsMachinesQueryHookResult = ReturnType<typeof useGetCatalogRowDiscountsMachinesQuery>;
export type GetCatalogRowDiscountsMachinesLazyQueryHookResult = ReturnType<typeof useGetCatalogRowDiscountsMachinesLazyQuery>;
export type GetCatalogRowDiscountsMachinesQueryResult = Apollo.QueryResult<GetCatalogRowDiscountsMachinesQuery, GetCatalogRowDiscountsMachinesQueryVariables>;
export const GetDiscountListsForCatalogRowDocument = gql`
    query getDiscountListsForCatalogRow($catalogRowId: ID!) {
  discountListsForCatalogRow(catalogRowId: $catalogRowId) {
    id
    name
    locked
    customer {
      id
      name
    }
    discountrowSetForCatalogRow(catalogRowId: $catalogRowId) {
      id
      percent
    }
  }
}
    `;

/**
 * __useGetDiscountListsForCatalogRowQuery__
 *
 * To run a query within a React component, call `useGetDiscountListsForCatalogRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscountListsForCatalogRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscountListsForCatalogRowQuery({
 *   variables: {
 *      catalogRowId: // value for 'catalogRowId'
 *   },
 * });
 */
export function useGetDiscountListsForCatalogRowQuery(baseOptions: Apollo.QueryHookOptions<GetDiscountListsForCatalogRowQuery, GetDiscountListsForCatalogRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscountListsForCatalogRowQuery, GetDiscountListsForCatalogRowQueryVariables>(GetDiscountListsForCatalogRowDocument, options);
      }
export function useGetDiscountListsForCatalogRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscountListsForCatalogRowQuery, GetDiscountListsForCatalogRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscountListsForCatalogRowQuery, GetDiscountListsForCatalogRowQueryVariables>(GetDiscountListsForCatalogRowDocument, options);
        }
export type GetDiscountListsForCatalogRowQueryHookResult = ReturnType<typeof useGetDiscountListsForCatalogRowQuery>;
export type GetDiscountListsForCatalogRowLazyQueryHookResult = ReturnType<typeof useGetDiscountListsForCatalogRowLazyQuery>;
export type GetDiscountListsForCatalogRowQueryResult = Apollo.QueryResult<GetDiscountListsForCatalogRowQuery, GetDiscountListsForCatalogRowQueryVariables>;
export const GetCatalogRowDocument = gql`
    query getCatalogRow($catalogRowId: ID!) {
  catalogRow(catalogRowId: $catalogRowId) {
    id
    billingDaysWeekCompany
    billingDaysWeekPrivate
    hourLimitDayCompany
    hourLimitDayPrivate
    hourLimitMonthCompany
    hourLimitMonthPrivate
    extraHoursCompany
    extraHoursPrivate
  }
}
    `;

/**
 * __useGetCatalogRowQuery__
 *
 * To run a query within a React component, call `useGetCatalogRowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatalogRowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatalogRowQuery({
 *   variables: {
 *      catalogRowId: // value for 'catalogRowId'
 *   },
 * });
 */
export function useGetCatalogRowQuery(baseOptions: Apollo.QueryHookOptions<GetCatalogRowQuery, GetCatalogRowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatalogRowQuery, GetCatalogRowQueryVariables>(GetCatalogRowDocument, options);
      }
export function useGetCatalogRowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatalogRowQuery, GetCatalogRowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatalogRowQuery, GetCatalogRowQueryVariables>(GetCatalogRowDocument, options);
        }
export type GetCatalogRowQueryHookResult = ReturnType<typeof useGetCatalogRowQuery>;
export type GetCatalogRowLazyQueryHookResult = ReturnType<typeof useGetCatalogRowLazyQuery>;
export type GetCatalogRowQueryResult = Apollo.QueryResult<GetCatalogRowQuery, GetCatalogRowQueryVariables>;
export const GetErrorsSettingCatalogActiveDocument = gql`
    query getErrorsSettingCatalogActive($catalogId: ID!) {
  errorsSettingCatalogActive(catalogId: $catalogId)
}
    `;

/**
 * __useGetErrorsSettingCatalogActiveQuery__
 *
 * To run a query within a React component, call `useGetErrorsSettingCatalogActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetErrorsSettingCatalogActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetErrorsSettingCatalogActiveQuery({
 *   variables: {
 *      catalogId: // value for 'catalogId'
 *   },
 * });
 */
export function useGetErrorsSettingCatalogActiveQuery(baseOptions: Apollo.QueryHookOptions<GetErrorsSettingCatalogActiveQuery, GetErrorsSettingCatalogActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetErrorsSettingCatalogActiveQuery, GetErrorsSettingCatalogActiveQueryVariables>(GetErrorsSettingCatalogActiveDocument, options);
      }
export function useGetErrorsSettingCatalogActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetErrorsSettingCatalogActiveQuery, GetErrorsSettingCatalogActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetErrorsSettingCatalogActiveQuery, GetErrorsSettingCatalogActiveQueryVariables>(GetErrorsSettingCatalogActiveDocument, options);
        }
export type GetErrorsSettingCatalogActiveQueryHookResult = ReturnType<typeof useGetErrorsSettingCatalogActiveQuery>;
export type GetErrorsSettingCatalogActiveLazyQueryHookResult = ReturnType<typeof useGetErrorsSettingCatalogActiveLazyQuery>;
export type GetErrorsSettingCatalogActiveQueryResult = Apollo.QueryResult<GetErrorsSettingCatalogActiveQuery, GetErrorsSettingCatalogActiveQueryVariables>;