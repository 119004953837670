import React, { useContext } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  QueryInspectionGiveAnswersForReservationArgs,
  ReservationType,
} from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_INSPECTION_GIVE_ANSWERS_FOR_RESERVATION_QUERY,
  QueryResultInspectionGiveAnswersForReservation,
} from "../../apollo/queries/inspections";
import { useTranslation } from "react-i18next";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import InspectionGiveAnswer from "./InspectionGiveAnswer";
import InspectionGiveAnswerReport from "./InspectionGiveAnswerReport";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  reservation: Pick<ReservationType, "id">;
  editable: boolean;
}

function DialogContentInspectionGiveAnswers({
  classes,
  reservation,
  editable,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultInspectionGiveAnswersForReservation,
    QueryInspectionGiveAnswersForReservationArgs
  >(GET_INSPECTION_GIVE_ANSWERS_FOR_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("inspectionGiveAnswersForReservation"),
    variables: {
      reservationId: reservation.id,
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeInspectionGiveAnswer = checkPermission(
    myPermissions,
    ["inspections.change_inspectiongiveanswer"]
  );

  const editableAndPerm = editable && hasPermissionChangeInspectionGiveAnswer;

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div className={loading ? "loading" : ""}>
      {data.inspectionGiveAnswersForReservation?.length === 0 && (
        <p className="text-muted">{t("no_inspections_for_machine")}</p>
      )}
      {data.inspectionGiveAnswersForReservation?.map((inspectionGiveAnswer) => {
        if (editableAndPerm) {
          return (
            <InspectionGiveAnswer
              key={inspectionGiveAnswer.id}
              inspectionGiveAnswer={inspectionGiveAnswer}
              inspectionGiveQuestion={
                inspectionGiveAnswer.inspectionGiveToMachine
                  .inspectionGiveQuestion
              }
            />
          );
        }

        return (
          <InspectionGiveAnswerReport
            key={inspectionGiveAnswer.id}
            inspectionGiveAnswer={inspectionGiveAnswer}
            inspectionGiveQuestion={
              inspectionGiveAnswer.inspectionGiveToMachine
                .inspectionGiveQuestion
            }
          />
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentInspectionGiveAnswers);
