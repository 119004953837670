import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, createStyles, Theme } from "@material-ui/core";
import { ReservationType } from "../../entity/types";
import MaterialTable, { Column } from "material-table";
import {
  getDefaultSortReservations,
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  setDefaultSortReservations,
  SortingDirectionType,
  SortingType,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DialogReservationInfo from "../Reservation/DialogReservationInfo";
import { ReservationEmpty } from "../../entity/empties";
import { ID_EMPTY } from "../../utils/constants";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ButtonOrderReservation from "../Shared/ButtonOrderReservation";
import { doesMatchAllTerms } from "../../utils/search";
import DialogOrderId from "../Customer/DialogOrderId";
import { getReservationProductName } from "../../utils/reservations/reservation";

export const defaultSortField = "orderId";
export const defaultSortDirection = "asc";

type ReservationRowType = {
  reservation: ReservationType;
  id: string;
  orderId: string;
  orderStatus: string;
  orderCreatedAt: string;
  reference: string;
  catalogRow: string;
  machine: string;
  dateRented: string;
  dateReturned: string;
  giveAt: string;
  returnAt: string;
  createdAt: string;
};

interface Props extends WithStyles<typeof styles> {
  reservations: ReservationType[];
}

const MaterialTableReservations = ({ classes, reservations }: Props) => {
  const { t } = useTranslation();

  const [reservationInfoOpen, setReservationInfoOpen] = useState(false);
  const [openDialogOrder, setOpenDialogOrder] = useState(false);
  const [orderIdSelected, setOrderIdSelected] = useState(ID_EMPTY);
  const [reservationIdSelected, setReservationIdSelected] = useState(ID_EMPTY);
  const [reservationSelected, setReservationSelected] =
    useState(ReservationEmpty);
  const [sorting, setSorting] = useState<SortingType>(
    getDefaultSortReservations()
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewOrder = checkPermission(myPermissions, [
    "orders.view_order",
  ]);

  const data: ReservationRowType[] = [];
  reservations.forEach((reservation) => {
    const catalogRowText = reservation.catalogExtraRowRental
      ? reservation.catalogExtraRowRental.catalogExtraCategoryRental.name
      : reservation.catalogRow
      ? reservation.catalogRow.name
      : "";

    data.push({
      reservation: reservation,
      id: reservation.id,
      orderId: reservation.order.id,
      orderStatus: t("order_status_" + reservation.order.status),
      orderCreatedAt: reservation.order.createdAt,
      reference: reservation.order.reference
        ? reservation.order.reference.name
        : "",
      catalogRow: catalogRowText,
      machine: getReservationProductName(t, reservation),
      dateRented: reservation.dateRented,
      dateReturned: reservation.dateReturned,
      giveAt: reservation.giveAt ? reservation.giveAt : t("no"),
      returnAt: reservation.returnAt ? reservation.returnAt : t("no"),
      createdAt: reservation.createdAt,
    });
  });

  data.sort((a, b) => b.createdAt.localeCompare(a.createdAt));

  const onClickOrder = (orderId: string) => {
    setOrderIdSelected(orderId);
    setReservationIdSelected(ID_EMPTY);
    setOpenDialogOrder(true);
  };

  const onClickReservation = (orderId: string, reservationId: string) => {
    setOrderIdSelected(orderId);
    setReservationIdSelected(reservationId);
    setOpenDialogOrder(true);
  };

  const columns: Column<ReservationRowType>[] = [
    {
      title: t("order_reservation_id"),
      field: "orderId",
      render: (row) => (
        <>
          <Button
            className="me-1 mb-1"
            onClick={() => onClickOrder(row.orderId)}
            variant="outlined"
            disabled={!hasPermissionViewOrder}
          >
            #{row.orderId}
          </Button>
          <ButtonOrderReservation
            reservationId={row.id}
            callbackOnClick={() => {
              onClickReservation(row.orderId, row.id);
            }}
          />
        </>
      ),
      customFilterAndSearch: (term: string, row) =>
        doesMatchAllTerms(term, row.orderId + " " + row.id),
      customSort: (rowA, rowB) => {
        const valA = rowA.orderId + " " + rowA.id;
        const valB = rowB.orderId + " " + rowB.id;
        return valA.localeCompare(valB);
      },
      defaultSort:
        sorting.field === "orderId" ? sorting.orderDirection : undefined,
    },
    {
      title: t("reference"),
      field: "reference",
      defaultSort:
        sorting.field === "reference" ? sorting.orderDirection : undefined,
    },
    {
      title: t("order_status"),
      field: "orderStatus",
      defaultSort:
        sorting.field === "orderStatus" ? sorting.orderDirection : undefined,
    },
    {
      title: t("catalog_row"),
      field: "catalogRow",
      defaultSort:
        sorting.field === "catalogRow" ? sorting.orderDirection : undefined,
    },
    {
      title: t("machine"),
      field: "machine",
      defaultSort:
        sorting.field === "machine" ? sorting.orderDirection : undefined,
    },
    {
      title: t("date_rented"),
      field: "dateRented",
      type: "date",
      defaultSort:
        sorting.field === "dateRented" ? sorting.orderDirection : undefined,
    },
    {
      title: t("date_returned"),
      field: "dateReturned",
      type: "date",
      defaultSort:
        sorting.field === "dateReturned" ? sorting.orderDirection : undefined,
    },
    {
      title: t("give_at"),
      field: "giveAt",
      type: "datetime",
      defaultSort:
        sorting.field === "giveAt" ? sorting.orderDirection : undefined,
    },
    {
      title: t("return_at"),
      field: "returnAt",
      type: "datetime",
      defaultSort:
        sorting.field === "returnAt" ? sorting.orderDirection : undefined,
    },
    {
      title: t("created_at"),
      field: "orderCreatedAt",
      type: "datetime",
      defaultSort:
        sorting.field === "orderCreatedAt" ? sorting.orderDirection : undefined,
    },
  ];

  const handleOrderChange = (
    orderBy: number,
    orderDirection: "asc" | "desc" | ""
  ) => {
    const field = columns[orderBy]
      ? String(columns[orderBy].field)
      : defaultSortField;
    const orderDirectionNew: SortingDirectionType =
      orderDirection === "" ? defaultSortDirection : orderDirection;

    setSorting({
      field: field,
      orderDirection: orderDirectionNew,
    });

    setDefaultSortReservations(field, orderDirectionNew);
  };

  return (
    <div>
      <MaterialTable<ReservationRowType>
        style={getTableStyle()}
        title={""}
        icons={tableIcons}
        localization={GetTableLocalization(t)}
        columns={columns}
        data={data}
        options={getTableOptions()}
        components={{
          Action: (props) => (
            <Button
              className="btnMaterialTableMenu"
              aria-controls="menuUser"
              aria-haspopup="true"
              onClick={() => {
                setReservationSelected(props.data.reservation);
                setReservationInfoOpen(true);
              }}
            >
              <FontAwesomeIcon size="lg" icon={faEllipsisV} />
            </Button>
          ),
        }}
        actions={[
          {
            icon: "menu",
            onClick: () => {},
          },
        ]}
        onOrderChange={handleOrderChange}
      />
      {reservationInfoOpen && (
        <DialogReservationInfo
          open={reservationInfoOpen}
          setOpen={setReservationInfoOpen}
          reservation={reservationSelected}
        />
      )}
      {openDialogOrder && (
        <DialogOrderId
          open={openDialogOrder}
          setOpen={setOpenDialogOrder}
          orderId={orderIdSelected}
          reservationIdSelected={reservationIdSelected}
        />
      )}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    reservationFav: {
      "&:hover": {
        cursor: "pointer",
      },
    },
  });

export default withStyles(styles)(MaterialTableReservations);
