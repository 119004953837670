import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogRowType,
  CatalogType,
  LocationType,
  MachineModelType,
  MachinesMachineRentThroughCommissionCaseChoices,
  MachineType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { renderSelectGroupCategoryRow } from "../../utils/catalogs/select_group";
import { useQuery } from "@apollo/client";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import {
  GET_MAKES_AND_MODELS_QUERY,
  QueryResultMakesAndModels,
} from "../../apollo/queries/machines";
import { Autocomplete } from "@material-ui/lab";
import { Button } from "react-bootstrap";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import DialogMachineMakeAndModel from "./DialogMachineMakeAndModel";
import MachineBulkAmount from "./MachineBulkAmount";
import {
  GET_LOCATIONS_QUERY,
  QueryResultLocations,
} from "../../apollo/queries/locations";
import SelectRequired from "../Shared/SelectRequired";
import { ID_EMPTY } from "../../utils/constants";
import { LocationEmpty } from "../../entity/empties";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  setMachine: React.Dispatch<React.SetStateAction<MachineType>>;
  catalog: CatalogType;
}

function DialogContentMachine({
  classes,
  machine,
  setMachine,
  catalog,
}: Props) {
  const { t } = useTranslation();

  const [openMachineMakeModel, setOpenMachineMakeModel] = useState(false);

  const {
    loading: loadingLocations,
    error: errorLocations,
    data: dataLocations,
  } = useQuery<QueryResultLocations>(GET_LOCATIONS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("locations"),
  });

  const { loading, error, data } = useQuery<QueryResultMakesAndModels>(
    GET_MAKES_AND_MODELS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("machineMakes"),
    }
  );

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "machines.change_machine",
  ]);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "machines.add_machine",
  ]);
  const disabled =
    machine.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  if (loading || loadingLocations) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (errorLocations) return <Error error={errorLocations} />;
  if (!data || !dataLocations) return <Error error={t("error_query_failed")} />;

  let machineModels: MachineModelType[] = [];
  data.machineMakes?.forEach((machineMake) => {
    machineMake.machinemodelSet.forEach((machineModel) => {
      machineModels.push({ ...machineModel, machineMake: machineMake });
    });
  });

  return (
    <DialogContent>
      <label className="text-muted small">{t("machine_make")}</label>
      <p>{machine.machineModel.machineMake.title}</p>
      <div className="d-flex">
        <FormControl fullWidth>
          <Autocomplete
            options={machineModels}
            groupBy={(machineModel) => machineModel.machineMake.title}
            getOptionLabel={(machineMake) => machineMake.title}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("machine_model")}
                required={true}
              />
            )}
            value={machine.machineModel}
            getOptionSelected={(a, b) => {
              return a.id === b.id;
            }}
            onChange={(event, machineModelSelected) => {
              if (machineModelSelected) {
                setMachine({
                  ...machine,
                  machineModel: machineModelSelected,
                });
              }
            }}
          />
        </FormControl>
        <Button
          onClick={() => setOpenMachineMakeModel(!openMachineMakeModel)}
          className="btnRoundSmall ms-3"
          variant="primary"
        >
          {openMachineMakeModel ? <ClearIcon /> : <AddIcon />}
        </Button>
        {openMachineMakeModel && (
          <DialogMachineMakeAndModel
            open={openMachineMakeModel}
            setOpen={setOpenMachineMakeModel}
            machineMakeIdDefault={machine.machineModel.machineMake.id}
          />
        )}
      </div>
      <FormControl fullWidth>
        <TextField
          label={t("serial")}
          onChange={(event) => {
            setMachine({ ...machine, serial: event.target.value });
          }}
          value={machine.serial}
          required={true}
          disabled={disabled}
          inputProps={{ maxLength: 50 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("identifier")}
          onChange={(event) => {
            setMachine({ ...machine, identifier: event.target.value });
          }}
          value={machine.identifier}
          required={true}
          disabled={disabled}
          inputProps={{ maxLength: 5 }}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          type="date"
          label={t("acquisition_date")}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setMachine({ ...machine, acquisitionDate: event.target.value });
          }}
          value={machine.acquisitionDate ? machine.acquisitionDate : ""}
          required={true}
          disabled={disabled}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          type="date"
          label={t("date_removed")}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => {
            setMachine({ ...machine, dateRemoved: event.target.value });
          }}
          value={machine.dateRemoved ? machine.dateRemoved : ""}
          disabled={disabled}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextFieldFocus
          type="number"
          label={t("year_model")}
          onChange={(event) => {
            setMachine({ ...machine, yearModel: Number(event.target.value) });
          }}
          value={machine.yearModel}
          disabled={disabled}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblMachineHourTracking">
          {t("hour_tracking")}
        </InputLabel>
        <Select
          autoWidth
          labelId="lblMachineHourTracking"
          value={machine.hourTracking ? 1 : 0}
          disabled={disabled}
          onChange={(event) => {
            setMachine({
              ...machine,
              hourTracking: Number(event.target.value) === 1,
            });
          }}
        >
          <MenuItem value={1}>{t("yes")}</MenuItem>
          <MenuItem value={0}>{t("no")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblMachineRentThrough">{t("rent_through")}</InputLabel>
        <Select
          autoWidth
          labelId="lblMachineRentThrough"
          value={machine.rentThrough ? 1 : 0}
          disabled={disabled}
          onChange={(event) => {
            setMachine({
              ...machine,
              rentThrough: Number(event.target.value) === 1,
            });
          }}
        >
          <MenuItem value={1}>{t("yes")}</MenuItem>
          <MenuItem value={0}>{t("no")}</MenuItem>
        </Select>
      </FormControl>
      {machine.rentThrough && (
        <FormControl fullWidth>
          <InputLabel id="lblMachineRentThroughCommissionCase">
            {t("rent_through_commission_case")}
          </InputLabel>
          <Select
            autoWidth
            labelId="lblMachineRentThroughCommissionCase"
            value={machine.rentThroughCommissionCase}
            disabled={disabled}
            onChange={(event) => {
              setMachine({
                ...machine,
                rentThroughCommissionCase: event.target
                  .value as MachinesMachineRentThroughCommissionCaseChoices,
              });
            }}
          >
            <MenuItem
              value={MachinesMachineRentThroughCommissionCaseChoices.Yes}
            >
              {t(
                "rent_through_commission_case_" +
                  MachinesMachineRentThroughCommissionCaseChoices.Yes
              )}
            </MenuItem>
            <MenuItem
              value={MachinesMachineRentThroughCommissionCaseChoices.No}
            >
              {t(
                "rent_through_commission_case_" +
                  MachinesMachineRentThroughCommissionCaseChoices.No
              )}
            </MenuItem>
            <MenuItem
              value={MachinesMachineRentThroughCommissionCaseChoices.Substitute}
            >
              {t(
                "rent_through_commission_case_" +
                  MachinesMachineRentThroughCommissionCaseChoices.Substitute
              )}
            </MenuItem>
          </Select>
        </FormControl>
      )}
      {machine.rentThrough && (
        <FormControl fullWidth>
          <TextField
            label={t("rent_through_company")}
            onChange={(event) => {
              setMachine({
                ...machine,
                rentThroughCompany: event.target.value,
              });
            }}
            value={machine.rentThroughCompany}
            disabled={disabled}
            inputProps={{ maxLength: 100 }}
          />
        </FormControl>
      )}
      {machine.rentThrough && (
        <FormControl fullWidth>
          <TextField
            label={t("rent_through_information")}
            onChange={(event) => {
              setMachine({
                ...machine,
                rentThroughInformation: event.target.value,
              });
            }}
            value={machine.rentThroughInformation}
            disabled={disabled}
            inputProps={{ maxLength: 250 }}
            multiline={true}
            minRows={2}
          />
        </FormControl>
      )}
      {!machine.bulkProduct && (
        <FormControl fullWidth>
          <InputLabel id="lblMachineLocation">{t("location")}</InputLabel>
          <SelectRequired
            autoWidth
            labelId="lblMachineLocation"
            value={machine.location ? machine.location.id : ""}
            disabled={disabled}
            onChange={(event) => {
              setMachine({
                ...machine,
                location: {
                  ...(machine.location ? machine.location : LocationEmpty),
                  id: String(event.target.value),
                },
              });
            }}
          >
            <MenuItem key={ID_EMPTY} value={""}>
              {t("not_selected")}
            </MenuItem>
            {dataLocations.locations?.map((location: LocationType) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </SelectRequired>
        </FormControl>
      )}
      <FormControl fullWidth>
        <InputLabel id="lblMachineCatalogRow">{t("catalog_rows")}</InputLabel>
        <Select
          autoWidth
          labelId="lblMachineCatalogRow"
          value={machine.catalogRows.map((catalogRow) => catalogRow.id)}
          disabled={disabled}
          onChange={(event: React.ChangeEvent<{ value: any }>) => {
            let catalogRowIdsAllInCatalog: string[] = [];
            let catalogRowsNew: CatalogRowType[] = [];
            catalog.catalogcategoryupperSet.forEach((catalogCategoryUpper) =>
              catalogCategoryUpper.catalogcategorySet.forEach(
                (catalogCategory) =>
                  catalogCategory.catalogrowSet.forEach((catalogRow) => {
                    if (event.target.value.indexOf(catalogRow.id) > -1) {
                      catalogRowsNew.push(catalogRow);
                    }
                    catalogRowIdsAllInCatalog.push(catalogRow.id);
                  })
              )
            );

            // Add catalogRows from other catalogs back to the list
            machine.catalogRows.forEach((catalogRow) => {
              if (!catalogRowIdsAllInCatalog.includes(catalogRow.id)) {
                catalogRowsNew.push(catalogRow);
              }
            });

            setMachine({
              ...machine,
              catalogRows: catalogRowsNew,
            });
          }}
          multiple={true}
        >
          {catalog.catalogcategoryupperSet.map((catalogCategoryUpper) =>
            renderSelectGroupCategoryRow(catalogCategoryUpper)
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          label={t("information")}
          onChange={(event) => {
            setMachine({
              ...machine,
              information: event.target.value,
            });
          }}
          value={machine.information}
          disabled={disabled}
          inputProps={{ maxLength: 250 }}
          multiline={true}
          minRows={2}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel id="lblMachineBulkProduct">{t("bulk_product")}</InputLabel>
        <Select
          autoWidth
          labelId="lblMachineBulkProduct"
          value={machine.bulkProduct ? 1 : 0}
          disabled={disabled}
          onChange={(event) => {
            const bulkProductNew = Number(event.target.value) === 1;
            const locationNew =
              !bulkProductNew && machine.location
                ? machine.location
                : LocationEmpty;
            setMachine({
              ...machine,
              bulkProduct: bulkProductNew,
              location: locationNew,
            });
          }}
        >
          <MenuItem value={1}>{t("yes")}</MenuItem>
          <MenuItem value={0}>{t("no")}</MenuItem>
        </Select>
      </FormControl>
      {machine.bulkProduct && (
        <MachineBulkAmount machine={machine} setMachine={setMachine} />
      )}
    </DialogContent>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentMachine);
