import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { mergeDateTime } from "../../utils/dates";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faCommentAlt,
  faEuroSign,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { getReservationProductName } from "../../utils/reservations/reservation";
import WorkQueueCardButtonsRenting from "./WorkQueueCardButtonsRenting";
import { DriveListType } from "./WorkQueueCardDrive";
import WorkQueueCardUser from "./WorkQueueCardUser";
import DialogCatalogExtraReservation from "../CatalogExtraUnitPrice/DialogCatalogExtraReservation";
import ButtonOrderDialog from "../Shared/ButtonOrderDialog";
import { getUrlCustomerTab } from "../../utils/urls";
import { LinkContainer } from "react-router-bootstrap";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";
import { TransportFromDrivesForWorkQueuesType } from "../../apollo/queries/drives";
import WorkQueueComments from "./WorkQueueComments";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  type: DriveListType;
  transport?: TransportFromDrivesForWorkQueuesType | null;
}

function WorkQueueCardRenting({
  classes,
  reservation,
  type,
  transport = null,
}: Props) {
  const { t } = useTranslation();

  const [showComments, setShowComments] = useState(false);
  const [anchorElUserOwner, setAnchorElUserOwner] = useState<null | Element>(
    null
  );
  const [openCatalogExtra, setOpenCatalogExtra] = useState(false);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionShowComment = checkPermission(myPermissions, [
    "reservations.view_reservationcomment",
  ]);
  const hasPermissionAddComment = checkPermission(myPermissions, [
    "reservations.add_reservationcomment",
  ]);

  let date = "";
  if (reservation.giveAt) {
    date = mergeDateTime(t, reservation.dateReturned, reservation.timeReturned);
  } else {
    date = mergeDateTime(t, reservation.dateRented, reservation.timeRented);
  }

  let locations: string[] = [reservation.order.location.name];
  if (
    reservation.machine?.location &&
    !locations.includes(reservation.machine.location.name)
  ) {
    locations.push(reservation.machine.location.name);
  }

  const userOwner =
    type === "give" ? reservation.userGive : reservation.userReturn;

  return (
    <div className="cardWorkQueue">
      <div className="cardWorkQueueActions">
        <div className="cardWorkQueueActionCol left">
          <WorkQueueCardButtonsRenting
            reservation={reservation}
            type={type}
            transport={transport}
          />
        </div>
        <div className="cardWorkQueueActionCol right">
          {date}
          <br />
          <span className="text-muted">{locations.join(" / ")}</span>
        </div>
      </div>
      <span className="text-muted" title={t("order_id_header")}>
        <ButtonOrderDialog orderId={reservation.order.id} />
      </span>{" "}
      {reservation.order.customer && (
        <LinkContainer
          to={getUrlCustomerTab(reservation.order.customer.id, "basic")}
        >
          <Button size="sm" variant="light">
            {reservation.order.customer.name}
          </Button>
        </LinkContainer>
      )}
      <br />
      <span>
        <span className="text-muted" title={t("reservation_id_header")}>
          <ButtonOrderDialog
            orderId={reservation.order.id}
            reservationId={reservation.id}
          />
        </span>{" "}
        {getReservationProductName(t, reservation, true, true)}
      </span>
      {reservation.information && (
        <div className="text-muted mt-1">{reservation.information}</div>
      )}
      {hasPermissionShowComment &&
        (hasPermissionAddComment ||
          reservation.reservationcommentSet.length > 0) && (
          <div className="cardWorkQueueBottom">
            <span className={`me-2 ${userOwner ? "" : "text-muted"}`}>
              {userOwner
                ? userOwner.lastName + " " + userOwner.firstName
                : t("no_user_give_checked")}
            </span>
            <Button
              className="me-1"
              variant="light"
              onClick={(event) => setAnchorElUserOwner(event.currentTarget)}
            >
              <FontAwesomeIcon icon={faUser} />
            </Button>
            {anchorElUserOwner && (
              <WorkQueueCardUser
                type={type}
                reservation={reservation}
                anchorEl={anchorElUserOwner}
                setAnchorEl={setAnchorElUserOwner}
              />
            )}
            <Button
              className="me-1"
              variant="light"
              title={t("catalog_extra_unit_price")}
              onClick={() => setOpenCatalogExtra(!openCatalogExtra)}
            >
              <FontAwesomeIcon icon={faEuroSign} />
            </Button>
            {openCatalogExtra && (
              <DialogCatalogExtraReservation
                open={openCatalogExtra}
                setOpen={setOpenCatalogExtra}
                reservation={reservation}
              />
            )}
            <Button
              className="float-end"
              variant={
                reservation.reservationcommentSet.length > 0
                  ? "primary"
                  : "outline-primary"
              }
              onClick={() => setShowComments(!showComments)}
            >
              {reservation.reservationcommentSet.length}
              <FontAwesomeIcon className="ms-2" icon={faCommentAlt} />
              <FontAwesomeIcon
                className="ms-2"
                icon={showComments ? faChevronUp : faChevronDown}
              />
            </Button>
            {showComments && (
              <WorkQueueComments reservationId={reservation.id} />
            )}
          </div>
        )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueCardRenting);
