import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Menu, MenuItem, Theme, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import {
  MachineType,
  Mutation,
  MutationDeleteMachineArgs,
} from "../../entity/types";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { DELETE_MACHINE_MUTATION } from "../../apollo/mutations/machines";
import { GET_MACHINES_QUERY } from "../../apollo/queries/machines";
import { dialogConfirm } from "../../utils/dialogs";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialogConnectRowsRental: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialogConnectRowsUnitPrice: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setOpenDialogMachineInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialogInspectionReturn: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialogInspectionGive: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDialogMaintenanceSettings: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setMachineSelected: React.Dispatch<React.SetStateAction<MachineType>>;
}

const MachineListMenu = ({
  classes,
  machine,
  setOpenEdit,
  setOpenDialogConnectRowsRental,
  setOpenDialogConnectRowsUnitPrice,
  setOpenDialogMachineInfo,
  setOpenDialogInspectionReturn,
  setOpenDialogInspectionGive,
  setOpenDialogMaintenanceSettings,
  setMachineSelected,
}: Props) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [deleteMachine, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteMachineArgs
  >(DELETE_MACHINE_MUTATION, {
    refetchQueries: [{ query: GET_MACHINES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAddInspectionGiveToMachine = checkPermission(
    myPermissions,
    ["inspections.add_inspectiongivetomachine"]
  );
  const hasPermissionAddInspectionReturnToMachine = checkPermission(
    myPermissions,
    ["inspections.add_inspectionreturntomachine"]
  );
  const hasPermissionEdit = checkPermission(myPermissions, [
    "machines.change_machine",
  ]);
  const hasPermissionEditInfoAnswer = checkPermission(myPermissions, [
    "machines.change_machineinfoanswer",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "machines.delete_machine",
  ]);
  const hasPermissionViewServiceMaintenanceSettings = checkPermission(
    myPermissions,
    ["maintenance.view_maintenancesetting"]
  );

  if (
    !hasPermissionAddInspectionGiveToMachine &&
    !hasPermissionAddInspectionReturnToMachine &&
    !hasPermissionEdit &&
    !hasPermissionEditInfoAnswer &&
    !hasPermissionDelete
  ) {
    return <></>;
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenEdit(true);
  };

  const handleClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteMachine({ variables: { machineId: machine.id } }).then(() => {
        handleClose();
      });
    });
  };

  const handleClickCatalogExtraRental = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenDialogConnectRowsRental(true);
  };

  const handleClickCatalogExtraUnitPrice = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenDialogConnectRowsUnitPrice(true);
  };

  const handleClickMachineInfo = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenDialogMachineInfo(true);
  };

  const handleClickInspectionGive = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenDialogInspectionGive(true);
  };

  const handleClickInspectionReturn = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenDialogInspectionReturn(true);
  };

  const handleClickServiceMaintenanceSettings = () => {
    handleClose();
    setMachineSelected(machine);
    setOpenDialogMaintenanceSettings(true);
  };

  return (
    <div>
      <Button
        className="btnMaterialTableMenu"
        aria-controls="menuMachine"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <FontAwesomeIcon size="lg" icon={faEllipsisV} />
      </Button>
      <Menu
        id="menuMachine"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {hasPermissionEdit && (
          <MenuItem onClick={handleClickEdit}>{t("edit")}</MenuItem>
        )}
        {hasPermissionEdit && (
          <MenuItem onClick={handleClickCatalogExtraRental}>
            {t("machine_catalog_extra_rental")} &nbsp;
            <small>{machine.catalogExtraRowsRental.length}</small>
          </MenuItem>
        )}
        {hasPermissionEdit && (
          <MenuItem onClick={handleClickCatalogExtraUnitPrice}>
            {t("machine_catalog_extra_unit_price")} &nbsp;
            <small>{machine.catalogExtraRowsUnitPrice.length}</small>
          </MenuItem>
        )}
        {hasPermissionEditInfoAnswer && (
          <MenuItem onClick={handleClickMachineInfo}>
            {t("machine_information")}
          </MenuItem>
        )}
        {hasPermissionAddInspectionGiveToMachine && (
          <MenuItem onClick={handleClickInspectionGive}>
            {t("inspection_give")}
          </MenuItem>
        )}
        {hasPermissionAddInspectionReturnToMachine && (
          <MenuItem onClick={handleClickInspectionReturn}>
            {t("inspection_return")}
          </MenuItem>
        )}
        {hasPermissionViewServiceMaintenanceSettings && (
          <MenuItem onClick={handleClickServiceMaintenanceSettings}>
            {t("maintenance_settings")}
          </MenuItem>
        )}
        {hasPermissionDelete && (
          <MenuItem
            className={loadingDelete ? "loading" : ""}
            onClick={handleClickDelete}
          >
            {t("delete")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(MachineListMenu);
