import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CosttypeType,
  MutationCreateCosttypeArgs,
  MutationDeleteCosttypeArgs,
  MutationUpdateCosttypeArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";
import {
  CREATE_COSTTYPE_MUTATION,
  DELETE_COSTTYPE_MUTATION,
  MutationResultCreateCosttype,
  MutationResultDeleteCosttype,
  MutationResultUpdateCosttype,
  UPDATE_COSTTYPE_MUTATION,
} from "../../apollo/mutations/costtypes";
import TextField from "@material-ui/core/TextField";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import { CosttypeEmpty } from "../../entity/empties";

interface Props extends WithStyles<typeof styles> {
  costtype: CosttypeType;
  setCosttype: React.Dispatch<React.SetStateAction<CosttypeType>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DialogCosttype({
  classes,
  costtype,
  setCosttype,
  open,
  setOpen,
}: Props) {
  const { t } = useTranslation();

  const [createCosttype, { loading: loadingCreate }] = useMutation<
    MutationResultCreateCosttype,
    MutationCreateCosttypeArgs
  >(CREATE_COSTTYPE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("costtypes"),
      });
    },
  });

  const [updateCosttype, { loading: loadingUpdate }] = useMutation<
    MutationResultUpdateCosttype,
    MutationUpdateCosttypeArgs
  >(UPDATE_COSTTYPE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("costtypes"),
      });
    },
  });

  const [deleteCosttype, { loading: loadingDelete }] = useMutation<
    MutationResultDeleteCosttype,
    MutationDeleteCosttypeArgs
  >(DELETE_COSTTYPE_MUTATION, {
    onCompleted: () => {
      setOpen(false);
      setCosttype(CosttypeEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("costtypes"),
      });
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "costtypes.add_costtype",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "costtypes.change_costtype",
  ]);
  const disabled =
    costtype.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (costtype.id === ID_EMPTY) {
      createCosttype({
        variables: {
          number: costtype.number,
          name: costtype.name,
        },
      });
    } else {
      updateCosttype({
        variables: {
          costtypeId: costtype.id,
          number: costtype.number,
          name: costtype.name,
        },
      });
    }
    setOpen(false);
  };

  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCosttype({
        variables: {
          costtypeId: costtype.id,
        },
      });
    });
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("costtype")}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t("identifier")}
            onChange={(event) => {
              setCosttype({ ...costtype, number: event.target.value });
            }}
            inputProps={{ maxLength: 10 }}
            value={costtype.number}
            disabled={disabled}
            required={true}
          />
          <TextField
            fullWidth
            label={t("name")}
            onChange={(event) => {
              setCosttype({ ...costtype, name: event.target.value });
            }}
            inputProps={{ maxLength: 100 }}
            value={costtype.name}
            disabled={disabled}
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingDelete}
            type="button"
            onClick={onClickDelete}
            variant="light"
          >
            {t("delete")}
          </ButtonLoad>
          <ButtonLoad
            loading={loadingCreate || loadingUpdate}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCosttype);
