import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControl,
  MenuItem,
  Popover,
  Select,
  Theme,
} from "@material-ui/core";
import {
  Mutation,
  MutationUpdateReservationStampsArgs,
  UserType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { PermissionsContext, UserContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { DriveListType } from "./WorkQueueCardDrive";
import { useMutation, useQuery } from "@apollo/client";
import { GET_USERS_QUERY, QueryResultUsers } from "../../apollo/queries/users";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import { COLOR_MUTED_LIGHT, ID_EMPTY } from "../../utils/constants";
import { UPDATE_RESERVATION_STAMPS_MUTATION } from "../../apollo/mutations/reservations";
import { handleError } from "../../entity/ErrorHandler";
import { updateCacheReservationStampsMutation } from "../../utils/cache";
import { findFromSetById } from "../../utils/collections";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  type: DriveListType;
  anchorEl: null | Element;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | Element>>;
}

function WorkQueueCardUser({
  classes,
  reservation,
  type,
  anchorEl,
  setAnchorEl,
}: Props) {
  const { t } = useTranslation();

  const userMe = useContext(UserContext);

  const { loading, error, data } = useQuery<QueryResultUsers>(GET_USERS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("users"),
  });

  const [userOwner, setUserOwner] = useState<
    Pick<UserType, "id"> | null | undefined
  >(type === "give" ? reservation.userGive : reservation.userReturn);

  const [updateReservationStamps, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateReservationStampsArgs
  >(UPDATE_RESERVATION_STAMPS_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      updateCacheReservationStampsMutation(cache);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "reservations.change_reservation",
  ]);

  if (loading || loadingUpdate) {
    return (
      <Popover open={true} anchorEl={anchorEl}>
        <LoadingSimple />
      </Popover>
    );
  }
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  if (!hasPermissionEdit) {
    return <></>;
  }

  const handleUpdateStamps = (userOwnerNew: UserType | undefined) => {
    const userId = userOwnerNew ? userOwnerNew.id : undefined;
    updateReservationStamps({
      variables: {
        reservationId: reservation.id,
        userGiveId:
          type === "give"
            ? userId
            : reservation.userGive
            ? reservation.userGive.id
            : undefined,
        userReturnId:
          type === "return"
            ? userId
            : reservation.userReturn
            ? reservation.userReturn.id
            : undefined,
      },
    });
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClick={() => setAnchorEl(null)}
    >
      <div className={classes.popContent}>
        <FormControl>
          <Select
            value={userOwner ? userOwner.id : ID_EMPTY}
            onChange={(event) => {
              const userOwnerNew = findFromSetById(
                String(event.target.value),
                data.users ? data.users : [],
                undefined
              );
              setUserOwner(userOwnerNew);
              handleUpdateStamps(userOwnerNew);
            }}
          >
            <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
            {data.users
              ?.filter((user) => user.id === userMe.id)
              .map((user) => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  className={classes.menuMe}
                >
                  {user.lastName} {user.firstName}
                </MenuItem>
              ))}
            {data.users
              ?.filter((user) => user.id !== userMe.id)
              .map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.lastName} {user.firstName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </Popover>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    popContent: {
      padding: spacing(2),
    },
    menuMe: {
      borderWidth: "1px 0",
      borderStyle: "solid",
      borderColor: COLOR_MUTED_LIGHT,
    },
  });

export default withStyles(styles)(WorkQueueCardUser);
