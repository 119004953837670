import { gql } from "@apollo/client";
import { Query } from "../../entity/types";
import { GetDrivesForWorkQueuesQueryQuery } from "./drives.generated";

export interface QueryResultDrives {
  drives: Query["drives"];
}
export const GET_DRIVES_QUERY = gql`
  query getDrivesQuery($locationIds: [ID]!, $dateFrom: Date!, $dateTo: Date!) {
    drives(locationIds: $locationIds, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      createdBy {
        id
        firstName
        lastName
      }
      createdAt
      drivecontentSet {
        id
        transport {
          id
          method
          address
          postcode
          district
          dateDeadline
          timeDeadline
        }
        reservation {
          id
          order {
            id
          }
          catalogExtraRowRental {
            id
            identifier
            name
          }
          machine {
            id
            identifier
            machineModel {
              id
              title
              machineMake {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export type DriveFromDrivesForWorkQueuesType =
  GetDrivesForWorkQueuesQueryQuery["drivesForWorkQueues"][0];
export type DriveContentFromDrivesForWorkQueuesType =
  DriveFromDrivesForWorkQueuesType["drivecontentSet"][0];
export type TransportFromDrivesForWorkQueuesType =
  DriveContentFromDrivesForWorkQueuesType["transport"];
export type OrderFromDrivesForWorkQueuesType =
  DriveContentFromDrivesForWorkQueuesType["reservation"]["order"];
export const GET_DRIVES_FOR_WORK_QUEUES_QUERY = gql`
  query getDrivesForWorkQueuesQuery($locationIds: [ID]!, $userId: ID) {
    drivesForWorkQueues(locationIds: $locationIds, userId: $userId) {
      id
      drivecontentSet {
        id
        transport {
          id
          method
          address
          postcode
          district
          dateDeadline
          timeDeadline
          information
          dateEndUsage
          timeEndUsage
        }
        reservation {
          id
          status
          dateRented
          timeRented
          dateReturned
          timeReturned
          bulkAmount
          bulkAmountReturned
          information
          giveCheckedAt
          giveImagesAt
          giveInstructedAt
          giveAt
          returnAt
          returnImagesAt
          returnCheckedCatalogAt
          returnCheckedPreAt
          returnCheckedAt
          returnStoredAt
          userGive {
            id
            firstName
            lastName
          }
          userReturn {
            id
            firstName
            lastName
          }
          machine {
            id
            identifier
            bulkProduct
            hourTracking
            machineModel {
              id
              title
              machineMake {
                id
                title
              }
            }
          }
          catalogExtraRowRental {
            id
            identifier
            name
            location {
              id
            }
          }
          reservationcommentSet {
            id
          }
          order {
            id
            location {
              id
              name
            }
            customer {
              id
              name
            }
          }
        }
      }
    }
  }
`;
