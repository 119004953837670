import { gql } from "@apollo/client";
import { Mutation } from "../../entity/types";

export interface MutationResultCreateCosttype {
  createCosttype: Mutation["createCosttype"];
}
export const CREATE_COSTTYPE_MUTATION = gql`
  mutation createCosttype($number: String!, $name: String!) {
    createCosttype(number: $number, name: $name) {
      costtype {
        id
        number
        name
        createdAt
      }
    }
  }
`;

export interface MutationResultUpdateCosttype {
  updateCosttype: Mutation["updateCosttype"];
}
export const UPDATE_COSTTYPE_MUTATION = gql`
  mutation updateCosttype($costtypeId: ID!, $number: String!, $name: String!) {
    updateCosttype(costtypeId: $costtypeId, number: $number, name: $name) {
      costtype {
        id
        number
        name
      }
    }
  }
`;

export interface MutationResultDeleteCosttype {
  deleteCosttype: Mutation["deleteCosttype"];
}
export const DELETE_COSTTYPE_MUTATION = gql`
  mutation deleteCosttype($costtypeId: ID!) {
    deleteCosttype(costtypeId: $costtypeId) {
      costtypeId
    }
  }
`;

export interface MutationResultUpdateCosttypeForRentThrough {
  updateCosttypeForRentThrough: Mutation["updateCosttypeForRentThrough"];
}
export const UPDATE_COSTTYPE_FOR_RENT_THROUGH_MUTATION = gql`
  mutation updateCosttypeForRentThrough($locationId: ID!, $costtypeId: ID) {
    updateCosttypeForRentThrough(
      locationId: $locationId
      costtypeId: $costtypeId
    ) {
      costtypeForRentThrough {
        id
      }
    }
  }
`;
