import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import MaterialTable from "material-table";
import {
  GetTableLocalization,
  getTableOptions,
  getTableStyle,
  tableIcons,
} from "../../utils/TableProps";
import { useTranslation } from "react-i18next";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@apollo/client";
import {
  DELETE_HOLIDAY_MUTATION,
  UPDATE_HOLIDAY_MUTATION,
} from "../../apollo/mutations/holidays";
import { GET_HOLIDAYS_QUERY } from "../../apollo/queries/holidays";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentHoliday from "./DialogContentHoliday";
import { Button } from "react-bootstrap";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import {
  HolidayType,
  Mutation,
  MutationDeleteHolidayArgs,
  MutationUpdateHolidayArgs,
} from "../../entity/types";
import { HolidayEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { dialogConfirm } from "../../utils/dialogs";
import ButtonLoad from "../Shared/ButtonLoad";
import HeaderPage from "../Shared/HeaderPage";

interface Props extends WithStyles<typeof styles> {
  holidays: HolidayType[];
}

const HolidayList = ({ classes, holidays }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [holiday, setHoliday] = useState(HolidayEmpty);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "holidays.change_holiday",
  ]);

  const data = holidays.map((o) => ({ ...o }));

  const [updateHoliday, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateHolidayArgs
  >(UPDATE_HOLIDAY_MUTATION, {
    refetchQueries: [{ query: GET_HOLIDAYS_QUERY }],
    onCompleted: (result) => {
      setHoliday(HolidayEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [deleteHoliday, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteHolidayArgs
  >(DELETE_HOLIDAY_MUTATION, {
    refetchQueries: [{ query: GET_HOLIDAYS_QUERY }],
    onCompleted: (result) => {
      setHoliday(HolidayEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateHoliday({
      variables: {
        holidayId: holiday.id,
        holidayType: holiday.holidayType,
        date: holiday.date,
        name: holiday.name,
      },
    }).then(() => {
      setOpen(false);
    });
  };

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteHoliday({ variables: { holidayId: holiday.id } }).then(() => {
        setOpen(false);
      });
    });
  };

  const getTableActions = () => {
    if (!hasPermissionEdit) {
      return [];
    }

    return [
      {
        icon: () => <FontAwesomeIcon icon={faEllipsisV} />,
        tooltip: t("edit"),
        onClick: (event: any, rowData: HolidayType | HolidayType[]) => {
          const dataSingle: HolidayType = Array.isArray(rowData)
            ? rowData[0]
            : rowData;
          setHoliday(dataSingle);
          setOpen(true);
        },
      },
    ];
  };

  return (
    <>
      <HeaderPage title={t("holidays")} />
      <div className="containerInner">
        <MaterialTable<HolidayType>
          style={getTableStyle()}
          title={""}
          icons={tableIcons}
          localization={GetTableLocalization(t)}
          columns={[
            {
              title: t("type"),
              field: "type",
              render: (rowData) => {
                return t("holiday_type_" + rowData.holidayType);
              },
            },
            { title: t("name"), field: "name" },
            { title: t("date"), field: "date", type: "date" },
          ]}
          data={data}
          options={getTableOptions()}
          actions={getTableActions()}
        />
        <Dialog open={open}>
          <form onSubmit={(event) => handleSubmit(event)}>
            <DialogTitle>{t("edit_holiday")}</DialogTitle>
            <DialogContentHoliday holiday={holiday} setHoliday={setHoliday} />
            <DialogActions>
              <Button onClick={() => setOpen(false)} variant="secondary">
                {t("cancel")}
              </Button>
              <ButtonLoad
                loading={loadingDelete}
                onClick={(event) => handleDelete(event)}
                variant="light"
              >
                {t("delete")}
              </ButtonLoad>
              <ButtonLoad
                loading={loadingUpdate}
                disabled={!holiday.date.trim() || !holiday.name.trim()}
                type="submit"
                variant="primary"
              >
                {t("save")}
              </ButtonLoad>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    </>
  );
};

const styles = {};

export default withStyles(styles)(HolidayList);
