import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogExtraRowInvoiceType,
  QueryCatalogExtraRowsInvoiceStatusArgs,
} from "../../entity/types";
import CatalogExtraRowInvoiceTr from "./CatalogExtraRowInvoiceTr";
import { useQuery } from "@apollo/client";
import {
  GET_CATALOG_EXTRA_ROWS_INVOICE_STATUS_QUERY,
  QueryResultCatalogExtraRowsInvoiceStatus,
} from "../../apollo/queries/catalogs_extra";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";

interface Props extends WithStyles<typeof styles> {
  catalogExtraRowsInvoice: CatalogExtraRowInvoiceType[];
}

function CatalogExtraRowInvoiceTbody({
  classes,
  catalogExtraRowsInvoice,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultCatalogExtraRowsInvoiceStatus,
    QueryCatalogExtraRowsInvoiceStatusArgs
  >(GET_CATALOG_EXTRA_ROWS_INVOICE_STATUS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("catalogExtraRowsInvoiceStatus"),
    variables: {
      catalogExtraRowInvoiceIds: catalogExtraRowsInvoice.map(
        (catalogExtraRowInvoice) => catalogExtraRowInvoice.id
      ),
    },
  });

  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={6}>
            <LoadingSimple />
          </td>
        </tr>
      </tbody>
    );
  }
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const invoicedAtByRow: {
    [id: string]: {
      addedToInvoiceAt: Date | null;
      invoiceSentAt: Date | null;
    };
  } = {};
  data.catalogExtraRowsInvoiceStatus?.forEach(
    (catalogExtraRowInvoiceStatusField) => {
      if (catalogExtraRowInvoiceStatusField.id) {
        invoicedAtByRow[catalogExtraRowInvoiceStatusField.id] = {
          addedToInvoiceAt: catalogExtraRowInvoiceStatusField.addedToInvoiceAt,
          invoiceSentAt: catalogExtraRowInvoiceStatusField.invoiceSentAt,
        };
      }
    }
  );

  return (
    <tbody>
      {catalogExtraRowsInvoice.map((catalogExtraRowInvoice) => {
        let addedToInvoiceAt: Date | null = null;
        let invoiceSentAt: Date | null = null;
        if (invoicedAtByRow[catalogExtraRowInvoice.id] !== undefined) {
          addedToInvoiceAt =
            invoicedAtByRow[catalogExtraRowInvoice.id].addedToInvoiceAt;
          invoiceSentAt =
            invoicedAtByRow[catalogExtraRowInvoice.id].invoiceSentAt;
        }
        return (
          <CatalogExtraRowInvoiceTr
            key={catalogExtraRowInvoice.id}
            catalogExtraRowInvoice={catalogExtraRowInvoice}
            addedToInvoiceAt={addedToInvoiceAt}
            invoiceSentAt={invoiceSentAt}
          />
        );
      })}
    </tbody>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(CatalogExtraRowInvoiceTbody);
