import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  List,
  ListItem,
  ListItemText,
  Theme,
} from "@material-ui/core";
import { ReportPriceType } from "../../entity/types";
import { ID_EMPTY } from "../../utils/constants";
import DialogOrderId from "../Customer/DialogOrderId";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/formatting";
import ButtonOrderReservation from "../Shared/ButtonOrderReservation";

interface Props extends WithStyles<typeof styles> {
  date: string;
  reportPrices: ReportPriceType[];
}

function ReportDayRowContent({ classes, date, reportPrices }: Props) {
  const { t } = useTranslation();
  const [reservationInfoOpen, setReservationInfoOpen] = useState(false);
  const [reservationIdSelected, setReservationIdSelected] = useState(ID_EMPTY);
  const [orderIdSelected, setOrderIdSelected] = useState(ID_EMPTY);

  reportPrices.sort((a, b) =>
    a.title > b.title ? 1 : b.title > a.title ? -1 : 0
  );

  const onClickReservation = (reportPrice: ReportPriceType) => {
    setOrderIdSelected(reportPrice.order.id);
    setReservationIdSelected(reportPrice.reservation.id);
    setReservationInfoOpen(true);
  };

  const getPricePerDay = (reportPrice: ReportPriceType) => {
    let pricePerDay = 0;
    if (reportPrice.useDayPrice) {
      pricePerDay = reportPrice.dayPrice;
    } else {
      const dtArr = date.split("-");
      const dtStr = dtArr[0] + "-" + dtArr[1];
      reportPrice.reportpricemonthSet.forEach((reportPriceMonth) => {
        if (reportPriceMonth.month === dtStr) {
          pricePerDay = reportPriceMonth.pricePerDay;
        }
      });
    }

    return formatNumber(pricePerDay, 2);
  };

  return (
    <div className="w-100">
      <List>
        {reportPrices?.map((reservationWithPrice) => (
          <ListItem key={reservationWithPrice.reservation.id}>
            <ButtonOrderReservation
              reservationId={reservationWithPrice.reservation.id}
              callbackOnClick={() => {
                onClickReservation(reservationWithPrice);
              }}
            />
            <ListItemText
              className="ms-3"
              primary={reservationWithPrice.title}
              secondary={t("price_per_day", {
                price: getPricePerDay(reservationWithPrice),
              })}
            />
          </ListItem>
        ))}
      </List>
      {reservationInfoOpen && reservationIdSelected !== ID_EMPTY && (
        <DialogOrderId
          open={reservationInfoOpen}
          setOpen={setReservationInfoOpen}
          orderId={orderIdSelected}
          reservationIdSelected={reservationIdSelected}
        />
      )}
    </div>
  );
}
const styles = (theme: Theme) =>
  createStyles({
    avatarLower: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: theme.palette.primary.main,
    },
  });

export default withStyles(styles)(ReportDayRowContent);
