import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { Button, createStyles, Menu, MenuItem, Theme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-light-svg-icons";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { DELETE_PROFILE_MUTATION } from "../../apollo/mutations/profiles";
import { GET_PROFILES_QUERY } from "../../apollo/queries/profiles";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import {
  GroupType,
  Mutation,
  MutationDeleteGroupArgs,
} from "../../entity/types";
import { dialogConfirm } from "../../utils/dialogs";
import DialogProfileEdit from "./DialogProfileEdit";

interface Props extends WithStyles<typeof styles> {
  group: GroupType;
}

function ProfileMenu({ classes, group }: Props) {
  const { t } = useTranslation();
  const [anchorElProfile, setAnchorElProfile] =
    useState<HTMLButtonElement | null>(null);
  const [profileOpen, setProfileOpen] = useState<string>("0");
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const permissions = useContext(PermissionsContext);
  const showProfileMenu = checkPermission(permissions, [
    "auth.change_group",
    "auth.delete_group",
  ]);
  const hasPermissionDelete = checkPermission(permissions, [
    "auth.delete_group",
  ]);
  const hasPermissionEdit = checkPermission(permissions, ["auth.change_group"]);

  const [deleteProfile, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteGroupArgs
  >(DELETE_PROFILE_MUTATION, {
    refetchQueries: [{ query: GET_PROFILES_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  if (!showProfileMenu) {
    return <>{group.name}</>;
  }

  const handleCloseProfile = () => {
    setAnchorElProfile(null);
    setProfileOpen("0");
  };

  const handleDeleteProfile = (groupId: string) => {
    handleCloseProfile();

    dialogConfirm(t, t("confirm_delete"), () => {
      deleteProfile({ variables: { groupId } });
    });
  };

  const handleOpenProfile = (
    event: React.MouseEvent<HTMLButtonElement>,
    group_id: string
  ) => {
    setAnchorElProfile(event.currentTarget);
    setProfileOpen(group_id);
  };

  const handleClick = () => {
    handleCloseProfile();
    setOpenDialogEdit(true);
  };

  return (
    <>
      <Button
        className={classes.btn}
        aria-controls={"menuProfile" + group.id}
        aria-haspopup="true"
        onClick={(event) => {
          handleOpenProfile(event, group.id);
        }}
      >
        {group.name}
        <FontAwesomeIcon
          size="lg"
          icon={faEllipsisV}
          className={classes.profileEditIcon}
        />
      </Button>
      <Menu
        id={"menuProfile" + group.id}
        anchorEl={anchorElProfile}
        keepMounted
        open={profileOpen === group.id}
        onClose={handleCloseProfile}
      >
        {hasPermissionEdit && (
          <MenuItem onClick={handleClick}>{t("edit")}</MenuItem>
        )}
        {hasPermissionDelete && (
          <MenuItem
            className={loadingDelete ? "loading" : ""}
            onClick={() => {
              handleDeleteProfile(group.id);
            }}
          >
            {t("delete")}
          </MenuItem>
        )}
      </Menu>
      {openDialogEdit && (
        <DialogProfileEdit
          open={openDialogEdit}
          setOpen={setOpenDialogEdit}
          group={group}
        />
      )}
    </>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    btn: {
      textTransform: "initial",
      fontSize: "1rem",
    },
    profileEditIcon: {
      marginLeft: theme.spacing(2),
      color: theme.palette.primary.main,
    },
  });

export default withStyles(styles)(ProfileMenu);
