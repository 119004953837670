import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  CatalogTransferMethodType,
  Mutation,
  MutationCreateCatalogTransferMethodArgs,
} from "../../entity/types";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { GET_CATALOG_TRANSFER_QUERY } from "../../apollo/queries/catalogs_transfer";
import { CREATE_CATALOG_TRANSFER_METHOD_MUTATION } from "../../apollo/mutations/catalogs_transfer";
import CatalogTransferMethodRow from "./CatalogTransferMethodRow";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalogTransferMethods: CatalogTransferMethodType[];
}

function CatalogTransferLocation({ classes, catalogTransferMethods }: Props) {
  const { t } = useTranslation();

  const [transferMethodNew, setTransferMethodNew] = useState("");

  const [createCatalogTransferMethod, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCatalogTransferMethodArgs
  >(CREATE_CATALOG_TRANSFER_METHOD_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_TRANSFER_QUERY }],
    onCompleted: (result) => {
      setTransferMethodNew("");
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs_transfer.add_catalogtransfermethod",
  ]);

  const handleCreateMethod = () => {
    createCatalogTransferMethod({
      variables: {
        name: transferMethodNew,
      },
    });
  };

  return (
    <div>
      <h6 className="fw-bold mb-3">{t("catalog_transfer_methods")}</h6>
      <Table className={classes.table} borderless>
        {catalogTransferMethods.length > 0 && (
          <tbody>
            {catalogTransferMethods.map((catalogTransferMethod) => (
              <CatalogTransferMethodRow
                key={catalogTransferMethod.id}
                catalogTransferMethod={catalogTransferMethod}
              />
            ))}
          </tbody>
        )}
        {hasPermissionAdd && (
          <tfoot>
            <tr>
              <td>
                <FormControl fullWidth>
                  <TextField
                    label={t("new_catalog_transfer_method")}
                    value={transferMethodNew}
                    inputProps={{
                      maxLength: 100,
                    }}
                    InputProps={{
                      endAdornment: (
                        <ButtonLoad
                          loading={loadingCreate}
                          variant="link"
                          onClick={handleCreateMethod}
                        >
                          <FontAwesomeIcon icon={faCaretRight} />
                        </ButtonLoad>
                      ),
                    }}
                    onChange={(event) => {
                      setTransferMethodNew(event.target.value);
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        handleCreateMethod();
                      }
                    }}
                  />
                </FormControl>
              </td>
              <td></td>
            </tr>
          </tfoot>
        )}
      </Table>
    </div>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    table: {
      width: "auto",
    },
    headerCategory: {
      color: palette.primary.main,
      fontSize: "1.2rem",
    },
  });

export default withStyles(styles)(CatalogTransferLocation);
