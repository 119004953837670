import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { CatalogCategoryUpperType, CatalogType } from "../../entity/types";
import { faCalendarAlt, faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { getUrlCalendar } from "../../utils/urls";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { DATE_FORMAT_ISO, ID_EMPTY } from "../../utils/constants";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { addStringToArr, removeStringFromArr } from "../../utils/collections";
import CalendarSelectRowContent from "./CalendarSelectRowContent";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { useGetReservationsMachinesQuery } from "../../apollo/queries/reservations.generated";

interface Props extends WithStyles<typeof styles> {
  catalog: CatalogType;
}

function CalendarSelectRow({ classes, catalog }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [catalogCategoryUpperIdsOpen, setCatalogCategoryUpperIdsOpen] =
    useState<string[]>([]);

  const { loading, error, data } = useGetReservationsMachinesQuery({
    fetchPolicy: getQueryFetchPolicy("reservations"),
    variables: {
      dateFrom: format(new Date(), DATE_FORMAT_ISO),
      dateTo: format(new Date(), DATE_FORMAT_ISO),
      includeNotReturned: true,
    },
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickCalendarCategoryUpper = (
    event:
      | React.MouseEvent<HTMLAnchorElement>
      | React.MouseEvent<HTMLButtonElement>,
    catalogCategoryUpper: CatalogCategoryUpperType
  ) => {
    event.stopPropagation();

    const catalog_id = catalog.id;
    const catalog_category_upper_id = catalogCategoryUpper.id;
    const catalog_category_id = ID_EMPTY;
    const catalog_row_id = ID_EMPTY;

    history.push(
      getUrlCalendar(
        catalog_id,
        catalog_category_upper_id,
        catalog_category_id,
        catalog_row_id
      )
    );
  };

  return (
    <div id="conCalendarSelectRow">
      {catalog.catalogcategoryupperSet.map((catalogCategoryUpper) => (
        <Accordion
          key={catalogCategoryUpper.id}
          onChange={(event, expanded) => {
            if (expanded) {
              setCatalogCategoryUpperIdsOpen(
                addStringToArr(
                  catalogCategoryUpperIdsOpen,
                  catalogCategoryUpper.id
                )
              );
            } else {
              setCatalogCategoryUpperIdsOpen(
                removeStringFromArr(
                  catalogCategoryUpperIdsOpen,
                  catalogCategoryUpper.id
                )
              );
            }
          }}
        >
          <AccordionSummary
            expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Button
              className="me-3"
              onClick={(event) =>
                onClickCalendarCategoryUpper(event, catalogCategoryUpper)
              }
            >
              <Avatar className={classes.avatarUpper}>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </Avatar>
            </Button>
            <Typography className={classes.heading}>
              {catalogCategoryUpper.name}
            </Typography>
            {catalogCategoryUpperIdsOpen.includes(catalogCategoryUpper.id) && (
              <Typography
                className={`${classes.headerEfficiency} d-none d-sm-inline`}
              >
                {t("efficiency_today")}
              </Typography>
            )}
          </AccordionSummary>
          <AccordionDetails className="ps-0 ps-sm-5">
            {catalogCategoryUpperIdsOpen.includes(catalogCategoryUpper.id) && (
              <CalendarSelectRowContent
                catalog_id={catalog.id}
                catalogCategoryUpper={catalogCategoryUpper}
                reservations={data.reservations ? data.reservations : []}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    avatarUpper: { backgroundColor: theme.palette.primary.main },
    heading: {
      flex: 1,
      fontSize: "1.5rem",
      paddingTop: "0.4rem",
    },
    headerEfficiency: {
      paddingTop: "1rem",
      width: "calc(33% - 20px)",
    },
  });

export default withStyles(styles)(CalendarSelectRow);
