import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  CatalogExtraCategoryUnitPriceType,
  Mutation,
  MutationDeleteCatalogExtraCategoryUnitPriceArgs,
} from "../../../entity/types";
import { Button, Table } from "react-bootstrap";
import { formatNumber } from "../../../utils/formatting";
import { PermissionsContext, SettingsContext } from "../../../Root";
import CatalogExtraUnitPriceRow from "./CatalogExtraUnitPriceRow";
import { checkPermission } from "../../../utils/permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/pro-light-svg-icons";
import DialogCatalogExtraCategoryUnitPrice from "../DialogCatalogExtraCategoryUnitPrice";
import { useMutation } from "@apollo/client";
import { DELETE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION } from "../../../apollo/mutations/catalogs_extra";
import { GET_CATALOG_EXTRA_UNIT_PRICE_QUERY } from "../../../apollo/queries/catalogs_extra";
import { handleError } from "../../../entity/ErrorHandler";
import { dialogConfirm } from "../../../utils/dialogs";
import ButtonLoad from "../../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  catalogExtraCategoryUnitPrice: CatalogExtraCategoryUnitPriceType;
}

function CatalogExtraUnitPriceTable({
  classes,
  catalogExtraCategoryUnitPrice,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);

  const [deleteCatalogExtraCategory, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteCatalogExtraCategoryUnitPriceArgs
  >(DELETE_CATALOG_EXTRA_CATEGORY_UNIT_PRICE_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_EXTRA_UNIT_PRICE_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_extra.change_catalogextracategoryunitprice",
  ]);
  const hasPermissionDelete = checkPermission(myPermissions, [
    "catalogs_extra.delete_catalogextracategoryunitprice",
  ]);

  const onClickEdit = () => {
    setOpenDialogEdit(true);
  };
  const onClickDelete = () => {
    dialogConfirm(t, t("confirm_delete"), () => {
      deleteCatalogExtraCategory({
        variables: {
          catalogExtraCategoryUnitPriceId: catalogExtraCategoryUnitPrice.id,
        },
      });
    });
  };

  const showCols =
    catalogExtraCategoryUnitPrice.catalogextrarowunitpriceSet.length > 0;

  return (
    <Table className={`${classes.table} ${showCols ? "" : "w-auto"}`}>
      <thead>
        <tr>
          <th colSpan={3} className={classes.thTitle}>
            {catalogExtraCategoryUnitPrice.name}
            {catalogExtraCategoryUnitPrice.printSeparately && (
              <small className="text-muted ms-2">
                {t("catalog_extra_print_separately")}
              </small>
            )}
          </th>
          <th colSpan={6}></th>
          <th>
            {hasPermissionEdit && (
              <Button
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickEdit}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
            {hasPermissionDelete && (
              <ButtonLoad
                loading={loadingDelete}
                variant="light"
                size="sm"
                className={classes.btn}
                onClick={onClickDelete}
              >
                <FontAwesomeIcon icon={faTrash} />
              </ButtonLoad>
            )}
            {hasPermissionEdit && (
              <DialogCatalogExtraCategoryUnitPrice
                open={openDialogEdit}
                setOpen={setOpenDialogEdit}
                catalogExtraCategoryUnitPriceOrig={
                  catalogExtraCategoryUnitPrice
                }
              />
            )}
          </th>
        </tr>
        {showCols && (
          <tr>
            <th>{t("product_number")}</th>
            <th title={t("costcenter")}>{t("costcenter_short")}</th>
            <th title={t("costtype")}>{t("costtype_short")}</th>
            <th>{t("title")}</th>
            <th>{t("unit")}</th>
            <th>{t("unit_price_vat0")}</th>

            <th>
              {t("unit_price_vat1", {
                vat: formatNumber(settings.vat),
              })}
            </th>
            <th>{t("min_charge")}</th>
            <th>{t("allow_discount")}</th>
            <th>{t("require_answer")}</th>
            <th>{t("show_print")}</th>
            <th></th>
          </tr>
        )}
      </thead>
      <tbody>
        {catalogExtraCategoryUnitPrice.catalogextrarowunitpriceSet.map(
          (catalogExtraRowUnitPrice) => {
            return (
              <CatalogExtraUnitPriceRow
                key={catalogExtraRowUnitPrice.id}
                catalogExtraRowUnitPrice={catalogExtraRowUnitPrice}
              />
            );
          }
        )}
      </tbody>
    </Table>
  );
}

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    btn: {
      marginRight: spacing(0.5),
    },
    table: {
      "& thead th, th, td": {
        border: "none",
      },
    },
    thTitle: {
      color: palette.primary.main,
    },
  });

export default withStyles(styles)(CatalogExtraUnitPriceTable);
