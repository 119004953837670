import React, { useContext } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { QueryInspectionReturnAnswersForReservationArgs } from "../../entity/types";
import { useQuery } from "@apollo/client";
import {
  GET_INSPECTION_RETURN_ANSWERS_FOR_RESERVATION_QUERY,
  QueryResultInspectionReturnAnswersForReservation,
} from "../../apollo/queries/inspections";
import { useTranslation } from "react-i18next";
import LoadingSimple from "../Shared/LoadingSimple";
import Error from "../Shared/Error";
import InspectionReturnAnswer from "./InspectionReturnAnswer";
import InspectionReturnAnswerReport from "./InspectionReturnAnswerReport";
import { WithStyles } from "@material-ui/core/styles";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  editable: boolean;
}

function DialogContentInspectionReturnAnswers({
  classes,
  reservation,
  editable,
}: Props) {
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<
    QueryResultInspectionReturnAnswersForReservation,
    QueryInspectionReturnAnswersForReservationArgs
  >(GET_INSPECTION_RETURN_ANSWERS_FOR_RESERVATION_QUERY, {
    fetchPolicy: getQueryFetchPolicy("inspectionReturnAnswersForReservation"),
    variables: {
      reservationId: reservation.id,
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionChangeInspectionReturnAnswer = checkPermission(
    myPermissions,
    ["inspections.change_inspectionreturnanswer"]
  );

  const editableAndPerm = editable && hasPermissionChangeInspectionReturnAnswer;

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <div className={loading ? "loading" : ""}>
      {data.inspectionReturnAnswersForReservation?.length === 0 && (
        <p className="text-muted">{t("no_inspections_for_machine")}</p>
      )}
      {data.inspectionReturnAnswersForReservation?.map(
        (inspectionReturnAnswer) => {
          if (editableAndPerm) {
            return (
              <InspectionReturnAnswer
                key={inspectionReturnAnswer.id}
                inspectionReturnAnswer={inspectionReturnAnswer}
                inspectionReturnQuestion={
                  inspectionReturnAnswer.inspectionReturnToMachine
                    .inspectionReturnQuestion
                }
              />
            );
          }

          return (
            <InspectionReturnAnswerReport
              key={inspectionReturnAnswer.id}
              inspectionReturnAnswer={inspectionReturnAnswer}
              inspectionReturnQuestion={
                inspectionReturnAnswer.inspectionReturnToMachine
                  .inspectionReturnQuestion
              }
            />
          );
        }
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogContentInspectionReturnAnswers);
