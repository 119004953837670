import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import {
  createStyles,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Theme,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CatalogCategoryType,
  CatalogCategoryUpperType,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { ID_EMPTY } from "../../utils/constants";
import SelectRequired from "../Shared/SelectRequired";

interface Props extends WithStyles<typeof styles> {
  catalogCategory: CatalogCategoryType;
  setCatalogCategory: React.Dispatch<React.SetStateAction<CatalogCategoryType>>;
  catalogCategoriesUpper: CatalogCategoryUpperType[];
}

function DialogContentCatalogCategory({
  classes,
  catalogCategory,
  setCatalogCategory,
  catalogCategoriesUpper,
}: Props) {
  const { t } = useTranslation();

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "catalogs.add_catalogcategory",
  ]);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs.change_catalogcategory",
  ]);
  const disabled =
    catalogCategory.id === ID_EMPTY ? !hasPermissionAdd : !hasPermissionEdit;

  return (
    <DialogContent>
      <FormControl fullWidth>
        <InputLabel id="lblCustomerOwner">
          {t("catalog_category_upper")}
        </InputLabel>
        <SelectRequired
          autoWidth
          labelId="lblCustomerOwner"
          value={
            catalogCategory.catalogCategoryUpper
              ? catalogCategory.catalogCategoryUpper.id
              : ID_EMPTY
          }
          disabled={disabled}
          onChange={(event) => {
            setCatalogCategory({
              ...catalogCategory,
              catalogCategoryUpper: {
                ...catalogCategory.catalogCategoryUpper,
                id: String(event.target.value),
              },
            });
          }}
          className={classes.textField}
        >
          <MenuItem value={ID_EMPTY}>{t("not_selected")}</MenuItem>
          {catalogCategoriesUpper.map(
            (catalogCategoryUpper: CatalogCategoryUpperType) => (
              <MenuItem
                value={catalogCategoryUpper.id}
                key={catalogCategoryUpper.id}
              >
                {catalogCategoryUpper.name}
              </MenuItem>
            )
          )}
        </SelectRequired>
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtCatalogName"
          label={t("name")}
          className={classes.textField}
          onChange={(event) => {
            setCatalogCategory({
              ...catalogCategory,
              name: event.target.value,
            });
          }}
          value={catalogCategory.name}
          disabled={disabled}
          inputProps={{ maxLength: 100 }}
          autoFocus={true}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          id="txtCatalogInformation"
          label={t("information")}
          className={classes.textField}
          multiline={true}
          onChange={(event) => {
            setCatalogCategory({
              ...catalogCategory,
              information: event.target.value,
            });
          }}
          value={catalogCategory.information}
          disabled={disabled}
        />
      </FormControl>
    </DialogContent>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  });

export default withStyles(styles)(DialogContentCatalogCategory);
