import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { getReservationProductName } from "../../utils/reservations/reservation";
import WorkQueueCardIconsRenting from "./WorkQueueCardIconsRenting";
import { useTranslation } from "react-i18next";
import { DriveListType } from "./WorkQueueCardDrive";
import ButtonOrderDialog from "../Shared/ButtonOrderDialog";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsWorkQueuesType;
  type: DriveListType;
}

function WorkQueueCardRentingTiny({ classes, reservation, type }: Props) {
  const { t } = useTranslation();

  return (
    <div className="mt-3">
      <span className="me-2">
        <span className="text-muted">
          <ButtonOrderDialog
            orderId={reservation.order.id}
            reservationId={reservation.id}
          />
        </span>{" "}
        {getReservationProductName(t, reservation, true)}
      </span>
      <WorkQueueCardIconsRenting reservation={reservation} type={type} />
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueueCardRentingTiny);
