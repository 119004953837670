import { StyleRules } from "@material-ui/core";

export const CALENDAR_CUSTOMER_PRICE_ROW: StyleRules = {
  th: {
    width: "15%",
  },
  td1: {
    width: "15%",
  },
  td2: {
    width: "30%",
  },
  tdSpacing: {},
};
