import { gql } from "@apollo/client";

export const CREATE_INSPECTION_RETURN_CATEGORY_MUTATION = gql`
  mutation createInspectionReturnCategory($name: String!) {
    createInspectionReturnCategory(name: $name) {
      inspectionReturnCategory {
        id
      }
    }
  }
`;

export const DELETE_INSPECTION_RETURN_CATEGORY_MUTATION = gql`
  mutation deleteInspectionReturnCategory($inspectionReturnCategoryId: ID!) {
    deleteInspectionReturnCategory(
      inspectionReturnCategoryId: $inspectionReturnCategoryId
    ) {
      inspectionReturnCategoryId
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_CATEGORY_MUTATION = gql`
  mutation updateInspectionReturnCategory(
    $inspectionReturnCategoryId: ID!
    $name: String!
  ) {
    updateInspectionReturnCategory(
      inspectionReturnCategoryId: $inspectionReturnCategoryId
      name: $name
    ) {
      inspectionReturnCategory {
        id
        name
      }
    }
  }
`;

export const CREATE_INSPECTION_RETURN_QUESTION_MUTATION = gql`
  mutation createInspectionReturnQuestion(
    $inspectionReturnCategoryId: ID!
    $question: String!
    $answerType: String!
  ) {
    createInspectionReturnQuestion(
      inspectionReturnCategoryId: $inspectionReturnCategoryId
      question: $question
      answerType: $answerType
    ) {
      inspectionReturnQuestion {
        id
      }
    }
  }
`;

export const DELETE_INSPECTION_RETURN_QUESTION_MUTATION = gql`
  mutation deleteInspectionReturnQuestion($inspectionReturnQuestionId: ID!) {
    deleteInspectionReturnQuestion(
      inspectionReturnQuestionId: $inspectionReturnQuestionId
    ) {
      inspectionReturnQuestionId
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_QUESTION_MUTATION = gql`
  mutation updateInspectionReturnQuestion(
    $inspectionReturnQuestionId: ID!
    $inspectionReturnCategoryId: ID!
    $question: String!
    $answerType: String!
  ) {
    updateInspectionReturnQuestion(
      inspectionReturnQuestionId: $inspectionReturnQuestionId
      inspectionReturnCategoryId: $inspectionReturnCategoryId
      question: $question
      answerType: $answerType
    ) {
      inspectionReturnQuestion {
        id
        inspectionReturnCategory {
          id
        }
        question
        answerType
      }
    }
  }
`;

export const CREATE_INSPECTION_RETURN_TAG_MUTATION = gql`
  mutation createInspectionReturnTag(
    $inspectionReturnQuestionId: ID!
    $name: String!
  ) {
    createInspectionReturnTag(
      inspectionReturnQuestionId: $inspectionReturnQuestionId
      name: $name
    ) {
      inspectionReturnTag {
        id
      }
    }
  }
`;

export const REMOVE_INSPECTION_RETURN_TAG_FROM_QUESTION_MUTATION = gql`
  mutation removeInspectionReturnTagFromQuestion(
    $inspectionReturnTagId: ID!
    $inspectionReturnQuestionId: ID!
  ) {
    removeInspectionReturnTagFromQuestion(
      inspectionReturnTagId: $inspectionReturnTagId
      inspectionReturnQuestionId: $inspectionReturnQuestionId
    ) {
      inspectionReturnTagId
    }
  }
`;

export const ADD_INSPECTION_RETURN_TAG_TO_QUESTION_MUTATION = gql`
  mutation addInspectionReturnTagToQuestion(
    $inspectionReturnTagId: ID!
    $inspectionReturnQuestionId: ID!
  ) {
    addInspectionReturnTagToQuestion(
      inspectionReturnTagId: $inspectionReturnTagId
      inspectionReturnQuestionId: $inspectionReturnQuestionId
    ) {
      inspectionReturnTagId
    }
  }
`;

export const DELETE_INSPECTION_RETURN_TAG_MUTATION = gql`
  mutation deleteInspectionReturnTag($inspectionReturnTagId: ID!) {
    deleteInspectionReturnTag(inspectionReturnTagId: $inspectionReturnTagId) {
      inspectionReturnTagId
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_TAG_MUTATION = gql`
  mutation updateInspectionReturnTag(
    $inspectionReturnTagId: ID!
    $name: String!
  ) {
    updateInspectionReturnTag(
      inspectionReturnTagId: $inspectionReturnTagId
      name: $name
    ) {
      inspectionReturnTag {
        id
        name
      }
    }
  }
`;

export const ADD_INSPECTION_RETURN_TAG_TO_MACHINE_MUTATION = gql`
  mutation addInspectionReturnTagToMachine(
    $machineId: ID!
    $inspectionReturnTagIds: [ID]!
  ) {
    addInspectionReturnTagToMachine(
      machineId: $machineId
      inspectionReturnTagIds: $inspectionReturnTagIds
    ) {
      machineId
    }
  }
`;

export const CREATE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION = gql`
  mutation createInspectionReturnQuestionOption(
    $inspectionReturnQuestionId: ID!
    $title: String!
  ) {
    createInspectionReturnQuestionOption(
      inspectionReturnQuestionId: $inspectionReturnQuestionId
      title: $title
    ) {
      inspectionReturnQuestionOption {
        id
      }
    }
  }
`;

export const DELETE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION = gql`
  mutation deleteInspectionReturnQuestionOption(
    $inspectionReturnQuestionOptionId: ID!
  ) {
    deleteInspectionReturnQuestionOption(
      inspectionReturnQuestionOptionId: $inspectionReturnQuestionOptionId
    ) {
      inspectionReturnQuestionOptionId
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_QUESTION_OPTION_MUTATION = gql`
  mutation updateInspectionReturnQuestionOption(
    $inspectionReturnQuestionOptionId: ID!
    $title: String!
  ) {
    updateInspectionReturnQuestionOption(
      inspectionReturnQuestionOptionId: $inspectionReturnQuestionOptionId
      title: $title
    ) {
      inspectionReturnQuestionOption {
        id
        title
      }
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_ANSWER_MUTATION = gql`
  mutation updateInspectionReturnAnswer(
    $inspectionReturnAnswerId: ID!
    $inspectionReturnQuestionOptionId: ID
    $answer: String!
    $checked: Boolean
  ) {
    updateInspectionReturnAnswer(
      inspectionReturnAnswerId: $inspectionReturnAnswerId
      inspectionReturnQuestionOptionId: $inspectionReturnQuestionOptionId
      answer: $answer
      checked: $checked
    ) {
      inspectionReturnAnswer {
        id
        inspectionReturnQuestionOption {
          id
        }
        answer
        checked
      }
    }
  }
`;

export const COPY_INSPECTION_RETURN_TAGS_MUTATION = gql`
  mutation copyInspectionReturnTags($machineIdFrom: ID!, $machineIdTo: ID!) {
    copyInspectionReturnTags(
      machineIdFrom: $machineIdFrom
      machineIdTo: $machineIdTo
    ) {
      countAnswersAdded
    }
  }
`;

export const CREATE_INSPECTION_RETURN_TO_MACHINE_MUTATION = gql`
  mutation createInspectionReturnToMachine(
    $inspectionReturnQuestionIds: [ID]!
    $machineId: ID!
  ) {
    createInspectionReturnToMachine(
      inspectionReturnQuestionIds: $inspectionReturnQuestionIds
      machineId: $machineId
    ) {
      success
    }
  }
`;

export const DELETE_INSPECTION_RETURN_TO_MACHINE_MUTATION = gql`
  mutation deleteInspectionReturnToMachine($inspectionReturnToMachineId: ID!) {
    deleteInspectionReturnToMachine(
      inspectionReturnToMachineId: $inspectionReturnToMachineId
    ) {
      inspectionReturnToMachineId
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_CATEGORY_ORDER_MUTATION = gql`
  mutation updateInspectionReturnCategoryOrder(
    $idOrderInput: [IdOrderInputType]
  ) {
    updateInspectionReturnCategoryOrder(idOrderInput: $idOrderInput) {
      inspectionReturnCategories {
        id
        order
      }
    }
  }
`;

export const UPDATE_INSPECTION_RETURN_QUESTION_ORDER_MUTATION = gql`
  mutation updateInspectionReturnQuestionOrder(
    $idOrderInput: [IdOrderInputType]
  ) {
    updateInspectionReturnQuestionOrder(idOrderInput: $idOrderInput) {
      inspectionReturnCategories {
        id
        order
      }
    }
  }
`;

export const CREATE_INSPECTION_GIVE_CATEGORY_MUTATION = gql`
  mutation createInspectionGiveCategory($name: String!) {
    createInspectionGiveCategory(name: $name) {
      inspectionGiveCategory {
        id
      }
    }
  }
`;

export const DELETE_INSPECTION_GIVE_CATEGORY_MUTATION = gql`
  mutation deleteInspectionGiveCategory($inspectionGiveCategoryId: ID!) {
    deleteInspectionGiveCategory(
      inspectionGiveCategoryId: $inspectionGiveCategoryId
    ) {
      inspectionGiveCategoryId
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_CATEGORY_MUTATION = gql`
  mutation updateInspectionGiveCategory(
    $inspectionGiveCategoryId: ID!
    $name: String!
  ) {
    updateInspectionGiveCategory(
      inspectionGiveCategoryId: $inspectionGiveCategoryId
      name: $name
    ) {
      inspectionGiveCategory {
        id
        name
      }
    }
  }
`;

export const CREATE_INSPECTION_GIVE_QUESTION_MUTATION = gql`
  mutation createInspectionGiveQuestion(
    $inspectionGiveCategoryId: ID!
    $question: String!
    $answerType: String!
  ) {
    createInspectionGiveQuestion(
      inspectionGiveCategoryId: $inspectionGiveCategoryId
      question: $question
      answerType: $answerType
    ) {
      inspectionGiveQuestion {
        id
      }
    }
  }
`;

export const DELETE_INSPECTION_GIVE_QUESTION_MUTATION = gql`
  mutation deleteInspectionGiveQuestion($inspectionGiveQuestionId: ID!) {
    deleteInspectionGiveQuestion(
      inspectionGiveQuestionId: $inspectionGiveQuestionId
    ) {
      inspectionGiveQuestionId
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_QUESTION_MUTATION = gql`
  mutation updateInspectionGiveQuestion(
    $inspectionGiveQuestionId: ID!
    $inspectionGiveCategoryId: ID!
    $question: String!
    $answerType: String!
  ) {
    updateInspectionGiveQuestion(
      inspectionGiveQuestionId: $inspectionGiveQuestionId
      inspectionGiveCategoryId: $inspectionGiveCategoryId
      question: $question
      answerType: $answerType
    ) {
      inspectionGiveQuestion {
        id
        inspectionGiveCategory {
          id
        }
        question
        answerType
      }
    }
  }
`;

export const CREATE_INSPECTION_GIVE_TAG_MUTATION = gql`
  mutation createInspectionGiveTag(
    $inspectionGiveQuestionId: ID!
    $name: String!
  ) {
    createInspectionGiveTag(
      inspectionGiveQuestionId: $inspectionGiveQuestionId
      name: $name
    ) {
      inspectionGiveTag {
        id
      }
    }
  }
`;

export const REMOVE_INSPECTION_GIVE_TAG_FROM_QUESTION_MUTATION = gql`
  mutation removeInspectionGiveTagFromQuestion(
    $inspectionGiveTagId: ID!
    $inspectionGiveQuestionId: ID!
  ) {
    removeInspectionGiveTagFromQuestion(
      inspectionGiveTagId: $inspectionGiveTagId
      inspectionGiveQuestionId: $inspectionGiveQuestionId
    ) {
      inspectionGiveTagId
    }
  }
`;

export const ADD_INSPECTION_GIVE_TAG_TO_QUESTION_MUTATION = gql`
  mutation addInspectionGiveTagToQuestion(
    $inspectionGiveTagId: ID!
    $inspectionGiveQuestionId: ID!
  ) {
    addInspectionGiveTagToQuestion(
      inspectionGiveTagId: $inspectionGiveTagId
      inspectionGiveQuestionId: $inspectionGiveQuestionId
    ) {
      inspectionGiveTagId
    }
  }
`;

export const DELETE_INSPECTION_GIVE_TAG_MUTATION = gql`
  mutation deleteInspectionGiveTag($inspectionGiveTagId: ID!) {
    deleteInspectionGiveTag(inspectionGiveTagId: $inspectionGiveTagId) {
      inspectionGiveTagId
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_TAG_MUTATION = gql`
  mutation updateInspectionGiveTag($inspectionGiveTagId: ID!, $name: String!) {
    updateInspectionGiveTag(
      inspectionGiveTagId: $inspectionGiveTagId
      name: $name
    ) {
      inspectionGiveTag {
        id
        name
      }
    }
  }
`;

export const ADD_INSPECTION_GIVE_TAG_TO_MACHINE_MUTATION = gql`
  mutation addInspectionGiveTagToMachine(
    $machineId: ID!
    $inspectionGiveTagIds: [ID]!
  ) {
    addInspectionGiveTagToMachine(
      machineId: $machineId
      inspectionGiveTagIds: $inspectionGiveTagIds
    ) {
      machineId
    }
  }
`;

export const CREATE_INSPECTION_GIVE_QUESTION_OPTION_MUTATION = gql`
  mutation createInspectionGiveQuestionOption(
    $inspectionGiveQuestionId: ID!
    $title: String!
  ) {
    createInspectionGiveQuestionOption(
      inspectionGiveQuestionId: $inspectionGiveQuestionId
      title: $title
    ) {
      inspectionGiveQuestionOption {
        id
      }
    }
  }
`;

export const DELETE_INSPECTION_GIVE_QUESTION_OPTION_MUTATION = gql`
  mutation deleteInspectionGiveQuestionOption(
    $inspectionGiveQuestionOptionId: ID!
  ) {
    deleteInspectionGiveQuestionOption(
      inspectionGiveQuestionOptionId: $inspectionGiveQuestionOptionId
    ) {
      inspectionGiveQuestionOptionId
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_QUESTION_OPTION_MUTATION = gql`
  mutation updateInspectionGiveQuestionOption(
    $inspectionGiveQuestionOptionId: ID!
    $title: String!
  ) {
    updateInspectionGiveQuestionOption(
      inspectionGiveQuestionOptionId: $inspectionGiveQuestionOptionId
      title: $title
    ) {
      inspectionGiveQuestionOption {
        id
        title
      }
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_ANSWER_MUTATION = gql`
  mutation updateInspectionGiveAnswer(
    $inspectionGiveAnswerId: ID!
    $inspectionGiveQuestionOptionId: ID
    $answer: String!
    $checked: Boolean
  ) {
    updateInspectionGiveAnswer(
      inspectionGiveAnswerId: $inspectionGiveAnswerId
      inspectionGiveQuestionOptionId: $inspectionGiveQuestionOptionId
      answer: $answer
      checked: $checked
    ) {
      inspectionGiveAnswer {
        id
        inspectionGiveQuestionOption {
          id
        }
        answer
        checked
      }
    }
  }
`;

export const COPY_INSPECTION_GIVE_TAGS_MUTATION = gql`
  mutation copyInspectionGiveTags($machineIdFrom: ID!, $machineIdTo: ID!) {
    copyInspectionGiveTags(
      machineIdFrom: $machineIdFrom
      machineIdTo: $machineIdTo
    ) {
      countAnswersAdded
    }
  }
`;

export const CREATE_INSPECTION_GIVE_TO_MACHINE_MUTATION = gql`
  mutation createInspectionGiveToMachine(
    $inspectionGiveQuestionIds: [ID]!
    $machineId: ID!
  ) {
    createInspectionGiveToMachine(
      inspectionGiveQuestionIds: $inspectionGiveQuestionIds
      machineId: $machineId
    ) {
      success
    }
  }
`;

export const DELETE_INSPECTION_GIVE_TO_MACHINE_MUTATION = gql`
  mutation deleteInspectionGiveToMachine($inspectionGiveToMachineId: ID!) {
    deleteInspectionGiveToMachine(
      inspectionGiveToMachineId: $inspectionGiveToMachineId
    ) {
      inspectionGiveToMachineId
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_CATEGORY_ORDER_MUTATION = gql`
  mutation updateInspectionGiveCategoryOrder(
    $idOrderInput: [IdOrderInputType]
  ) {
    updateInspectionGiveCategoryOrder(idOrderInput: $idOrderInput) {
      inspectionGiveCategories {
        id
        order
      }
    }
  }
`;

export const UPDATE_INSPECTION_GIVE_QUESTION_ORDER_MUTATION = gql`
  mutation updateInspectionGiveQuestionOrder(
    $idOrderInput: [IdOrderInputType]
  ) {
    updateInspectionGiveQuestionOrder(idOrderInput: $idOrderInput) {
      inspectionGiveCategories {
        id
        order
      }
    }
  }
`;
