import React, { useContext, useEffect, useMemo, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Checkbox, createStyles, Theme } from "@material-ui/core";
import {
  CatalogRowType,
  CustomerType,
  DiscountListType,
  DiscountRowType,
  Mutation,
  MutationCreateDiscountRowArgs,
  MutationUpdateDiscountRowArgs,
} from "../../../entity/types";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import { PermissionsContext, SettingsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { useMutation } from "@apollo/client";
import {
  CREATE_DISCOUNT_ROW_MUTATION,
  UPDATE_DISCOUNT_ROW_MUTATION,
} from "../../../apollo/mutations/discounts";
import { handleError } from "../../../entity/ErrorHandler";
import {
  addStringToArr,
  removeStringFromArr,
} from "../../../utils/collections";
import {
  getPercentPrefixCalc,
  getPercentPrefixDB,
  getPercentPrefixUserInput,
} from "../../../utils/discounts/prefixes";
import { ID_EMPTY, ROOT_QUERY } from "../../../utils/constants";
import {
  getPercentDifference,
  getPercentFactorDiscount,
} from "../../../utils/calc";
import TextFieldFocus from "../../Shared/TextFieldFocus";
import { getQueryKey } from "../../../utils/cache";
import { useTranslation } from "react-i18next";

const getPercentFactorDiscountWithGlobalDiscount = (
  value: string | null,
  discountGlobalCatalogRow: number
) => {
  const useGlobal = value === null || value === "";
  return getPercentFactorDiscount(
    useGlobal ? formatNumber(discountGlobalCatalogRow, 2) : value
  );
};

const getDefaultCatalogRowEdited = (
  catalogRow: CatalogRowType,
  discountRow: DiscountRowType,
  discountGlobalCatalogRow: number
) => {
  const percentFactorDefault = getPercentFactorDiscountWithGlobalDiscount(
    discountRow.percent,
    discountGlobalCatalogRow
  );

  return {
    ...catalogRow,
    priceDayCompany: formatNumber(
      Number(catalogRow.priceDayCompany) * percentFactorDefault,
      2
    ),
    priceMonthCompany: formatNumber(
      Number(catalogRow.priceMonthCompany) * percentFactorDefault,
      2
    ),
    priceDayPrivate: formatNumber(
      Number(catalogRow.priceDayPrivate) * percentFactorDefault,
      2
    ),
    priceMonthPrivate: formatNumber(
      Number(catalogRow.priceMonthPrivate) * percentFactorDefault,
      2
    ),
  };
};

const getDefaultDiscountRowEdited = (discountRow: DiscountRowType) => ({
  ...discountRow,
  percent:
    discountRow.percent === null
      ? ""
      : getPercentPrefixDB(discountRow.percent) +
        formatNumber(discountRow.percent, 2),
});

export const isDiscountRowDisabled = (
  discountList: DiscountListType,
  hasPermissionDiscountRowEdit: boolean
) => {
  return Boolean(
    !hasPermissionDiscountRowEdit ||
      discountList.locked ||
      discountList.id === ID_EMPTY
  );
};

interface Props extends WithStyles<typeof styles> {
  catalogRow: CatalogRowType;
  discountList: DiscountListType;
  discountRow: DiscountRowType;
  selectedRows: string[];
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>;
  timeClickedSet: number;
  customer: CustomerType;
  catalogRowIdsPrinted: string[];
  setCatalogRowIdsPrinted: React.Dispatch<React.SetStateAction<string[]>>;
}

const DiscountRow = ({
  classes,
  catalogRow,
  discountList,
  discountRow,
  selectedRows,
  setSelectedRows,
  timeClickedSet,
  customer,
  catalogRowIdsPrinted,
  setCatalogRowIdsPrinted,
}: Props) => {
  const { t } = useTranslation();

  const settings = useContext(SettingsContext);

  const discountGlobalCatalogRow =
    customer.customerParent && customer.useParentPricing
      ? customer.customerParent.discountGlobalCatalogRow
      : customer.discountGlobalCatalogRow;

  const percentFactorDefault = getPercentFactorDiscountWithGlobalDiscount(
    discountRow.percent,
    discountGlobalCatalogRow
  );

  const [discountRowEdited, setDiscountRowEdited] = useState<DiscountRowType>(
    getDefaultDiscountRowEdited(discountRow)
  );
  const [catalogRowEdited, setCatalogRowEdited] = useState(
    getDefaultCatalogRowEdited(
      catalogRow,
      discountRow,
      discountGlobalCatalogRow
    )
  );

  const [priceDayCompanyVat, setPriceDayCompanyVat] = useState(
    formatNumber(
      Number(catalogRow.priceDayCompany) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );
  const [priceMonthCompanyVat, setPriceMonthCompanyVat] = useState(
    formatNumber(
      Number(catalogRow.priceMonthCompany) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );

  const [priceDayPrivateVat, setPriceDayPrivateVat] = useState(
    formatNumber(
      Number(catalogRow.priceDayPrivate) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );
  const [priceMonthPrivateVat, setPriceMonthPrivateVat] = useState(
    formatNumber(
      Number(catalogRow.priceMonthPrivate) *
        settings.vatFactor *
        percentFactorDefault,
      2
    )
  );

  useEffect(() => {
    setDiscountRowEdited(getDefaultDiscountRowEdited(discountRow));
    setCatalogRowEdited(
      getDefaultCatalogRowEdited(
        catalogRow,
        discountRow,
        discountGlobalCatalogRow
      )
    );
    // eslint-disable-next-line
  }, [timeClickedSet]);

  const [createDiscountRow, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateDiscountRowArgs
  >(CREATE_DISCOUNT_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("discountList"),
      });
    },
  });

  const [updateDiscountRow, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateDiscountRowArgs
  >(UPDATE_DISCOUNT_ROW_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "discounts.change_discountrow",
  ]);

  const handleDiscountRowUpdate = (values: any) => {
    const base: { [key: string]: any } = {
      percent: parseNumber(discountRowEdited.percent),
      showPrint: discountRowEdited.showPrint,
      discountListId: discountList.id,
      catalogRowId: catalogRow.id,
    };

    let variables = { ...base, ...values };
    if (discountRowEdited.id !== ID_EMPTY) {
      variables.discountRowId = discountRowEdited.id;

      updateDiscountRow({
        variables: variables,
      });
    } else {
      createDiscountRow({
        variables: variables,
      });
    }
  };

  const setPercentUserInput = (value: string) => {
    let prefix = getPercentPrefixUserInput(value);
    setDiscountRowEdited({
      ...discountRowEdited,
      percent: prefix + formatNumber(parseNumber(value), 2),
    });
  };
  const setPercentCalc = (
    catalogRowNew: CatalogRowType,
    valueOld: number,
    valueNew: number,
    update: boolean,
    changedField: string
  ) => {
    let percentNew = getPercentDifference(valueOld, valueNew);
    let percentFormatted = formatNumber(percentNew, 2);
    let prefix = getPercentPrefixDB(String(percentNew));
    handlePercentChange(catalogRowNew, prefix + percentFormatted, changedField);

    if (update) {
      handleDiscountRowUpdate({
        percent: percentNew,
      });
    }
  };

  const handlePercentChange = (
    catalogRowNew: CatalogRowType,
    percent: string,
    changedField = ""
  ) => {
    setDiscountRowEdited({ ...discountRowEdited, percent: percent });
    calculateNewPrices(catalogRowNew, percent, changedField);
  };

  const calculateNewPrices = (
    catalogRowBase: CatalogRowType,
    percent: string,
    changedField = ""
  ) => {
    const percentFactorNew = getPercentFactorDiscountWithGlobalDiscount(
      percent,
      discountGlobalCatalogRow
    );

    let catalogRowNew = catalogRowBase;

    if (changedField !== "priceDayCompany") {
      catalogRowNew.priceDayCompany = formatNumber(
        Number(catalogRow.priceDayCompany) * percentFactorNew,
        2
      );
    }
    if (changedField !== "priceDayCompanyVat") {
      setPriceDayCompanyVat(
        formatNumber(
          Number(catalogRow.priceDayCompany) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }
    if (changedField !== "priceMonthCompany") {
      catalogRowNew.priceMonthCompany = formatNumber(
        Number(catalogRow.priceMonthCompany) * percentFactorNew,
        2
      );
    }
    if (changedField !== "priceMonthCompanyVat") {
      setPriceMonthCompanyVat(
        formatNumber(
          Number(catalogRow.priceMonthCompany) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }

    if (changedField !== "priceDayPrivate") {
      catalogRowNew.priceDayPrivate = formatNumber(
        Number(catalogRow.priceDayPrivate) * percentFactorNew,
        2
      );
    }
    if (changedField !== "priceDayPrivateVat") {
      setPriceDayPrivateVat(
        formatNumber(
          Number(catalogRow.priceDayPrivate) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }
    if (changedField !== "priceMonthPrivate") {
      catalogRowNew.priceMonthPrivate = formatNumber(
        Number(catalogRow.priceMonthPrivate) * percentFactorNew,
        2
      );
    }
    if (changedField !== "priceMonthPrivateVat") {
      setPriceMonthPrivateVat(
        formatNumber(
          Number(catalogRow.priceMonthPrivate) *
            settings.vatFactor *
            percentFactorNew,
          2
        )
      );
    }

    setCatalogRowEdited(catalogRowNew);
  };

  const onChangePercent = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    handlePercentChange(catalogRowEdited, event.target.value);
  };

  const onChangePriceDayCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceDayCompany: event.target.value,
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceDayCompany),
      parseNumber(event.target.value, 2),
      false,
      "priceDayCompany"
    );
  };
  const onChangePriceDayCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayCompanyVat(event.target.value);
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceDayCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      false,
      "priceDayCompanyVat"
    );
  };
  const onChangePriceMonthCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceMonthCompany: event.target.value,
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceMonthCompany),
      parseNumber(event.target.value, 2),
      false,
      "priceMonthCompany"
    );
  };
  const onChangePriceMonthCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthCompanyVat(event.target.value);
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceMonthCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      false,
      "priceMonthCompanyVat"
    );
  };

  const onBlurPriceDayCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceDayCompany: formatNumber(parseNumber(event.target.value), 2),
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceDayCompany),
      parseNumber(event.target.value, 2),
      true,
      "priceDayCompany"
    );
  };
  const onBlurPriceDayCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayCompanyVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceDayCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      true,
      "priceDayCompanyVat"
    );
  };
  const onBlurPriceMonthCompany = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceMonthCompany: formatNumber(parseNumber(event.target.value), 2),
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceMonthCompany),
      parseNumber(event.target.value, 2),
      true,
      "priceMonthCompany"
    );
  };
  const onBlurPriceMonthCompanyVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthCompanyVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceMonthCompany) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      true,
      "priceMonthCompanyVat"
    );
  };

  const onChangePriceDayPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceDayPrivate: event.target.value,
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceDayPrivate),
      parseNumber(event.target.value, 2),
      false,
      "priceDayPrivate"
    );
  };
  const onChangePriceDayPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayPrivateVat(event.target.value);
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceDayPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      false,
      "priceDayPrivateVat"
    );
  };
  const onChangePriceMonthPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceMonthPrivate: event.target.value,
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceMonthPrivate),
      parseNumber(event.target.value, 2),
      false,
      "priceMonthPrivate"
    );
  };
  const onChangePriceMonthPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthPrivateVat(event.target.value);
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceMonthPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      false,
      "priceMonthPrivateVat"
    );
  };

  const onBlurPriceDayPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceDayPrivate: formatNumber(parseNumber(event.target.value), 2),
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceDayPrivate),
      parseNumber(event.target.value, 2),
      true,
      "priceDayPrivate"
    );
  };
  const onBlurPriceDayPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceDayPrivateVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceDayPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      true,
      "priceDayPrivateVat"
    );
  };
  const onBlurPriceMonthPrivate = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const catalogRowNew = {
      ...catalogRowEdited,
      priceMonthPrivate: formatNumber(parseNumber(event.target.value), 2),
    };
    setCatalogRowEdited(catalogRowNew);
    setPercentCalc(
      catalogRowNew,
      Number(catalogRow.priceMonthPrivate),
      parseNumber(event.target.value, 2),
      true,
      "priceMonthPrivate"
    );
  };
  const onBlurPriceMonthPrivateVat = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setPriceMonthPrivateVat(formatNumber(parseNumber(event.target.value), 2));
    setPercentCalc(
      catalogRowEdited,
      Number(catalogRow.priceMonthPrivate) * settings.vatFactor,
      parseNumber(event.target.value, 2),
      true,
      "priceMonthPrivateVat"
    );
  };

  const disabled = isDiscountRowDisabled(discountList, hasPermissionEdit);
  const useStateForPrint = disabled;

  return useMemo(() => {
    return (
      <tr className={loadingUpdate || loadingCreate ? "loading" : ""}>
        {hasPermissionEdit && !discountList.locked && (
          <td>
            <Checkbox
              checked={selectedRows.includes(discountRowEdited.id)}
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedRows(
                    addStringToArr(selectedRows, discountRowEdited.id)
                  );
                } else {
                  setSelectedRows(
                    removeStringFromArr(selectedRows, discountRowEdited.id)
                  );
                }
              }}
            />
          </td>
        )}
        <td className={classes.cellNoInput}>{catalogRow.name}</td>
        {discountList.isBusiness ? (
          <>
            <td className={classes.cellNoInput}>
              {formatNumber(catalogRow.priceDayCompany, 2)}
            </td>
            <td className={classes.cellNoInput}>
              {formatNumber(
                Number(catalogRow.priceDayCompany) * settings.vatFactor,
                2
              )}
            </td>
            <td className={classes.cellNoInput}>
              {formatNumber(catalogRow.priceMonthCompany, 2)}
            </td>
            <td className={classes.cellNoInput}>
              {formatNumber(
                Number(catalogRow.priceMonthCompany) * settings.vatFactor,
                2
              )}
            </td>
          </>
        ) : (
          <>
            <td className={classes.cellNoInput}>
              {formatNumber(catalogRow.priceDayPrivate, 2)}
            </td>
            <td className={classes.cellNoInput}>
              {formatNumber(
                Number(catalogRow.priceDayPrivate) * settings.vatFactor,
                2
              )}
            </td>
            <td className={classes.cellNoInput}>
              {formatNumber(catalogRow.priceMonthPrivate, 2)}
            </td>
            <td className={classes.cellNoInput}>
              {formatNumber(
                Number(catalogRow.priceMonthPrivate) * settings.vatFactor,
                2
              )}
            </td>
          </>
        )}
        <td className="cellPriceChange">
          <div className="d-flex flex-row">
            <div>
              <TextFieldFocus
                value={discountRowEdited.percent}
                disabled={disabled}
                onChange={onChangePercent}
                onBlur={() => {
                  const percent = discountRowEdited.percent;

                  if (percent === "") {
                    handleDiscountRowUpdate({
                      percent: null,
                    });
                  } else {
                    setPercentUserInput(percent);
                    handleDiscountRowUpdate({
                      percent:
                        getPercentPrefixCalc(percent) +
                        parseNumber(percent).toFixed(2),
                    });
                  }
                }}
                placeholder={t("discount_row_percent_placeholder", {
                  percent: formatNumber(discountGlobalCatalogRow, 2),
                })}
              />
            </div>
            <div>%</div>
          </div>
        </td>
        {discountList.isBusiness ? (
          <>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={catalogRowEdited.priceDayCompany}
                disabled={disabled}
                onChange={onChangePriceDayCompany}
                onBlur={onBlurPriceDayCompany}
              />
            </td>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={priceDayCompanyVat}
                disabled={disabled}
                onChange={onChangePriceDayCompanyVat}
                onBlur={onBlurPriceDayCompanyVat}
              />
            </td>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={catalogRowEdited.priceMonthCompany}
                disabled={disabled}
                onChange={onChangePriceMonthCompany}
                onBlur={onBlurPriceMonthCompany}
              />
            </td>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={priceMonthCompanyVat}
                disabled={disabled}
                onChange={onChangePriceMonthCompanyVat}
                onBlur={onBlurPriceMonthCompanyVat}
              />
            </td>
          </>
        ) : (
          <>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={catalogRowEdited.priceDayPrivate}
                disabled={disabled}
                onChange={onChangePriceDayPrivate}
                onBlur={onBlurPriceDayPrivate}
              />
            </td>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={priceDayPrivateVat}
                disabled={disabled}
                onChange={onChangePriceDayPrivateVat}
                onBlur={onBlurPriceDayPrivateVat}
              />
            </td>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={catalogRowEdited.priceMonthPrivate}
                disabled={disabled}
                onChange={onChangePriceMonthPrivate}
                onBlur={onBlurPriceMonthPrivate}
              />
            </td>
            <td>
              <TextFieldFocus
                className={classes.txt}
                value={priceMonthPrivateVat}
                disabled={disabled}
                onChange={onChangePriceMonthPrivateVat}
                onBlur={onBlurPriceMonthPrivateVat}
              />
            </td>
          </>
        )}
        <td className="cellPriceChange">
          <Checkbox
            checked={
              useStateForPrint
                ? catalogRowIdsPrinted.includes(catalogRowEdited.id)
                : discountRowEdited.showPrint
            }
            onChange={(event) => {
              // Set state
              if (catalogRowIdsPrinted.includes(catalogRowEdited.id)) {
                setCatalogRowIdsPrinted(
                  removeStringFromArr(catalogRowIdsPrinted, catalogRowEdited.id)
                );
              } else {
                setCatalogRowIdsPrinted([
                  ...catalogRowIdsPrinted,
                  catalogRowEdited.id,
                ]);
              }

              // Set discount row value
              discountRowEdited.showPrint = event.target.checked;
              handleDiscountRowUpdate({
                showPrint: event.target.checked,
              });
            }}
          />
        </td>
      </tr>
    );
    // eslint-disable-next-line
  }, [
    catalogRowEdited,
    discountRowEdited,
    loadingUpdate,
    loadingCreate,
    priceDayCompanyVat,
    priceMonthCompanyVat,
    priceDayPrivateVat,
    priceMonthPrivateVat,
    selectedRows,
    catalogRowIdsPrinted,
  ]);
};

const styles = (theme: Theme) =>
  createStyles({
    txt: {
      maxWidth: "80px",
    },
    cellNoInput: {
      verticalAlign: "middle !important",
    },
  });

export default withStyles(styles)(DiscountRow);
