import { InvoiceableRowsByReferenceType } from "../../components/Invoice/InvoicesCreateListReferences";
import { InvoiceableRowType } from "../../entity/types";

export const getInvoiceableRowsByReference = (
  invoiceableRows: InvoiceableRowType[]
) => {
  let invoiceableRowsByReference: InvoiceableRowsByReferenceType = {};
  invoiceableRows.forEach((invoiceableRow) => {
    if (invoiceableRowsByReference[invoiceableRow.referenceId] === undefined) {
      invoiceableRowsByReference[invoiceableRow.referenceId] = [];
    }
    invoiceableRowsByReference[invoiceableRow.referenceId].push(invoiceableRow);
  });

  return invoiceableRowsByReference;
};
