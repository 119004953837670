import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Paper, Theme } from "@material-ui/core";
import {
  CatalogTransferMethodType,
  CatalogTransferPriceType,
  LocationType,
  Mutation,
  MutationUpdateLocationTransferArgs,
} from "../../entity/types";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CatalogTransferLocationPrices from "./CatalogTransferLocationPrices";
import { CatalogTransferPriceEmpty } from "../../entity/empties";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { useMutation } from "@apollo/client";
import { GET_CATALOG_TRANSFER_QUERY } from "../../apollo/queries/catalogs_transfer";
import { handleError } from "../../entity/ErrorHandler";
import { UPDATE_LOCATION_TRANSFER_MUTATION } from "../../apollo/mutations/locations";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  location: LocationType;
  catalogTransferMethods: CatalogTransferMethodType[];
}

function CatalogTransferLocation({
  classes,
  location,
  catalogTransferMethods,
}: Props) {
  const { t } = useTranslation();

  const [locationEdited, setLocationEdited] = useState(location);

  const [updateLocation, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateLocationTransferArgs
  >(UPDATE_LOCATION_TRANSFER_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_TRANSFER_QUERY }],
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "locations.change_location",
  ]);

  const handleUpdate = () => {
    updateLocation({
      variables: {
        locationId: locationEdited.id,
        transferDistanceIncluded: locationEdited.transferDistanceIncluded,
        transferDistanceLimit: locationEdited.transferDistanceLimit,
      },
    });
  };

  return (
    <Paper className={classes.paper}>
      <Table className="m-0" borderless>
        <thead>
          <tr>
            <th>{location.name}</th>
            <th>{t("transfer_price_basic")}</th>
            <th>{t("transfer_price_extra")}</th>
            <th>{t("transfer_price_stop")}</th>
          </tr>
        </thead>
        <tbody>
          {catalogTransferMethods.map((catalogTransferMethod) => {
            let catalogTransferPrice: CatalogTransferPriceType =
              CatalogTransferPriceEmpty;
            catalogTransferMethod.catalogtransferpriceSet.forEach(
              (catalogTransferPriceLooped) => {
                if (catalogTransferPriceLooped.location.id === location.id) {
                  catalogTransferPrice = catalogTransferPriceLooped;
                }
              }
            );

            return (
              <CatalogTransferLocationPrices
                key={catalogTransferMethod.id}
                catalogTransferMethod={catalogTransferMethod}
                catalogTransferPrice={catalogTransferPrice}
                location={location}
              />
            );
          })}
        </tbody>
      </Table>
      <Table
        className={`m-0 mt-3 ${loadingUpdate ? "loading" : ""}`}
        borderless
      >
        <thead>
          <tr>
            <th>{t("transfer_limits")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("transfer_distance_included")}</td>
            <td>
              <FormControl className={classes.txtInt} fullWidth>
                <TextFieldFocus
                  type="number"
                  value={locationEdited.transferDistanceIncluded}
                  disabled={!hasPermissionEdit}
                  onChange={(event) => {
                    setLocationEdited({
                      ...locationEdited,
                      transferDistanceIncluded: Number(event.target.value),
                    });
                  }}
                  onBlur={handleUpdate}
                />
              </FormControl>
            </td>
            <td>{t("transfer_distance_included_suffix")}</td>
          </tr>
          <tr>
            <td>{t("transfer_distance_limit")}</td>
            <td>
              <FormControl className={classes.txtInt} fullWidth>
                <TextFieldFocus
                  type="number"
                  value={locationEdited.transferDistanceLimit}
                  disabled={!hasPermissionEdit}
                  onChange={(event) => {
                    setLocationEdited({
                      ...locationEdited,
                      transferDistanceLimit: Number(event.target.value),
                    });
                  }}
                  onBlur={handleUpdate}
                />
              </FormControl>
            </td>
            <td>{t("transfer_distance_limit_suffix")}</td>
          </tr>
        </tbody>
      </Table>
    </Paper>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    paper: {
      width: "auto",
      float: "left",
      marginRight: spacing(3),
      marginBottom: spacing(3),
      padding: spacing(2),
    },
    txtInt: {
      maxWidth: "90px",
    },
  });

export default withStyles(styles)(CatalogTransferLocation);
