import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  EventType,
  Mutation,
  MutationCreateEventArgs,
  MutationUpdateCustomerArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { EventEmpty } from "../../entity/empties";
import { CREATE_EVENT_MUTATION } from "../../apollo/mutations/events";
import DialogContentEvent from "../Event/DialogContentEvent";
import { format } from "date-fns";
import { DATE_FORMAT_ISO, ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import ButtonLoad from "../Shared/ButtonLoad";
import { getQueryKey } from "../../utils/cache";
import { UPDATE_CUSTOMER_MUTATION } from "../../apollo/mutations/customers";
import { getContactNextDefault } from "../../utils/customers/customer";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customer: CustomerType;
}

function DialogCustomerEvent({ classes, open, setOpen, customer }: Props) {
  const { t } = useTranslation();

  const [event, setEvent] = useState<EventType>({
    ...EventEmpty,
    customer: {
      ...customer,
      contactNext: getContactNextDefault(),
    },
    date: format(new Date(), DATE_FORMAT_ISO),
  });

  const [createEvent, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateEventArgs
  >(CREATE_EVENT_MUTATION, {
    onCompleted: () => {
      updateCustomer();
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("events"),
      });
    },
  });

  const [updateCustomer, { loading: loadingUpdateCustomer }] = useMutation<
    Mutation,
    MutationUpdateCustomerArgs
  >(UPDATE_CUSTOMER_MUTATION, {
    variables: {
      customerId: event.customer.id,
      contactNext: event.customer.contactNext
        ? event.customer.contactNext
        : null,
    },
    onCompleted: () => {
      setEvent(EventEmpty);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createEvent({
      variables: {
        eventType: event.eventType,
        date: event.date,
        information: event.information,
        customerId: event.customer.id,
      },
    });
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(e) => handleSubmit(e)}>
        <DialogTitle>{t("new_event")}</DialogTitle>
        <DialogContentEvent event={event} setEvent={setEvent} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad
            loading={loadingCreate || loadingUpdateCustomer}
            disabled={!event.date.trim() || event.customer.id === ID_EMPTY}
            type="submit"
            variant="primary"
          >
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCustomerEvent);
