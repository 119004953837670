import { gql } from "@apollo/client";

export const CREATE_PRODUCT_CODE_MUTATION = gql`
  mutation createProductCode(
    $code: String!
    $costcenterId: ID
    $costtypeId: ID
    $description: String
  ) {
    createProductCode(
      code: $code
      costcenterId: $costcenterId
      costtypeId: $costtypeId
      description: $description
    ) {
      productCode {
        id
        code
        description
        createdAt
      }
    }
  }
`;

export const UPDATE_PRODUCT_CODE_MUTATION = gql`
  mutation updateProductCode(
    $productCodeId: ID!
    $costcenterId: ID
    $costtypeId: ID
    $code: String
    $description: String
  ) {
    updateProductCode(
      productCodeId: $productCodeId
      costcenterId: $costcenterId
      costtypeId: $costtypeId
      code: $code
      description: $description
    ) {
      productCode {
        id
        code
        description
      }
    }
  }
`;

export const DELETE_PRODUCT_CODE_MUTATION = gql`
  mutation deleteProductCode($productCodeId: ID!) {
    deleteProductCode(productCodeId: $productCodeId) {
      productCodeId
    }
  }
`;
