import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import LocationFilter from "../components/Shared/LocationFilter";
import { UserContext } from "../Root";
import { getLocationSelected } from "../utils/locations/location";
import HeaderPage from "../components/Shared/HeaderPage";
import { useQuery } from "@apollo/client";
import {
  GET_RESERVATIONS_RENTED_OUT_QUERY,
  QueryResultReservationsRentedOut,
} from "../apollo/queries/reservations";
import { QueryReservationsRentedOutArgs } from "../entity/types";
import { getQueryFetchPolicy } from "../utils/getQueryFetchPolicy";
import Error from "../components/Shared/Error";
import LoadingSimple from "../components/Shared/LoadingSimple";
import MaterialTableReservations from "../components/Reservation/MaterialTableReservations";
import { CONTAINER_MAIN_WIDTH_XL } from "../utils/constants";

interface Props extends WithStyles<typeof styles> {}

function WorkQueuesOnRent({ classes }: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);
  const locationIdDefault = getLocationSelected(user);

  const [locationIdsSelected, setLocationIdsSelected] = useState<string[]>([
    locationIdDefault,
  ]);

  const { loading, error, data } = useQuery<
    QueryResultReservationsRentedOut,
    QueryReservationsRentedOutArgs
  >(GET_RESERVATIONS_RENTED_OUT_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationsRentedOut"),
    variables: {
      locationIds: locationIdsSelected,
    },
  });

  if (loading) return <LoadingSimple />;
  if (error) return <Error error={error} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  return (
    <Container fluid className={`containerMain ${classes.container}`}>
      <HeaderPage title={t("work_queues_on_rent")} />
      <div className="containerInner">
        <Row className="mb-3">
          <Col className="mb-2" sm={6} md={6} lg={4} xl={3}>
            <LocationFilter
              locationIdsSelected={locationIdsSelected}
              setLocationIdsSelected={setLocationIdsSelected}
            />
          </Col>
        </Row>
        <MaterialTableReservations reservations={data.reservationsRentedOut} />
      </div>
    </Container>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: CONTAINER_MAIN_WIDTH_XL,
    },
  });

export default withStyles(styles)(WorkQueuesOnRent);
