import React, { useContext, useRef } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, DialogContent, Theme } from "@material-ui/core";
import {
  MutationUploadReservationImagesArgs,
  QueryReservationImageGivesArgs,
  QueryReservationImageReturnsArgs,
  ReservationImageType,
} from "../../entity/types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useMutation, useQuery } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import DialogTitleWithClose from "../Shared/DialogTitleWithClose";
import {
  MutationResultUploadReservationImages,
  UPLOAD_RESERVATION_IMAGES_MUTATION,
} from "../../apollo/mutations/reservations";
import {
  GET_RESERVATION_IMAGE_GIVES_QUERY,
  GET_RESERVATION_IMAGE_RETURNS_QUERY,
  QueryResultReservationImageGives,
  QueryResultReservationImageReturns,
} from "../../apollo/queries/reservations";
import Error from "../Shared/Error";
import { PermissionsContext, SettingsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import ReservationButtonImagesAt from "./ReservationButtonImagesAt";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import ReservationImages from "./ReservationImages";
import ImageUploader from "react-images-upload";
import LoadingSimple from "../Shared/LoadingSimple";
import { useGetReservationForAddingImagesQuery } from "../../apollo/queries/reservations.generated";

export type ImageFieldType = "" | "giveImagesAt" | "returnImagesAt";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  reservationId: string;
  field: ImageFieldType;
}

function DialogReservationWorkQueueImages({
  classes,
  open,
  setOpen,
  reservationId,
  field,
}: Props) {
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const imageUploadRef = useRef<any>(null);

  const {
    loading: loadingReservation,
    error: errorReservation,
    data: dataReservation,
  } = useGetReservationForAddingImagesQuery({
    variables: {
      id: reservationId,
    },
  });

  const {
    loading: loadingGives,
    error: errorGives,
    data: dataGives,
  } = useQuery<
    QueryResultReservationImageGives,
    QueryReservationImageGivesArgs
  >(GET_RESERVATION_IMAGE_GIVES_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageGives"),
    skip: field !== "giveImagesAt",
    variables: {
      reservationId: reservationId,
    },
  });

  const {
    loading: loadingReturns,
    error: errorReturns,
    data: dataReturns,
  } = useQuery<
    QueryResultReservationImageReturns,
    QueryReservationImageReturnsArgs
  >(GET_RESERVATION_IMAGE_RETURNS_QUERY, {
    fetchPolicy: getQueryFetchPolicy("reservationImageReturns"),
    skip: field !== "returnImagesAt",
    variables: {
      reservationId: reservationId,
    },
  });

  const refetchQueries = [
    {
      query: GET_RESERVATION_IMAGE_GIVES_QUERY,
      variables: { reservationId: reservationId },
    },
    {
      query: GET_RESERVATION_IMAGE_RETURNS_QUERY,
      variables: { reservationId: reservationId },
    },
  ];

  const [uploadReservationImage, { loading: loadingUpload }] = useMutation<
    MutationResultUploadReservationImages,
    MutationUploadReservationImagesArgs
  >(UPLOAD_RESERVATION_IMAGES_MUTATION, {
    refetchQueries: refetchQueries,
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionAdd = checkPermission(myPermissions, [
    "reservations.add_reservationimage",
  ]);

  if (loadingReservation) return <LoadingSimple />;
  if (errorReservation) return <Error error={errorReservation} />;
  if (!dataReservation) return <Error error={t("error_query_failed")} />;
  if (!loadingGives && errorGives) return <Error error={errorGives} />;
  if (!loadingReturns && errorReturns) return <Error error={errorReturns} />;

  const reservation = dataReservation.reservation;

  const reservationImages: ReservationImageType[] =
    field === "giveImagesAt" && dataGives && dataGives.reservationImageGives
      ? dataGives.reservationImageGives
      : field === "returnImagesAt" &&
        dataReturns &&
        dataReturns.reservationImageReturns
      ? dataReturns.reservationImageReturns
      : [];

  function onChange(files: File[]) {
    uploadReservationImage({
      variables: {
        reservationAlbumId:
          field === "giveImagesAt"
            ? reservation.albumGive.id
            : reservation.albumReturn.id,
        files: files,
      },
    });

    // Empty the uploaded files so if user uploads more files, we don't upload same files again
    imageUploadRef.current.state.files = [];
  }

  const hasImages = reservationImages.length > 0;

  const helpText =
    field === "giveImagesAt"
      ? settings.helpTextImagesGive
      : settings.helpTextImagesReturn;

  return (
    <Dialog open={open}>
      <DialogTitleWithClose
        id="dialogTitleCatalogRowUpload"
        onClose={() => setOpen(false)}
      >
        {t("reservation_images")}
      </DialogTitleWithClose>
      <DialogContent className={loadingUpload ? "loading" : ""}>
        {helpText !== "" && <p>{helpText}</p>}
        <ReservationImages reservationImages={reservationImages} />
        {hasPermissionAdd && (
          <ImageUploader
            ref={imageUploadRef}
            withIcon={true}
            buttonText={t("upload_files")}
            onChange={onChange}
            imgExtension={[".jpg", ".jpeg", ".gif", ".png"]}
            withLabel={false}
            maxFileSize={20971520}
          />
        )}
      </DialogContent>
      <DialogActions>
        <ReservationButtonImagesAt
          reservation={reservation}
          field={field}
          disabled={!hasImages}
          setOpenDialogOperationHours={setOpen}
        />
      </DialogActions>
    </Dialog>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    conImages: {
      maxWidth: "300px",
      marginBottom: spacing(2),
    },
    conImage: {
      position: "relative",
    },
    btnDelete: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  });

export default withStyles(styles)(DialogReservationWorkQueueImages);
