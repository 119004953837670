import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineType,
  MutationUploadFileMachineCheckListArgs,
} from "../../../entity/types";
import { useMutation } from "@apollo/client";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { TabPanel } from "@material-ui/lab";
import { handleError } from "../../../entity/ErrorHandler";
import {
  MutationResultUploadFileMachineCheckList,
  UPLOAD_FILE_MACHINE_CHECK_LIST_MUTATION,
} from "../../../apollo/mutations/machines";
import ButtonLoad from "../../Shared/ButtonLoad";
import { ProductCardTabs } from "./DialogMachineProductCard";

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCheckList({ classes, machine }: Props) {
  const { t } = useTranslation();

  const [uploadFileCheckList, { loading }] = useMutation<
    MutationResultUploadFileMachineCheckList,
    MutationUploadFileMachineCheckListArgs
  >(UPLOAD_FILE_MACHINE_CHECK_LIST_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);

  const hasPermissionViewMachines = checkPermission(myPermissions, [
    "machines.view_machine",
  ]);
  const hasPermissionEditMachines = checkPermission(myPermissions, [
    "machines.change_machine",
  ]);

  if (!hasPermissionViewMachines) {
    return null;
  }

  const onChangeFileCheckList = ({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && validity.valid) {
      let file = files[0];
      uploadFileCheckList({
        variables: {
          machineId: machine.id,
          fileCheckList: file,
        },
      });
    }
  };

  const onDeleteFileCheckList = () => {
    uploadFileCheckList({
      variables: {
        machineId: machine.id,
        fileCheckList: null,
      },
    });
  };

  return (
    <TabPanel
      value={ProductCardTabs.CheckList}
      className={loading ? "loading" : ""}
    >
      {machine.fileCheckList && (
        <div className="mb-3">
          <a href={machine.fileCheckList} target="_blank" rel="noreferrer">
            {t("machine_check_list")}
          </a>
        </div>
      )}
      <input
        id="fileMachineCheckList"
        type="file"
        onChange={onChangeFileCheckList}
        disabled={!hasPermissionEditMachines}
      />
      {machine.fileCheckList && (
        <ButtonLoad
          className="ms-3"
          variant="light"
          loading={false}
          onClick={onDeleteFileCheckList}
          disabled={!hasPermissionEditMachines}
        >
          {t("delete")}
        </ButtonLoad>
      )}
    </TabPanel>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(TabMachineCheckList);
