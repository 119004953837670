import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import { createStyles, Theme } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import {
  CustomerType,
  Mutation,
  MutationCreateCustomerArgs,
} from "../../entity/types";
import { Button } from "react-bootstrap";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { CustomerEmpty } from "../../entity/empties";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER_MUTATION } from "../../apollo/mutations/customers";
import { handleError } from "../../entity/ErrorHandler";
import DialogContentCustomer from "./DialogContentCustomer";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey } from "../../utils/cache";
import ButtonLoad from "../Shared/ButtonLoad";

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customerParent: CustomerType;
  callbackCreateCustomer?: Function;
}

function DialogCustomerSub({
  classes,
  open,
  setOpen,
  customerParent,
  callbackCreateCustomer,
}: Props) {
  const { t } = useTranslation();
  const [customer, setCustomer] = useState<CustomerType>(() => {
    let tmp = CustomerEmpty;
    return {
      ...tmp,
      blockedAt: customerParent.blockedAt,
      blockedReason: customerParent.blockedReason,
      businessId: customerParent.businessId,
      creditInformationOk: customerParent.creditInformationOk,
      creditInformationCheckedAt: customerParent.creditInformationCheckedAt,
      creditRiskClass: customerParent.creditRiskClass,
      customerParent: {
        ...customerParent,
        useParentInvoicing: true,
      },
      hasInsurance: customerParent.hasInsurance,
      invoicingAddress: customerParent.invoicingAddress,
      invoicingDistrict: customerParent.invoicingDistrict,
      invoicingEmail: customerParent.invoicingEmail,
      invoicingPostcode: customerParent.invoicingPostcode,
      invoicingEAddress: customerParent.invoicingEAddress,
      invoicingEOperator: customerParent.invoicingEOperator,
      name: customerParent.name,
      preInvoicingAt: customerParent.preInvoicingAt,
      preInvoicingReason: customerParent.preInvoicingReason,
    };
  });

  const [createCustomer, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCustomerArgs
  >(CREATE_CUSTOMER_MUTATION, {
    onCompleted: (result) => {
      setCustomer(CustomerEmpty);
      if (callbackCreateCustomer && result.createCustomer) {
        callbackCreateCustomer(result.createCustomer.customer);
      }
    },
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createCustomer({
      variables: {
        customerAreaIds: customer.customerAreas.map((a) => a.id),
        customerRankId: customer.customerRank
          ? customer.customerRank.id
          : ID_EMPTY,
        customerIndustryIds: customer.customerIndustries.map((a) => a.id),
        businessId: customer.businessId,
        name: customer.name,
        contactPersonFirstname: customer.contactPersonFirstname,
        contactPersonLastname: customer.contactPersonLastname,
        email: customer.email,
        phone: customer.phone,
        visitAddress: customer.visitAddress,
        visitPostcode: customer.visitPostcode,
        visitDistrict: customer.visitDistrict,
        invoicingAddress: customerParent.id
          ? customerParent.invoicingAddress
          : customer.invoicingAddress,
        invoicingDistrict: customerParent.id
          ? customerParent.invoicingDistrict
          : customer.invoicingDistrict,
        invoicingEmail: customerParent.id
          ? customerParent.invoicingEmail
          : customer.invoicingEmail,
        invoicingPostcode: customerParent.id
          ? customerParent.invoicingPostcode
          : customer.invoicingPostcode,
        invoicingEAddress: customerParent.id
          ? customerParent.invoicingEAddress
          : customer.invoicingEAddress,
        invoicingEOperator: customerParent.id
          ? customerParent.invoicingEOperator
          : customer.invoicingEOperator,
        contactNext: customer.contactNext ? customer.contactNext : null,
        isBusiness: customer.isBusiness,
        creditInformationOk: customer.creditInformationOk,
        hasInsurance: customer.hasInsurance,
        creditInformationCheckedAt: customer.creditInformationCheckedAt,
        creditRiskClass: customer.creditRiskClass,
        preInvoicingAt: customer.preInvoicingAt,
        preInvoicingReason: customer.preInvoicingReason,
        blockedAt: customer.blockedAt,
        blockedReason: customer.blockedReason,
        userOwnerId: customer.userOwner ? customer.userOwner.id : ID_EMPTY,
        customerIdParent: customerParent.id,
        useParentInvoicing: customer.useParentInvoicing
          ? customer.useParentInvoicing
          : false,
        useParentPricing: customer.useParentPricing
          ? customer.useParentPricing
          : false,
        paymentTerm: customer.paymentTerm,
        noBillingSurchargeAt: customer.noBillingSurchargeAt,
      },
    });
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <form onSubmit={(event) => handleSubmit(event)}>
        <DialogTitle>{t("new_customer_sub")}</DialogTitle>
        <DialogContentCustomer customer={customer} setCustomer={setCustomer} />
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="secondary">
            {t("cancel")}
          </Button>
          <ButtonLoad loading={loadingCreate} type="submit" variant="primary">
            {t("save")}
          </ButtonLoad>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(DialogCustomerSub);
