import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  createStyles,
  FormControlLabel,
  Menu,
  Radio,
  RadioGroup,
  Theme,
} from "@material-ui/core";
import {
  CatalogType,
  CloneDiscountList,
  CloneDiscountListFromCatalog,
  CustomerType,
  DiscountListType,
  Mutation,
  MutationCloneDiscountListArgs,
  MutationCloneDiscountListFromCatalogArgs,
  MutationSelectCustomerCatalogArgs,
  MutationSelectCustomerDiscountListArgs,
  MutationSelectLinkedCustomerPricingArgs,
  MutationUpdateCustomerArgs,
  SelectCustomerCatalog,
  SelectCustomerDiscountList,
  SelectLinkedCustomerPricing,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import { Col, ListGroup, Row } from "react-bootstrap";
import ListGroupItemContentDiscountList from "../Shared/ListGroupItemContentDiscountList";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  GET_CATALOGS_WITH_DISCOUNT_LISTS_QUERY,
  QueryResultCatalogsWithDiscountLists,
} from "../../apollo/queries/catalogs";
import format from "date-fns/format";
import MenuItem from "@material-ui/core/MenuItem";
import { handleError } from "../../entity/ErrorHandler";
import {
  SELECT_CUSTOMER_CATALOG_MUTATION,
  SELECT_CUSTOMER_DISCOUNT_LIST_MUTATION,
  SELECT_LINKED_CUSTOMER_PRICING,
  UPDATE_CUSTOMER_MUTATION,
} from "../../apollo/mutations/customers";
import { CatalogEmpty, DiscountListEmpty } from "../../entity/empties";
import { GET_CUSTOMER_QUERY } from "../../apollo/queries/customers";
import {
  CLONE_DISCOUNT_LIST_FROM_CATALOG_MUTATION,
  CLONE_DISCOUNT_LIST_MUTATION,
} from "../../apollo/mutations/discounts";
import CustomerPriceSource from "./CustomerPriceSource";
import { PermissionsContext, SettingsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { newDate } from "../../utils/dates";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import FormControl from "@material-ui/core/FormControl";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import { getQueryKey, updateCacheCatalogEdit } from "../../utils/cache";
import { getCatalogIdActive } from "../../utils/catalogs/selected_catalog";
import {
  GET_DISCOUNT_LIST_QUERY,
  QueryResultDiscountList,
} from "../../apollo/queries/discounts";
import { formatNumber, parseNumber } from "../../utils/formatting";
import {
  getPercentPrefixDB,
  getPercentPrefixUserInput,
} from "../../utils/discounts/prefixes";
import TextFieldFocus from "../Shared/TextFieldFocus";

const getCustomerDefault = (customer: CustomerType) => ({
  ...customer,
  discountGlobalCatalogRow:
    getPercentPrefixDB(customer.discountGlobalCatalogRow) +
    formatNumber(customer.discountGlobalCatalogRow, 2),
  discountGlobalCatalogExtra:
    getPercentPrefixDB(customer.discountGlobalCatalogExtra) +
    formatNumber(customer.discountGlobalCatalogExtra, 2),
});

interface Props extends WithStyles<typeof styles> {
  customer: CustomerType;
}

const CustomerShowOfferSelect = ({ classes, customer }: Props) => {
  const { t } = useTranslation();
  const [anchorElMenuDiscountList, setAnchorElMenuDiscountList] =
    useState<null | HTMLElement>(null);
  const [anchorElMenuCatalog, setAnchorElMenuCatalog] =
    useState<null | HTMLElement>(null);
  const [discountList, setDiscountList] = useState(DiscountListEmpty);
  const [catalog, setCatalog] = useState(CatalogEmpty);
  const [customerEdited, setCustomerEdited] = useState(
    getCustomerDefault(customer)
  );
  const settings = useContext(SettingsContext);
  const catalogIdActive = getCatalogIdActive(settings);

  const selectDiscountListId =
    customerEdited.discountListSelected &&
    customerEdited.discountListSelected.id
      ? customerEdited.discountListSelected.id
      : customerEdited.customerParent &&
        customerEdited.customerParent.discountListSelected &&
        customerEdited.customerParent.discountListSelected.id
      ? customerEdited.customerParent.discountListSelected.id
      : ID_EMPTY;

  const {
    loading: dlLoading,
    error: dlError,
    data: dlData,
  } = useQuery<QueryResultDiscountList>(GET_DISCOUNT_LIST_QUERY, {
    variables: {
      discountListId: selectDiscountListId,
    },
    skip: selectDiscountListId === ID_EMPTY,
  });

  let getId = "";
  if (catalogIdActive !== ID_EMPTY) {
    getId = catalogIdActive;
  } else if (
    customerEdited.catalogSelected &&
    customerEdited.catalogSelected.id
  ) {
    getId = customerEdited.catalogSelected.id;
  }

  const { loading, error, data } =
    useQuery<QueryResultCatalogsWithDiscountLists>(
      GET_CATALOGS_WITH_DISCOUNT_LISTS_QUERY,
      {
        fetchPolicy: getQueryFetchPolicy("catalogs"),
        variables: {
          catalogIds: getId,
        },
      }
    );

  const [selectCustomerDiscountList] = useMutation<
    SelectCustomerDiscountList,
    MutationSelectCustomerDiscountListArgs
  >(SELECT_CUSTOMER_DISCOUNT_LIST_MUTATION, {
    update: (cache) => {
      updateCacheCatalogEdit(cache);
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [selectCustomerCatalog] = useMutation<
    SelectCustomerCatalog,
    MutationSelectCustomerCatalogArgs
  >(SELECT_CUSTOMER_CATALOG_MUTATION, {
    update: (cache) => {
      updateCacheCatalogEdit(cache);
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [cloneDiscountList] = useMutation<
    CloneDiscountList,
    MutationCloneDiscountListArgs
  >(CLONE_DISCOUNT_LIST_MUTATION, {
    refetchQueries: [
      { query: GET_CUSTOMER_QUERY, variables: { id: customerEdited.id } },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [cloneDiscountListFromCatalog] = useMutation<
    CloneDiscountListFromCatalog,
    MutationCloneDiscountListFromCatalogArgs
  >(CLONE_DISCOUNT_LIST_FROM_CATALOG_MUTATION, {
    refetchQueries: [
      { query: GET_CUSTOMER_QUERY, variables: { id: customerEdited.id } },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  const [SelectLinkedCustomerPricing, { loading: loadingUpdate }] = useMutation<
    SelectLinkedCustomerPricing,
    MutationSelectLinkedCustomerPricingArgs
  >(SELECT_LINKED_CUSTOMER_PRICING, {
    update: (cache) => {
      updateCacheCatalogEdit(cache);
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customer"),
      });
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [updateCustomer, { loading: loadingUpdateCustomer }] = useMutation<
    Mutation,
    MutationUpdateCustomerArgs
  >(UPDATE_CUSTOMER_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("customers"),
      });
    },
  });

  useEffect(() => {
    setCustomerEdited(getCustomerDefault(customer));
  }, [customer]);

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "customers.change_customer",
  ]);

  if (loading || dlLoading) return <Loading />;
  if (error) return <Error error={error} />;
  if (dlError) return <Error error={dlError} />;
  if (!data) return <Error error={t("error_query_failed")} />;

  const onClickDiscountList = (
    event: React.MouseEvent<Element>,
    discountList: DiscountListType
  ) => {
    if (!hasPermissionEdit) {
      return;
    }

    setAnchorElMenuDiscountList(event.target as HTMLElement);
    setDiscountList(discountList);
  };

  const onclickDiscountListSelect = () => {
    handleMenuClose();
    selectCustomerDiscountList({
      variables: {
        customerId: customerEdited.id,
        discountListId: discountList.id,
      },
    });
  };

  const onclickCatalogSelect = () => {
    handleMenuClose();
    selectCustomerCatalog({
      variables: { customerId: customerEdited.id, catalogId: catalog.id },
    });
  };

  const onClickCatalog = (
    event: React.MouseEvent<Element>,
    catalog: CatalogType
  ) => {
    if (!hasPermissionEdit) {
      return;
    }

    setAnchorElMenuCatalog(event.target as HTMLElement);
    setCatalog(catalog);
  };

  const onclickDiscountListCopy = () => {
    handleMenuClose();
    cloneDiscountList({
      variables: {
        discountListId: discountList.id,
        customerId: customerEdited.id,
      },
    });
  };

  const onclickCatalogCopy = () => {
    handleMenuClose();
    cloneDiscountListFromCatalog({
      variables: {
        catalogId: catalog.id,
        customerId: customerEdited.id,
      },
    });
  };

  const handleMenuClose = () => {
    setAnchorElMenuDiscountList(null);
    setAnchorElMenuCatalog(null);
  };

  const handleRadioButtonChange = (
    values: Partial<MutationSelectLinkedCustomerPricingArgs>
  ) => {
    if (customerEdited.id !== ID_EMPTY) {
      const base: MutationSelectLinkedCustomerPricingArgs = {
        customerId: customerEdited.id,
        useParentPricing: customerEdited.useParentPricing,
      };
      SelectLinkedCustomerPricing({
        variables: { ...base, ...values },
      });
    }
  };

  const showError = () => {
    if (customerEdited.catalogSelected && customerEdited.catalogSelected.id) {
      if (customerEdited.catalogSelected.id !== catalogIdActive) {
        return true;
      }
    } else if (
      customerEdited.discountListSelected &&
      customerEdited.discountListSelected.id
    ) {
      if (
        dlData &&
        dlData.discountList &&
        dlData.discountList.catalog.id !== catalogIdActive
      ) {
        return true;
      }
    } else if (customerEdited.customerParent) {
      if (
        customerEdited.customerParent.catalogSelected &&
        customerEdited.customerParent.catalogSelected.id &&
        customerEdited.customerParent.catalogSelected.id !== catalogIdActive
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={loadingUpdate ? "loading" : ""}>
      <b>{t("customer_active_price_list")}</b>
      <CustomerPriceSource customer={customerEdited} />
      {showError() ? (
        <div className="bg-warning mb-3 p-3">{t("error_outdated_catalog")}</div>
      ) : (
        ""
      )}
      {!customerEdited.useParentPricing && (
        <div>
          <h4>{t("customer_discount_global")}</h4>
          <Row className={`mb-5 ${loadingUpdateCustomer ? "loading" : ""}`}>
            <Col className="mb-3" sm={2}>
              <FormControl fullWidth>
                <TextFieldFocus
                  label={t("customer_discount_global_catalog_row")}
                  value={customerEdited.discountGlobalCatalogRow}
                  disabled={!hasPermissionEdit}
                  onChange={(event) => {
                    setCustomerEdited({
                      ...customerEdited,
                      discountGlobalCatalogRow: event.target.value,
                    });
                  }}
                  onBlur={() => {
                    const prefix = getPercentPrefixUserInput(
                      customerEdited.discountGlobalCatalogRow
                    );
                    const discountGlobalCatalogRow = formatNumber(
                      parseNumber(customerEdited.discountGlobalCatalogRow),
                      2
                    );

                    setCustomerEdited({
                      ...customerEdited,
                      discountGlobalCatalogRow:
                        prefix + discountGlobalCatalogRow,
                    });

                    updateCustomer({
                      variables: {
                        customerId: customerEdited.id,
                        discountGlobalCatalogRow: parseNumber(
                          prefix + discountGlobalCatalogRow
                        ),
                      },
                    });
                  }}
                />
              </FormControl>
            </Col>
            <Col sm={2}>
              <FormControl fullWidth>
                <TextFieldFocus
                  label={t("customer_discount_global_catalog_extra")}
                  value={customerEdited.discountGlobalCatalogExtra}
                  disabled={!hasPermissionEdit}
                  onChange={(event) => {
                    setCustomerEdited({
                      ...customerEdited,
                      discountGlobalCatalogExtra: event.target.value,
                    });
                  }}
                  onBlur={() => {
                    const prefix = getPercentPrefixUserInput(
                      customerEdited.discountGlobalCatalogExtra
                    );
                    const discountGlobalCatalogExtra = formatNumber(
                      parseNumber(customerEdited.discountGlobalCatalogExtra),
                      2
                    );

                    setCustomerEdited({
                      ...customerEdited,
                      discountGlobalCatalogExtra:
                        prefix + discountGlobalCatalogExtra,
                    });

                    updateCustomer({
                      variables: {
                        customerId: customerEdited.id,
                        discountGlobalCatalogExtra: parseNumber(
                          prefix + discountGlobalCatalogExtra
                        ),
                      },
                    });
                  }}
                />
              </FormControl>
            </Col>
          </Row>
        </div>
      )}
      {customerEdited.customerParent ? (
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              value="0"
              control={<Radio color="primary" />}
              label={t("choose_price_list")}
              checked={!Boolean(customerEdited.useParentPricing)}
              onChange={() => {
                setCustomerEdited({
                  ...customerEdited,
                  useParentPricing: false,
                });
                handleRadioButtonChange({ useParentPricing: false });
              }}
            />
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label={t("using_parent_pricing")}
              checked={Boolean(customerEdited.useParentPricing)}
              onChange={() => {
                setCustomerEdited({
                  ...customerEdited,
                  useParentPricing: true,
                });
                handleRadioButtonChange({ useParentPricing: true });
              }}
            />
          </RadioGroup>
        </FormControl>
      ) : (
        <h5 className={classes.h5}>{t("choose_price_list")}</h5>
      )}

      {(data.catalogs && !Boolean(customerEdited.useParentPricing)
        ? data.catalogs
        : []
      ).map((catalog: CatalogType) => (
        <ListGroup className="mt-4" key={catalog.id}>
          <ListGroup.Item
            action={hasPermissionEdit}
            onClick={(event) => {
              onClickCatalog(event, catalog);
            }}
            variant={
              customerEdited.catalogSelected &&
              customerEdited.catalogSelected.id === catalog.id
                ? "secondary"
                : ""
            }
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">{catalog.name}</h5>
              <small title={t("created_at")}>
                {format(newDate(catalog.createdAt), t("format_datetime"))}
              </small>
            </div>
            {catalog.createdBy && (
              <p>
                {catalog.createdBy.lastName} {catalog.createdBy.firstName}
              </p>
            )}
          </ListGroup.Item>
          {catalog.discountlistSet.map((discountList: DiscountListType) => {
            if (
              customerEdited.discountListSelected === null ||
              (customerEdited.discountListSelected &&
                customerEdited.discountListSelected.id !== discountList.id)
            ) {
              return null;
            }

            if (
              (discountList.isOffer &&
                discountList.customer?.id !== customerEdited.id) ||
              customerEdited.isBusiness !== discountList.isBusiness ||
              !discountList.locked
            ) {
              return null;
            }

            return (
              <ListGroup.Item
                key={discountList.id}
                action={hasPermissionEdit}
                onClick={(event) => {
                  onClickDiscountList(event, discountList);
                }}
                variant={
                  customerEdited.discountListSelected &&
                  customerEdited.discountListSelected.id === discountList.id
                    ? "secondary"
                    : ""
                }
              >
                <ListGroupItemContentDiscountList
                  discountList={discountList}
                  customer={customerEdited}
                />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ))}
      <Menu
        anchorEl={anchorElMenuDiscountList}
        keepMounted
        open={Boolean(anchorElMenuDiscountList)}
        onClose={handleMenuClose}
      >
        <CustomerPriceSource
          customer={customerEdited}
          catalogId={ID_EMPTY}
          discountListId={discountList.id}
          menuItem={t("use_discount_list_show")}
        />
        {!Boolean(customerEdited.useParentPricing) && (
          <MenuItem onClick={onclickDiscountListSelect}>
            {t("use_discount_list")}
          </MenuItem>
        )}
        {!Boolean(customerEdited.useParentPricing) && (
          <MenuItem onClick={onclickDiscountListCopy}>
            {t("use_discount_list_copy")}
          </MenuItem>
        )}
      </Menu>
      <Menu
        anchorEl={anchorElMenuCatalog}
        keepMounted
        open={Boolean(anchorElMenuCatalog)}
        onClose={handleMenuClose}
      >
        <CustomerPriceSource
          customer={customerEdited}
          catalogId={catalog.id}
          discountListId={ID_EMPTY}
          menuItem={t("use_catalog_show")}
        />
        {!Boolean(customerEdited.useParentPricing) && (
          <MenuItem onClick={onclickCatalogSelect}>{t("use_catalog")}</MenuItem>
        )}
        {!Boolean(customerEdited.useParentPricing) && (
          <MenuItem onClick={onclickCatalogCopy}>
            {t("use_catalog_copy")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const styles = ({ palette }: Theme) =>
  createStyles({
    h5: {
      color: palette.primary.main,
    },
  });

export default withStyles(styles)(CustomerShowOfferSelect);
