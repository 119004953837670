import {
  MachineBreakdownType,
  MachineType,
  MaintenanceMaintenanceIntervalIntervalTypeChoices,
  MaintenanceIntervalType,
  MaintenanceMaintenanceSettingScheduleTypeChoices,
  OperationHourType,
  ReservationType,
} from "../../entity/types";
import { TFunction } from "react-i18next";
import format from "date-fns/format";
import { newDate } from "../dates";
import { ID_EMPTY } from "../constants";
import { OperationHourEmpty } from "../../entity/empties";

export const formMaintenanceTimeRange = (
  t: TFunction<"translation">,
  intervalType: MaintenanceMaintenanceIntervalIntervalTypeChoices,
  scheduleType: MaintenanceMaintenanceSettingScheduleTypeChoices
) => {
  return intervalType ===
    MaintenanceMaintenanceIntervalIntervalTypeChoices.Normal
    ? scheduleType === MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
      ? t("hours")
      : t("days")
    : scheduleType === MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
    ? t("every_x_hours")
    : t("every_x_days");
};

export const formMaintenanceIntervalData = (
  t: TFunction<"translation">,
  intervalData: MaintenanceIntervalType
) => {
  if (
    intervalData.maintenanceSetting.scheduleType ===
    MaintenanceMaintenanceSettingScheduleTypeChoices.HoursDays
  ) {
    return (
      t("scheduled_maintenance") +
      " " +
      intervalData.hours +
      " " +
      formMaintenanceTimeRange(
        t,
        intervalData.intervalType,
        MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
      ) +
      " / " +
      intervalData.days +
      " " +
      formMaintenanceTimeRange(
        t,
        intervalData.intervalType,
        MaintenanceMaintenanceSettingScheduleTypeChoices.Days
      )
    );
  } else {
    return (
      t("scheduled_maintenance") +
      " " +
      (intervalData.maintenanceSetting.scheduleType ===
      MaintenanceMaintenanceSettingScheduleTypeChoices.Hours
        ? intervalData.hours
        : intervalData.days) +
      " " +
      formMaintenanceTimeRange(
        t,
        intervalData.intervalType,
        intervalData.maintenanceSetting.scheduleType
      )
    );
  }
};

export const formCurrentLocation = (
  t: TFunction<"translation">,
  reservation: ReservationType
) => {
  let returnString = "";

  if (reservation.id !== ID_EMPTY) {
    returnString =
      (reservation.order.customer?.name
        ? reservation.order.customer.name + ", "
        : "") +
      (reservation.order.customer?.visitAddress
        ? reservation.order.customer.visitAddress + ", "
        : "") +
      (reservation.order.customer?.visitPostcode
        ? reservation.order.customer.visitPostcode + " "
        : "") +
      (reservation.order.customer?.visitDistrict
        ? reservation.order.customer.visitDistrict + " "
        : "");
  }

  return returnString;
};

export const formCurrentRentTime = (
  t: TFunction<"translation">,
  reservation: ReservationType
) => {
  let returnString = "";

  if (reservation.id !== ID_EMPTY) {
    returnString = t("date_range", {
      start: format(newDate(reservation.dateRented), t("format_date")),
      end: format(newDate(reservation.dateReturned), t("format_date")),
    });
  }

  return returnString;
};

export const sortReservationsByMachineAndRowExtra = (
  reservations: ReservationType[]
) => {
  let reservationMachine: { [machine_id: string]: ReservationType } = {};
  let reservationRowExtra: { [machine_id: string]: ReservationType } = {};

  reservations.forEach((reservation) => {
    if (reservation.catalogExtraRowRental) {
      reservationRowExtra[reservation.catalogExtraRowRental.id] = reservation;
    } else if (reservation.machine) {
      reservationMachine[reservation.machine.id] = reservation;
    }
  });

  return [reservationMachine, reservationRowExtra];
};

export const sortBreakdownsByMachineAndRowExtra = (
  breakdowns: MachineBreakdownType[]
) => {
  let breakdownsByMachine: { [machine_id: string]: MachineBreakdownType } = {};
  let breakdownsByRowExtra: { [machine_id: string]: MachineBreakdownType } = {};

  breakdowns.forEach((breakdown) => {
    if (breakdown.catalogExtraRowRental) {
      breakdownsByRowExtra[breakdown.catalogExtraRowRental.id] = breakdown;
    } else if (breakdown.machine) {
      breakdownsByMachine[breakdown.machine.id] = breakdown;
    }
  });

  return [breakdownsByMachine, breakdownsByRowExtra];
};

export const sortOperationHoursByMachine = (machines: MachineType[]) => {
  let operationHoursByMachine: {
    [operationHours_id: string]: OperationHourType;
  } = {};

  machines.forEach((machine) => {
    operationHoursByMachine[machine.id] = machine.operationhourSet[0]
      ? machine.operationhourSet[0]
      : OperationHourEmpty;
  });

  return operationHoursByMachine;
};
