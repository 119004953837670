import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Link, Theme } from "@material-ui/core";
import {
  CatalogExtraRowRentalType,
  LocationType,
  Mutation,
  MutationCreateOrderArgs,
  MutationCreateReservationArgs,
  QueryOrderArgs,
} from "../../entity/types";
import CalendarCatalogRow from "./CalendarCatalogRow";
import { Col, Row } from "react-bootstrap";
import {
  MachineBreakdownEmpty,
  OrderEmpty,
  ReservationEmpty,
} from "../../entity/empties";
import CalendarSelectCustomer from "./CalendarSelectCustomer";
import { getUrlCalendar } from "../../utils/urls";
import { LinkContainer } from "react-router-bootstrap";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarFilter from "./CalendarFilter";
import CalendarSelectCatalogRow from "./CalendarSelectCatalogRow";
import CalendarOrder from "./CalendarOrder";
import { useMutation, useQuery } from "@apollo/client";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import {
  CALENDAR_INSERT_MODES,
  CUSTOMER_ID_CATALOG_COMPANY,
  CUSTOMER_ID_CATALOG_PRIVATE,
  DATE_FORMAT_ISO,
  ID_EMPTY,
  ROOT_QUERY,
} from "../../utils/constants";
import DialogOrder from "./DialogOrder";
import { GET_ORDER_QUERY, QueryResultOrder } from "../../apollo/queries/orders";
import {
  getCustomerCatalogCompany,
  getCustomerCatalogDefault,
  getCustomerCatalogPrivate,
} from "../../utils/customers/customer";
import { useTranslation } from "react-i18next";
import {
  getLocalStorageCustomerId,
  getLocalStorageOrderId,
  reservationsByDateType,
  selectOrder,
  setLocalStorageCustomerId,
  setLocalStorageOrderId,
} from "../../utils/calendar/calendar";
import { CustomerForCalendarType } from "../../apollo/queries/customers";
import format from "date-fns/format";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import { getLocationIdDefault } from "../../utils/locations/location";
import { UserContext } from "../../Root";
import { CREATE_ORDER_MUTATION } from "../../apollo/mutations/orders";
import { handleError } from "../../entity/ErrorHandler";
import { CREATE_RESERVATION_MUTATION } from "../../apollo/mutations/reservations";
import {
  getQueryKey,
  updateCacheReservationAndBreakdowns,
} from "../../utils/cache";
import DialogMachineBreakdown from "../Machine/DialogMachineBreakdown";
import { dialogConfirm } from "../../utils/dialogs";
import { getReservationDuplicateConfirmMessage } from "../../utils/reservations/reservation";
import { useGetReservationsLazyQuery } from "../../apollo/queries/reservations.generated";
import { ReservationFromReservationsType } from "../../apollo/queries/reservations";
import { useGetCustomerForCalendarQuery } from "../../apollo/queries/customers.generated";
import {
  BulkAmountByDateFromCalendarType,
  CatalogCategoryUpperFromCalendarType,
  MachineBreakdownFromCalendarType,
  MaintenanceFromCalendarType,
  ReservationFromCalendarType,
} from "../../apollo/queries/catalogs";
import { useGetMachinesForCatalogRowsQuery } from "../../apollo/queries/machines.generated";
import { MachineForCatalogRowsType } from "../../apollo/queries/machines";

interface Props extends WithStyles<typeof styles> {
  catalogId: string;
  catalogCategoryId: string;
  catalogRowId: string;
  catalogCategoriesUpper: CatalogCategoryUpperFromCalendarType[];
  location: LocationType;
  setLocation: React.Dispatch<React.SetStateAction<LocationType>>;
  locations: LocationType[];
  dateFrom: Date;
  setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
  dateTo: Date;
  setDateTo: React.Dispatch<React.SetStateAction<Date>>;
  bulkAmountsByDate: BulkAmountByDateFromCalendarType[];
  reservations: ReservationFromCalendarType[];
  reservationsByDate: reservationsByDateType;
  machineBreakdowns: MachineBreakdownFromCalendarType[];
  maintenances: MaintenanceFromCalendarType[];
  dateSelectionStart: Date | null;
  setDateSelectionStart: React.Dispatch<React.SetStateAction<Date | null>>;
  dateSelectionEnd: Date | null;
  setDateSelectionEnd: React.Dispatch<React.SetStateAction<Date | null>>;
  machineSelected: MachineForCatalogRowsType | null;
  setMachineSelected: React.Dispatch<
    React.SetStateAction<MachineForCatalogRowsType | null>
  >;
  catalogRowIdSelected: string;
  setCatalogRowIdSelected: React.Dispatch<React.SetStateAction<string>>;
  catalogExtraRowRentalSelected: CatalogExtraRowRentalType | null;
  setCatalogExtraRowRentalSelected: React.Dispatch<
    React.SetStateAction<CatalogExtraRowRentalType | null>
  >;
  bulkAmount: number;
  setBulkAmount: React.Dispatch<React.SetStateAction<number>>;
  insertMode: CALENDAR_INSERT_MODES;
  setInsertMode: React.Dispatch<React.SetStateAction<CALENDAR_INSERT_MODES>>;
}

function CalendarCatalog({
  classes,
  catalogId,
  catalogCategoryId,
  catalogRowId,
  catalogCategoriesUpper,
  location,
  setLocation,
  locations,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  bulkAmountsByDate,
  reservations,
  reservationsByDate,
  machineBreakdowns,
  maintenances,
  dateSelectionStart,
  setDateSelectionStart,
  dateSelectionEnd,
  setDateSelectionEnd,
  machineSelected,
  setMachineSelected,
  catalogRowIdSelected,
  setCatalogRowIdSelected,
  catalogExtraRowRentalSelected,
  setCatalogExtraRowRentalSelected,
  bulkAmount,
  setBulkAmount,
  insertMode,
  setInsertMode,
}: Props) {
  const { t } = useTranslation();

  const user = useContext(UserContext);

  const orderIdStorage = getLocalStorageOrderId();

  const [customerIdSelected, setCustomerIdSelected] = useState<string>(
    getLocalStorageCustomerId()
  );
  const [customer, setCustomer] = useState<CustomerForCalendarType>(
    getCustomerCatalogDefault(t)
  );
  const [orderId, setOrderId] = useState<string>(orderIdStorage);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openDialogMachineBreakdown, setOpenDialogMachineBreakdown] =
    useState(false);
  const [machineBreakdown, setMachineBreakdown] =
    useState<MachineBreakdownFromCalendarType>(MachineBreakdownEmpty);

  const { loading: loadingOrder, data: dataOrder } = useQuery<
    QueryResultOrder,
    QueryOrderArgs
  >(GET_ORDER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("order"),
    skip: orderId === ID_EMPTY,
    variables: { orderId: orderId },
    onCompleted: (result) => {
      if (result.order) {
        if (!result.order.customer) {
          setCustomer(
            result.order.isBusinessIfNoCustomer
              ? getCustomerCatalogCompany(t)
              : getCustomerCatalogPrivate(t)
          );
        } else if (result.order.customer.id !== customer.id) {
          setCustomer(result.order.customer);
        }
      }
    },
    onError: (error) => {
      if (getLocalStorageOrderId() === orderId) {
        forgetOrder();
      }
      handleError(error);
    },
  });

  const forgetOrder = () => {
    setLocalStorageOrderId("");
    setCustomer(getCustomerCatalogDefault(t));
    setLocalStorageCustomerId("");
    setOrderId(ID_EMPTY);
  };

  const { loading: loadingCustomer, error: errorCustomer } =
    useGetCustomerForCalendarQuery({
      fetchPolicy: getQueryFetchPolicy("customer"),
      skip:
        [
          ID_EMPTY,
          CUSTOMER_ID_CATALOG_PRIVATE,
          CUSTOMER_ID_CATALOG_COMPANY,
        ].includes(customerIdSelected) || orderId !== ID_EMPTY,
      variables: { id: customerIdSelected },
      onCompleted: (result) => {
        if (result.customer) {
          forgetOrder();
          setCustomer(result.customer);
        }
      },
    });

  let catalogRowIds: string[] = [];
  catalogCategoriesUpper.forEach((catalogCategoryUpper) =>
    catalogCategoryUpper.catalogcategorySet.forEach((catalogCategory) =>
      catalogCategory.catalogrowSet.forEach((catalogRowLooped) => {
        catalogRowIds.push(catalogRowLooped.id);
      })
    )
  );

  const {
    loading: loadingMachines,
    error: errorMachines,
    data: dataMachines,
  } = useGetMachinesForCatalogRowsQuery({
    fetchPolicy: getQueryFetchPolicy("machinesForCatalogRows"),
    variables: {
      catalogRowIds: catalogRowIds,
      locationId: location.id,
    },
  });

  const [createOrder, { loading: loadingCreateOrder }] = useMutation<
    Mutation,
    MutationCreateOrderArgs
  >(CREATE_ORDER_MUTATION, {
    onCompleted: (result) => {
      if (result.createOrder && result.createOrder.order) {
        selectOrder(result.createOrder.order.id, setOrderId);
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const clearSelection = () => {
    setDateSelectionStart(null);
    setDateSelectionEnd(null);
    setMachineSelected(null);
    setMachineBreakdown(MachineBreakdownEmpty);
    setBulkAmount(ReservationEmpty.bulkAmount);
  };

  const [getReservationsBetween, { loading: loadingReservations }] =
    useGetReservationsLazyQuery({
      fetchPolicy: "network-only",
      onError: (error) => handleError(error),
    });

  const [createReservation, { loading: loadingCreateReservation }] =
    useMutation<Mutation, MutationCreateReservationArgs>(
      CREATE_RESERVATION_MUTATION,
      {
        onCompleted: (result) => {
          clearSelection();
        },
        onError: (error) => {
          handleError(error);
        },
        update: (cache) => {
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("bulkAmountsByDate"),
          });
          cache.evict({
            id: ROOT_QUERY,
            fieldName: getQueryKey("reservationsWorkQueues"),
          });
          updateCacheReservationAndBreakdowns(cache);
        },
      }
    );

  const orderFetched =
    dataOrder && dataOrder.order ? dataOrder.order : OrderEmpty;

  const createNewReservation = (orderId: string) => {
    if (dateSelectionStart && dateSelectionEnd) {
      const callback = () => {
        createReservation({
          variables: {
            orderId: orderId,
            machineId: machineSelected ? machineSelected.id : undefined,
            catalogRowId: catalogExtraRowRentalSelected
              ? undefined
              : catalogRowIdSelected,
            catalogExtraRowRentalId: catalogExtraRowRentalSelected
              ? catalogExtraRowRentalSelected.id
              : undefined,
            dateRented: format(dateSelectionStart, DATE_FORMAT_ISO),
            timeRented: undefined,
            dateReturned: format(dateSelectionEnd, DATE_FORMAT_ISO),
            timeReturned: undefined,
            information: ReservationEmpty.information,
            deliveryMethod: ReservationEmpty.deliveryMethod,
            bulkAmount: bulkAmount,
          },
        });
      };

      if (machineSelected && machineSelected.bulkProduct) {
        callback();
      } else {
        getReservationsBetween({
          variables: {
            dateFrom: format(dateSelectionStart, DATE_FORMAT_ISO),
            dateTo: format(dateSelectionEnd, DATE_FORMAT_ISO),
            includeNotReturned: false,
            catalogRowId: catalogExtraRowRentalSelected
              ? undefined
              : catalogRowIdSelected,
            catalogExtraRowRentalId: catalogExtraRowRentalSelected
              ? catalogExtraRowRentalSelected.id
              : undefined,
            machineId:
              machineSelected && !catalogExtraRowRentalSelected
                ? machineSelected.id
                : undefined,
          },
        }).then((result) => {
          const reservationsFound: ReservationFromReservationsType[] = result
            .data?.reservations
            ? result.data.reservations
            : [];

          if (reservationsFound.length === 0) {
            callback();
          } else {
            dialogConfirm(
              t,
              getReservationDuplicateConfirmMessage(
                t,
                reservationsFound,
                t("confirm_new_reservation")
              ),
              () => {
                callback();
              },
              () => {
                clearSelection();
              }
            );
          }
        });
      }
    }
  };

  useEffect(() => {
    if (customerIdSelected === CUSTOMER_ID_CATALOG_PRIVATE) {
      setCustomer(getCustomerCatalogPrivate(t));
    } else if (customerIdSelected === CUSTOMER_ID_CATALOG_COMPANY) {
      setCustomer(getCustomerCatalogCompany(t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerIdSelected]);

  useEffect(() => {
    const addReservationToOrder = () => {
      if (
        dateSelectionStart &&
        dateSelectionEnd &&
        (machineSelected || catalogExtraRowRentalSelected)
      ) {
        if (insertMode === CALENDAR_INSERT_MODES.RESERVATION) {
          if (orderFetched.id !== ID_EMPTY) {
            createNewReservation(orderFetched.id);
          } else {
            createOrder({
              variables: {
                customerId: customer.id,
                referenceId: orderFetched.reference
                  ? orderFetched.reference.id
                  : ID_EMPTY,
                locationId:
                  orderFetched.location.id === ID_EMPTY
                    ? getLocationIdDefault(user)
                    : orderFetched.location.id,
                status: orderFetched.status,
                information: orderFetched.information,
                informationInvoice: orderFetched.informationInvoice,
                confirmationType: orderFetched.confirmationType,
                advanceValidUntil: orderFetched.advanceValidUntil,
                isBusinessIfNoCustomer: customer.isBusiness,
                hasInsurance: customer.hasInsurance,
                updatePricesInCatalogSwitch:
                  orderFetched.updatePricesInCatalogSwitch,
              },
            }).then((result) => {
              if (result.data?.createOrder?.order) {
                createNewReservation(result.data.createOrder.order.id);
              }
            });
          }
        } else if (insertMode === CALENDAR_INSERT_MODES.BREAKDOWN) {
          setMachineBreakdown({
            ...machineBreakdown,
            fixByStart: format(dateSelectionStart, DATE_FORMAT_ISO),
            fixByEnd: format(dateSelectionEnd, DATE_FORMAT_ISO),
          });
          setOpenDialogMachineBreakdown(true);
        }
      }
    };

    addReservationToOrder();
    // eslint-disable-next-line
  }, [dateSelectionStart, dateSelectionEnd, machineSelected]);

  if (loadingOrder || loadingCustomer || loadingMachines) return <Loading />;
  if (errorCustomer) return <Error error={errorCustomer} />;
  if (errorMachines) return <Error error={errorMachines} />;
  if (!dataMachines) return <Error error={t("error_query_failed")} />;
  /* We can't check here if dataOrder has been set since it will be not, if no order is selected. */

  return (
    <div
      className={
        loadingCreateOrder || loadingCreateReservation || loadingReservations
          ? "loading"
          : ""
      }
    >
      {catalogCategoriesUpper.map((catalogCategoryUpper) => {
        return (
          <div key={catalogCategoryUpper.id}>
            <Row className="ms-0 me-0">
              <Col className="ps-0 mb-2">
                <CalendarSelectCatalogRow
                  catalogId={catalogId}
                  catalogRowId={catalogRowId}
                />
                <CalendarFilter
                  dateFrom={dateFrom}
                  setDateFrom={setDateFrom}
                  dateTo={dateTo}
                  setDateTo={setDateTo}
                  location={location}
                  setLocation={setLocation}
                  locations={locations}
                  insertMode={insertMode}
                  setInsertMode={setInsertMode}
                />
              </Col>
              <Col>
                <CalendarSelectCustomer
                  customer={customer}
                  setCustomerIdSelected={setCustomerIdSelected}
                  catalogRowId={catalogRowId}
                  order={orderFetched}
                  canChangeCustomer={
                    dataOrder && dataOrder.orderCanChangeCustomer !== undefined
                      ? dataOrder.orderCanChangeCustomer
                      : true
                  }
                />
              </Col>
              <Col className="pe-0">
                {orderId !== ID_EMPTY && (
                  <CalendarOrder
                    forgetOrder={forgetOrder}
                    order={orderFetched}
                    setOpenDialog={setOpenDialog}
                  />
                )}
              </Col>
            </Row>
            {catalogCategoryUpper.catalogcategorySet.map((catalogCategory) => {
              return (
                <div key={catalogCategory.id}>
                  <div className={`${classes.headerCategory} mb-3`}>
                    {catalogCategoryId !== ID_EMPTY ? (
                      <LinkContainer
                        to={getUrlCalendar(
                          catalogId,
                          catalogCategoryUpper.id,
                          ID_EMPTY,
                          ID_EMPTY
                        )}
                      >
                        <Link>{catalogCategoryUpper.name}</Link>
                      </LinkContainer>
                    ) : (
                      catalogCategoryUpper.name
                    )}
                    <FontAwesomeIcon
                      className="ms-2 me-2"
                      icon={faChevronRight}
                      size="sm"
                    />
                    {catalogCategoryId !== catalogCategory.id ||
                    catalogRowId !== ID_EMPTY ? (
                      <LinkContainer
                        to={getUrlCalendar(
                          catalogId,
                          catalogCategoryUpper.id,
                          catalogCategory.id,
                          ID_EMPTY
                        )}
                      >
                        <Link>{catalogCategory.name}</Link>
                      </LinkContainer>
                    ) : (
                      catalogCategory.name
                    )}
                    {catalogCategory.information && (
                      <span className="text-muted ps-3">
                        {catalogCategory.information}
                      </span>
                    )}
                  </div>
                  {catalogCategory.catalogrowSet.map((catalogRowLooped) => {
                    const machinesForThisCatalogRow: MachineForCatalogRowsType[] =
                      (
                        dataMachines.machinesForCatalogRows
                          ? dataMachines.machinesForCatalogRows
                          : []
                      ).filter((machine) => {
                        const machinePurchasedAfter =
                          machine.acquisitionDate &&
                          machine.acquisitionDate >
                            format(dateTo, DATE_FORMAT_ISO);
                        const machineRemovedBefore =
                          machine.dateRemoved &&
                          machine.dateRemoved <
                            format(dateFrom, DATE_FORMAT_ISO);
                        return (
                          machine.catalogRows.some(
                            (c) => c.id === catalogRowLooped.id
                          ) &&
                          !machinePurchasedAfter &&
                          !machineRemovedBefore
                        );
                      });
                    return (
                      <CalendarCatalogRow
                        key={catalogRowLooped.id}
                        catalogRow={catalogRowLooped}
                        machinesForThisCatalogRow={machinesForThisCatalogRow}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        location={location}
                        setOrderId={setOrderId}
                        order={orderFetched}
                        customer={customer}
                        reservations={reservations}
                        reservationsByDate={reservationsByDate}
                        dateSelectionStart={dateSelectionStart}
                        setDateSelectionStart={setDateSelectionStart}
                        dateSelectionEnd={dateSelectionEnd}
                        setDateSelectionEnd={setDateSelectionEnd}
                        machineSelected={machineSelected}
                        setMachineSelected={setMachineSelected}
                        setCatalogRowIdSelected={setCatalogRowIdSelected}
                        machineBreakdowns={machineBreakdowns}
                        maintenances={maintenances}
                        insertMode={insertMode}
                        catalogId={catalogId}
                        catalogCategoryUpperId={catalogCategoryUpper.id}
                        catalogCategoryId={catalogCategory.id}
                        catalogRowId={catalogRowId}
                        catalogExtraRowRentalSelected={
                          catalogExtraRowRentalSelected
                        }
                        setCatalogExtraRowRentalSelected={
                          setCatalogExtraRowRentalSelected
                        }
                        bulkAmount={bulkAmount}
                        setBulkAmount={setBulkAmount}
                        bulkAmountsByDate={bulkAmountsByDate}
                        clearSelection={clearSelection}
                        setMachineBreakdown={setMachineBreakdown}
                        setOpenDialogMachineBreakdown={
                          setOpenDialogMachineBreakdown
                        }
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
      {customer.id !== ID_EMPTY && openDialog && (
        <DialogOrder
          open={openDialog}
          setOpen={setOpenDialog}
          customer={customer}
          order={orderFetched}
          setOrderId={setOrderId}
          forgetOrder={forgetOrder}
        />
      )}
      {openDialogMachineBreakdown && (
        <DialogMachineBreakdown
          open={openDialogMachineBreakdown}
          setOpen={setOpenDialogMachineBreakdown}
          machineBreakdown={machineBreakdown}
          machine={
            catalogExtraRowRentalSelected || !machineSelected
              ? undefined
              : machineSelected
          }
          catalogExtraRowRental={
            catalogExtraRowRentalSelected
              ? catalogExtraRowRentalSelected
              : undefined
          }
          onClose={clearSelection}
        />
      )}
    </div>
  );
}

const styles = ({ palette }: Theme) =>
  createStyles({
    headerCategory: {
      fontWeight: "bold",
      fontSize: "1rem",
      backgroundColor: palette.grey["100"],
      marginTop: "1vh",
      padding: "10px 15px",

      "& span": {
        fontWeight: "normal",
        fontSize: "0.9rem",
      },
    },
  });

export default withStyles(styles)(CalendarCatalog);
