import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import WorkQueueCardRenting from "./WorkQueueCardRenting";
import { ReservationFromReservationsWorkQueuesType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservations: ReservationFromReservationsWorkQueuesType[];
  id?: string;
}

function WorkQueueRentingCustomerReturn({ classes, reservations, id }: Props) {
  const { t } = useTranslation();

  return (
    <div id={id} className={`${classes.conWorkQueue} conWorkQueue`}>
      <div className="titleWorkQueue">
        {t("work_queues_renting_customer_return")}
      </div>
      {reservations.map((reservation) => {
        return (
          <WorkQueueCardRenting
            key={reservation.id}
            reservation={reservation}
            type="return"
          />
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    conWorkQueue: {
      position: "relative",
    },
  });

export default withStyles(styles)(WorkQueueRentingCustomerReturn);
