import React, { useContext, useEffect, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { useTranslation } from "react-i18next";
import { createStyles, TextField, Theme } from "@material-ui/core";
import {
  MachineType,
  Mutation,
  MutationUpdateMachineArgs,
  MutationUploadFileMachinePurchaseArgs,
} from "../../../entity/types";
import { useMutation } from "@apollo/client";
import { PermissionsContext } from "../../../Root";
import { checkPermission } from "../../../utils/permissions";
import { TabPanel } from "@material-ui/lab";
import { handleError } from "../../../entity/ErrorHandler";
import {
  MutationResultUploadFileMachinePurchase,
  UPDATE_MACHINE_MUTATION,
  UPLOAD_FILE_MACHINE_PURCHASE_MUTATION,
} from "../../../apollo/mutations/machines";
import ButtonLoad from "../../Shared/ButtonLoad";
import { formatNumber, parseNumber } from "../../../utils/formatting";
import TextFieldFocus from "../../Shared/TextFieldFocus";
import { ProductCardTabs } from "./DialogMachineProductCard";

const getMachineDefaults = (machine: MachineType) => ({
  ...machine,
  purchasePrice: formatNumber(machine.purchasePrice, 2),
});

interface Props extends WithStyles<typeof styles> {
  machine: MachineType;
}

function TabMachineCardPurchase({ classes, machine }: Props) {
  const { t } = useTranslation();

  const [machineEdited, setMachineEdited] = useState<MachineType>(
    getMachineDefaults(machine)
  );

  useEffect(() => {
    setMachineEdited(getMachineDefaults(machine));
  }, [machine]);

  const [updateMachine, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateMachineArgs
  >(UPDATE_MACHINE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [uploadFilePurchase, { loading: loadingUpload }] = useMutation<
    MutationResultUploadFileMachinePurchase,
    MutationUploadFileMachinePurchaseArgs
  >(UPLOAD_FILE_MACHINE_PURCHASE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const myPermissions = useContext(PermissionsContext);

  const hasPermissionViewMachinePurchaseInfo = checkPermission(myPermissions, [
    "machines.show_machine_purchase_info",
  ]);
  const hasPermissionEditMachines = checkPermission(myPermissions, [
    "machines.change_machine",
  ]);

  if (!hasPermissionViewMachinePurchaseInfo) {
    return null;
  }

  const onChangeFilePurchase = ({
    target: { validity, files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (files && validity.valid) {
      let file = files[0];
      uploadFilePurchase({
        variables: {
          machineId: machineEdited.id,
          filePurchase: file,
        },
      });
    }
  };

  const onDeleteFilePurchase = () => {
    uploadFilePurchase({
      variables: {
        machineId: machineEdited.id,
        filePurchase: null,
      },
    });
  };

  return (
    <TabPanel
      value={ProductCardTabs.PurchaseInfo}
      className={loadingUpload || loadingUpdate ? "loading" : ""}
    >
      <TextFieldFocus
        fullWidth
        className={classes.txt}
        label={t("machine_purchase_price")}
        value={machineEdited.purchasePrice}
        disabled={!hasPermissionEditMachines}
        onChange={(event) => {
          setMachineEdited({
            ...machineEdited,
            purchasePrice: event.target.value,
          });
        }}
        onBlur={() => {
          const purchasePriceNew =
            machineEdited.purchasePrice === ""
              ? null
              : parseNumber(machineEdited.purchasePrice);
          setMachineEdited({
            ...machineEdited,
            purchasePrice:
              purchasePriceNew === null
                ? purchasePriceNew
                : formatNumber(purchasePriceNew, 2),
          });
          updateMachine({
            variables: {
              machineId: machineEdited.id,
              purchasePrice: purchasePriceNew,
            },
          });
        }}
      />
      <TextField
        fullWidth
        className={classes.txt}
        label={t("machine_purchase_supplier")}
        value={machineEdited.purchaseSupplier}
        disabled={!hasPermissionEditMachines}
        inputProps={{ maxLength: 100 }}
        onChange={(event) => {
          setMachineEdited({
            ...machineEdited,
            purchaseSupplier: event.target.value,
          });
        }}
        onBlur={() => {
          updateMachine({
            variables: {
              machineId: machineEdited.id,
              purchaseSupplier: machineEdited.purchaseSupplier,
            },
          });
        }}
      />
      <TextField
        fullWidth
        multiline
        minRows={5}
        className={classes.txt}
        label={t("machine_purchase_information")}
        value={machineEdited.purchaseInformation}
        disabled={!hasPermissionEditMachines}
        onChange={(event) => {
          setMachineEdited({
            ...machineEdited,
            purchaseInformation: event.target.value,
          });
        }}
        onBlur={() => {
          updateMachine({
            variables: {
              machineId: machineEdited.id,
              purchaseInformation: machineEdited.purchaseInformation,
            },
          });
        }}
      />
      {machineEdited.filePurchase && (
        <div className="mb-3">
          <a href={machineEdited.filePurchase} target="_blank" rel="noreferrer">
            {t("machine_file_purchase")}
          </a>
        </div>
      )}
      <input
        id="fileMachinePurchase"
        type="file"
        onChange={onChangeFilePurchase}
        disabled={!hasPermissionEditMachines}
      />
      {machineEdited.filePurchase && (
        <ButtonLoad
          className="ms-3"
          variant="light"
          loading={false}
          onClick={onDeleteFilePurchase}
          disabled={!hasPermissionEditMachines}
        >
          {t("delete")}
        </ButtonLoad>
      )}
    </TabPanel>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    txt: {
      display: "block",
      width: "25rem",
      maxWidth: "100%",
    },
  });

export default withStyles(styles)(TabMachineCardPurchase);
