import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import {
  MachineBreakdownType,
  MaintenanceType,
  QueryMachinesWithIdsArgs,
  ReservationType,
} from "../../entity/types";
import { useTranslation } from "react-i18next";
import {
  MachineBreakdownEmpty,
  MachineEmpty,
  OperationHourEmpty,
  ReservationEmpty,
} from "../../entity/empties";
import { ID_EMPTY } from "../../utils/constants";
import {
  sortBreakdownsByMachineAndRowExtra,
  sortOperationHoursByMachine,
  sortReservationsByMachineAndRowExtra,
} from "../../utils/maintenances/maintenance";
import WorkQueueCardMaintenance from "./WorkQueueCardMaintenance";
import { useQuery } from "@apollo/client";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import Error from "../Shared/Error";
import LoadingSimple from "../Shared/LoadingSimple";
import {
  GET_MACHINES_AND_OPERATION_HOURS_QUERY,
  QueryResultMachinesWithOperationHours,
} from "../../apollo/queries/machines";
import CreateMaintenance from "../Maintenance/CreateMaintenance";

interface Props extends WithStyles<typeof styles> {
  maintenancesUpcoming: MaintenanceType[];
  reservationsCurrent: ReservationType[];
  reservationsUpcoming: ReservationType[];
  machineBreakdowns: MachineBreakdownType[];
  locations: string[];
}

function WorkQueuesMaintenancesUpcoming({
  classes,
  maintenancesUpcoming,
  reservationsCurrent,
  reservationsUpcoming,
  machineBreakdowns,
  locations,
}: Props) {
  const { t } = useTranslation();

  let maintenanceIdList: string[] = [];
  maintenancesUpcoming.forEach((maintenance) => {
    maintenanceIdList.push(maintenance.id);
  });

  const {
    loading: loadingMachineHours,
    error: errorMachineHours,
    data: dataMachineHours,
  } = useQuery<QueryResultMachinesWithOperationHours, QueryMachinesWithIdsArgs>(
    GET_MACHINES_AND_OPERATION_HOURS_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("operationHoursForMachine"),
      variables: {
        machineIds: maintenanceIdList,
      },
    }
  );

  if (errorMachineHours) return <Error error={errorMachineHours} />;
  if (loadingMachineHours) return <LoadingSimple />;
  if (!dataMachineHours) {
    return <Error error={t("error_query_failed")} />;
  }

  const operationHoursByMachine = sortOperationHoursByMachine(
    dataMachineHours.machinesAndOperationHours
      ? dataMachineHours.machinesAndOperationHours
      : [MachineEmpty]
  );

  const currentReservations =
    sortReservationsByMachineAndRowExtra(reservationsCurrent);
  const reservationCurrentByMachine = currentReservations[0];
  const reservationCurrentByRowExtra = currentReservations[1];

  const nextReservations =
    sortReservationsByMachineAndRowExtra(reservationsUpcoming);
  const reservationNextByMachine = nextReservations[0];
  const reservationNextByRowExtra = nextReservations[1];

  const breakdowns = sortBreakdownsByMachineAndRowExtra(machineBreakdowns);
  const breakdownsByMachine = breakdowns[0];
  const breakdownsByRowExtra = breakdowns[1];

  return (
    <div className="conWorkQueue">
      <CreateMaintenance
        btnClass="btnRound btn btn-primary btn-sm fa-pull-right mt-minus-0-5"
        locations={locations}
      />
      <div className="titleWorkQueue">
        {t("work_queues_upcoming_maintenances")}
      </div>
      {maintenancesUpcoming.map((maintenanceUpcoming) => {
        const key = maintenanceUpcoming.catalogExtraRowRental
          ? maintenanceUpcoming.catalogExtraRowRental.id
          : maintenanceUpcoming.machine
          ? maintenanceUpcoming.machine.id
          : ID_EMPTY;

        const reservationNext =
          reservationNextByRowExtra[key] !== undefined
            ? reservationNextByRowExtra[key]
            : reservationNextByMachine[key] !== undefined
            ? reservationNextByMachine[key]
            : ReservationEmpty;

        const reservationCurrent =
          reservationCurrentByRowExtra[key] !== undefined
            ? reservationCurrentByRowExtra[key]
            : reservationCurrentByMachine[key] !== undefined
            ? reservationCurrentByMachine[key]
            : ReservationEmpty;

        const breakdown =
          breakdownsByRowExtra[key] !== undefined
            ? breakdownsByRowExtra[key]
            : breakdownsByMachine[key] !== undefined
            ? breakdownsByMachine[key]
            : MachineBreakdownEmpty;

        const operationHours =
          operationHoursByMachine[key] !== undefined
            ? operationHoursByMachine[key]
            : OperationHourEmpty;

        return (
          <WorkQueueCardMaintenance
            key={maintenanceUpcoming.id}
            maintenance={maintenanceUpcoming}
            reservationCurrent={reservationCurrent}
            reservationNext={reservationNext}
            machineBreakdown={breakdown}
            recordedOperationHours={operationHours}
          />
        );
      })}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(WorkQueuesMaintenancesUpcoming);
