import React, { useContext } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Button, createStyles, Theme } from "@material-ui/core";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";

interface Props extends WithStyles<typeof styles> {
  reservationId: string;
  callbackOnClick: Function;
}

const ButtonOrderReservation = ({
  classes,
  reservationId,
  callbackOnClick,
}: Props) => {
  const myPermissions = useContext(PermissionsContext);
  const hasPermissionViewOrder = checkPermission(myPermissions, [
    "orders.view_order",
  ]);

  return (
    <Button
      onClick={() => {
        callbackOnClick();
      }}
      variant="outlined"
      disabled={!hasPermissionViewOrder}
    >
      #{reservationId}
    </Button>
  );
};

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ButtonOrderReservation);
