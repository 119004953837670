import React from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { parseNumber } from "../../utils/formatting";
import { Button } from "react-bootstrap";
import TextFieldFocus from "../Shared/TextFieldFocus";
import { ReservationFromReservationsQueueReturnType } from "../../apollo/queries/reservations";

interface Props extends WithStyles<typeof styles> {
  reservation: ReservationFromReservationsQueueReturnType;
  bulkAmount: number;
  callbackSetBulkAmount: (bulkAmountNew: number) => void;
}

function ReservationBulkAmountReturned({
  classes,
  reservation,
  bulkAmount,
  callbackSetBulkAmount,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="mt-1">
      <FormControl>
        <TextFieldFocus
          type="number"
          label={t("bulk_amount_returned")}
          onChange={(event) => {
            callbackSetBulkAmount(parseNumber(event.target.value));
          }}
          value={bulkAmount}
          inputProps={{
            maxLength: 13,
            min: 1,
            max: reservation.bulkAmount,
          }}
        />
      </FormControl>
      {bulkAmount !== reservation.bulkAmount && (
        <Button
          variant="light"
          size="sm"
          className="mt-3 ms-2"
          onClick={() => callbackSetBulkAmount(reservation.bulkAmount)}
        >
          {t("all")}
        </Button>
      )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(ReservationBulkAmountReturned);
