import React, { useContext, useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, FormControl, TextField, Theme } from "@material-ui/core";
import {
  CatalogTransferMethodType,
  CatalogTransferPriceType,
  LocationType,
  Mutation,
  MutationCreateCatalogTransferPriceArgs,
  MutationUpdateCatalogTransferPriceArgs,
} from "../../entity/types";
import { PermissionsContext } from "../../Root";
import { checkPermission } from "../../utils/permissions";
import { formatNumber, parseNumber } from "../../utils/formatting";
import { useMutation } from "@apollo/client";
import { handleError } from "../../entity/ErrorHandler";
import { GET_CATALOG_TRANSFER_QUERY } from "../../apollo/queries/catalogs_transfer";
import {
  CREATE_CATALOG_TRANSFER_PRICE_MUTATION,
  UPDATE_CATALOG_TRANSFER_PRICE_MUTATION,
} from "../../apollo/mutations/catalogs_transfer";
import { ID_EMPTY } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import TextFieldFocus from "../Shared/TextFieldFocus";

interface Props extends WithStyles<typeof styles> {
  catalogTransferPrice: CatalogTransferPriceType;
  catalogTransferMethod: CatalogTransferMethodType;
  location: LocationType;
}

function CatalogTransferLocationPrices({
  classes,
  catalogTransferPrice,
  catalogTransferMethod,
  location,
}: Props) {
  const { t } = useTranslation();

  const [createCatalogTransferPrice, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateCatalogTransferPriceArgs
  >(CREATE_CATALOG_TRANSFER_PRICE_MUTATION, {
    refetchQueries: [{ query: GET_CATALOG_TRANSFER_QUERY }],
    onCompleted: (result) => {
      if (result.createCatalogTransferPrice?.catalogTransferPrice) {
        setCatalogTransferPriceEdited({
          ...catalogTransferPriceEdited,
          id: result.createCatalogTransferPrice.catalogTransferPrice.id,
        });
      }
    },
    onError: (error) => {
      handleError(error);
    },
  });
  const [updateCatalogTransferPrice, { loading: loadingUpdate }] = useMutation<
    Mutation,
    MutationUpdateCatalogTransferPriceArgs
  >(UPDATE_CATALOG_TRANSFER_PRICE_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
  });

  const [catalogTransferPriceEdited, setCatalogTransferPriceEdited] = useState({
    ...catalogTransferPrice,
    priceBasic: formatNumber(catalogTransferPrice.priceBasic, 2),
    priceExtra: formatNumber(catalogTransferPrice.priceExtra, 2),
    priceStop: formatNumber(catalogTransferPrice.priceStop, 2),
  });

  const myPermissions = useContext(PermissionsContext);
  const hasPermissionEdit = checkPermission(myPermissions, [
    "catalogs_transfer.change_catalogtransferprice",
  ]);

  const handleSave = (values: { [key: string]: string | number }) => {
    if (catalogTransferPriceEdited.id !== ID_EMPTY) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  const handleUpdate = (
    values: Partial<MutationUpdateCatalogTransferPriceArgs>
  ) => {
    const base: MutationUpdateCatalogTransferPriceArgs = {
      catalogTransferPriceId: catalogTransferPriceEdited.id,
      catalogTransferMethodId: catalogTransferMethod.id,
      locationId: location.id,
      priceBasic: parseNumber(catalogTransferPriceEdited.priceBasic),
      priceExtra: parseNumber(catalogTransferPriceEdited.priceExtra),
      priceStop: parseNumber(catalogTransferPriceEdited.priceStop),
      productNumberBasic: catalogTransferPriceEdited.productNumberBasic,
      productNumberExtra: catalogTransferPriceEdited.productNumberExtra,
      productNumberStop: catalogTransferPriceEdited.productNumberStop,
    };

    updateCatalogTransferPrice({
      variables: { ...base, ...values },
    });
  };

  const handleCreate = (
    values: Partial<MutationCreateCatalogTransferPriceArgs>
  ) => {
    const base: MutationCreateCatalogTransferPriceArgs = {
      catalogTransferMethodId: catalogTransferMethod.id,
      locationId: location.id,
      priceBasic: parseNumber(catalogTransferPriceEdited.priceBasic),
      priceExtra: parseNumber(catalogTransferPriceEdited.priceExtra),
      priceStop: parseNumber(catalogTransferPriceEdited.priceStop),
      productNumberBasic: catalogTransferPriceEdited.productNumberBasic,
      productNumberExtra: catalogTransferPriceEdited.productNumberExtra,
      productNumberStop: catalogTransferPriceEdited.productNumberStop,
    };

    createCatalogTransferPrice({
      variables: { ...base, ...values },
    });
  };

  return (
    <>
      <tr className={loadingCreate || loadingUpdate ? "loading" : ""}>
        <td>{catalogTransferMethod.name}</td>
        <td>
          <FormControl className={classes.txtPrice} fullWidth>
            <TextFieldFocus
              value={catalogTransferPriceEdited.priceBasic}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  priceBasic: event.target.value,
                });
              }}
              onBlur={() => {
                const priceBasic = parseNumber(
                  catalogTransferPriceEdited.priceBasic
                );

                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  priceBasic: formatNumber(priceBasic, 2),
                });

                handleSave({
                  priceBasic: priceBasic,
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl className={classes.txtPrice} fullWidth>
            <TextFieldFocus
              value={catalogTransferPriceEdited.priceExtra}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  priceExtra: event.target.value,
                });
              }}
              onBlur={() => {
                const priceExtra = parseNumber(
                  catalogTransferPriceEdited.priceExtra
                );

                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  priceExtra: formatNumber(priceExtra, 2),
                });

                handleSave({
                  priceExtra: priceExtra,
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl className={classes.txtPrice} fullWidth>
            <TextFieldFocus
              value={catalogTransferPriceEdited.priceStop}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  priceStop: event.target.value,
                });
              }}
              onBlur={() => {
                const priceStop = parseNumber(
                  catalogTransferPriceEdited.priceStop
                );

                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  priceStop: formatNumber(priceStop, 2),
                });

                handleSave({
                  priceStop: priceStop,
                });
              }}
            />
          </FormControl>
        </td>
      </tr>
      <tr className={loadingCreate || loadingUpdate ? "loading" : ""}>
        <td>
          <small className="text-muted">{t("product_number")}</small>
        </td>
        <td>
          <FormControl className={classes.txtPrice} fullWidth>
            <TextField
              value={catalogTransferPriceEdited.productNumberBasic}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  productNumberBasic: event.target.value,
                });
              }}
              onBlur={() => {
                handleSave({
                  productNumberBasic:
                    catalogTransferPriceEdited.productNumberBasic,
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl className={classes.txtPrice} fullWidth>
            <TextField
              value={catalogTransferPriceEdited.productNumberExtra}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  productNumberExtra: event.target.value,
                });
              }}
              onBlur={() => {
                handleSave({
                  productNumberExtra:
                    catalogTransferPriceEdited.productNumberExtra,
                });
              }}
            />
          </FormControl>
        </td>
        <td>
          <FormControl className={classes.txtPrice} fullWidth>
            <TextField
              value={catalogTransferPriceEdited.productNumberStop}
              disabled={!hasPermissionEdit}
              onChange={(event) => {
                setCatalogTransferPriceEdited({
                  ...catalogTransferPriceEdited,
                  productNumberStop: event.target.value,
                });
              }}
              onBlur={() => {
                handleSave({
                  productNumberStop:
                    catalogTransferPriceEdited.productNumberStop,
                });
              }}
            />
          </FormControl>
        </td>
      </tr>
    </>
  );
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    txtPrice: {
      maxWidth: "90px",
    },
  });

export default withStyles(styles)(CatalogTransferLocationPrices);
