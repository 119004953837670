import React, { useState } from "react";
import { WithStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { createStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Mutation,
  MutationCreateTransportArgs,
  MutationCreateTransportInvoicingArgs,
  OrderType,
  QueryReferencesArgs,
  QueryTransportInvoicingsByOrderArgs,
  QueryTransportsByOrderArgs,
  TransportsTransportInvoicingRowTypeChoices,
  TransportsTransportMethodChoices,
} from "../../entity/types";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_TRANSPORT_INVOICINGS_BY_ORDER_QUERY,
  GET_TRANSPORTS_BY_ORDER_QUERY,
  QueryResultTransportInvoicingsByOrder,
  QueryResultTransportsByOrder,
} from "../../apollo/queries/transports";
import Loading from "../Shared/Loading";
import Error from "../Shared/Error";
import { Button } from "react-bootstrap";
import AddIcon from "@material-ui/icons/Add";
import { handleError } from "../../entity/ErrorHandler";
import {
  CREATE_TRANSPORT_INVOICING_MUTATION,
  CREATE_TRANSPORT_MUTATION,
} from "../../apollo/mutations/transports";
import TransportCard from "./TransportCard";
import {
  GET_CATALOG_TRANSFER_QUERY,
  QueryResultCatalogTransfer,
} from "../../apollo/queries/catalogs_transfer";
import { ID_EMPTY, ROOT_QUERY } from "../../utils/constants";
import {
  GET_REFERENCES_QUERY,
  QueryResultReferences,
} from "../../apollo/queries/references";
import TransportInvoicingSummary from "./TransportInvoicingSummary";
import { parseNumber } from "../../utils/formatting";
import { TransportInvoicingEmpty } from "../../entity/empties";
import { getQueryKey } from "../../utils/cache";
import { getQueryFetchPolicy } from "../../utils/getQueryFetchPolicy";
import TransportQuickButton from "./TransportQuickButton";
import TransportCardInvoicing from "./TransportCardInvoicing";

interface Props extends WithStyles<typeof styles> {
  order: OrderType;
}

function Transport({ classes, order }: Props) {
  const { t } = useTranslation();

  const [showTransportQuick, setShowTransportQuick] = useState(false);

  const {
    loading: loadingTransports,
    error: errorTransports,
    data: dataTransports,
  } = useQuery<QueryResultTransportsByOrder, QueryTransportsByOrderArgs>(
    GET_TRANSPORTS_BY_ORDER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("transportInvoicingsByOrder"),
      variables: {
        orderId: order.id,
      },
    }
  );

  const {
    loading: loadingReferences,
    error: errorReferences,
    data: dataReferences,
  } = useQuery<QueryResultReferences, QueryReferencesArgs>(
    GET_REFERENCES_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("references"),
      variables: {
        customerId: order.customer ? order.customer.id : ID_EMPTY,
      },
      skip: !order.customer,
    }
  );

  const {
    loading: loadingTransfers,
    error: errorTransfers,
    data: dataTransfers,
  } = useQuery<QueryResultCatalogTransfer, QueryTransportsByOrderArgs>(
    GET_CATALOG_TRANSFER_QUERY,
    {
      fetchPolicy: getQueryFetchPolicy("catalogTransferMethods"),
      variables: {
        orderId: order.id,
      },
    }
  );

  const {
    loading: loadingRows,
    error: errorRows,
    data: dataRows,
  } = useQuery<
    QueryResultTransportInvoicingsByOrder,
    QueryTransportInvoicingsByOrderArgs
  >(GET_TRANSPORT_INVOICINGS_BY_ORDER_QUERY, {
    fetchPolicy: getQueryFetchPolicy("transportInvoicingsByOrder"),
    variables: {
      orderId: order.id,
    },
  });

  const [createTransport, { loading: loadingCreate }] = useMutation<
    Mutation,
    MutationCreateTransportArgs
  >(CREATE_TRANSPORT_MUTATION, {
    onError: (error) => {
      handleError(error);
    },
    update: (cache) => {
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportsUnhandled"),
      });
      cache.evict({
        id: ROOT_QUERY,
        fieldName: getQueryKey("transportsByOrder"),
      });
    },
  });

  const [createTransportInvoicing, { loading: loadingCreateRow }] = useMutation<
    Mutation,
    MutationCreateTransportInvoicingArgs
  >(CREATE_TRANSPORT_INVOICING_MUTATION, {
    refetchQueries: [
      {
        query: GET_TRANSPORT_INVOICINGS_BY_ORDER_QUERY,
        variables: { orderId: order.id },
      },
    ],
    onError: (error) => {
      handleError(error);
    },
  });

  if (
    loadingTransports ||
    loadingTransfers ||
    loadingCreate ||
    loadingRows ||
    loadingCreateRow ||
    loadingReferences
  ) {
    return <Loading />;
  }
  if (errorTransports) return <Error error={errorTransports} />;
  if (errorTransfers) return <Error error={errorTransfers} />;
  if (errorRows) return <Error error={errorRows} />;
  if (errorReferences) return <Error error={errorReferences} />;
  if (!dataTransports || !dataTransfers || !dataRows) {
    // dataReferences has skip
    return <Error error={t("error_query_failed")} />;
  }

  const onClickAdd = () => {
    createTransport({
      variables: {
        orderId: order.id,
        catalogTransferMethodId: ID_EMPTY,
        reservationIds: [],
        method: TransportsTransportMethodChoices.Send,
        address: "",
        postcode: "",
        district: "",
        information: "",
        dateDeadline: null,
        timeDeadline: null,
      },
    });
  };

  const onClickAddRow = () => {
    createTransportInvoicing({
      variables: {
        orderId: order.id,
        referenceId: ID_EMPTY,
        catalogTransferMethodId: ID_EMPTY,
        method: TransportInvoicingEmpty.method,
        rowType: TransportsTransportInvoicingRowTypeChoices.Basic,
        title: TransportInvoicingEmpty.title,
        description: TransportInvoicingEmpty.description,
        unitPrice: TransportInvoicingEmpty.unitPrice,
        quantity: 1,
        discountPercent: TransportInvoicingEmpty.discountPercent,
      },
    });
  };

  const rowsTotal = dataRows.transportInvoicingsByOrder
    ? dataRows.transportInvoicingsByOrder.reduce(
        (sum, transportInvoicing) =>
          sum + transportInvoicing.quantity * transportInvoicing.unitPrice,
        0
      )
    : 0;

  const hasDiscountCol: boolean = (
    dataRows.transportInvoicingsByOrder
      ? dataRows.transportInvoicingsByOrder
      : []
  ).some(
    (transportInvoicing) =>
      parseNumber(transportInvoicing.discountPercent) !== 0
  );

  return (
    <div className="mb-1">
      {showTransportQuick && (
        <TransportQuickButton
          order={order}
          setShow={setShowTransportQuick}
          transports={order.transportSet}
        />
      )}
      {!showTransportQuick && (
        <div>
          <Button
            className="me-2"
            variant="light"
            onClick={() => setShowTransportQuick(!showTransportQuick)}
          >
            {t("quick_transport")}
          </Button>
        </div>
      )}
      <h4 className="mt-3 mb-3">{t("transports_for_organizer")}</h4>
      {dataTransports.transportsByOrder?.map((transport) => (
        <TransportCard
          key={transport.id}
          order={order}
          transport={transport}
          catalogTransferMethods={
            dataTransfers.catalogTransferMethods
              ? dataTransfers.catalogTransferMethods
              : []
          }
        />
      ))}
      <Button
        onClick={onClickAdd}
        className="btnRoundSmall d-inline-block"
        variant="primary"
      >
        <AddIcon />
      </Button>
      <h4 className="mt-5 mb-3">{t("transports_for_invoicing")}</h4>
      {dataRows.transportInvoicingsByOrder &&
        dataRows.transportInvoicingsByOrder.length > 0 && (
          <div>
            {dataRows.transportInvoicingsByOrder.map((transportInvoicing) => (
              <TransportCardInvoicing
                key={transportInvoicing.id}
                order={order}
                transportInvoicing={transportInvoicing}
                catalogTransferMethods={
                  dataTransfers.catalogTransferMethods
                    ? dataTransfers.catalogTransferMethods
                    : []
                }
                references={
                  dataReferences?.references ? dataReferences.references : []
                }
                hasDiscountCol={hasDiscountCol}
              />
            ))}
          </div>
        )}
      <Button
        onClick={onClickAddRow}
        className="btnRoundSmall d-inline-block"
        variant="primary"
      >
        <AddIcon />
      </Button>
      {dataRows.transportInvoicingsByOrder &&
        dataRows.transportInvoicingsByOrder.length > 0 && (
          <TransportInvoicingSummary
            order={order}
            references={
              dataReferences?.references ? dataReferences.references : []
            }
            total={rowsTotal}
            transportInvoicings={dataRows.transportInvoicingsByOrder}
          />
        )}
    </div>
  );
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Transport);
